/* import __COLOCATED_TEMPLATE__ from './content-viewer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import ArticlesComposerConfig from 'embercom/lib/articles/composer-config';
import { SchemaGenerator } from '@intercom/embercom-prosemirror-composer/lib/config/schema-generator';
import { Step } from 'prosemirror-transform';

export default class extends Component {
  @service store;
  @service notificationsService;
  @service appService;
  @service helpCenterService;
  @tracked isSaving = false;

  get app() {
    return this.appService.app;
  }

  get activeContentBlocksDocument() {
    return new BlocksDocument(this.activeContentBlocks, this.activeContentVersionSteps);
  }

  get activeContentVersionSteps() {
    if (this.app.canUseArticleVersionSteps && this.args.content.stepsSinceLastVersion) {
      let schema = new SchemaGenerator(this.prosemirrorComposerConfig).schema();
      return this.args.content.stepsSinceLastVersion.map((step) => Step.fromJSON(schema, step));
    } else {
      return [];
    }
  }

  get activeContentBlocks() {
    if (this.args.content) {
      return this.args.content.jsonBlocksForEditing || this.args.content.jsonBlocks;
    }
    return [];
  }

  get previousContentBlocksDocument() {
    return new BlocksDocument(this.previousContentBlocks);
  }

  get previousContentBlocks() {
    if (this.args.previousContent) {
      return this.args.previousContent.jsonBlocksForEditing || this.args.previousContent.jsonBlocks;
    }
    return [];
  }

  get prosemirrorComposerConfig() {
    let eventService = this.intercomEventService;
    let analytics = {
      trackAnalyticsEvent: (eventName, attrs) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'article',
          place: 'composer',
          ...attrs,
        });
      },
    };

    let config = new ArticlesComposerConfig(
      this.app,
      this.args.content.textDirection,
      analytics,
      () => {},
    );

    config.isInteractivityDisabled = true;
    config.autoFocus = false;
    return config;
  }

  get hasVersionChanges() {
    return this.args.versionDiffing && this.args.previousContent !== undefined;
  }

  get hasTitleChanged() {
    return this.hasVersionChanges && this.args.previousContent.title !== this.args.content.title;
  }

  get hasSummaryChanged() {
    return (
      this.hasVersionChanges && this.args.previousContent.summary !== this.args.content.summary
    );
  }

  get hasAuthorChanged() {
    return (
      this.hasVersionChanges && this.args.previousContent.authorId !== this.args.content.authorId
    );
  }
}
