/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import $ from 'jquery';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import ENV from 'embercom/config/environment';
import InboundActions from 'ember-component-inbound-actions/inbound-actions';
import abbreviatedRelativeTimeAgo from 'embercom/lib/abbreviated-relative-time-ago';
import abbreviatedRelativeTime from 'embercom/lib/abbreviated-relative-time';
import { equalsAny } from '@intercom/pulse/lib/computed-properties';

export default Component.extend(InboundActions, {
  ariaRole: 'application',
  frontendStatsService: service(),

  noAppYet: equalsAny('app', [undefined, null]),

  classNames: ['application__component'],
  didInsertElement() {
    this._super(...arguments);
    if (ENV.environment === 'development' && window.fetch) {
      fetch('http://intercom.test:4200/time-to-reload');
    }
  },

  click(e) {
    if (!this.get('frontendStatsService.hasRecordedFirstInteraction')) {
      this.frontendStatsService.recordFirstInteraction(e.target);
    }
  },

  actions: {
    refreshRelativeTime() {
      $('.js__relative-time', this.element).each(function () {
        let $this = $(this);
        let absTime = $this.attr('datetime');
        let formatter = $this.data('formatter');
        let oldRelative = $this.text().trim();
        let newRelative = oldRelative;
        if (formatter === 'abbreviated-relative-time-ago-helper') {
          newRelative = abbreviatedRelativeTimeAgo(absTime);
        } else if (formatter === 'abbreviated-relative-time-helper') {
          newRelative = abbreviatedRelativeTime(absTime);
        }
        $this.text($this.text().replace(oldRelative, newRelative));
      });
    },
  },
});
