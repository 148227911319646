/* import __COLOCATED_TEMPLATE__ from './billing-comparison-current-plan-card.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Price from 'embercom/models/price';

interface Args {
  heading: string;
  currentPrices: Array<Price>;
  infoContent: string;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationBillingComparisonCurrentPlanCardComponent extends Component<Signature> {
  @service declare customerService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get totalPrice() {
    return this.currentPrice.amount / 100;
  }

  get currentPrice() {
    return this.args.currentPrices[0];
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::BillingComparisonCurrentPlanCard': typeof BillingMigrationBillingComparisonCurrentPlanCardComponent;
  }
}
