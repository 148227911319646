/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';

import $ from 'jquery';

export default Component.extend({
  focusIf: true,
  withNewLine: true,
  shouldSelect: false,
  focusBeginningOfInput: false,
  preventScrollDown: false,
  focusIfShould() {
    if (!this.focusIf) {
      return;
    }

    let $fields = $('input:text, textarea, input[type="email"]', this.element);

    if ($fields === undefined) {
      return;
    }

    let $element = $fields.first();

    if (this.preventScrollDown) {
      let x = window.scrollX;
      let y = window.scrollY;
      $element.select().focus();
      window.scrollTo(x, y);
    } else if ($element.is('input')) {
      if (this.focusBeginningOfInput) {
        $element.select().focus();
      } else if (this.shouldSelect) {
        $element.focus().select();
      } else {
        $element.focus();
      }
    } else if ($element.is('textarea')) {
      let value = $element.val();
      if (this.withNewLine) {
        value = `${value}\n`;
      }
      if (this.shouldSelect) {
        $element.focus().val(value).select();
      } else {
        $element.focus().val(value);
      }
    }
  },
  didRender() {
    this._super(...arguments);
    let focusIf = this.focusIf;
    if (focusIf !== this._lastFocusIf) {
      this.focusIfShould();
    }
    this._lastFocusIf = focusIf;
  },
});
