/* import __COLOCATED_TEMPLATE__ from './navbar-collapsed.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  navbarCollapsingService: service(),
  contentEditorService: service(),
  navbarCollapsed: readOnly('navbarCollapsingService.collapsed'),
  collapsed: or('navbarCollapsed', 'contentEditorService.versionSidebarOpen'),
});
