/* import __COLOCATED_TEMPLATE__ from './collection-cards-container.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import shuffle from '@intercom/pulse/lib/shuffle';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

const DISPLAY_3_CARDS_WIDTH = 1650;

export default class CollectionCardsContainer extends Component {
  @service intercomEventService;

  get randomisedCollections() {
    return shuffle(this.args.collections);
  }

  backgroundUrls = {
    apps_by_intercom: assetUrl(
      '/assets/images/appstore/collections/apps-by-intercom-horizontal.png',
    ),
    hottest_apps: assetUrl('/assets/images/appstore/collections/hottest-apps-horizontal.png'),
    measure_nps: assetUrl('/assets/images/appstore/collections/measure-nps-horizontal.png'),
    schedule_sales_calls: assetUrl(
      '/assets/images/appstore/collections/schedule-sales-calls-horizontal.png',
    ),
    super_charge_data: assetUrl(
      '/assets/images/appstore/collections/super-charge-data-horizontal.png',
    ),
    survey: assetUrl('/assets/images/appstore/collections/survey-horizontal.png'),
  };

  @action
  trackCardClick(collection, cardIndex) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'collection_card',
      collection: collection.slug,
      card_index: cardIndex + 1,
      displayed_cards: window.innerWidth < DISPLAY_3_CARDS_WIDTH ? 2 : 3,
    });
  }
}
