/* import __COLOCATED_TEMPLATE__ from './message-templates.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { tracked } from '@glimmer/tracking';

export default class MessageTemplates extends Component {
  @service intercomEventService;
  @tracked selectedIntegration = {};

  constructor() {
    super(...arguments);
    this.selectedIntegration = this.whatsappIntegrations?.firstObject;
  }

  get whatsappIntegrations() {
    return this.args.whatsappIntegrations;
  }

  get integrationsDropdown() {
    let dropdownItems = this.whatsappIntegrations.map((integration) => {
      return {
        text: integration.displayString,
        value: integration,
        isSelected: this.selectedIntegration?.id === integration.id,
      };
    });

    return [
      {
        hasItemsMarkableAsSelected: true,
        items: dropdownItems,
      },
    ];
  }

  @action
  openManageTemplates() {
    let url = `https://business.facebook.com/wa/manage/message-templates/?waba_id=${this.selectedIntegration?.whatsappAccountId}&business_id=${this.selectedIntegration.businessId}`;
    safeWindowOpen(url, '_blank');

    this.intercomEventService.trackAnalyticsEvent({
      action: 'open_manage_templates',
      object: this.selectedIntegration,
    });
  }

  @action
  selectIntegration(integration) {
    this.selectedIntegration = integration;
  }
}
