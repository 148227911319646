/* import __COLOCATED_TEMPLATE__ from './current-seats-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  teammate: any;
}

interface Signature {
  Args: Args;
}

export default class SeatAssignmentCurrentSeatsCell extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: IntlService;

  get currentSeatType() {
    if (this.appService.app.hasValueBasedPricing2) {
      // this is a relatively naive approach as it only considers self serve customers
      // as of writing this comment SS customers are the only eligible customers
      // however, we should ideally consider VBP 2 Sales Led customers too as they can have Support/Convert/Engage seats
      return this.intl.t(
        'billing.migrations.edit_plan.admin_seat_mapping.body.current_seat_types.starter',
      );
    } else if (this.appService.app.canUsePerProductPricingFlow) {
      return this.args.teammate.currentAppPermissions.has_inbox_access
        ? this.intl.t(
            'billing.migrations.edit_plan.admin_seat_mapping.body.current_seat_types.inbox',
          )
        : this.intl.t(
            'billing.migrations.edit_plan.admin_seat_mapping.body.current_seat_types.none',
          );
    } else {
      return '';
    }
  }

  get withoutASeat() {
    return (
      this.currentSeatType ===
      this.intl.t('billing.migrations.edit_plan.admin_seat_mapping.body.current_seat_types.none')
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::SeatAssignment::CurrentSeatsCell': typeof SeatAssignmentCurrentSeatsCell;
  }
}
