/* import __COLOCATED_TEMPLATE__ from './auto-resizing-textarea-with-count-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { cancel, throttle } from '@ember/runloop';
import $ from 'jquery';
import { on } from '@ember/object/evented';
import Component from '@ember/component';
export default Component.extend({
  countInitiator: on('didInsertElement', function () {
    this.updateCountAndStatus();
  }),
  onDestroy: on('willDestroyElement', function () {
    cancel(this.pasteTimer);
    cancel(this.keyDownTimer);
  }),
  updateCountAndStatus() {
    this.updateCount();
    if (this.count < 0) {
      $('.auto-resizing-textarea-with-count__count-container', this.element).addClass('text-red');
      this.set('error', true);
      this.set('isResizable', false);
    } else {
      $('.auto-resizing-textarea-with-count__count-container', this.element).removeClass(
        'text-red',
      );
      $('textarea', this.element).removeClass('o__error');
      this.set('error', false);
      this.set('isResizable', true);
    }
  },
  handlePaste: on('paste', function () {
    this.pasteTimer = throttle(this, this.updateCountAndStatus, 10, false);
  }),
  handleKeyDown: on('keyDown', function () {
    this.keyDownTimer = throttle(this, this.updateCountAndStatus, 10, false);
  }),
  updateCount() {
    this.set('count', this.maxLength - (this.text || '').length);
  },
});
