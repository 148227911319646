/* import __COLOCATED_TEMPLATE__ from './flat-fee.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';
import type Charge from 'embercom/models/billing/price/charge';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    charge: Charge;
    testIdentifier: string;
  };
}

const FlatFee = templateOnlyComponent<Signature>();
export default FlatFee;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::FlatFee': typeof FlatFee;
  }
}
