/* import __COLOCATED_TEMPLATE__ from './article-list.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type ArticleList from 'embercom/models/customization-options/layout-options/blocks/article-list';
import type ArticleSummaryService from 'embercom/services/article-summary-service';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type LocalizedArticleListContent from 'embercom/models/customization-options/layout-options/home-page/localized-article-list-content';

interface Args {
  site: HelpCenterSite;
  homepageBlock: ArticleList;
}

export default class ArticleListComponent extends Component<Args> {
  @service declare articleSummaryService: ArticleSummaryService;

  @tracked language = this.args.site.locale;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.articleSummaryService.fetchAllArticleSummaries(this.args.site);
  }

  get columns() {
    return this.args.homepageBlock.columns || 1;
  }

  set columns(value: number) {
    this.args.homepageBlock.setColumns(value);
  }

  get enabled() {
    return this.args.homepageBlock.enabled;
  }

  set enabled(enabled: boolean) {
    this.args.homepageBlock.enabled = enabled;
  }

  // Article picker section

  get allLocalizedArticles() {
    let allArticlesForLocale = this.articleSummaryService.allArticlesForLocale(this.language) ?? [];
    return allArticlesForLocale.map((article) => ({
      text: article.locales[this.language]?.title ?? '',
      value: article.id,
      isDisabled: this.selectedArticleContent?.hasLink(article.id) ?? false,
    }));
  }

  get selectedArticleContent(): LocalizedArticleListContent | undefined {
    return this.args.homepageBlock.contentForLocale(this.language);
  }

  // Article language selector

  get languages() {
    return this.args.site.selectedLocales.map((locale) => ({
      text: locale.name,
      value: locale.localeId,
    }));
  }

  @action
  onLanguageChange(language: string) {
    this.language = language;
  }

  // Article title section

  @action
  onSelectArticle(slotIndex: number, article_id: string) {
    this.selectedArticleContent?.setArticleId(slotIndex, article_id);
  }

  @action
  onRemoveArticle(slotIndex: number) {
    this.selectedArticleContent?.removeArticleLinkSlot(slotIndex);
  }

  @action
  addArticleSlot() {
    this.selectedArticleContent?.addArticleLinkSlot();
  }

  @action
  onOrderArticles(reorderedArticleLinks: string[]) {
    this.selectedArticleContent?.reorderArticleLinks(reorderedArticleLinks);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Content::Layout::ArticleList': typeof ArticleListComponent;
    'articles/site/settings/content/layout/article-list': typeof ArticleListComponent;
  }
}
