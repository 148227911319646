/* import __COLOCATED_TEMPLATE__ from './notification-type-list-item.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly, notEmpty, and } from '@ember/object/computed';
import Component from '@ember/component';
export default Component.extend({
  assignee: readOnly('notificationRule.assignee'),
  hasAssignee: notEmpty('notificationRule.assignee'),
  showAssignee: and('hasAssignee', 'notificationType.isConversationType'),
});
