/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';

import { inject as service } from '@ember/service';
import type HelpCenterNavigationService from 'embercom/services/help-center-navigation-service';
import { type HomePageBlock } from 'embercom/models/customization-options/layout-options/home-page/home-page-options';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type NavigationStack from 'embercom/components/articles/site/settings/navigation-stack';
import { type UploadHelper } from 'embercom/objects/media/media-helper';

interface Args {
  isFirst: boolean;
  isLast: boolean;
  layoutBlock: HomePageBlock;
  site: HelpCenterSite;
  navigationStack: NavigationStack;
  uploadHelper: UploadHelper;
}
export default class LayoutItem extends Component<Args> {
  @service declare helpCenterNavigationService: HelpCenterNavigationService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::NewStyling::Layout::Item': typeof LayoutItem;
  }
}
