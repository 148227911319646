/* import __COLOCATED_TEMPLATE__ from './drag-and-drop.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { ref } from 'ember-ref-bucket';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ['application/pdf', 'image/jpeg', 'image/png'];

export default class DragAndDrop extends Component {
  @service notificationsService;
  @service intl;

  @tracked isDragging = false;
  @tracked selectedFile = this.args.selectedFile;

  @ref('inputElement') inputElement;

  @action
  pickFile() {
    this.clearFileInput();
    this.inputElement.click();
  }

  @action
  dragOver(event) {
    event.preventDefault();
    this.isDragging = true;
  }

  clearFileInput() {
    this.inputElement.value = '';
    this.selectedFile = null;
  }

  @action
  dragLeave(event) {
    event.preventDefault();
    this.isDragging = false;
  }

  @action
  drop(event) {
    event.preventDefault();
    this.isDragging = false;
    this.onFileSelect(event.dataTransfer.files[0]);
  }

  humanReadableFileSize(bytes) {
    if (bytes === 0) {
      return '';
    }

    let i = Math.floor(Math.log(bytes) / Math.log(1024));
    let sizes = ['B', 'KB', 'MB', 'GB'];
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  }

  @action
  onFileSelect(file) {
    if (file.size > MAX_FILE_SIZE) {
      this.notificationsService.notifyError(
        `${this.intl.t(
          'calling.settings.phone-regulatory-bundle.file-size-error',
        )} ${this.humanReadableFileSize(MAX_FILE_SIZE)}`,
      );
      return;
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      this.notificationsService.notifyError(
        this.intl.t('calling.settings.phone-regulatory-bundle.file-type-error'),
      );
      return;
    }

    this.selectedFile = file;
    if (file) {
      this.args.onFileSelect(file);
    }
  }

  @action
  onInputChange(event) {
    this.onFileSelect(event.target.files[0]);
  }

  get allowed_file_types() {
    return ALLOWED_FILE_TYPES.join(',');
  }
}
