/* import __COLOCATED_TEMPLATE__ from './prosemirror.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { inject as service } from '@ember/service';
import {
  BaseConfig,
  BlocksDocument,
  EMOJI_TYPEAHEAD,
  INPUT_RULE_EMOJI,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
} from '@intercom/embercom-prosemirror-composer';
import { trackedReset } from 'tracked-toolbox';
import { INLINE_CONTROL_ALIGNMENT } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';

interface CarouselComposerConfigCtorArgs {
  app: any;
  resolver: AttributeInfoResolver;
}

class CarouselComposerConfig extends BaseConfig {
  placeholder = '';
  autoFocus = false;
  allowedBlocks = [
    'paragraph',
    'image',
    'imageText',
    'heading',
    'subheading',
    'orderedList',
    'unorderedList',
  ];
  allowedInline = ['bold', 'italic', 'anchor'];
  allowImplicitMarginParagraphs = true;
  typeaheads = [EMOJI_TYPEAHEAD];
  tools = [
    { name: 'text-formatter' },
    { name: 'template-inserter' },
    { name: 'fallback-editor' },
    { name: 'media-inserter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'image-editor', options: { supportAltAttributeEditing: false } },
    { name: 'image-text-editor' },
  ];
  hideFromBlockFormatter = [INLINE_CONTROL_ALIGNMENT];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  inputRules = [INPUT_RULE_EMOJI, INPUT_RULE_ORDERED_LIST, INPUT_RULE_UNORDERED_LIST];
  inserters = {
    imageTextInserter: 'inserters/image-text-component',
  };
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
    onUploadStart: null,
    onUploadFinish: null,
  };
  templating: { picker: string; resolver: AttributeInfoResolver };

  constructor({ app, resolver }: CarouselComposerConfigCtorArgs) {
    super();
    this.templating = { picker: 'common/attribute-picker', resolver };
    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;
  }
}

export interface ProsemirrorComposerArgs {
  screen: $TSFixMe;
  currentDevice: 'ios' | 'android';
  isEditing: boolean;
}

export default class ProsemirrorComposer extends Component<ProsemirrorComposerArgs> {
  @service attributeService: $TSFixMe;
  @service appService: $TSFixMe;
  @service contentEditorService: $TSFixMe;
  @service store: $TSFixMe;

  @trackedReset({
    memo: 'blocksDocResetMemo',
    update() {
      // Reset blocksDoc when screen changes
      let blocks = this.args.screen?.blocks?.serialize?.();
      return new BlocksDocument(blocks || []);
    },
  })
  blocksDoc!: BlocksDocument;

  get blocksDocResetMemo() {
    return `${this.args.screen?.id} ${this.args.screen?.templateId}`;
  }

  get app() {
    return this.appService.app;
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    if (!selectedEvent) {
      return [];
    }
    return [selectedEvent];
  }

  get seriesTriggerAttributes() {
    return this.contentEditorService.seriesTriggerAttributes;
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorAndMetadataAttributes,
      includeAppAttributes: true,
      selectedEvents: this.selectedEvents,
      transientAttributes: this.seriesTriggerAttributes,
    });
  }

  get composerConfig() {
    let config = new CarouselComposerConfig({
      app: this.app,
      resolver: this.resolver,
    });
    config.upload.onUploadStart = this.contentEditorService.uploadFileStarted;
    config.upload.onUploadFinish = this.contentEditorService.uploadFileFinished;
    return config;
  }

  get blocks() {
    return this.blocksDoc.blocks;
  }

  @action
  updateBlocks(blocksDoc: BlocksDocument) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.args.screen.set('blocks', blockFragments);
    this.blocksDoc = blocksDoc;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Carousel::ScreenEditor::Composer::Prosemirror': typeof ProsemirrorComposer;
    'carousel/screen-editor/composer/prosemirror': typeof ProsemirrorComposer;
  }
}
