/* import __COLOCATED_TEMPLATE__ from './screen-editor.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  actionToTypeMap,
  permissionsLabelIconMap,
  actionTypes,
  typeToActionMap,
  lastScreenOptions,
  nonPermissionTypes,
  permissionTypeValues,
} from 'embercom/models/data/carousel/action-constants';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { screenTemplates } from 'embercom/models/data/carousel/screen-templates';
import { task } from 'ember-concurrency-decorators';

export default class ScreenEditorSidebar extends Component {
  @service appService;
  @service store;
  @service intercomEventService;
  @service contentEditorService;
  @service intercomConfirmService;

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  app = this.appService.app;

  @tracked selectedActionType = actionToTypeMap.continue;
  @tracked selectedPermission = actionToTypeMap.cameraPermission;
  @tracked selectedAction = null;

  constructor() {
    super(...arguments);
    this.setupCurrentModel();
  }

  @action setupCurrentModel() {
    this.selectedAction = this.args.screen.primaryAction;
    // if there are more than one screen it means that
    // a permission based action was selected.
    if (this.args.screen.carouselActions.length === 2) {
      this.selectedActionType = -1;
      this.selectedPermission = this.selectedAction.type;
    } else {
      this.selectedActionType = this.selectedAction.type;
    }
  }

  get hasPermissionBeenSelected() {
    return permissionTypeValues.includes(this.args.screen.primaryAction.type);
  }

  buildActions() {
    this.args.screen.clearActions();
    if (nonPermissionTypes.includes(this.selectedActionType)) {
      let action = typeToActionMap[this.selectedActionType];
      this.selectedAction = this.args.screen.createPrimaryAction(action);
    } else {
      let action = typeToActionMap[this.selectedPermission];
      this.args.screen.createSecondaryAction('skip');
      this.selectedAction = this.args.screen.createPrimaryAction(action);
    }
  }

  applyTemplateBlocks(screenTemplate) {
    let blockFragments = screenTemplate.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.args.screen.set('blocks', blockFragments);
  }

  applyTemplateActions(screenTemplate) {
    if (screenTemplate.actions) {
      this.args.screen.clearActions();
      screenTemplate.actions.forEach((action) => {
        if (action.order === 'primary') {
          this.args.screen.createPrimaryAction(action.actionType);
          this.showRelevantSidebarOptions(action.actionType);
        } else {
          this.args.screen.createSecondaryAction(action.actionType);
        }
      });
    }
  }

  showRelevantSidebarOptions(actionType) {
    if (permissionsLabelIconMap.map((p) => p.value).includes(actionToTypeMap[actionType])) {
      this.selectedActionType = -1;
      this.selectedPermission = actionToTypeMap[actionType];
    } else {
      this.selectedActionType = actionToTypeMap[actionType];
    }
  }

  applyTemplate(type) {
    let screenTemplate = screenTemplates[type];
    this.applyTemplateBlocks(screenTemplate);
    this.args.screen.templateId = type;
    this.applyTemplateActions(screenTemplate);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'mobile_carousel_screen_template_clicked',
      object: `carousel_screen_template_${type}`,
      owner: 'team-messenger',
      place: 'carousel_editor_screen',
      metadata: { ruleset_id: this.ruleset.id },
    });
  }

  @action
  onChangeActionType(option) {
    this.selectedActionType = option;
    this.buildActions();
  }

  @action onChangePermission(option) {
    this.selectedActionType = -1;
    this.selectedPermission = option;
    this.buildActions();
  }

  @action setUris(e) {
    this.setAndroidUri(e);
    this.setIosUri(e);
  }

  @action setAndroidUri(e) {
    this.args.screen.carouselActions.firstObject.set('androidUri', e.target.value);
  }

  @action setIosUri(e) {
    this.args.screen.carouselActions.firstObject.set('iosUri', e.target.value);
  }

  @action onBack() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'mobile_carousel_full_preview_nav_clicked',
      object: `carousel_breadcrumb`,
      owner: 'team-messenger',
      place: 'carousel_editor_screen',
      metadata: { ruleset_id: this.ruleset.id },
    });
    this.args.onBack();
  }

  @task *onTemplateClick(type) {
    if (this.args.screen.blocks) {
      let confirmation = yield this.intercomConfirmService.confirm({
        title: 'Choose a new layout?',
        body: `Choosing a new layout template will erase any edits you have already made to this screen.`,
        confirmButtonText: 'Confirm',
      });
      if (!confirmation) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'mobile_carousel_screen_template_clicked_rejected_confirmation',
          object: `carousel_screen_template_${type}`,
          owner: 'team-messenger',
          place: 'carousel_editor_screen',
          metadata: { ruleset_id: this.ruleset.id },
        });
        return;
      }
    }
    this.applyTemplate(type);
  }

  get actionTypes() {
    if (this.args.screen.isLastScreen) {
      return lastScreenOptions;
    } else {
      return actionTypes;
    }
  }

  get permissionTypes() {
    return permissionsLabelIconMap;
  }
}
