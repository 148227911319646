/* import __COLOCATED_TEMPLATE__ from './auto-resolve-rule-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Task from 'embercom/models/checklists/task';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { contentObjectNames } from 'embercom/models/data/outbound/template-constants';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';

interface Args {
  task: Task;
}

const userAttributesToFilterOut = [
  'last_conversation_rating_given',
  'user_event_summaries.viewed article',
  'user_event_summaries.viewed-article',
  'user_event_summaries.reacted with 😃 to article',
  'user_event_summaries.reacted with 😐 to article',
  'user_event_summaries.reacted with 😞 to article',
  'tag_ids',
];
const companyAttributesToFilterOut = ['company.tag_ids'];
const tourEventsToFilterOut = ['tour_failed', 'tour_goal_hit'];

export default class AutoResolveRuleSelector extends Component<Args> {
  @service declare appService: any;
  @service attributeService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  get task() {
    return this.args.task;
  }

  get attributeGroupList() {
    let groups = [];
    let allContentEvents = this.attributeService.attributeGroupListForContentEvents;

    groups.push(this.getProductTourEvents(allContentEvents));

    if (this.app.canUseArticleContentEvents) {
      groups.push(this.getArticleEvents(allContentEvents));
    }

    groups.push({
      heading: this.intl.t('outbound.checklists.task-settings.user-data'),
      attributes: this.getUserAttributes(),
    });

    groups.push({
      heading: this.intl.t('outbound.checklists.task-settings.company-data'),
      attributes: this.companyAttributes(),
    });

    return groups;
  }

  private getProductTourEvents(allContentEvents: any[]) {
    let productToursEvents = allContentEvents.filter(
      (contentEvent: any) =>
        contentEvent.heading.toLowerCase() === contentObjectNames[objectTypes.tour],
    )[0];

    if (productToursEvents) {
      productToursEvents.attributes = productToursEvents.attributes.filter(
        (attr: any) => !tourEventsToFilterOut.includes(attr.identifier),
      );

      return productToursEvents;
    }

    return {};
  }

  private getArticleEvents(allContentEvents: any[]) {
    let articleEvents = allContentEvents.filter(
      (contentEvent: any) => contentEvent.heading.toLowerCase() === 'articles',
    )[0];

    if (articleEvents) {
      return articleEvents;
    }

    return {};
  }

  private getUserAttributes() {
    let attributes = this.attributeService.filterableUserAttributesForMessaging;
    return attributes.filter((attr: any) => {
      // Filter out attributes in the list, except if they are already set on the model.
      if (userAttributesToFilterOut.includes(attr.identifier)) {
        let predicateAlreadySetOnModel = Boolean(
          this.task.predicateGroup._findPredicate((pred: any) =>
            pred.attribute?.startsWith(attr.identifier),
          ),
        );

        return predicateAlreadySetOnModel;
      }

      return true;
    });
  }

  private companyAttributes() {
    let attributes = this.attributeService.filterableCompanyAttributesForMessaging;
    return attributes.filter((attr: any) => {
      // Filter out attributes in the list, except if they are already set on the model.
      if (companyAttributesToFilterOut.includes(attr.identifier)) {
        let predicateAlreadySetOnModel = Boolean(
          this.task.predicateGroup._findPredicate((pred: any) =>
            pred.attribute?.startsWith(attr.identifier),
          ),
        );

        return predicateAlreadySetOnModel;
      }

      return true;
    });
  }

  @action
  onChangePredicates() {
    if (this.task.hasCompanyRule) {
      this.task.canBeManuallyResolved = true;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Sidebar::AutoResolveRuleSelector': typeof AutoResolveRuleSelector;
    'checklist/sidebar/auto-resolve-rule-selector': typeof AutoResolveRuleSelector;
  }
}
