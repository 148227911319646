/* import __COLOCATED_TEMPLATE__ from './twitter-card.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { statuses } from 'embercom/lib/channels/constants';

export default class TwitterCard extends Component {
  @service appService;
  @service store;
  @service intl;
  @tracked appPackage;
  @tracked integrations;
  @tracked integrationDisabled = !this.appService.app.canUseFeature('enable-twitter-integration');

  constructor() {
    super(...arguments);

    if (!this.integrationDisabled) {
      this.loadAppPackage.perform();
    }
  }

  @task({ drop: true })
  *loadAppPackage() {
    this.appPackage = yield this.store.findRecord('appstore/app-package', 'twitter');
  }

  get status() {
    return statuses.active;
  }
}
