/* import __COLOCATED_TEMPLATE__ from './cleanup-before-sync-modal.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    processing?: boolean;
    closeModal: () => void;
    removeSyncBeforeOtherSync: () => void;
  };
}

const CleanupBeforeSyncModal = templateOnlyComponent<Signature>();
export default CleanupBeforeSyncModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Shared::CleanupBeforeSyncModal': typeof CleanupBeforeSyncModal;
  }
}
