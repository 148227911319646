/* import __COLOCATED_TEMPLATE__ from './what-happens-next.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */

import Component from '@glimmer/component';

export interface Args {
  heading: string;
  body: Array<{ heading: string; content: string }>;
}

export default class WhatHappensNext extends Component<Args> {
  get heading() {
    return this.args.heading;
  }

  get body() {
    return this.args.body;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checkout::Success::WhatHappensNext': typeof WhatHappensNext;
    'checkout/success/what-happens-next': typeof WhatHappensNext;
  }
}
