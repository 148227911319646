/* import __COLOCATED_TEMPLATE__ from './multisection-accordion.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';
import storage from 'embercom/vendor/intercom/storage';

interface Args {
  isDisabled?: boolean;
  rememberOpenSections?: boolean;
  groupId?: string; // this field is used as an identifier for the `rememberOpenSections` functionality
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: any;
}

export default class MultisectionAccordion extends Component<Signature> {
  @service panelActions: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  willDestroy() {
    super.willDestroy();

    if (!this.args.rememberOpenSections) {
      return false;
    }

    let openSections = this.panelActions.state.keys.filter(
      (sectionId: string) =>
        sectionId.includes(this.groupId) && this.panelActions.state.get(sectionId).isOpen,
    );
    storage.set(`accordion-state:${this.groupId}`, openSections);
    return;
  }

  get groupId() {
    return `pulse-accordion-${this.args.groupId ?? guidFor(this)}`;
  }

  toggleSection(sectionId: string) {
    this.panelActions.toggle(sectionId);
  }

  @action
  onSectionReady(sectionId: string) {
    if (!this.args.rememberOpenSections) {
      return this.panelActions.open(sectionId);
    }

    let openSections = storage.get(`accordion-state:${this.groupId}`);
    if (!openSections || openSections.includes(sectionId)) {
      this.panelActions.open(sectionId);
    }
  }

  toggleAll(sectionId: string) {
    let panel = this.panelActions._panelFor(sectionId);

    if (panel.get('isOpen')) {
      this.panelActions.closeAll(this.groupId);
    } else {
      this.panelActions.openAll(this.groupId);
    }
  }

  get accordionAPI() {
    return {
      isDisabled: this.args.isDisabled,
      openSection: this.toggleSection.bind(this),
      closeSection: this.toggleSection.bind(this),
      toggleSection: this.toggleSection.bind(this),
      toggleAll: this.toggleAll.bind(this),
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::MultisectionAccordion': typeof MultisectionAccordion;
    'common/multisection-accordion': typeof MultisectionAccordion;
  }
}
