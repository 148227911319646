/* import __COLOCATED_TEMPLATE__ from './billing-contacts.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { post } from 'embercom/lib/ajax';
import { isEqual } from 'underscore';

export default class BillingContacts extends Component {
  @service appService;
  @service customerService;
  @service notificationsService;
  @service intl;
  @service store;

  @tracked attachInvoicePdfToEmail;
  @tracked isUpdatingCheckbox = false;
  @tracked isUpdatingEmails = false;
  @tracked allEmails = [];
  @tracked bouncedEmailError;

  constructor() {
    super(...arguments);
    this._mapCustomerEmails();
    this.attachInvoicePdfToEmail = this.customer.attachInvoicePdfToEmail;
  }

  get app() {
    return this.appService.app;
  }

  _mapCustomerEmails() {
    this.customer.billingAddressees?.map((billingAddress) => {
      this.allEmails.push({
        id: billingAddress.id,
        value: billingAddress.email_address,
        error: false,
        errorMessage: null,
      });
    });
  }

  get customer() {
    return this.customerService.customer;
  }

  get buttonDisabled() {
    return (
      this.invalidEmailsExist ||
      this.hasTooManyEmails ||
      this._hasNoEmails ||
      (!this.isCheckboxEdited && !this.isEmailInputEdited)
    );
  }

  get errorMessage() {
    if (this._hasNoEmails) {
      return this.intl.t('billing.billing-settings.billing_addressees_input.no-emails');
    }
    if (this.hasTooManyEmails) {
      return this.intl.t('billing.billing-settings.billing_addressees_input.emails-too-long');
    }
  }

  get invalidEmailsExist() {
    return this.allEmails.some((email) => email.error === true);
  }

  get isEmailInputEdited() {
    let existingEmailAddresses = this.customer.billingAddressees?.map(
      ({ email_address }) => email_address,
    );

    let newEmailAddresses = this.allEmails.map((email) => {
      return email.value;
    });

    return !isEqual(existingEmailAddresses, newEmailAddresses);
  }

  get isCheckboxEdited() {
    return this.attachInvoicePdfToEmail !== this.customer.attachInvoicePdfToEmail;
  }

  get isEdited() {
    return this.isCheckboxEdited || this.isEmailInputEdited;
  }

  @action
  onEmailUpdate(emails) {
    this.allEmails = emails;
  }

  @task
  *handleSubmit() {
    try {
      if (this.isCheckboxEdited) {
        yield this._updateAttachPdfCheckbox.perform();
      }
      if (this.isEmailInputEdited) {
        yield this._updateBillingAddresses.perform();
      }
      this.notificationsService.notifyConfirmation(
        this.intl.t('billing.billing-settings.billing_addressees_input.saved'),
      );
    } catch (error) {
      this._showError(error);
    }
  }

  _showError(error) {
    let bouncedEmails = error.jqXHR.responseJSON?.bounced_emails;
    if (bouncedEmails) {
      this.bouncedEmailError = this.intl.t(
        'billing.billing-settings.billing_addressees_input.bounced-emails-error',
        { bouncedEmails: bouncedEmails.join(', ') },
      );
      return;
    }
    let errorMessage = error.jqXHR.responseJSON[0] || error.jqXHR.responseJSON?.error;
    this.notificationsService.notifyError(errorMessage);
  }

  @task({ drop: true })
  *_updateAttachPdfCheckbox() {
    this.isUpdatingCheckbox = true;

    yield post(`/ember/customers/${this.customer.id}/change_company_details`, {
      app_id: this.app.id,
      attach_invoice_pdf_to_email: this.attachInvoicePdfToEmail,
    }).then((result) => {
      this.store.pushPayload('billing/customer', {
        'billing/customer': {
          id: this.customer.id,
          attach_invoice_pdf_to_email: result.attach_invoice_pdf_to_email,
        },
      });
      this.isUpdatingCheckbox = false;
    });
  }

  @task({ drop: true })
  *_updateBillingAddresses() {
    this.isUpdatingEmails = true;

    let result = yield post('/ember/billing_addressees', {
      app_id: this.app.id,
      billing_addressees: this.formattedBillingAddresses,
    });
    this.store.pushPayload('billing/customer', {
      'billing/customer': { id: this.customer.id, billing_addressees: result.billing_addressees },
    });
  }

  get formattedBillingAddresses() {
    return this.allEmails.map((email) => {
      return {
        email_address: email.value,
      };
    });
  }

  get _hasNoEmails() {
    return this.allEmails.length === 0;
  }

  get hasTooManyEmails() {
    return (
      this.allEmails.length > 16 ||
      this.allEmails.reduce((accumulator, email) => (accumulator += email.value.length), 0) > 1200
    );
  }

  @action clearAllEmails() {
    this.allEmails = [];
  }
}
