/* import __COLOCATED_TEMPLATE__ from './task-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Task from 'embercom/models/checklists/task';

interface Args {
  tasks: Task[];
  isEditing: boolean;
  openSectionId?: string;
  shouldShowValidations: boolean;
  onOpenSection: () => void;
  deleteTask: (task: Task) => void;
  setActiveTask: (task: Task) => void;
  reorderTasks: (tasks: Task[]) => void;
}

export default class TaskList extends Component<Args> {
  @tracked hoveredTaskId?: string;

  @action onTaskDragged(newTaskOrder: Task[], draggedTask: Task) {
    let draggedStepIndex = newTaskOrder.indexOf(draggedTask);
    let noMovement = draggedStepIndex === draggedTask.order;

    if (noMovement) {
      return; // no reordering to do
    }

    this.args.reorderTasks(newTaskOrder);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Editor::Preview::TaskList': typeof TaskList;
    'checklist/editor/preview/task-list': typeof TaskList;
  }
}
