/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
import moment from 'moment-timezone';

const ISO_FORMAT_WITHOUT_TZ = 'YYYY-MM-DDTHH:mm:ss';

// after we strip the app's zone we need to convert it back into a moment for ember-power-calendar
let stripAppTimezone = (date) => (date ? moment(moment(date).format(ISO_FORMAT_WITHOUT_TZ)) : null);

export default Component.extend({
  // because we pass a range as moment dates formatted in the app's zone,
  // we need to strip the app's zone so that ember-power-calendar
  // doesn't try to convert it to the computer's timezone if they are different
  rangeWithoutZone: computed('range.{start,end}', function () {
    return {
      start: stripAppTimezone(this.get('range.start')),
      end: stripAppTimezone(this.get('range.end')),
    };
  }),
});
