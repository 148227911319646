/* import __COLOCATED_TEMPLATE__ from './simple-preview-modal.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
// import { modifier } from 'ember-modifier';
import { tracked } from '@glimmer/tracking';
import isValidUrl from 'embercom/lib/url-validator';
import type IntlService from 'embercom/services/intl';

interface Args {
  intersectionHandlerName: string;
  analyticsObjectName?: string;
  intersectionModeName: string;
  infoTip: string;
  closeModal: () => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class SimplePreviewModal extends Component<Signature> {
  @service declare intersectionService: any;
  @service declare appService: any;
  @service declare contentEditorService: any;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;

  @tracked isSelectingRemotePreview = false;
  @tracked selectedDomain: string | undefined;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.intersectionService.registerEventHandler(
      this.args.intersectionHandlerName,
      (_data: any) => {
        return {
          id: this.activeObject.id,
          title: this.title,
        };
      },
    );
  }

  get activeObject() {
    if (
      this.contentEditorService.ruleset.hasTests &&
      !this.contentEditorService.ruleset.hasControlGroup
    ) {
      return this.contentEditorService.activeObject;
    }

    return this.contentEditorService.ruleset.rulesetLinks.firstObject.object;
  }

  get title() {
    return (
      this.contentEditorService.ruleset.clientData.title || this.intl.t('outbound.preview.untitled')
    );
  }

  @action
  closeModal() {
    this.args.closeModal();
  }

  @action
  openSite() {
    if (this.args.analyticsObjectName) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: this.args.analyticsObjectName,
      });
    }
    this.intersectionService.openWindow(this.selectedDomain, this.args.intersectionModeName);
    this.args.closeModal();
  }

  get isValidSelectedDomain() {
    return isValidUrl(this.selectedDomain);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::OutboundPreview::SimplePreviewModal': typeof SimplePreviewModal;
    'common/outbound-preview/simple-preview-modal': typeof SimplePreviewModal;
  }
}
