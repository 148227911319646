/* import __COLOCATED_TEMPLATE__ from './migration-overview.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import type StripeMigration from 'embercom/models/billing/stripe-migration';
import templateOnlyComponent from '@ember/component/template-only';
import type Price from 'embercom/models/price';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';

interface Args {
  migration: StripeMigration;
  pricing5Estimates: {
    essentialEstimate: Price;
    essentialWithPSPEstimate: Price;
    essentialWithCopilotEstimate: Price;
    essentialWithPSPCopilotEstimate: Price;
    advancedEstimate: Price;
    advancedWithPSPEstimate: Price;
    advancedWithCopilotEstimate: Price;
    advancedWithPSPCopilotEstimate: Price;
    expertEstimate: Price;
    expertWithPSPEstimate: Price;
    expertWithCopilotEstimate: Price;
    expertWithPSPCopilotEstimate: Price;
  };
  onSelectionChange: $TSFixMe;
  howWeAssignSeatsLink?: string;
  howWeAssignSeatsContent?: string;
  allFeatures: string[];
  availableFeaturesForApp: string[];
  availableEssentialFeatures: string[];
  availableAdvancedFeatures: string[];
  availableExpertFeatures: string[];
  availablePSPFeatures: string[];
  currentPrices: Price[];
  onProactiveMigrationSave: () => void;
  migrationSeatConfiguration?: MigrationSeatConfiguration;
}

interface Signature {
  Args: Args;
}

const BillingMigrationOverviewComponent = templateOnlyComponent<Signature>();
export default BillingMigrationOverviewComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationOverview': typeof BillingMigrationOverviewComponent;
  }
}
