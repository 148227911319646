/* import __COLOCATED_TEMPLATE__ from './plan-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  PLAN_DATA,
  P5_CORE_PLAN_UPGRADE_FEATURES,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
} from 'embercom/lib/billing';
import type QuoteService from 'embercom/services/quote-service';
import type Plan from 'embercom/models/plan';
import { action } from '@ember/object';
import { ModalTypes } from './subscription-builder';
import moment from 'moment-timezone';

interface Args {
  plan: Plan;
  loading: boolean;
  selectedCorePlanId?: number;
  addOnIdsToAdd: Array<number>;
  setSelected: (plan: Plan) => void;
  paramsFeatureName: string;
  paramsPlanId: number;
  inSubscriptionPlan: Plan;
  showModal: (modalType: ModalTypes) => void;
}

interface Signature {
  Args: Args;
  Element: never;
}

const PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM = Number(PROACTIVE_SUPPORT_PLUS_BASE_ID);

export default class PlanCard extends Component<Signature> {
  @service declare intl: any;
  @service declare quoteService: QuoteService;

  get featureName() {
    return this.args.paramsFeatureName;
  }

  get features() {
    let features = P5_CORE_PLAN_UPGRADE_FEATURES[this.args.plan.idAsNumber];
    if (features) {
      return [
        ...P5_CORE_PLAN_UPGRADE_FEATURES[this.args.plan.idAsNumber].left,
        ...P5_CORE_PLAN_UPGRADE_FEATURES[this.args.plan.idAsNumber].right,
      ];
    }
    return PLAN_DATA[this.args.plan.idAsNumber]?.marketingFeaturesTranslationKeys;
  }

  get isDowngrade() {
    //it's only a downgrade if its on the same product
    if (this.args.inSubscriptionPlan.product.id !== this.args.plan.product.id) {
      return false;
    }
    return this.args.inSubscriptionPlan.tierId > this.args.plan.tierId;
  }

  get hasFeature() {
    return (
      Number(this.args.paramsPlanId) === this.args.plan.idAsNumber && this.args.paramsFeatureName
    );
  }

  get isNotLowestTierCorePlan() {
    return this.args.plan.tierId > 1;
  }

  // prices
  get quote() {
    return this.quoteService.getQuoteById([this.args.plan.idAsNumber]);
  }

  get isSelected() {
    return (
      // selected core plan, core plans can only be single selected
      this.args.selectedCorePlanId === this.args.plan.idAsNumber ||
      // selected addons
      this.args.addOnIdsToAdd?.includes(this.args.plan.idAsNumber) ||
      // addon is in subscription. Addons can be multi selected at once
      this.isAddonInSubscription
    );
  }

  get isProactiveSupportPlus() {
    return this.args.plan.idAsNumber === PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM;
  }

  get fullPrice() {
    if (this.isProactiveSupportPlus) {
      return this.quote?.fullBreakdownAmount(this.args.plan.idAsNumber) || 0;
    }
    return (
      this.quote?.fullSeatPrice(this.args.plan.idAsNumber, this.args.plan.product.pricingMetric) ||
      0
    );
  }

  get planDaysRemainingOnTrial() {
    if (!this.args.plan.activeTrial) {
      return 0;
    }
    let trialEnd = moment(this.args.plan.activeTrialEndedAt);
    return trialEnd.diff(moment(), 'days');
  }

  get discountedSeatPrice() {
    return (
      this.quote?.discountedAnnualSeatPrice(
        this.args.plan.idAsNumber,
        this.args.plan.product.pricingMetric,
      ) || 0
    );
  }

  get proactiveSupportBaseUsage() {
    return this.quote?.proactiveSupportBaseUsage;
  }

  get proactiveSupportMetricPrice() {
    return this.quote?.proactiveSupportMetricPrice;
  }

  get fullProactiveAddonPrice() {
    return this.quote?.fullBreakdownAmount(Number(this.args.plan.idAsNumber)) || 0;
  }

  get isInSubscription() {
    return this.args.plan.billableCustomerPlan;
  }

  get isAddonInSubscription() {
    return this.isInSubscription && this.args.plan.product.addon;
  }

  get isDisabled() {
    // only disable clicks if it's an add on and it's already in subscription
    // otherwise you should be able to switch between the core plans unless its a downgrade
    return this.isAddonInSubscription || this.isDowngrade;
  }

  @action
  setSelectedPlan() {
    // Dont toggle plan if already exists
    // or it's a downgrade
    if (this.isDisabled) {
      if (this.isDowngrade) {
        this.args.showModal(ModalTypes.DowngradePlan);
      }
      if (this.isAddonInSubscription) {
        this.args.showModal(ModalTypes.RemovingProducts);
      }
      return;
    }
    this.args.setSelected(this.args.plan);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::AnnualManageSubscription::PlanCard': typeof PlanCard;
  }
}
