/* import __COLOCATED_TEMPLATE__ from './choice-consent-step.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import type StripeMigration from 'embercom/models/billing/stripe-migration';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { restartableTask } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import type Price from 'embercom/models/price';
import type Breakdown from 'embercom/models/billing/price/breakdown';
import {
  FIN_AI_COPILOT_BASE_ID,
  PRICING_5_X_ADDON_PLAN_IDS,
  PRICING_5_X_CORE_PLANS,
} from 'embercom/lib/billing';
import { getEstimatedCoreSeatCharge } from 'embercom/helpers/billing/migrations-helper';

interface Args {
  saveMigrationTask: any;
  migration: StripeMigration;
  planIds?: string[];
  currentPrices: Price[];
  pricing5Estimates: {
    essentialEstimate: Price;
    essentialWithPSPEstimate: Price;
    essentialWithCopilotEstimate: Price;
    advancedEstimate: Price;
    advancedWithPSPEstimate: Price;
    advancedWithCopilotEstimate: Price;
    expertEstimate: Price;
    expertWithPSPEstimate: Price;
    expertWithCopilotEstimate: Price;
    essentialWithPSPCopilotEstimate: Price;
    advancedWithPSPCopilotEstimate: Price;
    expertWithPSPCopilotEstimate: Price;
  };
}

interface Signature {
  Args: Args;
}

export default class ChoiceConsentStep extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  get basePriceOfPackage() {
    let total =
      this.futureMigrationPrice?.breakdown.reduce(
        (acc: number, breakdown: Breakdown) =>
          (acc +=
            (PRICING_5_X_CORE_PLANS.includes(String(breakdown.plan_id))
              ? getEstimatedCoreSeatCharge(breakdown)!.price
              : this.calculateAddonBreakdownPrice(breakdown)) ?? 0),
        0,
      ) / 100;

    return this.intl.formatNumber(total ?? 0, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  private get futureMigrationPrice(): Price | undefined {
    return Object.values(this.args.pricing5Estimates).find((estimate) =>
      estimate.hasSamePlans(this.planIds),
    )!;
  }

  calculateAddonBreakdownPrice(breakdown: Breakdown) {
    if (!PRICING_5_X_ADDON_PLAN_IDS.includes(String(breakdown.plan_id))) {
      return 0;
    }

    if (String(breakdown.plan_id) === FIN_AI_COPILOT_BASE_ID) {
      return breakdown.amount;
    }

    return breakdown.plan_starting_price;
  }

  private get planIds() {
    return this.args.planIds?.map((planId) => Number(planId));
  }

  get estimatedTotal() {
    return (
      this.futureMigrationPrice?.formattedAmountToTwoDecimals ??
      this.intl.formatNumber(0, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }

  get currentPrice() {
    return this.args.currentPrices.find((price) =>
      price.hasSamePlans(this.customerService.activePlanIds),
    );
  }

  get estimatedPriceChange() {
    let total = (this.futureMigrationPrice?.amount - this.currentPrice?.amount) / 100 ?? 0;

    return this.intl.formatNumber(total, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      signDisplay: 'exceptZero',
    });
  }
  get submitButtonLabel() {
    return this.args.saveMigrationTask.isRunning
      ? this.intl.t('billing.migrations.proactive.saving')
      : this.intl.t('billing.migrations.proactive.review_and_confirm');
  }

  @restartableTask
  *saveMigrationAndEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'stripe_migrations',
      place: 'edit_package_tab',
      object: 'confirm_button',
      is_increaser: (this.futureMigrationPrice?.amount - this.currentPrice?.amount) / 100 > 0,
      previous_plan_ids: this.customerService.activePlanIds.toString(),
      recommended_plan_ids: this.args.migration.recommendedPlanIds.toString(),
      chosen_plan_ids: this.planIds?.toString(),
    });
    yield this.args.saveMigrationTask.linked().perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationSubcontent::ChoiceConsentStep': typeof ChoiceConsentStep;
  }
}
