/* import __COLOCATED_TEMPLATE__ from './tab-container.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable @intercom/intercom/require-empty-tagname */

import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed, action } from '@ember/object';

export default Component.extend({
  classNames: ['message__editor__stats__container', 'flex-auto', 'flex', 'flex-row'],
  classNameBindings: ['hideComponent:u__hidden', 'isEditing:o__collapsed'],
  router: service(),
  hideComponent: false,
  activeView: computed('eventType', function () {
    return this.eventType || 'answer';
  }),

  changeView: action(function (displaying) {
    let transitionRoute = this.currentRouteName;
    if (displaying !== 'answer') {
      transitionRoute += '.conversations';
    }
    this.router.transitionTo(transitionRoute, { queryParams: { eventType: displaying } });
  }),
});
