/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
import { on } from '@ember/object/evented';
import { TextArea } from '@ember/legacy-built-in-components';
const ENTER = 13;

export default TextArea.extend({
  keyDown(e) {
    if (e.keyCode === ENTER) {
      e.preventDefault();
    }
  },

  input() {
    this.resizeInput();
  },

  resizeInput: on('didInsertElement', 'didUpdate', function () {
    let e = this.element;
    e.style.height = '0px';
    e.style.height = `${e.scrollHeight + 1}px`;
  }),
});
