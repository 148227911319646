/* import __COLOCATED_TEMPLATE__ from './content-author-created-by.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import templateOnlyComponent from '@ember/component/template-only';
import type App from 'embercom/app';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    author: any;
    updatedAt: any;
    canEdit: boolean;
    app: App;
    onSelectAuthor: any;
    createdBy: any;
    hasAuthorChanged?: any;
    authorsHidden?: any;
    siteId?: any;
    onShowHistory?: () => void;
  };
}

const ContentAuthorCreatedBy = templateOnlyComponent<Signature>();
export default ContentAuthorCreatedBy;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Shared::ContentAuthorCreatedBy': typeof ContentAuthorCreatedBy;
    'articles/shared/content-author-created-by': typeof ContentAuthorCreatedBy;
  }
}
