/* import __COLOCATED_TEMPLATE__ from './accordion-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
/* eslint-disable ember/no-side-effects */
import { isPresent } from '@ember/utils';
import EmberObject, { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { later } from '@ember/runloop';

export default Component.extend({
  panelActions: service(),
  groupName: computed('elementId', function () {
    return `accordion-component-${this.elementId}`;
  }),

  didReceiveAttrs() {
    this._super(...arguments);
    if (!isPresent(this.openSectionName)) {
      this.set('openSectionName', this.initiallyOpenSectionName);
    }
  },

  allSections: {},
  openSectionName: null,
  initiallyOpenSectionName: null,
  parentViewportClassName: null,
  isDisabled: false,

  sectionCanOpen: () => true,
  sectionCanClose: () => true,
  sectionDidOpen: () => {},
  sectionDidClose: () => {},

  publicAPI: computed('openSectionName', function () {
    return EmberObject.create({
      openSectionName: this.openSectionName,
      openSection: (...args) => this.send('openSection', ...args),
      closeSection: (...args) => this.send('closeSection', ...args),
      toggleSection: (...args) => this.send('toggleSection', ...args),
      toggleFullscreen: (...args) => this.send('toggleFullscreen', ...args),
      renameSection: (...args) => this.send('renameSection', ...args),
      removeSectionByName: (...args) => this.send('removeSectionByName', ...args),
    });
  }),

  privateAPI: computed('openSectionName', 'groupName', 'isDisabled', function () {
    return EmberObject.create({
      isDisabled: this.isDisabled,
      groupName: this.groupName,
      openSectionName: this.openSectionName,
      initiallyOpenSectionName: this.initiallyOpenSectionName,
      toggleSection: (...args) => this.send('toggleSection', ...args),
      registerSection: (section) => {
        this.allSections[section.get('externalName')] = section;
      },
      unregisterSection: (section) => {
        delete this.allSections[section.get('externalName')];
      },
    });
  }),

  _currentSectionCanClose() {
    let openSectionName = this.openSectionName;
    if (isPresent(openSectionName)) {
      return this.sectionCanClose(openSectionName);
    } else {
      return true;
    }
  },

  _currentSectionDidClose() {
    let openSectionName = this.openSectionName;
    if (isPresent(openSectionName)) {
      this.sectionDidClose(openSectionName);
    }
  },

  _openSection: task(function* (section) {
    let currentSectionCanClose = yield this._currentSectionCanClose();
    if (currentSectionCanClose && !this.isDisabled && !section.isDisabled) {
      let sectionCanOpen = yield this.sectionCanOpen(section.get('externalName'));
      if (sectionCanOpen) {
        this._handleParentViewportHeightShifting();
        this._currentSectionDidClose();
        section.set('isFullscreenToggled', false);
        this.panelActions.open(section.get('internalName'));
        this.set('openSectionName', section.get('externalName'));
        this.sectionDidOpen(section.get('externalName'));
      }
    }
  }).drop(),

  _closeSection: task(function* (section) {
    let canCloseSection = yield this.sectionCanClose(section.get('externalName'));
    if (canCloseSection) {
      this._handleParentViewportHeightShifting();
      this.panelActions.close(section.get('internalName'));
      this.set('openSectionName', null);
      this.sectionDidClose(section.get('externalName'));
      section.set('isFullscreenToggled', false);
    }
  }).drop(),

  // The following is a hack to account for situations where changing height of the viewport
  // causes the scroll position to jerk  This issue occurs within liquid-fire.
  // as different DOM states are computed, the scroll position is not preserved,
  // causing the scroll position to jump unexpectedly up and down. By setting an arbitrarily
  // large height on the parent viewport before the transition, scroll position is preserved
  // as the child elements animate.
  _handleParentViewportHeightShifting() {
    if (!this.parentViewportClassName) {
      return;
    }

    let viewportElement = document.querySelector(`.${this.parentViewportClassName}`);
    viewportElement.style.height = `${viewportElement.clientHeight * 3}px`;
    later(() => {
      viewportElement.style.height = '';
    });
  },

  _sectionForName(sectionExternalName) {
    return this.get(`allSections.${sectionExternalName}`);
  },

  actions: {
    openSection(sectionExternalName) {
      let section = this._sectionForName(sectionExternalName);
      if (!section.get('isCurrentOpenSection')) {
        this._openSection.perform(section);
      }
    },

    closeSection(sectionExternalName) {
      let section = this._sectionForName(sectionExternalName);
      if (section.get('isCurrentOpenSection')) {
        this._closeSection.perform(section);
      }
    },

    toggleSection(sectionExternalName) {
      let section = this._sectionForName(sectionExternalName);
      if (section.get('isCurrentOpenSection')) {
        this._closeSection.perform(section);
      } else {
        this._openSection.perform(section);
      }
    },

    toggleFullscreen() {
      let section = this._sectionForName(this.openSectionName);
      section.toggleProperty('isFullscreenToggled');
    },

    renameSection(oldSectionName, newSectionName) {
      let section = this.allSections[oldSectionName];
      section.set('name', newSectionName);

      delete this.allSections[oldSectionName];
      this.allSections[newSectionName] = section;
      if (this.openSectionName === oldSectionName) {
        this.set('openSectionName', newSectionName);
        this.sectionDidOpen(section.get('externalName'));
      }
    },

    removeSectionByName(sectionName) {
      delete this.allSections[sectionName];
      if (this.openSectionName === sectionName) {
        this.set('openSectionName', null);
      }
    },
  },
});
