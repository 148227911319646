/* import __COLOCATED_TEMPLATE__ from './relative-datetime.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
import ENV from 'embercom/config/environment';
import abbreviatedRelativeTime from 'embercom/lib/abbreviated-relative-time';
import { runTask, pollTask } from 'ember-lifeline';

export default Component.extend({
  tagName: 'time',
  attributeBindings: ['datetime'],
  didInsertElement() {
    this._super(...arguments);
    pollTask(this, 'update', `updatingTime#${this.elementId}`);
  },
  update(next) {
    this.notifyPropertyChange('formatted');
    runTask(this, next, ENV.APP._3000MS);
  },
  formatted: computed('datetime', function () {
    return abbreviatedRelativeTime(this.datetime);
  }),
});
