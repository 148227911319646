/* import __COLOCATED_TEMPLATE__ from './preview-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import type Checklist from 'embercom/models/checklists/checklist';
import { inject as service } from '@ember/service';
import { ChecklistScreen } from '../editor';
import type Store from '@ember-data/store';

interface Args {
  checklist: Checklist;
  isEditing: boolean;
  currentScreen: ChecklistScreen;
}

export default class PreviewWrapperComponent extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.setDefaultCompletionScreen();
  }

  private setDefaultCompletionScreen() {
    if (this.checklist.completionScreenBlocks.length === 0) {
      this.checklist.initCompletionScreen();
    }
  }

  get app() {
    return this.appService.app;
  }

  get checklist() {
    return this.args.checklist;
  }

  get onMainScreen() {
    return this.args.currentScreen === ChecklistScreen.Main;
  }

  get previewConfiguration() {
    return this.store.peekRecord('messenger-settings/all', this.app.id)?.previewConfiguration;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Editor::PreviewWrapper': typeof PreviewWrapperComponent;
    'checklist/editor/preview-wrapper': typeof PreviewWrapperComponent;
  }
}
