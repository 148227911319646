/* import __COLOCATED_TEMPLATE__ from './feature-comparison.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { getFeaturesData } from 'embercom/helpers/billing/migrations-helper';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import { action } from '@ember/object';

export interface AddonData {
  name: string;
  proactiveSupportPlusHCLink: string;
  tooltipContent: string;
}

interface Args {
  allFeatures: string[];
  availableFeaturesForApp: string[];
  availableEssentialFeatures: string[];
  availableAdvancedFeatures: string[];
  availableExpertFeatures: string[];
  availablePSPFeatures: string[];
  proactiveSupportFullPrice: number;
}

interface Signature {
  Args: Args;
}

export default class EarlyStageProgressionFeatureComparisonComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare purchaseAnalyticsService: $TSFixMe;
  @tracked searchValue = '';
  @tracked debouncedSearchValue = '';

  get tableData() {
    let data: any = [];
    let featuresData: any = getFeaturesData(
      {
        allFeatures: this.args.allFeatures,
        availableFeaturesForApp: this.args.availableFeaturesForApp,
        availableEssentialFeatures: this.args.availableEssentialFeatures,
        availableAdvancedFeatures: this.args.availableAdvancedFeatures,
        availableExpertFeatures: this.args.availableExpertFeatures,
        availablePSPFeatures: this.args.availablePSPFeatures,
      },
      this.intl,
      false,
      this.debouncedSearchValue,
    );

    data = featuresData.allData;

    data = data.sort((a: any, b: any) =>
      a.feature.localeCompare(b.feature, undefined, { sensitivity: 'base' }),
    );

    return data;
  }

  get proactiveSupportPlusData(): AddonData {
    return {
      name: this.intl.t('billing.migrations.proactive_support_plus'),
      proactiveSupportPlusHCLink: this.proactiveSupportPlusHCLink,
      tooltipContent: this.intl.t('billing.migrations.proactive_support_plus_tooltip', {
        price: this.args.proactiveSupportFullPrice,
        htmlSafe: true,
      }),
    };
  }

  get proactiveSupportPlusHCLink(): string {
    return 'https://www.intercom.com/help/en/articles/9061648-proactive-support-plus';
  }

  @action
  onChangeSearchValue() {
    debounce(this, this.updateSearchValue, ENV.APP.__250MS);
  }

  updateSearchValue() {
    this.debouncedSearchValue = this.searchValue;
    this.purchaseAnalyticsService.trackEvent({
      action: 'search',
      context: 'early_stage_progression',
      place: 'update_subscription',
      object: 'feature_comparison',
      value: this.debouncedSearchValue,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'billing/update-early-stage-subscription/feature-comparison': typeof EarlyStageProgressionFeatureComparisonComponent;
    'Billing::UpdateEarlyStageSubscription::FeatureComparison': typeof EarlyStageProgressionFeatureComparisonComponent;
  }
}
