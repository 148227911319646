/* import __COLOCATED_TEMPLATE__ from './icon-component.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import type ArticleGroup from 'embercom/models/articles/article-group';

interface Args {
  folder: ArticleGroup;
  selectedIcon: string;
  isScaled: boolean;
}

export default class IconComponent extends Component<Args> {
  get iconUrl() {
    return this.args.folder.iconUrl;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'articles/site/collections/icon-component': typeof IconComponent;
    'Articles::Site::Collections::IconComponent': typeof IconComponent;
  }
}
