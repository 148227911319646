/* import __COLOCATED_TEMPLATE__ from './edit-payment-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';

export default class EditPaymentComponent extends Component {
  @service customerService;
  @service modalService;
  @tracked cardModalIsOpen = false;

  get customer() {
    return this.customerService.customer;
  }

  get dayOfMonth() {
    // this date is arbitrary, but needs to be a full date, not just a number for the correct conversion
    // moment(15).format('Do') === 31 while moment('2019/1/15').format('Do') === 15
    return moment(`2010/1/${this.customer.get('subscription.invoiceDayOfMonth')}`).format('Do');
  }
}
