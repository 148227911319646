/* import __COLOCATED_TEMPLATE__ from './help-center-article-group-selector.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { trackedReset } from 'tracked-toolbox';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';
import type ArticleContent from 'embercom/models/articles/article-content';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { type HelpCenterOption } from 'embercom/components/knowledge-hub/content-editor/article-content/side-panel/help-center-selector';

interface HelpCenterArticleGroupSelectorArgs {
  onHelpCenterSelect: (helpCenterId: string, oldHelpCenterId?: string) => void;
  onCollectionSelect: (helpCenterId: string, collectionId: string) => void;
  removeTuple: (helpCenterId: string) => void;
  helpCenterOptions: HelpCenterOption[];
  collectionOptions: (helpCenterId: string) => any[];
  selectedTuple: [string, string] | [string, undefined];
  indexTuple: number;
  displayColumn?: boolean;
  displayModal?: boolean;
  readOnlyArticle?: boolean;
  enforceManageArticlesPermissions?: boolean;
  showHelpCenterUrl?: boolean;
  activeContent?: ArticleContent;
  onClickCreateNewCollection?: (hcId?: string) => void;
}

export default class HelpCenterArticleGroupSelector extends Component<HelpCenterArticleGroupSelectorArgs> {
  @tracked selectedHelpCenterId: string | undefined;
  @tracked selectedCollectionId: string | undefined;
  @trackedReset('args.selectedTuple') collectionOptions: any[] = this.args.selectedTuple?.[0]
    ? this.args.collectionOptions(this.args.selectedTuple?.[0])
    : [];
  @tracked selectedHelpCenterOptions: HelpCenterOption | undefined;
  @service declare intl: IntlService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare permissionsService: {
    checkPermission: Function;
  };

  constructor(owner: any, args: HelpCenterArticleGroupSelectorArgs) {
    super(owner, args);
    if (args.selectedTuple) {
      this.selectedHelpCenterId = args.selectedTuple[0];
      this.selectedCollectionId = args.selectedTuple[1];
      this.collectionOptions = args.collectionOptions(this.selectedHelpCenterId);
      this.selectedHelpCenterOptions = args.helpCenterOptions.find(
        (hc) => hc.value === this.selectedHelpCenterId,
      );
    }
  }

  get selectorIndex() {
    return this.args.indexTuple || 0;
  }

  get collectionDefaultLabel() {
    return this.collectionOptions.length > 0
      ? this.collectionPlaceHolder
      : this.intl.t('articles.editor.side-drawer.settings.no-collections');
  }

  get selectedLabel() {
    if (!this.selectedCollectionId) {
      return this.collectionDefaultLabel;
    }
    let option = this.collectionOptions.find(
      (option) => option.value === this.selectedCollectionId,
    );
    return option.text;
  }

  get collectionSelectorIsDisabled() {
    return this.args.readOnlyArticle || !this.collectionOptions.length;
  }

  get inKnowledgeHub() {
    return this.args.displayModal || this.args.displayColumn;
  }

  get helpCenterPlaceHolder() {
    return this.inKnowledgeHub
      ? this.intl.t(
          'knowledge-hub.content-editor.article-content.side-panel.help-center-section.help-center-placeholder',
        )
      : this.intl.t('articles.editor.side-drawer.settings.select-help-center');
  }

  get collectionPlaceHolder() {
    return this.inKnowledgeHub
      ? this.intl.t(
          'knowledge-hub.content-editor.article-content.side-panel.help-center-section.collection-placeholder',
        )
      : this.intl.t('articles.editor.side-drawer.settings.select');
  }

  get publicUrl() {
    return this.args.activeContent?.publicUrl;
  }

  get url() {
    if (
      !this.args.activeContent ||
      !this.args.activeContent.isPublished ||
      !this.selectedHelpCenterId
    ) {
      return null;
    }
    if (this.args.activeContent.externalUrl) {
      return this.args.activeContent.externalUrl;
    }
    if (!this.args.activeContent.publicUrl) {
      return null;
    }
    let id = `/articles/${this.args.activeContent.publicUrl.split('/articles/')[1]}`;
    let locale = this.args.activeContent.locale;
    return `${this.selectedHelpCenterOptions?.url}/${locale}${id}`;
  }

  get shouldShowLink() {
    return (
      this.selectedHelpCenterOptions?.turnedOn && this.selectedHelpCenterOptions?.websiteTurnedOn
    );
  }

  @action onClickCreateCollection() {
    if (this.args.onClickCreateNewCollection) {
      this.args.onClickCreateNewCollection(this.selectedHelpCenterId);
    }
  }

  @action async onHelpCenterSelect(helpCenterId: string) {
    try {
      await this.maybeEnforcePermissions();
    } catch (e) {
      return;
    }
    let oldHelpCenterId = this.selectedHelpCenterId;
    this.selectedHelpCenterId = helpCenterId;
    this.args.onHelpCenterSelect(helpCenterId, oldHelpCenterId);

    this.collectionOptions = this.args.collectionOptions(helpCenterId);
    if (this.collectionOptions.length === 0) {
      return;
    }
    this.selectedCollectionId = this.collectionOptions.filter(
      (option: any) => option.isSelected,
    )[0]?.value;
  }

  @action async onCollectionSelect(collectionId: string) {
    try {
      await this.maybeEnforcePermissions();
    } catch (e) {
      return;
    }
    if (this.selectedHelpCenterId) {
      this.selectedCollectionId = collectionId;
      this.args.onCollectionSelect(this.selectedHelpCenterId, collectionId);
    }
  }

  @action async removeTuple() {
    try {
      await this.maybeEnforcePermissions();
    } catch (e) {
      return;
    }
    if (this.selectedHelpCenterId) {
      this.args.removeTuple(this.selectedHelpCenterId);
      this.selectedCollectionId = undefined;
      this.selectedHelpCenterId = undefined;
      this.collectionOptions = [];
    }
  }

  @action trackLinkClicked() {
    this.knowledgeHubEditorService.trackAnalyticsEvent('clicked_help_center_link');
  }

  @action trackLinkCopied() {
    this.knowledgeHubEditorService.trackAnalyticsEvent('copied_help_center_link');
  }

  private async maybeEnforcePermissions() {
    if (this.args.enforceManageArticlesPermissions) {
      await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Editor::SettingsSideDrawer::HelpCenterArticleGroupSelector': typeof HelpCenterArticleGroupSelector;
    'articles/editor/settings-side-drawer/help-center-article-group-selector': typeof HelpCenterArticleGroupSelector;
  }
}
