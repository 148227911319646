/* import __COLOCATED_TEMPLATE__ from './charge-breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { type PlanCardRowsByCharge } from 'embercom/components/billing/summary/price-usage-breakdown-card';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Contract from 'embercom/models/billing/contract';
import type Charge from 'embercom/models/billing/price/charge';
import { SMS_BASE_ID } from 'embercom/lib/billing';
import type Plan from 'embercom/models/plan';
import { Metric } from 'embercom/models/data/pricing/metric-types';

interface Args {
  planId: number;
  charge: Charge;
  shouldHidePrices: boolean;
  allRowsByCharge: PlanCardRowsByCharge; // Deprecated - We're trying to move away from rows. Use the other args to generate the relevant content instead
  contract?: Contract;
  plan: Plan;
  onPricing5_XPlan: boolean;
  supportsCollapsibleBreakdownCharges: boolean;
}

export default class ChargeBreakdown extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service customerService: any;

  get isSalesforceContracted() {
    return this.appService.app.isSalesforceContracted;
  }

  get plan() {
    return this.args.plan;
  }

  get rows() {
    return this.args.allRowsByCharge[this.pricingMetric.metric];
  }

  get pricingMetric() {
    return new PricingMetric(this.args.charge, this.intl);
  }

  get isFin() {
    return this.pricingMetric.isResolutionsMetric;
  }

  get finPricingLearnMoreUrl() {
    if (this.pricingMetric.metric === Metric.resolutions_with_custom_answers) {
      // pp5_todo: This link should point to a subsection in the article. Just like the existing one
      return 'https://www.intercom.com/help/en/articles/8205718-fin-resolutions-and-custom-answers';
    } else {
      return 'https://www.intercom.com/help/en/articles/7837512-fin-pricing#h_156049b838';
    }
  }

  get isLinearPriceWithoutInclusions() {
    return this.pricingMetric.hasLinearPricing && this.args.charge.includedUsage === 0;
  }

  get shouldUseBillingCycleVariation() {
    // This is temporarily duplicated here and in the PriceUsageBreakdownCards component, while
    // we refactor that component to remove the logic. This will be the source of truth in future, but we will need
    // to update both with any changes in the meantime.
    // We want to show this for self serve and primary contract customers (but not secondary)
    // We've excluded SMS from this logic as updated it's UI was too complex and scoped out
    return (
      this.args.onPricing5_XPlan ||
      (this.customerService.customer.showBillingCycleMetricsInBillingSummary &&
        (this.args.contract?.isPrimarySubscription || !this.isSalesforceContracted) &&
        this.pricingMetric.usesBillingCycleMetric &&
        String(this.args.planId) !== SMS_BASE_ID)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::ChargeBreakdown': typeof ChargeBreakdown;
  }
}
