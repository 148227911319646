/* import __COLOCATED_TEMPLATE__ from './warning-banners-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import readableProducts from 'embercom/lib/readable-products';
import { EARLY_STAGE_PRODUCTS } from 'embercom/lib/billing';

export default class WarningBannersComponent extends Component {
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  @service modalService;
  @service cardlessConversionModalService;
  @service customerService;
  @tracked cardModalIsOpen = false;

  get showIcon() {
    return this.args.showIcon ?? true;
  }

  get earlyStageProducts() {
    this.customerService.products.filter((product) => {
      return EARLY_STAGE_PRODUCTS.includes(product.id);
    });
  }

  get formattedEarlyStageProducts() {
    return readableProducts(this.earlyStageProducts);
  }

  get subscriptionIsSalesforceContracted() {
    return this.args.customer.subscription.isSalesforceContracted;
  }

  get hasActiveBillingAdminTrialSubscription() {
    return this.args.customer.hasActiveBillingAdminTrialSubscription;
  }

  @action showResumePendingCancellationModal() {
    this.modalService.openModal('billing/resume-pending-cancellation-modal', this.args.customer);
  }

  @action showCardlessConversionModalPricing5() {
    this.cardlessConversionModalService.toggle();
  }

  get stripeMigration() {
    return this.customerService.peekStripeMigration;
  }

  get shouldDisplayCancellationBanner() {
    return (
      this.args.customer.subscriptionWillBeCancelled &&
      !this.args.customer.hasSucceedingSubscription &&
      !this.stripeMigration?.isPending
    );
  }
}
