/* import __COLOCATED_TEMPLATE__ from './answer-list-item-training-cell.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AnswerListItemTrainingCell extends Component {
  @service permissionsService;

  get currentAdminCanEditBots() {
    return this.permissionsService.currentAdminCan('can_create_and_edit_bots');
  }

  get showLoadingIndicator() {
    return (
      this.trainingInProgress && this.args.extraArgs.answerInTraining?.id === this.args.answer.id
    );
  }

  get trainingInProgress() {
    return this.args.extraArgs.answerInTraining !== null;
  }

  @action
  trainAnswer() {
    this.args.extraArgs.trainExistingAnswer(this.args.answer);
  }
}
