/* import __COLOCATED_TEMPLATE__ from './themed-styles.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  themeColor: string;
}

const ThemedStyles = templateOnlyComponent<Args>();
export default ThemedStyles;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Shared::ThemedStyles': typeof ThemedStyles;
    'articles/shared/themed-styles': typeof ThemedStyles;
  }
}
