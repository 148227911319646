/* import __COLOCATED_TEMPLATE__ from './selectable-list.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { filterBy, readOnly } from '@ember/object/computed';
import { action } from '@ember/object';
import Component from '@ember/component';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  list: notImplemented('selectable-list: You must set the "list" property on the component'),
  selectedItems: filterBy('list', 'selected', true),
  selectedCount: readOnly('selectedItems.length'),

  updateSelection: action(function (selectedItem, value) {
    selectedItem.set('selected', value);
  }),
});
