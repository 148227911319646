/* import __COLOCATED_TEMPLATE__ from './verify-email-button.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly, not } from '@ember/object/computed';
import { task } from 'ember-concurrency';

export default Component.extend({
  intercomEventService: service(),
  appService: service(),
  notificationsService: service(),
  app: readOnly('appService.app'),
  currentAdmin: readOnly('app.currentAdmin'),
  emailVerificationSent: readOnly('currentAdmin.current_email_pending_verification'),
  disableVerificationButton: not('currentAdmin.canResendVerificationEmail'),

  sendVerificationEmail: task(function* () {
    try {
      yield this.currentAdmin.sendValidationEmailWithRetry();
      this.notificationsService.notifyConfirmation(
        'Verification email sent. Please check your email.',
      );
    } catch (e) {
      this.notificationsService.notifyError(
        'Something went wrong sending your verfication email. Please try again.',
      );
    }
  }),
});
