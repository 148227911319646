/* import __COLOCATED_TEMPLATE__ from './tree.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type Tree } from 'embercom/objects/tree-list';
import { ItemPoint } from './tree-list/tree-item';
import { type InsertionPoint } from './tree-list/insertion-point';

interface Args {
  tree: Tree;
  foldersLoading?: boolean;
}

interface DistanceFromPoint {
  elementReference: ItemPoint | InsertionPoint;
  distance: number;
}

let distanceFromPoint = (
  elementReference: ItemPoint | InsertionPoint,
  event: DragEvent,
): DistanceFromPoint => {
  let { y, bottom } = elementReference.element.getBoundingClientRect();
  let insertionPointY = (y + bottom) / 2;
  return { distance: Math.abs(event.clientY - insertionPointY), elementReference };
};

let findMinDistancePoint = (prev: DistanceFromPoint, current: DistanceFromPoint) =>
  current.distance < prev.distance ? current : prev;
export default class TreeComponent extends Component<Args> {
  elementRef?: HTMLElement = undefined;

  @action
  onDragOver(event: DragEvent) {
    if (this.args.tree.settings.dragProperties.onDrag) {
      this.args.tree.settings.dragProperties.onDrag(event.clientY);
    }
    let closestInsertionPoint = this.args.tree.insertionPoints
      .map((insertionPoint: InsertionPoint) => distanceFromPoint(insertionPoint, event))
      .reduce(findMinDistancePoint);
    let isCloseEnough =
      closestInsertionPoint.distance < this.args.tree.settings.dragProperties.minDistanceInPX;
    if (isCloseEnough) {
      this.args.tree.setDroppingPoint(closestInsertionPoint.elementReference);
    } else {
      let closestItemPoint = this.args.tree.itemPoints
        .map((itemPoint: ItemPoint) => distanceFromPoint(itemPoint, event))
        .reduce(findMinDistancePoint);
      this.args.tree.setDroppingPoint(
        new ItemPoint(closestItemPoint.elementReference as ItemPoint),
      );
    }
    event.preventDefault();
  }

  @action
  onDragEnter(event: DragEvent) {
    event.preventDefault();
  }

  @action
  onDrop() {
    this.args.tree.dropItemOnSelectedDroppingPoint();
    this.args.tree.selectedDroppingPoint = undefined;
  }

  @action
  insertReference(htmlElement: HTMLElement) {
    this.elementRef = htmlElement;
  }

  // when the mouse leaves the container, cleanup the latest selected dropping point
  @action
  onDragLeave(event: DragEvent) {
    if (this.elementRef) {
      let { top, bottom } = this.elementRef.getBoundingClientRect();
      if (event.clientY <= top || event.clientY >= bottom) {
        this.args.tree.selectedDroppingPoint = undefined;
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::Tree': typeof TreeComponent;
    'common/tree': typeof TreeComponent;
  }
}
