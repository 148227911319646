/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import animateAddScreen from 'embercom/lib/carousels/add-screen-animation';

export default class CarouselEditorComponent extends Component {
  @service attributeService;
  @service appService;
  @service store;
  @service intercomEventService;
  @service contentEditorService;
  @service intercomConfirmService;

  @tracked currentScreenIndex = null;
  @tracked currentDevice = 'ios';
  @tracked currentCarouselContent;

  constructor() {
    super(...arguments);
    this.setupCurrentModel();
  }

  get appBaseColor() {
    return this.appService.app.base_color;
  }

  @action
  setupCurrentModel() {
    if (this.args.carousel.localizedCarouselContents) {
      this.currentCarouselContent = this.args.carousel.localizedCarouselContents.firstObject;
    }

    if (this.args.carousel.hasIosAsPreferredDevice) {
      this.currentDevice = 'ios';
    } else {
      this.currentDevice = 'android';
    }
  }

  get currentScreen() {
    if (this.currentCarouselContent) {
      return this.currentCarouselContent.carouselScreens.objectAt(this.currentScreenIndex);
    } else {
      return undefined;
    }
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  @action
  addScreen() {
    if (this.currentCarouselContent.carouselScreens.length === 10) {
      return;
    }
    this.currentCarouselContent.addScreen();
    this.currentCarouselContent = this.currentCarouselContent;
    animateAddScreen(!this.currentScreen);
  }

  @action
  setCurrentCarouselContent(carouselIndex) {
    this.currentScreenIndex = carouselIndex;
  }

  @task *setCurrentCarouselContentIfUploadsFinished(carouselIndex) {
    if (this.contentEditorService.isUploadingFile) {
      let confirmation = yield this.intercomConfirmService.confirm({
        title: 'Wait for upload to finish?',
        body: `The image you added is still uploading. Leaving this screen before the upload finishes will discard it.`,
        confirmButtonText: 'Discard Changes',
      });
      if (!confirmation) {
        return;
      }
    }
    this.setCurrentCarouselContent(carouselIndex);
  }

  @action setBackgroundColor(color) {
    this.currentCarouselContent.carouselScreens.forEach((screen) => {
      screen.backgroundColor = color;
    });
    this.args.carousel.backgroundColor = color;
    this.currentCarouselContent = this.currentCarouselContent;
  }

  @action
  setTextColor(color) {
    this.currentCarouselContent.carouselScreens.forEach((screen) => {
      screen.textColor = color;
    });
    this.args.carousel.textColor = color;
    this.currentCarouselContent = this.currentCarouselContent;
  }

  @action
  setActionColor(color) {
    this.args.carousel.actionsColor = color;
    this.currentCarouselContent.carouselScreens.forEach((screen) => {
      screen.carouselActions.forEach((action) => {
        let generatedColors = action.isPrimary
          ? screen.generatePrimaryActionColors()
          : screen.generateSecondaryActionColors();
        action.backgroundColor = generatedColors.backgroundColor;
        action.textColor = generatedColors.textColor;
      });
    });
    this.currentCarouselContent = this.currentCarouselContent;
  }

  @action onVerticalAlignmentChange(alignment) {
    this.args.carousel.verticalAlignment = alignment;
    this.currentCarouselContent.carouselScreens.forEach((screen) => {
      screen.verticalAlignment = alignment;
    });
    this.currentCarouselContent = this.currentCarouselContent;
  }

  @action reorderScreens(localizedCarouselContent, screens) {
    screens.forEach((screen, index) => screen.set('order', index));
    screens.pushObject(this.currentCarouselContent.getLastScreen());
    localizedCarouselContent.reorderScreens(screens);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'mobile_carousel_screen_reordered',
      object: 'carousel_screen',
      owner: 'team-messenger',
      place: 'carousel_editor_create',
      metadata: {
        ruleset_id: this.ruleset.id,
        number_of_screens: screens.length,
      },
    });
  }

  @action updateCurrentPreviewDevice(device) {
    this.currentDevice = device;
    let place = 'carousel_editor_create';
    if (this.currentScreen) {
      place = 'carousel_editor_screen';
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'mobile_carousel_preview_changed',
      object: `carousel_preview_${device}`,
      owner: 'team-messenger',
      place,
      metadata: { ruleset_id: this.ruleset.id },
    });
  }

  @task *onScreenDelete(index) {
    let confirmation = yield this.intercomConfirmService.confirm({
      title: 'Delete this screen?',
      body: `This action is permanent and can't be undone.`,
      confirmButtonText: 'Delete',
    });
    if (confirmation) {
      this.currentCarouselContent.removeScreen(index);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'mobile_carousel_screen_deleted',
        object: 'carousel_screen',
        owner: 'team-messenger',
        place: 'carousel_editor_create',
        metadata: { ruleset_id: this.ruleset.id },
      });
    }
  }
}
