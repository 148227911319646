/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Checklist from 'embercom/models/checklists/checklist';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { action } from '@ember/object';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { type Block } from 'embercom/models/common/blocks/block';
import type Task from 'embercom/models/checklists/task';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';

class Config extends BaseConfig {
  autoFocus = false;
  textDirection: NotificationDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  allowedInline = [];
  allowTextAlignment = false;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  attributes: any;
  templating: { picker: string; resolver: AttributeInfoResolver };
  singleBlockMode = true;
  maxCharacterCount: number;

  constructor(app: any, placeholder: string, maxCharacterCount: number) {
    super();

    this.attributes = app.allowedAttributes;
    this.placeholder = placeholder;
    this.maxCharacterCount = maxCharacterCount;

    this.templating = {
      picker: 'common/attribute-picker',
      resolver: new AttributeInfoResolver({
        attributes: this.attributes,
        includeAppAttributes: false,
      }),
    };
  }
}

interface Args {
  checklist: Checklist;
  tasks: Task[];
  shouldShowValidations: boolean;
}

export default class ChecklistSettings extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @tracked selectedSenderType = this.checklist.senderType;
  @tracked titleBlocksDoc: BlocksDocument;
  @tracked subtitleBlocksDoc: BlocksDocument;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.titleBlocksDoc = new BlocksDocument(this.checklist.titleBlocks.toArray());
    this.subtitleBlocksDoc = new BlocksDocument(this.checklist.subtitleBlocks.toArray());
  }

  get app() {
    return this.appService.app;
  }

  get checklist() {
    return this.args.checklist;
  }

  get firstTaskGroup() {
    return this.checklist.firstTaskGroup;
  }

  get tasks() {
    return this.firstTaskGroup?.nonDeletedTasks;
  }

  get numberOfTasks() {
    if (!this.tasks) {
      return 0;
    } else {
      return this.tasks.length;
    }
  }

  get titleComposerConfig() {
    let placeholder = this.intl.t(
      'outbound.checklists.checklist-settings.checklist-title-placeholder',
    );
    return new Config(this.app, placeholder, 70);
  }

  get subtitleComposerConfig() {
    let placeholder = this.intl.t(
      'outbound.checklists.checklist-settings.checklist-subtitle-placeholder',
    );
    return new Config(this.app, placeholder, 90);
  }

  get showNoTitleValidation() {
    return this.args.shouldShowValidations && !this.checklist.hasTitle;
  }

  @action updateTitleBlocks(blocksDoc: BlocksDocument) {
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.checklist.set('titleBlocks', blockFragments);
    this.checklist.notifyPropertyChange('titleBlocks');
  }

  @action updateSubtitleBlocks(blocksDoc: BlocksDocument) {
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.checklist.set('subtitleBlocks', blockFragments);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Editor::Preview::Header': typeof ChecklistSettings;
    'checklist/editor/preview/header': typeof ChecklistSettings;
  }
}
