/* import __COLOCATED_TEMPLATE__ from './addon-card.hbs'; */
/* RESPONSIBLE TEAM: team-billing */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';

interface Args {
  name: string;
  planId: string;
  price: number | undefined;
  planData: any;
  item: any;
  includedUsage: number | undefined;
  hcLink: string;
  onClick: (planId: string, alreadySelected: boolean) => void;
  pricingMetric: string | undefined;
  isSelected?: boolean;
  isDisabled: boolean;
  addonInfo: string | undefined;
  isRecommended?: boolean;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationAddonCardComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @tracked isSelected = this.args.isSelected ?? false;
  @tracked isRecommended = this.args.isRecommended ?? false;

  @action
  selectAddon(planId: string, alreadySelected: boolean, event: any) {
    this.args.onClick(planId, alreadySelected);
    this.isSelected = !this.isSelected;
    event.stopPropagation();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::AddonCard': typeof BillingMigrationAddonCardComponent;
  }
}
