/* import __COLOCATED_TEMPLATE__ from './message-style-popover-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-jquery */
import { schedule } from '@ember/runloop';
import $ from 'jquery';
import { on } from '@ember/object/evented';
import EmberObject, { computed, observer } from '@ember/object';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import LegacyOverlayBaseComponent from 'embercom/components/legacy-overlay-base-component';
import { ternary } from '@intercom/pulse/lib/computed-properties';

const EXCLUDED_TEMPLATE_IDS = ['html-block-template', 'official'];
const FALLBACK_TEMPLATE_ID = 'plain';

let MessageStylePopover = LegacyOverlayBaseComponent.extend({
  store: service(),
  extraClassNames: ['message-popover__container', 'o__fixed', 'js__popover__message-style'],
  caretOffset: 10,
  showCaret: true,
  isOpen: false,
  smallAnnouncementLabel: 'Note',
  bigAnnouncementLabel: 'Post',

  chatIcon: 'chat-bubble-line',
  smallAnnouncementIcon: 'inapp-note',
  bigAnnouncementIcon: 'inapp-post',

  channelType: ternary('isEmail', 'Email Template', 'Message Type'),
  isEmail: equal('messageStyle', 'email'),
  isChat: equal('messageStyle', 'chat'),
  isSmallAnnouncement: equal('messageStyle', 'small-announcement'),
  isBigAnnouncement: equal('messageStyle', 'announcement'),
  openerElementSelector: computed('parentElementId', function () {
    return `#${this.parentElementId} .js__popover-opener__message-style`;
  }),
  popoverElementSelector: computed('parentElementId', function () {
    return `#${this.parentElementId} .js__popover__message-style.js__popover`;
  }),
  filteredEmailTemplates: computed('app.email_templates.[]', function () {
    return this.store
      .peekAll('email-template-titles')
      .filter(
        (template) => this._keepEmailTemplate(template.get('id')) && template.type === 'html',
      );
  }),
  defaultEmailTemplateId: computed('app.default_email_template_id', function () {
    let defaultTemplate = this.app.default_email_template_id;

    if (EXCLUDED_TEMPLATE_IDS.includes(defaultTemplate)) {
      return FALLBACK_TEMPLATE_ID;
    }

    return defaultTemplate;
  }),
  emailTemplates: computed('emailTemplateId', 'filteredEmailTemplates.[]', function () {
    return this.filteredEmailTemplates.map((template) => {
      return EmberObject.create({
        id: template.get('id'),
        name: template.get('name'),
        isSelected: template.get('id') === this.emailTemplateId,
        isDefault: template.get('id') === this.defaultEmailTemplateId,
      });
    });
  }),
  resetEmailTemplateId: on('didInsertElement', function () {
    this.set('emailTemplateId', this.defaultEmailTemplateId);
  }),
  closePopover() {
    this.set('isOpen', false);
  },
  _keepEmailTemplate(templateId) {
    return !EXCLUDED_TEMPLATE_IDS.includes(templateId);
  },
  _scrollToSelectedItem() {
    schedule('afterRender', this, function () {
      let $popoverList = $('.message-popover__list', this.element);
      let $selectedItem = $popoverList.find('.js__message-popover__dropdown__list-item-selected');
      if ($selectedItem.length) {
        let position = $selectedItem.position().top;
        let reasonableScrollLimit = $popoverList.height() - $selectedItem.height();
        if (position > reasonableScrollLimit) {
          $popoverList.scrollTop(position);
        }
      }
    });
  },
  setupInitialScrollPosition: observer({
    dependentKeys: ['isOpen'],

    fn() {
      if (this.isOpen) {
        this._scrollToSelectedItem();
      }
    },

    sync: true,
  }),
  actions: {
    setEmailTemplate(emailTemplateId) {
      this.setEmailTemplate(emailTemplateId);
      this.closePopover();
    },
    setInAppStyle(inAppStyle) {
      this.setMessageType(inAppStyle);
      this.closePopover();
    },
  },
});

export default MessageStylePopover;
