/* import __COLOCATED_TEMPLATE__ from './audience-targeting-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { copy } from 'ember-copy';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AudienceTargetingSideDrawer extends Component {
  @service helpCenterService;

  @tracked ruleset = this.args.article.ruleset;
  @tracked articleGroup = this.args.article.folder;

  @action
  onUpdatePredicates(predicates) {
    this.ruleset.set('predicateGroup.predicates', copy(predicates));
    this.ruleset.get('fetchAudiencePreviewTask').perform();
  }

  @action
  onUpdateRolePredicates(rolePredicates) {
    this.ruleset.set('rolePredicateGroup.predicates', copy(rolePredicates));
    this.ruleset.get('fetchAudiencePreviewTask').perform();
  }

  get segmentedContentTurnedOff() {
    return !this.helpCenterService.site.segmentedContentTurnedOn;
  }

  get userTypeDescription() {
    let userTypeFromAudiencePreview =
      this.ruleset.get('audiencePreview.predicateGroup.userType') ||
      this.ruleset.get('audiencePreview.rolePredicateGroup.userType');
    let userType = userTypeFromAudiencePreview || this.ruleset.userType || 'user';
    if (userType === 'user') {
      return 'user';
    } else if (userType === 'lead') {
      return 'lead';
    }
    return 'person';
  }

  get collectionsList() {
    return this.args.home.get('children').map((collection) => ({
      text: collection.name,
      value: collection.id,
      icon: collection.icon,
      component: 'articles/editor/collection-select-item',
    }));
  }

  get showSectionsDropdown() {
    let parentIsCollectionWithSections =
      this.articleGroup.get('isCollection') && this.articleGroup.get('hasChildren');
    return this.articleGroup.get('isANonHomeSection') || parentIsCollectionWithSections;
  }

  get sectionsInSelectedCollection() {
    return this.args.article.collection.get('children').map((section) => ({
      text: section.name,
      value: section.id,
    }));
  }
}
