/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Range from 'embercom/models/reporting/range';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { extractUnversionedId } from 'embercom/helpers/content-versioning';

const CAROUSEL_SCREEN_VIEWS_SIGNAL_CONFIG = {
  name: 'default_query',
  debug_name: 'carousel_screen_views',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'stat_screen_view.id',
};

const CAROUSEL_BUTTON_TAP_SIGNAL_CONFIG = {
  name: 'default_query',
  debug_name: 'carousel_button_action_tapped',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'stat_button_tap.id',
};

const CAROUSEL_PERMISSION_GRANT_SIGNAL_CONFIG = {
  name: 'default_query',
  debug_name: 'carousel_permission_granted',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'stat_permission_grant.id',
};

export default class Main extends Component {
  @service appService;
  @service reportingService;
  @service contentEditorService;
  @tracked screenViewStats = {};

  constructor() {
    super(...arguments);
    this.updateScreenViewStats.perform();
  }

  @task
  *updateScreenViewStats() {
    let stats = yield this.fetchReportingServiceStats.perform();
    this.buildScreenViewStats(stats);
  }

  @task
  *fetchReportingServiceStats() {
    let selectedVersion = this.contentEditorService.selectedVersion;
    let filters;
    let rangeStart;
    if (selectedVersion) {
      let carouselId = extractUnversionedId(this.args.carousel.id);
      filters = { 'carousel.id': carouselId, 'ruleset_version.id': selectedVersion.id };
      rangeStart = new Date(selectedVersion.createdAt);
    } else {
      filters = { 'carousel.id': this.args.carousel.id };
      rangeStart = new Date(this.args.carousel.updatedAt);
    }
    let range = Range.createFromParams(rangeStart, null, null);
    let aggregations = [{ grouping: 'carousel_screen.id', interval: 1 }];
    let viewData = yield this.reportingService.fetchNumericSignal(
      CAROUSEL_SCREEN_VIEWS_SIGNAL_CONFIG,
      'views',
      range,
      aggregations,
      filters,
    );
    let buttonData = yield this.reportingService.fetchNumericSignal(
      CAROUSEL_BUTTON_TAP_SIGNAL_CONFIG,
      'views',
      range,
      aggregations,
      filters,
    );
    let permissionData = yield this.reportingService.fetchNumericSignal(
      CAROUSEL_PERMISSION_GRANT_SIGNAL_CONFIG,
      'views',
      range,
      aggregations,
      filters,
    );
    let stats = {};
    viewData.context.forEach(({ key, value }) => {
      stats[key] = { viewCount: value };
    });
    buttonData.context.forEach(({ key, value }) => {
      stats[key] = Object.assign({ buttonCount: value }, stats[key] || {});
    });
    permissionData.context.forEach(({ key, value }) => {
      stats[key] = Object.assign({ permissionCount: value }, stats[key] || {});
    });
    return stats;
  }

  buildScreenViewStats(reportingServiceStats) {
    let screenViewStats = {};
    let firstScreenId = this.getScreenId(this.args.screens.firstObject);
    let firstScreen = reportingServiceStats[firstScreenId];
    let firstCount = firstScreen ? firstScreen.viewCount : undefined;
    this.args.screens.forEach((screen) => {
      let screenId = this.getScreenId(screen);
      let { viewCount, buttonCount, permissionCount } = reportingServiceStats[screenId] || {
        viewCount: 0,
        buttonCount: 0,
        permissionCount: 0,
      };
      let percentage = firstCount ? (100 * viewCount) / firstCount : undefined;
      let buttonPercentage = firstCount ? (100 * buttonCount) / firstCount : undefined;
      let permissionPercentage = firstCount ? (100 * permissionCount) / firstCount : undefined;
      screenViewStats[screenId] = {
        viewCount,
        buttonCount,
        permissionCount,
        percentage,
        buttonPercentage,
        permissionPercentage,
      };
    });
    this.screenViewStats = screenViewStats;
  }

  getScreenId(screen) {
    if (this.contentEditorService.selectedVersion) {
      return extractUnversionedId(screen.id);
    }
    return screen.id;
  }

  get shouldRenderScreenStats() {
    if (
      this.appService.app.hasVersioningBillingFeature &&
      this.contentEditorService.selectedVersion
    ) {
      return true;
    }
    if (!this.appService.app.hasVersioningBillingFeature) {
      return true;
    }
    return false;
  }

  get shouldRenderVersionPrompt() {
    return (
      this.appService.app.hasVersioningBillingFeature && !this.contentEditorService.selectedVersion
    );
  }
}
