/* import __COLOCATED_TEMPLATE__ from './guidance.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {}

const AiAgentGuidance = templateOnlyComponent<Args>();
export default AiAgentGuidance;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Guidance': typeof AiAgentGuidance;
  }
}
