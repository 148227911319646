/* import __COLOCATED_TEMPLATE__ from './usage-in-billing-cycle-charge.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import PricingMetric, { ChargeModel } from 'embercom/lib/purchase/pricing-metric';
import { isPeopleReachedPlan } from 'embercom/lib/billing/plans';
import type Charge from 'embercom/models/billing/price/charge';
import type IntlService from 'embercom/services/intl';
import type Contract from 'embercom/models/billing/contract';
import { TierPriceFormat } from 'embercom/models/data/pricing/tier-types';
import { Metric } from 'embercom/models/data/pricing/metric-types';

interface Args {
  planId: number;
  charge: Charge;
  contract?: Contract;
  onPricing5_XPlan: boolean;
  supportsCollapsibleBreakdownCharges: boolean;
}

export default class UsageInBillingCycleCharge extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service customerService: any;

  get app() {
    return this.appService.app;
  }

  get usageIncludedTitle(): string {
    if (this.customerService.onPricing5_X) {
      return this.pricingMetric.pricing5UsageIncludedText();
    } else {
      return this.pluralPricingMetricString;
    }
  }

  get pluralPricingMetricString() {
    return this.pricingMetric.pluralize(100)!;
  }

  get singularPricingMetricString() {
    return this.pricingMetric.pluralize(1)!;
  }

  get baseUsage() {
    return this.args.charge.base_usage;
  }

  get actualUsage() {
    if (this.args.contract) {
      return this.args.contract.totalUsage[this.args.charge.pricing_metric]!;
    }
    return this.args.charge.actual_usage;
  }

  get shouldRenderOverageTooltip() {
    return this.customerService.onPricing5_X && this.pricingMetric.metric !== Metric.calling;
  }

  get shouldHideAdditionalUsage() {
    return this.customerService.onPricing5_X && this.pricingMetric.metric === Metric.calling;
  }

  get overageFound() {
    return this.actualUsage > this.baseUsage;
  }

  get baseFee() {
    return this.pricingMetric.tiers[0].price * 100;
  }

  get shouldShowBaseFee() {
    return this.customerService.onPricing5_X && this.isOnlyInitialTierHasFlatFee;
  }

  get hideIncludedUsagePrice() {
    return (
      this.shouldShowBaseFee &&
      this.pricingMetric.tiers[0].ending_unit === this.args.charge.base_usage
    );
  }

  get includedUsagePrice() {
    if (this.shouldShowBaseFee) {
      return this.args.charge.base_price - this.baseFee;
    } else {
      return this.args.charge.base_price;
    }
  }

  private get isOnlyInitialTierHasFlatFee() {
    return (
      this.pricingMetric.tiers?.length >= 2 &&
      this.pricingMetric.tiers[0].price_format === TierPriceFormat.FlatFee &&
      this.pricingMetric.tiers
        .slice(1)
        .every((tier) => tier.price_format === TierPriceFormat.PerUnit)
    );
  }

  get usageDifference() {
    return Math.max(this.actualUsage - this.baseUsage, 0);
  }

  get overageCostDifference() {
    return Math.max(this.args.charge.price - this.args.charge.base_price, 0);
  }

  get pricingMetric() {
    return new PricingMetric(this.args.charge, this.intl);
  }

  get perUnitPrice() {
    if (!this.pricingMetric.hasLinearPricing) {
      return;
    }
    // Resolutions on VBP 2.2 has a free first tier with usage included but has unit pricing after that
    if (this.pricingMetric.chargeModel === ChargeModel.Tiered) {
      return this.pricingMetric.tiers[1].price;
    }
    return this.pricingMetric.perUnitPrice / 100;
  }

  get chargeTierText() {
    let relevantTier = this.pricingMetric.getTierForUsage(this.actualUsage);
    if (!relevantTier) {
      return;
    }
    if (relevantTier.price_format !== TierPriceFormat.PerUnit) {
      return this.intl.formatNumber(relevantTier.price, {
        format: 'USDallowingFractionCents',
      });
    }

    let price = this.intl.formatNumber(relevantTier.price * this.pricingMetric.blockSize, {
      format: 'USDallowingFractionCents',
    });
    let unit =
      this.pricingMetric.blockSize === 1
        ? this.perAdditionalCostText
        : this.intl.formatNumber(this.pricingMetric.blockSize);

    return this.intl?.t('billing.pricing-metric.price-per-additional-unit', { price, unit });
  }

  private get perAdditionalCostText(): string {
    if (this.customerService.onPricing5_X) {
      return this.pricingMetric.pricing5PerAdditionalCostText();
    } else {
      return this.singularPricingMetricString;
    }
  }

  get isPeopleReachedPlan() {
    return isPeopleReachedPlan(this.args.planId.toString());
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::UsageInBillingCycleCharge': typeof UsageInBillingCycleCharge;
  }
}
