/* import __COLOCATED_TEMPLATE__ from './upload-profile-picture.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { action, computed } from '@ember/object';
import { gt, and } from '@ember/object/computed';
import Component from '@ember/component';
import ImageUploadComponent from 'embercom/components/mixins/image-upload-component';
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend(ImageUploadComponent, {
  appService: service(),
  notificationsService: service(),
  intercomEventService: service(),
  intl: service(),
  fileIsLargerThanOneMB: gt('file.size', 1000000),
  fileIsTooLarge: and('fileIsLargerThanOneMB', 'hasFile'),
  existingPictureUrl: computed('whatsappIntegration.profilePictureUrl', function () {
    return this.whatsappIntegration?.profilePictureUrl || DefaultAvatars.users[64];
  }),

  uploadPictureTask: task(function* () {
    try {
      yield this.whatsappIntegration?.uploadProfilePicture({
        app: this.appService.app,
        image: this.file,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('appstore.settings.whatsapp.upload-profile-picture.notification.success'),
      );
      this.removeFile();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'picture_uploaded',
        object: this.whatsappIntegration,
      });
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyError(
        this.intl.t('appstore.settings.whatsapp.upload-profile-picture.notification.error'),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'picture_upload_failed',
        object: this.whatsappIntegration,
      });
    }
  }).drop(),

  removeFile: action(function () {
    this.set('file', null);
    this.set('hasFile', false);
    this.set('previewImageUrl', null);
  }),

  showFilePicker: action(function () {
    this.element.querySelector('input[type="file"]').click();
  }),

  uploadProfilePicture: action(function () {
    this.uploadPictureTask.perform();
  }),
});
