/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type UploadHelper } from 'embercom/objects/media/media-helper';
interface Args {
  site: HelpCenterSite;
  uploadHelper: UploadHelper;
}

export default class Header extends Component<Args> {
  get headerComponent() {
    return this.args.site.customizationOptions.global?.namedComponents.header;
  }

  @action
  removeHeaderImageUrl(): void {
    this.args.site.headerUrl = '';
  }

  get mediaHelper() {
    return this.args.uploadHelper.getMediaHelper('header');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Styling::Header': typeof Header;
  }
}
