/* import __COLOCATED_TEMPLATE__ from './actions-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { and, equal, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import $ from 'jquery';
export default Component.extend({
  notificationsService: service(),
  quickActionBaseClasses: 'quick-action o__overflow-hidden u__left u__relative',

  isEmail: equal('messageType', 'email'),
  isInApp: computed('messageType', function () {
    return ['chat', 'small-announcement', 'announcement', 'video'].includes(this.messageType);
  }),
  isMobilePush: equal('messageType', 'push_only'),
  showComposerVideo: and('hasComposerVideo', 'isInApp'),
  hasSavedContentFeature: readOnly('app.hasSavedContentFeature'),
  maximumAllowedFileSize: 41943040,

  change(e) {
    let $target = $(e.target);
    if ($target.is('.js__simple-composer__attachment-input, .js__simple-composer__image-input')) {
      let action = $target.is('.js__simple-composer__attachment-input') ? 'Attachment' : 'Image';

      let files = $.makeArray(e.target.files);
      let fileSizesAreValid = files.every((file) => file.size < this.maximumAllowedFileSize);
      if (fileSizesAreValid) {
        this.get(`upload${action}`)(files);
      } else {
        this.notificationsService.notifyError('File exceeded maximum file size of 40MB');
      }
    }
  },
  _openFileInputDialog(selector) {
    let $fileInput = $(selector);
    $fileInput.val(null);
    $fileInput.trigger('click');
  },
  actions: {
    uploadComposerImage() {
      this._openFileInputDialog('.js__simple-composer__image-input');
    },
    uploadComposerAttachment() {
      this._openFileInputDialog('.js__simple-composer__attachment-input');
    },
    toggleComposerVideoPopover() {
      this.toggleComposerVideoPopover();
    },
    toggleMessageTypePopover() {
      this.toggleMessageTypePopover();
    },
    toggleMessageStylePopover() {
      this.toggleMessageStylePopover();
    },
    toggleSavedContentPopover() {
      this.toggleSavedContentPopover();
    },
  },
});
