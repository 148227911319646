/* import __COLOCATED_TEMPLATE__ from './task-header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { action } from '@ember/object';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { type Block } from 'embercom/models/common/blocks/block';
import type Task from 'embercom/models/checklists/task';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';

class TitleConfig extends BaseConfig {
  autoFocus = false;
  textDirection: NotificationDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  singleBlockMode = true;
  allowedInline = [];
  allowTextAlignment = false;
  tools = [{ name: 'fallback-editor' }];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  attributes: any;
  maxCharacterCount = 70;

  constructor(app: any, placeholder: string) {
    super();

    this.attributes = app.allowedAttributes;
    this.placeholder = placeholder;
  }
}

interface Args {
  task: Task;
  shouldShowValidations: boolean;
  onTaskHeaderConstructor: () => void;
}

export default class TaskHeader extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @tracked titleBlocksDoc: BlocksDocument;
  @tracked showConfirmationModal = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.titleBlocksDoc = new BlocksDocument(this.task.titleBlocks.toArray());
    this.args.onTaskHeaderConstructor();
  }

  get app() {
    return this.appService.app;
  }

  get task() {
    return this.args.task;
  }

  get titleComposerConfig() {
    let placeholder = this.intl.t('outbound.checklists.task-settings.task-title-placeholder');
    return new TitleConfig(this.app, placeholder);
  }

  get showNoTitleValidation() {
    return this.args.shouldShowValidations && !this.task.hasTitle;
  }

  @action updateTitleBlocks(blocksDoc: BlocksDocument) {
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.task.set('titleBlocks', blockFragments);
    this.task.notifyPropertyChange('titleBlocks');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Editor::Preview::TaskList::TaskHeader': typeof TaskHeader;
    'checklist/editor/preview/task-list/task-header': typeof TaskHeader;
  }
}
