/* import __COLOCATED_TEMPLATE__ from './part-composer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type IntercomAttribute from 'embercom/models/attribute';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { type Block } from 'embercom/models/common/blocks/block';
import {
  BaseConfig,
  BlocksDocument,
  EMOJI_TYPEAHEAD,
  INPUT_RULE_EMOJI,
  INPUT_RULE_MARKDOWN_ANCHOR,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
} from '@intercom/embercom-prosemirror-composer';
import {
  INLINE_CONTROL_ALIGNMENT,
  type TextDirection,
} from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import { EmbercomCanvasNetworkClient } from 'embercom/objects/composer/embercom-canvas-network-client';

class ComposerConfig extends BaseConfig {
  autoFocus = true;
  allowedBlocks = ['paragraph', 'image', 'orderedList', 'unorderedList'];
  allowedInline = ['bold', 'italic', 'anchor'];
  hideFromTextFormatter = [INLINE_CONTROL_ALIGNMENT];
  allowTextAlignment = false;
  canvasNetworkClient;
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  inputRules = [
    INPUT_RULE_EMOJI,
    INPUT_RULE_MARKDOWN_ANCHOR,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
  ];
  textDirection: TextDirection = 'auto';
  tools = [
    { name: 'text-formatter' },
    { name: 'template-inserter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'fallback-editor' },
    { name: 'image-editor', options: { supportAltAttributeEditing: false } },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
    onUploadStart: null,
    onUploadFinish: null,
  };
  templating: { picker: string; resolver: AttributeInfoResolver };

  constructor({
    app,
    resolver,
    placeholder,
  }: {
    app: any;
    resolver: AttributeInfoResolver;
    placeholder: string;
  }) {
    super();
    this.canvasNetworkClient = new EmbercomCanvasNetworkClient(app.id);
    this.templating = { picker: 'common/attribute-picker', resolver };
    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;
    this.placeholder = placeholder;
  }
}

interface Signature {
  Element: HTMLDivElement;
  Args: {
    part: $TSFixMe; // operator/models/bot-intro/part
    placeholder: string;
    onPartChange?: () => void;
  };
}

export default class PartComposer extends Component<Signature> {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare attributeService: $TSFixMe;
  @service declare contentEditorService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked blocksDoc: BlocksDocument;
  @tracked composerApi: any;
  @tracked openInserters: string[] = [];

  constructor(owner: unknown, args: never) {
    super(owner, args);
    this.blocksDoc = new BlocksDocument(this.serializedPart);
  }

  get app() {
    return this.appService.app;
  }

  get allowedAttributes() {
    return this.attributeService.attributes.filter(
      (attr: IntercomAttribute) => (attr.isUser || attr.isCompany) && !attr.isUserEvent,
    );
  }

  get composerConfig() {
    let config = new ComposerConfig({
      app: this.app,
      resolver: new AttributeInfoResolver({
        attributes: this.app.allowedAttributes,
        attributeAllowlist: this.attributeService.attributesToGroupList(this.allowedAttributes),
        includeAppAttributes: false,
      }),
      placeholder: this.args.placeholder,
    });

    config.upload.onUploadStart = this.contentEditorService.uploadFileStarted;
    config.upload.onUploadFinish = this.contentEditorService.uploadFileFinished;

    return config;
  }

  get serializedPart() {
    return this.args.part.jsonBlocks.toArray();
  }

  get hasError() {
    return !this.args.part.validations.isValid;
  }

  get inserters() {
    let inserters = [
      {
        name: 'emoji',
        icon: 'lwr-happy',
        descriptor: this.intl.t(
          'operator.workflows.visual-builder.chat-message-inserter.add-emoji',
        ),
        hasPopoverContentComponent: true,
      },
    ];

    if (this.app.teammateConversationGifsEnabled) {
      inserters.push({
        name: 'gif',
        icon: 'gif',
        descriptor: this.intl.t('operator.workflows.visual-builder.chat-message-inserter.add-gif'),
        hasPopoverContentComponent: true,
      });
    }

    inserters.push({
      name: 'image',
      icon: 'picture',
      descriptor: this.intl.t('operator.workflows.visual-builder.chat-message-inserter.add-image'),
      hasPopoverContentComponent: false,
    });

    return inserters;
  }

  get controlsAPI() {
    return {
      app: this.app,
      actions: {
        registerOpenInserter: (inserterName: string) => {
          this.openInserters.pushObject(inserterName);
        },
        clearOpenInserter: (inserterName: string) => {
          if (!this.isDestroying) {
            this.openInserters.removeObject(inserterName);
          }
        },
        paste: (text: string) => {
          this.composerApi.composer.commands.insertText(text);
        },
        insertBlock: (type: string, block: Block) => {
          block['type'] = type;
          this.composerApi.composer.commands.insertBlock(block);
        },
        uploadImage: (files: Array<File>) => {
          files.reverse().forEach((file) => {
            this.composerApi.composer.commands.insertImageFile(file);
            this.composerApi.composer.commands.collapseSelection();
          });
        },
        uploadAttachment: (files: Array<File>) => {
          files.forEach((file) => {
            this.composerApi.composer.commands.addAttachment(file);
          });
        },
      },
    };
  }

  @action updateBlocks(blocksDoc: BlocksDocument) {
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.args.part.blocks = blockFragments;
    this.args.part.notifyPropertyChange('blocks');
    this.args.onPartChange?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::PartComposer': typeof PartComposer;
    'ai-agent/part-composer': typeof PartComposer;
  }
}
