/* import __COLOCATED_TEMPLATE__ from './graduation-picker-modal.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { all } from 'ember-concurrency';
import { restartableTask } from 'ember-concurrency-decorators';
import { getNumberOfSeatsInUse } from 'embercom/lib/admins/seat-info';
import {
  CORE_START_ID,
  CORE_GROW_ID,
  CORE_SEAT_METRIC,
  INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
  PRICING_5_EARLY_STAGE_BUNDLE,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  FIN_AI_COPILOT_BASE_ID,
} from 'embercom/lib/billing';
import moment from 'moment-timezone';

export default class GraduationPickerModal extends Component {
  @service appService;
  @service customerService;
  @service permissionsService;
  @tracked selectedPlan;
  @tracked numberOfTeammates = 0;

  constructor() {
    super(...arguments);
    this.selectedPlan = this.args.initiallySelectedPlan;
    this._loadData.perform();
  }

  @restartableTask
  *_loadData() {
    yield all([this.requestNumberOfSeats.perform(), this.requestGraduationPrices.perform()]);
  }

  @restartableTask
  *requestNumberOfSeats() {
    try {
      let numberOfSeatsInUseInfo = yield getNumberOfSeatsInUse();
      this.numberOfTeammates = numberOfSeatsInUseInfo.totalNumberOfSeatsInUse;
    } catch (err) {
      this.numberOfTeammates = 0;
    }
  }

  @restartableTask
  *requestGraduationPrices() {
    let planIds = this.planIdsExcludingEarlyStage;
    if (!planIds.length) {
      return;
    }
    let pricingModelForNonEarlyStagePlans =
      this.customerService.earlyStageGraduation?.pricingModelForNonEarlyStagePlans;

    let planIdsWithUnloadedPrices = planIds.filter((planId) => {
      return (
        this.customerService.isPriceLoaded([planId].flat(), pricingModelForNonEarlyStagePlans) ===
        false
      );
    });

    if (planIdsWithUnloadedPrices.length === 0) {
      return;
    }

    let priceSetsParams = [];
    planIdsWithUnloadedPrices.map((planId) => {
      let pricingModelToUse = pricingModelForNonEarlyStagePlans;
      if (planId === PRICING_5_EARLY_STAGE_BUNDLE) {
        pricingModelToUse =
          this.customerService.earlyStageGraduation?.pricingModelForEarlyStagePlan;
      }

      priceSetsParams.push({
        planIds: [planId].flat(),
        includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
        pricingModel: pricingModelToUse,
        source: 'graduation-picker-modal-component',
      });
    });
    yield this.customerService.bulkLoadPrices(priceSetsParams);
  }

  get planIdsExcludingEarlyStage() {
    let plans = this.args.plans.mapBy('id').filter((id) => id !== undefined);
    if (this.customerService.earlyStageGraduation?.pricing5_X_Graduation) {
      plans.push(
        PRICING_5_EARLY_STAGE_BUNDLE,
        PROACTIVE_SUPPORT_PLUS_BASE_ID,
        FIN_AI_COPILOT_BASE_ID,
      );
    }
    return plans;
  }

  get graduationDate() {
    return moment(this.customerService.earlyStageGraduation.graduationDate);
  }

  get shouldShowDefault() {
    if (
      this.args.defaultPlan &&
      isPresent(this.args.recommendedPlan) &&
      this.args.graduatingPlan?.id !== this.args.recommendedPlan?.id &&
      this.args.recommendedPlan?.id !== CORE_GROW_ID
    ) {
      return true;
    }
  }

  get shouldShowChatToSalesLink() {
    return this.customerService.onVbp2_X_EarlyStage || this.customerService.onPricing5_X;
  }

  get hasBillingPermission() {
    return this.permissionsService.currentAdminCan('can_access_billing_settings');
  }

  get selectedPlanTeammateLimit() {
    if (!this.selectedPlan?.id) {
      return;
    }
    return this.selectedPlan?.get('pricingStrategies')?.findBy('pricingMetric', CORE_SEAT_METRIC)
      ?.maximum;
  }

  get teammateLimitReached() {
    return (
      this.selectedPlan?.id === CORE_START_ID &&
      this.numberOfTeammates > this.selectedPlanTeammateLimit
    );
  }

  @action updatePlan(plan) {
    if (this.args.allowEmptySelection && this.selectedPlan && plan.id === this.selectedPlan.id) {
      this.selectedPlan = null;
    } else {
      this.selectedPlan = plan;
    }
  }
}
