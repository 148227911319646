/* import __COLOCATED_TEMPLATE__ from './ai-category-modal.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type ListOption from 'embercom/models/conversation-attributes/list-option';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type { TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

export interface Args {
  category: ListOption | null;
  activeCategories: ListOption[];
  onSave: (id: any, label: string, description: string) => TaskGenerator<boolean>;
  onClose: () => void;
}

export const LABEL_MIN_LENGTH = 1;
export const LABEL_MAX_LENGTH = 150;
export const DESCRIPTION_MIN_LENGTH = 10;
export const DESCRIPTION_MAX_LENGTH = 1000;

export default class AiCategoryModal extends Component<Args> {
  @tracked label: string = this.args.category?.label || '';
  @tracked description: string = this.args.category?.description || '';
  @service declare intl: IntlService;
  labelMinLength = LABEL_MIN_LENGTH;
  labelMaxLength = LABEL_MAX_LENGTH;
  descriptionMinLength = DESCRIPTION_MIN_LENGTH;
  descriptionMaxLength = DESCRIPTION_MAX_LENGTH;

  get isLabelValid() {
    return (this.isLabelPresent && this.isLabelUnique) || taskFor(this.args.onSave).isRunning;
  }

  get isLabelPresent() {
    return this.label.length !== 0;
  }

  get isLabelUnique() {
    return !this.args.activeCategories.some(
      (category) => category.label === this.label && this.args.category?.id !== category.id,
    );
  }

  get isDescriptionValid(): boolean {
    return this.description.length >= DESCRIPTION_MIN_LENGTH;
  }

  get isFormValid(): boolean {
    return this.isLabelValid && this.isDescriptionValid;
  }

  get formInvalidTooltip(): string {
    let errors = [] as string[];

    if (!this.isLabelPresent) {
      errors.pushObject(
        this.intl.t('operator.fin.setup.ai-categories.category-modal.input-name-invalid'),
      );
    }

    if (!this.isLabelUnique) {
      errors.pushObject(
        this.intl.t('operator.fin.setup.ai-categories.category-modal.input-name-not-unique'),
      );
    }

    if (!this.isDescriptionValid) {
      errors.pushObject(
        this.intl.t('operator.fin.setup.ai-categories.category-modal.input-description-invalid', {
          min: DESCRIPTION_MIN_LENGTH,
        }),
      );
    }

    return errors.join(' ');
  }

  @action async saveCategory() {
    let saveSuccess = await taskFor(this.args.onSave).perform(
      this.args.category?.id,
      this.label,
      this.description,
    );
    saveSuccess && this.args.onClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::AiCategories::AiCategoryModal': typeof AiCategoryModal;
  }
}
