/* import __COLOCATED_TEMPLATE__ from './version-overlay.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { useTask } from 'ember-resources';

export default class VersionOverlay extends Component {
  versionsData = useTask(this, this.retrieveVersions, () => [
    this.args.content,
    this.args.isOpen,
    this.page,
  ]);
  @tracked currentVersionIndex = 0;
  @tracked page = 1;
  @service store;
  @service notificationsService;
  @service intl;
  @service intercomEventService;
  @service appService;
  @tracked showDiff = true;

  @task
  *retrieveVersions() {
    try {
      let result = yield ajax({
        type: 'GET',
        url: `/ember/article_contents/${this.args.content.id}/versions?app_id=${this.args.content.app.id}&page=${this.page}`,
      });
      let previousVersions = this.page !== 1 ? this.versionsData.value.versions : [];
      if (this.page === 1) {
        this.currentVersionIndex = 0;
      }
      let rawVersions = result.data;
      let newVersions = rawVersions.map((rawVersion) => ({
        ...rawVersion,
        jsonBlocks: rawVersion['json_blocks'],
        authorId: rawVersion['author_id'].toString(),
        author: this.store.peekRecord('admin', rawVersion['author_id']),
        createdBy: this.store.peekRecord('admin', rawVersion['created_by_id']),
        stepsSinceLastVersion: rawVersion['steps_since_last_version'],
      }));
      let hasMoreData = result.metadata['next_page'];
      return {
        versions: [...previousVersions, ...newVersions],
        hasMoreData,
      };
    } catch (exception) {
      this.notificationsService.notifyError(
        this.intl.t('articles.show.article-view-options.history.unable-to-load-versions'),
      );
      this.args.onClose();
    }
  }

  @action
  selectVersion(index) {
    this.currentVersionIndex = index;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'article_version_link',
      place: this.args.analyticsPlace,
      section: 'articles',
      article_id: this.args.articleId,
      article_content_id: this.args.content.id,
      article_version_id: this.selectedContent.id,
      locale: this.args.content.locale,
    });
  }

  @action
  async onCancel() {
    this.args.onClose();
  }

  @action
  onClose() {
    this.page = 1;
    this.args.onClose();
  }

  @action
  toggleDiff() {
    this.showDiff = !this.showDiff;
  }

  get selectedContent() {
    let version = this.versionsData.value.versions[this.currentVersionIndex];
    return { ...version, updatedAt: version['updated_at'] };
  }

  get previousContent() {
    if (this.versionsData.value.versions.length > this.currentVersionIndex + 1) {
      let version = this.versionsData.value.versions[this.currentVersionIndex + 1];
      return { ...version, updatedAt: version['updated_at'] };
    }
    return undefined;
  }

  get app() {
    return this.appService.app;
  }

  @action
  loadNextPage() {
    this.page += 1;
  }
}
