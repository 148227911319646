/* import __COLOCATED_TEMPLATE__ from './included-usage-row.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { isPeopleReachedPlan } from 'embercom/lib/billing/plans';

interface Args {
  planId: number;
  name: string;
  price: string | null;
  testIdentifier: string;
  showLine: boolean;
  usage: string;
  additionalContractedTooltipData: any;
  metricIdentifier: string;
  shouldHidePrices: boolean;
}

export default class IncludedUsageRow extends Component<Args> {
  get isPeopleReachedPlan() {
    return isPeopleReachedPlan(this.args.planId.toString());
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::IncludedUsageRow': typeof IncludedUsageRow;
  }
}
