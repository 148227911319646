/* import __COLOCATED_TEMPLATE__ from './tagging-modal-header.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  intercomEventService: service(),

  actions: {
    closeModalAction() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'closed',
        object: 'tagging_component',
      });
      if (this.closeModalAction) {
        this.closeModalAction();
      } else {
        this.modalService.closeModal();
      }
    },
  },
});
