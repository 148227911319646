/* import __COLOCATED_TEMPLATE__ from './hero-banner-subcolumn.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  translationKey: string;
  title: string;
  content: string;
  icon: string;
}

interface Signature {
  Args: Args;
}

const MigrationBannerSubcolumn = templateOnlyComponent<Signature>();
export default MigrationBannerSubcolumn;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationSubcontent::HeroBannerSubcolumn': typeof MigrationBannerSubcolumn;
  }
}
