/* import __COLOCATED_TEMPLATE__ from './welcome-fin.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { get } from 'embercom/lib/ajax';
import { taskFor } from 'ember-concurrency-ts';
import { modifier } from 'ember-modifier';
import { post } from 'embercom/lib/ajax';

interface Signature {
  Args: {
    consentToFinIngestion: () => void;
  };
  Element: never;
}

type PersonalizedFinDemoKeys = 'lyft' | 'slack' | 'spotify';

type PersonalizedFinDemoData = {
  token: string;
  icon: string;
  titleTranslationKey: string;
};

type FinEvalRequest = {
  expired: boolean;
  metadata: {
    sample_questions: string[];
  };
  help_center_url: string;
};

const PERSONALIZED_FIN_DEMOS: Record<PersonalizedFinDemoKeys, PersonalizedFinDemoData> = {
  lyft: {
    token: 'd2dc1141-e132-49d2-83a2-a0983e49f1a0',
    icon: 'https://asset.brandfetch.io/idF3tki5X5/idLsGba5N3.svg',
    titleTranslationKey: 'operator.welcome-fin.demo-title.lyft',
  },
  slack: {
    token: '88f2a3ec-b89a-4af7-b122-ec96b21e92a0',
    icon: 'https://asset.brandfetch.io/idJ_HhtG0Z/idaPaNYhFw.svg',
    titleTranslationKey: 'operator.welcome-fin.demo-title.slack',
  },
  spotify: {
    token: 'b0c04693-ebe5-4a96-b035-f77de13bbdb4',
    icon: 'https://storage.googleapis.com/pr-newsroom-wp/1/2018/11/Spotify_Logo_CMYK_Green.png',
    titleTranslationKey: 'operator.welcome-fin.demo-title.spotify',
  },
};

export default class WelcomeFin extends Component<Signature> {
  @service declare appService: any;
  @service notificationsService: any;
  @service intercomEventService: any;
  @service store: any;
  @service intl: any;

  @tracked showFinPreview = false;
  @tracked showLoadingIcon = true;
  @tracked hasSampleQuestions = false;
  @tracked finEvalRequest: TaskGenerator<FinEvalRequest> | any = null;
  @tracked showModal = false;

  @tracked selectedDemo: PersonalizedFinDemoKeys = 'spotify';

  constructor(owner: unknown, args: never) {
    super(owner, args);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'welcome_fin_component_in_fin_setup',
    });
    taskFor(this.getFinPreviewRequest).perform();
    taskFor(this.recordFirstSelectedWizard).perform();
  }

  get app() {
    return this.appService.app;
  }

  get token(): string {
    return PERSONALIZED_FIN_DEMOS[this.selectedDemo].token;
  }

  get sampleQuestions(): string[] {
    return this.finEvalRequest?.metadata?.sample_questions || [];
  }

  get totalSampleQuestions(): number {
    return this.sampleQuestions.length;
  }

  get helpCenterUrl(): string {
    return this.finEvalRequest?.help_center_url || '';
  }

  get title(): string {
    return this.intl.t(PERSONALIZED_FIN_DEMOS[this.selectedDemo].titleTranslationKey);
  }

  get finDemoList(): Record<PersonalizedFinDemoKeys, PersonalizedFinDemoData> {
    return PERSONALIZED_FIN_DEMOS;
  }

  bindIFrame = modifier((el) => {
    let iframe = document.createElement('iframe');
    let iframeUrl = `${window.location.origin}/client/fin_messenger_preview/`;
    if (this.token) {
      iframeUrl += this.token;
    }

    iframe.src = iframeUrl;
    iframe.setAttribute('id', 'hosted-messenger-fin-demo-preview');

    el.replaceChildren(iframe);
  });

  @action
  onSelectedDemoChange(demo: PersonalizedFinDemoKeys) {
    this.selectedDemo = demo;
    this.showLoadingIcon = true;
    taskFor(this.getFinPreviewRequest).perform();
  }

  @action
  sendMessageToMessenger(messageIdx: number): void {
    let message = this.sampleQuestions[messageIdx];
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'fin_sample_question',
      place: 'fin-setup-page',
      section: 'welcome_fin',
      token: this.token,
      app_id: this.app?.id,
    });
    let finPreview: any = window.document.querySelector('#hosted-messenger-fin-demo-preview');
    finPreview?.contentWindow?.postMessage(
      JSON.stringify({ type: 'intercom-send-message', message }),
      '*',
    );
  }

  @task({ restartable: true })
  *getFinPreviewRequest(): TaskGenerator<FinEvalRequest> {
    try {
      this.finEvalRequest = yield get(
        `/fin_evaluation_request?token=${this.token}`,
      ) as FinEvalRequest;
      if (this.finEvalRequest.expired) {
        this.notificationsService.notifyError(
          this.intl.t('onboarding.preview-fin.error-loading-expired-message'),
        );
        console.error('welcome-fin-preview-token-expired');
        this.showLoadingIcon = false;
      } else {
        this.showFinPreview = true;
        this.showLoadingIcon = false;
        this.intercomEventService.trackAnalyticsEvent({
          action: 'viewed',
          object: 'link_from_welcome_fin_preview',
          place: 'welcome-fin-preview-page',
          section: 'welcome_fin_preview',
          token: this.token,
          app_id: this.app?.id,
        });
      }
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('onboarding.preview-fin.error-loading-message'),
      );
      this.showLoadingIcon = false;
      if (e.jqXHR.status === 404) {
        console.error('welcome-fin-preview-token-invalid');
        this.intercomEventService.trackAnalyticsEvent({
          action: 'viewed',
          object: 'invalid_token_welcome_fin_preview',
          place: 'welcome-fin-preview-page',
          section: 'welcome_fin_preview_error',
          token: this.token,
          app_id: this.app?.id,
        });
      }
    }
    return this.finEvalRequest;
  }

  @task({ restartable: true })
  *recordFirstSelectedWizard(): TaskGenerator<void> {
    yield post('/ember/onboarding/guide_library_cda/record_first_selected_wizard', {
      app_id: this.appService.app.id,
      wizard_id: 'evaluation_wizard_chatbot',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Automation::WelcomeFin': typeof WelcomeFin;
  }
}
