/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed, observer, action } from '@ember/object';
import { alias, equal, gt, not, readOnly } from '@ember/object/computed';
import Evented from '@ember/object/evented';
import { cancel, debounce, schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import {
  changeGate,
  notImplemented,
  ternary,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';
import ENV from 'embercom/config/environment';
import NumberPagination from 'embercom/controllers/mixins/number-pagination';
import ListSelection from 'embercom/controllers/mixins/users-and-companies/list-selection';
// eslint-disable-next-line @intercom/intercom/no-legacy-modal
import ModalActions from 'embercom/controllers/mixins/users-and-companies/modal-actions';
import PageLoader from 'embercom/controllers/mixins/users-and-companies/page-loader';
import { post } from 'embercom/lib/ajax';
import DisplayAttributeSetting from 'embercom/models/display-attribute-setting';
import LegacySelectionState from 'embercom/models/legacy-selection-state';
import { copy } from 'ember-copy';

const SHORTCUT_GROUP = 'list-keyboard-actions';

const TRANSLATION_KEYS = {
  tagged: {
    user: 'components.base.user-or-companies-list.tagged-user',
    lead: 'components.base.user-or-companies-list.tagged-lead',
    company: 'components.base.user-or-companies-list.tagged-company',
  },
  willTag: {
    user: 'components.base.user-or-companies-list.will-tag-user',
    lead: 'components.base.user-or-companies-list.will-tag-lead',
    company: 'components.base.user-or-companies-list.will-tag-company',
  },
  tagHover: {
    user: 'components.base.user-or-companies-list.tag-hover-user',
    lead: 'components.base.user-or-companies-list.tag-hover-lead',
    company: 'components.base.user-or-companies-list.tag-hover-company',
  },
  tag: {
    user: 'components.base.user-or-companies-list.tag-user',
    lead: 'components.base.user-or-companies-list.tag-lead',
    company: 'components.base.user-or-companies-list.tag-company',
  },
  message: {
    user: 'components.base.user-or-companies-list.message-user',
    lead: 'components.base.user-or-companies-list.message-lead',
    company: 'components.base.user-or-companies-list.message-company',
  },
  messageNotSelected: {
    user: 'components.base.user-or-companies-list.message-user-not-selected',
    lead: 'components.base.user-or-companies-list.message-lead-not-selected',
    company: 'components.base.user-or-companies-list.message-company-not-selected',
  },
  bulkActionAudienceKey: {
    user: 'components.base.user-or-companies-list.bulk-action-user',
    lead: 'components.base.user-or-companies-list.bulk-action-lead',
    company: 'components.base.user-or-companies-list.bulk-action-company',
  },
};

export default Component.extend(
  ModalActions,
  Evented,
  NumberPagination,
  ListSelection,
  PageLoader,
  {
    segment: notImplemented(),
    search: notImplemented(),
    app: readOnly('appService.app'),
    appService: service(),
    pubsub: service(),
    shortcuts: service(),
    router: service(),
    intl: service(),
    intercomEventService: service(),
    permissionsService: service(),
    notificationsService: service(),

    tagName: '', // having no wrapping element is important for the viewport height used in auto-pagination

    model: undefined,

    init() {
      this.set('model', []);
      this._super(...arguments);
      // @adam.byrne 2018-11-20 - the following get allows the observer to fire for the initial value
      // it's not idea but I'll be getting rid of that observer in a follow-up PR soon.
      this.significantPredicateChange;
    },

    didInsertElement() {
      this._super(...arguments);
      this.registerKeyboardShortcuts();
      this.pubsub.subscribe('reloadUserOrCompanyList', this, this.reload);
      this.reload();
    },

    willDestroyElement() {
      this._super(...arguments);
      cancel(this.reloadDebounce);
      this.shortcuts.unregister(SHORTCUT_GROUP);
      this.pubsub.unsubscribe('reloadUserOrCompanyList', this, this.reload);
    },

    registerKeyboardShortcuts() {
      this.shortcuts.register(SHORTCUT_GROUP, this, [
        { key: 'j', modalActive: false, method: this.highlightNextItem },
        { key: 'k', modalActive: false, method: this.highlightPreviousItem },
        { key: 'x', modalActive: false, method: this.selectHighlightedItem },
        { key: 'o', modalActive: false, method: this.redirectToUserOrCompanyProfile },
        { key: '↵', modalActive: false, method: this.redirectToUserOrCompanyProfile },
        { key: 't', modalActive: false, method: this.openTagModalFromShortcut },
      ]);
    },

    tagEndpoint: null,
    isLimitedResponse: false,
    isCompany: alias('segment.isCompany'),
    targetPlural: ternary('isCompany', 'companies', 'users'),
    isNotCompany: not('isCompany'),
    hasPeopleOrCompaniesToShow: gt('totalCount', 0),
    hasNoPeopleOrCompaniesToShow: not('hasPeopleOrCompaniesToShow'),

    segmentChanged: observer({
      dependentKeys: ['significantPredicateChange'],

      fn() {
        this.set('isSelectAllMode', false);
        this.set('isLoading', true);
        this.set('reloadDebounce', debounce(this, this.reload, ENV.APP._750MS));
      },

      sync: true,
    }),

    hasSelectedUsers: gt('selectedCount', 0),
    companyTypeName: 'company',
    selectedType: ternaryToProperty(
      'segment.isCompany',
      'companyTypeName',
      'segment.personTypeName',
    ),
    selectedOneUser: equal('selectedCount', 1),
    selectedUsersLabel: alias('selected.firstObject.displayAs'),

    get bulkTagTooltipText() {
      if (this.hasSelectedUsers) {
        if (TRANSLATION_KEYS['tagHover'][this.tagTypeLabel]) {
          return this.intl.t(TRANSLATION_KEYS['tagHover'][this.tagTypeLabel], {
            count: this.taggableCount,
            label: this.selectedUsersLabel,
          });
        } else {
          return this.intl.t('components.base.user-or-companies-list.tag-hover-default');
        }
      } else if (TRANSLATION_KEYS['tag'][this.tagTypeLabel]) {
        return this.intl.t(TRANSLATION_KEYS['tag'][this.tagTypeLabel]);
      } else {
        return this.intl.t('components.base.user-or-companies-list.tag-default');
      }
    },

    get bulkActionAudienceLabel() {
      if (TRANSLATION_KEYS['bulkActionAudienceKey'][this.tagTypeLabel]) {
        return this.intl.t(TRANSLATION_KEYS['bulkActionAudienceKey'][this.tagTypeLabel], {
          selectedType: this.selectedUsersLabel,
          selectedCount: this.selectedCount,
        });
      } else {
        return this.intl.t[
          ('components.base.user-or-companies-list.bulk-action-default',
          { selectedCount: this.selectedCount })
        ];
      }
    },

    get bulkMessageTooltipText() {
      if (this.hasSelectedUsers) {
        if (TRANSLATION_KEYS['message'][this.tagTypeLabel]) {
          return this.intl.t(TRANSLATION_KEYS['message'][this.tagTypeLabel], {
            count: this.taggableCount,
            label: this.selectedUsersLabel,
          });
        } else {
          return this.intl.t('components.base.user-or-companies-list.message-default');
        }
      } else if (TRANSLATION_KEYS['messageNotSelected'][this.tagTypeLabel]) {
        return this.intl.t(TRANSLATION_KEYS['messageNotSelected'][this.tagTypeLabel]);
      } else {
        return this.intl.t('components.base.user-or-companies-list.message-default-not-selected');
      }
    },

    finishedLoading() {
      this._super(...arguments);
      if (this.get('model.length') === 0 || this.model.isAny('isHighlighted', true)) {
        return;
      }
      this.set('model.firstObject.isHighlighted', true);
    },

    highlightAdjacentItem(isNext) {
      let model = this.model;
      let highlighted = model.findBy('isHighlighted', true);
      let offset = isNext ? 1 : -1;
      let nextIndex = model.indexOf(highlighted) + offset;
      if (nextIndex < model.get('length') && nextIndex > -1) {
        highlighted.set('isHighlighted', false);
        model.objectAt(nextIndex).set('isHighlighted', true);
      }
      schedule('afterRender', () => {
        this.listTable.send('highlightedItemChanged');
      });
    },

    taggableCount: computed('hasSelectedUsers', 'selectedCount', 'totalCount', function () {
      return this.hasSelectedUsers ? this.selectedCount : this.totalCount;
    }),

    updateTaggingsSelectionState: computed(
      'hasSelectedUsers',
      'selectionState',
      'predicates',
      'totalCount',
      function () {
        return this.hasSelectedUsers
          ? this.selectionState
          : LegacySelectionState.create({
              isSelectAllMode: true,
              predicates: this.predicates,
              count: this.totalCount,
            });
      },
    ),

    updateTaggingsTask: task(function* (addedTags) {
      let selectionState = this.updateTaggingsSelectionState;
      for (let tag of addedTags) {
        yield post(
          this.tagEndpoint,
          Object.assign(selectionState.toApi(), {
            app_id: this.get('app.id'),
            tag_name: tag.get('name'),
          }),
        );
      }
    }).drop(),

    tagNotificationMessage() {
      let selectionState = this.updateTaggingsSelectionState;
      let targetCount = selectionState.get('count');

      if (targetCount < 200 && selectionState.get('isNotSelectAllMode')) {
        if (TRANSLATION_KEYS['tagged'][this.tagTypeLabel]) {
          return this.intl.t(TRANSLATION_KEYS['tagged'][this.tagTypeLabel], {
            count: targetCount,
          });
        } else {
          return this.intl.t('components.base.user-or-companies-list.tagged-default');
        }
      } else if (TRANSLATION_KEYS['willTag'][this.tagTypeLabel]) {
        return this.intl.t(TRANSLATION_KEYS['willTag'][this.tagTypeLabel], {
          count: targetCount,
        });
      } else {
        return this.intl.t('components.base.user-or-companies-list.will-tag-default');
      }
    },

    showTagModal: false,

    // keyboard shortcuts

    highlightNextItem() {
      this.highlightAdjacentItem(true);
    },
    highlightPreviousItem() {
      this.highlightAdjacentItem(false);
    },
    selectHighlightedItem() {
      this.model.findBy('isHighlighted', true).toggleProperty('selected');
    },
    redirectToUserOrCompanyProfile() {
      let highlighted = this.model.findBy('isHighlighted', true);
      if (highlighted) {
        this.send('showUserOrCompany', highlighted);
      }
    },
    openTagModalFromShortcut() {
      if (this.taggableCount >= 25) {
        this.notificationsService.notifyWarning(
          this.intl.t('components.base.user-or-companies-list.more-than-25'),
        );
      } else {
        this.set('showTagModal', true);
      }
    },

    sort: action(function (displayedAttribute) {
      let self = this;
      this.intercomEventService.trackEvent('user-list-sort', {
        userCount: this.totalCount,
        attribute: displayedAttribute.identifier,
      });
      let list = this.isCompany ? 'companies' : 'users';
      DisplayAttributeSetting.sortBy(displayedAttribute, this.app, list).then(function () {
        self.reload();
      });
    }),

    actions: {
      clearSelection() {
        this.model.setEach('selected', false);
      },
      reload() {
        this.reload();
      },
      reloadCurrentPage() {
        this.loadPage(this.page);
      },
      checkPermissionsForExportAndOpenAppropriateModal() {
        let path = this.isCompany ? 'companies' : 'users';
        this.permissionsService.checkPermission('can_export_data').then(() => {
          this.send(
            'openModalWithSelectionState',
            `${path}/segments/segment/modals/export`,
            undefined,
            { personTypeName: this.segment.personTypeName },
          );
        });
      },
      afterTagSave(addedTags, removedTags) {
        this.set('showTagModal', false);

        this.notificationsService.notifyConfirmation(this.tagNotificationMessage());

        this.intercomEventService.trackEvent('user-company-tagged', {
          action: 'tagged',
          object: this.isCompany ? 'company' : 'user',
          place: this.isCompany ? 'company-list' : 'user-list',
          owner: 'team-reporting',
          user_company_ids: this.get('selectionState.selectedIDs'),
          tags: addedTags.map((tag) => tag.get('name')),
        });
      },
      updateTaggings(admin, addedTags, removedTags, initialTags) {
        return this.updateTaggingsTask.perform(addedTags);
      },
    },

    significantPredicateChange: changeGate(
      'predicates',
      function (value) {
        return JSON.stringify(value);
      },
      { sync: true },
    ),

    predicates: computed('segment.activePredicates', 'search.predicate', function () {
      let predicates = this.get('segment.activePredicates');

      if (predicates) {
        predicates = copy(predicates, true);
        let searchPredicate = this.get('search.predicate');

        if (searchPredicate) {
          predicates.push(searchPredicate);
        }

        return predicates;
      }
    }),
    updateCachedCount(count) {
      if (!this.get('segment.hasDirtyAttributes') && !this.get('search.predicate')) {
        this.segment.set('search_count', count);
      }
      this.updateAppPeopleCount(count).updateAppCompanyCount(count);
    },
    updateAppPeopleCount(count) {
      if (!this.get('segment.isCompany') && this.get('app.peopleCount') < count) {
        this.set('app.peopleCount', count);
      }
      return this;
    },
    updateAppCompanyCount(count) {
      if (this.get('segment.isCompany') && this.get('app.company_count') < count) {
        this.set('app.company_count', count);
      }
      return this;
    },
  },
);
