/* import __COLOCATED_TEMPLATE__ from './playground.hbs'; */
/* RESPONSIBLE TEAM: team-ml */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { deleteRequest, postRequest, request } from 'embercom/lib/inbox/requests';
import { dropTask } from 'ember-concurrency-decorators';
import {
  PlaygroundTestRun,
  PlaygroundTestQuestion,
  type PlaygroundTestRunWireFormat,
  Status,
} from 'embercom/lib/fin-playground';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { taskFor } from 'ember-concurrency-ts';
import { cached } from 'tracked-toolbox';
import { use } from 'ember-resources/util/function-resource';

export default class AiAgentPlayground extends Component {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare realTimeEventService: $TSFixMe;

  @tracked newQuestion = '';
  @tracked selectedQuestionIndex = 0;
  @tracked isAddingQuestions = false;
  @tracked showResetModal = false;
  @tracked showFinContentModal = false;

  @cached
  get latestTest() {
    return this.latestTestLoader.value ?? PlaygroundTestRun.build();
  }

  get selectedQuestion() {
    return this.latestTest.questions[this.selectedQuestionIndex];
  }

  get deductionRequiredToAddQuestion(): number {
    if (!this.latestTest.hasReachedQuestionLimit) {
      return 0;
    }

    return 1 + this.latestTest.questions.length - this.latestTest.questionLimit;
  }

  @use private latestTestLoader = AsyncData<PlaygroundTestRun>(async () => {
    let response = await request(`/ember/fin_playground/test?app_id=${this.appService.app.id}`);
    return response.ok ? PlaygroundTestRun.deserialize(await response.json()) : null;
  });

  @action
  openFinContentModal() {
    this.showFinContentModal = true;
  }

  @action
  resetTest() {
    this.showResetModal = false;
    this.latestTestLoader.update(PlaygroundTestRun.build());
    this.selectedQuestionIndex = 0;

    taskFor(this._addQuestionsTask).perform([]);
  }

  @action
  saveQuestions(list: string[]) {
    let questions = list
      .uniq()
      .filter((q) => q.trim().length > 0 && !this.latestTest.hasQuestion(q))
      .map((q) => new PlaygroundTestQuestion(undefined, q));

    this.latestTest.addQuestions(questions);
    taskFor(this._addQuestionsTask).perform(questions);
  }

  get canRunQuestions() {
    return !this.latestTest.isRunning && this.latestTest.hasPendingQuestions;
  }

  @action
  runQuestions() {
    taskFor(this._runTestTask).perform(this.latestTest.pendingQuestions);
  }

  @action
  runSelectedQuestion() {
    if (!this.selectedQuestion?.id) {
      return;
    }

    this.latestTest.setQuestionState(this.selectedQuestion.id, Status.Running);

    taskFor(this._runTestTask).perform([this.selectedQuestion]);
  }

  @action
  removeQuestion(question_id?: number) {
    if (!question_id) {
      return;
    }

    taskFor(this._removeQuestionTask).perform(question_id);

    if (this.selectedQuestionIndex === question_id) {
      this.selectedQuestionIndex = 0;
    }
  }

  @action
  selectQuestion(questionText: string) {
    this.selectedQuestionIndex =
      this.latestTest.questions.findIndex((q) => q.questionText === questionText) || 0;
  }

  @action
  async handleAnswerGenerated(e: { client_assigned_uuid: string }) {
    if (this.latestTest.clientAssignedUuid === e.client_assigned_uuid) {
      this.latestTestLoader.reload();
    }
  }

  @dropTask
  *_addQuestionsTask(questions: PlaygroundTestQuestion[]) {
    let response = (yield postRequest(
      `/ember/fin_playground/add_questions?app_id=${this.appService.app.id}`,
      {
        client_assigned_uuid: this.latestTest.clientAssignedUuid,
        questions: questions.map((q) => ({ question_text: q.questionText })),
      },
    )) as Response;

    let json = (yield response.json()) as PlaygroundTestRunWireFormat;
    this.latestTestLoader.update(PlaygroundTestRun.deserialize(json));
  }

  @dropTask
  *_removeQuestionTask(question_id: number) {
    let response = (yield deleteRequest(
      `/ember/fin_playground/${this.latestTest.id}/delete_question?app_id=${this.appService.app.id}`,
      {
        question_id,
      },
    )) as Response;

    let json = (yield response.json()) as PlaygroundTestRunWireFormat;
    this.latestTestLoader.update(PlaygroundTestRun.deserialize(json));
  }

  @dropTask
  *_runTestTask(questions?: PlaygroundTestQuestion[]) {
    this.realTimeEventService.subscribeTopics([
      `fin-playground/${this.latestTest.clientAssignedUuid}`,
    ]);

    this.realTimeEventService.on('FinPlaygroundQuestionResponse', this, 'handleAnswerGenerated');

    this.latestTest.setRunningStatus(questions);

    let response = (yield postRequest(
      `/ember/fin_playground/${this.latestTest.id}/run?app_id=${this.appService.app.id}`,
      {
        question_ids: questions?.map((q) => q.id),
      },
    )) as Response;

    let json = (yield response.json()) as PlaygroundTestRunWireFormat;
    this.latestTestLoader.update(PlaygroundTestRun.deserialize(json));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground': typeof AiAgentPlayground;
  }
}
