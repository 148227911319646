/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const VALID_GOOGLE_ANALYTICS_TRACKING_ID = /^(UA|YT|MO)-\d+-\d+$/i;
const VALID_GOOGLE_ANALYTICS_4_MEASUREMENT_ID = /^G-[0-9A-Z]{10}$/;

export default class GoogleAnalytics extends Component {
  @service notificationsService;
  @service appService;
  @service store;
  @service intl;

  @tracked messengerAnalyticsSettings;
  @tracked customTrackingIdEnabled = false;

  constructor() {
    super(...arguments);
    this.loadMessengerAnalyticsSettings.perform();
  }

  @task
  *loadMessengerAnalyticsSettings() {
    this.messengerAnalyticsSettings = yield this.store.findRecord(
      'messenger-settings/analytics',
      this.appService.app.id,
    );
    this.customTrackingIdEnabled = !!this.googleAnalyticsTrackingId;
  }

  get googleAnalyticsTrackingId() {
    return this.messengerAnalyticsSettings?.googleAnalyticsTrackingId;
  }

  get saveDisabled() {
    return this.customTrackingIdEnabled && !this.isValidTrackingId;
  }

  get isValidTrackingId() {
    if (this.appService.app.canUseGoogleAnalytics4Integration) {
      return (
        VALID_GOOGLE_ANALYTICS_TRACKING_ID.test(this.googleAnalyticsTrackingId) ||
        VALID_GOOGLE_ANALYTICS_4_MEASUREMENT_ID.test(this.googleAnalyticsTrackingId)
      );
    }
    return VALID_GOOGLE_ANALYTICS_TRACKING_ID.test(this.googleAnalyticsTrackingId);
  }

  @action
  enableCustomTrackingId() {
    this.customTrackingIdEnabled = true;
  }

  @action
  disableCustomTrackingId() {
    this.customTrackingIdEnabled = false;
  }

  @task
  *updateChanges() {
    try {
      let settings = this.messengerAnalyticsSettings;
      if (!this.customTrackingIdEnabled) {
        settings.set('googleAnalyticsTrackingId', null);
      }
      yield settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'app.components.appstore.app-package.settings.google-analytics.settings-updated',
        ),
      );
    } catch (error) {
      let errorMessage = error.jqXHR.responseJSON.errors[0].message;
      this.notificationsService.notifyResponseError(errorMessage, {
        default: errorMessage,
      });
    }
  }
}
