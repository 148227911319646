/* import __COLOCATED_TEMPLATE__ from './map-data.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { get, put } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import percent from 'embercom/lib/percentage-formatter';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import {
  CANCELLED,
  STOPPING,
  PREPARING,
  PROCESSING,
  COMPLETED,
  COMPLETED_WITH_ERRORS,
} from 'embercom/lib/app-store/salesforce/settings-constants';
import {
  syncDirection,
  IntercomEmailAttributeName,
  SalesforceEmailFieldName,
} from 'embercom/models/crm/attribute-mapping-constants';
import { isBlank, isPresent } from '@ember/utils';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { captureException } from 'embercom/lib/sentry';

const STOPPABLE_BULK_SYNC_STATES = [PREPARING, PROCESSING];
const TERMINAL_BULK_SYNC_STATES = [COMPLETED, COMPLETED_WITH_ERRORS, CANCELLED];

export default class MapData extends Component {
  @service store;
  @service appService;
  @service attributeService;
  @service intl;
  @service notificationsService;
  @service intercomEventService;
  @service intercomConfirmService;

  @tracked peopleSyncData = {};
  @tracked showAttributeMappingModal = false;
  @tracked showBulkSyncModal = false;
  @tracked selectedSalesforceObjectType = 'Lead';
  @tracked selectedMappingId;
  @tracked selectedIntercomAttr;
  @tracked selectedSalesforceAttr;
  @tracked selectedSyncDirection;
  @tracked selectedDisableEmptySourceSync = true;
  @tracked selectedDisableNonEmptyDestinationSync = false;
  @tracked hideSyncWarningBanner = false;

  constructor() {
    super(...arguments);

    this.fetchPeopleSyncData.perform();
    this.bulkSyncStatusPollingTask.perform();
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.peopleSyncData.rollbackAttributes();
  }

  @task({ restartable: true })
  *fetchPeopleSyncData() {
    this.peopleSyncData = yield this.store.queryRecord(
      'appstore/app-package/salesforce/people-sync-data',
      { section_id: 'people_sync' },
    );
  }

  get settingsAreDirty() {
    return this.peopleSyncData.hasDirtyAttributes;
  }

  get selectedIdentityMappingField() {
    return this.peopleSyncData.settings.salesforceIdentityMappingField !== SalesforceEmailFieldName
      ? this.peopleSyncData.settings.salesforceIdentityMappingField
      : undefined;
  }

  get isSaveButtonDisabled() {
    let result =
      !this.settingsAreDirty ||
      (this.peopleSyncData.settings.intercomIdentityMappingAttribute === 'user_id' &&
        isEmpty(this.selectedIdentityMappingField));

    this.args.setSettingsAreDirty(!result);

    return result;
  }

  get leadAttributeMappings() {
    return this.peopleSyncData.attributeMappings.filter((attributeMapping) => {
      return (
        (attributeMapping.direction === syncDirection.IntercomToCRM &&
          attributeMapping.destinationObjectType === 'Lead') ||
        (attributeMapping.direction === syncDirection.CrmtoIntercom &&
          attributeMapping.sourceObjectType === 'Lead')
      );
    });
  }

  get contactAttributeMappings() {
    return this.peopleSyncData.attributeMappings.filter((attributeMapping) => {
      return (
        (attributeMapping.direction === syncDirection.IntercomToCRM &&
          attributeMapping.destinationObjectType === 'Contact') ||
        (attributeMapping.direction === syncDirection.CrmtoIntercom &&
          attributeMapping.sourceObjectType === 'Contact')
      );
    });
  }

  get attributeMappings() {
    return this.selectedSalesforceObjectType === 'Lead'
      ? this.leadAttributeMappings
      : this.contactAttributeMappings;
  }

  get salesforceFields() {
    return this.selectedSalesforceObjectType === 'Lead'
      ? this.peopleSyncData.salesforceLeadFields
      : this.peopleSyncData.salesforceContactFields;
  }

  get salesforceDropdownFields() {
    return this.salesforceFields.map((field) =>
      field.asCrmAttributeMappingOption(
        this.peopleSyncData.settings.salesforceIdentityMappingField,
        this.attributeMappings,
        'contact',
        this.selectedSalesforceObjectType,
      ),
    );
  }

  get supportedFieldsGroupList() {
    let items = this.peopleSyncData.identityMappingSupportedFields.map((field) => {
      let tooltipText = this.isSalesforceAttributeMappingField(field.name)
        ? 'This field is already used in an attribute mapping below'
        : null;

      return {
        text: field.name,
        value: field.name,
        tooltipText,
        isDisabled: this.isSalesforceAttributeMappingField(field.name),
      };
    });

    return [{ items }];
  }

  get intercomAttributeMappingOptions() {
    let intercomAttributes = this.attributeService.crmUserAttributes;

    let shouldUseQualificationAttributes = this.selectedSalesforceObjectType === 'Lead';
    if (shouldUseQualificationAttributes) {
      let qualificationAttributes = this.appService.app.qualification_attributes.content.map(
        (attribute) => attribute.dataAttribute,
      );
      intercomAttributes = intercomAttributes.concat(qualificationAttributes).uniqBy('identifier');
    }

    let shouldUseLeadCreateOnlyAttributes = this.selectedSalesforceObjectType === 'Lead';
    if (!shouldUseLeadCreateOnlyAttributes) {
      intercomAttributes = intercomAttributes.filter((attribute) => !attribute.crm_create_only);
    }

    return intercomAttributes.map((attribute) =>
      attribute.asCrmAttributeMappingOption(
        [IntercomEmailAttributeName, 'user_id'],
        this.attributeMappings,
        'leads and users',
        'Lead',
      ),
    );
  }

  get bulkSyncProgressPercentage() {
    let percentage = percent(
      this.peopleSyncData.lastBulkSyncStatus.totalItemsCount,
      this.peopleSyncData.lastBulkSyncStatus.finishedItemsCount,
    );

    if (isPresent(percentage)) {
      return Math.floor(percentage);
    }
  }

  isSalesforceAttributeMappingField(field) {
    let sourceMapping = this.attributeMappings.findBy('sourceAttributePath', field);
    let destinationMapping = this.attributeMappings.findBy('destinationAttributePath', field);
    return (
      (sourceMapping && sourceMapping.direction === syncDirection.CrmtoIntercom) ||
      (destinationMapping && destinationMapping.direction === syncDirection.IntercomToCRM)
    );
  }

  @task({ enqueue: true })
  *saveSettings() {
    if (yield this._ensureOngoingBulkSyncStopped.perform()) {
      try {
        if (
          this.peopleSyncData.settings.intercomIdentityMappingAttribute ===
          IntercomEmailAttributeName
        ) {
          this.peopleSyncData.settings.salesforceIdentityMappingField = SalesforceEmailFieldName;
          this.peopleSyncData.settings.identityMappingEmailFallbackEnabled = false;
        }
        yield this.peopleSyncData.save();

        let notificationMessage = 'Your settings were updated';
        this.notificationsService.notifyConfirmation(notificationMessage);
      } catch (e) {
        if (e.jqXHR?.responseJSON?.errors) {
          this.notificationsService.notifyError(e.jqXHR.responseJSON.errors);
        } else {
          this.notificationsService.notifyError(
            "Something went wrong and we couldn't update your Salesforce settings",
          );
        }
      }
    }
  }

  @task({ enqueue: true })
  *_ensureOngoingBulkSyncStopped() {
    if (!this.appService.app.canUseHistoricalBulkSync || this.bulkSyncCanBeStarted) {
      return true;
    }
    if (!(yield this._confirmStopBulkSync({ isSaveAndStop: true }))) {
      return false;
    }

    try {
      yield this._stopBulkSyncRequest();
      return true;
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('appstore.settings.salesforce-by-intercom.notification.stop-bulk-sync-failure'),
      );
      return false;
    }
  }

  @action
  onMappingFieldChange(selectedItem) {
    this.peopleSyncData.settings.salesforceIdentityMappingField = selectedItem;
  }

  @action
  enableEmailFallback() {
    if (
      this.peopleSyncData.settings.intercomIdentityMappingAttribute ===
        IntercomEmailAttributeName &&
      !this.peopleSyncData.settings.hasDirtyAttributes
    ) {
      this.peopleSyncData.settings.identityMappingEmailFallbackEnabled = true;
    }
  }

  @action
  closeModal() {
    this.selectedMappingId = undefined;
    this.selectedIntercomAttr = undefined;
    this.selectedSalesforceAttr = undefined;
    this.selectedSyncDirection = undefined;
    this.selectedDisableEmptySourceSync = true;
    this.selectedDisableNonEmptyDestinationSync = false;
    this.showAttributeMappingModal = false;
  }

  @action
  closeBulkSyncModal() {
    this.showBulkSyncModal = false;
  }

  @action
  openModal(row) {
    this.selectedMappingId = row.id;
    this.selectedIntercomAttr = row.isIntercomAttributeMissing
      ? undefined
      : row.intercomAttributePath;
    this.selectedSalesforceAttr = row.isSalesforceFieldMissing
      ? undefined
      : row.salesforceFieldPath;
    this.selectedSyncDirection = row.direction;
    this.selectedDisableEmptySourceSync = row.disableEmptySourceSync;
    this.selectedDisableNonEmptyDestinationSync = row.disableNonEmptyDestinationSync;
    this.showAttributeMappingModal = true;
  }

  @action
  openBulkSyncModal() {
    this.showBulkSyncModal = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'bulk_sync_start_button',
      place: 'salesforce_settings',
      section: 'sync_people_data',
    });
  }

  @action
  removeAttributeMapping(attributeMapping) {
    this.peopleSyncData.attributeMappings.removeFragment(attributeMapping);
  }

  @action async openStopBulkSyncModal() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'bulk_sync_stop_button',
      place: 'salesforce_settings',
      section: 'sync_people_data',
    });

    if (await this._confirmStopBulkSync({ isSaveAndStop: false })) {
      try {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'clicked',
          object: 'bulk_sync_stop_confirm',
          place: 'salesforce_settings',
          section: 'sync_people_data',
        });

        await this._stopBulkSyncRequest();

        this.notificationsService.notifyConfirmation(
          this.intl.t(
            'appstore.settings.salesforce-by-intercom.notification.stop-bulk-sync-success',
          ),
        );
      } catch (e) {
        this.notificationsService.notifyError(
          this.intl.t(
            'appstore.settings.salesforce-by-intercom.notification.stop-bulk-sync-failure',
          ),
        );
      }
    }
  }

  get bulkSyncCanBeStarted() {
    return !this.bulkSyncCanBeStopped;
  }

  get bulkSyncCanBeStopped() {
    return STOPPABLE_BULK_SYNC_STATES.includes(this.peopleSyncData.lastBulkSyncStatus?.state);
  }

  async _confirmStopBulkSync({ isSaveAndStop }) {
    return await this.intercomConfirmService.confirm(
      this._generateOptionsForStopBSyncModal(isSaveAndStop),
    );
  }

  async _stopBulkSyncRequest() {
    let response = await put('/ember/salesforce_integrations/stop_bulk_sync', {
      app_id: this.appService.app.id,
    });

    this._updateBulkSyncStatus({ ...response, state: STOPPING });
  }

  @action
  async startBulkSync() {
    try {
      await put('/ember/salesforce_integrations/start_bulk_sync', {
        app_id: this.appService.app.id,
      });

      this._updateBulkSyncStatus({ state: PREPARING });
      this.bulkSyncStatusPollingTask.perform();

      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'appstore.settings.salesforce-by-intercom.notification.start-bulk-sync-success',
        ),
      );

      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'bulk_sync_start_confirm',
        place: 'salesforce_settings',
        section: 'sync_people_data',
      });
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t(
          'appstore.settings.salesforce-by-intercom.notification.start-bulk-sync-failure',
        ),
      );
    }
  }

  @task({ restartable: true })
  *bulkSyncStatusPollingTask() {
    if (ENV.environment === 'test') {
      return;
    }

    let statusUpdateRetryCount = 0;
    while (true) {
      yield timeout(this._bulkSyncStatusUpdateBackoff(statusUpdateRetryCount));

      if (this._isBulkSyncInTerminalState) {
        return;
      }

      yield this._refreshBulkSyncStatus();

      statusUpdateRetryCount += 1;
    }
  }

  _bulkSyncStatusUpdateBackoff(statusUpdateRetryCount) {
    if (statusUpdateRetryCount > 50) {
      return ENV.APP._10000MS;
    } else if (statusUpdateRetryCount > 40) {
      return ENV.APP._7000MS;
    } else if (statusUpdateRetryCount > 25) {
      return ENV.APP._5000MS;
    } else if (statusUpdateRetryCount > 10) {
      return ENV.APP._3000MS;
    } else {
      return ENV.APP._2000MS;
    }
  }

  get _isBulkSyncInTerminalState() {
    return (
      isBlank(this.peopleSyncData.lastBulkSyncStatus?.state) ||
      TERMINAL_BULK_SYNC_STATES.includes(this.peopleSyncData.lastBulkSyncStatus.state)
    );
  }

  async _refreshBulkSyncStatus() {
    try {
      let response = await get('/ember/salesforce_integrations/bulk_sync_status', {
        app_id: this.appService.app.id,
      });
      this._updateBulkSyncStatus(response);
    } catch (error) {
      captureException(error, {
        tags: {
          responsibleTeam: 'team-customer-data-platform',
          responsible_team: 'team-customer-data-platform',
        },
      });
    }
  }

  _generateOptionsForStopBSyncModal(isSaveAndStop) {
    return {
      title: this.intl.t('appstore.settings.salesforce-by-intercom.stop-bulk-sync-modal.title'),
      bodyComponentName:
        'appstore/app-package/settings/salesforce-by-intercom/stop-bulk-sync-modal-body',
      confirmButtonText: isSaveAndStop
        ? this.intl.t('appstore.settings.salesforce-by-intercom.stop-bulk-sync-modal.stop-and-save')
        : this.intl.t(
            `appstore.settings.salesforce-by-intercom.stop-bulk-sync-modal.stop-${this._bulkSyncStateForStopSyncModal}`,
          ),
      cancelButtonText: this.intl.t(
        'appstore.settings.salesforce-by-intercom.stop-bulk-sync-modal.cancel',
      ),
      confirmContext: {
        progressPercentage: this.bulkSyncProgressPercentage,
        isSaveAndStop,
        bulkSyncState: this._bulkSyncStateForStopSyncModal,
      },
    };
  }

  get _bulkSyncStateForStopSyncModal() {
    if (isBlank(this.peopleSyncData.lastBulkSyncStatus?.state)) {
      return PREPARING;
    }
    return this.peopleSyncData.lastBulkSyncStatus.state;
  }

  _updateBulkSyncStatus(newBulkSyncStatusAttributes) {
    this.store.push(
      this.store.normalize('appstore/app-package/salesforce/people-sync-data', {
        id: this.peopleSyncData.id,
        last_bulk_sync_status: {
          ...this.peopleSyncData.lastBulkSyncStatus?.serialize(),
          ...newBulkSyncStatusAttributes,
        },
      }),
    );
  }
}
