/* import __COLOCATED_TEMPLATE__ from './answer-summary.hbs'; */
/* RESPONSIBLE TEAM: team-ml */

import Component from '@glimmer/component';
import { type PlaygroundTestRun } from 'embercom/lib/fin-playground';

interface AiAgentPlaygroundAnswerSummaryArgs {
  test: PlaygroundTestRun;
}

export default class AiAgentPlaygroundAnswerSummary extends Component<AiAgentPlaygroundAnswerSummaryArgs> {
  get directAnswerPercentage(): number {
    let { test } = this.args;
    if (!test.questions.length) {
      return 0;
    }

    let directAnswers = test.questions.filter((q) => !q.isNotDirectAnswer).length;
    return Math.round((directAnswers / test.questions.length) * 100);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::AnswerSummary': typeof AiAgentPlaygroundAnswerSummary;
  }
}
