/* import __COLOCATED_TEMPLATE__ from './cluster-list.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { A } from '@ember/array';
import { timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { SUGGESTED_ANSWERS_ANIMATION_LENGTH } from 'embercom/transition-maps/answers-transitions';
import { dropTask } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';

const CLUSTER_GROUP_SIZE = 3;

interface Signature {
  Args: {
    suggestedAnswerClusters: any;
    loadingAnimationIsActive: boolean;
    onSelectCluster: any;
    onEditRoute: boolean;
    languageCode: any;
  };
}

export default class ClusterList extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare appService: any;
  get clusterGroups() {
    let clusters = this.args.suggestedAnswerClusters;
    let clusterGroups = A();
    for (let i = 0; i < clusters.get('length'); i += CLUSTER_GROUP_SIZE) {
      let newClusterGroup = A(clusters.slice(i, i + CLUSTER_GROUP_SIZE));
      clusterGroups.pushObject(newClusterGroup);
    }
    return clusterGroups;
  }
  @tracked currentGroupIndex = 0;
  get currentClusterGroup() {
    return this.clusterGroups[this.currentGroupIndex];
  }

  get showLeftArrow() {
    return this.currentGroupIndex > 0;
  }
  get showRightArrow() {
    return this.currentGroupIndex < this.clusterGroups.length - 1;
  }

  private _trackScroll(direction: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'scrolled',
      object: 'common_customer_questions',
      context: direction,
    });
  }

  @dropTask *showPreviousGroup() {
    this._trackScroll('previous');
    let targetGroupIndex = this.currentGroupIndex - 1;
    let targetGroup = this.clusterGroups[targetGroupIndex] as any;
    targetGroup.set('slideDirection', 'previous');
    this.currentGroupIndex -= 1;
    yield timeout(SUGGESTED_ANSWERS_ANIMATION_LENGTH);
  }

  @dropTask *showNextGroup() {
    this._trackScroll('next');
    let targetGroupIndex = this.currentGroupIndex + 1;
    let targetGroup = this.clusterGroups[targetGroupIndex] as any;
    targetGroup.set('slideDirection', 'next');
    this.currentGroupIndex += 1;
    yield timeout(SUGGESTED_ANSWERS_ANIMATION_LENGTH);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Answers::SuggestedAnswers::ClusterList': typeof ClusterList;
  }
}
