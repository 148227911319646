/* import __COLOCATED_TEMPLATE__ from './upsell-banner-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, readOnly, and, not, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import {
  PRODUCTS,
  MODAL_UPGRADE_PLAN_INFO,
  ARTICLES_ESSENTIAL_ID,
  ANSWER_BOT_ESSENTIAL_ID,
  OPERATOR_PRO_ID,
  ARTICLES_PRO_ID,
} from 'embercom/lib/billing';

export default Component.extend({
  purchaseAnalyticsService: service(),
  intercomEventService: service(),
  appService: service(),
  customerService: service(),
  customer: alias('customerService.customer'),
  app: alias('appService.app'),

  didRender() {
    this._super(...arguments);
    if (this.plan) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'upsell-banner',
        context: this.planId,
        productName: this.get('product.key'),
        place: 'billing',
      });
    }
  },

  productIcon: computed('plan', function () {
    return `products/id-${this.get('plan.productId')}-brand-refresh-product-card`;
  }),

  product: computed('plan', function () {
    return PRODUCTS[this.get('plan.productId')];
  }),

  customBotsIsActive: readOnly('app.customBotsIsActive'),
  onlyInboxIsActive: and('app.onlyOneProductIsActive', 'app.inboxIsActive'),
  notOnlyInboxIsActive: not('onlyInboxIsActive'),

  answerBotIsActive: readOnly('app.answerBotIsActive'),
  answerBotIsNotActive: not('answerBotIsActive'),
  hasCustomBotsOrOnlyInbox: or('customBotsIsActive', 'onlyInboxIsActive'),
  notEarlyStageApplicant: not('app.validEarlyStageApplicant'),
  notCurrentlyOnEarlyStage: not('customer.currentlyOnEarlyStage'),
  notEarlyStage: and('notEarlyStageApplicant', 'notCurrentlyOnEarlyStage'),
  showAnswerBotBanner: and('answerBotIsNotActive', 'hasCustomBotsOrOnlyInbox', 'notEarlyStage'),

  customBotsIsNotActive: not('customBotsIsActive'),
  showCustomBotsBanner: and('customBotsIsNotActive', 'notOnlyInboxIsActive'),

  articlesProIsNotActive: not('app.articlesProduct.hasProPlan'),
  doesNotHaveArticles: readOnly('app.articlesProduct.inactive'),

  planId: computed(
    'doesNotHaveArticles',
    'articlesProIsNotActive',
    'showAnswerBotBanner',
    'showCustomBotsBanner',
    function () {
      if (this.showAnswerBotBanner) {
        return ANSWER_BOT_ESSENTIAL_ID;
      } else if (this.showCustomBotsBanner) {
        return OPERATOR_PRO_ID;
      } else if (this.doesNotHaveArticles) {
        return ARTICLES_ESSENTIAL_ID;
      } else if (this.articlesProIsNotActive) {
        return ARTICLES_PRO_ID;
      }
    },
  ),

  plan: computed('planId', function () {
    return MODAL_UPGRADE_PLAN_INFO[this.planId];
  }),

  featureKey: readOnly('product.uniqueFeatureKey'),
});
