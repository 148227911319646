/* import __COLOCATED_TEMPLATE__ from './attribute-plan-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import { isPresent } from '@ember/utils';

export default class AttributePlanComponent extends Component {
  get value() {
    return this.args.userOrCompany.plan.get('name');
  }

  get isNotBlank() {
    return isPresent(this.value);
  }

  get filterUrl() {
    return B64FilterParamEncoder.filterUrl(
      this.args.userOrCompany,
      this.args.attribute,
      this.args.userOrCompany.plan.get('id'),
    );
  }

  get tooltipText() {
    return `${this.value}`;
  }
}
