/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import ajax from 'embercom/lib/ajax';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import redirect from 'embercom/lib/redirect';
import { task } from 'ember-concurrency';

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  classNames: ['flex-auto'],
  store: service(),
  defaultSource: 'app-store',
  source: ternaryToProperty('installSource', 'installSource', 'defaultSource'),
  settings: null,
  modelName: computed('calendarType', function () {
    return `appstore/app-package/${this.calendarType}-calendar-meetings/app-settings`;
  }),

  authenticationURL: computed('calendarType', 'app.id', 'source', function () {
    return `/admin/apps/${this.app.id}/${this.calendarType}_calendar_auth?source=${this.source}`;
  }),

  uninstallUrl: computed('calendarType', function () {
    return `app_packages/${this.calendarType}_calendar_meetings/uninstall`;
  }),

  init() {
    this._super(...arguments);
    this.registerHooks({ afterUninstall: this.cleanupPostUninstall });
  },

  didInsertElement() {
    this._super(...arguments);
    this.fetchSettings.perform();
  },

  willDestroyElement() {
    this._super(...arguments);
    this.set('settings', null);
  },

  fetchSettings: task(function* () {
    try {
      let settings = yield this.store.findRecord(this.modelName, this.get('admin.id'));
      this.set('settings', settings);
    } catch (error) {
      this.set('settings', null);
    }
  }).drop(),

  saveSettings: task(function* () {
    try {
      yield this.settings.save();
      this.notificationsService.notifyConfirmation('You’ve saved your Meeting settings.');
    } catch (error) {
      if (error.jqXHR.status === 409 || error.jqXHR.status === 400) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else if (error.jqXHR.status === 404) {
        this.set('settings', null);
        this.notificationsService.notifyError(
          'Your calendar has been disconnected. Please reconnect your calendar.',
        );
      } else {
        this.notificationsService.notifyError('Something went wrong. Please try again');
      }
    }
  }),

  _redirect(url) {
    redirect(url);
  },

  revokeAuthorizationTask: task(function* () {
    try {
      yield ajax({
        url: this.authenticationURL,
        type: 'DELETE',
      });
      this.settings.deleteRecord();
      this.notificationsService.notifyConfirmation(
        `You have successfully disconnected your calendar from this app.`,
      );
      this.set('settings', null);
    } catch (error) {
      this.notificationsService.notifyError(
        'There was a problem revoking access to your calendar. Please try again. If the problem persists please contact us.',
      );
    }
  }).drop(),

  cleanupPostUninstall: task(function* () {
    try {
      yield ajax({
        url: this.uninstallUrl,
        type: 'POST',
        data: JSON.stringify({
          app_id: this.get('app.id'),
        }),
      });
      if (this.settings) {
        this.store.unloadRecord(this.settings);
      }
    } catch (e) {
      this.notificationsService.notifyError(
        'An error occurred while uninstalling the app. Please try again.',
      );
    }
  }).drop(),

  save: action(function () {
    this.saveSettings.perform();
  }),

  redirectToAuth: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'authenticate',
      object: this.calendarType,
      place: 'settings',
      context: 'meeting_scheduler',
    });
    redirect(this.authenticationURL);
  }),

  revokeAccess: action(function () {
    this.revokeAuthorizationTask.perform();
  }),
});
