/* import __COLOCATED_TEMPLATE__ from './article-group-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';

export default class ArticleGroupFilter extends Component {
  @service intl;
  @service intercomEventService;

  @tracked selectedValues = [];

  filterResetable = modifier((_, [v]) => {
    let newValues = v || [];

    this.onSelectionChange(newValues.slice());
  });

  get isOpen() {
    return this.args.activeFilter === 'collection_ids';
  }

  get availableItems() {
    return this.args.availableItems || [];
  }

  get hasFilter() {
    return this.items.length >= 8;
  }

  get items() {
    let result = [
      {
        text: this.intl.t('articles.shared.group_filter.no_collection_label'),
        value: '',
        type: 'collection',
        isSelected: this.selectedValues ? this.selectedValues.includes('') : false,
        component: 'articles/shared/article-group-filter-option',
      },
    ];
    result.push(
      ...this.availableItems.map((item) => ({
        text: item.text,
        value: item.value,
        type: item.type,
        isSelected: this.selectedValues ? this.selectedValues.includes(item.value) : false,
        component: 'articles/shared/article-group-filter-option',
      })),
    );
    return result;
  }

  get details() {
    let count = this.selectedValues.length;

    return this.intl.t('articles.shared.group_filter.details', {
      firstItem: count ? this._getDisplayName(this.selectedValues[0]) : '',
      count,
      additional: count - 1,
    });
  }

  get label() {
    return this.intl.t('articles.shared.group_filter.label');
  }

  get searchPlaceHolder() {
    return this.intl.t('articles.shared.group_filter.search_placeholder');
  }

  _getDisplayName(v) {
    let matched = this.items.find((e) => e.value === v);
    if (!matched) {
      return '';
    }
    return matched.text;
  }

  @action deleteSelectedValues() {
    this.onSelectionChange([]);
    this.args.filterContent('collection_ids', this.selectedValues);
  }

  @action onSelectionChange(selectedValues) {
    this.selectedValues = selectedValues;
  }

  @action onOpen() {
    if (this.isOpen) {
      return;
    }

    this.args.updateActiveFilter('collection_ids');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'article_group_filter',
    });
  }

  @action onClose() {
    this.args.updateActiveFilter(undefined);
    let original = this.args.selectedValues || [];
    if (
      original.length === this.selectedValues.length &&
      original.every((value, index) => value === this.selectedValues[index])
    ) {
      return;
    }

    this.args.filterContent('collection_ids', this.selectedValues);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'applied_filter',
      object: 'article_group_filter',
      count: this.selectedValues.length,
      values: this.selectedValues.map((v) => this._getDisplayName(v)),
    });
  }
}
