/* import __COLOCATED_TEMPLATE__ from './notification-rule-assignee-select.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed, action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  appService: service(),
  store: service(),
  app: readOnly('appService.app'),
  tagName: 'div',
  classNames: ['integrations__slack__selection-container'],

  selectableAssignees: computed('app.assignableAdmins', function () {
    let allTeams = this.store.createRecord('admin', {
      name: 'All teams',
    });
    let resultSet = [allTeams];

    this.get('app.assignableAdmins').forEach((admin) => {
      resultSet.push(admin);
    });
    return resultSet;
  }),

  selectAssignee: action(function (assignee) {
    let updatableAssignee = null;
    if (assignee.get('id') !== null) {
      updatableAssignee = assignee;
    }
    this.updateAssignee(updatableAssignee);
  }),
});
