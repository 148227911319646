/* import __COLOCATED_TEMPLATE__ from './bounced-email-important-admin-notification-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import { post } from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class BouncedEmailImportantAdminNotificationComponent extends Component {
  @service appService;
  @service modelDataCacheService;

  @tracked dismissed = false;

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.app.currentAdmin;
  }

  get notifications() {
    return this.admin.important_admin_notifications.filter(
      (n) => n.notification_type === 'bounced_email_removed',
    );
  }

  get notification() {
    return this.notifications.firstObject;
  }

  get hasNotifications() {
    return !this.dismissed && this.notification;
  }

  @action
  async dismiss() {
    this.dismissed = true;
    try {
      await post('/ember/important_admin_notifications/acknowledge', {
        app_id: this.app.id,
        id: this.notification.id,
      });
    } catch (error) {
      captureException(error, {
        fingerprint: ['important-admin-notifications', 'failed-to-acknowledge'],
        extra: {
          notification: this.notification,
        },
      });
      console.error('failed to acknowledge notification');
    }
    this.modelDataCacheService.clear();
  }
}
