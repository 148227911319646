/* import __COLOCATED_TEMPLATE__ from './attachment-list-styles.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
}

const AttachmentListStyles = templateOnlyComponent<Signature>();
export default AttachmentListStyles;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Composer::AttachmentListStyles': typeof AttachmentListStyles;
  }
}
