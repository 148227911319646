/* import __COLOCATED_TEMPLATE__ from './usage-for-current-period.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
export default class UsageForCurrentPeriod extends Component {
  @service appService;
  @service customerService;

  get app() {
    return this.appService.app;
  }

  get subscription() {
    return this.customerService.customer.subscription;
  }

  get hasMultiWorkspaces() {
    return this.subscription
      ? this.subscription.isAnnualContractSecondarySubscription ||
          this.subscription.numberOfSecondarySubscriptions > 0
      : false;
  }

  get contractedResolutionsAmount() {
    if (this.app.usesResolutionsWithCustomAnswers) {
      return this.args.contract.contractUsageLimits.resolutions_with_custom_answers;
    }

    return this.args.contract.contractUsageLimits.resolutions;
  }
}
