/* import __COLOCATED_TEMPLATE__ from './chart.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import Range from 'embercom/models/reporting/range';
import moment from 'moment-timezone';
import CustomUsageLinechartBuilder from 'embercom/lib/billing/usage/custom-usage-linechart-builder';
import CustomUsageSerieschartBuilder from 'embercom/lib/billing/usage/custom-usage-serieschart-builder';
import ViewConfig from 'embercom/lib/reporting/flexible/view-config';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import ValueFormatter from 'embercom/lib/reporting/flexible/formatters/value';
import IntervalFormatter from 'embercom/lib/reporting/flexible/interval-formatter';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import { inject as service } from '@ember/service';

export default class BillingUsageChart extends Component {
  @service intl;

  get chartData() {
    return [
      {
        data:
          this.args.chartData && this.args.chartData.length > 0
            ? this.args.chartData
            : [{ id: 'noData', data: 0, color: '#aaa', showInLegend: false }],
        showInLegend: false,
      },
    ];
  }

  get chartOptions() {
    if (this.args.chartType === VISUALIZATION_TYPES.LINE) {
      return this.lineChartOptions;
    }

    if (this.args.chartType === VISUALIZATION_TYPES.BAR) {
      return this.barChartOptions;
    }
  }

  get lineChartOptions() {
    let options = {
      range: this.range,
      chartData: this.chartData,
      width: 'small',
      viewConfig: this.viewConfig,
      xAxisType: 'temporal',
    };

    let builder = new CustomUsageLinechartBuilder(options);

    return builder.buildTheme();
  }

  get barChartOptions() {
    let options = {
      range: this.range,
      chartData: this.chartData,
      width: 'small',
      viewConfig: this.viewConfig,
      dataConfig: this.dataConfig,
    };

    let builder = new CustomUsageSerieschartBuilder(options);

    return builder.buildTheme();
  }

  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value };
    viewConfig.tooltipFormatter = this.buildTooltipFormatter();
    viewConfig.formatYAxisAsPrice = this.args.formatAsPrice;
    viewConfig.visualizationOptions = {
      target: this.args.target,
    };
    viewConfig.useDarkTooltips = true;

    return viewConfig;
  }

  get dataConfig() {
    return {
      xAxis: {
        type: 'temporal',
        data: {
          interval: this.range.interval,
        },
      },
    };
  }

  buildTooltipFormatter() {
    let component = this;

    return function () {
      let formattedData = component.getFormattedData(this.y, component);

      let intervalFormatter = new IntervalFormatter('day');
      let datePart = intervalFormatter.datePart(this.x, this.timezone);
      let cssClass = 'reporting__highcharts-tooltip';

      return `<div class='${cssClass}'><strong>${formattedData}</strong><br/>(${datePart})</div>`;
    };
  }

  getFormattedData(yAxisData, component) {
    let valueFormatter = new ValueFormatter();
    let value = valueFormatter.formatData(yAxisData);
    return component.args.formatAsPrice
      ? valueFormatter.formatPriceFromCents(value)
      : valueFormatter.formatValue(value);
  }

  get range() {
    let startDate = moment.tz(this.currentTimestamp, this.timezone).startOf('day').format();
    let endDate = moment
      .tz(this.currentTimestamp, this.timezone)
      .add(this.numberOfDaysPastInBillingCycle, 'days')
      .format();
    return Range.createFromParams(startDate, endDate, this.timezone);
  }

  get numberOfDaysPastInBillingCycle() {
    return this.args.chartData.length;
  }

  get currentTimestamp() {
    return this.args.chartData.length > 0 ? this.args.chartData[0][0] : new Date().getTime();
  }

  get timezone() {
    return 'UTC';
  }
}
