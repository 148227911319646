/* import __COLOCATED_TEMPLATE__ from './code-text-area.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { bind } from '@ember/runloop';
import $ from 'jquery';
import { typeOf } from '@ember/utils';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import { computed } from '@ember/object';
import { maybeImportCodeMirror } from 'embercom/lib/import-code-mirror';

export default Component.extend({
  classNames: ['flex-auto'],
  attributeBindings: ['data-test-code-text-area'],
  'data-test-code-text-area': true,

  mode: 'htmlmixed',
  theme: 'monokai',
  lint: false,
  gutters: [],
  isDevOrTest: computed(function () {
    return ENV.environment === 'development' || ENV.environment === 'test';
  }),

  didInsertElement() {
    this._super(...arguments);
    this.loadCodeMirrorTask.perform();
  },

  willDestroyElement() {
    $('script', this.element).off('load');
    $('.CodeMirror', this.element).off('change');
    if (this.codeMirrorEditor) {
      this.codeMirrorEditor.toTextArea();
    }
    this._super(...arguments);
  },

  _sendUpdateAction() {
    let sendUpdate = this.update;
    if (typeOf(sendUpdate) === 'function') {
      let currentValue = this.codeMirrorEditor.getValue();
      sendUpdate(currentValue);
    }
  },

  loadCodeMirrorTask: task(function* () {
    try {
      yield maybeImportCodeMirror();

      this._initializeCodeMirror();
    } catch (error) {
      this.set('hasErrored', true);
      console.error(error);
    }
  }).drop(),

  _initializeCodeMirror() {
    let codeMirrorEditor = window.CodeMirror.fromTextArea($('textarea', this.element).get(0), {
      lineNumbers: true,
      tabSize: 2,
      mode: this.mode,
      theme: this.theme,
      gutters: this.gutters,
      lint: this.lint,
      lineWrapping: this.enableLineWrapping,
    });
    codeMirrorEditor.setSize('100%', '100%');

    if (this.value) {
      codeMirrorEditor.setValue(this.value);
    }
    this.set('codeMirrorEditor', codeMirrorEditor);
    codeMirrorEditor.on('change', bind(this, this._sendUpdateAction));
    $(this.element).trigger('codemirrorloaded');
  },

  actions: {
    onObjectChange() {
      this.codeMirrorEditor.setValue(this.value);
    },
  },
});
