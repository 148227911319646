/* import __COLOCATED_TEMPLATE__ from './expandable-list.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const INITIAL_VISIBLE_ITEM_COUNT = 3;

export default class ExpandableList extends Component {
  @tracked isCollapsed = true;

  get listToShow() {
    if (this.isCollapsed) {
      return this.args.list.slice(0, INITIAL_VISIBLE_ITEM_COUNT);
    } else {
      return this.args.list;
    }
  }

  get canExpand() {
    return this.args.list.length > INITIAL_VISIBLE_ITEM_COUNT;
  }

  get needsExpander() {
    if (this.args.needsExpander !== undefined) {
      return this.args.needsExpander;
    } else {
      return true;
    }
  }

  get showExpander() {
    return this.isCollapsed && this.canExpand && this.needsExpander;
  }

  @action
  expandList(e) {
    e.stopImmediatePropagation();
    this.isCollapsed = false;
    if (this.args.onExpand) {
      this.args.onExpand();
    }
  }
}
