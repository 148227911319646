/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import extractResponseError from 'embercom/lib/extract-response-error';

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  store: service(),
  intercomConfirmService: service(),
  redirectService: service(),
  regionService: service(),
  slackInstallationIncomplete: false,
  slackSetupFailed: false,

  init() {
    this._super(...arguments);
    this.setupSlackData.perform();
  },

  setupSlackData: task(function* () {
    try {
      let slackIntegration = yield this.store.queryRecord('slack-integration', {});
      this.set('slackIntegration', slackIntegration);
    } catch (e) {
      if (extractResponseError(e).status !== 404) {
        this.set('slackSetupFailed', true);
        this.notificationsService.notifyError(
          "Something went wrong and we couldn't load your Slack settings",
        );
      } else {
        this.set('slackInstallationIncomplete', true);
        this.notificationsService.notifyError(
          'Slack installation incomplete, please reauthenticate with slack',
        );
      }
    }
  }).drop(),

  authenticateSlack: task(function* () {
    let redirectUrl = yield this.generateRedirectUrl.perform(
      this.regionService.slackInstallStartURL,
      false,
    );
    this.redirectService.redirect(redirectUrl);
  }),

  deleteRuleTask: task(function* (notificationRule) {
    let options = {
      title: 'Delete this notification?',
      body: 'Deleting this notification will stop it going to your Slack channel.',
      confirmButtonText: 'Delete',
      primaryButtonType: 'primary-destructive',
    };

    let confirmed = yield this.intercomConfirmService.confirm(options);
    if (confirmed) {
      this.intercomEventService.trackEvent('removed');
      yield notificationRule.destroyRecord();
      this.notificationsService.notifyConfirmation('Notification deleted');
    }
  }).drop(),

  saveRuleTask: task(function* (notificationRuleData) {
    let notificationRule = this.selectedNotificationRule;
    let analyticsAction = 'updated';
    if (notificationRule === null || notificationRule === undefined) {
      notificationRule = this.get('slackIntegration.notificationRules').createRecord();
      analyticsAction = 'created';
    }
    notificationRule.setProperties(notificationRuleData);

    try {
      yield notificationRule.save();
      this.intercomEventService.trackEvent(analyticsAction);
      this.notificationsService.notifyConfirmation('Notification successfully saved');
      this.closeNotificationEditor();
      this.notificationsService.notifyConfirmation('Notification successfully saved');
    } catch (e) {
      this.notificationsService.notifyError('An error occurred while saving this notification');
    }
  }).drop(),

  openNotificationEditor: action(function (notificationRule) {
    this.setProperties({
      selectedNotificationRule: notificationRule,
      showNotificationEditor: true,
    });
  }),

  closeNotificationEditor: action(function () {
    this.setProperties({
      showNotificationEditor: false,
      selectedNotificationRule: null,
    });
  }),
});
