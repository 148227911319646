/* import __COLOCATED_TEMPLATE__ from './input-with-attribute-inserter.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { htmlUnescape } from 'embercom/lib/html-unescape';
import { sanitizeUrl } from '@intercom/pulse/lib/sanitize';

class ComposerConfig extends BaseConfig {
  placeholder = '';
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  singleBlockMode = true;
  allowTextAlignment = false;
  isPlaintext = true;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  allowedInline = [];
  experimental = {
    hideInsertersOnMouseOut: true,
  };

  constructor(app, includeAppAttributes, overrides = {}) {
    super(app);

    if (overrides.attributes) {
      this.attributes = overrides.attributes;
    } else {
      this.attributes = app.allowedAttributes;
    }

    let attributeResolverOverrides = {};
    if (overrides.hideAppAllowedAttributes) {
      attributeResolverOverrides.hideAppAllowedAttributes = overrides.hideAppAllowedAttributes;
    }
    if (overrides.manualAppAttributes) {
      attributeResolverOverrides.manualAppAttributes = overrides.manualAppAttributes;
    }
    if (overrides.selectedEvents) {
      attributeResolverOverrides.selectedEvents = overrides.selectedEvents;
    }

    this.templating = {
      picker: 'common/attribute-picker',
      resolver: new AttributeInfoResolver({
        attributes: this.attributes,
        includeAppAttributes,
        ...attributeResolverOverrides,
      }),
    };

    Object.assign(this, overrides);
  }
}

export default class InputWithAttributeInserter extends Component {
  blocksDoc;
  @service appService;

  constructor() {
    super(...arguments);
    this.composerConfig = new ComposerConfig(this.appService.app, this.includeAppAttributes, {
      placeholder: this.args.placeholder,
      attributes: this.args.attributes,
      manualAppAttributes: this.args.manualAppAttributes,
      hideAppAllowedAttributes: this.args.hideAppAllowedAttributes,
      selectedEvents: this.args.selectedEvents,
    });
    this.blocksDoc = new BlocksDocument(this.stringToBlocks);
  }

  get stringToBlocks() {
    return [{ type: 'paragraph', text: this.args.value, modelKey: 'common/blocks/paragraph' }];
  }

  get includeAppAttributes() {
    return this.args.includeAppAttributes ?? true;
  }

  @action
  onComposerChange(newBlocksDoc) {
    let blocks = newBlocksDoc.blocks;
    blocks.forEach((block) => (block.text = sanitizeUrl(htmlUnescape(block.text))));
    this.args.onChange(blocks.firstObject.text.toString());
  }
}
