/* import __COLOCATED_TEMPLATE__ from './intercom-pages-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { readOnly } from '@ember/object/computed';

export default Component.extend({
  intl: service(),
  appService: service(),
  store: service(),
  app: readOnly('appService.app'),
  get language() {
    return this.intl.t('components.admin.intercom-pages-component.default-language');
  },

  init() {
    this._super(...arguments);
    this.getIntercomPageSettings.perform();
  },

  getIntercomPageSettings: task(function* () {
    try {
      let settings = yield this.store.queryRecord('intercom-pages-profile', {});
      this.set('settings', settings);
    } catch (e) {
      if (e.jqXHR.status === 404) {
        let newSettings = this.store.createRecord('intercom-pages-profile');
        this.set('settings', newSettings);
      }
    }
  }).drop(),

  enterEditMode: action(function () {
    this.set('editableMode', true);
  }),

  save: action(function () {
    this.settings.save();
    this.get('settings.subPages').forEach((subPage) => subPage.save());
    this.set('editableMode', false);
  }),

  createNewSubPage: action(function () {
    this.store.createRecord('intercom-sub-page', {
      page: this.settings,
    });
  }),

  deleteSubPage: action(function (subPage) {
    subPage.destroyRecord();
  }),
});
