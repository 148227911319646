/* import __COLOCATED_TEMPLATE__ from './whatsapp-account.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import ENV from 'embercom/config/environment';
import whatsappEmbeddedSignup from 'embercom/lib/channels/whatsapp/helpers';

export default class WhatsappAccount extends Component {
  @service store;
  @service appService;
  @service notificationsService;
  @service intercomEventService;
  @service fb;
  @service intl;
  @tracked showModal = false;
  @tracked removingIntegration;

  constructor() {
    super(...arguments);
    let app = this.appService.app;
    this.initFacebook(this.fb, app);
    if (this.firstIntegration) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'whatsapp_settings_selected',
        object: 'app_package',
        place: 'app_package',
        whatsapp_integration_id: this.firstIntegration.id,
        business_verification_status: this.firstIntegration.businessVerificationStatus,
        whatsapp_account_status: this.firstIntegration.whatsappAccountStatus,
        displayNameStatus: this.firstIntegration.displayNameStatus,
        current_tab: 'settings',
        app_package_code: 'whatsapp-fqam',
      });
    }
  }

  get firstIntegration() {
    return this.args.whatsappIntegrations?.firstObject;
  }

  get whatsappIntegrations() {
    return this.args.whatsappIntegrations;
  }

  get allIntegrationsApproved() {
    return this.whatsappIntegrations.every(
      (whatsappIntegration) => whatsappIntegration.isWhatsappApproved,
    );
  }

  get whatsappStatusClass() {
    return this.getStampClass(this.whatsappStatus);
  }

  get whatsappStatusLabel() {
    return this.getStatusLabel(this.whatsappStatus);
  }

  get whatsappManagerUrl() {
    return `https://business.facebook.com/wa/manage/phone-numbers/`;
  }

  @task({ drop: true })
  *removeAccount() {
    yield this.removingIntegration.destroyRecord();
    this.removingIntegration = null;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'deleted',
      object: 'whatsapp_integration',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });
  }

  @task({ drop: true })
  *connectWhatsapp(whatsappIntegration) {
    let data = {
      app_id: this.appService.app.id,
      whatsapp_account_id: whatsappIntegration.whatsappAccountId,
      phone_number: whatsappIntegration.phoneNumber,
    };
    let response = yield post('/ember/whatsapp/integrations/submit_number', data);
    this.store.pushPayload({ 'whatsapp/integration': response });
  }

  @task({ drop: true })
  *startEmbeddedOnboarding(whatsappIntegration) {
    try {
      yield whatsappEmbeddedSignup(this.appService.app.id, this.fb);
      yield this.connectWhatsapp.perform(whatsappIntegration);
      this.successNotification(whatsappIntegration);
    } catch (e) {
      this.errorNotification(e);
    }
  }

  @action
  showRemoveModal(whatsappIntegration) {
    this.removingIntegration = whatsappIntegration;
    this.showModal = true;
  }

  @action
  connectIntegration(whatsappIntegration) {
    this.startEmbeddedOnboarding.perform(whatsappIntegration);
  }

  @action
  confirmRemoveAccount() {
    this.removeAccount.perform();
    this.showModal = false;
  }

  successNotification(whatsappIntegration) {
    this.notificationsService.notifyConfirmation(
      this.intl.t('appstore.settings.whatsapp.whatsapp-account.notification.success'),
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'reconnected',
      object: whatsappIntegration,
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });
  }

  errorNotification(e) {
    console.error(e);
    this.notificationsService.notifyResponseError(
      e,
      {
        default: this.intl.t('appstore.settings.whatsapp.whatsapp-account.notification.error'),
      },
      {
        responseProvidedErrors: [403, 422],
        duration: 10000,
      },
    );
  }

  async initFacebook(fb, app) {
    let initSettings = {
      appId: ENV.APP.whatsapp.appId,
      version: app.koala_api_version,
      xfbml: true,
    };
    await fb.FBInit(initSettings);
    window.FB.init(initSettings);
  }
}
