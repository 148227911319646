/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { resolve } from 'rsvp';
import { debounce } from '@ember/runloop';
import { observer, computed } from '@ember/object';
import { on } from '@ember/object/evented';
import { A } from '@ember/array';
import $ from 'jquery';
import { alias, or, empty, notEmpty, and, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import {
  equalToProperty,
  greaterThanProperty,
  lessThanProperty,
} from '@intercom/pulse/lib/computed-properties';
import ENV from 'embercom/config/environment';

export default Component.extend({
  attributeService: service(),

  currentPage: 1,
  hasReachedMaxPages: greaterThanProperty('currentPage', 'maxPages'),
  loadedItemsEqualTotalCount: equalToProperty('items.length', 'total_count'),

  lastQueryWasLongRunning: false,
  isLimitedResponse: false,

  displayedUserAttributes: alias('attributeService.displayedUserAttributes'),
  displayedCompanyAttributes: alias('attributeService.displayedCompanyAttributes'),

  hasLoadedAllItems: or('hasReachedMaxPages', 'loadedItemsEqualTotalCount'),
  hasEmptyItemList: empty('items'),
  hasItems: notEmpty('items'),
  hasNotLoadedAllItems: lessThanProperty('items.length', 'total_count'),
  hasNotLoadedFullPage: lessThanProperty('items.length', 'pageSize'),
  hasIncompleteList: and('hasNotLoadedAllItems', 'hasNotLoadedFullPage'),
  shouldResetList: or('hasEmptyItemList', 'hasIncompleteList'),
  isNotLoading: not('isLoading'),
  isLoading: false,
  isInitializing: true,
  pageSize: 20,
  viewportHeight: 0,
  searchOnDidInsertElement: true,

  didInsertElement() {
    this._super(...arguments);
    this.set('viewportHeight', $(this.viewportSelector).height());
    if (this.shouldResetList) {
      this.set('items', A());
    }
    if (this.searchOnDidInsertElement) {
      this.triggerAction({ action: 'nextPage', target: this });
    }
    this.set('currentPage', Math.floor(this.get('items.length') / this.pageSize) + 1);
  },
  onPredicateSelectionChange: on(
    'init',
    observer({
      dependentKeys: ['selectionStateObject.predicatesUpdated', 'searchSelection'],

      fn() {
        this.get('selectionStateObject.predicatesUpdated');
        let debounceTime = this.lastQueryWasLongRunning ? ENV.APP._10000MS : ENV.APP._2000MS;
        debounce(this, this._runNewSearch, debounceTime);
      },

      sync: true,
    }),
  ),
  _runNewSearch() {
    if (this.get('selectionStateObject.allPredicatesAreValid')) {
      this.setProperties({
        total_count: null,
        currentPage: 1,
        lastPageTriggeredAtHeight: 0,
        isLoading: false,
        hasLoadedAllItems: false,
        clearOldItemsOnNextPage: true,
      });
      this.send('nextPage');
    }
  },
  pageVars: computed('currentPage', 'pageSize', function () {
    return {
      page: this.currentPage,
      per_page: this.pageSize,
    };
  }),
  actions: {
    nextPage() {
      if (this.hasReachedMaxPages || this.loadedItemsEqualTotalCount) {
        return resolve();
      }
      let timeAtSearchStart = new Date().getTime();
      this.setProperties({
        isLoading: true,
        isLimitedResponse: false,
      });
      return this.searchFunction(this.app, this.searchSelection, this.pageVars).then((response) => {
        if (!this.isDestroyed) {
          if (response.limited === true) {
            this.set('isLimitedResponse', true);
          }
          let timeAtSearchEnd = new Date().getTime();

          this.set(
            'lastQueryWasLongRunning',
            timeAtSearchEnd - timeAtSearchStart > ENV.APP._5000MS,
          );

          if (this.clearOldItemsOnNextPage) {
            this.setProperties({
              items: A(),
              clearOldItemsOnNextPage: false,
            });
          }
          response[this.responseArrayName].forEach((item) => {
            item.list = this.items;
            this.items.pushObject(item);
          });
          if (this.get('selectionStateObject.isUserMessage')) {
            this.selectionStateObject.set('totalCount', response.totalCount);
          }
          this.set('total_count', response.totalCount);

          this.set('currentPage', this.currentPage + 1);
          if (this.searchOnDidInsertElement && this.isInitializing) {
            this.set('isInitializing', false);
          }
          this.set('isLoading', false);
        }
      });
    },
  },
});
