/* import __COLOCATED_TEMPLATE__ from './identify-existing-users.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';

export default class IdentifyExistingUsers extends Component {
  @task({ restartable: true })
  *togglePhoneBasedUserAssociation(newValue) {
    this.args.settings.phoneBasedUserAssociation = newValue;
    yield this.args.settings.save();
  }
}
