/* import __COLOCATED_TEMPLATE__ from './article-header.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type ArticleMultilingual from 'embercom/models/article-multilingual';

interface Args {
  article: ArticleMultilingual;
  showEditSettings?: () => void;
}

interface Signature {
  Args: Args;
}

export default class ArticleHeader extends Component<Signature> {
  @service declare appService: any;
  @service declare modalService: any;
  @service declare intercomEventService: any;

  @tracked taggingModalOpened = false;

  @action
  openTaggingModal() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'tagging_modal',
    });

    this.taggingModalOpened = true;
  }

  get app() {
    return this.appService.app;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Shared::ArticleHeader': typeof ArticleHeader;
  }
}
