/* import __COLOCATED_TEMPLATE__ from './footer-links.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  site: HelpCenterSite;
}

const maxGroupsPerLocale = 6;

const simpleFooterType = 0;
const classicFooterType = 1;

export default class FooterLinks extends Component<Args> {
  @service declare intl: IntlService;

  @tracked selectedLanguage: string = this.args.site.locale;
  maxLinksPerGroup = 8;

  @action
  onLanguageChange(locale: string) {
    this.selectedLanguage = locale;
  }

  get displayWarning() {
    return this.footerType === simpleFooterType || this.footerType === classicFooterType;
  }

  get warningMessage() {
    if (this.footerType === simpleFooterType) {
      return this.intl.t('articles.settings.redesign.content.site-links.warnings.simple-footer');
    } else {
      return this.intl.t('articles.settings.redesign.content.site-links.warnings.classic-footer');
    }
  }

  get footerType() {
    return this.args.site.customizationOptions.global.namedComponents.footer.type || 0;
  }

  get languages() {
    return this.args.site.selectedLocales.map((locale) => ({
      text: locale.name,
      value: locale.localeId,
    }));
  }

  @action
  addLinkGroup() {
    let sortOrder = this.countOfLinkGroupsByLocale(this.selectedLanguage);
    this.args.site.addFooterLinkGroup(this.selectedLanguage, sortOrder);
  }

  get canAddLinkGroup() {
    return this.countOfLinkGroupsByLocale(this.selectedLanguage) < maxGroupsPerLocale;
  }

  private countOfLinkGroupsByLocale(locale: string) {
    return this.args.site.footerLinkGroups.filter((linkGroup) => linkGroup.locale === locale)
      .length;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Content::Links::FooterLinks': typeof FooterLinks;
    'articles/site/settings/content/links/footer-links': typeof FooterLinks;
  }
}
