/* import __COLOCATED_TEMPLATE__ from './auto-resizing-textarea-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { cancel, throttle } from '@ember/runloop';
import $ from 'jquery';
import { on } from '@ember/object/evented';
import Component from '@ember/component';
export default Component.extend({
  maxHeight: 700,
  isDisabled: false,
  textInitiator: on('didInsertElement', function () {
    this.setInitialVariables();
    this.resizeTextArea();
  }),
  onDestroy: on('willDestroyElement', function () {
    this.unsetInitialVariables();
    cancel(this.resizingTimer);
  }),
  resizeTextArea() {
    let $heightHelper = this.$heightHelper;
    let $textarea = this.$textarea;

    $heightHelper.text(this.value || '');
    let newHeight = this.calculateNewHeight();

    if (this.isResizable !== false) {
      if (newHeight < this.maxHeight) {
        if (newHeight > this.minHeight) {
          $textarea.height(newHeight);
        } else {
          $textarea.height(this.minHeight);
        }
      } else {
        $textarea.height(this.maxHeight);
      }
    }
  },
  calculateNewHeight() {
    return this.$heightHelper.outerHeight() + this.borderTop + this.borderBottom;
  },
  setInitialVariables() {
    this.setProperties({
      $textarea: $('textarea', this.element),
      $heightHelper: $('pre', this.element),
    });

    let $textarea = this.$textarea;

    this.$heightHelper.addClass($textarea.attr('class'));
    this.$heightHelper.css('width', $textarea.css('width'));

    this.setProperties({
      minHeight: parseInt($textarea.css('min-height'), 10),
      borderTop: parseFloat($textarea.css('border-top-width')),
      borderBottom: parseFloat($textarea.css('border-bottom-width')),
      lineHeight: parseInt($textarea.css('line-height'), 10),
    });
  },
  unsetInitialVariables() {
    this.set('$textarea', null);
    this.set('$heightHelper', null);
  },
  handleKeyDown: on('keyDown', function () {
    this.resizingTimer = throttle(this, this.resizeTextArea, 20, false);
  }),
  actions: {
    forceResizeTextArea() {
      this.resizeTextArea();
    },
  },
});
