/* import __COLOCATED_TEMPLATE__ from './phone-number-provisioning.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { timeout, type Task, type TaskGenerator } from 'ember-concurrency';
import { dropTask, restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { countrySelect } from 'embercom/helpers/country-select-helper';
import { get, post } from 'embercom/lib/ajax';
import Countries from 'embercom/lib/countries';
import type CallingPhoneNumber from 'embercom/models/calling-phone-number';
import {
  PhoneNumberType,
  PHONE_NUMBER_COUNTRY_DETAILS,
  type PhoneNumber,
  countryNeedAddressForNumberType,
  COUNTRIES_LIST,
  countrySupportsNumberType,
  countryNeedsBundleForNumberType,
  countryNeedsPrivateOfferingForNumberType,
} from 'embercom/models/settings/calling';
import type RegulatoryAddress from 'embercom/models/settings/calling/regulatory_address';
import type IntlService from 'embercom/services/intl';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { type SuggestedAddress } from './phone-number-provisioning/regulatory-address-section';
import type IntercomCallService from 'embercom/services/intercom-call-service';

interface Args {
  enableOutboundCalling: () => void;
  showProvisioningModal: boolean;
  activateProvisioningModal: () => any;
  closeProvisioningModal: () => void;
  peekPhoneNumbersTask: Task<Array<PhoneNumber>, any>;
  onPhoneNumberProvisioned: (phoneNumber: CallingPhoneNumber) => void;
  isNumberProvisioningPrimary: boolean;
}

interface Signature {
  Args: Args;
}

const DEBOUNCE_MS = 500;
export const SURVEY_ID = '37560701';

export default class PhoneNumberProvisioning extends Component<Signature> {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare router: any;
  @service declare intercomCallService: IntercomCallService;

  @tracked selectedCountry?: string;
  @tracked selectedPhoneNumberType: PhoneNumberType = PhoneNumberType.Local;
  @tracked regulatoryAddressSid?: string;
  @tracked newRegulatoryAddress?: RegulatoryAddress;
  @tracked regulatoryAddressValidationError?: string;
  @tracked regulatoryAddressSuggestedFields?: SuggestedAddress;
  @tracked phoneNumberStartWithFilter?: string;
  @tracked availablePhoneNumbers?: string[];
  @tracked selectedPhoneNumber?: string;
  @tracked selectedPhoneNumberPrice?: number;
  @tracked selectedRegulatoryBundle?: string;
  @tracked quantityOfNumbers?: number = 1;
  @tracked selectedNumberPrefix?: string;

  get countriesList() {
    return COUNTRIES_LIST.map((countryCode: string) => ({
      text: this.intl.t(`calling.settings.phone-calls.countries.${countryCode}`),
      value: countryCode,
    }));
  }

  @action async onSelectCountry(countryIsoCode: string) {
    this.selectedCountry = countryIsoCode;
    this.onSelectPhoneType();
  }

  @action async onSelectPhoneType(phoneType?: PhoneNumberType) {
    this.resetAll();
    if (phoneType !== undefined) {
      this.selectedPhoneNumberType = phoneType;
    }
    this.loadAvailablePhoneNumbers();
  }

  get phoneNumberTypesSupportedBySelectedCountry() {
    if (!this.selectedCountry) {
      return [];
    }

    return PHONE_NUMBER_COUNTRY_DETAILS.get(this.selectedCountry)?.supportedNumberTypes;
  }

  get isRegulatoryAddressRequired() {
    if (!this.selectedCountry) {
      return false;
    }

    return countryNeedAddressForNumberType(this.selectedCountry, this.selectedPhoneNumberType);
  }

  get isValid() {
    if (!this.selectedCountry) {
      return false;
    }

    if (!countrySupportsNumberType(this.selectedCountry, this.selectedPhoneNumberType)) {
      return false;
    }

    if (!this.isPrivateOffering && !this.selectedPhoneNumber) {
      return false;
    }

    if (this.needsRegulatoryBundle) {
      if (!this.selectedRegulatoryBundle) {
        return false;
      }
    }

    if (this.isRegulatoryAddressRequired) {
      if (this.regulatoryAddressSid) {
        return this.regulatoryAddressSid;
      } else {
        return this.newRegulatoryAddress?.validations.isValid;
      }
    }

    return true;
  }

  @action async purchasePhoneNumber() {
    this.regulatoryAddressSuggestedFields = undefined;
    this.regulatoryAddressValidationError = undefined;

    try {
      let requestData: any = {
        app_id: this.appService.app.id,
        phone_type: this.selectedPhoneNumberType,
        quantity_of_numbers: this.quantityOfNumbers,
        selected_number_prefix: this.selectedNumberPrefix,
        regulatory_bundle_sid: this.selectedRegulatoryBundle,
        country_iso_code: this.selectedCountry,
      };

      if (this.isRegulatoryAddressRequired) {
        if (this.regulatoryAddressSid) {
          requestData = {
            ...requestData,
            regulatory_address_sid: this.regulatoryAddressSid,
          };
        } else {
          requestData = {
            ...requestData,
            new_regulatory_address: this.newRegulatoryAddress!.serialize(),
          };
        }
      }

      await post('/ember/calling_phone_numbers/create_phone_number_purchase_request', requestData);
      this.args.closeProvisioningModal();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.video-call.settings.purchased-number'),
      );
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.errors);
    }
  }

  @action async provisionPhoneNumber() {
    this.regulatoryAddressSuggestedFields = undefined;
    this.regulatoryAddressValidationError = undefined;

    try {
      let response = await taskFor(this.provisionNumber).perform();
      this.store.pushPayload({ 'calling-phone-number': response });
      let phoneNumber = this.store.peekRecord('calling-phone-number', response.id);

      this.args.enableOutboundCalling();
      this.resetAll();
      this.selectedCountry = undefined;
      this.args.closeProvisioningModal();

      this.args.onPhoneNumberProvisioned(phoneNumber);
      this.intercomCallService.registerCallEvent({
        action: 'provisioned',
        object: 'phone_number',
        place: 'calling_settings',
        section: 'settings_page',
      });

      this.args.peekPhoneNumbersTask.perform();
    } catch (err) {
      if (err.jqXHR.status === 422 && err.jqXHR.responseJSON.address_validation_error) {
        this.regulatoryAddressValidationError = err.jqXHR.responseJSON.address_validation_error;
        this.regulatoryAddressSuggestedFields = err.jqXHR.responseJSON.suggested_address;
      } else {
        this.notificationsService.notifyError(
          this.intl.t('channels.video-call.settings.provisioning-error'),
        );
        throw err;
      }
    }
  }

  @dropTask *provisionNumber(): TaskGenerator<CallingPhoneNumber> {
    let requestData: any = {
      app_id: this.appService.app.id,
      country: this.selectedCountry,
      phone_type: this.selectedPhoneNumberType,
      selected_phone_number: this.selectedPhoneNumber,
    };

    if (this.isRegulatoryAddressRequired) {
      if (this.regulatoryAddressSid) {
        requestData = {
          ...requestData,
          regulatory_address_sid: this.regulatoryAddressSid,
        };
      } else {
        requestData = {
          ...requestData,
          new_regulatory_address: this.newRegulatoryAddress!.serialize(),
        };
      }
    }

    if (this.needsRegulatoryBundle) {
      requestData = {
        ...requestData,
        regulatory_bundle_sid: this.selectedRegulatoryBundle,
      };
    }

    return yield post(`/ember/calling_settings/provision_phone_number`, requestData);
  }

  @action async loadAvailablePhoneNumbers() {
    if (this.isPrivateOffering) {
      return;
    }
    let response = await taskFor(this.fetchAvailablePhoneNumbers).perform();
    this.selectedPhoneNumber = undefined;
    this.availablePhoneNumbers = response;
  }

  @action async onPhoneNumberFilterInput(event: KeyboardEvent) {
    let target = event.target as HTMLInputElement;
    this.phoneNumberStartWithFilter = target.value;

    await this.loadAvailablePhoneNumbers();
  }

  @restartableTask
  *fetchAvailablePhoneNumbers(): TaskGenerator<string[]> {
    yield timeout(DEBOUNCE_MS);
    return yield get(`/ember/calling_settings/fetch_available_phone_numbers`, {
      app_id: this.appService.app.id,
      country: this.selectedCountry,
      phone_type: this.selectedPhoneNumberType,
      start_with: this.prefixedPhoneNumberStartWithFilter,
    }) as string[];
  }

  get countryCodePrefix() {
    return PHONE_NUMBER_COUNTRY_DETAILS.get(this.selectedCountry!)?.prefix;
  }

  @action async selectPhoneNumber(phoneNumber: string) {
    this.selectedPhoneNumber = phoneNumber;
    this.fetchPricingInfo();
  }

  @action selectRegulatoryBundle(regulatoryBundleSid: string) {
    this.selectedRegulatoryBundle = regulatoryBundleSid;
  }

  @action phoneNumbersRequired(numbersRequired: number) {
    this.quantityOfNumbers = numbersRequired;
  }

  @action prefixRequired(prefix: string) {
    this.selectedNumberPrefix = prefix;
  }

  get prefixedPhoneNumberStartWithFilter() {
    return this.phoneNumberStartWithFilter
      ? `${this.countryCodePrefix}${this.phoneNumberStartWithFilter}`
      : '';
  }

  @action resetRegulatoryAddress() {
    this.regulatoryAddressSid = undefined;
    this.regulatoryAddressValidationError = undefined;
    this.regulatoryAddressSuggestedFields = undefined;
    if (this.newRegulatoryAddress && !this.newRegulatoryAddress.isDeleted) {
      this.newRegulatoryAddress.deleteRecord();
    }
  }

  @action resetAll() {
    if (this.selectedCountry) {
      if (!countrySupportsNumberType(this.selectedCountry, this.selectedPhoneNumberType)) {
        this.selectedPhoneNumberType = this.phoneNumberTypesSupportedBySelectedCountry![0];
      }
    }
    this.phoneNumberStartWithFilter = undefined;
    this.availablePhoneNumbers = undefined;
    this.selectedPhoneNumber = undefined;
    this.selectedPhoneNumberPrice = undefined;
    this.resetRegulatoryAddress();
  }

  get isLoadingAvailablePhoneNumbers() {
    return taskFor(this.fetchAvailablePhoneNumbers).isRunning;
  }

  get countries() {
    return countrySelect(Countries.allCountries);
  }

  get selectedPhoneNumberFormatted() {
    return parsePhoneNumberFromString(this.selectedPhoneNumber!)?.formatInternational();
  }

  get selectedCountryFlag() {
    return PHONE_NUMBER_COUNTRY_DETAILS.get(this.selectedCountry!)?.flag;
  }

  @action async fetchPricingInfo() {
    let response = await taskFor(this.fetchPrice).perform();
    this.selectedPhoneNumberPrice = response.price;
  }

  @dropTask
  *fetchPrice(): TaskGenerator<{ price: number }> {
    return yield get(`/ember/calling_settings/fetch_phone_number_price`, {
      app_id: this.appService.app.id,
      country: this.selectedCountry,
      phone_type: this.selectedPhoneNumberType,
    }) as { price: number };
  }

  get showPricingInfo() {
    if (this.selectedPhoneNumberPrice === undefined) {
      return false;
    }

    return this.appService.app.onPricing5;
  }

  get showPricingInfoForEarlyAccess() {
    if (this.selectedPhoneNumberPrice === undefined) {
      return false;
    }

    return !this.appService.app.onPricing5;
  }

  get hasAccessToSettingsHub() {
    return this.appService.app.canSeeNewIASettingsHub;
  }

  get needsRegulatoryBundle() {
    return countryNeedsBundleForNumberType(this.selectedCountry!, this.selectedPhoneNumberType);
  }

  get isPrivateOffering() {
    return countryNeedsPrivateOfferingForNumberType(
      this.selectedCountry!,
      this.selectedPhoneNumberType,
    );
  }

  get canBuyPhoneNumber() {
    return !this.isPrivateOffering || !this.needsRegulatoryBundle || this.selectedRegulatoryBundle;
  }

  @action
  onAddressSelected(addressSid: string) {
    this.regulatoryAddressValidationError = undefined;
    this.regulatoryAddressSuggestedFields = undefined;
    this.regulatoryAddressSid = addressSid;
  }

  @action
  onAddressCreated(address: RegulatoryAddress) {
    this.regulatoryAddressValidationError = undefined;
    this.regulatoryAddressSuggestedFields = undefined;
    this.newRegulatoryAddress = address;
  }

  @action
  showPhoneSurvey() {
    window.Intercom('startSurvey', SURVEY_ID);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhoneNumberProvisioning': typeof PhoneNumberProvisioning;
    'calling/settings/phone-number-provisioning': typeof PhoneNumberProvisioning;
  }
}
