/* import __COLOCATED_TEMPLATE__ from './actions.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Task from 'embercom/models/checklists/task';
import { ActionType } from 'embercom/models/checklists/task';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { BaseConfig, type BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver, {
  APP_NAME_ATTRIBUTE,
} from 'embercom/lib/common/template-attribute-resolver';
import { get } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  activeTask: Task;
  actionUrlBlocksDoc: BlocksDocument;
  shouldShowValidations: boolean;
  updateActionUrl: () => void;
  setShowAutoComplete: (value: boolean) => void;
}

type InsertableContent = {
  ruleset_id: number;
  trigger_id: number;
  trigger_url: string;
  title: string;
  state: 'live' | 'paused' | 'draft';
  type: string;
  description?: string;
};

class ActionUrlComposerConfig extends BaseConfig {
  autoFocus = false;
  allowedBlocks = ['paragraph'];
  allowedInline = [];
  allowTextAlignment = false;
  singleBlockMode = true;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  templating: { picker: string; resolver: AttributeInfoResolver };

  constructor(placeholder: string, { resolver }: { resolver: AttributeInfoResolver }) {
    super();
    this.templating = { picker: 'common/attribute-picker', resolver };
    this.placeholder = placeholder;
  }
}

enum TriggerType {
  // This string doesn't need to be translated
  // eslint-disable-next-line @intercom/intercom/no-bare-strings
  Tour = 'Tour',
  // eslint-disable-next-line @intercom/intercom/no-bare-strings
  Article = 'Article',
}

const TRIGGER_TYPES_TO_ACTION_TYPES: Record<string, number> = {
  [TriggerType.Tour]: 2,
  [TriggerType.Article]: 3,
};

const ACTION_TYPES_TO_TRIGGER_TYPES: Record<number, string> = {
  2: TriggerType.Tour,
  3: TriggerType.Article,
};

export default class TaskSettingsActionsComponent extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare attributeService: any;
  @service declare store: Store;

  @tracked showTriggerContentModal = false;
  @tracked insertableContent: Array<InsertableContent> = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadContent).perform();
  }

  get task() {
    return this.args.activeTask;
  }

  get contentSelectDropdownVisible(): boolean {
    return (
      this.task.actionType === ActionType.ProductTour || this.task.actionType === ActionType.Article
    );
  }

  get contentSelectDropdownLabel(): string {
    if (this.task.productTourAction) {
      return this.intl.t('outbound.checklists.task-settings.select-content-dropdown-label.tour');
    } else if (this.task.articleAction) {
      return this.intl.t('outbound.checklists.task-settings.select-content-dropdown-label.article');
    } else {
      return '';
    }
  }

  get contentSelectSearchPlaceholder(): string {
    if (this.task.productTourAction) {
      return this.intl.t(
        'outbound.checklists.task-settings.select-content-search-placeholder.tour',
      );
    } else if (this.task.articleAction) {
      return this.intl.t(
        'outbound.checklists.task-settings.select-content-search-placeholder.article',
      );
    } else {
      return '';
    }
  }

  get triggerActionUrlInput(): boolean {
    return this.task.actionType === ActionType.OpenUrl;
  }

  get actionUrlComposerConfig() {
    let placeholder = this.intl.t('outbound.checklists.task-settings.url-placeholder');
    return new ActionUrlComposerConfig(placeholder, { resolver: this.actionUrlResolver });
  }

  get actionUrlResolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.checklistsActionUrlComposerAttributes,
      manualAppAttributes: [APP_NAME_ATTRIBUTE],
    });
  }

  get liveContentForActionType() {
    let relevantContent = this.insertableContent.filter(
      (content) =>
        content.state === 'live' &&
        content.type === ACTION_TYPES_TO_TRIGGER_TYPES[this.task.actionType],
    );

    return relevantContent;
  }

  get selectedContent(): InsertableContent | undefined {
    return this.insertableContent.find(
      (content) =>
        TRIGGER_TYPES_TO_ACTION_TYPES[content.type] === this.task.actionType &&
        content.trigger_url === this.task.actionContentUrl,
    );
  }

  get contentSelectDropdownItems() {
    let allGroupHeadings = this.liveContentForActionType.map((ic) => ic.description);
    let uniqueHeadings = Array.from(new Set(allGroupHeadings));
    uniqueHeadings = uniqueHeadings.length > 0 ? uniqueHeadings : [''];

    return uniqueHeadings
      .map((heading) => {
        let items = this.liveContentForActionType
          .filter((ic) => ic.description === heading)
          .map((content) => {
            return { text: content.title, value: content.trigger_url };
          });

        return {
          heading,
          items,
        };
      })
      .sort((a, b) => {
        if (!a.heading || !b.heading) {
          return 0;
        }

        if (a.heading === 'Unlisted articles') {
          return 1;
        }
        if (b.heading === 'Unlisted articles') {
          return -1;
        }

        if (a.heading < b.heading) {
          return -1;
        }

        if (a.heading > b.heading) {
          return 1;
        }

        return 0;
      });
  }

  get selectedContentItem() {
    let selectedDropdownItem = this.contentSelectDropdownItems
      .flatMap((g) => g.items)
      .find((item) => item.value === this.selectedContent?.trigger_url);

    if (!selectedDropdownItem) {
      return;
    }

    return selectedDropdownItem.value;
  }

  get actionTypeItems() {
    return [
      {
        text: this.intl.t('outbound.checklists.task-settings.task-actions.no-action'),
        value: ActionType.ReadOnly,
        icon: 'long-text',
      },
      {
        text: this.intl.t('outbound.checklists.task-settings.task-actions.go-to-url'),
        value: ActionType.OpenUrl,
        icon: 'link',
      },
      {
        text: this.intl.t('outbound.checklists.task-settings.task-actions.launch-product-tour'),
        value: ActionType.ProductTour,
        icon: 'product-tours',
      },
      {
        text: this.intl.t('outbound.checklists.task-settings.task-actions.open-article'),
        value: ActionType.Article,
        icon: 'article',
      },
    ];
  }

  get showContentValidation() {
    return (
      this.args.shouldShowValidations &&
      this.contentSelectDropdownVisible &&
      !this.selectedContentItem
    );
  }

  get showUrlValidation() {
    return this.args.shouldShowValidations && this.triggerActionUrlInput && !this.task.hasUrl;
  }

  initActionContentUrl() {
    if (!this.task.actionContentUrl && !!this.task.actionContentId) {
      let content = this.insertableContent.find(
        (insertable) => insertable.trigger_id === this.task.actionContentId,
      );
      if (content) {
        this.task.actionContentUrl = content.trigger_url;
      }
    }
  }

  @task *loadContent(): TaskGenerator<void> {
    let content = yield get(`/ember/content_service/contents/insertable_content`, {
      app_id: this.appService.app.id,
      multi_help_center_aware: this.appService.app.canUseChecklistsMultiHcInserters
        ? true
        : undefined,
    });
    this.insertableContent = content;
    this.initActionContentUrl();
  }

  @action changeActionType(newValue: number) {
    if (newValue === this.task.actionType) {
      return;
    }

    let oldValue = this.task.actionType;
    if (oldValue === ActionType.Article || oldValue === ActionType.ProductTour) {
      this.removeContentRules(oldValue);
    }
    this.task.actionType = newValue;
    this.task.actionContentId = null;
    this.task.actionContentUrl = null;
    this.task.actionUrl = null;
  }

  private removeContentRules(actionType: number) {
    let attributeName = actionType === ActionType.ProductTour ? 'tour_completed' : 'article_viewed';
    this.task.predicateGroup.removeAll((pred: any) => pred.attribute === attributeName);
  }

  private populateContentEvent(contentOrRulesetId: number | undefined) {
    if (!contentOrRulesetId) {
      return;
    }

    let attribute =
      this.task.actionType === ActionType.ProductTour ? 'tour_completed' : 'article_viewed';

    let newPred = {
      attribute,
      comparison: 'in',
      type: 'content_event',
      value: `${contentOrRulesetId}`,
    };

    let isPredicatePresent = Boolean(
      this.task.predicateGroup._findPredicate(
        (pred: any) => pred.attribute === newPred.attribute && pred.value === newPred.value,
      ),
    );

    if (isPredicatePresent) {
      return;
    }

    this.task.predicateGroup.add(newPred);
    this.task.predicateGroup.notifyPropertyChange('predicates');
    this.args.setShowAutoComplete(true);
  }

  @action selectTriggeredContent(triggerUrl: string) {
    let content = this.insertableContent.find((i) => i.trigger_url === triggerUrl);
    let triggerId = content?.trigger_id || null;
    this.task.actionContentUrl = triggerUrl;
    this.task.actionContentId = triggerId;

    if (
      this.task.actionType !== ActionType.ProductTour &&
      this.task.actionType !== ActionType.Article
    ) {
      return;
    }

    if (this.task.actionType === ActionType.ProductTour) {
      let rulesetId = content?.ruleset_id;

      this.populateContentEvent(rulesetId);
    } else if (this.task.actionType === ActionType.Article) {
      this.populateContentEvent(triggerId || undefined);
    }
  }

  @action toggleOpenLinkInNewTab() {
    this.task.openActionUrlInNewTab = !this.task.openActionUrlInNewTab;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Sidebar::TaskSettings::Actions': typeof TaskSettingsActionsComponent;
    'checklist/sidebar/task-settings/actions': typeof TaskSettingsActionsComponent;
  }
}
