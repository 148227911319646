/* import __COLOCATED_TEMPLATE__ from './remove-addon-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { INCLUDED_TIERS_FROM_PRICING_ENDPOINT, SMS_BASE_ID } from 'embercom/lib/billing';

export default class RemoveAddonModal extends Component {
  @service customerService;
  @tracked selectedAddon = this.args.selectedAddon;
  @tracked submitActionRunning = false;
  @service appService;

  constructor() {
    super(...arguments);
    this.loadPrices.perform();
  }

  @task({ restartable: true })
  *loadPrices() {
    let params = this.allRequiredPriceCombinations();
    yield this.customerService.bulkLoadPrices(params);
  }

  allRequiredPriceCombinations() {
    let activePlanIds = this.customerService.activePlanIds;
    let targetPlanId = parseInt(this.selectedAddon.id, 10);

    if (activePlanIds.length === 0) {
      return [];
    }

    let activePlansWithoutTargetPlan = this.removePlanIdFromActivePlans(
      targetPlanId,
      activePlanIds,
    );
    return [
      {
        planIds: activePlansWithoutTargetPlan,
        includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
        includePlanCombinationValidation: true,
        source: 'remove-plan-modal-component',
      },
    ];
  }

  removePlanIdFromActivePlans(planId, activePlanIds) {
    return activePlanIds.filter((activePlanId) => {
      return activePlanId !== planId;
    });
  }

  get app() {
    return this.appService.app;
  }

  get addonName() {
    return this.selectedAddon.product?.name
      ? this.selectedAddon.product.name
      : this.selectedAddon.name;
  }

  get isSMS() {
    return this.selectedAddon.id === SMS_BASE_ID;
  }

  get customer() {
    return this.customerService.customer;
  }

  get subscription() {
    return this.customer?.subscription;
  }

  @action downgradeNow() {
    this.submitActionRunning = true;
    this.selectedAddon.set('active', false);
    this.args.updateSubscription();
  }
}
