/* import __COLOCATED_TEMPLATE__ from './review-table.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */

import { action } from '@ember/object';
import { isNone, isEmpty, isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  BaseConfig,
  BlocksDocument,
  EMOJI_TYPEAHEAD,
} from '@intercom/embercom-prosemirror-composer';
import { MENTION_TYPEAHEAD } from 'embercom/lib/inbox/composer-config';
import { get, post } from 'embercom/lib/ajax';

import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import moment from 'moment-timezone';
import type ArticleContentReviewEntry from 'embercom/models/articles/article-content-review-entry';
import type ArticleContentReviewWrapper from 'embercom/models/articles/article-content-review-wrapper';
import { task } from 'ember-concurrency-decorators';
import { perform } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';

interface Args {
  reviews: Array<ArticleContentReviewWrapper>;
}

class ArticleReviewComposerConfig extends BaseConfig {
  placeholder = 'Add a comment... (optional)';
  autoFocus = false;
  allowImplicitMarginParagraphs = true;
  typeaheads = [EMOJI_TYPEAHEAD, MENTION_TYPEAHEAD];
  tools = [
    { name: 'text-formatter' },
    { name: 'image-editor', options: { supportAltAttributeEditing: false } },
  ];
  allowedBlocks = ['paragraph', 'image', 'unorderedList'];
  allowedInline = ['bold', 'italic', 'mention'];
}

export default class ArticlesReviewTable extends Component<Args> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare permissionsService: any;
  @service declare notificationsService: any;

  @tracked articleState: 'published' | 'latest-draft' = 'published';

  @tracked selectedReviewState = 'any';
  @tracked selectedReviewType = 'commented';

  @tracked searchTerm = '';

  @tracked showReviewModal = false;

  @tracked selectedNextReviewDate?: Date;

  @tracked selectedReviewWrapper?: ArticleContentReviewWrapper;
  @tracked entries?: Array<ArticleContentReviewEntry>;

  @tracked commentBlocksDocument = new BlocksDocument();
  commentBlocks = [];
  composerConfig = new ArticleReviewComposerConfig();

  reviewStates = [
    { text: 'Any state', value: 'any' },
    { text: 'Pending', value: 'pending' },
    { text: 'Approved', value: 'approved' },
    { text: 'Needs changes', value: 'needs_changes' },
    { text: 'Stale', value: 'stale' },
  ];

  reviewTypes = [
    { text: 'Comment only', value: 'commented' },
    { text: 'Approve', value: 'approved' },
    { text: 'Request changes', value: 'requested_changes' },
  ];

  get isPublishedState() {
    return this.articleState === 'published';
  }

  @action changeArticleState(state: 'published' | 'latest-draft') {
    this.args.reviews.setEach('currentReviewState', state);
    this.articleState = state;

    // Unset the next review date as we don't want this set for unpublished articles
    this.selectedNextReviewDate = undefined;
  }

  @action changeSelectedState(state: string) {
    this.selectedReviewState = state;
  }

  @action changeSelectedReviewType(type: string) {
    this.selectedReviewType = type;
  }

  @action showModal() {
    this.showReviewModal = true;
  }

  @action onComposerChange(blocksDocument: BlocksDocument) {
    this.commentBlocks = blocksDocument.blocks as any;
  }

  @action async submitReview() {
    if (this.selectedReviewType === 'approved' || this.selectedReviewType === 'requested_changes') {
      if (!this.permissionsService.currentAdminCan('can_manage_articles')) {
        this.notificationsService.notifyError(
          'You must have full articles permissions to approve or request changes. You may add comments instead.',
        );
        return;
      }
    }

    let updates = this.selectedReviewWrappers.map((review) => ({
      entry_type: this.selectedReviewType,
      comment: this.commentBlocks,
      article_content_id: review.id,
      article_content_version_id: review.reviewIdForCurrentState,
    }));

    let results = await post(`/ember/article_content_reviews/add_review_updates`, {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
      next_review_required_at: this.selectedNextReviewDate?.toISOString(),
      updates,
    });

    this.args.reviews.setEach('selected', false);
    this.closeReviewModal();

    this.store.pushPayload({ 'articles/article-content-review-wrapper': results });
  }

  get allSelected() {
    return this.filteredReviews.every((review) => review.selected);
  }

  @action toggleAllSelected(value: boolean | undefined = undefined) {
    value = value ?? !this.allSelected;
    this.filteredReviews.setEach('selected', value);
  }

  @action closeReviewModal() {
    this.showReviewModal = false;
    this.commentBlocksDocument = new BlocksDocument();
  }

  @action setNextReviewDate(data: any) {
    this.selectedNextReviewDate = data.date;
  }

  @action setDateToSixMonths() {
    this.selectedNextReviewDate = moment().add(6, 'months').toDate();
  }

  get reviewsMatchingStateFilter(): Array<ArticleContentReviewWrapper> {
    let reviews = this.args.reviews;

    if (this.articleState === 'published') {
      reviews = reviews.filter((review) => isPresent(review.liveVersionId));
    } else {
      reviews = reviews.filter((review) => review.lastVersionId !== review.liveVersionId);
    }

    if (this.selectedReviewState === 'any') {
      return reviews;
    }
    if (this.selectedReviewState === 'pending') {
      return reviews.filter(
        (review) =>
          isNone(review.reviewForCurrentState?.get('currentStatus')) ||
          review.reviewForCurrentState?.get('currentStatus') === 'pending',
      );
    }
    return reviews.filter(
      (review) => review.reviewForCurrentState?.get('currentStatus') === this.selectedReviewState,
    );
  }

  get filteredReviews(): Array<ArticleContentReviewWrapper> {
    if (isEmpty(this.searchTerm)) {
      return this.reviewsMatchingStateFilter;
    }

    return this.reviewsMatchingStateFilter.filter((review) => {
      return (review.title || 'Untitled')
        .toLowerCase()
        .includes(this.searchTerm.toLocaleLowerCase());
    });
  }

  get selectedReviewWrappers(): Array<ArticleContentReviewWrapper> {
    return this.filteredReviews.filter((review) => review.selected);
  }

  @task *loadEntries(): TaskGenerator<void> {
    let entryJson = yield get(
      `/ember/article_content_reviews/${this.selectedReviewWrapper!.get('id')}/entries`,
      {
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdmin.id,
      },
    ) as Array<any>;

    this.store.pushPayload({ 'articles/article-content-review-entry': entryJson });

    this.entries = entryJson.map((entry: any) =>
      this.store.peekRecord('articles/article-content-review-entry', entry.id),
    );
  }

  @action async setSelectedReviewWrapper(wrapper?: ArticleContentReviewWrapper) {
    if (this.selectedReviewWrapper !== wrapper) {
      this.selectedReviewWrapper = wrapper;

      if (this.selectedReviewWrapper) {
        perform(this.loadEntries);
      } else {
        this.entries = [];
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Reviews::ReviewTable': typeof ArticlesReviewTable;
    'articles/reviews/review-table': typeof ArticlesReviewTable;
  }
}
