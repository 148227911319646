/* import __COLOCATED_TEMPLATE__ from './order-summary.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import {
  FIN_AI_COPILOT_BASE_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  type BILLING_PERIODS,
} from 'embercom/lib/billing';
import { Metric } from 'embercom/models/data/pricing/metric-types';

import type QuoteService from 'embercom/services/quote-service';

const FIN_AI_COPILOT_BASE_ID_NUM = Number(FIN_AI_COPILOT_BASE_ID);
const PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM = Number(PROACTIVE_SUPPORT_PLUS_BASE_ID);

interface Args {
  header: string;
  loading: boolean;
  selectedPlanId: number;
  selectedPlanName: string;
  contractedSeats: number;
  overageSeats: number;
  contractedCopilotSeats: number;
  overageCopilotSeats: number;
  addOnIds: number[];
  billingPeriod: BILLING_PERIODS;
  renewalDate: string;
  setMonthlyTotal: (totalWithMonthlyOverages: number) => void;
  setContractedTotal: (contractedTotal: number) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

export default class OrderSummary extends Component<Signature> {
  @service declare intl: any;
  @service declare quoteService: QuoteService;

  private calculateMontlhySeatPrice(price: number, seatAmount: number) {
    return price * seatAmount;
  }

  get monthlyFullContractedSeatPrice() {
    return this.calculateMontlhySeatPrice(this.fullSeatPrice, this.args.contractedSeats);
  }

  get monthlyContractedCopilotSeatPrice() {
    return this.calculateMontlhySeatPrice(this.copilotSeatPrice, this.args.contractedCopilotSeats);
  }

  get monthlyDiscountedContractedSeatPrice() {
    return this.calculateMontlhySeatPrice(this.discountedSeatPrice, this.args.contractedSeats);
  }

  get monthlyDiscountedContractedCopilotSeatPrice() {
    return this.calculateMontlhySeatPrice(
      this.discountedCopilotSeatPrice,
      this.args.contractedCopilotSeats,
    );
  }

  private calculateOverageSeatPrice(price: number, seatAmount: number) {
    return seatAmount > 0 ? price * seatAmount : 0;
  }

  get monthlyOverageSeatPrice() {
    return this.calculateOverageSeatPrice(this.fullSeatPrice, this.args.overageSeats);
  }

  get monthlyOverageCopilotSeatPrice() {
    return this.calculateOverageSeatPrice(this.copilotSeatPrice, this.args.overageCopilotSeats);
  }

  get monthlyFullCopilotAddonPrice() {
    return this.fullCopilotSeatPrice * this.args.contractedSeats;
  }

  get monthlyDiscountedCopilotAddonPrice() {
    return this.discountedCopilotSeatPrice * this.args.contractedSeats;
  }

  get monthlyOverageCopilotAddonPrice() {
    return this.fullCopilotSeatPrice * this.args.overageSeats;
  }

  get monthlyTotal() {
    return (
      this.monthlyFullContractedSeatPrice +
      this.monthlyContractedCopilotSeatPrice +
      this.monthlyOverageSeatPrice +
      this.monthlyOverageCopilotSeatPrice +
      this.fullProactiveAddonPrice
    );
  }

  get monthlyDiscountedTotal() {
    let contractedTotal =
      this.monthlyDiscountedContractedSeatPrice +
      this.monthlyDiscountedContractedCopilotSeatPrice +
      this.fullProactiveAddonPrice;

    let totalWithMonthlyOverages =
      this.monthlyOverageSeatPrice + contractedTotal + this.monthlyOverageCopilotSeatPrice;

    this.args.setContractedTotal(contractedTotal);
    this.args.setMonthlyTotal(totalWithMonthlyOverages);
    return totalWithMonthlyOverages;
  }

  get yearlySavings() {
    return this.quoteService.formatPrice(this.monthlyTotal * 12 - this.monthlyDiscountedTotal * 12);
  }

  // prices
  quote(id: number) {
    return this.quoteService.getQuoteById([id]);
  }

  get fullSeatPrice() {
    return this.quote(this.args.selectedPlanId)?.fullSeatPrice(this.args.selectedPlanId) || 0;
  }

  get copilotSeatPrice() {
    return (
      this.quote(FIN_AI_COPILOT_BASE_ID_NUM)?.fullSeatPrice(
        FIN_AI_COPILOT_BASE_ID_NUM,
        Metric.copilot_seat_count,
      ) ?? 0
    );
  }

  get discountedSeatPrice() {
    return (
      this.quote(this.args.selectedPlanId)?.discountedAnnualSeatPrice(this.args.selectedPlanId) || 0
    );
  }

  get fullProactiveAddonPrice() {
    if (!this.selectedProactiveAddon) {
      return 0;
    }
    return (
      this.quote(PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM)?.fullBreakdownAmount(
        PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM,
      ) ?? 0
    );
  }

  get fullCopilotSeatPrice() {
    return (
      this.quote(FIN_AI_COPILOT_BASE_ID_NUM)?.fullSeatPrice(
        FIN_AI_COPILOT_BASE_ID_NUM,
        Metric.copilot_seat_count,
      ) ?? 0
    );
  }

  get discountedCopilotSeatPrice() {
    return (
      this.quote(FIN_AI_COPILOT_BASE_ID_NUM)?.discountedAnnualSeatPrice(
        FIN_AI_COPILOT_BASE_ID_NUM,
        Metric.copilot_seat_count,
      ) ?? 0
    );
  }

  get selectedProactiveAddon() {
    return this.args.addOnIds?.includes(PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::AnnualManageSubscription::OrderSummary': typeof OrderSummary;
  }
}
