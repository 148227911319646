/* import __COLOCATED_TEMPLATE__ from './news-inserter.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';

const intercomToursPackageId = 'intercom-news';

export default class NewsInserter extends Component {
  @service contentEditorService;
  @service store;

  @tracked messengerApps = [];

  constructor() {
    super(...arguments);
    this.fetchMessengerApps.perform();
  }

  @action
  createBlock(type, args) {
    let block = Object.assign({}, { type }, args);
    this.args.selectNews(block);
  }

  @task({ drop: true })
  *fetchMessengerApps() {
    let messengerApps = yield this.store.query('messenger-app', { restrict_to: 'messages' });
    this.messengerApps = messengerApps;
  }

  get inserterApi() {
    return { createBlock: this.createBlock };
  }

  get newsInserterContext() {
    return {
      object_type: this.contentEditorService.configRulesetLink.objectType,
      object_id: this.contentEditorService.activeObjectId,
      location: 'message',
    };
  }

  get newsMessengerApp() {
    return this.messengerApps.find((app) => app.app_package_id === intercomToursPackageId);
  }
}
