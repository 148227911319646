/* import __COLOCATED_TEMPLATE__ from './cluster-list-comparison-counters.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import percent from 'embercom/lib/percentage-formatter';
import { underscore } from '@ember/string';
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import { useResource } from 'ember-resources';

export default class ClusterListComparisonCounters extends Component {
  dataResource = useResource(this, ChartDataResourceCompatible, () => ({
    dataConfig: this.args.dataConfig,
    viewConfig: this.args.viewConfig,
  }));

  get countersByInterval() {
    let responses = this.dataResource.rawChartData?.[0].aggregations[0].values[0] || [];

    return this.args.tabs.reduce((groups, item) => {
      let key = underscore(item.label);
      let currentRangeAgg = responses.findBy('key', key);
      let currentCount = currentRangeAgg?.doc_count;

      groups[item.interval] = { currentCount };

      if (item.isComparison) {
        let previousRangeAgg = responses.findBy('key', `previous_${key}`);
        let previousCount = previousRangeAgg?.doc_count || 0;
        groups[item.interval]['percentChange'] = percent(
          previousCount,
          currentCount - previousCount,
        );
      }

      return groups;
    }, {});
  }
}
