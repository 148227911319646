/* import __COLOCATED_TEMPLATE__ from './sub-migration-choice-seats.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import templateOnlyComponent from '@ember/component/template-only';
import type Charge from 'embercom/models/billing/price/charge';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';

interface Args {
  translationKey: string;
  fullSeatTitle: string;
  liteSeatTitle: string;
  fullSeatTooltipLinkUrl: string;
  liteSeatTooltipLinkUrl: string;
  howWeAssignSeatsLink?: string;
  howWeAssignSeatsContent?: string;
  estimatedCoreSeatCharge: Charge;
  estimatedLiteSeatCount: number;
  isConfirmedByCustomer?: boolean;
  migrationSeatConfiguration?: MigrationSeatConfiguration;
}

interface Signature {
  Args: Args;
}

const BillingMigrationChoiceSeats = templateOnlyComponent<Signature>();
export default BillingMigrationChoiceSeats;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationSubcontent::SubMigrationChoiceSeats': typeof BillingMigrationChoiceSeats;
  }
}
