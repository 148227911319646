/* import __COLOCATED_TEMPLATE__ from './guidance-composer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { type Block } from 'embercom/models/common/blocks/block';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import type Store from '@ember-data/store';
import {
  INLINE_CONTROL_ALIGNMENT,
  type TextDirection,
} from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';

class ComposerConfig extends BaseConfig {
  autoFocus = true;
  allowedBlocks = ['paragraph'];
  allowedInline = ['bold', 'italic'];
  hideFromTextFormatter = [INLINE_CONTROL_ALIGNMENT];
  allowTextAlignment = false;
  textDirection: TextDirection = 'auto';
  tools = [{ name: 'text-formatter' }];

  constructor({ placeholder }: { app: any; placeholder: string }) {
    super();
    this.placeholder = placeholder;
  }
}

interface Signature {
  Element: HTMLDivElement;
  Args: {
    part: $TSFixMe; // operator/models/bot-intro/part;
    placeholder: string;
    onPartChange?: () => void;
  };
}

export default class GuidanceComposer extends Component<Signature> {
  @service declare store: Store;
  @service declare appService: $TSFixMe;

  @tracked blocksDoc: BlocksDocument;
  @tracked composerApi: any;
  @tracked openInserters: string[] = [];

  constructor(owner: unknown, args: never) {
    super(owner, args);
    this.blocksDoc = new BlocksDocument(this.serializedPart);
  }

  get app() {
    return this.appService.app;
  }

  get composerConfig() {
    let config = new ComposerConfig({
      app: this.app,
      placeholder: this.args.placeholder,
    });

    return config;
  }

  get serializedPart() {
    return this.args.part.jsonBlocks.toArray();
  }

  get hasError() {
    return !this.args.part.validations.isValid;
  }

  @action updateBlocks(blocksDoc: BlocksDocument) {
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.args.part.blocks = blockFragments;
    this.args.part.notifyPropertyChange('blocks');
    this.args.onPartChange?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::GuidanceComposer': typeof GuidanceComposer;
    'ai-agent/guidance-composer': typeof GuidanceComposer;
  }
}
