/* import __COLOCATED_TEMPLATE__ from './restore-version-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type ArticleContent from 'embercom/models/articles/article-content';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  article: ArticleContent;
  selectedVersion: any;
  closeSideDrawer: () => void;
  onVersionRestore: () => void;
  isLatestVersion: boolean;
}

export default class RestoreVersionButton extends Component<Args> {
  @service router: $TSFixMe;
  @tracked showConfirmModal = false;
  @service appService: $TSFixMe;
  @service declare intl: IntlService;

  @action
  async restoreVersion() {
    await this.args.article.restoreVersion(this.args.selectedVersion);
    this.args.closeSideDrawer();
    this.transitionToEdit();
  }

  get app() {
    return this.appService.app;
  }

  get buttonCopy() {
    if (this.app.showArticleVersionHistoryInKnowledgeHub) {
      if (this.args.isLatestVersion) {
        return this.intl.t('articles.editor.side-drawer.restore-version.edit-latest');
      } else {
        return this.intl.t('articles.editor.side-drawer.restore-version.button-new');
      }
    }
    return this.intl.t('articles.editor.side-drawer.restore-version.button');
  }

  @action onButtonClick() {
    if (this.app.showArticleVersionHistoryInKnowledgeHub && this.args.isLatestVersion) {
      this.restoreVersion();
    } else {
      this.showConfirmModal = true;
    }
  }

  transitionToEdit() {
    if (
      this.app.showArticleVersionHistoryInKnowledgeHub &&
      this.router.currentRouteName === 'apps.app.knowledge-hub.view'
    ) {
      this.router.transitionTo('apps.app.knowledge-hub.edit', 'article', this.args.article.id);
    } else if (
      this.app.showArticleVersionHistoryInKnowledgeHub &&
      this.router.currentRouteName === 'apps.app.knowledge-hub.edit'
    ) {
      // user is editing latest draft, stay in this route
      return;
    } else if (this.router.currentRouteName === 'apps.app.articles.articles.article-content.edit') {
      this.args.onVersionRestore();
    } else {
      this.router.transitionTo(
        'apps.app.articles.articles.article-content.edit',
        this.args.article,
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Show::Content::RestoreVersionButton': typeof RestoreVersionButton;
    'articles/show/content/restore-version-button': typeof RestoreVersionButton;
  }
}
