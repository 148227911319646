/* import __COLOCATED_TEMPLATE__ from './attribute-select.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { A } from '@ember/array';
import { computed, action } from '@ember/object';
import Component from '@ember/component';
import { isEmpty } from '@ember/utils';
import { findByProperty } from '@intercom/pulse/lib/computed-properties';
import defaultTo from '@intercom/pulse/lib/default-to';

export default Component.extend({
  tagName: 'span',
  filterPlaceholder: defaultTo('Search data'),
  dropdownDisplayMode: defaultTo('inline'),

  selectTagName: computed('dropdownDisplayMode', function () {
    return this.dropdownDisplayMode === 'block' ? 'div' : 'span';
  }),

  buttonType: 'secondary',

  //this can also be passed in from the consuming component
  selectedAttribute: findByProperty('allAttributes', 'identifier', 'selectedAttributeIdentifier'),

  shouldShowFilter: computed('hasFilter', function () {
    if (isEmpty(this.hasFilter)) {
      return true;
    }
    return this.hasFilter;
  }),

  allAttributes: computed('attributeGroupList.[]', function () {
    let groupList = this.attributeGroupList;
    let attributes = A();

    groupList.forEach((group) => {
      if (group.attributes) {
        //not all groups have attributes
        attributes.pushObjects(group.attributes);
      }
    });

    return attributes;
  }),

  mappedItems: computed(
    'selectedAttributeIdentifier',
    'attributeGroupList.[]',
    'selectedAttribute',
    'disabledAttributeIdentifiers.[]',
    function () {
      let selectedAttribute;
      if (this.selectedAttributeIdentifier) {
        selectedAttribute = this.selectedAttribute;
      }

      let items = [];
      let disabledIdentifierToReasonLookup = {};
      if (Array.isArray(this.disabledAttributeIdentifiers)) {
        // convert from an array of identifiers to a mapping from identifier => an undefined reason
        this.disabledAttributeIdentifiers.forEach(
          (identifier) => (disabledIdentifierToReasonLookup[identifier] = undefined),
        );
      } else if (this.disabledAttributeIdentifiers) {
        disabledIdentifierToReasonLookup = this.disabledAttributeIdentifiers;
      }

      this.attributeGroupList.forEach((group) => {
        let itemGroup = {
          heading: group.heading,
          items: [],
        };

        if (group.attributes) {
          group.attributes.forEach((attribute) => {
            if (attribute) {
              let isSelected = attribute === selectedAttribute;
              let isDisabled = disabledIdentifierToReasonLookup.hasOwnProperty(
                attribute.identifier,
              );
              let item = {
                text: attribute.name,
                icon: attribute.icon,
                value: attribute.identifier,
                descriptionForTooltip: isDisabled ? undefined : attribute.description,
                component: attribute.component || this.attributeItemComponent,
                componentShouldReplaceItem: true,
                isDisabled,
                isSelected,
                paywallOpenUpgradeModal: attribute.paywallOpenUpgradeModal,
                paywallAnalyticsEventData: attribute.paywallAnalyticsEventData,
                tooltipText: disabledIdentifierToReasonLookup[attribute.identifier],
              };
              itemGroup.items.push(item);
            }
          });
        }

        items.push(itemGroup);
      });
      return items;
    },
  ),

  _findAttribute(identifier) {
    let attribute;
    for (let i = 0, length = this.get('attributeGroupList.length'); i < length; i++) {
      attribute = this.attributeGroupList.objectAt(i).attributes.findBy('identifier', identifier);
      if (attribute) {
        break;
      }
    }
    return attribute;
  },

  addAttribute: action(function (attributeOrIdentifier) {
    let attribute =
      typeof attributeOrIdentifier === 'string'
        ? this._findAttribute(attributeOrIdentifier)
        : attributeOrIdentifier;
    this.onSelectAttribute(attribute);
  }),
});
