/* import __COLOCATED_TEMPLATE__ from './fin-multilingual-settings.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type MultilingualSettings from 'embercom/models/ai-agent/multilingual-settings';

interface SettingsArgs {
  aiAgentMultilingualSettings: MultilingualSettings;
  body: any;
  languages: any;
}

interface Locale {
  name: string;
  localeId: string;
}

export default class FinMultilingualSettings extends Component<SettingsArgs> {
  @service declare intl: IntlService;

  constructor(owner: unknown, args: SettingsArgs) {
    super(owner, args);
  }

  get defaultLocaleName() {
    return this.args.languages.supportedLocales.find(
      (locale: Locale) => locale.localeId === this.args.languages.defaultLocale,
    )?.name;
  }

  get availableLanguages() {
    let availableLanguages = this.args.languages.supportedLocales.map((locale: Locale) => ({
      text: locale.name,
      value: locale.localeId,
    }));

    // do not change this value - we use it in the backend to fallback to the app locale
    availableLanguages.unshift({
      value: 'app_locale',
      text: this.intl.t('ai-agent.settings.multilingual.real-time-translation.default-language', {
        defaultLanguage: this.defaultLocaleName,
      }),
    });
    return availableLanguages;
  }

  get supportedLanguages() {
    let supportedLanguages = this.args.languages.supportedLocales
      .filter((locale: any) => locale.isPermitted)
      .map((locale: any) => locale.name);

    supportedLanguages.unshift(
      `${this.defaultLocaleName} (${this.intl.t('ai-agent.settings.multilingual.real-time-translation.default')})`,
    );
    return supportedLanguages;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::FinMultilingualSettings': typeof FinMultilingualSettings;
    'ai-agent/fin-multilingual-settings': typeof FinMultilingualSettings;
  }
}
