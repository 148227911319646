/* import __COLOCATED_TEMPLATE__ from './social-link-picker.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type SupportedNetworksType } from 'embercom/models/help-center-social-link';
import type HelpCenterSocialLink from 'embercom/models/help-center-social-link';
import { NetworkMap } from 'embercom/models/help-center-social-link';

type Args = {
  site: HelpCenterSite;
};

export default class SocialLinkPicker extends Component<Args> {
  @action
  onOrderChange(socialLinksUpdate: Array<HelpCenterSocialLink>) {
    this.args.site.socialLinks.clear();
    this.args.site.socialLinks.addObjects(socialLinksUpdate);
  }

  @action
  onRemoveSocialLink(socialLink: HelpCenterSocialLink) {
    this.args.site.removeSocialLink(socialLink);
  }

  get items() {
    let alreadyChosen = this.args.site.socialLinks.map((socialLink) => socialLink.socialNetwork);
    return Object.keys(NetworkMap)
      .filter((key) => !alreadyChosen.includes(key))
      .map((key) => ({ text: key, value: key, icon: key === 'x' ? 'twitter-x' : key }));
  }

  get availableOptions() {
    return [
      {
        isFilterable: true,
        items: this.items,
      },
    ];
  }

  @action
  createNewLink(socialNetwork: SupportedNetworksType) {
    this.args.site.addSocialLink(socialNetwork);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Content::SocialLink::SocialLinkPicker': typeof SocialLinkPicker;
  }
}
