/* import __COLOCATED_TEMPLATE__ from './plan-card.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Signature {
  Args: {
    title: string;
    subtitle: string;
    planName: string;
    planPrice: string;
    whatsIncludedDescription: string;
    coreSeats: string;
    coreSeatsPrice: string;
    lightSeats: string;
    isEarlyStage?: boolean;
    isEssentialPlan?: boolean;
    proactiveSupportPlusPrice?: string;
    copilotPrice?: string;
    whatsChangingDescription: string;
    addonsDescription?: string;
    ctaLabel: string;
    ctaAction: () => void;
    seatCount?: number;
    lightSeatCount?: number;
    onReviewFeatures?: () => void;
    disableButton: boolean;
    disabledButtonTooltip: string;
  };
}

export default class PlanCardComponent extends Component<Signature> {
  @service purchaseAnalyticsService: any;

  @action
  onReviewFeatures() {
    if (this.args.onReviewFeatures) {
      this.args.onReviewFeatures();
      this.trackClickEvent('review_features');
    }
  }

  @action
  trackClickEvent(objectName: string) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'recommended_plan_card',
      place: 'update_subscription',
      object: objectName,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::UpdateEarlyStageSubscription::PlanCard': typeof PlanCardComponent;
    'billing/update-early-stage-subscription/plan-card': typeof PlanCardComponent;
  }
}
