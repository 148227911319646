/* import __COLOCATED_TEMPLATE__ from './search-bar.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  type HeaderAlignment,
  type HeaderJustify,
} from 'embercom/models/customization-options/styles/header';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  site: HelpCenterSite;
}

const MAX_BORDER_RADIUS_VALUE = 30;
export default class SearchBar extends Component<Args> {
  maxBorderRadiusValue = MAX_BORDER_RADIUS_VALUE;

  @tracked borderRadius = this.searchBarStyle.borderRadius;

  get searchBarStyle() {
    return this.args.site.customizationOptions.global.namedComponents.searchBar.style;
  }

  get headerStyle() {
    return this.args.site.customizationOptions.global.namedComponents.header.style;
  }

  get searchBarLength() {
    return this.searchBarStyle.width;
  }

  @action
  onLengthChange(width: string) {
    this.searchBarStyle.width = width;
  }

  get headerAlignment() {
    return this.headerStyle.align;
  }

  @action
  onAlignmentChange(type: HeaderAlignment) {
    this.headerStyle.align = type;
  }

  get headerJustify() {
    return this.headerStyle.justify;
  }

  @action
  onJustifyChange(type: HeaderJustify) {
    this.headerStyle.justify = type;
  }

  @action
  setBorderRadius() {
    if (this.borderRadius < 0 || this.borderRadius > this.maxBorderRadiusValue) {
      return;
    }

    this.searchBarStyle.borderRadius = this.borderRadius;
  }

  @action
  onBorderRadiusBlur() {
    if (this.borderRadius > this.maxBorderRadiusValue) {
      this.borderRadius = this.maxBorderRadiusValue;
    } else if (this.borderRadius < 0) {
      this.borderRadius = 0;
    }
    this.setBorderRadius();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Styling::SearchBar': typeof SearchBar;
  }
}
