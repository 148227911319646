/* import __COLOCATED_TEMPLATE__ from './whatsapp-installation.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class WhatsappInstallation extends Component {
  @tracked connectWhatsappFlow = false;
  @tracked selectedIntegration = {};

  constructor() {
    super(...arguments);
    this.selectedIntegration = this.whatsappIntegrations?.firstObject;
  }

  get whatsappIntegrations() {
    return this.args.whatsappIntegrations;
  }

  get integrationsDropdown() {
    let dropdownItems = this.whatsappIntegrations.map((integration) => {
      return {
        text: integration.displayString,
        value: integration,
        isSelected: this.selectedIntegration?.id === integration.id,
      };
    });

    return [
      {
        hasItemsMarkableAsSelected: true,
        items: dropdownItems,
      },
    ];
  }

  @action
  selectIntegration(integration) {
    this.selectedIntegration = integration;
  }
}
