/* import __COLOCATED_TEMPLATE__ from './button-group.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { set } from '@ember/object';
import { copy } from 'ember-copy';
import { map } from '@ember/object/computed';
import Component from '@ember/component';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';

// Example content:
// [{
//   value: 'chat',
//   label: 'Chat',
//   icon: 'chat-bubble',
//   feature: 'some-feature-name'
// },
// {
//   value: 'small-announcement',
//   label: 'Note',
//   description: 'Send a quick update',
//   icon: 'inapp-note',
//   tooltip: {
//     component: 'tooltips/some-component',
//     content: 'something',
//     interactive: 'true',
//     location: 'right'
//   }
// }]

export default Component.extend({
  app: null,
  selected: null,
  paywallService: service(),
  content: notImplemented('common/button-group: You must override the `content` property'),
  buttons: map('content', function (item) {
    return copy(item, true);
  }),
  didReceiveAttrs() {
    this._super(...arguments);
    this.updateSelectedItem();
  },
  updateSelectedItem() {
    let buttons = this.buttons;
    let button = buttons.findBy('value', this.selected);
    buttons.setEach('selected', false);
    if (button) {
      set(button, 'selected', true);
    }
  },
  actions: {
    select(value) {
      if (value === this.selected) {
        return;
      }
      if (this.buttons.findBy('value', value).disabled) {
        return;
      }
      let feature = this.buttons.findBy('value', value).feature;
      if (feature) {
        this.paywallService
          .paywall({ featureName: feature })
          .then(() => this.onSelected(value))
          .catch(this.paywallService.handleError);
      } else {
        this.onSelected(value);
      }
    },
  },
});
