/* import __COLOCATED_TEMPLATE__ from './predictive-answers-toggle.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class PredictiveAnswersToggle extends Component {
  @service appService;
  @service attributeService;

  @tracked userSelection = this.allowPredictiveAnswersForAllUsers ? 'all' : 'match';
  @tracked visitorSelection = this.allowPredictiveAnswersForAllVisitors ? 'all' : 'match';

  get showDisableAnswerMessage() {
    return (
      (this.args.isDisplayingUserGroup &&
        this.args.aiAgentSettings.predictiveAnswersEnabledForUsers) ||
      (!this.args.isDisplayingUserGroup &&
        this.args.aiAgentSettings.predictiveAnswersEnabledForVisitors)
    );
  }

  get allowPredictiveAnswersForAllUsers() {
    return (
      this.args.aiAgentSettings.predictiveAnswersForUsersPredicateGroup.predicates.length === 0
    );
  }

  get allowPredictiveAnswersForAllVisitors() {
    return (
      this.args.aiAgentSettings.predictiveAnswersForVisitorsPredicateGroup.predicates.length === 0
    );
  }

  get app() {
    return this.appService.app;
  }

  get attributeGroupListForUsers() {
    return this.attributeService.attributeGroupListForUsers;
  }

  get attributeGroupListForVisitors() {
    return this.attributeService.attributeGroupListForAnonymous;
  }
}
