/* import __COLOCATED_TEMPLATE__ from './test-answer-modal.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import ajax from 'embercom/lib/ajax';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask, restartableTask } from 'ember-concurrency-decorators';
import { isEmpty } from 'underscore';
import CustomAnswer from 'embercom/models/custom-answers/custom-answer';
import { RESOLUTION_BOT_REQUIRED_PERMISSION } from 'embercom/lib/operator/resolution-bot/constants';

export default class AnswersTestAnswerModalComponent extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;
  @service notificationsService;
  @service permissionsService;
  @service router;

  @tracked query = '';
  @tracked queryMatchesTestAnswer = false;
  @tracked isSubmitted = false;
  @tracked matchingAnswer = null;

  get app() {
    return this.appService.app;
  }

  @action
  resetQuery(answer) {
    this._trackMatchingAnswerForQuery(answer, { action: 'reset' });

    this.query = '';
    this.queryMatchesTestAnswer = false;
    this.isSubmitted = false;
    this.matchingAnswer = null;
  }

  @action
  submit(answer, e) {
    this.findAnswerForQuery.perform(answer, e);
  }

  @action
  handleEnterKey(answer, e) {
    if (e.shiftKey) {
      return;
    }

    if (this.isQueryEmpty) {
      e.preventDefault();
      return;
    }

    e.preventDefault();
    this.findAnswerForQuery.perform(answer, e);
  }

  @action
  async setLive(answer) {
    try {
      await this.permissionsService.checkPermission(RESOLUTION_BOT_REQUIRED_PERMISSION);
    } catch (e) {
      this.args.closeModal();
      return;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'set_live',
      object: answer,
      context: 'rb_preview_answer_modal',
    });

    this.setAnswerLive.perform(answer);
  }

  get canSetAnswerLive() {
    return this.matchingAnswer.titleIsComplete && this.matchingAnswer.answerCanBeSetLive;
  }

  get isMatchingAnswerPathEmpty() {
    let firstPath = this.matchingAnswer.paths.firstObject;
    let firstStep = firstPath?.steps.firstObject;
    let firstBlock = firstStep?.blocks.firstObject;

    if (!firstStep || !firstBlock) {
      return true;
    }

    if (firstBlock.type === 'paragraph') {
      return !firstBlock.hasContent;
    }

    return firstStep.hasNoBlocks;
  }

  get isQueryEmpty() {
    return isEmpty(this.trimmedQuery);
  }

  get formattedQuery() {
    return this.trimmedQuery.replace(/\n\r?/g, '<br />');
  }

  get trimmedQuery() {
    return this.query.trim();
  }

  get isProcessing() {
    return this.findAnswerForQuery.isRunning;
  }

  get canShowAnswer() {
    return this.isSubmitted || this.findAnswerForQuery.isRunning;
  }

  get hasNotRun() {
    return this.findAnswerForQuery.performCount === 0;
  }

  get hasMatchingAnswer() {
    return this.matchingAnswer !== null;
  }

  @dropTask
  *setAnswerLive(answer) {
    let previousStatus = answer.status;
    answer.set('status', 'live');

    try {
      yield answer.save();
      this.intercomEventService.trackEvent('answer-bot-interacted');
      this.intercomEventService.trackEvent('answer-bot-answer-saved-live');
      this.notificationsService.notifyConfirmation('The answer was set live.');

      this.router.transitionTo('apps.app.fin-ai-agent.custom-answers.answer', answer.id);
      this.args.closeModal();
    } catch (e) {
      answer.set('status', previousStatus);
      this.notificationsService.notifyError(
        this.intl.t('custom-answers.test-answer.set-live-error'),
      );
    }
  }

  @restartableTask
  *findAnswerForQuery(answer, e) {
    try {
      let rawMatch = yield this._findMatchingAnswerForQuery(answer);
      if (rawMatch?.id === answer.id) {
        this.queryMatchesTestAnswer = true;
        this.matchingAnswer = answer;
      } else if (rawMatch?.id) {
        this.matchingAnswer = yield CustomAnswer.loadFullAnswer(rawMatch.id);
      }
      this._trackMatchingAnswerForQuery(answer, { action: 'submitted' });
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('custom-answers.test-answer.error'));
    } finally {
      this.isSubmitted = true;
    }
  }

  async _findMatchingAnswerForQuery(answer) {
    let { answer: rawAnswer } = await ajax({
      url: `/ember/custom_answers/custom_answers/${answer.id}/test_answer`,
      type: 'POST',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        answer: answer.serialize(),
        query: this.trimmedQuery,
      }),
    });

    return !isEmpty(rawAnswer) ? rawAnswer : null;
  }

  _trackMatchingAnswerForQuery(answer, { action }) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'rb_preview_answer_modal',
      query_length: this.trimmedQuery.length,
      match_answer_id: this.matchingAnswer?.id,
      test_answer_is_match: this.queryMatchesTestAnswer,
      test_answer_id: answer.id,
      test_answer_language_code: answer.languageCode,
      test_answer_status: answer.status,
    });
  }
}
