/* import __COLOCATED_TEMPLATE__ from './stepped-graduation-banner.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class SteppedGraduationBanner extends Component {
  @service intl;

  get graduationDate() {
    return this.intl.formatDate(this.earlyStageGraduation.graduationDate, {
      month: 'short',
      day: 'numeric',
    });
  }

  get earlyStageGraduation() {
    return this.args.earlyStageGraduation;
  }
}
