/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { generateShareableUrl } from 'embercom/models/checklists/checklist';
import { isPresent } from '@ember/utils';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  ruleset: $TSFixMe;
  isViewMode: boolean;
}

export default class ChecklistSharingOptionsSummary extends Component<Args> {
  @service declare store: Store;
  @service attributeService: $TSFixMe;
  @service declare intl: IntlService;

  get ruleset() {
    return this.args.ruleset;
  }

  get rulesetId() {
    return this.ruleset.id;
  }

  get hasShareableUrl() {
    return isPresent(this.ruleset?.clientData?.shareableUrl);
  }

  get generatedShareableUrl() {
    let url = this.ruleset.clientData.shareableUrl;
    return generateShareableUrl(url, this.rulesetId);
  }

  get shareableUrlEmptyState() {
    if (this.args.isViewMode) {
      return this.intl.t('outbound.content-editor.panels.checklist-sharing.edit-generate-link');
    }

    return this.intl.t('outbound.content-editor.panels.checklist-sharing.expand-generate-link');
  }

  get triggerFromCodeEmptyState() {
    if (this.args.isViewMode) {
      return this.intl.t('outbound.content-editor.panels.checklist-sharing.trigger-from-code');
    }

    return this.intl.t('outbound.content-editor.panels.checklist-sharing.expand-for-code');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::SharingOptions::Summary': typeof ChecklistSharingOptionsSummary;
    'checklist/sharing-options/summary': typeof ChecklistSharingOptionsSummary;
  }
}
