/* import __COLOCATED_TEMPLATE__ from './cda-onboarding-display-row-component.hbs'; */
/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
export default Component.extend({
  intercomEventService: service(),
  tagName: 'tr',

  actions: {
    addSample() {
      this.set('added', true);
      this.cdaList.unshiftObject({
        name: this.name,
        description: this.description,
        type: this.type,
      });

      this.intercomEventService.trackEvent('sample-cda-added', {
        action: 'added',
        object: this.name,
        place: 'cda_tool',
        owner: 'growth',
      });
    },

    viewAttributes() {
      this.set('selectedTab', 'cda');
    },
  },
});
