/* import __COLOCATED_TEMPLATE__ from './guidelines.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface GuidelinesArgs {}

export const GUIDELINE_CATEGORIES = ['routing', 'clarification', 'tone', 'other'];

export default class Guidelines extends Component<GuidelinesArgs> {
  @service declare intl: IntlService;

  get categories() {
    return GUIDELINE_CATEGORIES.map((category) => {
      return {
        id: category,
        title: this.intl.t(`ai-agent.guidance.guidelines.categories.${category}.title`),
        description: this.intl.t(`ai-agent.guidance.guidelines.categories.${category}.description`),
      };
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Guidance::Guidelines': typeof Guidelines;
  }
}
