/* import __COLOCATED_TEMPLATE__ from './chrome.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ChatChromeComponent extends Component {
  @service appService;

  get appColor() {
    return this.appService.app.base_color;
  }

  get isWeb() {
    return this.args.deviceType === 'web';
  }
}
