/* import __COLOCATED_TEMPLATE__ from './date-and-time-picker.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { action } from '@ember/object';

export default class ScheduleDateAndTimePicker extends Component {
  @service appService;

  get changeAt() {
    return this._changeAtInTimezone();
  }

  get timezone() {
    return this.appService.app.timezone;
  }

  _changeAtInTimezone() {
    return moment(this.args.selectedDate).tz(this.timezone);
  }

  @action
  setChangeAtHours(hours) {
    let changeAtCopy = this._changeAtInTimezone();
    changeAtCopy.set('hour', hours);
    this.args.onChange(changeAtCopy.toDate());
  }

  @action
  setChangeAtDate(date) {
    let changeAtCopy = this._changeAtInTimezone();
    changeAtCopy.set({
      date: date.moment.date(),
      month: date.moment.month(),
      year: date.moment.year(),
    });
    this.args.onChange(changeAtCopy.toDate());
  }
}
