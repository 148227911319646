/* import __COLOCATED_TEMPLATE__ from './custom-font-face-panel.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type FontFace from 'embercom/models/customization-options/global-options/font-face';
import { tracked } from '@glimmer/tracking';

type Args = {
  fontFace: FontFace;
  allFontFaces: FontFace[];
  alreadyExists: boolean;
  onRemove: (fontFace: FontFace) => void;
  onFontFamilyChange: (fontFace: FontFace, fontFamily: string) => void;
};

export default class CustomFontFacePanel extends Component<Args> {
  @tracked showConfirmDeleteModal = false;

  @action
  updateFontFaceFamily(event: Event) {
    let fontFamily = (event.target as HTMLInputElement).value;
    this.args.onFontFamilyChange(this.args.fontFace, fontFamily);
  }

  @action
  confirmDelete() {
    this.showConfirmDeleteModal = false;
    this.args.onRemove(this.args.fontFace);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Styling::Fonts::CustomFontFacePanel': typeof CustomFontFacePanel;
  }
}
