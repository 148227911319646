/* import __COLOCATED_TEMPLATE__ from './standard-tab.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class StandardTab extends Component {
  @service intercomEventService;

  get isActive() {
    return this.args.config.view === this.args.activeView;
  }

  @action
  changeView() {
    if (!this.isActive) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: `${this.args.config.view}_tab`,
      });
      this.args.changeView(this.args.config.view);
    }
  }
}
