/* import __COLOCATED_TEMPLATE__ from './authentication-error.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';

export default Component.extend({
  intercomEventService: service(),
  redirectService: service(),
  regionService: service(),
  appService: service(),
  notificationsService: service(),
  app: readOnly('appService.app'),

  reauthenticate: task(function* () {
    try {
      let signedRedirectUrl = yield this.generateRedirectUrl.perform(
        this.reauthenticationUrl,
        true,
      );
      this.trackReauthenticationRequest();
      this.redirectService.redirect(signedRedirectUrl);
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
  }).drop(),

  trackReauthenticationRequest() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'reauthentication_button',
      action: 'clicked',
      place: 'salesforce_settings',
    });
  },

  reauthenticationUrl: computed('app.id', 'regionService.salesforceBaseURL', function () {
    return `${this.regionService.salesforceBaseURL}/salesforce/reauthenticate?app_id=${this.app.id}`;
  }),
});
