/* import __COLOCATED_TEMPLATE__ from './business-profile-settings.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { allVerticals } from 'embercom/models/data/whatsapp/whatsapp-constants';

export default class BusinessProfileSettings extends Component {
  @service appService;
  @service notificationsService;
  @service intercomEventService;
  @service intl;

  get allVerticals() {
    return allVerticals.map((vertical) => {
      return {
        text: this.intl.t(vertical.text),
        value: vertical.value,
      };
    });
  }

  @task({ restartable: true })
  *updateBusinessProfileSettings() {
    try {
      yield this.args.whatsappIntegration?.updateBusinessProfileSettings({
        app: this.appService.app,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('appstore.settings.whatsapp.business-profile-settings.notification.success'),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'business_profile_settings_updated',
        object: this.args.whatsappIntegration,
      });
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyError(
        this.intl.t('appstore.settings.whatsapp.business-profile-settings.notification.error'),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'update_business_profile_settings_failed',
        object: this.args.whatsappIntegration,
      });
    }
  }
}
