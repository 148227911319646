/* import __COLOCATED_TEMPLATE__ from './resolution-rate.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { AVERAGE_RESOLUTION_RATE } from 'embercom/lib/operator/resolution-bot/constants';
import { inject as service } from '@ember/service';

export const ANSWER_EFFECTIVENESS_ITEMS = [
  {
    labelKey:
      'components.answers.reporting.resolution-rate.answer-effectiveness-items.no-action-required.label',
    key: 'no_action_required',
    color: 'green',
    tooltipKey:
      'components.answers.reporting.resolution-rate.answer-effectiveness-items.no-action-required.tooltip',
  },
  {
    labelKey:
      'components.answers.reporting.resolution-rate.answer-effectiveness-items.minimal-action-required.label',
    key: 'minimal_action_required',
    color: 'answer-bot-green',
    tooltipKey:
      'components.answers.reporting.resolution-rate.answer-effectiveness-items.minimal-action-required.tooltip',
  },
  {
    labelKey:
      'components.answers.reporting.resolution-rate.answer-effectiveness-items.action-required.label',
    key: 'action_required',
    color: 'gray-light',
    tooltipKey:
      'components.answers.reporting.resolution-rate.answer-effectiveness-items.action-required.tooltip',
  },
  {
    labelKey:
      'components.answers.reporting.resolution-rate.answer-effectiveness-items.negative-feedback.label',
    key: 'negative_feedback',
    color: 'vis-salmon-70',
    tooltipKey:
      'components.answers.reporting.resolution-rate.answer-effectiveness-items.negative-feedback.tooltip',
  },
];

const ANSWER_EFFECTIVENESS_KEYS = ANSWER_EFFECTIVENESS_ITEMS.map((item) => item.key);

export const EFFECTIVE_ANSWER_KEYS = [ANSWER_EFFECTIVENESS_KEYS[0], ANSWER_EFFECTIVENESS_KEYS[1]];

function calculateAggregate(aggregateBucketIndexes = [0], valuesByKey = {}) {
  return aggregateBucketIndexes.reduce((total, index) => {
    return total + (valuesByKey[index] || 0);
  }, 0);
}

export default class ResolutionRate extends Component {
  @service intl;
  @service appService;

  get showAbandonedLegendItem() {
    let abandonedCount = this.report.totalsPerKey.negative_feedback || 0;
    return abandonedCount !== 0;
  }

  get legendItems() {
    return ANSWER_EFFECTIVENESS_ITEMS.map((item) => ({
      ...item,
      label: this.intl.t(item.labelKey),
      tooltip: this.intl.t(item.tooltipKey),
    }));
  }
  get tooltips() {
    return this.legendItems.reduce((hash, item) => ({ ...hash, [item.key]: item.tooltip }), {});
  }
  averageResolutionRate = AVERAGE_RESOLUTION_RATE;

  get report() {
    return this.args.signal.aggregateSignalAsStackablePercentageData();
  }

  get currentValue() {
    return calculateAggregate(
      EFFECTIVE_ANSWER_KEYS,
      this.args.signal.aggregateSignalAsStackablePercentageData().percentagesByKey,
    );
  }

  get previousValue() {
    return calculateAggregate(
      EFFECTIVE_ANSWER_KEYS,
      this.args.signal.aggregateSignalAsStackablePercentageData(true).percentagesByKey,
    );
  }

  get count() {
    return calculateAggregate(
      EFFECTIVE_ANSWER_KEYS,
      this.args.signal.aggregateSignalAsStackablePercentageData().totalsPerKey,
    );
  }

  get total() {
    return this.args.signal.aggregateSignalAsStackablePercentageData().total;
  }
}
