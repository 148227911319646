/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, and, not, notEmpty, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
const TABS = {
  message: {
    label: 'Message',
    isPercentageValue: false,
    views: ['content'],
    color: 'text-gray',
  },
  controlGroup: {
    label: 'Control group',
    isPercentageValue: false,
    views: ['content'],
    color: 'text-gray',
  },
  controlGroupMembers: {
    label: 'People',
    isPercentageValue: false,
    views: ['members'],
  },
  audience: {
    label: 'Audience',
    isPercentageValue: false,
    views: ['audience'],
  },
  pending: {
    label: 'Pending',
    isPercentageValue: false,
    views: ['pending'],
  },
  sent: {
    label: 'Sent',
    isPercentageValue: false,
    views: ['sent'],
  },
  opened: {
    label: 'Opened',
    isPercentageValue: true,
    views: ['opened'],
    color: 'outbound__opens-stat',
  },
  clicked: {
    label: 'Clicked',
    isPercentageValue: true,
    views: ['clicked'],
    color: 'outbound__clicks-stat',
  },
  collected: {
    label: 'Collected',
    isPercentageValue: true,
    views: ['collected'],
    color: 'outbound__collected-stat',
  },
  replied: {
    label: 'Replied',
    isPercentageValue: true,
    views: ['conversations', 'replied'],
    color: 'outbound__replies-stat',
  },
  failed: {
    label: 'Failed',
    isPercentageValue: true,
    views: ['failed', 'unsubscribed', 'bounced', 'complained'],
    color: 'outbound__failed-stat',
  },
  goal: {
    label: 'Goal',
    isPercentageValue: true,
    views: ['goal'],
    color: 'outbound__goal-stat',
  },
};

export default Component.extend({
  stats: readOnly('variation.stats'),
  message: readOnly('variation.message'),
  tabs: computed(
    'stats.lastLoadedAt',
    'variation.isControlGroup',
    'variation.isEmail',
    'variation.isInApp',
    'variation.isMobilePush',
    function () {
      if (this.get('variation.isControlGroup')) {
        return this.getControlGroupTabs();
      } else if (this.get('variation.isEmail')) {
        return this.getEmailTabs();
      } else if (this.get('variation.isInApp')) {
        return this.getInAppTabs();
      } else if (this.get('variation.isMobilePush')) {
        return this.getPushTabs();
      }
    },
  ),

  hasReactions: notEmpty('variation.reactions'),
  hasNoReactions: not('hasReactions'),
  hasRepliesEnabled: not('variation.hasRepliesDisabled'),
  showReplies: and('hasNoReactions', 'hasRepliesEnabled'),
  showAudience: readOnly('message.isStoppableManualMessage'),
  appService: service(),
  app: alias('appService.app'),

  getTabContent(name, value = '') {
    let clickDisabled =
      this.get('message.isVisitorAutoMessage') && this.tabIsDisabledForVisitorAutoMessage(name);
    return Object.assign({}, TABS[name], {
      statistic: value,
      isClickable: !clickDisabled,
    });
  },

  tabIsDisabledForVisitorAutoMessage(name) {
    return ['sent', 'opened', 'controlGroupMembers'].includes(name);
  },

  buildControlGroupTab() {
    return Object.assign(this.getTabContent('controlGroup'), {
      icon: this.get('variation.typeIcon'),
    });
  },
  buildControlGroupMembersStats() {
    return this.getTabContent('controlGroupMembers', this.get('stats.sentCount'));
  },

  buildMessageContent() {
    return Object.assign(this.getTabContent('message'), { icon: this.get('variation.typeIcon') });
  },
  buildAudienceStats() {
    return this.getTabContent('pending', this.get('message.remainingSendCount'));
  },
  buildSentStats() {
    return this.getTabContent('sent', this.get('stats.sentCount'));
  },
  buildOpenedStats() {
    return this.getTabContent('opened', this.get('stats.openPercentage'));
  },
  buildClickedStats() {
    return this.getTabContent('clicked', this.get('stats.clickPercentage'));
  },
  buildGoalStats() {
    return this.getTabContent('goal', this.get('stats.goalSuccessPercentage'));
  },
  buildCollectedStats() {
    return this.getTabContent('collected', this.get('stats.attributeCollectorReplyPercentage'));
  },

  getControlGroupTabs() {
    let tabs = [];
    tabs.pushObject(this.buildControlGroupTab());
    tabs.pushObject(this.buildControlGroupMembersStats());
    tabs.pushObject(this.buildGoalStats());
    return tabs;
  },

  getEmailTabs() {
    let tabs = [];
    tabs.pushObject(this.buildMessageContent());
    tabs.pushObject(this.buildSentStats());
    tabs.pushObject(this.buildOpenedStats());
    if (this.get('message.hasGoal')) {
      tabs.pushObject(this.buildGoalStats());
    }

    tabs.pushObject(this.buildClickedStats());

    tabs.pushObject(this.getTabContent('replied', this.get('stats.replyPercentage')));
    tabs.pushObject(this.getTabContent('failed', this.get('stats.failedPercentage')));
    return tabs;
  },

  getInAppTabs() {
    let tabs = [];
    tabs.pushObject(this.buildMessageContent());
    if (this.showAudience) {
      tabs.pushObject(this.buildAudienceStats());
    }
    tabs.pushObject(this.buildSentStats());
    tabs.pushObject(this.buildOpenedStats());

    if (this.get('message.hasGoal')) {
      tabs.pushObject(this.buildGoalStats());
    }

    tabs.pushObject(this.buildClickedStats());

    if (this.get('variation.isAttributeCollectorReply')) {
      tabs.pushObject(this.buildCollectedStats());
    }
    if (this.showReplies) {
      tabs.pushObject(this.getTabContent('replied', this.get('stats.replyPercentage')));
    }
    return tabs;
  },

  getPushTabs() {
    let tabs = [];
    tabs.pushObject(this.buildMessageContent());
    tabs.pushObject(this.buildSentStats());
    tabs.pushObject(this.buildOpenedStats());
    if (this.get('message.hasGoal')) {
      tabs.pushObject(this.buildGoalStats());
    }
    return tabs;
  },
});
