/* import __COLOCATED_TEMPLATE__ from './duplicate-modal.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { NO_TRIGGER_TARGET } from 'embercom/lib/operator/custom-bots/constants';

import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';
import { OUTBOUND_EDITOR_ROUTES } from 'embercom/models/outbound/content-wrapper';

import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { post } from 'embercom/lib/ajax';

interface Args {
  onModalClose: () => void;
  isOpen: boolean;
  answer: $TSFixMe;
}

type DuplicableTargets = typeof NO_TRIGGER_TARGET;

export default class DuplicateModal extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare contentEditorService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare router: RouterService;
  @service declare intl: IntlService;

  @tracked selectedTarget: DuplicableTargets;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.selectedTarget = NO_TRIGGER_TARGET;
  }

  get app() {
    return this.appService.app;
  }

  get duplicationOptions() {
    let botTypeOptions: Array<{ text: string; value: DuplicableTargets }> = [
      {
        text: this.intl.t('operator.custom-bot.duplicate-modal.type.no-trigger'),
        value: NO_TRIGGER_TARGET,
      },
    ];

    return botTypeOptions;
  }

  get isDuplicating() {
    return taskFor(this.duplicateAnswer).isRunning;
  }

  @action
  updateSelectedTarget(value: DuplicableTargets) {
    this.selectedTarget = value;
  }

  @task({ drop: true })
  *duplicateAnswer(): TaskGenerator<void> {
    let { objectType, botType } = CUSTOM_BOT_CONFIG[this.selectedTarget];
    try {
      let result = yield post(
        `/ember/custom_answers/custom_answers/${this.args.answer.id}/duplicate`,
        {
          app_id: this.appService.app.id,
          new_object_type: objectType,
          new_object_data: {
            type: botType,
            target: this.selectedTarget,
            visual_builder_object: this.args.answer.visualBuilderObject.serialize(),
          },
        },
      );

      let routes = OUTBOUND_EDITOR_ROUTES as Record<string, string>;
      let objectName = objectNames[objectType];
      let outboundRoute = routes[objectName];
      this.notificationsService.notifyConfirmation(
        this.intl.t('custom-answers.duplicate-modal.success'),
      );
      this.router.transitionTo(outboundRoute, result.id, {
        queryParams: { mode: 'edit' },
      });
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('custom-answers.duplicate-modal.error'));
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Answers::DuplicateModal': typeof DuplicateModal;
    'answers/duplicate-modal': typeof DuplicateModal;
  }
}
