/* import __COLOCATED_TEMPLATE__ from './slider.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { action } from '@ember/object';
//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

const SLIDER_COLOUR_PROGRESS = '#334bfa';
const SLIDER_COLOUR_EMPTY = '#dedede';

interface Args {
  minimum?: number;
  step?: number;
  maximum: number;
  index: number;
  onChange: (index: number) => void;
}

export default class Slider extends Component<Args> {
  get value(): number {
    return this.args.index;
  }

  get step(): number {
    return this.args.step ?? 1;
  }
  get minimum(): number {
    return this.args.minimum ?? 0;
  }

  get maximum(): number {
    return this.args.maximum;
  }

  @action
  onInput({ target }: any): void {
    let value = Number(target.value);
    this.args.onChange(value);
  }

  get currentValue(): number {
    return ((this.value - this.minimum) / (this.maximum - this.minimum)) * 100;
  }

  get gradientStyle(): string {
    return sanitizeHtml(
      `background: linear-gradient(to right, ${SLIDER_COLOUR_PROGRESS} 0%, ${SLIDER_COLOUR_PROGRESS} ${this.currentValue}%, ${SLIDER_COLOUR_EMPTY} ${this.currentValue}%, ${SLIDER_COLOUR_EMPTY} 100%)`,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::Slider': typeof Slider;
    'common/slider': typeof Slider;
  }
}
