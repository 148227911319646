/* import __COLOCATED_TEMPLATE__ from './subscription-usage-header.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';

interface Args {
  shouldHidePrices: boolean;
  isPrimarySubscription: boolean;
}

export default class SubscriptionUsageHeader extends Component<Args> {
  @service declare appService: any;
  @service intl!: IntlService;

  get subscriptionTitle() {
    if (this.args.isPrimarySubscription) {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.plan-group-heading.subscription-title-all-workspaces',
      );
    }

    return this.intl.t(
      'billing.summary.price-usage-breakdown-card.plan-group-heading.subscription-title-single-workspace',
      { workspaceName: this.appService.app.name },
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::SubscriptionUsageHeader': typeof SubscriptionUsageHeader;
    'billing/summary/subscription-usage-header': typeof SubscriptionUsageHeader;
  }
}
