/* import __COLOCATED_TEMPLATE__ from './company-name-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
export default class CompanyNameComponent extends Component {
  @service customerService;
  @tracked companyNameChangeModalIsOpen = false;
  @tracked companyNameText = this.customer.entityName;

  get customer() {
    return this.customerService.customer;
  }

  @action
  updateCompanyName(companyName) {
    this.companyNameText = companyName;
  }
}
