/* import __COLOCATED_TEMPLATE__ from './usage-alerts-limits-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { task } from 'ember-concurrency-decorators';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { mapUsageReminderType } from 'embercom/helpers/billing/usage-helper';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type RouterService from '@ember/routing/router-service';
import type Charge from 'embercom/models/billing/price/charge';
import type Contract from 'embercom/models/billing/contract';
import type UsageReminderModel from 'embercom/models/usage-reminder';
import { taskFor } from 'ember-concurrency-ts';
import { type UsageAlertLimitAmountInputArgs } from 'embercom/components/billing/usage/usage-alerts-limits-modal-input';

const CAN_USE_LIMITS = [Metric.resolutions_with_custom_answers];
const METRICS_WITH_DOLLAR_AMOUNT = [Metric.calling];

interface Args {
  charge: Charge;
  isAnnualOrSalesforceContracted: boolean;
  contractedUsage: number;
  savedUsageReminder: UsageReminderModel;
  contract: Contract;
  closeModal: () => void;
  usage: number;
  title: string;
  currentMetricUsageLimit: $TSFixMe;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class UsageAlertsLimitsModal extends Component<Signature> {
  @service declare modalService: $TSFixMe;
  @service declare router: RouterService;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare customerService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomConfirmService: IntercomConfirmService;

  // usage
  @tracked alertEnabled = this.reminder ? !this.reminder.deleted : false;
  @tracked alertAmount = this.getInitialAlertAmount;
  @tracked tiered = this.reminder ? this.reminder.tiered : true;
  @tracked price = 0;

  // limit
  @tracked limitEnabled = (this.limit && this.limit.enabled) || false;
  @tracked limitAmount = this.limit ? this.limit.threshold : null;
  @tracked limitPrice = 0;

  @tracked hasStateChanged = false;

  selectedMetric = this.args.charge.pricing_metric;
  primaryButtonLabel = 'billing.usage.modal-usage-alerts-and-limits.primary_button';
  secondaryButtonLabel = 'billing.usage.modal-usage-alerts-and-limits.secondary_button';
  tertiaryButtonLabel = 'billing.usage.modal-usage-alerts-and-limits.billing_contacts_link';

  @action
  toggleAlertState() {
    this.alertEnabled = !this.alertEnabled;

    if (this.alertEnabled) {
      setTimeout(function () {
        $('[data-test-usage-alerts-modal-amount]:first').focus();
      }, 50);
    }
  }

  @action
  toggleLimitState() {
    this.limitEnabled = !this.limitEnabled;

    if (this.limitEnabled) {
      setTimeout(function () {
        $('[data-test-usage-alerts-modal-amount]:eq(1)').focus();
      }, 50);
    }
  }

  @action
  openBillingContacts() {
    let url = this.router.urlFor('apps.app.billing.settings', this.appService.app.id);
    safeWindowOpen(url, '_blank');
  }

  get getInitialAlertAmount() {
    let initialAmount;
    if (this.reminder) {
      initialAmount = this.reminder.threshold;
    } else if (this.args.isAnnualOrSalesforceContracted && this.args.contractedUsage > 0) {
      initialAmount = this.args.contractedUsage;
    } else if (this.canUseLimits) {
      initialAmount = 100;
    } else {
      initialAmount = null;
    }

    if (
      initialAmount &&
      initialAmount > 0 &&
      METRICS_WITH_DOLLAR_AMOUNT.includes(this.selectedMetric)
    ) {
      initialAmount /= 100;
    }

    return initialAmount;
  }

  /*
   * Save button should be disabled if:
   * - initial state - alert not set & not enabled
   *
   * - already alarm
   * --- alert deleted number & enabled
   *
   * - initial state - limit not set & not enabled
   *
   * - already limit
   * --- limit deleted & enabled
   *
   * */
  get saveButtonDisabled() {
    if (!this.canUseLimits && this.alertShouldDisableSave) {
      return true;
    }

    if (this.canUseLimits && this.alertShouldDisableSave && this.limitShouldDisableSave) {
      return true;
    }

    if (
      (!this.isAlertInitialState && this.alertShouldDisableSave) ||
      (!this.isLimitInitialState && this.limitShouldDisableSave)
    ) {
      return true;
    }

    return false;
  }

  get isAlertInitialState() {
    if (
      !this.hasAlertStateChanged &&
      (this.alertAmount === this.getInitialAlertAmount ||
        (this.alertAmount !== null && Number(this.alertAmount) === this.getInitialAlertAmount)) &&
      !this.hasThresholdChanged
    ) {
      return true;
    }

    return false;
  }

  get isLimitInitialState() {
    if (
      !this.hasLimitStateChanged &&
      this.limitAmount === (this.limit ? this.limit.threshold : null)
    ) {
      return true;
    }

    return false;
  }

  get alertShouldDisableSave() {
    if (this.isAlertInitialState) {
      return true;
    }

    if (
      this.alertEnabled &&
      (this.alertAmount === null || this.alertAmount <= 0 || isNaN(this.alertAmount))
    ) {
      return true;
    }

    return false;
  }

  get limitShouldDisableSave() {
    if (this.isLimitInitialState) {
      return true;
    }

    if (
      this.canUseLimits &&
      this.limitEnabled &&
      (this.limitAmount <= 0 || isNaN(this.limitAmount))
    ) {
      return true;
    }

    return false;
  }

  get hasAlertStateChanged() {
    return this.reminder
      ? this.reminder.deleted === this.alertEnabled
      : this.alertEnabled !== false;
  }

  get hasLimitStateChanged() {
    return (
      this.canUseLimits &&
      (this.limit ? this.limit.enabled !== this.limitEnabled : this.limitEnabled !== false)
    );
  }

  get hasThresholdChanged() {
    return this.reminder ? this.reminder.tiered !== this.tiered : !this.tiered;
  }

  get isAlertValid() {
    return this.formattedAmount(this.alertAmount) > 0;
  }

  get isLimitValid() {
    return this.canUseLimits && this.formattedAmount(this.limitAmount) > 0;
  }

  get limit() {
    return this.args.currentMetricUsageLimit;
  }

  get reminder() {
    return this.args.savedUsageReminder;
  }

  formattedAmount(amount: UsageAlertLimitAmountInputArgs['initialAmount']) {
    amount = Number(amount);
    if (METRICS_WITH_DOLLAR_AMOUNT.includes(this.selectedMetric)) {
      return amount * 100;
    }

    return amount;
  }

  successNotificationText(hasAlertSaved: boolean, hasLimitSaved: boolean) {
    if (hasAlertSaved && this.canUseLimits && hasLimitSaved) {
      return this.intl.t('billing.usage.modal-usage-alerts-and-limits.save-both.success');
    }
    if (hasAlertSaved) {
      return this.intl.t('billing.usage.modal-usage-alerts-and-limits.save.success');
    }
    if (this.canUseLimits && hasLimitSaved) {
      return this.intl.t('billing.usage.modal-usage-alerts-and-limits.limits.save.success');
    }
    return '';
  }

  errorNotificationText(hasAlertSaved: boolean, hasLimitSaved: boolean): string | undefined {
    if (!hasAlertSaved && this.canUseLimits && !hasLimitSaved) {
      return this.intl.t('billing.usage.modal-usage-alerts-and-limits.save-both.error');
    }
    if (!hasAlertSaved) {
      return this.intl.t('billing.usage.modal-usage-alerts-and-limits.save.error');
    }
    if (this.canUseLimits && !hasLimitSaved) {
      return this.intl.t('billing.usage.modal-usage-alerts-and-limits.limits.save.error');
    }
    return undefined;
  }

  get isAlertBelowUsageForPeriod() {
    return this.isThresholdBelowUsageForPeriod(this.alertAmount);
  }

  get isLimitBelowUsageForPeriod() {
    return this.isThresholdBelowUsageForPeriod(this.limitAmount);
  }

  get shouldShowAlertBelowUsageWarning() {
    // If there is already a reminder saved, don't show the warning,
    // only if the alert amount is different than the reminder's amount
    if (
      this.alertEnabled &&
      this.reminder &&
      this.reminder.threshold === Number(this.alertAmount)
    ) {
      return false;
    }

    return this.alertEnabled && this.isAlertBelowUsageForPeriod;
  }

  get shouldShowNextCycleLabel() {
    // If there is already a reminder saved with willStartNextBillingCycle true,
    // however the alertAmount has been modified, we shouldn't show the warning
    return (
      this.reminder?.willStartNextBillingCycle &&
      this.reminder?.threshold === Number(this.alertAmount)
    );
  }

  isThresholdBelowUsageForPeriod(amount: UsageAlertLimitAmountInputArgs['initialAmount']): boolean {
    return Boolean(amount && amount > 0 && amount <= this.usage);
  }

  get isThresholdBelowContractForPeriod() {
    let contractUsageLimit = this.args?.contract?.contractUsageLimits[this.selectedMetric];

    if (this.args.isAnnualOrSalesforceContracted && contractUsageLimit) {
      return this.limitAmount < contractUsageLimit;
    }

    return false;
  }

  @action
  async showConfirmationBox() {
    // If there are no changes, allow them to just close the modal
    if (this.saveButtonDisabled) {
      this.args.closeModal();
      return;
    }

    let confirmOptions = {
      title: this.intl.t('billing.usage.modal-usage-alerts-and-limits.confirm-options.title'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t(
        'billing.usage.modal-usage-alerts-and-limits.confirm-options.confirm-button-text',
      ),
      cancelButtonText: this.intl.t(
        'billing.usage.modal-usage-alerts-and-limits.confirm-options.cancel-button-text',
      ),
      body: this.intl.t('billing.usage.modal-usage-alerts-and-limits.confirm-options.body'),
    };

    let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
    if (isConfirmed) {
      this.args.closeModal();
    }
  }

  @action
  async save() {
    let hasAlertSaved = true;
    let hasLimitSaved = true;

    if (!this.alertEnabled && this.reminder) {
      this.reminder.deleteRecord();
      this.reminder.save();
    }

    if (this.alertEnabled) {
      hasAlertSaved = await taskFor(this.saveReminder).perform();
    }

    if (this.canUseLimits) {
      hasLimitSaved = await taskFor(this.saveLimit).perform();
    }

    if (!hasAlertSaved || !hasLimitSaved) {
      this.notificationsService.notifyError(
        this.errorNotificationText(hasAlertSaved, hasLimitSaved),
      );
      return;
    }

    this.notificationsService.notifyConfirmation(
      this.successNotificationText(hasAlertSaved, hasLimitSaved),
    );
    this.args.closeModal();
  }

  @task({ drop: true })
  *saveReminder() {
    let reminder = this.reminder;
    if (reminder === undefined) {
      reminder = this.store.createRecord('usage-reminder', {
        threshold: this.formattedAmount(this.alertAmount),
        usageType: mapUsageReminderType(this.selectedMetric),
        tiered: this.tiered,
        willStartNextBillingCycle: this.isAlertBelowUsageForPeriod,
        deleted: !this.alertEnabled,
      });
    } else {
      reminder.threshold = this.formattedAmount(this.alertAmount);
      reminder.usageType = mapUsageReminderType(this.selectedMetric);
      reminder.tiered = this.tiered;
      reminder.willStartNextBillingCycle = this.isAlertBelowUsageForPeriod;
      reminder.deleted = !this.alertEnabled;
    }

    try {
      yield reminder.save();
    } catch (e) {
      reminder.rollbackAttributes();
      return false;
    }

    return true;
  }

  @task({ drop: true })
  *saveLimit() {
    let limit = this.limit;
    if (limit === undefined) {
      limit = this.store.createRecord('fin-usage-limit', {
        threshold: this.limitAmount,
        enabled: this.limitEnabled,
      });
    } else {
      limit.threshold = this.limitAmount;
      limit.enabled = this.limitEnabled;
    }

    try {
      yield limit.save();
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('billing.usage.modal-usage-alerts-and-limits.limits.save.error'),
      );
      limit.rollbackAttributes();
      return false;
    }

    return true;
  }

  @action
  updateAlertAmount(amount: number) {
    this.alertAmount = amount;
  }

  @action
  updateLimitAmount(amount: number) {
    this.limitAmount = amount;
  }

  get usage() {
    return this.args.usage;
  }

  get canUseLimits() {
    return CAN_USE_LIMITS.includes(this.selectedMetric);
  }

  get titleTranslationKey() {
    return this.canUseLimits
      ? 'billing.usage.modal-usage-alerts-and-limits.header.alerts-and-limits'
      : 'billing.usage.modal-usage-alerts-and-limits.header.alerts';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::UsageAlertsLimitsModal': typeof UsageAlertsLimitsModal;
    'billing/usage/usage-alerts-limits-modal': typeof UsageAlertsLimitsModal;
  }
}
