/* import __COLOCATED_TEMPLATE__ from './attribute-company-tag-component.hbs'; */
/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { alias } from '@ember/object/computed';
import AttributeComponent from 'embercom/components/attribute-component';

let ProfileCompanyTagComponent = AttributeComponent.extend({
  segments: alias('userOrCompany.segments'),
});

export default ProfileCompanyTagComponent;
