/* import __COLOCATED_TEMPLATE__ from './feature-comparison.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Price from 'embercom/models/price';
import { PROACTIVE_SUPPORT_PLUS_BASE_ID } from 'embercom/lib/billing';
import { getFeaturesData } from 'embercom/helpers/billing/migrations-helper';
import type IntlService from 'embercom/services/intl';
import type StripeMigration from 'embercom/models/billing/stripe-migration';

export interface AddonData {
  name: string;
  tooltipContent: string;
  proactiveSupportPlusHCLink: string;
}

interface Args {
  migration: StripeMigration;
  allFeatures: string[];
  availableFeaturesForApp: string[];
  availableEssentialFeatures: string[];
  availableAdvancedFeatures: string[];
  availableExpertFeatures: string[];
  availablePSPFeatures: string[];
  essentialWithPSPEstimate: Price;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationFeatureComparisonComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @tracked showOnlyDifferences = true;
  @tracked showFeaturesLost = false;
  @tracked showNewFeatures = false;

  get tableData() {
    let data: any = [];
    let featuresData: any = getFeaturesData(
      {
        allFeatures: this.args.allFeatures,
        availableFeaturesForApp: this.args.availableFeaturesForApp,
        availableEssentialFeatures: this.args.availableEssentialFeatures,
        availableAdvancedFeatures: this.args.availableAdvancedFeatures,
        availableExpertFeatures: this.args.availableExpertFeatures,
        availablePSPFeatures: this.args.availablePSPFeatures,
      },
      this.intl,
      this.showOnlyDifferences,
    );

    data = featuresData.allData;

    if (this.showFeaturesLost) {
      data = featuresData.featuresLost;
    }

    if (this.showNewFeatures) {
      data = featuresData.featuresNew;
    }

    data = data.sort((a: any, b: any) =>
      a.feature.localeCompare(b.feature, undefined, { sensitivity: 'base' }),
    );

    return data;
  }

  get proactiveSupportPlusData(): AddonData {
    return {
      name: this.intl.t('billing.migrations.proactive_support_plus'),
      proactiveSupportPlusHCLink: this.proactiveSupportPlusHCLink,
      tooltipContent: this.intl.t('billing.migrations.proactive_support_plus_tooltip', {
        price: this.pspPlusPriceFormatted,
        htmlSafe: true,
      }),
    };
  }

  get proactiveSupportPlusHCLink(): string {
    return 'https://www.intercom.com/help/en/articles/9061648-proactive-support-plus';
  }

  private get pspPlusPriceFormatted() {
    return this.intl.formatNumber(this.pspPrice, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  private get pspPrice() {
    return this.pspPlanBreakdown.plan_starting_price / 100;
  }

  private get pspPlanBreakdown() {
    // Can either use the essential or advanced prices with PSP included. Just
    // need to see if there is any usage for messages_sent
    return this.args.essentialWithPSPEstimate.breakdown.find(
      (model: { plan_id: string }) => String(model.plan_id) === PROACTIVE_SUPPORT_PLUS_BASE_ID,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::FeatureComparison': typeof BillingMigrationFeatureComparisonComponent;
  }
}
