/* import __COLOCATED_TEMPLATE__ from './cluster-list-comparison-counter-tabs.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import { underscore } from '@ember/string';
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';
import { RANGE, CONVERSATION_CREATED_AT } from 'embercom/lib/reporting/flexible/constants';
import { buildFilters } from 'embercom/lib/reporting/flexible/filter-helpers';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { isEmpty } from 'underscore';

export default class ClusterListComparisonCounterTabs extends Component {
  get tabs() {
    return [
      {
        interval: 7,
        label: '7 days',
        isComparison: true,
      },
      {
        interval: 28,
        label: '28 days',
        isComparison: true,
      },
      {
        interval: 90,
        label: '90 days',
        isComparison: true,
      },
      {
        interval: 365,
        label: '1 year',
        isComparison: false,
      },
    ];
  }

  get selectedTab() {
    return this.tabs.findBy('interval', Number(this.args.selectedInterval));
  }

  get _maxIntervalItem() {
    return this.tabs.reduce((maxItem, item) => {
      return maxItem?.interval > item.interval ? maxItem : item;
    });
  }

  get _ranges() {
    return this.tabs.reduce((items, item) => {
      let currentRangeItem = {
        from: this._subtractIntervalFromDate(this.args.clusterRunUpdatedAt, item.interval) + 1,
        to: new Date(this.args.clusterRunUpdatedAt).getTime(),
        key: underscore(item.label),
      };

      if (item.isComparison) {
        let previousRangeItem = {
          from:
            this._subtractIntervalFromDate(this.args.clusterRunUpdatedAt, 2 * item.interval) + 1,
          to: this._subtractIntervalFromDate(this.args.clusterRunUpdatedAt, item.interval),
          key: underscore(`previous ${item.label}`),
        };
        items.push(previousRangeItem);
      }

      return items.concat(currentRangeItem);
    }, []);
  }

  _subtractIntervalFromDate(initialDate, subtractedInterval = 0) {
    let date = new Date(initialDate);
    return date.setDate(date.getDate() - subtractedInterval);
  }

  // -------- view abstraction component overrides --------
  get viewConfig() {
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value };
    viewConfig.aggregations = { defaultOption: RANGE };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[CONVERSATION_CREATED_AT],
      },
    };

    viewConfig.counter = {
      cluster_list_comparison_counter: {
        improvementDirection: improvementDirections.DECREASING,
      },
    };

    return viewConfig;
  }

  get dataConfig() {
    return {
      url: '/ember/custom_answers/signal_flexible',
      series: [
        {
          name: 'cluster_list_comparison_counter',
          source: 'answers',
          type: RANGE,
          data: {
            property: CONVERSATION_CREATED_AT,
            ranges: this._ranges,
          },
          time: {
            property: CONVERSATION_CREATED_AT,
            start:
              this._subtractIntervalFromDate(
                this.args.clusterRunUpdatedAt,
                this._maxIntervalItem.interval,
              ) + 1,
            end: new Date(this.args.clusterRunUpdatedAt).getTime(),
          },
          filter: this._transformFilterList([...this._getGlobalFilters()]),
        },
      ],
    };
  }

  // -------- global filters merging --------
  get globalFilters() {
    return this.args.filters || {};
  }

  _transformFilterList(list) {
    if (list.length === 0) {
      return;
    } else if (list.length === 1) {
      return list[0];
    } else {
      return { type: 'and', filters: list };
    }
  }

  _getGlobalFilters() {
    let filtersList = Object.entries(this.globalFilters).reduce((filtersList, [key, value]) => {
      if (value !== undefined) {
        let filters = buildFilters(key, value, { mergeCombined: true });
        return filtersList.concat(...filters);
      }
    }, []);

    return filtersList.filter((f) => !isEmpty(f));
  }
}
