/* import __COLOCATED_TEMPLATE__ from './render.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import intercomMessengerColors from '@intercom/intercom-messenger-colors';

export default class ScreenViewRender extends Component {
  shouldShowScrollHelper = false;

  get backgroundType() {
    let messengerColors = intercomMessengerColors({
      primaryColor: this.args.carousel.backgroundColor || '#fff',
    });
    return messengerColors.primary_type;
  }

  get composerPadding() {
    return 55 * this.args.screen.carouselActions.length;
  }

  get isLastScreen() {
    return this.args.screen.order === this.totalScreens - 1;
  }

  get totalScreens() {
    return this.args.screen.get('localizedCarouselContent.carouselScreens.length');
  }

  get isFirstScreen() {
    return this.args.screen.order === 0;
  }

  get isMiddleScreen() {
    return !this.isLastScreen && !this.isFirstScreen;
  }

  @action determineIfScrollBarShouldBeVisible(container) {
    let shadowElem = container.querySelector('.carousel__editor__carousel-composer__scroll-helper');
    if (!shadowElem) {
      return;
    }
    if (!this.args.isEditing && this.isScrollBarVisible(container)) {
      shadowElem.style.display = 'block';
    } else {
      shadowElem.style.display = 'none';
    }
  }

  isScrollBarVisible(container) {
    let el = container.querySelector('#composer-read-only-content');
    if (el) {
      return el.scrollHeight > el.clientHeight;
    }
  }
}
