/* import __COLOCATED_TEMPLATE__ from './basic.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    description: string;
    articleLinkText?: string | undefined;
    articleLink?: string;
    hasDarkIcon?: boolean;
    shouldShowInMessenger?: boolean;
    messengerArticleLink?:
      | { articleId: string; articleHelpCenterSelector: string }
      | { articleId: null; articleHelpCenterSelector: null };
  };
}

export default class Basic extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;

  @action
  openLinkInMessenger() {
    window.Intercom(
      'showArticle',
      this.args.messengerArticleLink?.articleId,
      // @ts-ignore
      null,
      this.args.messengerArticleLink?.articleHelpCenterSelector,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::Tooltips::Basic': typeof Basic;
  }
}
