/* import __COLOCATED_TEMPLATE__ from './channel-card-compact.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { billingStatuses } from 'embercom/lib/channels/constants';
import { capitalize } from '@ember/string';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';

interface Args {
  title: string;
  description: string;
  iconUrl: string;
  status: string;
  route: string;
  onAction?: () => void;
  trackObject: string;
  featureKey: string;
}

export default class ChannelCardCompact extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get statusLabel() {
    let localisedStatuses = {
      [billingStatuses.included]: this.intl.t('channels.channels-all.billing-status.included'),
      [billingStatuses.usage]: this.intl.t('channels.channels-all.billing-status.usage'),
      [billingStatuses.upgrade]: this.intl.t('channels.channels-all.billing-status.upgrade'),
    };

    if (!this.appService.app.canUseFeature(this.featureKey)) {
      return localisedStatuses['upgrade'];
    }

    return localisedStatuses[this.args.status?.toLowerCase()] || capitalize(this.args.status ?? '');
  }

  get featureKey() {
    return this.args.featureKey || 'unknown';
  }

  _handleClick() {
    if (this.args?.onAction) {
      this.args.onAction();
    } else {
      this.router.transitionTo(this.args.route);
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: this.args.trackObject || 'unknown',
      place: 'all_channels_settings',
      section: 'settings',
    });
  }

  @action
  buttonClick() {
    if (!(this.args.onAction || this.args.route)) {
      return;
    }
    this._handleClick();
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Channels::ChannelCardCompact': typeof ChannelCardCompact;
    'channels/channel-card-compact': typeof ChannelCardCompact;
  }
}
