/* import __COLOCATED_TEMPLATE__ from './browser-header.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import type HelpCenterSite from 'embercom/models/help-center-site';
import Component from '@glimmer/component';
import { SizeType } from 'embercom/components/articles/site/settings/react-preview';
import { inject as service } from '@ember/service';
import Clipboard from 'clipboard';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';

interface Signature {
  Args: {
    site: HelpCenterSite;
    faviconUrl: string;
    faviconDeleted: boolean;
    previewType: string;
    currentSizeType: SizeType;
  };
}

export default class BrowserHeader extends Component<Signature> {
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  clipboard: Clipboard | null = null;

  copyBtnContainer = modifier(
    (_: Element) => {
      this.onRender();
    },
    { eager: false },
  );

  willDestroy() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
    super.willDestroy();
  }

  @action
  onRender() {
    // when the domain is updated we re-render the component, but no need to
    // add the clipboard listener again since the data-clipboard-text is already set
    if (this.clipboard) {
      return;
    }
    let copyLinkButton = document.querySelector('#copy-btn');
    if (copyLinkButton) {
      this.clipboard = new Clipboard(copyLinkButton);
      let onCopySuccess = () => {
        this.notificationsService.removeNotification('copy-hc-url-success');
        this.notificationsService.notifyConfirmation(
          this.intl.t('articles.settings.appearance.header-preview.copy-hc-url-success'),
          2000,
          'copy-hc-url-success',
        );
        this.intercomEventService.trackAnalyticsEvent({
          action: 'success_copy_link',
          object: 'hc_preview',
          place: 'collections_page',
        });
      };

      let onCopyError = (trigger: any) => {
        // We show error for the user only if the problem is with the clipboard api, not the text
        if (trigger.text) {
          this.notificationsService.removeNotification('copy-hc-url-error');
          this.notificationsService.notifyWarning(
            this.intl.t('articles.settings.appearance.header-preview.copy-hc-url-error'),
            2000,
            'copy-hc-url-error',
          );
          this.intercomEventService.trackAnalyticsEvent({
            action: 'failed_copy_link',
            object: 'hc_preview',
            place: 'collections_page',
          });
        }
      };

      this.clipboard.on('success', onCopySuccess);
      this.clipboard.on('error', onCopyError);
    }
  }

  get url() {
    let baseUrl =
      this.args.site.customDomainAndPathPrefix ||
      this.args.site.defaultDomain.concat('/', this.args.site.identifier);
    return baseUrl.concat('/en');
  }

  get buttonCustomClass() {
    if (!this.isMobileSize) {
      return 'px-10 not-mobile';
    }
    return 'px-3 m-auto flex-grow';
  }

  get addressBarPadding() {
    if (this.isMobileSize) {
      return 'site-preview-url-bar-btn-holder';
    }
    return 'px-3';
  }

  get isMobileSize() {
    return this.args.currentSizeType === SizeType.Mobile;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Preview::BrowserHeader': typeof BrowserHeader;
    'articles/site/settings/preview/browser-header': typeof BrowserHeader;
  }
}
