/* import __COLOCATED_TEMPLATE__ from './invoice-table-row.hbs'; */
/* RESPONSIBLE TEAM: team-billing */
/* eslint-disable promise/prefer-await-to-then */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class InvoiceTableRow extends Component {
  @service notificationsService;
  @service paymentService;

  constructor(...args) {
    super(...args);
  }

  get canPayManually() {
    let { invoice } = this.args;
    return invoice.isOpen;
  }

  @action
  async openPaymentPage() {
    let { invoice, changeActiveInvoice } = this.args;

    if (invoice.isStripe) {
      safeWindowOpen(invoice.hostedInvoiceUrl, '_blank');
      return;
    }

    changeActiveInvoice();
    return this.fetchPayload().then((payload) => {
      if (payload.errors) {
        this.handleError(payload.errors);
      } else {
        this.handleSuccess(payload);
      }
    });
  }

  handleError(errors) {
    let { notificationsService } = this;
    let { closePaymentModal } = this.args;

    notificationsService.notifyError(errors);
    closePaymentModal();
  }

  handleSuccess(payload) {
    let { openPaymentModal, setPaymentPayload } = this.args;

    setPaymentPayload(payload);
    openPaymentModal();
  }

  async fetchPayload() {
    let { invoice, app } = this.args;
    return await ajax({
      url: '/ember/zuora_account/prepare_card_payment_payload',
      type: 'GET',
      data: { invoice_id: invoice.id, app_id: app.id },
    });
  }
}
