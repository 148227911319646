/* import __COLOCATED_TEMPLATE__ from './plan-starting-price.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Charge from 'embercom/models/billing/price/charge';
import type IntlService from 'embercom/services/intl';

interface Args {
  priceInCents: number;
  charge?: Charge;
  testIdentifier: string;
}

export default class PlanStartingPrice extends Component<Args> {
  @service customerService: any;
  @service declare intl: IntlService;

  get usage() {
    if (!this.customerService.isSelfServeAnnualCustomer) {
      return;
    }
    return this.args.charge?.plan_limit?.minimum;
  }

  get showUsage() {
    return this.usage !== undefined;
  }

  get title() {
    if (this.customerService.isSelfServeAnnualCustomer) {
      let numberOfSeatsIncludedInPlan = this.usage;
      return this.intl.t('billing.summary.plan-starting-price.self-serve-annual-plans', {
        numberOfSeatsIncludedInPlan,
      });
    } else {
      return this.intl.t('billing.summary.plan-starting-price.default');
    }
  }

  get price() {
    return this.args.priceInCents / 100;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::PlanStartingPrice': typeof PlanStartingPrice;
  }
}
