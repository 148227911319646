/* import __COLOCATED_TEMPLATE__ from './choice.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { isPresent } from '@ember/utils';
import {
  PLAN_DATA,
  PRICING_5_X_CORE_EXPERT_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  FIN_AI_COPILOT_BASE_ID,
} from 'embercom/lib/billing';
import type StripeMigration from 'embercom/models/billing/stripe-migration';
import type IntlService from 'embercom/services/intl';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type Price from 'embercom/models/price';
import type Charge from 'embercom/models/billing/price/charge';
import type Admin from 'embercom/models/admin';
import type Breakdown from 'embercom/models/billing/price/breakdown';
import type RouterService from '@ember/routing/router-service';
import { sortBy } from 'underscore';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';
import type { CopilotSeatInfo } from 'embercom/components/billing/stripe-migration/types';

interface Args {
  migration: StripeMigration;
  shouldShowSeatPrices: boolean;
  planSelection: string;
  setPlanSelection: (planId: string) => void;
  setAddonPlanIds: (addonPlanIds: string[]) => void;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  editPlanCopilotSeatsByPlanId: { [planId: string]: CopilotSeatInfo };
  copilotSeatPrice: number;
  addonPlanIds: string[];
  pricing5Estimates: {
    essentialEstimate: Price;
    essentialWithPSPEstimate: Price;
    essentialWithCopilotEstimate: Price;
    essentialWithPSPCopilotEstimate: Price;
    advancedEstimate: Price;
    advancedWithPSPEstimate: Price;
    advancedWithCopilotEstimate: Price;
    advancedWithPSPCopilotEstimate: Price;
    expertEstimate: Price;
    expertWithPSPEstimate: Price;
    expertWithCopilotEstimate: Price;
    expertWithPSPCopilotEstimate: Price;
  };
  hasAppliedSeatChanges: boolean;
  howWeAssignSeatsLink?: string;
  howWeAssignSeatsContent?: string;
  currentPrices: Price[];
  onProactiveMigrationSave: () => void;
  migrationSeatConfiguration: MigrationSeatConfiguration;
  onMigrationSeatConfigurationSave: (seatCounts: {
    core_seat_count: number;
    copilot_seat_count: number;
  }) => Promise<void>;
  onPackageConfirmation: any;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationChoiceComponent extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare customerService: any;
  @service declare notificationsService: any;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked consentFinTermsSelected = false;
  @tracked consentMovePricingSelected = false;
  @tracked showSeatMappingModal = false;

  currentConfigureSeatMappingNoticeLabel = '';

  get confirmationStepTitle(): string {
    if (this.args.migration.isProactive) {
      return this.intl.t('billing.migrations.step_3_title_proactive');
    }

    return this.intl.t('billing.migrations.step_3_title_reactive');
  }

  get corePlanChoices() {
    let essentialCopilotSeats =
      this.args.editPlanCopilotSeatsByPlanId[PRICING_5_X_CORE_ESSENTIAL_ID];
    let advancedCopilotSeats = this.args.editPlanCopilotSeatsByPlanId[PRICING_5_X_CORE_ADVANCED_ID];
    let expertCopilotSeats = this.args.editPlanCopilotSeatsByPlanId[PRICING_5_X_CORE_EXPERT_ID];
    return [
      {
        id: 'essential', // used for data-test attributes
        isEssentialPlan: true,
        planId: PRICING_5_X_CORE_ESSENTIAL_ID,
        seats: this.essentialSeats,
        totalSeatPrice: this.getSeatPrices(
          this.essentialSeats.core_seat_count,
          PRICING_5_X_CORE_ESSENTIAL_ID,
          essentialCopilotSeats,
        ),
        metadata: PLAN_DATA[PRICING_5_X_CORE_ESSENTIAL_ID],
        liteSeatInfo: this.includedLiteSeatLimitInfo(
          PLAN_DATA[PRICING_5_X_CORE_ESSENTIAL_ID].nameTranslationKey,
          PLAN_DATA[PRICING_5_X_CORE_ESSENTIAL_ID].liteSeatLimit,
        ),
        copilotSeatCount: essentialCopilotSeats.seatCount,
        copilotSeatPrice: essentialCopilotSeats.pricePerSeat,
      },
      {
        id: 'advanced', // used for data-test attributes
        isEssentialPlan: false,
        planId: PRICING_5_X_CORE_ADVANCED_ID,
        seats: this.advancedSeats,
        totalSeatPrice: this.getSeatPrices(
          this.advancedSeats.core_seat_count,
          PRICING_5_X_CORE_ADVANCED_ID,
          advancedCopilotSeats,
        ),
        metadata: PLAN_DATA[PRICING_5_X_CORE_ADVANCED_ID],
        liteSeatInfo: this.includedLiteSeatLimitInfo(
          PLAN_DATA[PRICING_5_X_CORE_ADVANCED_ID].nameTranslationKey,
          PLAN_DATA[PRICING_5_X_CORE_ADVANCED_ID].liteSeatLimit,
        ),
        copilotSeatCount: advancedCopilotSeats.seatCount,
        copilotSeatPrice: advancedCopilotSeats.pricePerSeat,
      },
      {
        id: 'expert',
        isEssentialPlan: false,
        planId: PRICING_5_X_CORE_EXPERT_ID,
        seats: this.expertSeats,
        totalSeatPrice: this.getSeatPrices(
          this.expertSeats.core_seat_count,
          PRICING_5_X_CORE_EXPERT_ID,
          expertCopilotSeats,
        ),
        metadata: PLAN_DATA[PRICING_5_X_CORE_EXPERT_ID],
        liteSeatInfo: this.includedLiteSeatLimitInfo(
          PLAN_DATA[PRICING_5_X_CORE_EXPERT_ID].nameTranslationKey,
          PLAN_DATA[PRICING_5_X_CORE_EXPERT_ID].liteSeatLimit,
        ),
        copilotSeatCount: expertCopilotSeats.seatCount,
        copilotSeatPrice: expertCopilotSeats.pricePerSeat,
      },
    ];
  }

  @action
  configureSeatMappingNoticeLabel() {
    if (!this.isSaveMigrationTaskRunning) {
      if (!this.args.hasAppliedSeatChanges) {
        if (this.args.migrationSeatConfiguration.hasCustomerSaved) {
          this.currentConfigureSeatMappingNoticeLabel = '';
        } else {
          this.currentConfigureSeatMappingNoticeLabel = this.intl.t(
            'billing.migrations.edit_plan.configure_seat_mapping_notice',
          );
        }
      } else {
        this.currentConfigureSeatMappingNoticeLabel = this.intl.t(
          'billing.migrations.edit_plan.configure_seat_mapping_notice_confirm',
        );
      }
    }

    return this.currentConfigureSeatMappingNoticeLabel;
  }

  getSeatPrices(coreSeatCount: number, planId: string, copilotSeatInfo: CopilotSeatInfo): number {
    return (
      coreSeatCount * PLAN_DATA[planId].pricePerSeat +
      copilotSeatInfo.seatCount * copilotSeatInfo.pricePerSeat
    );
  }

  includedLiteSeatLimitInfo(planNameTranslationKey: string, liteSeatLimit: number) {
    if (liteSeatLimit > 0) {
      if (this.liteSeatUsage <= liteSeatLimit) {
        return this.args.migration.isProactive
          ? ''
          : this.intl.t('billing.migrations.edit_plan.included_lite_seat_info', {
              liteSeatLimit,
            });
      }

      return '';
    } else {
      return this.intl.t('billing.migrations.edit_plan.lite_seat_not_included_info', {
        planName: this.intl.t(planNameTranslationKey),
      });
    }
  }

  get recommendedPlanIdsAsString() {
    return this.args.migration.recommendedPlanIds?.map((planId) => String(planId)) ?? [];
  }

  get proactiveSupportPlusPlanData() {
    return PLAN_DATA[PROACTIVE_SUPPORT_PLUS_BASE_ID];
  }

  get finAiCopilotPlanData() {
    return PLAN_DATA[FIN_AI_COPILOT_BASE_ID];
  }

  get submitButtonLabel() {
    return this.isSaveMigrationTaskRunning
      ? this.intl.t('billing.migrations.saving')
      : this.intl.t('billing.migrations.submit');
  }

  get isSaveMigrationTaskRunning() {
    return taskFor(this.saveMigrationTask).isRunning;
  }

  get submitButtonDisabled() {
    if (this.isSaveMigrationTaskRunning) {
      return true;
    }

    if (this.args.migration.isProactive) {
      return false;
    }

    return (
      !this.args.planSelection || !this.consentFinTermsSelected || !this.consentMovePricingSelected
    );
  }

  get essentialSeats() {
    return this.getSeatCounts(
      this.args.pricing5Estimates.essentialEstimate,
      this.appService.app.humanAdmins,
    );
  }

  get advancedSeats() {
    return this.getSeatCounts(
      this.args.pricing5Estimates.advancedEstimate,
      this.appService.app.humanAdmins,
    );
  }

  get expertSeats() {
    return this.getSeatCounts(
      this.args.pricing5Estimates.expertEstimate,
      this.appService.app.humanAdmins,
    );
  }

  get proactiveSupportPlusPrice() {
    let priceInCents: number = this.args.pricing5Estimates.essentialWithPSPEstimate.breakdown.find(
      (breakdown: Breakdown) => breakdown.plan_id === Number(PROACTIVE_SUPPORT_PLUS_BASE_ID),
    ).base_plan_price_pre_discount;

    return priceInCents / 100;
  }

  get proactiveSupportPlusIncludedUsage() {
    let charge = this.args.pricing5Estimates.essentialWithPSPEstimate.breakdown
      .find((breakdown: Breakdown) => breakdown.plan_id === Number(PROACTIVE_SUPPORT_PLUS_BASE_ID))
      .charges.find((charge: Charge) => charge.pricing_metric === Metric.messages_sent);

    return charge.base_usage;
  }

  get addonData() {
    return [
      {
        planId: PROACTIVE_SUPPORT_PLUS_BASE_ID,
        price: this.proactiveSupportPlusPrice,
        planData: this.proactiveSupportPlusPlanData,
        includedUsage: this.proactiveSupportPlusIncludedUsage,
        hcLink: this.proactiveSupportPlusHCLink,
        isDisabled: false,
        pricingMetric: this.intl.t('billing.summary.breakdown.group-title.messages_sent'),
      },
      {
        planId: FIN_AI_COPILOT_BASE_ID,
        planData: this.finAiCopilotPlanData,
        isDisabled: true,
        hcLink: this.finAiCopilotHCLink,
        addonInfo: this.customerService.customer?.subscription.isOnTprPricingModel
          ? this.intl.t('billing.migrations.edit_plan.copilot_in_new_plan')
          : this.intl.t('billing.migrations.edit_plan.manage_copilot_seats', {
              teammateSettingUrl: this.router.urlFor('apps.app.settings.workspace.teammates'),
              htmlSafe: true,
            }),
      },
    ];
  }

  get copilotTooltip() {
    return this.intl.t('billing.migrations.copilot_tooltip', {
      teammateSettingUrl: this.router.urlFor('apps.app.settings.workspace.teammates'),
      htmlSafe: true,
    });
  }

  @action
  showEditSeatsModal() {
    this.showSeatMappingModal = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'proactive_migrations_seat_assignment',
      place: 'edit_package',
      object: 'open_modal_button',
    });
  }

  @action
  toggleAddonToMigration(planId: string, alreadySelected: boolean) {
    if (alreadySelected) {
      this.args.setAddonPlanIds(this.args.addonPlanIds.filter((plan) => plan !== planId));
    } else {
      this.args.setAddonPlanIds([...this.args.addonPlanIds, planId]);
    }
  }

  @action
  onManageSeatsClicked() {
    return this.router.transitionTo('apps.app.settings.workspace.teammates');
  }

  getSeatCounts(price: Price, admins: Admin[]) {
    let charge = price.breakdown.firstObject.charges.find(
      (charge: Charge) => charge.pricing_metric === Metric.core_seat_count,
    );

    let totalAdmins = admins.length;
    let isEssentialPlan = price.planIds.includes(parseInt(PRICING_5_X_CORE_ESSENTIAL_ID, 10));

    return {
      core_seat_count: isEssentialPlan ? totalAdmins : charge.actual_usage,
      lite_seat_count: isEssentialPlan ? 0 : totalAdmins - charge.actual_usage,
    };
  }

  get liteSeatUsage() {
    return this.advancedSeats.lite_seat_count;
  }

  get hasLiteSeatUsage() {
    return this.liteSeatUsage > 0;
  }

  get intercomPlansExplainedHCLink() {
    return 'https://www.intercom.com/help/en/articles/9061614-intercom-plans-explained';
  }

  get proactiveSupportPlusHCLink() {
    return 'https://www.intercom.com/help/en/articles/9061648-proactive-support-plus';
  }

  get finAiCopilotHCLink() {
    return 'https://www.intercom.com/help/en/articles/9121384-fin-ai-copilot-pricing';
  }

  @action
  async saveSeatAssignmentConfig() {
    try {
      await this.args.migrationSeatConfiguration.save();
    } catch (_error) {
      this.notificationsService.notifyError(
        this.intl.t(
          'billing.migrations.edit_plan.admin_seat_mapping.body.new_seat_types.update_failed',
        ),
      );
    }
  }

  @restartableTask
  *saveMigrationTask() {
    let planIds = [parseInt(this.args.planSelection, 10)];
    let migration = this.args.migration;

    this.args.addonPlanIds.forEach((planId) => {
      planIds.push(parseInt(planId, 10));
    });

    migration.planIds = sortBy(planIds);

    try {
      if (
        isPresent(this.args.migrationSeatConfiguration) &&
        this.args.planSelection !== PRICING_5_X_CORE_ESSENTIAL_ID
      ) {
        yield this.saveSeatAssignmentConfig();
      }
      yield migration.save();
      if (migration.isProactive) {
        this.args.onProactiveMigrationSave();
        this.args.onPackageConfirmation.perform();
      } else {
        let formattedDate = this.intl.formatDate(migration.migrationDate, {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
        this.notificationsService.notifyConfirmation(
          this.intl.t('billing.migrations.submit_success_toast', { migrationDate: formattedDate }),
        );
      }
      this.args.setHasUnsavedChanges(false);
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('billing.migrations.submit_error_toast'));
      throw e;
    }
  }

  get allPlanIdsAsString(): string[] {
    let selectedPlans = [this.args.planSelection, ...this.args.addonPlanIds];
    return selectedPlans;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::Choice': typeof BillingMigrationChoiceComponent;
  }
}
