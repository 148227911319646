/* import __COLOCATED_TEMPLATE__ from './fin-tone-of-voice-settings.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import type ToneOfVoiceSettings from 'embercom/models/ai-agent/tone-of-voice-settings';

interface SettingsArgs {
  aiAgentToneOfVoiceSettings: ToneOfVoiceSettings;
  body: any;
}

export default class FinToneOfVoiceSettings extends Component<SettingsArgs> {
  @service declare intl: IntlService;

  @tracked selectedToneOfVoice: string;
  @tracked selectedLanguageStyle: string;

  constructor(owner: unknown, args: SettingsArgs) {
    super(owner, args);
    this.selectedToneOfVoice = this.args.aiAgentToneOfVoiceSettings.aiToneOfVoice;
    this.selectedLanguageStyle = this.args.aiAgentToneOfVoiceSettings.aiLanguageStyle;
  }

  get availableTonesOfVoice() {
    return [
      {
        value: 'plainspoken', // renamed matter_of_fact in ML
        text: this.intl.t('ai-agent.settings.tone-of-voice.tones.plainspoken.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.plainspoken.description'),
      },
      {
        value: 'professional',
        text: this.intl.t('ai-agent.settings.tone-of-voice.tones.professional.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.professional.description'),
      },
      {
        value: 'neutral',
        text: this.intl.t('ai-agent.settings.tone-of-voice.tones.neutral.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.neutral.description'),
      },
      {
        value: 'friendly',
        text: this.intl.t('ai-agent.settings.tone-of-voice.tones.friendly.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.friendly.description'),
      },
      {
        value: 'playful', // renamed humorous in ML
        text: this.intl.t('ai-agent.settings.tone-of-voice.tones.playful.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.playful.description'),
      },
    ];
  }

  get availableLanguageStyles() {
    return [
      {
        value: 'let_fin_decide',
        text: this.intl.t('ai-agent.settings.tone-of-voice.language-styles.let-fin-decide.name'),
      },
      {
        value: 'informal',
        text: this.intl.t('ai-agent.settings.tone-of-voice.language-styles.informal.name'),
      },
      {
        value: 'formal',
        text: this.intl.t('ai-agent.settings.tone-of-voice.language-styles.formal.name'),
      },
    ];
  }

  @action
  setToneOfVoice(value: string) {
    this.args.aiAgentToneOfVoiceSettings.aiToneOfVoice = value;
    this.selectedToneOfVoice = value;
  }

  @action
  setLanguageStyle(value: string) {
    this.args.aiAgentToneOfVoiceSettings.aiLanguageStyle = value;
    this.selectedLanguageStyle = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::FinToneOfVoiceSettings': typeof FinToneOfVoiceSettings;
    'ai-agent/fin-tone-of-voice-settings': typeof FinToneOfVoiceSettings;
  }
}
