/* import __COLOCATED_TEMPLATE__ from './learn.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type IntlService from 'ember-intl/services/intl';

interface Args {}

export default class Learn extends Component<Args> {
  @service declare intercomEventService: any;
  @service declare intl: IntlService;

  trackAnalytics(contentType: string, id?: undefined) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'learn_link',
      place: 'outbound_all',
      content_type: contentType,
      link_to: id,
    });
  }

  showGetStartedArticle = () => {
    window.Intercom('showArticle', 8488909); //https://www.intercom.com/help/en/articles/8488909-how-to-set-up-intercom-phone
    this.trackAnalytics('calling_get_started');
  };

  showIVRWorkflowsArticle = () => {
    window.Intercom('showArticle', 8556970); //https://www.intercom.com/help/en/articles/8556970-when-a-customer-calls
    this.trackAnalytics('calling_ivr_workflows');
  };

  showCallingReportingArticle = () => {
    window.Intercom('showArticle', 8488924); //https://www.intercom.com/help/en/articles/8488924-calls-report
    this.trackAnalytics('calling_reporting');
  };

  navigateToCallingCosts = () => {
    safeWindowOpen('https://www.intercom.com/help/en/articles/8488934-phone-pricing', '_blank');
    this.trackAnalytics('calling_costs');
  };

  trackLearningOpened = () => {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'learn_dropdown',
    });
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::Learn': typeof Learn;
    'calling/settings/learn': typeof Learn;
  }
}
