/* import __COLOCATED_TEMPLATE__ from './answer-trend-graph.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import percent from 'embercom/lib/percentage-formatter';
import { inject as service } from '@ember/service';

const statisticColors = {
  answer_sent: 'vis-blue-40',
  all_done: 'vis-green-40',
  abandoned: 'vis-salmon-40',
  wait_for_the_team: 'vis-yellow-30',
};

const valueFromUniqueCount = ({ context, value }) => {
  let newContext = context.map((item) => {
    return {
      key: item.key,
      value: item.value.map((v) => {
        return {
          key: v.key,
          value: v.unique_count.value,
        };
      }),
    };
  });

  return {
    context: newContext,
    value: newContext.reduce((sum, item) => sum + item.count, 0),
  };
};

const sumNestedBucketsToCategories =
  (name, sumKeys) =>
  ({ context, value }) => {
    let newContext = context.map((item) => {
      let count = item.value.reduce((sum, v) => (sumKeys.includes(v.key) ? sum + v.value : sum), 0);
      return {
        key: item.key,
        value: [
          {
            key: name,
            value: count,
            count,
          },
        ],
        count,
      };
    });

    return {
      context: newContext,
      value: newContext.reduce((sum, v) => sum + v.value, 0),
    };
  };

const createPercentageBucketsFromNestedCategories =
  (name, dividendKeys, divisorKeys) =>
  ({ context, value }) => {
    let newContext = context.map((item) => {
      return {
        key: item.key,
        value: [
          {
            key: name,
            value:
              Math.round(
                percent(
                  item.value.reduce(
                    (sum, v) => (divisorKeys.includes(v.key) ? sum + v.value : sum),
                    0,
                  ),
                  item.value.reduce(
                    (sum, v) => (dividendKeys.includes(v.key) ? sum + v.value : sum),
                    0,
                  ),
                ),
              ) || 0,
          },
        ],
      };
    });

    return {
      context: newContext,
      value,
    };
  };

export default class AnswerTrendGraph extends Component {
  @service appService;
  @service intl;

  get query() {
    return {
      name: 'default_query',
      document_type: 'resolution_bot_content_sent',
      range_field: 'first_user_conversation_part_created_at',
      aggregation_type: 'value_count',
      aggregation_field: 'effectiveness',
      nested_keys_are_dates: false,
    };
  }

  get keys() {
    return [this.statName()];
  }

  get tooltips() {
    return { [this.statName()]: this.trendGraphProperties.verb };
  }

  get keyColorHash() {
    return {
      [this.statName()]: statisticColors[this.statName()] || 'vis-blue-40',
    };
  }

  get groupings() {
    return [
      {
        grouping: 'effectiveness',
        interval: 1,
        unique_count: 'conversation_id',
      },
    ];
  }

  get filters() {
    return {
      content_type: 'answer',
      content_id: [this.args.answer.id],
      channel_type: this.args.targetChannelTypes,
    };
  }

  get transforms() {
    let transforms = [valueFromUniqueCount];
    switch (this.args.eventType) {
      case 'answer_sent':
        transforms.push(
          sumNestedBucketsToCategories('answer_sent', [
            'no_action_required',
            'minimal_action_required',
            'action_required',
            'negative_feedback',
          ]),
        );
        break;
      case 'all_done':
        transforms.push(
          createPercentageBucketsFromNestedCategories(
            'all_done',
            ['no_action_required', 'minimal_action_required'],
            [
              'no_action_required',
              'minimal_action_required',
              'action_required',
              'negative_feedback',
            ],
          ),
        );
        break;
      case 'abandoned':
        transforms.push(
          createPercentageBucketsFromNestedCategories(
            'abandoned',
            ['negative_feedback'],
            [
              'no_action_required',
              'minimal_action_required',
              'action_required',
              'negative_feedback',
            ],
          ),
        );
        break;
      case 'wait_for_the_team':
        transforms.push(
          createPercentageBucketsFromNestedCategories(
            'wait_for_the_team',
            ['action_required'],
            [
              'no_action_required',
              'minimal_action_required',
              'action_required',
              'negative_feedback',
            ],
          ),
        );
        break;
    }
    return transforms;
  }

  get trendGraphProperties() {
    switch (this.args.eventType) {
      case 'answer_sent':
        return { unit: '', verb: this.intl.t('custom-answers.answer-trend-graph.verb.sent') };
      case 'all_done':
        return {
          unit: 'percent',
          verb: this.intl.t('custom-answers.answer-trend-graph.verb.resolved'),
        };
      case 'abandoned':
        return {
          unit: 'percent',
          verb: this.intl.t('custom-answers.answer-trend-graph.verb.abandoned'),
        };
      case 'wait_for_the_team':
        return {
          unit: 'percent',
          verb: this.intl.t('custom-answers.answer-trend-graph.verb.routed-to-team'),
        };
    }
  }

  statName() {
    return this.args.eventType;
  }

  wentLiveAt() {
    return this.args.answer.ruleset.rulesetLinks.firstObject?.wentLiveAt;
  }

  startDate() {
    let wentLiveAt = new Date(this.wentLiveAt());
    let twoYearsAgo = moment()
      .subtract(2, 'years')
      .add(1, 'day') // without this query always fails
      .toDate();
    return wentLiveAt > twoYearsAgo ? wentLiveAt : twoYearsAgo;
  }

  timezone() {
    return this.appService.app.timezone;
  }
}
