/* import __COLOCATED_TEMPLATE__ from './add-task-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Checklist from 'embercom/models/checklists/checklist';

interface Args {
  addTask: () => void;
  shouldShowValidations: boolean;
  checklist: Checklist;
}

export default class AddTaskButton extends Component<Args> {
  @service declare appService: any;

  get checklist() {
    return this.args.checklist;
  }

  get showNoTasksValidation() {
    return this.args.shouldShowValidations && this.checklist.tasks.length === 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Editor::Preview::AddTaskButton': typeof AddTaskButton;
    'checklist/editor/preview/add-task-button': typeof AddTaskButton;
  }
}
