/* import __COLOCATED_TEMPLATE__ from './insertion-point.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
  type ElementReference,
  type Tree,
  TreeItem,
  type TreeParent,
} from 'embercom/objects/tree-list';

interface Args {
  parent: TreeParent;
  index: number;
  tree: Tree;
}

export class InsertionPoint implements ElementReference {
  element: HTMLElement;
  tree: Tree;
  parent: TreeParent;
  index: number;

  constructor(inputs: { element: HTMLElement; tree: Tree; parent: TreeParent; index: number }) {
    let { tree, parent, index, element } = inputs;
    this.element = element;
    this.tree = tree;
    this.parent = parent;
    this.index = index;
  }
}

export default class TreeListInsertionPoint extends Component<Args> {
  elementRef?: InsertionPoint = undefined;

  @action insertReference(htmlElement: HTMLElement) {
    this.elementRef = new InsertionPoint({
      element: htmlElement,
      parent: this.args.parent,
      tree: this.args.tree,
      index: 0,
    });
    Object.defineProperty(this.elementRef, 'index', {
      get: () => {
        return this.args.index;
      },
    });
    this.args.tree.insertionPoints.pushObject(this.elementRef);
  }

  @action removeReference() {
    this.elementRef && this.args.tree.insertionPoints.removeObject(this.elementRef);
  }

  get active() {
    let active = this.elementRef === this.args.tree.selectedDroppingPoint?.elementReference;
    return this.elementRef && active;
  }

  get depth() {
    let level = 0;
    if (this.args.parent instanceof TreeItem) {
      let treeItem = this.args.parent;
      level = treeItem.depth + 1;
    }
    return level;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::TreeList::InsertionPoint': typeof TreeListInsertionPoint;
    'common/tree-list/insertion-point': typeof TreeListInsertionPoint;
  }
}
