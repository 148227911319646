/* import __COLOCATED_TEMPLATE__ from './plan.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { not, alias } from '@ember/object/computed';
import {
  MESSAGES_ID,
  ARTICLES_ID,
  OPERATOR_ID,
  ANSWER_BOT_ID,
  PRODUCT_TOURS_ID,
} from 'embercom/lib/billing';

export default Component.extend({
  customerService: service(),
  checked: not('plan.active'),
  purchaseAnalyticsService: service(),
  price: computed('plan', function () {
    return this.customerService.getPriceFromPlanIds([this.plan.idAsNumber]);
  }),
  product: alias('plan.product'),
  activationMetric: computed('product', function () {
    let activationMetrics = new Map([
      [MESSAGES_ID, 'live campaigns'],
      [ARTICLES_ID, 'published articles'],
      [OPERATOR_ID, 'live bots'],
      [ANSWER_BOT_ID, 'live bots'],
      [PRODUCT_TOURS_ID, 'live tours'],
    ]);

    return activationMetrics.get(this.product.id);
  }),

  actions: {
    togglePlan() {
      let action = this.plan.active ? 'remove' : 'keep';
      this.toggleProperty('plan.active');

      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked',
        object: `${action}_${this.product.key}-${this.plan.name}_button`,
        context: 'cancellation_flow',
        place: 'downsize_products_offer',
        section: 'billing_cancel',
      });
    },
  },
});
