/* import __COLOCATED_TEMPLATE__ from './user-delete.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { equal, reads, readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import ajax from 'embercom/lib/ajax';
// eslint-disable-next-line @intercom/intercom/no-legacy-modal
import BulkActionModal from 'embercom/controllers/mixins/users-and-companies/bulk-action-modal';
import { default as inflector, default as pluralise } from 'embercom/lib/inflector';
import numberFormatter from 'embercom/lib/number-formatter';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action } from '@ember/object';

export default Component.extend(BulkActionModal, {
  tagName: '',
  pubsub: service(),
  notificationsService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  init() {
    this._super(...arguments);
    this.reset();
  },

  reset() {
    this.setProperties({
      isDeleting: false,
      hasErrored: false,
    });
  },
  modalTarget: 'user',
  modalTargetPlural: computed('model.hasCount', 'model.count', 'modalTarget', function () {
    let targetCount = this.get('model.hasCount') ? this.get('model.count') : 0;
    return pluralise(this.modalTarget, targetCount);
  }),
  modalTargetPluralForLink: computed('modalTarget', 'modalTargetPlural', function () {
    if (this.modalTarget === 'person') {
      return 'users';
    }
    if (this.modalTarget === 'user') {
      return 'users';
    }
    return this.modalTargetPlural;
  }),
  modalAction: 'delete',
  singleUserSelected: equal('model.selectedIDs.length', 1),
  notificationMessage() {
    let targetCount = this.get('model.hasCount')
      ? this.get('model.count')
      : 'An unknown number of ';
    let notice = `It might take a minute or two for them to disappear from your ${pluralise(
      this.modalTarget,
    )} list.`;
    if (this.get('model.hasCount')) {
      return `You've deleted ${targetCount} ${this.modalTargetPlural}. ${notice}`;
    } else {
      return `${targetCount} ${this.modalTargetPlural} were deleted. ${notice}`;
    }
  },
  deletionNumberFormatter: computed('model.count', function () {
    return numberFormatter(this.get('model.count'));
  }),
  deletingTargetTextWithRemoves: computed('model.count', function () {
    return inflector(
      `a ${this.modalTarget} removes`,
      this.get('model.count'),
      `${this.modalTargetPlural} removes`,
    );
  }),
  doBulkDelete: true,
  endpointForDeletion: ternary('doBulkDelete', 'bulk_delete', 'delete'),
  submitUrl: '/ember/companies/bulk_user_delete.json',
  remoteCompanyId: reads('model.predicates.firstObject.value'),
  deleteParameters: computed('app', 'model', function () {
    return JSON.stringify(
      $.extend(this.model.toApi(), {
        app_id: this.get('app.id'),
        remote_company_id: this.remoteCompanyId,
      }),
    );
  }),

  closeModal: action(function () {
    this.modalService.closeModal();
  }),
  actions: {
    submit() {
      this.set('isDeleting', true);
      this.set('hasErrored', false);
      let self = this;

      ajax({
        url: this.submitUrl,
        type: 'POST',
        data: this.deleteParameters,
      }).then(
        function () {
          self.notificationsService.notifyConfirmation(self.notificationMessage());
          self.send('decrementTargetCount');
          self.get('pubsub').publish('reloadUserOrCompanyList');
          self.reset();
          self.closeModal();
          self.send('deleteComplete');
        },
        function (jqXHR) {
          self.processBulkActionFailure(jqXHR, 'isDeleting');
        },
      );
    },
    deleteComplete() {
      //no-op - allows subclasses to hook in
    },
    decrementTargetCount() {
      let countAttributeLabel = `app.${this.modalTarget}_count`;
      let newTotal = this.get(countAttributeLabel) - this.get('model.count');
      this.set(countAttributeLabel, newTotal);
    },
  },
});
