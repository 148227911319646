/* import __COLOCATED_TEMPLATE__ from './email-subscribe.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-side-effects */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import EmberObject, { computed, action } from '@ember/object';
import { notEmpty, map, alias, not, or, equal } from '@ember/object/computed';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  redirectService: service(),
  needsReauthentication: equal('settings.installationStatus', 'INVALID_AUTH'),
  shouldShowSettings: notEmpty('settings.lists'),

  lists: map('settings.lists', function (item) {
    return { text: item.name, value: item.id };
  }),

  listId: alias('settings.listId'),

  listIdWithDefault: computed('settings.listId', 'settings.lists', function () {
    let listIdWithDefault = this.get('settings.listId') || (this.get('settings.lists')[0] || {}).id;
    this.set('listId', listIdWithDefault);
    return listIdWithDefault;
  }),

  providerName: alias('settings.providerName'),
  title: alias('settings.title'),
  description: alias('settings.description'),
  confirmationTitle: alias('settings.confirmationTitle'),
  isNotDirty: not('settings.hasDirtyAttributes'),

  textFieldIncomplete: computed(
    'rewardText',
    'rewardType',
    'confirmationDescription',
    'title',
    'confirmationTitle',
    function () {
      if (!this.title || !this.confirmationTitle) {
        return true;
      }
      if (this.rewardType === 'none') {
        return false;
      }
      if (this.rewardType === 'download') {
        return !this.rewardText || !this.confirmationDescription;
      }
      return !this.rewardText;
    },
  ),

  disableSubmit: or('isNotDirty', 'update.isRunning', 'textFieldIncomplete'),
  rewardType: alias('settings.rewardType'),
  rewardText: alias('settings.rewardText'),
  confirmationDescription: alias('settings.confirmationDescription'),

  rewardOptions: [
    EmberObject.create({
      key: 'none',
      title: 'None',
    }),
    EmberObject.create({
      key: 'discount',
      title: 'Discount code',
      placeholder: 'Add your discount code here',
    }),
    EmberObject.create({
      key: 'download',
      title: 'Download link',
      placeholder: 'Add a title',
      secondPlaceholder: 'http://exampleurl.com',
    }),
    EmberObject.create({
      key: 'text',
      title: 'Text',
      placeholder: 'Add body text to your confirmation screen',
    }),
  ],

  changeListId: action(function (listId) {
    this.set('listId', listId);
  }),

  reauthenticate: action(function () {
    this.redirectService.redirect(this.appPackage.installUrl);
  }),

  actions: {
    clickedRadio(rewardType) {
      if (rewardType !== this.rewardType) {
        this.setProperties({
          rewardType,
          rewardText: null,
          confirmationDescription: null,
        });
      }
    },
  },
});
