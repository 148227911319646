/* import __COLOCATED_TEMPLATE__ from './input-with-attribute-inserter.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { action } from '@ember/object';

export default class InputWithAttributeInserter extends Component {
  blocksDoc;

  constructor() {
    super(...arguments);
    this.composerConfig = { ...this.args.composerConfig }; // copy the composer config before we modify the placeholder
    this.composerConfig.placeholder = this.args.placeholder;
    this.blocksDoc = new BlocksDocument(this.args.blocks);
  }

  @action
  onComposerChange(newBlocksDoc) {
    this.args.onChange(newBlocksDoc.blocks);
  }
}
