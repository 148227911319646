/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed, action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend({
  notificationsService: service(),
  appService: service(),
  redirectService: service(),
  regionService: service(),
  adminId: readOnly('appService.app.currentAdmin.id'),
  appId: readOnly('appService.app.id'),

  redirectUrl: computed('adminId', 'appId', 'regionService.messengerAppsBaseURL', function () {
    return `${this.regionService.messengerAppsBaseURL}/google_meet/google_oauth/auth?app_id_code=${this.appId}&admin_id=${this.adminId}`;
  }),

  connectCalendar: task(function* () {
    try {
      let signedRedirectUrl = yield this.generateRedirectUrl.perform(this.redirectUrl, true);
      this.redirectService.redirect(signedRedirectUrl);
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
  }).drop(),

  revokeAccess: action(function () {
    this.set('settings.hasIntegratedCalendar', false);
    this.update.perform(this.settings);
  }),

  redirectToGoogleAuth: action(function () {
    this.connectCalendar.perform();
  }),
});
