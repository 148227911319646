/* import __COLOCATED_TEMPLATE__ from './usage-link.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {};
}

const UsageLink = templateOnlyComponent<Signature>();
export default UsageLink;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::UsageLink': typeof UsageLink;
  }
}
