/* import __COLOCATED_TEMPLATE__ from './need-something-else-section.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { startSurvey } from 'embercom/lib/intercom-widget-helper';
import { SELF_SERVE_CHURN_SURVEY_ID } from 'embercom/lib/billing/surveys';

export default class NeedSomethingElseSection extends Component {
  @service declare purchaseAnalyticsService: any;
  @service appService: any;
  @service router: any;
  @service pauseSubscriptionModalService: any;

  get app() {
    return this.appService.app;
  }

  get billingCancelRoute() {
    return 'apps.app.billing.cancel';
  }

  get isPauseSubscriptionModalOpen() {
    return this.pauseSubscriptionModalService.isOpen;
  }

  get isPauseSubscriptionEnabled() {
    return this.app.canPauseEarlyStageSubscription;
  }

  @action
  closePauseSubscriptionModal() {
    this.pauseSubscriptionModalService.close();
  }

  @action
  trackClickEvent(objectName: string) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'need_something_else_section',
      place: 'update_subscription',
      object: objectName,
    });
  }

  @action
  cancelSubscription() {
    this.trackClickEvent('cancel-subscription');
    if (this.pauseSubscriptionModalService.canPauseEarlyStageSubscription) {
      this.pauseSubscriptionModalService.open();
    } else if (this.app.canSeeSelfServeChurnSurvey) {
      // The last step of the new self serve churn Survey will
      // redirect customers to their cancellation page.
      startSurvey(SELF_SERVE_CHURN_SURVEY_ID);
    } else {
      this.router.transitionTo('apps.app.billing.cancel'); // Fallback for AU / EU workspaces
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::UpdateEarlyStageSubscription::NeedSomethingElseSection': typeof NeedSomethingElseSection;
    'billing/update-early-stage-subscription/need-something-else-section': typeof NeedSomethingElseSection;
  }
}
