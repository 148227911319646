/* import __COLOCATED_TEMPLATE__ from './team-impact.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { formatSeconds } from 'embercom/lib/duration-formatter';

const INDUSTRY_ONE_TOUCH_RESOLUTION_SECONDS = 720;

export default class TeamImpact extends Component {
  get timeSaved() {
    let conversationSource = this.args.conversationSource;
    if (conversationSource === 'inbound') {
      return this.inboundTimeSaved;
    } else if (conversationSource === 'outbound') {
      return this.outboundTimeSaved;
    }
    return this.totalTimeSaved;
  }

  get inboundCloseTime() {
    return this.args.signals?.inboundMedianClosedTimeLastWeek?.originalValue;
  }

  get outboundCloseTime() {
    return this.args.signals?.outboundMedianClosedTimeLastWeek?.originalValue;
  }

  get totalTimeSaved() {
    return this.inboundTimeSaved + this.outboundTimeSaved;
  }

  get inboundTimeSaved() {
    return isNaN(this.inboundCloseTime)
      ? 0
      : this.inboundCloseTime * this.args.effectiveInboundConversationWithAnswerCount;
  }

  get outboundTimeSaved() {
    return isNaN(this.outboundCloseTime)
      ? 0
      : this.outboundCloseTime * this.args.effectiveOutboundConversationWithAnswerCount;
  }

  get customerHoursSavedDescription() {
    let conversationSource = this.args.conversationSource;
    let description = 'Based on your team’s current median time to close of ';
    if (conversationSource === 'inbound') {
      description += formatSeconds(this.inboundCloseTime, {
        format: 'single-unit-hours',
      });
      description += ' for inbound conversations';
    } else if (conversationSource === 'outbound') {
      description += formatSeconds(this.outboundCloseTime, {
        format: 'single-unit-hours',
      });
      description += ' for outbound conversations';
    } else {
      description += formatSeconds(this.inboundCloseTime, {
        format: 'single-unit-hours',
      });
      description += ' for inbound conversations and ';
      description += formatSeconds(this.outboundCloseTime, {
        format: 'single-unit-hours',
      });
      description += ' for outbound conversations';
    }
    return description;
  }

  get augmentsTeam() {
    return INDUSTRY_ONE_TOUCH_RESOLUTION_SECONDS * this.args.effectiveConversationWithAnswerCount;
  }
}
