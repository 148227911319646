/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import EmberObject, { action, computed } from '@ember/object';
import { and, equal, readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import {
  fetchSyncingState,
  enableSyncing,
  cancelSyncing,
} from 'embercom/lib/appstore/unsubscribe-app';
import { TEAMMATE_APP_URL } from 'embercom/lib/teammate-app';

export default Component.extend({
  notificationsService: service(),
  syncingState: null,
  clientId: null,
  clientSecret: null,
  instanceId: null,
  hasAPIDetails: and('clientId', 'clientSecret', 'instanceId'),
  isMarketoUnsubscribeApp: equal('syncingState.integrate_with', 'marketo'),
  verified: readOnly('syncingState.verified'),
  teammateAppUrl: TEAMMATE_APP_URL,
  webhook_secret: readOnly('syncingState.webhook_secret'),
  unsubscribeUrl: computed('teammateAppUrl', function () {
    return `${this.teammateAppUrl}/external_unsubscribe`;
  }),
  webhookTemplate: computed('syncingState.webhook_secret', 'app.id', function () {
    return `type=unsubscribe&secret=${this.syncingState.webhook_secret}&app_id=${this.app.id}&data[email]={{lead.Email Address:default=null}}`;
  }),

  init() {
    this._super(...arguments);
    this.fetchSyncingState.perform();
    this.updateAPIDetails();

    this.registerHooks({ afterUninstall: this.cancelSyncing });
  },

  updateAPIDetails() {
    let accessToken = this.get('syncingState.access_token');

    if (accessToken) {
      let accessTokenComponents = accessToken.split(':');
      if (accessTokenComponents.length === 3) {
        this.setProperties({
          clientId: accessTokenComponents[0],
          clientSecret: accessTokenComponents[1],
          instanceId: accessTokenComponents[2],
        });
      }
    }
  },

  fetchSyncingState: task(function* () {
    let syncingState = yield fetchSyncingState(this.get('app.id'));
    this.set('syncingState', syncingState);
    this.updateAPIDetails();
  }),

  enableSyncing: task(function* () {
    if (!this.hasAPIDetails) {
      return;
    }

    try {
      let accessToken = this.get('syncingState.access_token');
      let response = yield enableSyncing(this.get('app.id'), accessToken, 'marketo');
      this.setProperties({
        syncingState: response,
        isEnabled: true,
      });
    } catch (err) {
      this.notificationsService.notifyError(err.jqXHR.responseJSON.errors);
    }
  }),

  cancelSyncing: task(function* () {
    yield cancelSyncing(this.get('app.id'));

    this.setProperties({
      'syncingState.access_token': '',
      'syncingState.verified': false,
      'syncingState.enabled': false,
      'syncingState.integrate_with': '',
    });
    this.notificationsService.notifyConfirmation('Success. Marketo syncing was disabled.');
  }),

  submitTokens: action(function () {
    let token = `${this.clientId}:${this.clientSecret}:${this.instanceId}`;
    if (!this.syncingState) {
      this.set('syncingState', EmberObject.create());
    }
    this.set('syncingState.access_token', token);
    this.enableSyncing.perform();
  }),
});
