/* import __COLOCATED_TEMPLATE__ from './tree-item.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
  DropActionType,
  type TreeParent,
  type ElementReference,
  type Tree,
  type TreeItem,
} from 'embercom/objects/tree-list';

interface Args {
  item: TreeItem;
  tree: Tree;
}

export class ItemPoint implements ElementReference {
  element: HTMLElement;
  tree: Tree;
  parent: TreeParent;
  item: TreeItem;

  constructor(inputs: { element: HTMLElement; tree: Tree; parent: TreeParent; item: TreeItem }) {
    let { tree, parent, item, element } = inputs;
    this.element = element;
    this.tree = tree;
    this.parent = parent;
    this.item = item;
  }
}

export default class TreeListItem extends Component<Args> {
  elementRef?: ItemPoint = undefined;

  @action
  toggleExpand(item: TreeItem) {
    item.isExpanded = !item.isExpanded;
  }

  @action
  onDragStart(event: DragEvent) {
    if (this.args.tree.hooks.canDragItem(this.args.item)) {
      this.args.tree.draggingItem = this.args.item;
      if (this.args.tree.settings.dragProperties.shortDraggingImage && this.elementRef) {
        event.dataTransfer?.setDragImage(this.elementRef.element, event.offsetX, event.offsetY);
      }
      event.stopPropagation();
    } else {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  @action onDragEnd() {
    this.args.tree.draggingItem = undefined;
    this.args.tree.selectedDroppingPoint = undefined;
  }

  @action insertReference(htmlElement: HTMLElement) {
    this.elementRef = {
      element: htmlElement,
      item: this.args.item,
      tree: this.args.tree,
      parent: this.args.item.parent,
    };
    this.args.tree.itemPoints.pushObject(this.elementRef);
  }

  @action removeReference() {
    this.elementRef && this.args.tree.insertionPoints.removeObject(this.elementRef);
  }

  get active() {
    let active =
      this.elementRef === this.args.tree.selectedDroppingPoint?.elementReference ||
      this.args.item ===
        (this.args.tree.selectedDroppingPoint?.elementReference as ItemPoint)?.item;
    return this.elementRef && active;
  }

  get isBeingDragged() {
    return this.args.tree.draggingItem === this.args.item;
  }

  get insertionPointIsActive() {
    if (this.args.tree.settings.insertionPoint.highlightParent) {
      // we want to highlight the parent of the insertion point and the folder
      return (
        (this.args.tree.selectedDroppingPoint?.dropActionType === DropActionType.nextToItem &&
          (this.args.tree.selectedDroppingPoint.parent as TreeItem) === this.args.item) ||
        (this.args.tree.selectedDroppingPoint?.dropActionType === DropActionType.insideItem &&
          (this.args.tree.selectedDroppingPoint.parent as TreeItem).parent === this.args.item)
      );
    }
    return false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::TreeList::TreeItem': typeof TreeListItem;
    'common/tree-list/tree-item': typeof TreeListItem;
  }
}
