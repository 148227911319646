/* import __COLOCATED_TEMPLATE__ from './feature-link.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import ajax from 'embercom/lib/ajax';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class BillingFeaturesFeatureLinkComponent extends Component {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @tracked showSideDrawer = false;

  allFeatures: Array<any> = [];
  availableFeaturesForApp: Array<any> = [];

  constructor(owner: unknown, args: any) {
    super(owner, args);

    Promise.all([this.fetchAllFeatures(), this.fetchAvailableFeaturesForApp()]);
  }

  async fetchAllFeatures() {
    try {
      let response = await ajax({
        url: `/ember/billing_features`,
        type: 'GET',
      });

      this.allFeatures = response;
    } catch (e) {
      console.error(e);
    }
  }

  async fetchAvailableFeaturesForApp() {
    try {
      let response = await ajax({
        url: `/ember/billing_features/available_billing_features`,
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
        },
      });

      this.availableFeaturesForApp = response;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  clickFeatureLink() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'open_features_ui',
      context: 'feature_link',
      place: 'billing_summary',
    });
    this.showSideDrawer = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Features::FeatureLink': typeof BillingFeaturesFeatureLinkComponent;
  }
}
