/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly, empty } from '@ember/object/computed';
import { A } from '@ember/array';
import Component from '@ember/component';
import FacebookIntegration from 'embercom/lib/facebook';
import { task } from 'ember-concurrency';
import initFacebookSdk from 'embercom/lib/facebook-sdk';
import { inject as service } from '@ember/service';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';

export default Component.extend({
  intl: service(),
  intercomEventService: service(),
  notificationsService: service(),
  appstoreService: service(),
  fb: service(),
  pagesData: A(),
  pages: readOnly('pagesData'),
  nextCursor: '',
  isFirefox: UserAgentDetector.isFirefox(),
  isConnectedToFacebook: false,
  pagesEmpty: empty('pagesData'),
  appService: service(),
  loginScope:
    'email,pages_manage_metadata,pages_read_engagement,pages_read_user_content,pages_messaging,pages_show_list,business_management',

  init() {
    this._super(...arguments);
    let app = this.appService.app;
    initFacebookSdk(this.fb, app);
    this.initializeProperties();
    this.retrievePagesTask.perform();
    this.registerHooks({ afterUninstall: this.cleanupPostUninstall });
  },

  initializeProperties() {
    this.setProperties({
      isConnectedToFacebook: false,
      pagesData: A(),
      nextCursor: '',
    });
  },

  cleanupPostUninstall: task(function* () {
    try {
      yield FacebookIntegration.unsubscribeAll(this.get('app.id'));
      this.initializeProperties();
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('appstore.settings.facebook.main.notification.uninstall-error'),
      );
    }
  }).drop(),

  retrievePagesTask: task(function* () {
    let resp = yield this.fb.getLoginStatus();
    if (resp.status === 'connected') {
      let accessToken = resp.authResponse.accessToken;
      let response = yield FacebookIntegration.fetchPages(accessToken, this.nextCursor);
      this.pagesData.pushObjects(response.get('pages').toArray());
      this.set('nextCursor', response.get('nextCursor'));
      this.set('isConnectedToFacebook', true);
    }
  }).drop(),

  login: task(function* () {
    this._trackConnectionEvents();
    let response = yield this.fb.login(this.loginScope);
    yield this.connectWithFacebook.perform(response);
  }).drop(),

  reAuth: task(function* () {
    this._trackConnectionEvents();
    let response = yield this.fb.login(this.loginScope, { auth_type: 'rerequest' });
    yield this.connectWithFacebook.perform(response);
  }).drop(),

  connectWithFacebook: task(function* (response) {
    if (response.status === 'connected') {
      this.set('pagesData', A());
      yield this.retrievePagesTask.perform();
    }
  }).drop(),

  subscribe: task(function* (page) {
    let appId = this.get('app.id');
    let accessToken = page.get('accessToken');
    let pageId = page.get('id');
    try {
      let response = yield FacebookIntegration.subscribe(appId, accessToken, pageId);
      if (response.success === true) {
        page.set('added', true);
        page.set('status', 'OK');
        this.notificationsService.notifyConfirmation(
          this.intl.t('appstore.settings.facebook.main.notification.page-add.success'),
        );
        this.intercomEventService.trackAnalyticsEvent({
          action: 'added',
          object: 'facebook-page',
        });
      } else {
        this.notificationsService.notifyError(
          this.intl.t('appstore.settings.facebook.main.notification.page-add.error'),
        );
      }
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('appstore.settings.facebook.main.notification.page-add.error-permissions'),
      );
    }
  }).drop(),

  unsubscribe: task(function* (page) {
    let appId = this.get('app.id');
    let accessToken = page.get('accessToken');
    let pageId = page.get('id');
    try {
      let response = yield FacebookIntegration.unsubscribe(appId, accessToken, pageId);
      if (response.success === true) {
        page.set('added', false);
        this.intercomEventService.trackAnalyticsEvent({
          action: 'removed',
          object: 'facebook-page',
        });
        this.notificationsService.notifyConfirmation(
          this.intl.t('appstore.settings.facebook.main.notification.page-remove.success'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('appstore.settings.facebook.main.notification.page-remove.error'),
        );
      }
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('appstore.settings.facebook.main.notification.page-remove.error'),
      );
    }
  }).drop(),

  _trackConnectionEvents() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'connect',
      object: 'facebook',
      app_package_code: 'facebook',
    });
    let { email_request } = this.appstoreService.getRedirectQueryParams();
    if (email_request) {
      let emailRequestAction = `${email_request}_connect`;
      this.intercomEventService.trackAnalyticsEvent({
        action: emailRequestAction,
        object: 'facebook',
        app_package_code: 'facebook',
      });
    }
  },
});
