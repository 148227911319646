/* import __COLOCATED_TEMPLATE__ from './tickets-portal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import StandardAudienceEditorConfiguration from 'embercom/objects/audience-editor/standard-audience-editor-configuration';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import { captureException } from 'embercom/lib/sentry';
import { action } from '@ember/object';

interface Args {
  site: HelpCenterSite;
}

enum CookieForwardingTestStatus {
  Success = 'Success', // eslint-disable-line @intercom/intercom/no-bare-strings
  Failure = 'Failure', // eslint-disable-line @intercom/intercom/no-bare-strings
  Unknown = 'Unknown', // eslint-disable-line @intercom/intercom/no-bare-strings
}

type CookieForwardingTestResult = {
  status: CookieForwardingTestStatus;
};

export default class TicketsPortal extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;

  @tracked cookieForwardingTestFailed = false;
  @tracked cookieForwardingTestUnknown = false;

  constructor(owner: any, args: Args) {
    super(owner, args);

    if (this.args.site.ticketsPortalTurnedOn) {
      taskFor(this.runCookieForwardingTest).perform();
    }
  }

  get audienceEditorConfiguration() {
    return new StandardAudienceEditorConfiguration({
      showClientPredicates: false,
      allowedUserRoles: ['user'],
      useRolePredicateGroup: true,
      attributePickerLabel: this.intl.t(
        'articles.settings.redesign.general.tickets-portal.access-conditions-picker',
      ),
      audiencePreviewHideDisplayableAttributes: true,
      audiencePreviewHeadingTranslationKey:
        'articles.settings.redesign.general.tickets-portal.preview-audience-heading',
    });
  }

  @task({ restartable: true })
  *runCookieForwardingTest() {
    try {
      let response: CookieForwardingTestResult = yield ajax({
        url: '/ember/help_center_sites/cookie_forwarding_test',
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
          help_center_site_id: this.args.site.id,
        },
      });

      this.cookieForwardingTestFailed = response.status === CookieForwardingTestStatus.Failure;
      this.cookieForwardingTestUnknown = response.status === CookieForwardingTestStatus.Unknown;
    } catch (e) {
      // We are unsure of the result, so it's better to fail silently and not showing anything
      captureException(e);
    }
  }

  @action
  toggleTicketsPortal() {
    this.args.site.ticketsPortalTurnedOn = !this.args.site.ticketsPortalTurnedOn;
    this.args.site.customizationOptions.layout.homePage.refreshTicketsPortalSection(this.args.site);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::General::TicketsPortal': typeof TicketsPortal;
    'articles/site/settings/general/tickets-portal': typeof TicketsPortal;
  }
}
