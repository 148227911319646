/* import __COLOCATED_TEMPLATE__ from './payment-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class PaymentModal extends Component {
  @service intl;
  @service paymentService;
  @service notificationsService;

  @tracked displayModalContent = false;
  @tracked step = 1;

  @action
  modalDidOpen() {
    let { paymentService, ZPaymentLoaded, paymentAttempted } = this;
    let { paymentPayload, permitPayment, retrieveInvoices } = this.args;

    paymentService.renderWithErrorHandler(
      paymentPayload,
      {},
      {
        ZPaymentLoaded,
        permitPayment,
        retrieveInvoices,
        paymentAttempted,
      },
    );
  }

  @action
  ZPaymentLoaded() {
    let { permitPayment } = this.args;

    this.displayModalContent = true;
    permitPayment();
  }

  @action
  async enterCardDetails() {
    let { appId } = this.args;
    let paymentrun_ongoing_response = await ajax({
      url: '/ember/zuora_account/paymentrun_ongoing',
      type: 'GET',
      data: { app_id: appId },
    });

    if (paymentrun_ongoing_response['is_paymentrun_ongoing']) {
      this.notificationsService.notifyError(
        this.intl.t('billing.invoices.payment-modal.payment-blocked-error'),
        20 * 1000,
      );
      return;
    }
    this.step = 2;
  }

  @action
  paymentAttempted(success) {
    this.step = success ? 3 : 1;
  }

  @action
  submit() {
    let { paymentService } = this;

    paymentService.submit();
  }
}
