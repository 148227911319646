/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { lastScreenOptions } from 'embercom/models/data/carousel/action-constants';

export default class ScreenAction extends Component {
  @service intercomEventService;
  @service contentEditorService;
  @service appService;

  app = this.appService.app;

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get carouselActions() {
    return this.args.screen.sortedCarouselActions;
  }

  @action onTitleChange(carouselAction, e) {
    carouselAction.set('title', e.target.value);
  }

  @action clickOnInputBox(carouselAction) {
    let analyticsAction;
    let object;
    if (lastScreenOptions.map((lso) => lso.value).include(carouselAction.type)) {
      analyticsAction = 'mobile_carousel_cta_button_action_clicked';
      object = `carousel_btn_action_${carouselAction.type}`;
    } else {
      if (carouselAction.type === 'skip' || carouselAction.type === 'continue') {
        object = `carousel_${carouselAction.type}_button`;
      } else {
        object = `carousel_cta`;
      }
      analyticsAction = 'mobile_carousel_button_contextual_clicked';
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: analyticsAction,
      object,
      owner: 'team-messenger',
      place: 'carousel_editor_screen',
      metadata: { ruleset_id: this.ruleset.id },
    });
  }
}
