/* import __COLOCATED_TEMPLATE__ from './configuration-pane.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { action } from '@ember/object';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { type UploadHelper } from 'embercom/objects/media/media-helper';
import { type HelpCenterSettingNavigationHelper, type TlsSelectionHelper } from './main';
import type Admin from 'embercom/models/admin';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { next } from '@ember/runloop';
import { type TabId } from 'embercom/services/help-center-setting-navigation-service';

interface Args {
  app: any;
  admin: Admin;
  customDomain: any;
  isManagedCustomDomainEnabled: boolean;
  navigationHelper: HelpCenterSettingNavigationHelper;
  uploadHelper: UploadHelper;
  site: HelpCenterSite;
  tlsSelectionHelper: TlsSelectionHelper;
  setPreviewType: (previewType: string) => void;
  newSettings?: boolean;
}

export default class ConfigurationPane extends Component<Args> {
  @ref('container') container!: HTMLElement;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    next(() => {
      this.args.navigationHelper.updateContainer(this.container);
    });
  }

  @action
  selectedTab(tabId: TabId): void {
    this.args.navigationHelper.navigateTab(tabId);
  }

  @action
  handleScroll(e: HTMLElement) {
    this.args.navigationHelper.updateLastScrollPosition(e.scrollTop);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::ConfigurationPane': typeof ConfigurationPane;
    'articles/site/settings/configuration-pane': typeof ConfigurationPane;
  }
}
