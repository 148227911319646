/* import __COLOCATED_TEMPLATE__ from './additional-usage.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    isSalesforceContracted: boolean;
  };
}

const AdditionalUsage = templateOnlyComponent<Signature>();
export default AdditionalUsage;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::Tooltips::AdditionalUsage': typeof AdditionalUsage;
  }
}
