/* import __COLOCATED_TEMPLATE__ from './messenger-canvas.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',

  store: service(),

  init() {
    this._super(...arguments);
    let previewConfiguration = this.store.createFragment('messenger-preview/configuration', {
      appPrimaryColor: '#334BFA',
      appSecondaryColor: '#546279',
    });
    this.set('previewConfiguration', previewConfiguration);
  },
});
