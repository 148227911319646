/* import __COLOCATED_TEMPLATE__ from './apply-for-early-stage-button.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import {
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  percentDiscountForSolution,
} from 'embercom/lib/billing';
import type RouterService from '@ember/routing/router-service';

export default class ApplyForEarlyStageButton extends Component {
  @service declare router: RouterService;
  @service declare purchaseAnalyticsService: any;
  @service declare appService: any;

  get earlyStageDiscount() {
    return percentDiscountForSolution(PRICING_5_X_EARLY_STAGE_SOLUTION_ID);
  }

  @action
  transitionToEarlyStageApplication() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'cardless_trial',
      place: 'cardless_trial_banner',
      object: 'apply_for_early_stage_button',
    });

    this.router.transitionTo(
      'apps.app.teams-checkout.early-stage-application',
      this.appService.app.id,
      {
        queryParams: {
          solution_id: PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
        },
      },
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Banners::CardlessTrial::ApplyForEarlyStageButton': typeof ApplyForEarlyStageButton;
    'banners/cardless-trial/apply-for-early-stage-button': typeof ApplyForEarlyStageButton;
  }
}
