/* import __COLOCATED_TEMPLATE__ from './feature-comparison-column-header.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  isRecommended: boolean;
  title: string;
}

interface Signature {
  Args: Args;
}

const FeatureComparisonColumnHeader = templateOnlyComponent<Signature>();
export default FeatureComparisonColumnHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::FeatureComparisonColumnHeader': typeof FeatureComparisonColumnHeader;
    'billing/stripe-migration/feature-comparison-column-header': typeof FeatureComparisonColumnHeader;
  }
}
