/* import __COLOCATED_TEMPLATE__ from './task-body.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { action } from '@ember/object';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import type Task from 'embercom/models/checklists/task';
import type IntlService from 'embercom/services/intl';
import { type Block } from '@intercom/interblocks.ts/dist/types/ast';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import type Store from '@ember-data/store';

interface Args {
  task: Task;
  isEditing: boolean;
  shouldShowValidations: boolean;
}

class TaskBodyConfig extends BaseConfig {
  autoFocus = false;
  textDirection: NotificationDirection = 'ltr';
  allowedBlocks = [
    'paragraph',
    'image',
    'orderedNestedList',
    'unorderedNestedList',
    'video',
    'videoFile',
  ];
  singleBlockMode = false;
  allowedInline = ['bold', 'italic', 'anchor', 'code'];
  allowTextAlignment = false;
  tools = [
    { name: 'template-inserter' },
    { name: 'fallback-editor' },
    { name: 'text-formatter' },
    { name: 'anchor-editor' },
    { name: 'media-inserter' },
    { name: 'video-editor' },
  ];
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    allowedVideoFileTypes: ['video/mp4'],
    allowedAttachmentTypes: [],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
  };
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  attributes: any;
  templating: { picker: string; resolver: AttributeInfoResolver };

  constructor(app: any, placeholder: string) {
    super();

    this.attributes = app.allowedAttributes;
    this.placeholder = placeholder;
    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;

    this.templating = {
      picker: 'common/attribute-picker',
      resolver: new AttributeInfoResolver({
        attributes: this.attributes,
        includeAppAttributes: false,
      }),
    };
  }
}

export default class TaskBody extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @tracked bodyBlocksDoc: BlocksDocument;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.bodyBlocksDoc = new BlocksDocument(this.task.bodyBlocks.toArray());
  }

  get app() {
    return this.appService.app;
  }

  get task() {
    return this.args.task;
  }

  get bodyComposerConfig() {
    let placeholder = this.intl.t('outbound.checklists.task-settings.task-body-placeholder');
    return new TaskBodyConfig(this.app, placeholder);
  }

  get showNoDescriptionValidation() {
    return this.args.shouldShowValidations && !this.task.hasBody;
  }

  get showEditableButtonValidation() {
    return (
      this.args.shouldShowValidations &&
      this.task.hasButtonAction &&
      (!this.task.customActionButtonText || this.task.customActionButtonText.length === 0)
    );
  }

  @action updateBodyBlocks(blocksDoc: BlocksDocument) {
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.task.set('bodyBlocks', blockFragments);
    this.task.notifyPropertyChange('bodyBlocks');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Editor::Preview::TaskList::TaskBody': typeof TaskBody;
    'checklist/editor/preview/task-list/task-body': typeof TaskBody;
  }
}
