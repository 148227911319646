/* import __COLOCATED_TEMPLATE__ from './grid-stack.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLElement;
  Args: any;
  Blocks: any;
}

const GridStack = templateOnlyComponent<Signature>();
export default GridStack;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::GridStack': typeof GridStack;
    'common/grid-stack': typeof GridStack;
  }
}
