/* import __COLOCATED_TEMPLATE__ from './services-plan-breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';
import type IntlService from 'embercom/services/intl';

interface Args {
  pricingMetrics: PricingMetric[];
  shouldShowPrices: boolean;
  testIdentifier: string;
}

export default class ServicesPlanBreakdown extends Component<Args> {
  @service declare intl: IntlService;

  get price() {
    let priceInCents = this.args.pricingMetrics
      .map((pricingMetric) => pricingMetric.price)
      .reduce((a: number, b: number) => a + b, 0);
    return priceInCents / 100;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::ServicesPlanBreakdown': typeof ServicesPlanBreakdown;
  }
}
