/* import __COLOCATED_TEMPLATE__ from './business-account.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class BusinessAccount extends Component {
  @service store;
  @service appService;
  @service notificationsService;

  get whatsappIntegrations() {
    return this.args.whatsappIntegrations;
  }

  get allBusinessesRequestedVerification() {
    return this.whatsappIntegrations.every(
      (whatsappIntegration) => whatsappIntegration.isBusinessVerificationRequested,
    );
  }

  get allBusinessesVerified() {
    return this.whatsappIntegrations.every(
      (whatsappIntegration) => whatsappIntegration.isBusinessVerified,
    );
  }

  get whatsappBusinesses() {
    let businesses = {};
    this.whatsappIntegrations.forEach((whatsappIntegration) => {
      businesses[whatsappIntegration.businessId] = {
        businessId: whatsappIntegration.businessId,
        businessName: whatsappIntegration.businessName,
        businessVerificationStatus: whatsappIntegration.businessVerificationStatus,
        businessStampClass: whatsappIntegration.businessStampClass,
        businessStatusLabel: whatsappIntegration.businessStatusLabel,
      };
    });
    return Object.values(businesses);
  }

  get businessStampClass() {
    return this.getStampClass(this.whatsappIntegration.businessVerificationStatus);
  }

  get businessStatusLabel() {
    return this.getStatusLabel(this.whatsappIntegration.businessVerificationStatus);
  }

  @action
  openBusinessVerification(businessId) {
    let url = `https://business.facebook.com/settings/security/business_verification?business_id=${businessId}`;
    safeWindowOpen(url, '_blank');
  }
}
