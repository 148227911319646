/* import __COLOCATED_TEMPLATE__ from './price-usage-breakdown-plan-group-header.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';
import { PLAN_DATA } from 'embercom/lib/billing';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export enum PlanGroupId {
  support = 'support',
  engage = 'engage',
  marketing = 'marketing',
  additionalServices = 'additional-services',
  peopleReachedAndAddons = 'people-reached-and-addons',
  pricing5_coreEssential = 'pricing5-core-essential',
  pricing5_coreAdvanced = 'pricing5-core-advanced',
  pricing5_coreExpert = 'pricing5-core-expert',
  addons = 'addons',
}

interface Args {
  planGroupId: PlanGroupId;
  planId?: string; // For Pricing 5 core plans the group is just one plan. We need to show the plan name in this case, so we need the planId
  shouldHidePrices: boolean;
  onPricing5_XPlan: boolean;
}

const pricing5CoreIcons: Partial<{ [key in PlanGroupId]: InterfaceIconName }> = {
  [PlanGroupId.pricing5_coreEssential]: 'stars',
  [PlanGroupId.pricing5_coreAdvanced]: 'rocket-ship',
  [PlanGroupId.pricing5_coreExpert]: 'trigger',
};

export default class PriceUsageBreakdownPlanGroupHeader extends Component<Args> {
  @service customerService: any;
  @service intl!: IntlService;

  get icon(): InterfaceIconName | string | undefined {
    if (this.isPricing5Core) {
      return pricing5CoreIcons[this.args.planGroupId];
    } else if (
      [PlanGroupId.support, PlanGroupId.engage, PlanGroupId.marketing].includes(
        this.args.planGroupId,
      )
    ) {
      return `upgrade/${this.args.planGroupId}`;
    }
    return;
  }

  get interfaceIcon(): InterfaceIconName | undefined {
    if (this.isPricing5Core) {
      return pricing5CoreIcons[this.args.planGroupId];
    }
    return;
  }

  get title() {
    if (this.isPricing5Core) {
      return PLAN_DATA[this.args.planId!].name;
    }
    if (this.args.onPricing5_XPlan) {
      return this.intl.t(
        `billing.summary.price-usage-breakdown-card.plan-groups.pricing-5.${this.args.planGroupId}`,
      );
    }

    return this.intl.t(
      `billing.summary.price-usage-breakdown-card.plan-groups.${this.args.planGroupId}`,
    );
  }

  get isPricing5Core() {
    return [
      PlanGroupId.pricing5_coreEssential,
      PlanGroupId.pricing5_coreAdvanced,
      PlanGroupId.pricing5_coreExpert,
    ].includes(this.args.planGroupId);
  }

  get showTitleOnly() {
    return [PlanGroupId.additionalServices, PlanGroupId.addons].includes(this.args.planGroupId);
  }

  get isAddonGroup() {
    return PlanGroupId.addons === this.args.planGroupId;
  }

  get isHeaderDisplayed() {
    return !(this.args.onPricing5_XPlan && this.isAddonGroup);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::PriceUsageBreakdownPlanGroupHeader': typeof PriceUsageBreakdownPlanGroupHeader;
    'billing/summary/price-usage-breakdown-plan-group-header': typeof PriceUsageBreakdownPlanGroupHeader;
  }
}
