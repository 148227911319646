/* import __COLOCATED_TEMPLATE__ from './credit-card-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import ENV from 'embercom/config/environment';
import { addressValidation } from 'embercom/lib/address-validation';
import AddressParams from 'embercom/lib/billing/address-params';
import { post } from 'embercom/lib/ajax';

export default class CreditCardModal extends Component {
  @service appService;
  @service customerService;
  @service stripev3;
  @service paymentIntentService;
  @service notificationsService;
  @service purchaseAnalyticsService;
  @service store;
  @service intl;
  @tracked address = new AddressParams();
  @tracked showAllErrors = false;
  @tracked setupIntent = null;
  @tracked elements = null;
  @service router;

  constructor() {
    super(...arguments);
    this.address.countryCode = 'US';

    if (this.customerService.customer.billingAddress) {
      this.address.countryCode = this.customerService.customer.billingAddress.country;
      this.address.stateCode = this.customerService.customer.billingAddress.state;
      this.address.postcode = this.customerService.customer.billingAddress.postcode;
      this.address.streetAddress = this.customerService.customer.billingAddress.streetAddress;
      this.address.city = this.customerService.customer.billingAddress.city;
    }

    if (this.appService.app.stripeElementsInBillingSettings) {
      taskFor(this.fetchSetupIntent).perform();
    }
  }

  get isFormValid() {
    return addressValidation(this.address);
  }

  @task
  *fetchSetupIntent() {
    try {
      let response = yield post(`/ember/customers/create_setup_intent_for_customer`, {
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdminId,
      });
      this.setupIntent = response;

      yield taskFor(this.initializeStripeElements).perform();
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('billing.credit-card-modal.stripe.payment-element-error'),
      );
    }
  }

  addressRequiresValidationForCountry(countryCode) {
    if (this.appService.app.disableFrontendAddressValidation) {
      return false;
    }

    return ['US', 'CA'].includes(countryCode);
  }

  @task
  *initializeStripeElements() {
    if (this.stripev3.stripeOptions?.stripeAccount !== this.setupIntent.stripe_account_id) {
      this.stripev3._stripe = null;
    }

    yield this.stripev3.load(this.getStripeKey(), {
      stripeAccount: this.setupIntent.stripe_account_id,
    });

    this.elements = this.stripev3.elements({
      loader: 'auto',
      appearance: { theme: 'minimal' },
      clientSecret: this.setupIntent.client_secret,
    });

    this.addressElementReference = this.elements.create('address', {
      mode: 'billing',
      fields: { name: 'never' },
    });
    this.paymentElementReference = this.elements.create('payment', { layout: 'tabs' });

    this.paymentElementReference.mount('#payment-element');
    this.addressElementReference.mount('#address-element');
  }

  _isStaging() {
    return window.location.hostname.startsWith(ENV.APP.stagingHostname);
  }

  getStripeKey() {
    if (this._isStaging()) {
      return ENV.APP.stripe.sandboxPublishableKey;
    }
    return this.stripev3.publishableKey;
  }

  @action
  stripeElementChanged(_, ...args) {
    let { complete, value } = args[0];
    let oldPostcode = this.address?.postcode?.toLowerCase() || '';
    let newPostcode = value.postalCode?.toLowerCase() || '';
    if (complete && newPostcode.includes(oldPostcode)) {
      this.address.postcode = value.postalCode;
    }
  }

  @task
  *updateCardTask() {
    let response;
    let addressElement = this.elements.getElement('address');
    let {
      value: { address },
    } = yield addressElement.getValue();

    try {
      let addressParams = {
        country_code: address?.country,
        state_code: address?.state,
        postcode: address?.postal_code,
        city: address?.city,
        street_address: address?.line1,
        app_id: this.appService.app.id,
      };

      // validate US/CA address with avalara
      if (this.addressRequiresValidationForCountry(address.country ?? '')) {
        try {
          yield taskFor(this.validateAddress).perform(addressParams);
        } catch (err) {
          this.notificationsService.notifyError(
            this.intl.t('signup.teams.pricing5.annual-plans.address-error'),
          );
          this.purchaseAnalyticsService.trackEvent({
            action: 'errored',
            object: 'update_card_button',
            context: 'address_validation',
            place: 'credit-card-modal',
          });
          return;
        }
      }

      let baseUrl = window.location.origin;
      let url = this.router.urlFor(
        'apps.app.settings.workspace.billing.settings',
        this.appService.app.id,
      );

      response = yield this.stripev3.instance.confirmSetup({
        elements: this.elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${baseUrl}${url}`,
        },
      });
    } catch (_err) {
      return this.notificationsService.notifyError(
        this.intl.t('billing.credit-card-modal.stripe.generic-error'),
      );
    }

    if (response.error) {
      switch (response.error?.type) {
        case 'card_error':
        case 'validation_error':
          if (
            response.error?.decline_code === 'lost_card' ||
            response.error?.decline_code === 'stolen_card'
          ) {
            this.notificationsService.notifyError(
              this.intl.t('billing.credit-card-modal.stripe.lost-stolen-card'),
            );
          } else {
            this.notificationsService.notifyError(response.error?.message);
          }
          break;
        case 'invalid_request_error':
          if (response.error?.code === 'setup_intent_authentication_failure') {
            this.notificationsService.notifyError(
              this.intl.t('billing.credit-card-modal.stripe.card-unauthorized'),
            );
          } else {
            this.notificationsService.notifyError(
              this.intl.t('billing.credit-card-modal.stripe.generic-error'),
            );
          }
          break;
        default:
          this.notificationsService.notifyError(
            this.intl.t('billing.credit-card-modal.stripe.generic-error'),
          );
          break;
      }
      return;
    }

    let stripePaymentMethodId = response.setupIntent.payment_method;

    let requestData = {
      country_code: address?.country,
      state_code: address?.state,
      stripe_payment_method_id: stripePaymentMethodId,
      app_id: this.appService.app.id,
      billing_address: {
        street_address: address?.line1,
        city: address?.city,
        postcode: address?.postal_code,
        country: address?.country,
        state_code: address?.state,
      },
    };
    yield taskFor(this.updateDefaultPaymentMethod).perform(requestData);
    this.args.closeModal();
  }

  @task
  *legacyUpdateCardTask(stripeElement) {
    if (!addressValidation(this.address)) {
      this.showAllErrors = true;
      return;
    }

    try {
      let stripePaymentMethodId = yield this.paymentIntentService.addPaymentMethod.perform(
        stripeElement,
        this.address.countryCode,
      );

      let requestData = {
        country_code: this.address.countryCode,
        state_code: this.address.stateCode,
        stripe_payment_method_id: stripePaymentMethodId,
        app_id: this.appService.app.id,
        billing_address: {
          street_address: this.address.streetAddress,
          city: this.address.city,
          postcode: this.address.postcode,
          country: this.address.countryCode,
          state_code: this.address.stateCode,
        },
      };

      yield taskFor(this.updateDefaultPaymentMethod).perform(requestData);
      this.args.closeModal();
    } catch (error) {
      if (error?.message) {
        return this.notificationsService.notifyError(error.message);
      }
      let responseObject = error.jqXHR.responseJSON;
      let errorMessage;
      if (responseObject) {
        errorMessage =
          responseObject.errors.country ||
          responseObject.errors.token ||
          responseObject.errors.billing_address;
      } else {
        errorMessage = this.intl.t('billing.credit-card-modal.update-error');
      }
      this.notificationsService.notifyError(errorMessage);
    }
  }

  @task
  *validateAddress(address) {
    let response;
    try {
      response = yield post('/ember/customers/validate_address', address);
      return response;
    } catch (err) {
      captureException(err);
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.address-error'),
      );
      console.error(err?.jqXHR?.responseJSON?.error);
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      throw err;
    }
  }

  @task
  *updateDefaultPaymentMethod(params) {
    let response = yield post(
      `/ember/customers/${this.customerService.customer.id}/update_credit_card`,
      params,
    );

    this.store.pushPayload('billing/customer', {
      'billing/customer': response,
    });
    this.notificationsService.notifyConfirmation(
      this.intl.t('billing.credit-card-modal.successful-update'),
    );
  }
}
