/* import __COLOCATED_TEMPLATE__ from './addon-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import templateOnlyComponent from '@ember/component/template-only';
import { type AddonData } from './feature-comparison';
const BillingMigrationAddonCellComponent = templateOnlyComponent<Signature>();

interface Args {
  addonData: AddonData;
  proactiveSupportPlusHCLink: string;
}

interface Signature {
  Args: Args;
}

export default BillingMigrationAddonCellComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::AddonCell': typeof BillingMigrationAddonCellComponent;
  }
}
