/* import __COLOCATED_TEMPLATE__ from './group-current-usage-row.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { SALES_LED_ONLY_SMS_METRICS } from 'embercom/lib/purchase/constants';
import { inject as service } from '@ember/service';
import { CALL_TO_ACTION_GROUPS } from 'embercom/components/billing/summary/card-elements/group-current-usage-row';

export default class GroupCurrentUsageRow extends Component {
  @service intl;

  get title() {
    if (this.isSalesforceContracted) {
      return this.intl.t('billing.summary.breakdown.current-usage.default');
    } else {
      return this.intl.t('billing.summary.breakdown.current-usage.with-metric-group', {
        metricDisplayName: this.row.metricDisplayName,
      });
    }
  }

  get showCallToAction() {
    return (
      this.row.actualUsageAsInt === 0 && CALL_TO_ACTION_GROUPS[this.mappedMetricInCallToActionList]
    );
  }

  get callToActionMetricsList() {
    return Object.keys(CALL_TO_ACTION_GROUPS);
  }

  get mappedMetricInCallToActionList() {
    return this.callToActionMetricsList.find((metric) =>
      this.row.metricIdentifier.startsWith(metric),
    );
  }

  get hideCustomMetricWhenSelfServe() {
    return (
      !this.isSalesforceContracted && SALES_LED_ONLY_SMS_METRICS.includes(this.row.metricIdentifier)
    );
  }

  get row() {
    return this.args.row;
  }

  get isSalesforceContracted() {
    return this.row.isSalesforceContracted;
  }

  // Add right padding if we're not showing the price breakdowntooltip
  get addPricePadding() {
    return !(this.row.price && this.row.tooltipData);
  }
}
