/* import __COLOCATED_TEMPLATE__ from './product-edit-row.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
import { map, filter, readOnly, not } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import ProductSummaryRow from 'embercom/components/billing/product-summary-row';
import { inject as service } from '@ember/service';
import { PRODUCTS } from 'embercom/lib/billing';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';

export default ProductSummaryRow.extend({
  purchaseAnalyticsService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  requiredPrice: readOnly('price'),
  displayPlans: filter('product.plans', (plan) => plan.get('selfServe') || plan.get('active')),

  plans: map('displayPlans', (plan) => ({
    value: plan.get('idAsNumber'),
    text: plan.get('name'),
  })),

  productDescription: computed('product.id', function () {
    return PRODUCTS[this.get('product.id')].toggledPerProductDescription;
  }),

  selectedPlan: computed('product', function () {
    let plan =
      this.displayPlans.find((plan) => plan.get('active')) || this.get('displayPlans.firstObject');

    return plan && plan.get('idAsNumber');
  }),

  // Products that are not self serve and require sales to buy
  salesEnabledProduct: not('product.selfServe'),

  buttonLabel: computed('salesEnabledProduct', function () {
    if (this.salesEnabledProduct) {
      return 'Chat with us';
    } else {
      return 'Add to subscription';
    }
  }),

  selectPlan: action(function (planId) {
    this.displayPlans.forEach((plan) => plan.set('active', plan.get('idAsNumber') === planId));
  }),

  addProductOrOpenMessenger: action(function () {
    let object = this.salesEnabledProduct ? 'chat_with_us' : 'add_product';

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object,
      context: 'current_subscription',
      productId: this.get('product.id'),
      productName: this.get('product.key'),
    });

    if (this.salesEnabledProduct) {
      let message = `Hi, I'd like to know more about ${this.product.name}.`;
      showNewMessageInIntercomWidget(message);
    } else {
      this.set('displayPlans.firstObject.active', true);
    }
  }),

  removeProduct: action(function () {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'remove_product',
      context: 'current_subscription',
      productId: this.get('product.id'),
      productName: this.get('product.key'),
    });

    this.get('product.plans').forEach((plan) => plan.set('active', false));
  }),
});
