/* import __COLOCATED_TEMPLATE__ from './pricing-tooltip-core.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import numericFormatter from 'embercom/lib/numeric-formatter';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';

export default class PricingCore extends Component {
  @service customerService;
  @service appService;
  @service intl;
  product = this.args.product;

  get shouldDisplayTooltip() {
    return this.args.price && !this.args.hideBreakdown;
  }

  get sections() {
    return [this.planSection];
  }

  get planSection() {
    return {
      label: this.args.label,
      metrics: this.generateMetrics(this.args.price),
    };
  }

  get billingPeriodDurationInMonths() {
    return this.customerService.customer.subscription.billingPeriodDurationInMonths;
  }

  generateMetrics(price) {
    let { charges } = price.breakdown.firstObject;
    let summaryRows = charges
      .map((charge) => new PricingMetric(charge, this.intl).tooltipSummary())
      .flat();
    let discountRow = price.getDiscountInfo(this.billingPeriodDurationInMonths);
    let priceDifferential = this.customerService.currentPrice?.diff(
      this.customerService.originalPrice,
      this.billingPeriodDurationInMonths,
      true,
    );

    return [
      {
        label: this.intl.t('billing.change-plan-modal.pricing-tooltip-core.starting-price'),
        value: `$${numericFormatter(price.planStartingPrice / 100, 2, true)}`,
      },
      ...summaryRows,
      discountRow,
      {
        label: this.intl.t('billing.change-plan-modal.pricing-tooltip-core.total-price'),
        value: `$${numericFormatter(priceDifferential / 100, 2, true)}`,
      },
    ];
  }
}
