/* import __COLOCATED_TEMPLATE__ from './locale-tab-control.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import defaultTo from '@intercom/pulse/lib/default-to';

export default Component.extend({
  helpCenterService: service(),
  site: readOnly('helpCenterService.site'),
  currentlySelectedLocaleId: readOnly('helpCenterService.currentlySelectedLocaleId'),
  attributeBindings: ['data-test-locale-tab-control'],
  'data-test-locale-tab-control': true,
  showAll: false,
  beforeLocaleUpdate: defaultTo(() => {}),
  afterLocaleUpdate: defaultTo(() => {}),
  showBorder: defaultTo(false),

  updateLocale: action(function (locale) {
    this.beforeLocaleUpdate(locale);
    this.helpCenterService.updateLocale(locale);
    this.afterLocaleUpdate(locale);
  }),
});
