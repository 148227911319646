/* import __COLOCATED_TEMPLATE__ from './channel-card.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { statuses } from 'embercom/lib/channels/constants';
import { capitalize } from '@ember/string';
import { action } from '@ember/object';

export default class FacebookCard extends Component {
  @service appService;
  @service store;
  @service intl;
  @service router;
  @service intercomEventService;
  @tracked appPackage;

  get statusLabel() {
    let localisedStatuses = {
      [statuses.installed]: this.intl.t('channels.channels-hub.status.installed'),
      [statuses.active]: this.intl.t('channels.channels-hub.status.active'),
      [statuses.inactive]: this.intl.t('channels.channels-hub.status.inactive'),
      [statuses.beta]: this.intl.t('channels.channels-hub.status.beta'),
    };
    return localisedStatuses[this.args.status?.toLowerCase()] || capitalize(this.args.status ?? '');
  }

  get stampColor() {
    let stampColors = {
      [statuses.active]: 'green',
      [statuses.installed]: 'green',
      [statuses.inactive]: 'gray',
      [statuses.beta]: 'sky',
    };
    return stampColors[this.args.status?.toLowerCase()] || 'blue';
  }

  get installOrSettingsRoute() {
    return this.shouldShowInstallButton ? this.args.installRoute : this.args.route;
  }

  get shouldShowInstallButton() {
    return this.args.status !== statuses.installed && this.args.installRoute;
  }

  _handleClick() {
    if (this.args.onAction) {
      this.args.onAction();
    } else {
      this.router.transitionTo(this.installOrSettingsRoute);
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: this.args.trackObject || 'unknown',
    });
  }

  @action
  buttonClick() {
    if (!(this.args.onAction || this.installOrSettingsRoute)) {
      return;
    }
    this._handleClick();
  }
}
