/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import SettingsPanel from 'embercom/components/appstore/app-package/settings/salesforce-by-intercom/base/panel';
import { computed } from '@ember/object';
import { post } from 'embercom/lib/ajax';

const UNAUTHENTICATED_ERROR_CODE = 403;
const RATE_LIMIT_EXCEEDED_ERROR_CODE = 429;

export default SettingsPanel.extend({
  store: service(),
  appService: service(),
  redirectService: service(),
  notificationsService: service(),
  intercomEventService: service(),
  regionService: service(),

  authenticationInvalid: false,
  integrationMisconfigured: false,
  rateLimitExceeded: false,
  showBetaConfirmationModal: false,

  reauthenticationUrl: computed('app.id', 'regionService.salesforceBaseURL', function () {
    return `${this.regionService.salesforceBaseURL}/salesforce/reauthenticate?app_id=${this.app.id}`;
  }),

  init() {
    this._super(...arguments);
    this.fetchSalesforceSettings.perform();
    this.registerHooks(this.getProperties('afterUninstall'));
  },

  fetchSalesforceSettings: task(function* () {
    try {
      let salesforceSyncState = yield this.store.queryRecord('salesforce-integration', {});
      if (salesforceSyncState && salesforceSyncState.hasDirtyAttributes) {
        salesforceSyncState.rollbackAttributes();
      }
      this.set('salesforceSyncState', salesforceSyncState);
    } catch (e) {
      switch (e.jqXHR.status) {
        case UNAUTHENTICATED_ERROR_CODE:
          this.set('authenticationInvalid', true);
          break;
        case RATE_LIMIT_EXCEEDED_ERROR_CODE:
          this.set('rateLimitExceeded', true);
          break;
        default:
          this.set('integrationMisconfigured', true);
          break;
      }
    }
  }).restartable(),

  afterUninstall: task(function* () {
    yield this.set('app.has_salesforce_integration', false);
  }).restartable(),

  updateSettings: task(function* () {
    try {
      this._notifyProcessing();
      yield this.salesforceSyncState.save();
      this._notifySuccess('Successfully updated your Salesforce settings');
    } catch (e) {
      this.salesforceSyncState.rollbackAttributes();
      this._notifyFailure("Something went wrong and we couldn't update your Salesforce settings");
    }
  }).drop(),

  reauthenticate: task(function* () {
    try {
      let signedRedirectUrl = yield this.generateRedirectUrl.perform(
        this.reauthenticationUrl,
        true,
      );
      this.redirectService.redirect(signedRedirectUrl);
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
  }).drop(),

  optInToBetaFeatures: task(function* () {
    this.intercomEventService.trackAnalyticsEvent({
      place: 'salesforce_beta_opt_in',
      object: 'confirm_modal',
      action: 'confirmed',
    });
    try {
      yield post('/ember/salesforce_integrations/opt_in_to_beta', { app_id: this.app.id });
      yield this.app.reload();
      this.fetchSalesforceSettings.perform();
      this.set('showBetaConfirmationModal', false);
      this.intercomEventService.trackAnalyticsEvent({
        place: 'salesforce_beta_opt_in',
        object: 'confirm_modal',
        action: 'succeeded',
      });
      this._notifySuccess('Success! You can now use the new features');
    } catch (error) {
      this.intercomEventService.trackAnalyticsEvent({
        place: 'salesforce_beta_opt_in',
        object: 'confirm_modal',
        action: 'failed',
      });
      this._notifyFailure("Something went wrong and we couldn't enable the new version for you");
    }
  }).drop(),
});
