/* import __COLOCATED_TEMPLATE__ from './locale-picker.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type SupportedLocale from 'embercom/models/articles/site/supported-locale';

interface Signature {
  Args: {
    site: HelpCenterSite;
    onSelectedLocale?: (locale: string) => void;
  };
  Blocks: {
    default: [string, SupportedLocale | undefined];
  };
}

export default class LocalePicker extends Component<Signature> {
  @tracked selectedLanguage: string = this.args.site.locale;

  get languages() {
    return this.args.site.selectedLocales.map((locale) => ({
      text: locale.name,
      value: locale.localeId,
    }));
  }

  get selectedLocale() {
    return this.args.site.selectedLocales.findBy('localeId', this.selectedLanguage);
  }

  @action
  selectLanguage(localeId: string) {
    this.selectedLanguage = localeId;
    if (this.args.onSelectedLocale) {
      this.args.onSelectedLocale(localeId);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::LocalePicker': typeof LocalePicker;
  }
}
