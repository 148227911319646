/* import __COLOCATED_TEMPLATE__ from './attribute-date-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
import { isBlank } from '@ember/utils';
import { computed } from '@ember/object';
import { equal, or, and, reads } from '@ember/object/computed';
import AttributeComponent from 'embercom/components/attribute-component';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import DateFormatter from 'embercom/vendor/intercom/date-formatter';
import { equalsAny, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';

let AttributeDateComponent = AttributeComponent.extend({
  isUserLastRequestAt: equalsAny('attribute.identifier', [
    'last_request_at',
    'last_request_at_ios',
    'last_request_at_android',
  ]),
  isCompanyLastRequestAt: equal('attribute.identifier', 'company.last_request_at'),
  isLastRequestAt: or('isCompanyLastRequestAt', 'isUserLastRequestAt'),
  isRecent: equal('value', 'Just now'),
  isJustNow: and('isLastRequestAt', 'isRecent'),
  tooltipText: reads('tooltipDate'),
  tooltipDate: ternaryToProperty('rawValue', 'formattedToolTipDate', 'defaultToolTipValue'),
  defaultToolTipValue: 'Unknown',
  formattedToolTipDate: computed('rawValue', function () {
    return moment(this.rawValue).format(dateAndTimeFormats.fullDateAndTimeWithoutTimezone);
  }),
  rawValue: computed(
    'attribute.identifier',
    'userOrCompany',
    'userOrCompany.last_request_at',
    'userOrCompany.last_request_at_ios',
    'userOrCompany.last_request_at_android',
    function () {
      return this.get(`userOrCompany.${this.get('attribute.identifierWithoutCompanyPrefix')}`);
    },
  ),
  isUnixTimestamp: computed('rawValue', function () {
    return this.rawValue && !isNaN(this.rawValue);
  }),
  unixTimestampToTimeString: computed('rawValue', function () {
    return moment.unix(this.rawValue).toString();
  }),
  cleanedDateValue: ternaryToProperty('isUnixTimestamp', 'unixTimestampToTimeString', 'rawValue'),
  value: computed('rawValue', 'attribute.identifier', function () {
    let previousLocale = moment.locale();
    if (this.inInbox) {
      moment.locale('en-inbox-attribute-date', {
        relativeTime: {
          future: 'Just now',
          past: '%s',
          s: 'Just now',
          m: '1m ago',
          mm: '%dm ago',
          h: '1h ago',
          hh: '%dh ago',
          d: '1d ago',
          dd: '%dd ago',
          M: '1mth ago',
          MM: '%dmth ago',
          y: '1y ago',
          yy: '%dy ago',
        },
      });
    }

    let valueToDisplay = null;
    if (this.isLastRequestAt) {
      valueToDisplay = DateFormatter.forcedPastFromNowRealTime(this.cleanedDateValue);
    } else {
      valueToDisplay = DateFormatter.attributeFromNow(
        this.cleanedDateValue,
        this.get('attribute.forcedPastFromNow'),
      );
    }

    if (this.inInbox) {
      moment.locale(previousLocale);
    }
    return valueToDisplay;
  }),
  filterUrl: computed('userOrCompany', 'attribute', 'rawValue', function () {
    return B64FilterParamEncoder.filterUrl(this.userOrCompany, this.attribute, this.rawValue);
  }),
  isNotBlank: computed('userOrCompany', 'attribute.identifier', function () {
    let userOrCompany = this.userOrCompany;
    if (this.attribute && userOrCompany) {
      return !isBlank(userOrCompany.getValue(this.get('attribute.identifier')));
    }
    return false;
  }),
});

export default AttributeDateComponent;
