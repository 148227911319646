/* import __COLOCATED_TEMPLATE__ from './save-view-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import ArticleSavedViewData from 'embercom/objects/content-service/saved-views/article-saved-view-data';
import { Scope } from 'embercom/objects/content-service/saved-views/constants/scopes';
import type SavedView from 'embercom/models/content-service/saved-view.js';
import type Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

interface Condition {
  languages: Array<string>;
  status: string;
  collection_ids: Array<number>;
  help_center_ids: Array<number>;
  author_ids: number[];
  last_edited_by_ids: number[];
  sync: boolean | null;
}

interface Request {
  conditions: Condition;
  columns: string[];
}

interface Args {
  request: Request;
  updateView: Function;
  selectedTagValues: string[];
  activeView?: SavedView;
  isPresetViewChanged?: boolean;
}

export default class SaveViewButton extends Component<Args> {
  @service declare router: Router;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get articleData() {
    return new ArticleSavedViewData({
      conditions: JSON.parse(JSON.stringify(this.args.request.conditions)),
      columns: this.args.request.columns,
      tags: this.args.selectedTagValues,
    });
  }

  get articleScope() {
    return Scope.ARTICLES;
  }

  @action onSave({ savedView }: { savedView: SavedView }) {
    this.args.updateView({ savedView });
  }

  get isSaveEnabled() {
    let showButton = false;
    if (this.args.isPresetViewChanged === false) {
      showButton = false;
    } else if (this.articleData) {
      showButton =
        this.args.activeView === undefined ||
        !this.articleData.equals(new ArticleSavedViewData(this.args.activeView?.savedViewData));
    }
    return showButton;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::List::SaveViewButton': typeof SaveViewButton;
    'articles/list/save-view-button': typeof SaveViewButton;
  }
}
