/* import __COLOCATED_TEMPLATE__ from './choice-step.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  stepNumber: number;
  stepTitle?: string | undefined;
  titleButtonVisible?: boolean;
  titleButtonLabel?: string;
  titleButtonAction?: $TSFixMe;
}

interface Signature {
  Blocks: {
    default: [];
  };
  Args: Args;
  Element: HTMLElement;
}

const ChoiceStep = templateOnlyComponent<Signature>();
export default ChoiceStep;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationSubcontent::ChoiceStep': typeof ChoiceStep;
  }
}
