/* import __COLOCATED_TEMPLATE__ from './video-popover-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { computed } from '@ember/object';
import LegacyOverlayBaseComponent from 'embercom/components/legacy-overlay-base-component';
import VideoUrlParser from '@intercom/embercom-composer/lib/video-url-parser';

const ENTER = 13;

let VideoPopover = LegacyOverlayBaseComponent.extend({
  extraClassNames: ['message-popover__container', 'o__input', 'o__fixed', 'js__popover__video'],
  classNameBindings: ['hasError:o__has-error'],
  hasError: false,
  caretOffset: 10,
  showCaret: true,
  openerElementSelector: computed('parentElementId', function () {
    return `#${this.parentElementId} .js__popover-opener__video`;
  }),
  popoverElementSelector: computed('parentElementId', function () {
    return `#${this.parentElementId} .js__popover__video.js__popover`;
  }),
  closePopover() {
    this.setProperties({
      isOpen: false,
      hasError: false,
      videoUrl: '',
    });
  },
  isVideoUrlValid: computed('videoUrl', function () {
    return VideoUrlParser.parse(this.videoUrl).isValid;
  }),
  keyDown(e) {
    let keyCode = e.which;
    if (keyCode === ENTER) {
      e.preventDefault();
      if (!this.isVideoUrlValid) {
        this.set('hasError', true);
      } else {
        this.insertVideo(this.videoUrl);
        this.closePopover();
      }
    }
  },
});

export default VideoPopover;
