/* import __COLOCATED_TEMPLATE__ from './conversation-card.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

class ConversationBubble {
  blocks = [];
  part;

  constructor(blocks, part) {
    this.blocks = blocks;
    this.part = part;
  }

  get isCard() {
    return this.blocks.length > 0 && this.firstBlock.type === 'messengerCard';
  }

  get canvas() {
    if (this.isCard) {
      return this.firstBlock.canvas;
    }
  }

  get firstBlock() {
    return this.blocks[0];
  }
}

export default class ConversationCard extends Component {
  @service store;
  @tracked selectedBubbles = [];

  @action
  onSelectBubble(reply) {
    this.selectedBubbles = [...this.selectedBubbles, reply];
  }

  @action
  onDeselectBubble(reply) {
    this.selectedBubbles = this.selectedBubbles.filter((r) => r !== reply);
  }

  get significantConversationBubbles() {
    let bubbles = [];

    let visibleParts = this.args.conversation.sortedParts.filter(this._isPartVisible);

    visibleParts.forEach((visiblePart) => {
      if (visiblePart.hasMessengerCards) {
        let partBubbles = this._splitPartWithCardsIntoReplies(visiblePart);
        bubbles = bubbles.concat(partBubbles);
      } else {
        bubbles.push(new ConversationBubble(visiblePart.blocks, visiblePart));
      }
    });

    return bubbles;
  }

  @action
  toggle(e) {
    if (!this.args.isExpanded) {
      this.loadMore();
    }
    this.args.onToggle();
  }

  @action
  loadMore() {
    this.args.conversation.syncedPartList.loadMoreUntilPart(
      this.args.conversation.firstUserPart.id,
    );
  }

  _splitPartWithCardsIntoReplies(partWithCards) {
    let selectableBlocks = [];
    let createNewPartForNextNonCardBlock = true;

    partWithCards.blocks.forEach((block) => {
      if (block.type === 'messengerCard') {
        let selectableBlock = new ConversationBubble([block], partWithCards);
        selectableBlocks.push(selectableBlock);
        createNewPartForNextNonCardBlock = true;
      } else if (createNewPartForNextNonCardBlock) {
        selectableBlocks.push(new ConversationBubble([block], partWithCards));
        createNewPartForNextNonCardBlock = false;
      } else {
        //append to last part
        let lastPart = selectableBlocks[selectableBlocks.length - 1];
        lastPart.blocks.push(block);
      }
    });

    return selectableBlocks;
  }

  _isPartVisible(part) {
    if (part.hasNoBody) {
      return false;
    }

    if (part.isUserPart) {
      return true;
    }

    if (part.isAdminPart) {
      return (
        (!part.isDeleted && !part.isRedacted && !part.admin_only && !part.isBotPart) ||
        (part.isBotPart && !part.answerId)
      );
    }

    return false;
  }
}
