/* import __COLOCATED_TEMPLATE__ from './message-type-popover-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/no-on-calls-in-components */
import { on } from '@ember/object/evented';
import { equal, not } from '@ember/object/computed';
import LegacyOverlayBaseComponent from 'embercom/components/legacy-overlay-base-component';
import { computed } from '@ember/object';

let MessageTypePopover = LegacyOverlayBaseComponent.extend({
  caretOffset: 10,
  showCaret: true,
  isOpen: false,
  isEmail: equal('messageType', 'email'),
  isInApp: not('isEmail'),
  openerElementSelector: computed('parentElementId', function () {
    return `#${this.parentElementId} .js__popover-opener__message-type`;
  }),
  popoverElementSelector: computed('parentElementId', function () {
    return `#${this.parentElementId} .js__popover__message-type.js__popover`;
  }),
  extraClassNames: ['message-popover__container', 'o__fixed', 'js__popover__message-type'],
  resetMessageType: on('didInsertElement', function () {
    if (this.get('app.importedUsersAndIsNotActivated')) {
      this.setMessageType('email');
    } else {
      this.setMessageType('chat');
    }
  }),
  emailDisabled: computed('users.@each.{unsubscribed_from_emails,canNotBeEmailed}', function () {
    if (this.users.length === 0) {
      // For group emails, we don't have the user objects straight away, so this will always be 0
      // so this check isn't useful in this case
      return false;
    } else if (this.users.length === 1) {
      // For 1:1s, we allow sending messages to unsubscribed users.
      return this.users[0].canNotBeEmailed;
    }
    return this.users.every((user) => user.unsubscribed_from_emails || user.canNotBeEmailed);
  }),
  closePopover() {
    this.set('isOpen', false);
  },
  actions: {
    setMessageType(messageType) {
      this.setMessageType(messageType);
      this.closePopover();
    },
  },
});

export default MessageTypePopover;
