/* import __COLOCATED_TEMPLATE__ from './status-per-workspace-banner.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import type Session from 'embercom/services/session';

const STATUS_PER_WORKSPACE_ARTICLE_ID = 999;
const HAS_CHANGED_OWN_STATUS_KEY = 'has_changed_own_away_status';
const HAS_DISMISSED_BANNER_KEY = 'has_dismissed_away_status_banner';

export default class StatusPerWorkspaceBanner extends Component {
  @service declare appService: any;
  @service declare session: Session;

  @trackedInLocalStorage({ keyName: HAS_CHANGED_OWN_STATUS_KEY })
  hasChangedOwnStatus = '';

  @trackedInLocalStorage({ keyName: HAS_DISMISSED_BANNER_KEY })
  hasDismissedBanner = '';

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.loadWorkspaces();
  }

  get canUseTeammateStatusPerWorkspace() {
    return this.appService.app?.canUseTeammateStatusPerWorkspace;
  }

  get adminAppsCount() {
    return this.workspacesLoaded ? this.session.workspacesList.length : 1;
  }

  get shouldShowBanner() {
    return (
      !this.hasDismissedBanner &&
      this.canUseTeammateStatusPerWorkspace &&
      this.adminAppsCount > 1 &&
      this.hasChangedOwnStatus
    );
  }

  get destinationElement() {
    return document.querySelector('.banner-portal-root');
  }

  private async loadWorkspaces() {
    await this.session.getWorkspaces();
  }

  get workspacesLoaded() {
    return this.session.isWorkspacesListLoaded;
  }

  @action dismissBanner() {
    this.hasDismissedBanner = '1';
  }

  @action
  openMoreInfoArticle() {
    window.Intercom('showArticle', STATUS_PER_WORKSPACE_ARTICLE_ID);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Admin::StatusPerWorkspaceBanner': typeof StatusPerWorkspaceBanner;
    'admin/status-per-workspace-banner': typeof StatusPerWorkspaceBanner;
  }
}
