/* import __COLOCATED_TEMPLATE__ from './section.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { notEmpty, and, readOnly, not } from '@ember/object/computed';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { notEqualToProperty } from '@intercom/pulse/lib/computed-properties';
import defaultTo from '@intercom/pulse/lib/default-to';

export default Component.extend({
  animate: defaultTo(true),
  isNotCurrentOpenSection: notEqualToProperty('name', 'privateAPI.openSectionName'),
  isCurrentOpenSection: not('isNotCurrentOpenSection'),
  accordionHasOpenSection: notEmpty('privateAPI.openSectionName'),
  isNotFocusedSection: and('accordionHasOpenSection', 'isNotCurrentOpenSection'),

  canGoFullscreen: defaultTo(false),
  isFullscreenToggled: defaultTo(false),
  isFullscreen: and('isCurrentOpenSection', 'isFullscreenToggled', 'canGoFullscreen'),

  shouldNotAddPaddingToBody: false,
  internalName: computed('privateAPI.groupName', 'name', function () {
    return `${this.privateAPI.groupName}${this.name}`;
  }),
  externalName: readOnly('name'),

  didInsertElement() {
    this._super(...arguments);
    this.privateAPI.registerSection(this);
  },

  willDestroyElement() {
    this._super(...arguments);
    this.privateAPI.unregisterSection(this);
  },

  mouseEnter() {
    this.set('isHovering', true);
  },

  mouseLeave() {
    this.set('isHovering', false);
  },
});
