/* import __COLOCATED_TEMPLATE__ from './user-select.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import User from 'embercom/models/user';

export default class CommonUserSelectComponent extends Component {
  @service appService;
  @tracked users = [];

  searchTerm = '';
  popover = null;

  @action search(term) {
    this.searchTerm = term;
    this.loadUsers.perform();
  }

  @action setPopover(popover) {
    this.popover = popover;
  }

  @action onSelect(user) {
    this.args.onSelect(user);
    this.popover.hide();
  }

  @task({ restartable: true })
  *loadUsers() {
    this.popover.show();
    yield timeout(ENV.APP._500MS);
    let result = yield User.search(this.appService.app, this.predicates, {});
    this.users = result.users;
  }

  get predicates() {
    return [
      {
        type: 'or',
        predicates: [
          { attribute: 'name', type: 'string', comparison: 'contains', value: this.searchTerm },
          { attribute: 'email', type: 'string', comparison: 'contains', value: this.searchTerm },
        ],
      },
    ];
  }
}
