/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import EmberObject, { action, computed } from '@ember/object';
import { bool, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Component from '@ember/component';
import { findBy } from '@intercom/pulse/lib/computed-properties';
import MailchimpList from 'embercom/models/mailchimp-list';
import {
  fetchSyncingState,
  enableSyncing,
  cancelSyncing,
} from 'embercom/lib/appstore/unsubscribe-app';
import { TEAMMATE_APP_URL } from 'embercom/lib/teammate-app';

export default Component.extend({
  notificationsService: service(),
  realTimeEventService: service(),
  redirectService: service(),

  init() {
    this._super(...arguments);
    this.set('mailchimpLists', []);
    this.setup.perform();
    this.registerHooks({ afterUninstall: this.cancelSyncing });
    this.realTimeEventService.subscribeTopics(['unsubscribe-integration-verified']);
    this.realTimeEventService.on('UnsubscribeIntegrationVerified', this, 'updateFromRealtime');
  },

  willDestroyElement() {
    this.realTimeEventService.off('UnsubscribeIntegrationVerified', this, 'updateFromRealtime');
    this.realTimeEventService.unsubscribeTopics(['unsubscribe-integration-verified']);
    this._super(...arguments);
  },

  updateFromRealtime() {
    this.set('syncingState.verified', true);
  },

  setup: task(function* () {
    yield this.fetchSyncingState.perform();
    yield this.fetchAllLists.perform();
  }),

  fetchSyncingState: task(function* () {
    let syncingState = yield fetchSyncingState(this.get('app.id'));
    this.set('syncingState', syncingState);
  }),

  lookupList: task(function* () {
    let response = yield MailchimpList.singleList(this.app.id, this.listToLookup);
    if (response.errorThrown) {
      this.notificationsService.notifyError('Unable to find a list with that ID.');
    } else {
      this.set('mailchimpLists', [response]);

      let selectedList = this.mailchimpLists.findBy('id', this.get('syncingState.listId'));
      if (selectedList) {
        selectedList.set('isSelected', true);
      }

      if (selectedList && this.get('syncingState.enabled')) {
        this.set('isEnabled', true);
      }
    }
  }),

  fetchAllLists: task(function* () {
    try {
      let lists = yield MailchimpList.list(this.app.id);
      this.set('mailchimpLists', lists);

      let selectedList = lists.findBy('id', this.get('syncingState.listId'));
      if (selectedList) {
        selectedList.set('isSelected', true);
      }

      if (selectedList && this.get('syncingState.enabled')) {
        this.set('isEnabled', true);
      }
    } catch (err) {
      this.set('syncingState.access_token', null);
    }
  }),

  enableSyncing: task(function* () {
    try {
      let listId = this.get('syncingState.listId');
      let response = yield enableSyncing(this.get('app.id'), null, 'mailchimp', listId);
      this.setProperties({
        syncingState: response,
        isEnabled: true,
      });
    } catch (err) {
      this.notificationsService.notifyError(err.jqXHR.responseJSON.errors);
    }
  }),

  cancelSyncing: task(function* () {
    yield cancelSyncing(this.get('app.id'));

    this.setProperties({
      isEnabled: false,
      'syncingState.access_token': '',
      'syncingState.webhook_secret': '',
      'syncingState.listId': '',
      'syncingState.verified': false,
      'syncingState.enabled': false,
      'syncingState.integrate_with': '',
      'syncingState.is_authorized': false,
      mailchimpLists: [],
    });

    this.notificationsService.notifyConfirmation('Success. Mailchimp syncing was disabled.');
  }),

  isEnabled: false,
  listToLookup: null,
  mailchimpLists: null,
  selectedList: findBy('mailchimpLists', 'isSelected', true),
  isMailchimpIntegration: equal('syncingState.integrate_with', 'mailchimp'),
  isAuthorized: bool('syncingState.is_authorized'),
  teammateAppUrl: TEAMMATE_APP_URL,

  callbackUrl: computed('syncingState.webhook_secret', 'app.id', 'teammateAppUrl', function () {
    return `${this.teammateAppUrl}/external_unsubscribe?app_id=${this.app.id}&secret=${this.syncingState.webhook_secret}`;
  }),

  redirectToOauth: action(function () {
    this.redirectService.redirect(
      `${this.teammateAppUrl}/mailchimp_sync/install?app_id=${this.app.id}&caller=sync`,
    );
  }),

  actions: {
    handleAuthorization(mailchimpApiKey) {
      if (!this.syncingState) {
        this.set('syncingState', EmberObject.create());
      }
      this.set('syncingState.access_token', mailchimpApiKey);
      this.fetchAllLists.perform();
    },

    selectList(list) {
      if (list === this.selectedList) {
        return;
      }

      let lists = this.mailchimpLists;
      lists.setEach('isSelected', false);
      list.set('isSelected', true);
      this.set('syncingState.listId', list.get('id'));
      this.enableSyncing.perform();
    },
  },
});
