/* import __COLOCATED_TEMPLATE__ from './simple-usage-without-inclusions.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import type Charge from 'embercom/models/billing/price/charge';
import { capitalize } from '@ember/string';
import { Metric } from 'embercom/models/data/pricing/metric-types';

interface Args {
  charge: Charge;
  shouldHidePrices: boolean;
  onPricing5_XPlan: boolean;
}

export default class SimpleUsageWithoutInclusions extends Component<Args> {
  get title() {
    return capitalize(this.args.charge.pricingMetricModel.pluralize(0)!);
  }

  get isCalling() {
    return this.args.charge.pricingMetricModel.metric === Metric.calling;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::SimpleUsageWithoutInclusions': typeof SimpleUsageWithoutInclusions;
  }
}
