/* import __COLOCATED_TEMPLATE__ from './selector.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { and, equal, readOnly, not, notEmpty, filterBy } from '@ember/object/computed';
import { action } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',
  inboxCapability: null,
  appService: service(),
  appstoreService: service(),
  intercomEventService: service(),
  router: service(),
  app: readOnly('appService.app'),
  isInbox: equal('restrictTo', 'conversations'),
  isOperator: equal('restrictTo', 'operator'),
  installedMessengerApps: filterBy('messengerApps', 'installed', true),
  availableMessengerApps: filterBy('installedMessengerApps', 'showInInserter', true),
  hasAvailableMessengerApps: notEmpty('availableMessengerApps'),
  doesNotHaveAvailableMessengerApps: not('hasAvailableMessengerApps'),
  shouldShowEmptyState: and('isInbox', 'doesNotHaveAvailableMessengerApps'),

  redirectToAppStore: action(function () {
    this.router.transitionTo(this.get('appstoreService.baseRoute'));
  }),

  actions: {
    trackExploreAppStore() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'explore_app_store',
        location: 'messenger',
      });
    },
  },
});
