/* import __COLOCATED_TEMPLATE__ from './hamburger-menu.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class extends Component {
  @service navbarCollapsingService;

  get includePadding() {
    if (typeof this.args.includePadding === 'undefined') {
      return true;
    } else {
      return this.args.includePadding;
    }
  }

  @action
  handleMenuToggleClick(event) {
    this.navbarCollapsingService.toggleNavbarCollapsed();
    let focusIsOnToggleButton = Boolean(
      document.activeElement?.closest('[data-submenu-collapse-toggle-button]'),
    );
    // we don't want the sidebar button to stay focused after click
    if (focusIsOnToggleButton) {
      document.activeElement.blur();
    }
  }
}
