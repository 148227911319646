/* import __COLOCATED_TEMPLATE__ from './font-picker-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

type Args = {
  item: {
    text: string;
    value: string;
    url?: string;
  };
};

export default class FontPickerItem extends Component<Args> {
  get filePath(): string {
    if (this.args.item.url) {
      return this.args.item.url;
    } else {
      let fileName = this.args.item.value.replace(/\s/g, '');

      return assetUrl(`/assets/fonts/${fileName}-Regular.ttf`);
    }
  }

  get fontName(): string {
    return this.args.item.text.trim();
  }

  get className(): string {
    return this.args.item.value.toLowerCase().replace(/\s/g, '-');
  }

  get isNotSystemDefaultFont(): boolean {
    return this.args.item.value !== 'System Default';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Styling::Fonts::FontPickerItem': typeof FontPickerItem;
    'articles/site/settings/styling/fonts/font-picker-item': typeof FontPickerItem;
  }
}
