/* import __COLOCATED_TEMPLATE__ from './attribute-browser-locale-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
import { isBlank } from '@ember/utils';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import AttributeComponent from 'embercom/components/attribute-component';

let BrowserLocaleComponent = AttributeComponent.extend({
  tooltipText: reads('displayValue'),
  isNotBlank: computed('userOrCompany', 'attribute.identifier', function () {
    return !isBlank(this.get('userOrCompany.locale.name'));
  }),
  displayValue: computed('userOrCompany', 'isNotBlank', function () {
    if (this.isNotBlank) {
      return this.get('userOrCompany.locale.name');
    }
    return 'Unknown';
  }),
  value: computed('userOrCompany', 'isNotBlank', function () {
    if (this.isNotBlank) {
      return this.get('userOrCompany.locale.locale_id');
    }
    return 'Unknown';
  }),
});

export default BrowserLocaleComponent;
