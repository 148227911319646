/* import __COLOCATED_TEMPLATE__ from './tagging-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { resolve, all, Promise as EmberPromise } from 'rsvp';
import $ from 'jquery';
import { computed, action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { notEmpty, readOnly, map, match, or, union, filter } from '@ember/object/computed';
import Component from '@ember/component';
import { task, didCancel } from 'ember-concurrency';
import escapeRegExp from 'embercom/lib/escape-regular-expression';
import { inject as service } from '@ember/service';
import RouteRegexes from 'embercom/lib/route-regexes';
import { isBlank } from '@ember/utils';

export default Component.extend({
  store: service(),
  appService: service(),
  intercomEventService: service(),
  notificationsService: service(),
  permissionsService: service(),
  router: service(),
  intl: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  highlightIndex: 0,
  query: null,
  addedTags: null,
  removedTags: null,
  maxHeight: 337,
  trackEvents: true,

  initialTagsIncludingPossibleUndefined: map('taggable.taggings', function (tagging) {
    let tagId = tagging.belongsTo('tag').id();
    return this.store.peekRecord('tag', tagId);
  }),

  initialTags: computed('initialTagsIncludingPossibleUndefined.[]', function () {
    return this.initialTagsIncludingPossibleUndefined.compact();
  }),

  shouldRedirectToTagsSection: readOnly('allowTagManagement'),
  isOnInboxRoute: match('router.currentRouteName', RouteRegexes.inbox),

  isRestrictedIntercomradeOnInboxRoute: computed(
    'app.{currentAdmin.canManageTags,isIntercom}',
    'isOnInboxRoute',
    function () {
      if (!this.app.isIntercom || !this.isOnInboxRoute) {
        return false;
      }
      return !this.app.currentAdmin.canManageTags;
    },
  ),

  hasAddedTags: notEmpty('addedTags'),
  hasRemovedTags: notEmpty('removedTags'),
  canSave: or('hasAddedTags', 'hasRemovedTags'),
  saveLabel: '',

  allowTagManagement: readOnly('app.currentAdmin.canManageTags'),

  init() {
    this._super(...arguments);
    this.setProperties({
      query: '',
      addedTags: [],
      removedTags: [],
      saveLabel: this.intl.t('inbox.conversation-details-sidebar.tagging-component.save-button'),
    });
  },

  didInsertElement() {
    this._super(...arguments);
    this.setHeightsAndWidths();
  },

  setHeightsAndWidths() {
    schedule('afterRender', this, function () {
      if (!this.isDestroying) {
        $('input', this.element).css({
          'max-width': this._calculateInputMaxWidth(),
        });
        $('.tagger__list', this.element).css({
          height: this._calculateTagListHeight(),
        });
      }
    });
  },

  keyDown(e) {
    switch (e.which) {
      case 40: // arrow down
        this.send('highlightNext');
        e.preventDefault();
        break;
      case 38: // arrow up
        this.send('highlightPrevious');
        e.preventDefault();
        break;
      case 13: // return
        if (e.metaKey || e.ctrlKey || $('button', this.element).is(':focus')) {
          this.saveTask.perform();
        } else {
          this.send('selectHighlighted');
        }
        break;
      case 8: // backspace
        if (this.query.length === 0) {
          this.send('removeLastTag');
        }
        break;
    }
  },

  selectedTags: union('initialTagsWithoutRemovedTags', 'addedTags'),
  allTags: union('app.tags', 'addedTags'),

  initialTagsWithoutRemovedTags: computed('initialTags.[]', 'removedTags.[]', function () {
    return this.initialTags.filter((tag) => {
      return !this.removedTags.includes(tag);
    });
  }),

  isCreateNewVisible: computed('allowTagManagement', 'query', 'app.tags.[]', function () {
    if (!this.allowTagManagement) {
      return false;
    }
    let query = this.query.toLowerCase();
    if (!query) {
      return false;
    }
    return !this.get('app.tags').any((tag) => {
      return query === tag.get('name').toLowerCase();
    });
  }),

  unusedTags: computed('app.tags.[]', 'selectedTags.[]', function () {
    let selectedTagIds = this.selectedTags.map((tag) => parseInt(tag.get('id'), 10));
    let filteredTags = this.get('app.tags').filter(
      (tag) => selectedTagIds.includes(parseInt(tag.get('id'), 10)) === false,
    );

    filteredTags = filteredTags.reject((tag) => tag.archived);

    return filteredTags;
  }),

  matchingTags: computed('unusedTags.[]', 'query', function () {
    let query = this.query.trim();
    let escaped = escapeRegExp(query);
    let regex = new RegExp(escaped, 'i');
    return this.unusedTags.filter((tag) => {
      return regex.test(tag.get('name'));
    });
  }),

  newTags: filter('addedTags', function (tag) {
    return !tag.get('id');
  }),

  saveTask: task(function* () {
    try {
      if (this.validateBeforeSaveTask) {
        yield this.validateBeforeSaveTask.linked().perform(this.addedTags);
      }
      yield this._saveNewTags();
      this._addNewTagsToTheApp();
      this._makeNewTagsVisibleToAdmin();
      yield this.taggable.updateTaggings(
        this.admin,
        this.addedTags,
        this.removedTags,
        this.initialTags,
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: 'tagging_component',
        added: this.addedTags.map((t) => t.name),
        removed: this.removedTags.map((t) => t.name),
      });
      this._trackEvents();
      this._doAfterSave();
      this.setProperties({
        addedTags: [],
        removedTags: [],
      });
    } catch (e) {
      if (!didCancel(e)) {
        console.error(e);
        this.notificationsService.notifyResponseError(e, {
          default: this.intl.t(
            'inbox.conversation-details-sidebar.tagging-component.default-error',
          ),
        });
      }
    }
  }).drop(),

  _saveNewTags() {
    if (!this.get('newTags.length')) {
      return resolve();
    }

    let promises = this.newTags.map((tag) => {
      return tag.save();
    });
    return all(promises);
  },

  _doAfterSave() {
    if (this.afterSave) {
      this.afterSave(this.addedTags, this.removedTags);
    }
  },

  _addNewTagsToTheApp() {
    let newTags = this.newTags;
    this.get('app.tags').pushObjects(newTags);
  },

  _makeNewTagsVisibleToAdmin() {
    let newTagIds = this.newTags.map((tag) => {
      return tag.get('id');
    });
    this.admin.addTagIdsToAdminsVisibleTagIds(newTagIds);
  },

  _trackEvents() {
    if (this.trackEvents) {
      let hasUserCompanyId = this.location === 'importer' || this.type === 'conversation';
      let hasMessageId = this.location !== 'importer' && this.type === 'conversation';

      this.intercomEventService.trackEvent(`${this.type}-tagged`, {
        action: 'tagged',
        object: this.type === 'conversation' ? 'message' : this.type,
        place: this.location,
        owner: 'growth',
        user_company_id: hasUserCompanyId ? null : this.get('taggable.id'),
        message_id: hasMessageId ? this.get('taggable.id') : null,
        conversation_id: this.type === 'conversation' ? this.taggable.conversation_id : null,
      });

      if (this.get('taggable.typeLabel') === 'User') {
        this.intercomEventService.trackEvent('tagged-user', {
          screen: 'user profile',
          userId: this.get('taggable.id'),
          inbox: this.isOnInboxRoute,
        });
      }
    }
  },

  _isInInitialTags(tag) {
    return this.initialTags.includes(tag);
  },

  _isInRemovedTags(tag) {
    return this.removedTags.includes(tag);
  },

  _calculateInputMaxWidth() {
    if (this.get('selectedTags.length') === 0) {
      return '100%';
    }

    let $tagHolder = $('.tagger__holder', this.element);
    let $lastTag = $('.pill:last', this.element);
    let spacingAfterTag = 10;
    let tagHolderLeft = $tagHolder.position().left;
    let lastTagLeft = $lastTag.position().left;
    let lastTagPosLeft = lastTagLeft - tagHolderLeft;

    let positionLeft = lastTagPosLeft + $lastTag.outerWidth() + spacingAfterTag;
    let inputWidth = $tagHolder.width() - positionLeft;

    let inputMinWidth = 100;
    let $input = $('input', this.element);
    if ($input.length !== 0) {
      inputMinWidth = parseInt($input.css('min-width'), 10);
    }
    return inputWidth < inputMinWidth ? '100%' : inputWidth;
  },

  _calculateTagListHeight() {
    return this.maxHeight - $('.tagger__holder', this.element).height();
  },

  resetQuery() {
    this.setProperties({
      query: '',
      highlightIndex: 0,
    });
    this.setHeightsAndWidths();
  },

  showPermissionCheckModal: action(function () {
    this.permissionsService.loadAllAdminsAndShowPermissionRequestModal('can_manage_tags');
  }),

  actions: {
    save() {
      return new EmberPromise((resolve) => {
        this.saveTask
          .perform()
          // eslint-disable-next-line promise/prefer-await-to-then
          .then(() => resolve())
          .catch(() => resolve());
      });
    },

    addTag(tag, isNew = false) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'tag_selected',
        object: 'tagging_component',
        existing: !isNew,
      });

      if (this._isInRemovedTags(tag)) {
        this.removedTags.removeObject(tag);
      } else {
        this.addedTags.pushObject(tag);
      }
      this.resetQuery();
    },

    createTagAndAdd(name) {
      name = name.trim();
      if (isBlank(name)) {
        return;
      }

      let matchesExisting = this.allTags.any(
        (tag) => tag.name.localeCompare(name, undefined, { sensitivity: 'accent' }) === 0,
      );
      if (matchesExisting) {
        this.resetQuery();
        return;
      }

      let tag = this.store.createRecord('tag', {
        name,
        app_id: this.app.id,
      });
      this.send('addTag', tag, true);
    },

    removeTag(tag) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'tag_unselected',
        object: 'tagging_component',
      });

      if (this._isInInitialTags(tag)) {
        this.removedTags.pushObject(tag);
      } else {
        this.addedTags.removeObject(tag);
      }
      this.setHeightsAndWidths();
    },

    selectHighlighted() {
      let highlightIndex = this.highlightIndex;
      if (
        highlightIndex >= this.get('matchingTags.length') &&
        !this.isRestrictedIntercomradeOnInboxRoute
      ) {
        this.send('createTagAndAdd', this.query);
      } else {
        let highlightedTag = this.matchingTags.objectAt(highlightIndex);
        if (highlightedTag) {
          this.send('addTag', highlightedTag);
        }
      }
    },

    removeLastTag() {
      let lastTag = this.get('selectedTags.lastObject');
      if (lastTag) {
        this.send('removeTag', lastTag);
      }
    },

    highlightNext() {
      let items = this.matchingTags;
      let highlightIndex = this.highlightIndex;
      if (highlightIndex < items.length) {
        this.incrementProperty('highlightIndex');
      }
    },

    highlightPrevious() {
      let highlightIndex = this.highlightIndex;
      if (highlightIndex > 0) {
        this.decrementProperty('highlightIndex');
      }
    },

    updateQuery(query) {
      this.set('highlightIndex', 0);
      this.set('query', query);
    },
  },
});
