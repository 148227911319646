/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import { htmlUnescape } from 'embercom/lib/html-unescape';

const ALLOWED_ATTRIBUTES = [
  'full_name',
  'user_id',
  'email',
  'company.name',
  'company.remote_company_id',
];

export default class Main extends Component {
  @service appService;
  @service store;
  @service regionService;

  @tracked canvas = null;

  constructor() {
    super(...arguments);
    this._renderPreview();
  }

  get canAddMoreLinks() {
    return this.args.settings?.links?.length < 10;
  }

  get allowedAttributes() {
    return this.appService.app.allowedAttributes.filter((attribute) => {
      if (!attribute.get('templatable_identifier')) {
        return false;
      }

      if (ALLOWED_ATTRIBUTES.includes(attribute.get('templatable_identifier'))) {
        return true;
      }

      if (attribute.get('isUserCustomData') || attribute.get('isCompanyCustomData')) {
        return true;
      }

      return false;
    });
  }

  get attributes() {
    let attributes = this.allowedAttributes.slice();

    attributes.forEach((attribute) => {
      attribute.set('noFallback', true);
    });

    return attributes;
  }

  get links() {
    return this.args.settings?.links;
  }

  get hasUrlErrors() {
    return this.args.settings?.links?.any((url) => !url.validations.isValid);
  }

  get hasLabelErrors() {
    return this.args.settings?.links?.mapBy('label').any((label) => label.length === 0);
  }

  _formatFaviconUrl(url) {
    let faviconUrl = `${this.regionService.quickLinksBaseURL}/quick_links/icon`;
    let domain = this._parseFaviconDomain(url);

    if (domain) {
      faviconUrl += `?domain=${domain}`;
    }

    return faviconUrl;
  }
  _parseFaviconDomain(url) {
    try {
      let hostname = new URL(url).hostname;

      // We use the default icon for dynamic domains
      if (decodeURIComponent(hostname).includes('{')) {
        return null;
      }

      return hostname;
    } catch (err) {
      return null;
    }
  }
  _createListItem(id, title, image) {
    return {
      type: 'item',
      id,
      title,
      image,
      image_width: 16,
      image_height: 16,
    };
  }
  _createListComponent(items) {
    return {
      type: 'list',
      items,
    };
  }
  _getUrlFromLink(link) {
    return link.urlActionReceiver
      ? htmlUnescape(link.urlActionReceiver.getBlocks().firstObject.text)
      : link.url;
  }

  @action
  _performBlocksUpdate() {
    if (this.links) {
      this.args.settings.links = this.links.map((link) => {
        let url = this._getUrlFromLink(link);
        return { url, label: link.label };
      });

      debounce(this, this._renderPreview, ENV.APP._1000MS);
    }
  }

  @action
  _renderPreview() {
    let listItems = [];
    if (this.links) {
      listItems = this.links.map((link) => {
        let faviconUrl = this._formatFaviconUrl(this._getUrlFromLink(link));
        return this._createListItem(link.get('id'), `${link.get('label')} ↗`, faviconUrl);
      });
    }

    let content = {
      components: [this._createListComponent(listItems)],
    };
    this.canvas = { content };
  }

  @action
  processLinks() {
    this._performBlocksUpdate();
  }

  @action
  onLabelChanged(link, event) {
    link.label = event.target.value;
    this._performBlocksUpdate();
  }

  @action
  addLink() {
    if (this.canAddMoreLinks) {
      this.links.createFragment({ label: '', url: '' });
      this._performBlocksUpdate();
    }
  }

  @action
  removeLink(link) {
    this.links.removeFragment(link);
    this._performBlocksUpdate();
  }
}
