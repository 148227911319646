/* import __COLOCATED_TEMPLATE__ from './rate-conversation-part.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { put } from 'embercom/lib/ajax';
import { restartableTask } from 'ember-concurrency-decorators';

export default class RateConversationPart extends Component {
  @service notificationsService;
  @service appService;
  @service intl;

  @restartableTask
  *saveRating(rating) {
    try {
      let partId = this.args.part.id;
      let app = this.appService.app;
      yield put(`/ember/custom_answers/comment_ratings/${partId}`, {
        app_id: app.id,
        id: partId,
        rating,
      });
      this.args.part.set('answerRating', rating);
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyError(
        this.intl.t('custom-answers.rate-conversation-part.error'),
      );
    }
  }
}
