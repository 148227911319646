/* import __COLOCATED_TEMPLATE__ from './event-metadata-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Task from 'embercom/models/checklists/task';
// @ts-ignore
import { copy } from 'ember-copy';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';

interface Args {
  metadataPredicates: any[];
  task: Task;
}

export default class EventMetadataEditor extends Component<Args> {
  @service declare appService: any;
  @service attributeService: any;
  @service declare store: Store;
  @service declare intl: IntlService;

  get task() {
    return this.args.task;
  }

  get activeEvents() {
    return this.attributeService.attributes.filter((attr: any) => attr.isUserEvent);
  }

  get eventMetadata() {
    let selectedEvent = this.activeEvents.find(
      (event: any) => event.event_id === this.task.eventId,
    );

    if (!selectedEvent) {
      return [];
    }

    return selectedEvent.metadata.map((metadata: any) => {
      return {
        name: metadata.humanFriendlyName,
        identifier: metadata.identifier,
        type: metadata.type,
      };
    });
  }

  get attributeGroupList() {
    return [
      {
        attributes: this.eventMetadata,
      },
    ];
  }

  get noMetadataTooltipContent() {
    return this.intl.t('outbound.checklists.task-settings.event-has-no-metadata');
  }

  @action updateMetadata(predicates: any) {
    this.task.eventPredicateGroup.predicates = copy(predicates);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Sidebar::EventMetadataEditor': typeof EventMetadataEditor;
    'checklist/sidebar/event-metadata-editor': typeof EventMetadataEditor;
  }
}
