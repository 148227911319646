/* import __COLOCATED_TEMPLATE__ from './teammate-mappings.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
import SettingsPanel from 'embercom/components/appstore/app-package/settings/salesforce-by-intercom/base/panel';
import { task } from 'ember-concurrency';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default SettingsPanel.extend({
  appService: service(),
  app: readOnly('appService.app'),

  teammateMappings: computed('app.admins.[]', 'salesforceUsers.[]', function () {
    let mappings = this.syncState.salesforceUsers.map((salesforceUser) => {
      let matchingTeammate = this.get('app.admins').findBy('email', salesforceUser.Email);
      if (matchingTeammate) {
        return {
          salesforceUser: { name: salesforceUser.Name },
          teammate: matchingTeammate,
        };
      }
    });
    return mappings.compact();
  }),

  toggleAutoAssign: task(function* () {
    this.toggleProperty('syncState.settings.autoAssign');
    try {
      yield this.syncState.save();
      this._notifySuccess(`Successfully saved your settings.`);
    } catch (e) {
      this.syncState.rollbackAttributes();
      this._notifyFailure("Something went wrong and we couldn't update your SalesForce settings");
    }
  }).drop(),
});
