/* import __COLOCATED_TEMPLATE__ from './project-jira-ticket-type-section.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ProjectJiraTicketTypeSection extends Component {
  @tracked selectedJiraProject = this.args.projectId;
  @tracked jiraIntegrationObject = this.args.jiraIntegrationObject;
  @tracked showDestructiveModal = false;
  @tracked previousSelectedJiraProject = null;

  get jiraTicketTypesArray() {
    return this.jiraIntegrationObject.jiraTicketTypes[this.selectedJiraProject].map(
      (ticketType) => {
        return {
          value: ticketType.id,
          text: ticketType.name,
        };
      },
    );
  }

  get selectedJiraProjectName() {
    return this.jiraIntegrationObject.jiraProjectsArray.find(
      (project) => project.value === this.selectedJiraProject,
    ).text;
  }

  get mappingsObjects() {
    return this.args.mappings[this.selectedJiraProject];
  }

  @action
  setJiraProjectId(value) {
    this.previousSelectedJiraProject = this.selectedJiraProject;
    this.selectedJiraProject = value;
  }

  @action
  changeSelectedJiraProject() {
    this.args.removeJiraProject(this.previousSelectedJiraProject, false);
    if (this.args.selectedProjects.indexOf(this.selectedJiraProject) === -1) {
      this.args.addNewJiraProjectWithId(this.selectedJiraProject);
    }
  }
}
