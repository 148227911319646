/* import __COLOCATED_TEMPLATE__ from './billing-date-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class BillingDateComponent extends Component {
  @service customerService;

  get subscription() {
    return this.customerService.customer.subscription;
  }
}
