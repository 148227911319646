/* import __COLOCATED_TEMPLATE__ from './phone-number-migration.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task, taskGroup } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';

export default class PhoneNumberMigration extends Component {
  @service store;
  @service appService;
  @service notificationsService;
  @service intercomEventService;
  @service intl;
  @tracked step = 'step1';
  @tracked destinationWabaIdInput;
  @tracked whatsappPhoneNumberInput;
  @tracked verificationCodeInput;

  constructor() {
    super(...arguments);
    if (this.args.destinationWabaId !== '') {
      this.destinationWabaIdInput = this.args.destinationWabaId;
    }
  }

  get isTaskRunning() {
    return this.migrationTasks.state === 'running';
  }

  @taskGroup({ drop: true }) migrationTasks;

  @task({ group: 'migrationTasks' })
  *submitDestinationWabaIdTask() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'submit_destination_waba_id',
      object: 'get_started_migration_flow',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });

    yield (this.step = 'step2');
  }

  @task({ group: 'migrationTasks' })
  *requestVerificationCodeTask() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'request_verification_code',
      object: 'get_started_migration_flow',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });

    let data = {
      app_id: this.appService.app.id,
      destination_waba_id: this.destinationWabaIdInput,
      whatsapp_phone_number: this.whatsappPhoneNumberInput,
    };
    try {
      yield post('/ember/whatsapp/integrations/request_migration_verification_code', data);
      this.step = 'step3';
    } catch (e) {
      this.errorNotification(e);
    }
  }

  @task({ group: 'migrationTasks' })
  *submitVerificationCodeTask() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'submit_verification_code',
      object: 'get_started_migration_flow',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });

    let data = {
      app_id: this.appService.app.id,
      destination_waba_id: this.destinationWabaIdInput,
      whatsapp_phone_number: this.whatsappPhoneNumberInput,
      verification_code: this.verificationCodeInput,
    };
    try {
      let response = yield post(
        '/ember/whatsapp/integrations/submit_migration_verification_code',
        data,
      );
      this.store.pushPayload({ 'whatsapp/integration': response });
      this.args.onExit();
      this.successNotification();
    } catch (e) {
      this.errorNotification(e);
    }
  }

  @action
  submitDestinationWabaId() {
    this.submitDestinationWabaIdTask.perform();
  }

  @action
  requestVerificationCode() {
    this.requestVerificationCodeTask.perform();
  }

  @action
  submitVerificationCode() {
    this.submitVerificationCodeTask.perform();
  }

  @action
  resendCode() {
    this.requestVerificationCodeTask.perform();
  }

  successNotification() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('appstore.settings.whatsapp.phone-number-migration.notification.success'),
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'completed',
      object: 'get_started_migration_flow',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });
  }

  errorNotification(e) {
    this.notificationsService.notifyResponseError(
      e,
      {
        default: this.intl.t(
          'appstore.settings.whatsapp.phone-number-migration.notification.error',
        ),
      },
      {
        responseProvidedErrors: [403, 422],
        duration: 10000,
      },
    );
  }
}
