/* import __COLOCATED_TEMPLATE__ from './about-profile-location-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';

export default class AboutProfileLocationComponent extends Component {
  get geoipData() {
    let model = this.args.model;

    if (model?.isAdmin) {
      return model.location_data;
    }
    return model?.geoip_data;
  }

  get countryName() {
    return this.geoipData?.country_name;
  }

  get cityName() {
    return this.geoipData?.city_name;
  }
}
