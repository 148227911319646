/* import __COLOCATED_TEMPLATE__ from './custom-settings.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CustomSettings extends Component {
  @service store;
  @service appService;
  @service notificationsService;
  @service intl;

  @tracked teamAvailability;

  constructor() {
    super(...arguments);
    this.loadTeamAvailabilitySettings.perform();
  }

  get availabilityEnabled() {
    return this.teamAvailability?.enabledForLeads;
  }

  @task({ drop: true })
  *loadTeamAvailabilitySettings() {
    this.teamAvailability = yield this.store.findRecord(
      'operator-settings/team-availability',
      this.appService.app.id,
    );
  }

  @task({ restartable: true })
  *toggleRepliesToStories() {
    this.args.settings.toggleProperty('repliesToStories');
    try {
      yield this.args.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('appstore.settings.instagram.custom-settings.changes-save-success'),
      );
    } catch (e) {
      this.args.settings.toggleProperty('repliesToStories');
      console.error(e);
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('appstore.settings.instagram.custom-settings.changes-save-error'),
      });
    }
  }

  @task({ restartable: true })
  *toggleMentionsInStories() {
    this.args.settings.toggleProperty('mentionsInStories');
    try {
      yield this.args.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('appstore.settings.instagram.custom-settings.changes-save-success'),
      );
    } catch (e) {
      this.args.settings.toggleProperty('mentionsInStories');
      console.error(e);
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('appstore.settings.instagram.custom-settings.changes-save-error'),
      });
    }
  }

  @task({ restartable: true })
  *toggleSetExpectations() {
    this.args.settings.toggleProperty('setExpectationsBot');
    try {
      yield this.args.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('appstore.settings.instagram.custom-settings.changes-save-success'),
      );
    } catch (e) {
      this.args.settings.toggleProperty('setExpectationsBot');
      console.error(e);
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('appstore.settings.instagram.custom-settings.changes-save-error'),
      });
    }
  }
}
