/* import __COLOCATED_TEMPLATE__ from './submenu-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
export default class SubmenuComponent extends Component {
  @service appService;
  @service paywallService;
  @service customerService;
  @service store;

  @tracked isSecondaryWorkspace = false;

  constructor() {
    super(...arguments);
    this.loadWorkspacesContracted.perform();
  }

  get app() {
    return this.appService.app;
  }

  get customer() {
    return this.customerService.customer;
  }

  get showBillingUsageLink() {
    return this.app.onPricing5 && this.app.hasActiveSubscription && !this.customer.inCardlessTrial;
  }

  get hideFinManageUsageLink() {
    return this.app.onPricing5 && this.app.hasActiveSubscription && !this.customer.inCardlessTrial;
  }

  get canManageAnnualSubscription() {
    return (
      this.customerService.customer.hasActiveSubscription &&
      this.customerService.customer.hasCard &&
      this.customerService.isSelfServeAnnualCustomer &&
      this.app.onPricing5
    );
  }

  @task
  *loadWorkspacesContracted() {
    if (this.app.isSalesforceContracted) {
      let contract = yield this.store.findRecord('billing/contract', this.app.id);
      this.isSecondaryWorkspace = contract?.isSecondarySubscription;
    }
  }

  @action
  redirectToPurchase() {
    this.paywallService.redirectToPurchase();
  }
}
