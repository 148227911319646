/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import type HelpCenterNavigationService from 'embercom/services/help-center-navigation-service';
import { inject as service } from '@ember/service';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type UploadHelper } from 'embercom/objects/media/media-helper';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';

interface Args {
  site: HelpCenterSite;
  app: any;
  uploadHelper: UploadHelper;
}

const SHOW_FOOTER_CONTENT_BLOCK_BADGE = 'help_center_settings_show_footer_content_block_badge';

export default class Footer extends Component<Args> {
  @service declare helpCenterNavigationService: HelpCenterNavigationService;
  @service declare appService: $TSFixMe;

  @tracked showFooterTextCustomizationSideDrawer = false;
  @tracked showRichTextField = !!this.args.site.customizationOptions.footer.showRichTextField;
  @tracked declare showFooterContentBlockBadge: boolean;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    let showFooterContentBlockBadge = localStorage.getItem(SHOW_FOOTER_CONTENT_BLOCK_BADGE);
    this.showFooterContentBlockBadge = showFooterContentBlockBadge !== 'false';
  }

  get hasCustomizedFooterTextContent() {
    return isPresent(this.args.site.customizedFooterTextContents);
  }

  get showEditCustomFieldButton() {
    return this.hasCustomizedFooterTextContent || this.showRichTextField;
  }

  @action
  setShowFooterTextCustomizationSideDrawer(show: boolean) {
    this.showFooterTextCustomizationSideDrawer = show;
  }

  @action
  toggleShowRichTextField() {
    this.showRichTextField = !this.showRichTextField;
    if (this.showRichTextField && !this.hasCustomizedFooterTextContent) {
      this.showFooterTextCustomizationSideDrawer = true;
    }
    this.args.site.customizationOptions.footer.showRichTextField = this.showRichTextField;
    this.showFooterContentBlockBadge = false;
    localStorage.setItem(SHOW_FOOTER_CONTENT_BLOCK_BADGE, 'false');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::NewStyling::Footer': typeof Footer;
    'articles/site/settings/new-styling/footer': typeof Footer;
  }
}
