/* import __COLOCATED_TEMPLATE__ from './group-included-usage-row.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    name: string;
    isSalesforceContracted: boolean;
    metricIdentifier: string;
    isSecondarySubscription: boolean;
    usage: string;
    price: string | undefined;
  };
}

const GroupIncludedUsageRow = templateOnlyComponent<Signature>();
export default GroupIncludedUsageRow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::GroupIncludedUsageRow': typeof GroupIncludedUsageRow;
  }
}
