/* import __COLOCATED_TEMPLATE__ from './bulk-sync.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class BulkSync extends Component {
  @action
  closeModal() {
    this.args.closeModal();
  }

  @action
  async startBulkSync() {
    await this.args.startBulkSync();
    this.args.closeModal();
  }
}
