/* import __COLOCATED_TEMPLATE__ from './article-preview.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { htmlToTextContent } from 'embercom/lib/html-unescape';

const PREVIEW_PANEL_ID = 'article-inserter-content-preview';

export default class ArticlePreview extends Component {
  @service intercomEventService;
  @service paywallService;
  @service notificationsService;
  @service appService;
  @tracked anchorLinkSelections = {};

  get selectedAnchorLinkId() {
    return this.anchorLinkSelections[this.args.selectedLocaleId] || null;
  }

  get displayContent() {
    return (
      this.args.article.contentByLocale(this.args.selectedLocaleId) || this.args.article.firstObject
    );
  }

  get canInsert() {
    if (this.args.controlsAPI.conversation && this.args.article.hasRestrictedAudience) {
      return this.args.controlsAPI.conversation.main_participant.isUserRole;
    }
    return true;
  }

  get addButtonLabel() {
    if (this.args.hasMultipleLanguages) {
      return `Add in ${this.args.selectedLocaleName}`;
    }

    return 'Add';
  }

  get anchorLinks() {
    let defaultItem = [
      {
        text: 'From the start',
        value: null,
        component: 'articles/inbox-inserter/anchor-link-item',
        componentAttrs: { type: 'whole' },
      },
    ];
    let items = defaultItem.concat(this.articleHeadings);
    return [{ items }];
  }

  get hasAnchorLinks() {
    return this.articleHeadings.length > 0;
  }

  get articleHeadings() {
    return this.displayContent.headingBlocks.map((block) => {
      return {
        text: htmlToTextContent(block.text),
        value: block.idAttribute,
        component: 'articles/inbox-inserter/anchor-link-item',
        componentAttrs: { type: block.type },
      };
    });
  }

  _trackEvents() {
    this.intercomEventService.trackAnalyticsEvent({
      object: this.args.article,
      action: 'added_to_reply',
      is_suggestion: false,
      target_user_type: this.args.article.targetUserType,
      conversation_id: this.args.controlsAPI.conversation && this.args.controlsAPI.conversation.id,
      locale: this.args.selectedLocaleId,
      default_locale: this.args.article.site.locale,
      is_default_locale: this.args.selectedLocaleId === this.args.article.site.locale,
      anchor_link_id: this.selectedAnchorLinkId,
    });

    this.intercomEventService.trackEvent('sent_article_in_conversation', {
      action: 'sent',
      object: 'article',
      place: 'comment',
      owner: 'growth-sp',
    });
  }

  @action
  selectAnchorLink(anchorLinkId) {
    this.jumpToSection(anchorLinkId, { behavior: 'smooth' });
    this.anchorLinkSelections[this.args.selectedLocaleId] = anchorLinkId;
  }

  jumpToSection(anchorLinkId, scrollOptions = {}) {
    if (anchorLinkId) {
      document.querySelector(`#${PREVIEW_PANEL_ID} #${anchorLinkId}`).scrollIntoView(scrollOptions);
    } else {
      document.querySelector(`#${PREVIEW_PANEL_ID}`).scrollTo({ top: 0, ...scrollOptions });
    }
  }

  @action
  changeLocale() {
    this.jumpToSection(this.selectedAnchorLinkId);
  }

  @task({ drop: true })
  *insertCardTask() {
    try {
      let anchorLink = null;

      if (this.selectedAnchorLinkId !== null) {
        let blocks = [
          ...this.displayContent.jsonBlocks,
          ...this.displayContent.headingBlocks,
        ].uniq();

        let block = blocks.find((b) => b.idAttribute === this.selectedAnchorLinkId);

        anchorLink = {
          id: this.selectedAnchorLinkId,
          title: htmlToTextContent(block.text),
        };

        let preview = this.displayContent.getAnchorLinkPreview(this.selectedAnchorLinkId);

        if (preview) {
          Object.assign(anchorLink, { preview: htmlToTextContent(preview) });
        }
      }

      return yield this.args.article.createMessengerCard(
        this.args.selectedLocaleId,
        this.args.controlsAPI.conversation && this.args.controlsAPI.conversation.id,
        anchorLink,
        this.args.helpCenterId !== 'unlisted' ? this.args.helpCenterId : undefined,
      );
    } catch (error) {
      this.notificationsService.notifyError(
        'Could not add the article. Please ensure the article inserter app is installed',
      );
    }
  }

  @task({ drop: true })
  *insert() {
    this._trackEvents();

    let card = yield this.insertCardTask.perform();
    this.args.controlsAPI.actions.insertBlock('messengerCard', card);
    this.args.inserterAPI.actions.close();

    if (this.args.controlsAPI.actions.clearSuggestions) {
      this.args.controlsAPI.actions.clearSuggestions();
    }
  }
}
