/* import __COLOCATED_TEMPLATE__ from './frame.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import $ from 'jquery';
import Component from '@ember/component';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';
import { readOnly, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  store: service(),
  appService: service(),
  app: readOnly('appService.app'),
  isLoading: or('isSaving', 'submitApp.isRunning', 'initializeApp.isRunning'),

  contextualData: {},
  initializeApp: task(function* (event) {
    yield this.submitApp.perform();
  }),

  init() {
    this._super(...arguments);
    let prefetchedConfigurationOptions = this.get('messengerApp.prefetchedConfigurationOptions');
    if (prefetchedConfigurationOptions) {
      this.set('messengerApp.prefetchedConfigurationOptions', undefined);
      this.handleResponse(prefetchedConfigurationOptions);
    } else {
      this.submitApp.perform();
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    this.clearCanvasModelInstance();
  },

  clearCanvasModelInstance() {
    let canvas = this.canvas;
    if (canvas && !canvas.isDeleted) {
      canvas.deleteRecord();
      this.set('canvas', null);
    }
  },

  handleResponse(response) {
    this.clearCanvasModelInstance();
    if (response.new_canvas) {
      let inserterPanel = $('.app-framework__messenger-apps__inserter__panel__body');
      if (inserterPanel.length > 0) {
        inserterPanel.scrollTop(0);
      }
      this.renderCanvasForModel(response.new_canvas);
    } else if (response.results) {
      this.onConfigResults(response.results);
    } else {
      console.error('No canvas or results provided');
    }
  },

  renderCanvasForModel(responseCanvas) {
    let store = this.store;
    let normalized = store.normalize('app-framework/canvas', responseCanvas);
    let canvas = store.createRecord('app-framework/canvas', normalized.data.attributes);
    this.set('canvas', canvas);
  },

  submitApp: task(function* (actionComponentId, canvasInputValues) {
    let response = yield ajax({
      url: '/ember/messenger_apps/configure_card',
      type: 'POST',
      data: JSON.stringify({
        admin_id: this.get('app.currentAdmin.id'),
        app_id: this.get('app.id'),
        messenger_app_id: this.get('messengerApp.id'),
        component_id: actionComponentId,
        context: this.contextualData,
        current_canvas: this.canvas && this.canvas.serialize(),
        input_values: canvasInputValues,
      }),
    });
    this.handleResponse(response);
  }),
});
