/* import __COLOCATED_TEMPLATE__ from './vbp2-early-stage-banner-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import moment from 'moment-timezone';

export default class VBP2EarlyStageBannerComponent extends Component {
  @service purchaseAnalyticsService;
  @service customerService;
  @service appService;
  @service intl;

  get billingDateOfMonth() {
    // this date is arbitrary, but needs to be a full date, not just a number for the correct conversion
    // moment(15).format('Do') === 31 while moment('2019/1/15').format('Do') === 15
    return moment(`2010/1/${this.customerService.customer.subscription.invoiceDayOfMonth}`).format(
      'Do',
    );
  }

  get couponEndDate() {
    let d = moment(this.customerService.customer.couponExpiresAt);
    return d.format('MMMM, YYYY');
  }

  get customer() {
    return this.customerService.customer;
  }

  get programName() {
    return this.customerService.earlyStageGraduation.earlyStageStep?.stepConfig
      ?.couponPercentOff === 100
      ? this.intl.t('billing.details.vbp2-early-stage-banner.program_name.startups_program')
      : this.intl.t('billing.details.vbp2-early-stage-banner.program_name.early_stage');
  }

  get couponPercentOff() {
    return Math.floor(
      this.customerService.earlyStageGraduation.earlyStageStep.stepConfig.couponPercentOff,
    );
  }

  get graduationInProgress() {
    return this.customerService.earlyStageGraduation.inProgress;
  }
}
