/* import __COLOCATED_TEMPLATE__ from './subscription-summary-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { action, computed } from '@ember/object';
import { alias, filterBy, mapBy, not, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isPresent } from '@ember/utils';
import { flatMap, ternary } from '@intercom/pulse/lib/computed-properties';
import { task } from 'ember-concurrency';
import EditSubscriptionComponent from 'embercom/components/billing/edit-subscription-component';
import ajax from 'embercom/lib/ajax';
import { ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID, PRODUCTS } from 'embercom/lib/billing';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import numberFormatter from 'embercom/lib/number-formatter';
import numericFormatter from 'embercom/lib/numeric-formatter';
import { PRICING_METRIC_GROUPS } from 'embercom/lib/purchase/constants';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import {
  CONVERT_SEAT_TYPE,
  ENGAGE_SEAT_TYPE,
  PROACTIVE_SUPPORT_SEAT_TYPE,
  SUPPORT_SEAT_TYPE,
  VBP2_SEAT_LABELS,
} from 'embercom/lib/settings/seats/constants';

// migration tabs
const CURRENT_PRICE_TAB = 'current';

// summary views
const NORMAL_VIEW = 'normalView';
const CUSTOM_VIEW = 'customView';
const ANNUAL_CONTRACT_VIEW = 'annualContractView';

const summaryView = EmberObject.extend({
  showUsage: false,
  showUsageComparison: false,
  allowEditingProducts: false,
  showCancelButton: false,
  cancelButtonAction: 'cancelSubscription',
  showEditButton: false,
  totalPriceText: 'Total monthly price',
  showDiscounts: false,
});

export default EditSubscriptionComponent.extend({
  purchaseAnalyticsService: service(),
  preDiscountTotalAmount: readOnly('customerService.totalAmount'),
  totalAmount: readOnly('customerService.totalAmountPostCoupon'),
  currentPrice: readOnly('customerService.currentPrice'),
  customer: alias('customerService.customer'),
  cardError: null,
  notificationsService: service(),
  appService: service(),
  modelDataCacheService: service(),
  router: service(),
  intl: service(),

  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  app: alias('appService.app'),
  plans: flatMap('products', 'plans'),
  activePlans: filterBy('plans', 'active'),
  activePlanIds: mapBy('activePlans', 'idAsNumber'),
  prices: alias('customerService.prices'),
  migrationPrices: alias('customerService.migrationPrices'),
  migrationPrice: readOnly('customerService.migrationPrice'),
  migrationDate: alias('customerService.migrationDate'),
  timeToMigrationDateText: alias('customerService.timeToMigrationDateText'),

  isEditing: alias('customerService.isEditing'),
  previousAction: null,

  views: computed('isEditing', 'currentPrice', 'migrationPrice', function () {
    // define properties
    let isEditing = this.isEditing;
    // define views
    let summaryViews = {};

    summaryViews[NORMAL_VIEW] = summaryView.create({
      identifier: NORMAL_VIEW,
      showUsage: true,
      allowEditingProducts: isEditing,
      showCancelButton: isEditing,
      showEditButton: !isEditing,
      showDiscounts: true,
    });

    summaryViews[CUSTOM_VIEW] = summaryView.create({
      identifier: CUSTOM_VIEW,
      totalPriceText: 'Estimated total monthly price (updated daily)',
      hardCodedPrice: `$${numericFormatter(this.customer.estimatedMonthlyCost / 100, 2, true)}`,
    });

    summaryViews[ANNUAL_CONTRACT_VIEW] = summaryView.create({
      identifier: ANNUAL_CONTRACT_VIEW,
      totalPriceText: 'Total monthly price',
      hardCodedPrice: `$${numericFormatter(this.customer.estimatedMonthlyCost / 100, 2, true)}`,
    });

    return summaryViews;
  }),

  getView(identifier) {
    if (identifier) {
      return this.views[identifier];
    }
    return null;
  },

  primaryView: computed('primaryViewIdentifier', 'views', function () {
    return this.getView(this.primaryViewIdentifier);
  }),

  primaryViewIdentifier: computed(
    'customer.hasCustomPricing',
    'app.isSalesforceContracted',
    function () {
      let customer = this.customer;
      if (customer.hasCustomPricing) {
        return CUSTOM_VIEW;
      } else if (this.app.isSalesforceContracted) {
        return ANNUAL_CONTRACT_VIEW;
      } else {
        return NORMAL_VIEW;
      }
    },
  ),

  currentSummaryView: alias('primaryView'),

  init() {
    this._super(...arguments);
    this.setActivePlans();
  },

  currentTab: CURRENT_PRICE_TAB,

  dirty: alias('customerService.isDirty'),
  isNotDirty: not('dirty'),

  displayProducts: computed(
    'customer',
    'products.@each.active',
    'editingOnRegularPlan',
    function () {
      let products = this.products;
      if (!this.get('customer.hasActiveSubscription') || this.editingOnRegularPlan) {
        return products;
      } else {
        return products.filterBy('active');
      }
    },
  ),

  sortedProducts: computed('displayProducts', function () {
    return this.displayProducts.sortBy('inactive');
  }),

  shouldBeDisabled: alias('isNotDirty'),

  anyProductsActive: computed('products.@each.active', function () {
    return this.products.find((product) => product.get('active'));
  }),

  activeNonAddonProducts: computed('products.@each.active', function () {
    return this.products.filter((product) => product.get('active') && !product.get('addon'));
  }),

  saveAction: computed('anyProductsActive', function () {
    if (this.anyProductsActive) {
      return 'save';
    } else {
      return 'cancelSubscription';
    }
  }),

  formattedCardExpiresAt: alias('customer.formattedCardExpiresAt'),
  cancelButtonLabel: ternary('dirty', 'Cancel changes', 'Go back'),

  formattedCouponDiscountDollarAmount: computed('currentPrice', function () {
    let couponAmount =
      (this.get('currentPrice.preDiscountAmount') - this.get('currentPrice.amount')) / 100;
    return Number.isInteger(couponAmount)
      ? numberFormatter(couponAmount)
      : numericFormatter(couponAmount, 2, true);
  }),

  upgradeProducts: computed(
    'customer',
    'isEditing',
    'products.@each.active',
    'products.@each.inactive',
    function () {
      let products = this.products;
      if (!this.get('customer.hasActiveSubscription')) {
        return products;
      } else {
        return products.filterBy('inactive');
      }
    },
  ),

  updateSubscriptionFormData() {
    let appId = this.get('app.id');
    let formData = {
      plan_ids: this.activePlanIds,
      app_id: appId,
    };
    return formData;
  },

  sendNewOrUpdateSubscriptionTask: task(function* (formData) {
    let appId = this.get('app.id');

    let params = {
      url: `/ember/customers/${appId}/update_subscription`,
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify(formData),
    };

    yield ajax(params)
      .then(() => {
        this.notificationsService.notifyConfirmation(
          'You have successfully updated your subscription!',
        );
        this.customerService.reportProductChangesForAnalytics({
          place: 'billing_summary',
          page: 'billing summary',
          context: this.context,
        });
        this.customerService.loadData({ source: 'subscription-summary-component' });
      })
      .catch((err) => {
        let message = 'Please contact our sales team.';
        if (err && err.jqXHR && err.jqXHR.responseJSON && err.jqXHR.responseJSON[0]) {
          message = err.jqXHR.responseJSON[0];
        }
        this.notificationsService.notifyError(
          `There was an error updating your subscription: ${message}`,
        );
      });
  }),

  updateAppAdminInboxAccess(inboxWasActive) {
    if (!inboxWasActive && this.get('app.inboxIsActive')) {
      return this.set('app.currentAdmin.currentAppPermissions.has_inbox_access', true);
    }
  },

  updateSubscription() {
    let formData = this.updateSubscriptionFormData();
    let inboxWasActive = this.get('app.inboxIsActive');
    this.sendNewOrUpdateSubscriptionTask
      .perform(formData)
      .then(() => {
        this.app.reload().then(() => {
          this.customerService.resetAll();
          this.modelDataCacheService.clear();
          this.updateAppAdminInboxAccess(inboxWasActive);
          this.set('isEditing', false);
        });
      })
      .catch((err) => {
        this.notificationsService.notifyError(
          `There was an error updating your subscription: ${err.jqXHR.responseJSON[0]}`,
        );
      });
  },

  setActivePlans() {
    this.customerService.resetProducts();
  },

  trackAnalyticsEventforSave() {
    let removedPlans = this.findElementsRemovedFromArray(this.originalPlanIds, this.activePlanIds);
    let addedPlans = this.findElementsRemovedFromArray(this.activePlanIds, this.originalPlanIds);
    this.trackAnalyticsEvent({
      action: 'saved',
      object: this.currentSummaryView.identifier,
      removedPlans,
      addedPlans,
    });
  },

  trackAnalyticsEvent(data = {}) {
    this.purchaseAnalyticsService.trackEvent({
      place: this.place,
      context: this.context,
      view: this.currentSummaryView.identifier,
      ...data,
    });
  },

  findElementsRemovedFromArray(originalArray, editedArray) {
    return originalArray.filter((value) => editedArray.indexOf(value) === -1);
  },

  editSubscription: action(function () {
    this.trackAnalyticsEvent({ action: 'clicked', object: 'edit_subscription' });
    this.set('originalPlanIds', this.activePlanIds);
    this.customerService.resetAll();
    this.set('isEditing', true);
  }),

  revert: action(function () {
    this.trackAnalyticsEvent({ action: 'clicked', object: 'revert_edit_subscription' });
    this.customerService.resetAll();
    this.set('isEditing', false);
  }),

  unsavedChangesModalTransition: action(function () {
    // perform the previously attempted action
    this.revert();
    let previousAction = this.previousAction;
    if (previousAction) {
      this.set('previousAction', null);
      this.send(previousAction['action'], ...previousAction['params']);
    }
  }),

  openInAppMessenger: action(function () {
    this.trackAnalyticsEvent({ action: 'clicked', object: 'talk_to_sales' });
    showNewMessageInIntercomWidget('Hi, I have a question about my custom subscription.');
  }),

  seatFilterValue(pricing_metric) {
    return {
      engage_seat_count: ENGAGE_SEAT_TYPE,
      marketing_seat_count: CONVERT_SEAT_TYPE,
      proactive_support_seat_count: PROACTIVE_SUPPORT_SEAT_TYPE,
      support_seat_count: SUPPORT_SEAT_TYPE,
    }[pricing_metric];
  },

  planId(product) {
    let planId = product.activePlan.idAsNumber;
    if (!planId) {
      planId = product.plans.firstObject.idAsNumber;
    }
    return planId;
  },

  rows: computed('pricingMetric', 'planId', function () {
    return this.getRows();
  }),

  overagePrice(charge, pricingMetric) {
    if (this.contract || this.mockContractForVBP2EarlyStageWorkspaces) {
      return (
        pricingMetric.price -
        pricingMetric.basePrice -
        this.additionalPriceUnderContract(charge, pricingMetric)
      );
    }
    return pricingMetric.additionalPrice;
  },

  formatPriceFromCents(priceInCents, minimumFractionDigits = 0) {
    return this.formatPrice(priceInCents / 100, minimumFractionDigits);
  },

  formatPrice(price, minimumFractionDigits = 0) {
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits,
      maximumFractionDigits: 2,
    });
  },

  isFixedTermCharge(charge) {
    return charge?.fixed_term_charge;
  },

  planBasePrice(charges) {
    return charges
      .map((charge) => {
        let pricingMetric = new PricingMetric(charge);
        return pricingMetric.basePrice;
      })
      .reduce((a, b) => a + b, 0);
  },

  formattedActualUsage(charge, pricingMetric, tooltip = false, groupMetric = null) {
    // uncontracted
    if (tooltip) {
      return this.actualUsageTextForIncludedUsage(charge, pricingMetric);
    }
    return `${pricingMetric.formattedUsage}`;
  },

  planTitle(charge) {
    if (charge.pricing_metric === 'fixed') {
      return this.intl.t('billing.summary.plan-starting-price.fixed-price');
    } else if (this.customerService.isSelfServeAnnualCustomer) {
      let numberOfSeatsIncludedInPlan = charge.plan_limit.minimum;
      return this.intl.t('billing.summary.plan-starting-price.self-serve-annual-plans', {
        numberOfSeatsIncludedInPlan,
      });
    } else {
      return this.intl.t('billing.summary.plan-starting-price.default');
    }
  },

  actualUsage(charge, pricingMetric) {
    return this.contract ? this.contract.totalUsage[charge.pricing_metric] : pricingMetric.usage;
  },

  createBreakdownFromCharges(charges, planId, groupMetric = null) {
    return charges.map((charge) => {
      let pricingMetric = charge.pricingMetricModel;
      let metricDisplayName = capitalize(pricingMetric.pluralize(0));
      let seatFilterValue = this.seatFilterValue(charge.pricing_metric);
      let seatLabel =
        pricingMetric.metric === 'inbox_seats' ? 'Inbox' : VBP2_SEAT_LABELS[seatFilterValue];
      let filterType = seatFilterValue ? 'seat' : null;
      let isSeatMetric = pricingMetric.isSeatMetric;

      return {
        metricIdentifier: `${pricingMetric.metric}`,
        metricDisplayName,
        seatFilterValue,
        filterType,
        isSeatMetric,
        seatLabel,
        overagePrice: this.overagePrice(charge, pricingMetric),
        actualUsage: this.formattedActualUsage(charge, pricingMetric, false, groupMetric),
        actualUsageAsInt: this.actualUsage(charge, pricingMetric),
        groupMetric,
      };
    });
  },

  productBreakdownFromCharges(charges, planId) {
    if (!charges || this.isFixedTermCharge(charges.firstObject)) {
      return [];
    }

    let breakdown = [];

    // For SMS we want to display metrics in groups based on active phone numbers vs SMS segments sent in regions
    let groupedCharges = PRICING_METRIC_GROUPS.map((metricGroup) => {
      return {
        metricGroup,
        charges: charges
          .filter((charge) => charge.pricing_metric.startsWith(metricGroup))
          .sortBy('pricing_metric'),
      };
    }).filter((group) => {
      return isPresent(group.charges);
    });

    let groupedPricingMetrics = groupedCharges.flatMap((groupCharge) => {
      return groupCharge.charges.map((charge) => {
        return charge.pricing_metric;
      });
    });

    // Charges that aren't accounted for as part of a group
    let ungroupedCharges = charges.filter((charge) => {
      return !groupedPricingMetrics.includes(charge.pricing_metric);
    });
    breakdown.push(...this.createBreakdownFromCharges(ungroupedCharges, planId));

    groupedCharges.map((group) => {
      breakdown.push({
        metricIdentifier: group.metricGroup,
        groupedMetricsComponent: 'group-title',
      });
      breakdown.push(...this.createBreakdownFromCharges(group.charges, planId, group.metricGroup));
    });
    return breakdown;
  },

  amount(product) {
    return this.prices.find((price) => price.hasSamePlans([this.planId(product)])).amount;
  },

  actions: {
    setTab(id) {
      // if the user is editing and the state is dirty, then we show the unsaved changes modal when they try to switch tabs
      if (this.isEditing && this.dirty) {
        this.set('showUnsavedChangesModal', true);
        this.set('previousAction', { action: 'setTab', params: [id] });
      }
      // we transition to the selected tab
      else {
        this.set('currentTab', id);
        this.revert();
        this.setActivePlans();
      }

      this.trackAnalyticsEvent({
        action: 'clicked',
        object: 'migration_tab',
        tab: id,
      });
    },

    save() {
      if (!this.anyProductsActive) {
        return this.send('cancelSubscription');
      }
      if (this.activeNonAddonProducts.length === 0) {
        this.notificationsService.notifyError(
          'You need at least one other active product to use addons',
        );
        this.revert();
      } else {
        this.updateSubscription();
      }
      this.trackAnalyticsEventforSave();
    },

    cancelSubscription(trackEvent) {
      if (trackEvent) {
        this.trackAnalyticsEvent({
          action: 'clicked',
          object: 'cancel_subscription_button',
          section: 'billing_settings',
        });
      }

      this.router.transitionTo('apps.app.billing.cancel');
    },

    getPrice(product) {
      return this.formatPriceFromCents(this.amount(product), 2);
    },

    setProduct(product) {
      this.set('currentProduct', product);
    },

    productImage(product) {
      return PRODUCTS[product.id].productImage;
    },

    getRows(product) {
      let planId = this.planId(product);
      let charges = this.prices
        .find((price) => price.hasSamePlans([planId]))
        .breakdown.objectAt(0).charges;
      let rows = [];

      this.productBreakdownFromCharges(charges, planId).forEach(
        ({
          metricIdentifier,
          metricDisplayName,
          overagePrice,
          actualUsage,
          actualUsageAsInt,
          seatFilterValue,
          seatLabel,
          filterType,
          isSeatMetric,
          groupedMetricsComponent,
          groupMetric,
        }) => {
          if (groupedMetricsComponent) {
            rows.push({
              metricIdentifier,
              groupedMetricsComponent,
            });
          } else if (metricDisplayName) {
            if (this.planId.toString() !== ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID) {
              // this row shows the current usage for given metric
              // - for non-contracted workspaces it shows the actual usage and the price for the overage if any
              // - for contracted workspaces it shows the actual usage in relation to the included usage and the price
              //   for the overage if any
              if (actualUsage) {
                rows.push({
                  metricDisplayName,
                  name: 'Current usage',
                  price: overagePrice ? `+${this.formatPriceFromCents(overagePrice, 2)}` : null,
                  usage: actualUsage,
                  includeLinkToTeammatesPage:
                    isSeatMetric && this.shouldLinkToTeammatesPageOnCurrentUsageRow,
                  seatFilterValue,
                  seatLabel,
                  groupedMetricsComponent: groupMetric ? 'group-current-usage-row' : null,
                  filterType,
                  rowType: 'usage-detail',
                  testIdentifier: `actual-usage-${metricIdentifier}`,
                  showLine: overagePrice && !this.shouldHidePrices,
                  metricIdentifier,
                  isSalesforceContracted: this.app.isSalesforceContracted,
                  actualUsageAsInt,
                });
              }
            }
          }
        },
      );
      return rows;
    },
  },
});
