/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { task, taskGroup } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import EmbercomBootDataModule from 'embercom/lib/embercom-boot-data';
import ajax from 'embercom/lib/ajax';
import { readOnly } from '@ember/object/computed';

export default Component.extend({
  attributeBindings: ['data-test-app-package-modal'],
  'data-test-app-package-modal': true,
  router: service(),
  intercomEventService: service(),
  store: service(),
  appstoreService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  isBootedInPublicAppStoreMode: EmbercomBootDataModule.isBootedInPublicAppStoreMode(),
  appPackageTasks: taskGroup().enqueue(),
  showReinstallModal: false,

  init() {
    this._super(...arguments);
    this.fetchRecommendations.perform();
    this.updateViewHistory();
  },

  click(e) {
    //@pat - When the app package modal is open in the Inbox, Messenger Home etc we prevent the messenger-framework/app-inserter popover from closing on clicks using hideOnOutsideClick=false. An issue exists where if we close the modal using the close button, hideOnOutsideClick flips to `true`, the click event propagates and causes the app-inserter to auto-close. To keep the app-inserter open when the modal closes we stop any click event propagation caused by clicking the modal close button.
    if ($(e.target).closest('.js__modal-close').length) {
      e.stopPropagation();
    }
  },

  didReceiveAttrs() {
    this._super(...arguments);

    if (this.appPackage) {
      // Need to check if the app package is available before tracking the view event.
      // The modal is initialised without an app package in the app inserter popup.
      // The app package is set when the user clicks on a list item.
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: this.appPackage,
      });
    }
  },

  willRender() {
    this._super(...arguments);

    if (this._shouldLoadAppPackage()) {
      this.set('recommendations', null);
      this.loadAppPackage.perform();
      this.fetchRecommendations.perform();
    }
  },

  _shouldLoadAppPackage() {
    if (!this.appstoreService.hasAppPackageCode()) {
      return false;
    }
    let appPackageCode = this.appstoreService.getRedirectQueryParams().app_package_code;
    return this.get('appPackage.id') !== appPackageCode;
  },

  loadAppPackage: task(function* () {
    let { app_package_code } = this.appstoreService.getRedirectQueryParams();
    let appPackage = yield this.store.findRecord('appstore/app-package', app_package_code);
    this.set('appPackage', appPackage);
  }).group('appPackageTasks'),

  updateViewHistory() {
    if (this.isBootedInPublicAppStoreMode) {
      return;
    }
    ajax({
      url: '/ember/appstore/app_packages/viewed',
      type: 'POST',
      data: JSON.stringify({
        app_package_code: this.get('appPackage.id'),
        app_id: this.get('app.id'),
      }),
    });
  },

  fetchRecommendations: task(function* () {
    if (!this.showRecommendations) {
      return;
    }
    let urlPath = this.isBootedInPublicAppStoreMode
      ? '/appstore/app_packages/recommendations'
      : '/app_package_recommendations';

    let recommendationAppCodes = yield ajax({
      url: `${EmbercomBootDataModule.getApiUrl()}${urlPath}`,
      type: 'GET',
      data: {
        app_package_code: this.get('appPackage.id'),
        app_id: this.get('app.id'),
        count: 4,
      },
    });

    let recommendations = yield this.appstoreService.resolveAppPackageCodes(recommendationAppCodes);
    this.set('recommendations', recommendations);
  }).group('appPackageTasks'),

  onAppCardClick: action(function (appPackage) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'recommended_app_package',
      sourceAppPackageCode: this.get('appPackage.id'),
      recommendedAppPackageCode: appPackage.get('id'),
    });
    this.setProperties({
      recommendations: null,
      appPackage,
    });
    this.updateViewHistory();
    this.fetchRecommendations.perform();
    this.element.scrollTop = 0;
    this.router.transitionTo({
      queryParams: { app_package_code: appPackage.get('id') },
    });
  }),
});
