/* import __COLOCATED_TEMPLATE__ from './edit-modal-card.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { FIN_AI_COPILOT_BASE_ID } from 'embercom/lib/billing';

export default class EditModalCard extends Component {
  @service customerService;
  @service intl;
  @service appService;

  get marketingName() {
    let plan = this.args.plan;
    if (plan.marketingNameTranslationKeyWithYearParam) {
      return this.intl.t(plan.marketingNameTranslationKeyWithYearParam, {
        year: this.earlyStageGraduation.nextStepYearNumber,
      });
    }
    // In some cases, the plan is an Proxy object, and we have to use get() to access the properties,
    // this wont work if the plan is a plain object, so we have to check for that first
    let translationKey = plan.get
      ? plan.get('marketingNameTranslationKey')
      : plan.nameTranslationKey;
    return this.intl.t(translationKey);
  }

  get isSelfServe() {
    return this.customerService.plans.find((plan) => plan.id === this.args.plan.id).selfServe;
  }

  get discountedEarlyStagePrice() {
    return this.nonDiscountedEarlyStagePrice - this.earlyStageDiscount;
  }

  get earlyStageDiscount() {
    return this.nonDiscountedEarlyStagePrice * this.earlyStageDiscountPercentage;
  }

  get nonDiscountedEarlyStagePrice() {
    let preDiscountAmount = this.earlyStagePlansPrice.preDiscountAmount / 100;
    if (this.earlyStageGraduation?.pricing5_X_Graduation) {
      // The early stage price is already discounted.
      // So we need to divide by the discount percentage to get the full amount.
      preDiscountAmount /= this.earlyStageDiscountPercentage;
    }
    return preDiscountAmount;
  }

  get earlyStagePlansPrice() {
    return this.customerService.getPriceFromPlanIds(
      this.sortedEarlyStagePlans,
      this.earlyStageGraduation.pricingModelForEarlyStagePlan,
    );
  }

  get earlyStageDiscountPercentage() {
    return this.earlyStageGraduation.earlyStageStep.nextStepConfig.couponPercentOff / 100;
  }

  get earlyStageGraduation() {
    return this.args.earlyStageGraduation;
  }

  get sortedEarlyStagePlans() {
    return this.earlyStageGraduation.earlyStagePlanIds.sort(function (a, b) {
      return a - b;
    });
  }

  get priceForPlanOnRelevantPricingModel() {
    return this.customerService.getPriceFromPlanIds(
      [this.args.plan.idAsNumber],
      this.earlyStageGraduation?.graduationPricingModel,
    );
  }

  get nonEarlyStagePriceForPlan() {
    if (this.args.plan.id === FIN_AI_COPILOT_BASE_ID) {
      return this.priceForPlanOnRelevantPricingModel?.formattedPerUnitPrice;
    }
    return this.priceForPlanOnRelevantPricingModel?.formattedStartingPrice;
  }

  get removing() {
    return this.args.inCurrentSubscription && this.args.isSelected;
  }

  get adding() {
    return this.args.isSelected && !this.removing;
  }

  get inCurrentSubscriptionNotSelected() {
    return this.args.inCurrentSubscription && !this.args.isSelected;
  }

  get buttonLabel() {
    if (this.inCurrentSubscriptionNotSelected) {
      return this.intl.t('billing.edit-modal-card.current-plan');
    } else if (this.removing || this.args.isRemoving) {
      return this.intl.t('billing.edit-modal-card.add-back');
    } else if (this.args.isSelected) {
      return this.intl.t('billing.edit-modal-card.selected');
    } else {
      return this.intl.t('billing.edit-modal-card.select');
    }
  }

  get buttonIcon() {
    return this.inCurrentSubscriptionNotSelected || this.adding ? 'check' : '';
  }

  get buttonIconColor() {
    return this.args.disabled ? 'gray' : 'blue';
  }
}
