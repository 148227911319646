/* import __COLOCATED_TEMPLATE__ from './lightbox-image.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import { map } from '@ember/object/computed';

const RIGHT_CHAR_CODE = 39;
const LEFT_CHAR_CODE = 37;
const ESCAPE_CHAR_CODE = 27;

export default Component.extend({
  intercomEventService: service(),
  eventListenerStack: service(),
  keyDownEventListener: null,

  tagName: '',

  isVisible: false,
  currentIndex: 0,
  currentImage: computed('imageUrls', 'currentIndex', function () {
    return this.imageUrls[this.currentIndex];
  }),
  items: map('imageUrls', (src, index) => ({
    src,
    index,
  })),

  _handleKeypress(event) {
    let keyCode = event.which;
    let maxIncrement = this.get('imageUrls.length') - 1;
    let currentIndex = this.currentIndex;

    if (keyCode === LEFT_CHAR_CODE && currentIndex > 0) {
      this.decrementProperty('currentIndex');
      this._trackAnalyticsEvent('pressed_left_key');
    } else if (keyCode === RIGHT_CHAR_CODE && currentIndex < maxIncrement) {
      this.incrementProperty('currentIndex');
      this._trackAnalyticsEvent('pressed_right_key');
    } else if (keyCode === ESCAPE_CHAR_CODE) {
      this._hideLightbox();
      this._trackAnalyticsEvent('closed_with_key');
    }
  },

  _hideLightbox() {
    if (!(this.isDestroyed || this.isDestroying)) {
      this.eventListenerStack._removeEventListener('keydown', this.keyDownEventListener);
      this.set('isVisible', false);
    }
  },

  _trackAnalyticsEvent(action) {
    if (this.analyticsObject) {
      this.intercomEventService.trackAnalyticsEvent({
        object: this.analyticsObject,
        action,
        place: 'lightbox_image',
        lightbox_index: this.currentIndex,
      });
    }
  },

  hideLightbox: action(function () {
    this._hideLightbox();
    this._trackAnalyticsEvent('closed_with_click');
  }),

  showLightbox: action(function (item = {}) {
    this.keyDownEventListener = this.eventListenerStack.pushEventListener(
      this,
      'keydown',
      this._handleKeypress,
    );
    this.setProperties({
      isVisible: true,
      currentIndex: item.index || 0,
    });
    this._trackAnalyticsEvent('viewed');
  }),
});
