/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';

let INITIALIZATION_URL = '/ember/inbox_apps/initialize_messenger_app';

export default Component.extend({
  appService: service(),
  messengerAppInsertionService: service(),

  init() {
    this._super(...arguments);
    this.messengerAppInsertionService.on(
      'initializeMessengerApp',
      this,
      this.initializeMessengerApp,
    );
  },

  willDestroy() {
    this._super(...arguments);
    this.messengerAppInsertionService.off(
      'initializeMessengerApp',
      this,
      this.initializeMessengerApp,
    );
  },

  initializeMessengerApp(inboxAppId, canvasCreationOptions, resolve, reject) {
    this.initializeMessengerAppTask.perform(inboxAppId, canvasCreationOptions, resolve, reject);
  },

  initializeMessengerAppTask: task(function* (inboxAppId, canvasCreationOptions, resolve, reject) {
    try {
      let actionData = yield post(INITIALIZATION_URL, {
        app_id: this.appService.app.id,
        inbox_app_id: inboxAppId,
        context: {
          conversation_id: this.conversation.id,
        },
        card_creation_params: {
          canvas_creation_options: canvasCreationOptions,
        },
      });
      this.controlsAPI.actions.insertBlockInCommentPane('messengerCard', actionData);
      this.messengerAppInsertionService.updateInboxAppCanvas(inboxAppId, resolve, reject, null);
    } catch (error) {
      this.messengerAppInsertionService.updateInboxAppCanvas(inboxAppId, resolve, reject, error);
    }
  }),
});
