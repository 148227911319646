/* import __COLOCATED_TEMPLATE__ from './future-seats-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import type AdminSeatMappingFragment from 'embercom/models/billing/migration-admin-seat-mapping';
import type Admin from 'embercom/models/admin';
import { action } from '@ember/object';
import {
  PRICING_5_X_FULL_SEAT_TYPE as CORE,
  PRICING_5_X_LITE_SEAT_TYPE as LITE,
  COPILOT_SEAT_TYPE as COPILOT,
  PRICING_5_X_SEAT_LABELS,
} from 'embercom/lib/settings/seats/constants';
import { PRICING_5_X_CORE_ESSENTIAL_ID } from 'embercom/lib/billing';

interface Args {
  adminSeatMapping: AdminSeatMappingFragment;
  selectedPlanId: string;
  admins: Admin[];
}

interface Signature {
  Args: Args;
}

export default class SeatAssignmentFutureSeatsCell extends Component<Signature> {
  get isEssentialPlanSelected() {
    return this.args.selectedPlanId === PRICING_5_X_CORE_ESSENTIAL_ID;
  }

  get newSeatTypeOptions() {
    return [
      { label: PRICING_5_X_SEAT_LABELS[CORE], value: CORE, isDisabled: false },
      {
        label: PRICING_5_X_SEAT_LABELS[LITE],
        value: LITE,
        isDisabled: this.isEssentialPlanSelected,
      },
    ];
  }

  get selectedValue() {
    if (this.isEssentialPlanSelected) {
      return CORE;
    }

    return this.args.adminSeatMapping.seatTypes.includes(LITE) ? LITE : CORE;
  }

  get admin() {
    return this.args.admins.find(
      (admin: Admin) => admin.id === this.args.adminSeatMapping.adminId.toString(),
    );
  }

  @action
  toggleNewSeatType(newSeatType: string) {
    let newSeatTypes = [newSeatType];
    if (newSeatType === CORE && this.admin?.seats.includes(COPILOT)) {
      newSeatTypes.push(COPILOT);
    }
    this.args.adminSeatMapping.seatTypes = newSeatTypes;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::SeatAssignment::FutureSeatsCell': typeof SeatAssignmentFutureSeatsCell;
  }
}
