/* import __COLOCATED_TEMPLATE__ from './contract-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class ContractDetails extends Component {
  @service appService;
  @service customerService;

  get app() {
    return this.appService.app;
  }

  get adminCanAccessPrimaryWorkspace() {
    return this.app.currentAdmin.apps.some((app) => app.id === this.primaryAppIdCode);
  }

  get primaryAppIdCode() {
    return this.args.contract.primaryWorkspaceAppIdCode;
  }

  get stripeMigration() {
    return this.customerService.peekStripeMigration;
  }

  get stripeMigrationScheduled() {
    return this.stripeMigration?.isPending;
  }

  get showCancelledStamp() {
    if (this.stripeMigrationScheduled) {
      return false;
    }

    return this.args.contract?.cancellationScheduled && !this.args.contract?.inRenewalPeriod;
  }

  get showCtaBanner() {
    if (this.stripeMigrationScheduled) {
      return false;
    }

    return (
      this.app.canSelfManageContract &&
      (this.args.contract?.inAutoRenewalPeriod || this.args.contract?.cancellationScheduled)
    );
  }
}
