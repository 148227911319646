/* import __COLOCATED_TEMPLATE__ from './welcome-message.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type HelpCenterSettingNavigationHelper } from '../main';
import { inject as service } from '@ember/service';

interface Args {
  site: HelpCenterSite;
  navigationHelper: HelpCenterSettingNavigationHelper;
}

export default class WelcomeMessage extends Component<Args> {
  @service attributeService: any;
  @action
  goToConfigLanguage(): void {
    this.args.navigationHelper.navigate('language', 'general');
  }

  get manualAppAttributes() {
    return this.attributeService.greetingComposerAttributes;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Content::WelcomeMessage': typeof WelcomeMessage;
    'articles/site/settings/content/welcome-message': typeof WelcomeMessage;
  }
}
