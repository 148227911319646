/* import __COLOCATED_TEMPLATE__ from './conversation-card.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import Velocity from 'velocity';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

const VIEWPORT_SELECTOR = '[data-reports-scroll-container]';
const SCROLL_OFFSET = -50;

export default class ConversationsCard extends Component {
  @service store;
  @service intercomEventService;
  @service conversationsService;
  @service conversationCloseService;
  @service appService;
  @tracked cardIsActive = false;

  lastAdminAssigned = this.args.isInbox
    ? this.args.conversation.assignee
    : this.args.conversation.partAuthor;

  mainConverstionUser = this.args.isInbox
    ? this.args.conversation.main_participant
    : this.args.conversation.user;

  convCreatedTimeStamp = this.args.isInbox
    ? this.args.conversation.timestamp
    : this.args.conversation.partToShow.created_at;

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.app.currentAdmin;
  }

  get otherMentionCount() {
    return this.args.conversation.matching_parts.length - 1;
  }

  get isExpanding() {
    return this.args.expandingConversationId === this.args.conversation.id;
  }

  get isExpanded() {
    return (
      this.isExpanding &&
      this.args.expandedConversation?.id === this.args.conversation.id.toString()
    );
  }

  get isMentionsInbox() {
    return this.args.inbox?.isMentionsInbox;
  }

  get datetime() {
    if (this.args.inbox?.isSortingByWaitTime) {
      return this.args.conversation.computedWaitingSince;
    }
    if (this.isMentionsInbox) {
      return this.args.conversation.last_part_where_current_admin_is_mentioned?.created_at;
    }
    return this.args.conversation.timestamp;
  }

  get inMentionsInboxAndUnread() {
    return this.isMentionsInbox && this.args.conversation.has_unread_mentions;
  }

  get notMentionsInboxAndUnreadForAdmin() {
    return !this.isMentionsInbox && this.args.conversation.adminHasNotRead;
  }

  get isUnread() {
    return this.inMentionsInboxAndUnread || this.notMentionsInboxAndUnreadForAdmin;
  }

  get shouldHaveVisibleIcon() {
    return (
      (this.cardIsActive && !this.isMentionsInbox && !this.args.isInSingleView) ||
      this.args.isSelected
    );
  }

  get shouldHaveVisibleCheckbox() {
    return this.shouldHaveVisibleIcon || this.args.showCheckboxes;
  }

  get shouldHaveVisibleCloseConversation() {
    return this.shouldHaveVisibleIcon || this.args.showsIconOnly;
  }

  closeConversation() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      object: 'conversation',
      place: 'inbox',
      context: 'from_conversation_list',
    });
    if (this.args.isSelected) {
      this.args.checkboxUp(new Event('mouseup'));
    }
    this.conversationCloseService.closeConversation(this.args.conversation);
  }

  reopenConversation() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'conversation',
      place: 'inbox',
      context: 'from_conversation_list',
    });
    this.conversationsService.reopenConversation(this.args.conversation);
  }

  @task({ restartable: true })
  *expandCard(element) {
    let contentExpanded = element.querySelector('[data-conversation-card-content-expanded]');
    if (contentExpanded) {
      yield Velocity(contentExpanded, 'stop');
      yield Velocity(contentExpanded, { maxHeight: '70vh' }, { duration: 500 });
    }
  }

  @task({ restartable: true })
  *scrollToCard(element) {
    let viewportElement = document.querySelector(VIEWPORT_SELECTOR);
    if (viewportElement) {
      yield Velocity(viewportElement, 'stop');
      yield Velocity(element, 'scroll', {
        duration: 500,
        offset: SCROLL_OFFSET,
        container: viewportElement,
      });
    }
  }

  @task({ restartable: true })
  *onCardExpand(element) {
    yield this.expandCard.perform(element);
    yield this.scrollToCard.perform(element);
  }

  @action
  clickRemoveConversation() {
    this.args.removeConversation(this.args.conversation.id);
  }

  @action
  onClick() {
    if (!this.args.conversation.restricted && !this.args.isInbox) {
      this.args.expandConversation(this.args.conversation.id);
      this.intercomEventService.trackEvent('viewed-conversation-in-topics');
    }
  }

  @action
  toggleConversationState(e) {
    e.stopPropagation();
    e.preventDefault();
    if (this.args.conversation.isNotClosed) {
      return this.closeConversation();
    }
    return this.reopenConversation();
  }
}
