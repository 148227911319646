/* import __COLOCATED_TEMPLATE__ from './contact-support.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ContactSupport extends Component {
  @service intercomEventService;

  @action
  openMessenger(event) {
    event.preventDefault();
    showNewMessageInIntercomWidget();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `get_in_touch_with_intercom_link`,
      app_package_code: this.appPackage.id,
    });
  }
}
