/* import __COLOCATED_TEMPLATE__ from './cancel-subscription.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { startSurvey } from 'embercom/lib/intercom-widget-helper';
import { SELF_SERVE_CHURN_SURVEY_ID } from 'embercom/lib/billing/surveys';

export default class CancelSubscription extends Component {
  @service appService;
  @service router;
  @service pauseSubscriptionModalService;

  get app() {
    return this.appService.app;
  }
  get isPauseSubscriptionModalOpen() {
    return this.pauseSubscriptionModalService.isOpen;
  }

  get isPauseSubscriptionEnabled() {
    return this.app.canPauseEarlyStageSubscription;
  }

  @action
  closePauseSubscriptionModal() {
    this.pauseSubscriptionModalService.close();
  }

  @action
  cancelSubscription() {
    if (this.pauseSubscriptionModalService.canPauseEarlyStageSubscription) {
      this.pauseSubscriptionModalService.open();
    } else if (this.app.canSeeSelfServeChurnSurvey) {
      // The last step of the new self serve churn Survey will
      // redirect customers to their cancellation page.
      startSurvey(SELF_SERVE_CHURN_SURVEY_ID);
    } else {
      this.router.transitionTo('apps.app.billing.cancel'); // Fallback for AU / EU workspaces
    }
  }
}
