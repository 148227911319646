/* import __COLOCATED_TEMPLATE__ from './action-url.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { inject as service } from '@ember/service';
import AttributeInfoResolver, {
  APP_NAME_ATTRIBUTE,
} from 'embercom/lib/common/template-attribute-resolver';

class ComposerConfig extends BaseConfig {
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph', 'heading'];
  allowedInline = [];
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = false;
  singleBlockMode = true;
  isPlaintext = true;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  experimental = {
    hideInsertersOnMouseOut: false,
  };

  constructor({ placeholder, resolver }) {
    super();

    this.placeholder = placeholder;
    this.templating = { picker: 'common/attribute-picker', resolver };
  }
}

export default class ActionUrl extends Component {
  blocksDocument;
  composerConfig;

  @service appService;
  @service attributeService;
  @service intl;

  constructor() {
    super(...arguments);
    this.blocksDocument = new BlocksDocument(this.actionUrlBlocks);

    this.composerConfig = new ComposerConfig({
      placeholder: this.intl.t('outbound.banners.enter-url-address'),
      resolver: this.resolver,
    });
  }

  get bannerContent() {
    return this.args.localizedBannerContent;
  }

  get actionUrlBlocks() {
    return [
      {
        type: 'paragraph',
        text: this.bannerContent.actionHref ?? '',
      },
    ];
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.bannerActionUrlComposerAttributes,
      manualAppAttributes: [APP_NAME_ATTRIBUTE],
    });
  }

  @action updateActionUrl(blocksDoc) {
    let blockText = blocksDoc.blocks.firstObject?.text ?? '';
    let textContent = htmlToTextContent(blockText);
    blockText = textContent.trim();
    this.bannerContent.actionHref = blockText;
  }
}
