/* import __COLOCATED_TEMPLATE__ from './link-item.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import EmbercomBootDataModule from 'embercom/lib/embercom-boot-data';
import { computed } from '@ember/object';

export default Component.extend({
  appstoreService: service(),

  _getCapabilitiesParams() {
    return {
      capability: this.capability || null,
      inbox_capability: this.capability === 'inbox' ? this.inboxCapability : null,
    };
  },

  params: computed('isInstalledLink', 'category', 'capability', 'inboxCapability', function () {
    let values = {
      search: null,
      category: this.category || null,
      ...this._getCapabilitiesParams(),
    };
    let isPrivateAppStore = EmbercomBootDataModule.isBootedInPublicAppStoreMode() === false;
    if (isPrivateAppStore) {
      values.installed = this.isInstalledLink || null;
    }
    return { route: this.appstoreService.baseRoute, query: values };
  }),

  dataAttributes: computed(
    'isFeaturedCategory',
    'category',
    'capability',
    'isInstalledLink',
    function () {
      let dataAttributes = (listItemType, eventObject, propertyName) => ({
        listItemType,
        eventObject,
        propertyName,
      });

      if (this.isFeaturedCategory) {
        return dataAttributes('featured-category', 'app_store_category', 'trackingEventData');
      } else if (this.category) {
        return dataAttributes('category', 'app_store_category', 'trackingEventData');
      } else if (this.capability) {
        return dataAttributes('capability', 'app_store_capability', 'trackingEventData');
      } else if (this.isInstalledLink) {
        return dataAttributes('installed-apps', 'app_store_installed', 'trackingEventData');
      }
      return dataAttributes('all-collections', 'app_store_category', 'trackingEventDataForAllLink');
    },
  ),
});
