/* import __COLOCATED_TEMPLATE__ from './inline-banner.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    type?: 'confirmation' | 'error' | 'help' | 'warning';
    icon?: string;
    dataTestId?: string;
  };
  Blocks: {
    default: any;
  };
}

const InlineBanner = templateOnlyComponent<Signature>();
export default InlineBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::InlineBanner': typeof InlineBanner;
  }
}
