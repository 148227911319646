/* import __COLOCATED_TEMPLATE__ from './tabs-component.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

const tabs = {
  answer: {
    isPercentageValue: false,
    views: ['answer'],
    color: 'text-gray',
    icon: 'chat-bubble',
    isClickable: true,
  },
  answer_sent: {
    isPercentageValue: false,
    views: ['answer_sent'],
    color: 'text-blue',
    isClickable: true,
  },
  all_done: {
    isPercentageValue: true,
    views: ['all_done'],
    color: 'text-green',
    isClickable: true,
  },
  abandoned: {
    isPercentageValue: true,
    views: ['abandoned'],
    color: 'text-vis-salmon-40',
    isClickable: true,
  },
  wait_for_the_team: {
    isPercentageValue: true,
    views: ['wait_for_the_team'],
    color: 'text-yellow-dark',
    isClickable: true,
  },
};

export default class TabsComponent extends Component {
  @service intl;
  @service appService;

  _buildTab(name, label, statistic = '') {
    return Object.assign({}, tabs[name], {
      label,
      statistic,
    });
  }

  get stats() {
    return this.args.answer.stats;
  }

  get tabs() {
    let tabs = [];
    tabs.pushObject(
      this._buildTab('answer', this.intl.t('operator.resolution-bot-answers.table.answer')),
    );
    tabs.pushObject(
      this._buildTab(
        'answer_sent',
        this.intl.t('operator.resolution-bot-answers.table.sent'),
        this.stats?.conversationCount,
      ),
    );
    tabs.pushObject(
      this._buildTab(
        'all_done',
        this.intl.t('operator.resolution-bot-answers.table.resolved'),
        this.stats?.effectivenessPercentage,
      ),
    );

    if (this.stats?.abandonedCount !== 0) {
      tabs.pushObject(
        this._buildTab(
          'abandoned',
          this.intl.t('operator.resolution-bot-answers.table.abandoned'),
          this.stats?.abandonedPercentage,
        ),
      );
    }
    tabs.pushObject(
      this._buildTab(
        'wait_for_the_team',
        this.intl.t('operator.resolution-bot-answers.table.routed'),
        this.stats?.waitForTeamPercentage,
      ),
    );
    return tabs;
  }
}
