/* import __COLOCATED_TEMPLATE__ from './sms-multi-workspace.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { formatPriceFromCents } from 'embercom/components/billing/usage/tooltips/format-price';

interface Args {
  usage: number;
  allWorkspaceUsage: number;
  totalCost: number;
}

export default class SmsMultiWorkspace extends Component<Args> {
  @service declare intl: IntlService;

  get sections() {
    let { usage, allWorkspaceUsage, totalCost } = this.args;
    return [
      {
        label: 'SMS',
        metrics: [
          {
            label: this.intl.t(
              'billing.usage.tooltip.sms-multi-workspace.sms-usage-for-this-workspace',
            ),
            muted: true,
          },
          {
            label: usage,
          },
          {
            label: this.intl.t(
              'billing.usage.tooltip.sms-multi-workspace.sms-usage-for-all-workspaces',
            ),
            muted: true,
          },
          {
            label: allWorkspaceUsage,
          },
          {
            label: this.intl.t('billing.usage.tooltip.sms-multi-workspace.total-cost'),
            mutedLabel: true,
            value: formatPriceFromCents(totalCost, this.intl),
            mutedValue: false,
          },
        ],
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::Tooltips::SmsMultiWorkspace': typeof SmsMultiWorkspace;
  }
}
