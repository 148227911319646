/* import __COLOCATED_TEMPLATE__ from './basics.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import type TaskBots from 'embercom/models/operator-settings/task-bots';

interface Args {
  forRole: 'users' | 'leads';
  openSection: string | null;
  openSectionChange: (sectionId: string) => void;
  settings: TaskBots;
  previewConfiguration: any;
}

export default class AutomationBasicsComponent extends Component<Args> {
  @service permissionsService: any;

  get groupedTasks() {
    if (this.args.forRole === 'users') {
      return [
        ['upfront-collection-users', 'team-availability-users', 'article-suggestions-users'],
        ['conversation-rating-users'],
      ];
    }
    return [
      ['upfront-email-collection'],
      [
        'team-availability-leads',
        'conversation-routing',
        'contact-collection',
        'qualify-leads',
        'article-suggestions-leads',
      ],
      ['conversation-rating-leads'],
    ];
  }
  get previewableTasks() {
    // eslint-disable-next-line prefer-let/prefer-let
    const { openSection } = this.args;
    if (openSection === null) {
      return this.groupedTasks[0];
    }
    let currentGroup = this.groupedTasks.find((group) => group.includes(openSection));
    if (!currentGroup) {
      return this.groupedTasks[0];
    }
    let currentTaskIndex = currentGroup.indexOf(openSection);
    return currentGroup.slice(0, currentTaskIndex + 1);
  }

  @task({ drop: true })
  *updateUserDelayEnabled() {
    try {
      yield this.permissionsService.checkPermission('can_create_and_edit_bots');
    } catch (e) {
      return;
    }
    this.args.settings.userDelayEnabled = !this.args.settings.userDelayEnabled;
    yield ajax({
      url: `/ember/operator_settings/user_delay/${this.args.settings.id}`,
      type: 'PUT',
      data: JSON.stringify({
        user_delay_enabled: this.args.settings.userDelayEnabled,
      }),
    });
  }

  @task({ drop: true })
  *updateLeadDelayEnabled() {
    try {
      yield this.permissionsService.checkPermission('can_create_and_edit_bots');
    } catch (e) {
      return;
    }
    this.args.settings.leadDelayEnabled = !this.args.settings.leadDelayEnabled;
    yield ajax({
      url: `/ember/operator_settings/lead_delay/${this.args.settings.id}`,
      type: 'PUT',
      data: JSON.stringify({
        lead_delay_enabled: this.args.settings.leadDelayEnabled,
      }),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Automation::Basics': typeof AutomationBasicsComponent;
    'automation/basics': typeof AutomationBasicsComponent;
  }
}
