/* import __COLOCATED_TEMPLATE__ from './horizontal-select-item.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { not, notEmpty } from '@ember/object/computed';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  classNames: ['f__horizontal-select', 'flex-auto'],
  classNameBindings: [
    'disabled:o__disabled',
    'selected:o__selected',
    'jsBindingClass',
    'testBindingClasses',
  ],
  disabled: false,
  disabledTooltipMessagePresent: notEmpty('disabledTooltipMessage'),
  deselected: not('selected'),
  onClick: notImplemented('onClick action is required'),

  mouseEnterHandler: () => {},
  mouseExitHandler: () => {},

  click() {
    if (this.disabled) {
      return;
    }
    this.onClick();
  },

  mouseEnter() {
    this.mouseEnterHandler();
  },

  mouseLeave() {
    this.mouseExitHandler();
  },
});
