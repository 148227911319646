/* import __COLOCATED_TEMPLATE__ from './overage-breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { capitalize } from '@ember/string';
import type IntlService from 'embercom/services/intl';
import type Contract from 'embercom/models/billing/contract';
import type Charge from 'embercom/models/billing/price/charge';
import type Store from '@ember-data/store';
interface Args {
  testIdentifier: string;
  pricingMetric: PricingMetric;
  charge: Charge;
  contract?: Contract;
}

export default class OverageBreakdown extends Component<Args> {
  @service declare intl: IntlService;
  @service declare customerService: any;
  @service declare store: Store;

  get mockContractForVBP2EarlyStageWorkspaces() {
    return this.customer.currentlyOnValueBasedPricingMar2021EarlyStage;
  }

  get customer() {
    return this.customerService.customer;
  }

  get formattedActualUsage() {
    let { charge, contract } = this.args;

    if (!charge.isValidUsageMetric) {
      return;
    } else if (
      (contract && !contract?.isSecondarySubscription) ||
      this.mockContractForVBP2EarlyStageWorkspaces
    ) {
      return this.actualUsageTextForIncludedUsage;
    } else if (contract?.isSecondarySubscription) {
      let usageAmount = contract?.perWorkspaceUsage.find(
        (workspace) => workspace.app_id === this.customer.app.id,
      )!.usage[charge.pricing_metric];
      return this.intl.formatNumber(usageAmount);
    }

    return this.actualUsageTextForIncludedUsage;
  }

  get actualUsageTextForIncludedUsage() {
    let { charge, pricingMetric } = this.args;

    if (!charge.actualUsage) {
      return this.intl.formatNumber(0);
    }

    if (charge.includedUsage === 0) {
      return this.intl.formatNumber(charge.actualUsage);
    } else if (pricingMetric.isSeatMetric) {
      return this.intl.t('billing.summary.price-usage-breakdown-card.actual-of-included-usage', {
        actualUsage: this.intl.formatNumber(charge.actualUsage),
        includedUsage: this.intl.formatNumber(charge.includedUsage),
      });
    } else {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.actual-of-included-usage-percent',
        {
          percentUsed: this.intl.formatNumber(charge.actualUsage / charge.includedUsage, {
            style: 'percent',
            maximumFractionDigits: 0,
          }),
          actualUsage: this.intl.formatNumber(charge.actualUsage),
          includedUsage: this.intl.formatNumber(charge.includedUsage),
        },
      );
    }
  }

  formattedOverageFormula(formula: string[]) {
    if (formula.length === 1) {
      return formula[0];
    }
    let finalText = '';
    formula.forEach((textPart, index) => {
      finalText = finalText.concat(`(${textPart})`);
      if (index < formula.length - 1) {
        finalText = finalText.concat(' + ');
      }
    });
    return finalText;
  }

  tierRows(pricingMetric: PricingMetric, lowestTierId: string, highestTierId: string) {
    let filteredTiers = pricingMetric.tiers
      ?.filter(({ id }: any) => id <= highestTierId && id >= lowestTierId)
      ?.filter(
        ({ starting_unit, ending_unit, price }: any) =>
          !(price === 0 && starting_unit === 0 && ending_unit === 0),
      );

    return filteredTiers.map(
      ({ id, starting_unit, ending_unit, price, price_format }: any, index: number) => {
        if (pricingMetric.isSeatMetric) {
          return { label: this.formatPriceFromCents(pricingMetric.perUnitPrice) };
        }
        let value;
        if (price_format === 'PerUnit') {
          if (this.customerService.onPricing5_X && pricingMetric.blockSize === 1) {
            value = this.formatPrice(price);
          } else {
            value = this.intl.t('billing.summary.price-usage-breakdown-card.tier-price-per-block', {
              price: this.formatPrice(price * pricingMetric.blockSize),
              blockSize: this.intl.formatNumber(pricingMetric.blockSize),
            });
          }
        } else if (price_format === 'FlatFee') {
          value = this.formatPrice(price);
        }

        let label = this.intl.t(
          'billing.summary.price-usage-breakdown-card.tier-price-per-range-label',
          {
            startingUnit: this.intl.formatNumber(starting_unit),
            endingUnit: this.intl.formatNumber(ending_unit),
          },
        );
        if (pricingMetric.tiers.lastObject && id === pricingMetric.tiers.lastObject.id) {
          // Don't show upper bound for top tier because the upper bounds in Zuora are arbitrary
          label = this.intl.t('billing.summary.price-usage-breakdown-card.top-tier-price-label', {
            topTierStart: this.intl.formatNumber(starting_unit - 1),
          });
        }

        return {
          label,
          value,
          noPadding: filteredTiers.length - index > 1,
        };
      },
    );
  }

  get tierInfo() {
    let { pricingMetric, charge, contract } = this.args;
    if (pricingMetric.tiers?.length > 0) {
      let highestTierId = pricingMetric.currentTier.id;
      let lowestTierId;
      if (charge.charge_model === 'Volume') {
        lowestTierId = highestTierId;
      } else {
        let lowestTierIdWithoutBaseUsageTier = charge.lowestTierWithoutBaseUsage;
        let includedUsageLimitTierId =
          contract || this.mockContractForVBP2EarlyStageWorkspaces
            ? charge.tierIdBasedOnUsage(charge.includedUsage)
            : lowestTierIdWithoutBaseUsageTier;
        lowestTierId = Math.max(includedUsageLimitTierId, lowestTierIdWithoutBaseUsageTier);
      }

      let tierRows = this.tierRows(pricingMetric, lowestTierId, highestTierId);
      if (tierRows.length === 1 && tierRows[0].value && !this.customerService.onPricing5_X) {
        return [
          {
            label: `${tierRows[0].label} (${tierRows[0].value})`,
            small: false,
          },
        ];
      }
      return tierRows.filter(Boolean);
    } else {
      // Treat non-tiered metrics as having a single per unit tier
      return [{ label: this.formatPriceFromCents(pricingMetric.perUnitPrice) }];
    }
  }

  get costHeading() {
    let { pricingMetric, charge } = this.args;
    if (charge.charge_model === 'Volume') {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.current-tier',
      );
    }
    if (charge.isSeatMetric) {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.additional-seat',
        { unitName: capitalize(pricingMetric.pluralize(1)) },
      );
    }
    if (pricingMetric.isResolutionsMetric) {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.cost-per-additional-unit',
        { unitName: capitalize(pricingMetric.pluralize(1)) },
      );
    }

    if (pricingMetric.allHigherTiersPerUnit(charge.tierIdBasedOnUsage(charge.includedUsage + 1))) {
      if (this.customerService.onPricing5_X) {
        return this.intl.t(
          'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.pricing-5-additional-block',
          { metric: capitalize(pricingMetric.pricing5PerAdditionalCostText()) },
        );
      } else {
        // We only want to show 'Cost per additional x' if every tier above the included is per unit
        // otherwise it does not make sense
        // https://github.com/intercom/embercom/pull/52969
        return this.intl.t(
          'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.additional-block',
          { blockSize: this.intl.formatNumber(charge.block_size) },
        );
      }
    }
    return this.intl.t(
      'billing.summary.price-usage-breakdown-card.overage-tooltip.cost-heading.pricing-metric',
      { pricingMetric: capitalize(pricingMetric.pluralize(2)) },
    );
  }

  get sections() {
    let { charge } = this.args;

    return [
      {
        label: this.overageBreakdownTitle,
        metrics: [
          {
            label: this.intl.t(
              'billing.summary.price-usage-breakdown-card.overage-tooltip.your-usage',
            ),
            small: true,
            noPadding: true,
          },
          { label: this.formattedActualUsage },
          {
            label: this.costHeading,
            small: true,
            noPadding: true,
          },
          ...this.tierInfo,
          {
            label: this.totalCostTitle,
            small: true,
            noPadding: true,
          },
          {
            label: this.formattedOverageFormula(charge.totalCostOverageBreakdown),
            value: this.formatPriceFromCents(charge.overagePrice),
          },
        ],
      },
    ];
  }

  formatPrice(price: number) {
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    });
  }

  get overageBreakdownTitle() {
    if (this.customerService.onPricing5_X) {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.pricing-5-overage-metric',
        { metric: capitalize(this.args.charge.pricingMetricModel.pricing5UsageIncludedText(2)!) },
      );
    } else {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.overage-metric',
        { metric: capitalize(this.args.charge.pricingMetricModel.pluralize(2)!) },
      );
    }
  }

  get totalCostTitle() {
    if (this.args.charge.isSeatMetric || this.customerService.onPricing5_X) {
      return this.intl.t('billing.summary.price-usage-breakdown-card.overage-tooltip.total-cost');
    } else {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.total-cost-of-overage',
      );
    }
  }

  formatPriceFromCents(priceInCents: number) {
    return this.intl.formatNumber(priceInCents / 100, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::Tooltips::OverageBreakdown': typeof OverageBreakdown;
  }
}
