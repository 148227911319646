/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import { capitalize } from '@ember/string';

export default class Header extends Component {
  @service purchaseAnalyticsService;
  @service customerService;
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get contract() {
    return this.args.contract;
  }

  get customer() {
    return this.customerService.customer;
  }

  get subscription() {
    return this.customer.subscription;
  }

  get isFrozenForNonPayment() {
    return this.app.isFrozenForNonPayment;
  }

  get hasMultiWorkspaces() {
    return this.subscription.numberOfSecondarySubscriptions > 0;
  }

  get totalNumberOfWorkspaces() {
    return this.subscription.numberOfSecondarySubscriptions + 1;
  }

  get isPrimaryWorkspace() {
    return this.subscription.isAnnualContractPrimarySubscription;
  }

  get isSecondaryWorkspace() {
    return this.subscription.isAnnualContractSecondarySubscription;
  }

  get primaryWorkspaceAppId() {
    return this.contract.primaryWorkspaceAppIdCode;
  }

  get primaryWorkspaceAppName() {
    return this.contract.primaryWorkspaceAppName;
  }

  get hasAccessToPrimaryWorkspace() {
    return this.app.currentAdmin.apps.some((app) => app.id === this.primaryWorkspaceAppId);
  }

  get nonSeatMetrics() {
    let nonSeatLimitList = [];
    for (let [key, value] of Object.entries(this.contract.contractUsageLimits)) {
      let pricingMetric = new PricingMetric({ pricing_metric: key }, this.intl);
      if (
        !pricingMetric.isSeatMetric &&
        pricingMetric.metric !== 'contracted_solution_seat_count' &&
        value > 0
      ) {
        nonSeatLimitList.push(capitalize(pricingMetric.pluralize(2)));
      }
    }
    return nonSeatLimitList.uniq();
  }

  get formattedNonSeatMetrics() {
    return this.intl.formatList(this.nonSeatMetrics, { style: 'long', type: 'conjunction' });
  }

  get usageLastUpdatedAt() {
    return this.contract.billingSnapshotLastUpdatedAt;
  }

  @action openInAppMessenger() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'talk_to_sales',
      place: 'billing_summary',
      context: 'hero banner', // eslint-disable-line @intercom/intercom/no-bare-strings
    });

    let textForMessenger;
    if (this.customer.currentlyOnEarlyStage) {
      textForMessenger = this.intl.t(
        'billing.summary.header.question-about-early-stage-subscription',
      );
    } else if (this.app.isSalesforceContracted) {
      textForMessenger = this.intl.t(
        'billing.summary.header.question-about-annual-contract-subscription',
      );
    } else {
      textForMessenger = this.intl.t('billing.summary.header.question-about-custom-subscription');
    }
    showNewMessageInIntercomWidget(textForMessenger);
  }

  get billingPeriodDurationInMonths() {
    return this.subscription?.billingPeriodDurationInMonths;
  }

  get _selfServeCustomerNotUnderTrial() {
    return (
      !this.app.isSalesforceContracted &&
      !this.customer.currentlyOnEarlyStage &&
      !this.customer.hasCustomPricing &&
      !this.customer.isOnInitialTrial
    );
  }

  get displayEditSubscriptionButton() {
    return (
      !this.isFrozenForNonPayment &&
      !this.customer.currentlyOnEarlyStage &&
      ((this.app.isSalesforceContracted && this.isPrimaryWorkspace) ||
        this.customer.hasCustomPricing)
    );
  }
}
