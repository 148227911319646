/* import __COLOCATED_TEMPLATE__ from './message-style-quick-action-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { equal } from '@ember/object/computed';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { selectFromObject, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  isMessageStylePopoverOpen: false,
  emailTemplateName: ternaryToProperty(
    'emailTemplate',
    'emailTemplate.name',
    'defaultEmailTemplate',
  ),
  tooltipText: ternaryToProperty('isEmail', 'tooltipTextForEmailTemplates', 'tooltipTextForInapps'),
  tooltipTextForEmailTemplates: computed('emailTemplateName', function () {
    return `Change template (${this.emailTemplateName})`;
  }),
  tooltipTextForInapps: 'Change message type',
  isEmail: equal('messageType', 'email'),
  messageStyleIcon: selectFromObject('styleIconSet', 'messageType'),

  styleIconSet: {
    email: 'brush',
    chat: 'chat-bubble-line',
    'small-announcement': 'inapp-note',
    announcement: 'inapp-post',
  },
  actions: {
    toggleMessageStylePopover() {
      this.toggleMessageStylePopover();
    },
  },
});
