/* import __COLOCATED_TEMPLATE__ from './grid-item.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import { tracked } from '@glimmer/tracking';

interface Args {
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
  id: string;
  gridStack: any;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: any;
}

export default class GridItem extends Component<Signature> {
  @tracked el: Element | undefined;
  addedToGrid = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    registerDestructor(this, () => {
      if (
        this.el &&
        this.args.gridStack &&
        this.args.gridStack.engine // if the gridStack is destroyed, the engine is set to null, this happens in the destructor of the grid component
      ) {
        if (document.getElementById(this.el.id)) {
          this.args.gridStack.removeWidget(this.el);
        }
      }
    });
  }

  attachWidget = modifier(
    (element: Element, _) => {
      this.el = element;
      if (this.args.gridStack && !this.addedToGrid) {
        let { x, y, w, h, minW, minH, id } = this.args;
        this.args.gridStack.addWidget(element, { x, y, w, h, minW, minH, id });
        this.addedToGrid = true;
      }
    },
    { eager: false },
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::GridItem': typeof GridItem;
    'common/grid-item': typeof GridItem;
  }
}
