/* import __COLOCATED_TEMPLATE__ from './conditional-link-to.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';

interface Signature {
  Element: HTMLSpanElement;
  Args: {
    linkEnabled: boolean;
    route?: string;
    model?: unknown;
    models?: unknown[];
    query?: Record<string, unknown>;
    activeClass?: string;
    linkToClasses?: string;
  };
  Blocks: {
    default: [];
  };
}

export default class ConditionalLinkTo extends Component<Signature> {
  get query() {
    return this.args.query ?? {};
  }

  get models() {
    return this.args.models ?? (this.args.model ? [this.args.model] : []);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::ConditionalLinkTo': typeof ConditionalLinkTo;
    'common/conditional-link-to': typeof ConditionalLinkTo;
  }
}
