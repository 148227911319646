/* import __COLOCATED_TEMPLATE__ from './usage-item.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { lte, not, or } from '@ember/object/computed';
import { computed } from '@ember/object';

const MAX_LIST_ITEMS = 6;

export default Component.extend({
  expandSubItems: false,
  itemCount: computed('items.[]', function () {
    return this.items ? this.items.length : 0;
  }),

  shouldShowAllByDefault: lte('itemCount', MAX_LIST_ITEMS),
  shouldShowAllSubItems: or('expandSubItems', 'shouldShowAllByDefault'),
  shouldShowExpandButton: not('shouldShowAllSubItems'),

  subItemsToDisplay: computed('items.[]', 'shouldShowAllSubItems', function () {
    return this.shouldShowAllSubItems ? this.items : this.items.slice(0, MAX_LIST_ITEMS - 1);
  }),
  hiddenSubItemsCount: computed('items.[]', 'subItemsToDisplay.[]', function () {
    return this.get('items.length') - this.get('subItemsToDisplay.length');
  }),

  actions: {
    expandSubItems() {
      this.set('expandSubItems', true);
    },
  },
});
