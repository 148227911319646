/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

const ERROR_MESSAGES = {
  601: 'An Access Token was included in the request, but the value was not a valid access token.',
  602: 'The Access Token included in the call is no longer valid due to expiration.',
  603: "Authentication is successful but user doesn't have sufficient permission to call all API's.",
};

export default class Main extends Component {
  @tracked marketoClientId = this.settings.marketoClientId;
  @tracked marketoClientSecret = this.settings.marketoClientSecret;
  @tracked marketoHostName = this.settings.marketoHostName;
  @tracked marketoAppHostName = this.settings.marketoAppHostName;

  get settings() {
    return this.args.settings || {};
  }

  get shouldShowSettings() {
    return (
      this.hasAuthCredentials &&
      (!this.updateAuthCredentials.isRunning || !this.settings.hasDirtyAttributes)
    );
  }

  get hasCredentialError() {
    return !!this.settings.errorCode;
  }

  get credentialErrorMessage() {
    return ERROR_MESSAGES[this.settings.errorCode];
  }

  get hasAuthCredentials() {
    let { marketoClientId, marketoClientSecret, marketoHostName, marketoAppHostName } =
      this.settings;
    return !!marketoClientId && !!marketoClientSecret && !!marketoHostName && !!marketoAppHostName;
  }

  @task
  *toggleAutoCreateLeads() {
    try {
      this.settings.toggleProperty('autoCreateLeads');
      yield this.args.update.perform(this.settings);
    } catch (e) {
      this.settings.rollbackAttributes();
    }
  }

  @task
  *toggleSendConversationActivityMetadata() {
    try {
      this.settings.toggleProperty('sendConversationActivityMetadata');
      yield this.args.update.perform(this.settings);
    } catch (e) {
      this.settings.rollbackAttributes();
    }
  }

  @task
  *toggleLeadEnrichment() {
    try {
      this.settings.toggleProperty('leadEnrichmentEnabled');
      yield this.args.update.perform(this.settings);
    } catch (e) {
      this.settings.rollbackAttributes();
    }
  }

  @task
  *updateAuthCredentials() {
    try {
      this.settings.setProperties({
        marketoClientId: this.marketoClientId,
        marketoClientSecret: this.marketoClientSecret,
        marketoHostName: this.marketoHostName,
        marketoAppHostName: this.marketoAppHostName,
      });
      yield this.args.update.perform(this.settings);
    } catch (e) {
      this.settings.rollbackAttributes();
    }
  }

  @task
  *updateSettings() {
    try {
      yield this.args.update.perform(this.settings);
    } catch (e) {
      this.settings.rollbackAttributes();
    }
  }
}
