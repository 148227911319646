/* import __COLOCATED_TEMPLATE__ from './current-and-future-price-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  CORE_ACCELERATE_ID,
  CORE_EARLY_STAGE_ENGAGEMENT_BUNDLE,
  CORE_GROW_ID,
  CORE_ID,
  CORE_PLAN_IDS,
  CORE_STARTER_ID,
  PLAN_DATA,
  PRICING_5_EARLY_STAGE_BUNDLE,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_ID,
  PRICING_5_X_CORE_PLANS,
  VBP2_PLAN_IDS,
  VBP_MAR2021_EARLY_STAGE_SEP2021_BUNDLE,
} from 'embercom/lib/billing';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';

export default class CurrentAndFuturePriceComponent extends Component {
  @service appService;
  @service customerService;
  @service purchaseAnalyticsService;
  @tracked showEditGraduationPlanModal = false;
  @tracked showEditGraduationAddonModal = false;
  @service notificationsService;
  @service intl;
  @service store;

  get app() {
    return this.appService.app;
  }

  get customer() {
    return this.customerService.customer;
  }

  get contract() {
    return this.args.contract;
  }

  get earlyStageGraduation() {
    return this.args.earlyStageGraduation;
  }

  get canAddCreditCard() {
    return !this.app.isSalesforceContracted || this.contract.isPrimarySubscription;
  }

  get graduationTabSelected() {
    return this.args.selectedTab === 'graduationPrice';
  }

  get currentPriceTabSelected() {
    return this.args.selectedTab === 'currentPrice';
  }

  get accelerateStartingPrice() {
    let startingPrice =
      this.customerService.originalPrice?.planStartingPrice ||
      this.customerService.currentPrice.planStartingPrice;

    return this.intl.formatNumber(startingPrice / 100, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });
  }

  get accelerateFeatures() {
    let product = this.products.find((product) => product.get('id') === CORE_ID);
    let accelerateTierId = 3;

    let plan = product.plans.find((plan) => plan.tierId === accelerateTierId);

    return plan.features
      .reject((feature) => feature.name === '')
      .flatMap((feature) => {
        return this.intl.t(`paywalls.upgrade-modal.features.${feature.key}.feature-name`);
      })
      .uniq();
  }

  get isSecondaryWorkspace() {
    return this.contract?.isSecondarySubscription;
  }

  isAddOn(plan) {
    return plan.content.product.addon;
  }

  @action talkToSales(plan, section) {
    let textForMessenger = '';
    let event = {
      action: 'clicked',
      object: 'chat_with_us',
      place: 'billing_summary',
      context: 'early stage', // eslint-disable-line @intercom/intercom/no-bare-strings
      section,
    };

    if (isEmpty(plan)) {
      textForMessenger = this.nonPlanChatToSalesText;
    } else {
      let planName = this.intl.t(plan.get('nameTranslationKey'));
      textForMessenger = this.isAddOn(plan)
        ? this.intl.t(
            'billing.summary.current-and-future-price-component.talk-to-sales.graduate-with-add-on',
            { addOnName: planName },
          )
        : this.intl.t(
            'billing.summary.current-and-future-price-component.talk-to-sales.graduate-to-plan',
            { planName },
          );
      event.modalSelectedPlan = plan.name;
    }

    this.purchaseAnalyticsService.trackEvent(event);

    showNewMessageInIntercomWidget(textForMessenger);
    this.showEditGraduationPlanModal && this.closeEditGraduationPlanModal();
    this.showEditGraduationAddonModal && this.closeEditGraduationAddonModal();
  }

  get nonPlanChatToSalesText() {
    return this.intl.t(
      'billing.summary.current-and-future-price-component.talk-to-sales.graduate-to-higher-plan',
    );
  }

  get recommendedPlanIds() {
    return this.earlyStageGraduation.recommendedPlanIds;
  }

  get recommendedPlanNames() {
    let plans = this.recommendedPlanIds
      .filter((planId) => PLAN_DATA[planId] && !PLAN_DATA[planId].addon)
      .map((id) => PLAN_DATA[id]);
    return this.intl.formatList(
      plans.map((plan) => this.intl.t(plan.nameTranslationKey)),
      { style: 'long', type: 'conjunction' },
    );
  }

  get recommendedAddOns() {
    return this.recommendedPlanIds
      .filter((planId) => PLAN_DATA[planId] && PLAN_DATA[planId].addon)
      .map((id) => PLAN_DATA[id]);
  }

  get recommendedAddOnNames() {
    return this.intl.formatList(
      this.recommendedAddOns.map((addOn) => this.intl.t(addOn.marketingNameTranslationKey)),
      { style: 'long', type: 'conjunction' },
    );
  }

  get withAddOn() {
    return this.recommendedPlanIds.any((planId) => PLAN_DATA[planId] && PLAN_DATA[planId].addon);
  }

  get withCoreRecommendation() {
    return this.recommendedPlanIds.any((planId) =>
      this.coreProductPlans.find((plan) => plan.idAsNumber === planId),
    );
  }

  get coreProductPlans() {
    let core_id;
    if (this.earlyStageGraduation.pricing5_X_Graduation) {
      core_id = PRICING_5_X_CORE_ID;
    } else if (this.customerService.onVbp1_X) {
      core_id = CORE_ID;
    } else {
      core_id = CORE_STARTER_ID;
    }
    let coreProduct = this.products.find((product) => product.id === core_id);
    let plans = coreProduct.plans;
    if (this.earlyStageGraduation?.isOnSteppedGraduation) {
      plans = this.addEarlyStageDummyPlan(plans);
    }
    return plans;
  }

  get graduationTabTitle() {
    if (this.earlyStageGraduation?.isOnSteppedGraduation) {
      return this.steppedGraduationPlanName;
    } else {
      return this.intl.t('billing.summary.current-and-future-price-component.graduation-tab.title');
    }
  }

  get steppedGraduationPlanName() {
    if (this.graduatingCorePlan.content.marketingNameTranslationKeyWithYearParam) {
      return this.intl.t(this.graduatingCorePlan.content.marketingNameTranslationKeyWithYearParam, {
        year: this.nextStepYearNumber,
      });
    } else {
      return this.intl.t(this.graduatingCorePlan.content.marketingNameTranslationKey);
    }
  }

  get isGraduationToYear3() {
    return this.nextStepYearNumber === 3;
  }

  get nextStepYearNumber() {
    return this.earlyStageGraduation.nextStepYearNumber;
  }

  get canEditAddons() {
    return !this.earlyStageGraduation.isGraduatingToEarlyStage;
  }

  replaceAccelerateWithEarlyStageVBP1_2DummyPlan(plans) {
    plans = this.removeAcceleratePlan(plans);
    let dummyEarlyStagePlan = this.createDummyEarlyStageVBP1_2Plan();
    plans.splice(plans.length - 1, 0, dummyEarlyStagePlan);
    return plans;
  }

  addEarlyStageVBP2_XDummyPlan(plans) {
    let dummyEarlyStagePlan = this.createDummyEarlyStageVBP2_XPlan();
    plans.push(dummyEarlyStagePlan);
    return plans;
  }

  addEarlyStageVPricing5_XDummyPlan(plans) {
    plans = this.pricing5SelfServePlans(plans);
    let dummyEarlyStagePlan = this.createDummyEarlyStagePricing5_XPlan();
    plans.push(dummyEarlyStagePlan);
    return plans;
  }

  addEarlyStageDummyPlan(plans) {
    if (this.earlyStageGraduation.pricing5_X_Graduation) {
      plans = this.addEarlyStageVPricing5_XDummyPlan(plans);
    } else if (this.earlyStageGraduation?.isOnSteppedGraduationVbp1_X) {
      plans = this.replaceAccelerateWithEarlyStageVBP1_2DummyPlan(plans);
    } else if (this.earlyStageGraduation?.isOnSteppedGraduationVbp2_X) {
      plans = this.addEarlyStageVBP2_XDummyPlan(plans);
    }
    return plans;
  }

  removeAcceleratePlan(plans) {
    return plans.filter(function (plan) {
      return plan.id !== CORE_ACCELERATE_ID;
    });
  }

  pricing5SelfServePlans(plans) {
    return plans.filter(function (plan) {
      return [PRICING_5_X_CORE_ESSENTIAL_ID, PRICING_5_X_CORE_ADVANCED_ID].includes(plan.id);
    });
  }

  createDummyEarlyStageVBP1_2Plan() {
    let plan = PLAN_DATA[CORE_EARLY_STAGE_ENGAGEMENT_BUNDLE];
    plan.content = PLAN_DATA[CORE_EARLY_STAGE_ENGAGEMENT_BUNDLE];
    return plan;
  }

  createDummyEarlyStageVBP2_XPlan() {
    let plan = PLAN_DATA[VBP_MAR2021_EARLY_STAGE_SEP2021_BUNDLE];
    plan.content = PLAN_DATA[VBP_MAR2021_EARLY_STAGE_SEP2021_BUNDLE];
    return plan;
  }

  createDummyEarlyStagePricing5_XPlan() {
    let plan = PLAN_DATA[PRICING_5_EARLY_STAGE_BUNDLE];
    plan.content = PLAN_DATA[PRICING_5_EARLY_STAGE_BUNDLE];
    return plan;
  }

  get pricingModelRelevantCorePlanIds() {
    if (this.earlyStageGraduation.pricing5_X_Graduation) {
      return PRICING_5_X_CORE_PLANS;
    } else if (this.earlyStageGraduation?.isOnSteppedGraduationVbp1_X) {
      return CORE_PLAN_IDS;
    } else {
      return VBP2_PLAN_IDS;
    }
  }

  getHighestTierCorePlan(plans) {
    let corePlanIds = plans.filter((id) =>
      this.pricingModelRelevantCorePlanIds.includes(id.toString()),
    );
    let highestCorePlanId = Math.max(...corePlanIds);
    if (this.earlyStageGraduation.isGraduatingToEarlyStage) {
      return this.coreProductPlans.find((plan) => plan.isEarlyStage);
    } else {
      return this.coreProductPlans.find((plan) => plan.idAsNumber === highestCorePlanId);
    }
  }

  get initiallySelectedCorePlan() {
    return this.graduatingCorePlan || this.recommendedCorePlan || this.growPlan;
  }

  get growPlan() {
    return this.coreProductPlans.find((plan) => plan.id === CORE_GROW_ID);
  }

  get graduatingCorePlan() {
    return this.getHighestTierCorePlan(this.earlyStageGraduation.planIds);
  }

  get recommendedCorePlan() {
    return this.getHighestTierCorePlan(this.earlyStageGraduation.recommendedPlanIds);
  }

  get addOnPlans() {
    return this.addons.map((addon) => addon.plans.firstObject).sortBy('id');
  }

  get downgradeAddons() {
    return this.earlyStageGraduation.downgradeAddons;
  }

  get earlyStageSteppedGraduationDowngradeAddOns() {
    return this.addOnPlans.filter((plan) => this.downgradeAddons.includes(plan.id));
  }

  get isAddOnRecommended() {
    return this.earlyStageGraduation.recommendedPlanIds.includes(this.downgradeAddon.id);
  }

  get graduatingAddOnPlan() {
    if (this.earlyStageGraduation.planIds.includes(this.downgradeAddon.id)) {
      return this.downgradeAddon;
    }
  }

  get recommendedAddOnPlan() {
    if (this.isAddOnRecommended) {
      return this.downgradeAddon;
    }
  }

  get shouldShowEarlyStageGraduationTab() {
    return this.earlyStageGraduation?.inProgress && !this.app.canUseNewProgressionExperience;
  }

  get downgradeAddon() {
    let includedPlanId = this.earlyStageGraduation.planIds.find((id) =>
      this.downgradeAddons.includes(id.toString()),
    );
    if (includedPlanId) {
      return this.addOnPlans.find((plan) => plan.id === includedPlanId.toString());
    }
    return this.addOnPlans.find((plan) => this.downgradeAddons.includes(plan.id));
  }

  get products() {
    return this.earlyStageGraduation.get('immutableProductsForGraduatingPricingModel.length') > 0
      ? this.earlyStageGraduation.immutableProductsForGraduatingPricingModel
      : this.customerService.products;
  }

  get addons() {
    return this.earlyStageGraduation.get('addons.length') > 0
      ? this.earlyStageGraduation.addons
      : this.customerService.addons;
  }

  get earlyStagePlanIds() {
    return this.earlyStageGraduation.earlyStagePlanIds;
  }

  @action
  closeEditGraduationPlanModal() {
    this.showEditGraduationPlanModal = false;
    this.customerService.resetAll();
  }

  @action
  openEditGraduationPlanModal(section) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'explore_other_plans',
      place: 'billing_summary',
      context: 'early stage', // eslint-disable-line @intercom/intercom/no-bare-strings
      section,
    });

    this.showEditGraduationPlanModal = true;
  }

  @action
  closeEditGraduationAddonModal() {
    this.showEditGraduationAddonModal = false;
    this.customerService.resetAll();
  }

  @action
  openEditGraduationAddonModal() {
    this.showEditGraduationAddonModal = true;
  }

  removeRelatedPlanIdsFromGraduationModel(plan) {
    let productForPlanId = this.products.find((product) =>
      product.planIds.includes(plan.idAsNumber),
    );
    this.earlyStageGraduation.planIds = this.earlyStageGraduation.planIds.reject((planId) =>
      productForPlanId.planIds.includes(planId),
    );
    this.earlyStageGraduation.recommendedPlanIds =
      this.earlyStageGraduation.recommendedPlanIds.reject((planId) =>
        productForPlanId.planIds.includes(planId),
      );
  }

  async saveEarlyStageGraduation() {
    try {
      await this.earlyStageGraduation.save();
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('billing.summary.current-and-future-price-component.error-saving-plan'),
      );
    }
  }

  get stripeMigration() {
    return this.store.peekRecord('billing/stripe-migration', this.app.id);
  }

  get hasPendingStripeMigration() {
    return this.stripeMigration?.isPending;
  }

  selectedPriceSetIdentifier(plan) {
    if (plan.isEarlyStage) {
      return this.earlyStageGraduation.pricingModelForEarlyStagePlan;
    } else {
      return this.earlyStageGraduation.pricingModelForNonEarlyStagePlans;
    }
  }

  @action
  async saveGraduationPlan(plan) {
    if (plan.isEarlyStage) {
      this.earlyStageGraduation.planIds = this.earlyStagePlanIds;
    } else if (this.earlyStageGraduation?.isOnSteppedGraduation) {
      this.earlyStageGraduation.planIds = [plan.idAsNumber];
    } else {
      this.removeRelatedPlanIdsFromGraduationModel(plan);
      this.earlyStageGraduation.planIds.push(plan.idAsNumber);
    }
    let priceSetIdentifier = this.selectedPriceSetIdentifier(plan);
    this.earlyStageGraduation.priceSetIdentifier = priceSetIdentifier;

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'save_graduation_plan',
      place: 'billing_summary',
      context: 'early stage', // eslint-disable-line @intercom/intercom/no-bare-strings
      plan_ids: this.earlyStageGraduation.planIds,
      price_set_identifier: priceSetIdentifier,
    });
    await this.saveEarlyStageGraduation();
    this.closeEditGraduationPlanModal();
  }

  @action
  async saveGraduationAddons(plan) {
    this.closeEditGraduationAddonModal();
    if (plan) {
      this.removeRelatedPlanIdsFromGraduationModel(plan);
      this.earlyStageGraduation.planIds.push(plan.idAsNumber);
    } else {
      this.addOnPlans.forEach((plan) => {
        this.removeRelatedPlanIdsFromGraduationModel(plan);
      });
    }
    await this.saveEarlyStageGraduation();
  }

  @action
  trackPageView() {
    this.purchaseAnalyticsService.trackPageView({
      object: 'sales_recommended_landing',
      place: 'billing_summary',
      context: 'early stage', // eslint-disable-line @intercom/intercom/no-bare-strings
    });
  }

  @action
  trackEvent(object) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object,
      place: 'billing_summary',
      context: 'early stage', // eslint-disable-line @intercom/intercom/no-bare-strings
    });
  }
}
