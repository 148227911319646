/* import __COLOCATED_TEMPLATE__ from './completion-preview.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import type Checklist from 'embercom/models/checklists/checklist';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { type Block } from 'embercom/models/common/blocks/block';
import type IntlService from 'embercom/services/intl';
import { ChecklistScreen } from '../editor';
import type Store from '@ember-data/store';

interface Args {
  checklist: Checklist;
  isEditing: boolean;
  shouldShowValidations: boolean;
}

class CompletionPreviewConfig extends BaseConfig {
  autoFocus = false;
  textDirection: NotificationDirection = 'ltr';
  allowedBlocks = [
    'paragraph',
    'orderedNestedList',
    'unorderedNestedList',
    'heading',
    'subheading',
  ];
  singleBlockMode = false;
  allowedInline = ['bold', 'italic', 'anchor', 'code'];
  allowTextAlignment = false;
  tools = [
    { name: 'template-inserter' },
    { name: 'fallback-editor' },
    { name: 'text-formatter' },
    { name: 'anchor-editor' },
  ];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  attributes: any;
  templating: { picker: string; resolver: AttributeInfoResolver };

  constructor(app: any, placeholder: string) {
    super();

    this.attributes = app.allowedAttributes;
    this.placeholder = placeholder;

    this.templating = {
      picker: 'common/attribute-picker',
      resolver: new AttributeInfoResolver({
        attributes: this.attributes,
        includeAppAttributes: false,
      }),
    };
  }
}

export default class CompletionPreviewComponent extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @tracked currentScreen: ChecklistScreen = ChecklistScreen.Main;
  @tracked completionScreenBlocks: BlocksDocument;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.completionScreenBlocks = new BlocksDocument(
      this.checklist.completionScreenBlocks.toArray(),
    );
  }

  get app() {
    return this.appService.app;
  }

  get checklist() {
    return this.args.checklist;
  }

  get completionPreviewConfig() {
    let placeholder = this.intl.t('outbound.checklists.completion-screen.placeholder');
    return new CompletionPreviewConfig(this.app, placeholder);
  }

  get showContentValidation() {
    return this.args.shouldShowValidations && !this.checklist.hasCompletionScreenContent;
  }

  get showButtonTextValidation() {
    return this.args.shouldShowValidations && !this.checklist.hasCompletionScreenButtonText;
  }

  @action updateCompletionScreenBlocks(blocksDoc: BlocksDocument) {
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block: Block) => {
      block.align = 'center'; // set the alignment here so it gets formatted correctly on the end user side
      return createFragmentFromBlock(this.store, block);
    });
    this.checklist.set('completionScreenBlocks', blockFragments);
    this.checklist.notifyPropertyChange('completionScreenBlocks');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Editor::CompletionPreview': typeof CompletionPreviewComponent;
    'checklist/editor/completion-preview': typeof CompletionPreviewComponent;
  }
}
