/* import __COLOCATED_TEMPLATE__ from './learn-and-import.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  importArticles: Function;
  showCleanupModal: Function;
  showSyncCleanupModal: Function;
  syncArticles: Function;
  importButtonDisabled: boolean;
  synchronizationEnabled: boolean;
}

export default class LearnAndImport extends Component<Args> {
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare intercomEventService: any;

  showArticlesTour = () => {
    window.Intercom('startTour', 51309);
    this.triggerAnalytics('take_a_tour_option');
  };

  showCreateArticle = () => {
    window.Intercom('showArticle', 56641); // https://www.intercom.com/help/en/articles/56641-create-your-first-article
    this.triggerAnalytics('create_your_first_article_option');
  };

  showMultiArticle = () => {
    window.Intercom('showArticle', 8170953); // https://www.intercom.com/help/en/articles/8170953-create-and-manage-multiple-help-centers
    this.triggerAnalytics('multi_helpcenter_option');
  };

  showControlArticle = () => {
    window.Intercom('showArticle', 2982784); // https://www.intercom.com/help/en/articles/2982784-control-who-can-see-your-articles
    this.triggerAnalytics('control_who_sees_article_option');
  };

  showSyncArticle = () => {
    window.Intercom('showArticle', 6717804); // https://www.intercom.com/help/en/articles/6717804-sync-articles-with-zendesk
    this.triggerAnalytics('how_sync_works_option');
  };

  showMigrateArticle = () => {
    window.Intercom('showArticle', 56643); // https://www.intercom.com/help/en/articles/56643-migrate-your-articles-from-zendesk
    this.triggerAnalytics('how_migration_works_option');
  };

  get items() {
    return [
      {
        items: [
          {
            text: this.intl.t('articles.list.dropdown.migrate'),
            component: 'articles/list/get-started-dropdown-items/migrate-from-zendesk',
            componentAttrs: {
              importArticles: this.args.importArticles,
              showCleanupModal: this.args.showCleanupModal,
              importButtonDisabled: this.args.importButtonDisabled,
              synchronizationEnabled: this.args.synchronizationEnabled,
            },
          },
          {
            text: this.intl.t('articles.list.dropdown.sync'),
            component: 'articles/list/get-started-dropdown-items/sync-with-zendesk',
            componentAttrs: {
              syncArticles: this.args.syncArticles,
              showSyncCleanupModal: this.args.showSyncCleanupModal,
              importButtonDisabled: this.args.importButtonDisabled,
              synchronizationEnabled: this.args.synchronizationEnabled,
            },
          },
        ],
      },
    ];
  }

  @action openOnboarding() {
    let url = this.router.urlFor('apps.app.articles.onboarding');
    this.triggerAnalytics('leverage_articles_in_messenger_option');
    safeWindowOpen(url, '_blank');
  }

  triggerAnalytics(object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      context: `onboarding_guide_library.articles.header.learn-dropdown`,
      place: 'app.articles.list.all.learn-import',
      section: 'learn_dropdown',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::List::LearnAndImport': typeof LearnAndImport;
    'articles/list/learn-and-import': typeof LearnAndImport;
  }
}
