/* import __COLOCATED_TEMPLATE__ from './help-center-link.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  publicUrl: string;
  displayColumn: boolean;
  trackLinkClicked: () => void;
  trackLinkCopied: () => void;
}

interface Signature {
  Args: Args;
}

const HelpCenterLink = templateOnlyComponent<Signature>();

export default HelpCenterLink;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Editor::SettingsSideDrawer::HelpCenterLink': typeof HelpCenterLink;
    'articles/editor/settings-side-drawer/help-center-link': typeof HelpCenterLink;
  }
}
