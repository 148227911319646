/* import __COLOCATED_TEMPLATE__ from './edit-subscription-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { alias, and, empty, equal, filterBy, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { ternary } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  purchaseAnalyticsService: service(),
  customerService: service(),

  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  appService: service(),
  context: 'billing',
  featureName: readOnly('customerService.featureName'),
  planIds: alias('customerService.planIds'),
  solutionId: alias('customerService.solutionId'),
  isEditing: alias('customerService.isEditing'),
  place: ternary('isEditing', 'edit_subscription', 'current_subscription'),
  customer: alias('customerService.customer'),
  app: alias('appService.app'),
  products: alias('customerService.products'),

  prices: alias('customerService.prices'),

  hasNoPrices: equal('prices.length', 0),
  onlyHasOneProduct: equal('products.length', 1),
  trackChanges: readOnly('customer.hasActiveSubscription'),
  totalAmount: readOnly('customerService.totalAmount'),
  showSolutionSelector: false,

  removingProducts: computed('products.@each.removing', function () {
    return this.products.any((product) => product.get('removing'));
  }),

  activeProducts: filterBy('products', 'active'),

  eligibleToCancelSubscription: computed(
    'customer.{hasActiveSubscription,subscriptionWillBeCancelledAt}',
    'app.isSalesforceContracted',
    'isEditing',
    function () {
      if (
        this.get('customer.hasActiveSubscription') &&
        this.get('customer.subscriptionWillBeCancelledAt')
      ) {
        return false;
      }

      if (this.get('app.isSalesforceContracted')) {
        return false;
      }

      return this.get('customer.hasActiveSubscription');
    },
  ),

  editing: computed(
    'customer.hasActiveSubscription',
    'customerService.eligibletoEditAsRegularCustomer',
    'isEditing',
    function () {
      if (
        this.get('customer.hasActiveSubscription') &&
        this.get('customerService.eligibletoEditAsRegularCustomer')
      ) {
        return this.isEditing;
      }

      if (!this.get('customerService.eligibletoEditAsRegularCustomer')) {
        return false;
      }

      return true;
    },
  ),

  earlyStagePartner: readOnly('customerService.earlyStagePartner'),

  editingOnRegularPlan: computed(
    'isEditing',
    'this.customer.currentEarlyStageCustomer',
    function () {
      return this.isEditing && !this.customer.currentEarlyStageCustomer;
    },
  ),

  buttonDisabled: and('isEditing', 'hasNoChangedProducts'),
  changedProducts: filterBy('products', 'changedPlan', true),
  hasNoChangedProducts: empty('changedProducts'),

  displayProducts: computed(
    'customer',
    'products.@each.active',
    'editingOnRegularPlan',
    function () {
      let products = this.products;
      if (!this.get('customer.hasActiveSubscription') || this.editingOnRegularPlan) {
        return products;
      } else {
        return products.filterBy('active');
      }
    },
  ),

  canceledProducts: filterBy('products', 'removing'),

  headerDescription: computed('isEditing', 'customer.hasActiveSubscription', function () {
    if (this.isEditing) {
      return 'Editing your current subscription';
    }
    if (this.get('customer.hasActiveSubscription')) {
      return 'Current subscription';
    }
    return 'Start with all three products';
  }),

  headerAction: computed(
    'isEditing',
    'customer.hasActiveSubscription',
    'showCancelProduct',
    'removingProducts',
    function () {
      if (this.isEditing && this.removingProducts && !this.showCancelProduct) {
        if (this.activeProducts) {
          return 'showCancelProductConfirm';
        }
        return 'cancelSubscription';
      }
      if (this.isEditing) {
        return 'next';
      }
      if (this.get('customer.hasActiveSubscription')) {
        return 'editSubscription';
      }
      return 'next';
    },
  ),

  headerButtonLabel: computed('isEditing', 'customer.hasActiveSubscription', function () {
    if (this.isEditing) {
      return 'sign_up.new.form.next';
    }
    if (this.get('customer.hasActiveSubscription')) {
      return 'sign_up.edit_subscription';
    }
    return 'sign_up.new.form.next';
  }),

  totalPrice: 1500,
  cancelAction: () => {},
  transition: () => {},

  subscriptionWillBeCancelledAndHasRelationshipManager: and(
    'customer.subscriptionWillBeCancelled',
    'app.hasRelationshipManager',
    'mrrIsOver200',
  ),

  setSolution: action(function (solutionId) {
    if (solutionId !== false) {
      this.set('solutionId', solutionId);
      this.customerService.syncPlanIds();
    }
    this.set('showSolutionSelector', false);
  }),

  actions: {
    showCancelProductConfirm() {
      let hasActiveProducts = this.products.any((product) => product.get('active'));
      if (this.get('customer.hasActiveSubscription') && !hasActiveProducts) {
        this.send('cancelSubscription');
      } else {
        this.set('showCancelProduct', true);
      }
    },

    next() {
      this.transition();
    },

    cancelSubscription(trackEvent) {
      this.cancelAction(trackEvent);
    },

    openSolutionSelector() {
      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked',
        object: 'usecase_dropdown',
        context: this.context,
        place: this.place,
      });
      this.set('showSolutionSelector', true);
    },

    editSubscription() {
      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked',
        object: 'edit_subscription',
        context: this.context,
        place: this.place,
      });
      this.set('isEditing', true);
    },

    cancelEdit() {
      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked',
        object: 'cancel_edit_subscription',
        context: this.context,
        place: this.place,
      });
      this.set('isEditing', false);
      this.set('showCancelProduct', false);
      this.customerService.resetProducts();
    },
  },
});
