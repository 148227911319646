/* import __COLOCATED_TEMPLATE__ from './horizontal-button-group.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import ButtonGroup from 'embercom/components/common/button-group';

// Example content:
// [{
//   value: 'chat',
//   label: 'Chat',
//   icon: 'chat-bubble',
// },
// {
//   value: 'small-announcement',
//   label: 'Note',
//   icon: 'inapp-note'
// }]

export default ButtonGroup.extend();
