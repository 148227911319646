/* import __COLOCATED_TEMPLATE__ from './layout.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type HomePageBlock } from 'embercom/models/customization-options/layout-options/home-page/home-page-options';

interface Args {
  site: HelpCenterSite;
}

export default class Layout extends Component<Args> {
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let { homeCollectionCols, selectedLocales } = this.args.site;
    this.homePageLayout.initialize({
      homeCollectionColumns: homeCollectionCols,
      selectedLocales,
    });
  }

  get homePageLayout() {
    return this.args.site.customizationOptions.layout.homePage;
  }

  get homePageBlocks() {
    let homePageBlocks = this.homePageLayout.blocks ?? [];
    // The content-block is not supported on this code path, it is only availble in new-styling with psh-hc-ia enabled
    return homePageBlocks.filter((block) => block.type !== 'content-block');
  }

  @action
  onItemOrdered(reorderedItems: HomePageBlock[]) {
    this.homePageLayout.reorderBlocks(reorderedItems.map((item) => item.type));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Content::Layout': typeof Layout;
    'articles/site/settings/content/layout': typeof Layout;
  }
}
