/* import __COLOCATED_TEMPLATE__ from './react-preview.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import { restartableTask } from 'ember-concurrency-decorators';
import type IntlService from 'embercom/services/intl';
import { addEventListener, runDisposables } from 'ember-lifeline';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import ENV from 'embercom/config/environment';
import type OutboundContent from 'embercom/models/customization-options/outbound-content';
import type HelpCenterSite from 'embercom/models/help-center-site';
import renderTemplate from '@intercom/pulse/lib/render-template';
import { camelize } from '@ember/string';
import type HelpCenterSocialLink from 'embercom/models/help-center-social-link';
import type HelpCenterSiteLinkGroup from 'embercom/models/help-center-site-link-group';
import type HelpCenterSiteLink from 'embercom/models/help-center-site-link';
import type SupportedLocale from 'embercom/models/articles/site/supported-locale';
import { postMessageToHCIframe, PreviewMessageType } from './helpers/help-center-preview-helper';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export enum PreviewType {
  Homepage = 'homepage',
  Collection = 'collection',
  Article = 'article',
  Footer = 'footer',
}

export enum SizeType {
  Actual = 'actual',
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export type PreviewPayload = {
  previewType: PreviewType;
  theme: Record<string, unknown>;
  helpCenterSite: Record<string, unknown>;
  layoutOptions: Record<string, unknown>;
};

interface Args {
  site: any;
  logoUrl: string;
  footerLogoUrl: string;
  headerBackgroundUrl: string;
  faviconUrl: string;
  faviconDeleted: boolean;
  customizationOptions?: OutboundContent;
  previewType: PreviewType;
  setPreviewType: (previewType: PreviewType) => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

const PREVIEW_BORDER_RADIUS = 8.0;
export default class HelpCenterReactPreview extends Component<Signature> {
  @service declare intl: IntlService;
  @tracked sizeType: SizeType = SizeType.Desktop;
  @tracked previewWidth = 0;
  @tracked previewHeight = 0;
  @service declare appService: any;

  // @ts-ignore TaskGenerator<void>' is not assignable to parameter of type 'TaskIsh<(string | undefined)[]
  iframeUrl = trackedTask(this, this.updateFrameUrlAndData, () => [
    this.settingsPayload,
    this.site.locale,
  ]);

  constructor(owner: any, args: Args) {
    super(owner, args);

    addEventListener(this, window, 'message', this.handleIframeEvent);
    this.site.customizationOptions.layout.homePage.refreshAvailableArticleLinks(this.args.site);
  }

  willDestroy(): void {
    super.willDestroy();
    runDisposables();
  }

  get app() {
    return this.appService.app;
  }

  get previewTypeOptions() {
    return [
      {
        text: this.intl.t('articles.settings.appearance.react-preview.homepage'),
        value: PreviewType.Homepage,
      },
      {
        text: this.intl.t('articles.settings.appearance.react-preview.collection'),
        value: PreviewType.Collection,
      },
      {
        text: this.intl.t('articles.settings.appearance.react-preview.article'),
        value: PreviewType.Article,
      },
    ];
  }

  get sizeTypeOptions() {
    return Object.keys(SizeType).map((key) => {
      let type = SizeType[key as keyof typeof SizeType];
      return {
        text: this.getSizeName(type),
        value: type,
      };
    });
  }

  private getSizeName(sizeType: SizeType) {
    switch (sizeType) {
      case SizeType.Actual:
        return this.intl.t('articles.settings.appearance.react-preview-size.actual');
      case SizeType.Mobile:
        return this.intl.t('articles.settings.appearance.react-preview-size.mobile');
      case SizeType.Tablet:
        return this.intl.t('articles.settings.appearance.react-preview-size.tablet');
      case SizeType.Desktop:
        return this.intl.t('articles.settings.appearance.react-preview-size.desktop');
    }
  }

  get customScaleStyle() {
    let { width, height } = this.previewScreenSize;

    if (this.sizeType === SizeType.Actual) {
      return sanitizeHtml(`
        width:${width}px;
        height:${height}px;
        top:40px;
        border-radius:${PREVIEW_BORDER_RADIUS}px;
        transform:scale(${this.scale});
      `);
    }

    let scaledHeight = height;
    if (this.sizeType === SizeType.Desktop) {
      scaledHeight = this.previewHeight / this.scale;
    }

    return sanitizeHtml(`
      left:calc(50% - ${width * 0.5}px);
      top:40px;
      width:${width}px;
      height:${scaledHeight}px;
      border-radius:${Math.round(PREVIEW_BORDER_RADIUS / this.scale)}px;
      transform:scale(${this.scale});
    `);
  }

  get headerCustomStyle() {
    let { width } = this.previewScreenSize;
    let customWidth = width * this.scale;
    return sanitizeHtml(`max-width: ${customWidth}px;`);
  }

  get previewScreenSize() {
    let size;
    switch (this.sizeType) {
      case SizeType.Actual:
        size = { width: this.previewWidth, height: this.previewHeight };
        break;
      case SizeType.Mobile:
        size = { width: 390, height: 844 };
        break;
      case SizeType.Tablet:
        size = { width: 768, height: 1024 };
        break;
      case SizeType.Desktop:
        size = { width: 1440, height: 900 };
        break;
    }
    return size;
  }

  get scale() {
    if (this.sizeType === SizeType.Actual) {
      return 1.0;
    }

    let xScale = this.previewWidth / this.previewScreenSize.width;
    let yScale = this.previewHeight / this.previewScreenSize.height;
    return Math.min(1.0, xScale, yScale);
  }

  get site(): HelpCenterSite {
    return this.args.site;
  }

  get footerLinks() {
    return {
      custom: this.site.footerLinks.map((link: any) => {
        return { title: link.title, url: link.url };
      }),
      socialLinks: this.site.socialLinks.map((link: HelpCenterSocialLink) => {
        let socialNetwork = link.socialNetwork === 'x' ? 'twitter-x' : link.socialNetwork;
        // disabling linter since the icons are hosted in the HC domain
        // eslint-disable-next-line @intercom/intercom/use-asset-url
        let iconUrl = `${this.site.url}/assets/svg/icon:social-${socialNetwork}`;
        return {
          provider: link.socialNetwork,
          url: '',
          iconUrl,
        };
      }),
      // Filter footerlink groups for links in the default locale
      linkGroups: this.site.footerLinkGroups
        .filter(
          (group: HelpCenterSiteLinkGroup) =>
            group.locale === this.site.defaultLocale.localeId && group.links.length > 0,
        )
        .map((group: HelpCenterSiteLinkGroup) => group.preview()),
    };
  }

  get headerLinks() {
    let headerLinkGroup = this.site.headerLinkGroups.filter((group: HelpCenterSiteLinkGroup) => {
      return group.locale === this.site.defaultLocale.localeId && group.links.length > 0;
    });

    return (
      headerLinkGroup.firstObject?.links.map((link: HelpCenterSiteLink) => {
        return link.preview();
      }) || []
    );
  }

  elementCustomizationOptions(elementCustomizationOptions?: OutboundContent) {
    return {
      backgroundColor: elementCustomizationOptions?.backgroundColor,
      fadeToEdge: elementCustomizationOptions?.fadeToEdge,
      backgroundGradient: elementCustomizationOptions?.backgroundGradient?.serialize(),
      fontColor: elementCustomizationOptions?.fontColor,
      backgroundImageUrl: elementCustomizationOptions?.backgroundImageUrl,
      showRichTextField: elementCustomizationOptions?.showRichTextField,
    };
  }

  // Need to serialise the fragment to a hash in order to track changed and rerender the preview
  get layoutOptions() {
    return {
      homePage: this.site.customizationOptions.layout.homePage.preview(this.site.locale),
      collectionsPage: {
        showArticleDescriptions:
          this.site.customizationOptions.layout.collectionsPage?.showArticleDescriptions,
      },
      articlePage: {},
      searchPage: {},
    };
  }

  get customizationOptions() {
    let customizationOptions = this.site.customizationOptions;
    return {
      header: this.elementCustomizationOptions(customizationOptions.header),
      body: this.elementCustomizationOptions(customizationOptions.body),
      footer: this.elementCustomizationOptions(customizationOptions.footer),
      collectionCard: customizationOptions.collectionCardPreview(this.elementCustomizationOptions),
      global: {
        font: customizationOptions?.global?.font?.preview(),
        componentStyle: {
          card: {
            borderRadius: customizationOptions?.global?.componentStyle?.card?.borderRadius,
            type: customizationOptions?.global?.componentStyle?.card?.type,
          },
        },
        namedComponents: {
          ...(customizationOptions?.global?.namedComponents?.searchBar && {
            searchBar: {
              style: this.camelizeKeys(
                customizationOptions?.global?.namedComponents?.searchBar?.style.serialize(),
              ),
            },
          }),
          ...(customizationOptions?.global?.namedComponents?.header && {
            header: {
              style: this.camelizeKeys(
                customizationOptions?.global?.namedComponents?.header?.style.serialize(),
              ),
              subheader: {
                enabled: customizationOptions?.global?.namedComponents?.header?.subheader?.enabled,
                style: this.camelizeKeys(
                  customizationOptions?.global?.namedComponents?.header?.subheader?.style.serialize(),
                ),
              },
            },
          }),
          ...(customizationOptions?.global?.namedComponents?.footer && {
            footer: {
              type: customizationOptions?.global?.namedComponents?.footer?.type,
            },
          }),
        },
      },
      contentBlock: {
        blockStyle: this.elementCustomizationOptions(customizationOptions.contentBlock?.blockStyle),
        buttonOptions: {
          backgroundColor: customizationOptions.contentBlock?.buttonOptions.backgroundColor,
          fontColor: customizationOptions.contentBlock?.buttonOptions.fontColor,
          borderRadius: customizationOptions.contentBlock?.buttonOptions.borderRadius,
        },
        isFullWidth: customizationOptions.contentBlock?.isFullWidth,
      },
    };
  }

  get settingsPayload(): PreviewPayload {
    let localeInformation = this.site.selectedLocales.find(
      (locale) => locale.localeId === this.site.locale,
    ) as SupportedLocale;
    let customizedFooterTextContent = this.site.customizedFooterTextContents?.findBy(
      'locale',
      this.site.locale,
    );
    return {
      previewType: this.args.previewType,
      helpCenterSite: {
        homeCollectionCols: this.site.homeCollectionCols,
        footerContactDetails: this.site.footerContactDetails,
        customizedFooterTextContent: customizedFooterTextContent?.jsonBlocks,
        headerLinks: this.headerLinks,
        footerLinks: this.footerLinks,
        hideAuthors: this.site.hideAuthors,
        showRelatedArticles: this.site.showRelatedArticles,
        showTableOfContents: this.site.showTableOfContents,
      },
      theme: {
        color: this.site.themeColor,
        header: this.args.headerBackgroundUrl,
        headerFontColor: this.site.headerFontColor,
        homeUrl: this.site.homeUrl === '' ? null : this.site.homeUrl,
        customizationOptions: this.customizationOptions,
        headline: renderTemplate(this.site.headline || '', {}),
        logo: this.args.logoUrl,
        footerLogo: this.args.footerLogoUrl,
        siteName: this.site.defaultLocale.title,
        localisedInformation: {
          contentBlock: {
            title: localeInformation.contentBlock?.title,
            description: localeInformation.contentBlock?.description,
            buttonTitle: localeInformation.contentBlock?.buttonTitle,
            buttonUrl: localeInformation.contentBlock?.buttonUrl,
            withButton: localeInformation.contentBlock?.withButton,
          },
        },
      },
      layoutOptions: this.layoutOptions,
    };
  }

  @action setSizeType(sizeType: SizeType) {
    this.sizeType = sizeType;
  }

  @action handleResize(element: HTMLElement) {
    this.previewWidth = element.clientWidth;
    this.previewHeight = element.clientHeight;
  }

  @restartableTask *updateFrameUrlAndData(): TaskGenerator<string> {
    // Add a small delay before the postMessage to allow things like color picker dragging to settle.
    yield timeout(50);

    // Something has changed so we need to update the iframe preview.
    this.sendDataToIframe();

    let baseUrl =
      ENV.environment === 'development' || ENV.environment === 'test'
        ? 'http://help-center.test'
        : `https://${this.site.defaultDomain}`;

    return `${baseUrl}/${this.site.identifier}/${this.site.locale}/preview`;
  }

  handleIframeEvent(event: MessageEvent) {
    if (event.data === 'help-center-react-preview-ready') {
      this.sendDataToIframe();
    }
  }

  sendDataToIframe() {
    postMessageToHCIframe({
      type: PreviewMessageType.UpdateHelpCenterPreviewSettings,
      payload: this.settingsPayload,
    });
  }

  camelizeKeys(hash: object): object | null {
    if (!hash) {
      return null;
    }

    let entries = Object.entries(hash);
    let camelizedEntries = entries.map(([key, value]) => [camelize(key), value]);
    return Object.fromEntries(camelizedEntries);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::ReactPreview': typeof HelpCenterReactPreview;
    'articles/site/settings/react-preview': typeof HelpCenterReactPreview;
  }
}
