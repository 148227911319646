/* import __COLOCATED_TEMPLATE__ from './need-help-card.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class NeedHelpCard extends Component {
  @service declare purchaseAnalyticsService: any;

  @action
  trackClickEvent() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'early_stage_need_help_card',
      place: 'billing_summary',
      object: 'contact_us',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::EarlyStage::NeedHelpCard': typeof NeedHelpCard;
    'billing/summary/early-stage/need-help-card': typeof NeedHelpCard;
  }
}
