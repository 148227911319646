/* import __COLOCATED_TEMPLATE__ from './simple-usage-breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';
import type Charge from 'embercom/models/billing/price/charge';

interface Args {
  charge: Charge;
  planName?: string;
}

export default class SimpleUsageBreakdown extends Component<Args> {
  get metricName() {
    return capitalize(this.args.charge.pricingMetricModel.pluralize(2)!);
  }

  get metricPluralizedForActualUsage() {
    let { charge } = this.args;
    return capitalize(charge.pricingMetricModel.pluralize(charge.actualUsage)!);
  }

  get singularMetricName() {
    return capitalize(this.args.charge.pricingMetricModel.pluralize(1)!);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::Tooltips::SimpleUsageBreakdown': typeof SimpleUsageBreakdown;
  }
}
