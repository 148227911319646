/* import __COLOCATED_TEMPLATE__ from './group-summary.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import type Charge from 'embercom/models/billing/price/charge';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    groupCharges: Charge[];
    shouldHidePrices: boolean;
    usageBreakdownsVisible: boolean;
    groupIdentifier: 'sms' | 'whatsapp' | 'email';
  };
}
export default class GroupSummary extends Component<Signature> {
  get totalUsageAcrossGroup() {
    return this.args.groupCharges.reduce(
      (accumulator, charge) => accumulator + charge.actual_usage,
      0,
    );
  }

  get totalPriceAcrossGroup() {
    let chargeGroupPrices = this.args.groupCharges.map((charge) =>
      // base_price shows the price due to ULA while price shows the price due to actual usage.
      // So for a given metric, the customer is in overages if the price is bigger than base_price and vice versa
      Math.max(charge.base_price, charge.price),
    );

    return chargeGroupPrices.reduce((accumulator, price) => accumulator + price, 0);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::GroupSummary': typeof GroupSummary;
  }
}
