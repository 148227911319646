/* import __COLOCATED_TEMPLATE__ from './product-summary-row.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import { readOnly, alias, or, equal } from '@ember/object/computed';
import { computed } from '@ember/object';
import usageFormatter from 'embercom/lib/usage-formatter';
import numberFormatter from 'embercom/lib/number-formatter';
import Component from '@ember/component';
import { PRODUCT_KEYS, PRODUCTS } from 'embercom/lib/billing';

export default Component.extend({
  intercomEventService: service(),
  tagName: 'tr',
  className: 'tbl__row',
  customerService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  metric: readOnly('product.pricingMetric'),
  metricDispalyName: readOnly('product.pricingMetricDisplayName'),
  product: null,
  prices: null,
  migrationPrices: null,
  showUsage: readOnly('summaryView.showUsage'),
  showUsageComparison: readOnly('summaryView.showUsageComparison'),
  articlesOrFixedPricingMetric: or('product.isArticles', 'metricIsFixed'),
  metricIsFixed: equal('metric', 'fixed'),
  pricingMetric: readOnly('price.pricingMetric'),
  actualUsage: readOnly('price.actualUsage'),
  futureActualUsage: readOnly('migrationPrice.actualUsage'),
  billedUsage: readOnly('price.billedUsage'),
  futureBilledUsage: readOnly('migrationPrice.billedUsage'),
  displayUsage: computed('showUsage', 'pricingMetric', function () {
    if (this.pricingMetric === 'fixed') {
      return false;
    }
    return this.showUsage;
  }),
  displayUsageComparison: computed(
    'showUsageComparison',
    'migrationPrice.pricingMetric',
    function () {
      if (this.migrationPrice && this.migrationPrice.pricingMetric === 'fixed') {
        return false;
      }
      return this.showUsageComparison;
    },
  ),

  displayProduct: readOnly('product.content'),

  higherPlan: computed('product.higherPlanId', function () {
    let higherPlanId = this.product.get('higherPlanId');
    return this.product.plans.find((plan) => plan.id === higherPlanId);
  }),

  featureKey: readOnly('higherPlan.features.firstObject.key'),

  showUpgradeLink: readOnly('product.hasHigherPlan'),

  productImage: computed('product.id', function () {
    return PRODUCTS[this.get('product.id')]?.productImage;
  }),

  usageNumber: readOnly('price.formattedUsageDisplay'),

  usageMetric: alias('currentUsageMetric'),

  getProductPrice(prices) {
    if (!prices) {
      return null;
    }
    let planId = this.get('product.activePlan.idAsNumber');
    if (!planId) {
      planId = this.get('product.plans.firstObject.idAsNumber');
    }
    return prices.find((price) => price.hasSamePlans([planId]));
  },

  price: computed('prices', 'product.activePlan', function () {
    return this.getProductPrice(this.prices);
  }),

  migrationPrice: computed('migrationPrices', 'product.activePlan', function () {
    return this.getProductPrice(this.migrationPrices);
  }),

  priceToUse: readOnly('price'),

  amount: computed('customerService.customer.hasCustomPricing', 'priceToUse', function () {
    return this.customerService.customer.hasCustomPricing
      ? 'Custom'
      : this.priceToUse?.preDiscountAmountToTwoDecimals;
  }),

  comparisonAmount: readOnly('migrationPrice.preDiscountAmountToTwoDecimals'),

  currentUsage: computed('product.price', 'metric', 'articlesOrFixedPricingMetric', function () {
    if (this.articlesOrFixedPricingMetric) {
      return '';
    } else if (this.metric) {
      return `${numberFormatter(this.get(`price.${this.metric}`))} ${this.metricDispalyName}`;
    } else {
      return `${numberFormatter(this.get('appService.app.peopleCount'))} Total People`;
    }
  }),

  currentUsageMetric: computed('product.isArticles', 'metric', function () {
    if (this.get('product.isArticles')) {
      return '';
    } else if (this.metric) {
      return this.metricDispalyName.toLowerCase();
    } else {
      return 'total people';
    }
  }),

  futureUsageMetric: computed('product', function () {
    if (this.get('product.isArticles')) {
      return '';
    } else if (this.get('product.isInbox')) {
      return 'inbox seats';
    } else {
      return 'active people';
    }
  }),

  productName: computed('product', 'displayProduct.activePlan', function () {
    let planName = this.product.hasOnePlan ? ' ' : this.displayProduct.activePlan.name;
    return `${this.product.name} ${planName}`;
  }),

  currentNumberOfInboxSeats: computed('appService.app.humanAdmins.[]', function () {
    return this.get('appService.app.humanAdmins').filter((admin) => admin.get('hasInboxAccess'))
      .length;
  }),

  migrationUsageMetric: computed('product', function () {
    let metric = this.get('migrationPrice.pricingMetric');
    if (metric === 'fixed') {
      return '';
    }
    return metric ? `${usageFormatter.startCase(metric)}`.toLowerCase() : '';
  }),

  isInbox: computed('product.key', function () {
    return this.get('product.key') === PRODUCT_KEYS[1];
  }),
});
