/* import __COLOCATED_TEMPLATE__ from './privacy.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import templateOnlyComponent from '@ember/component/template-only';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  site: HelpCenterSite;
}

const Privacy = templateOnlyComponent<Args>();
export default Privacy;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::General::Privacy': typeof Privacy;
    'articles/site/settings/general/privacy': typeof Privacy;
  }
}
