/* import __COLOCATED_TEMPLATE__ from './custom-answer-suggestions.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const LEADS_AND_VISITORS_TARGET_TYPE = 'leads_and_visitors';
const USER_TARGET_TYPE = 'users';

export default class CustomAnswerSuggestions extends Component {
  @service intercomEventService;
  @service intl;
  @tracked targetUserType = LEADS_AND_VISITORS_TARGET_TYPE;

  targetUserTypeOptions = [
    {
      value: LEADS_AND_VISITORS_TARGET_TYPE,
      label: this.intl.t('operator.task-bots.visitors-and-leads'),
    },
    { value: USER_TARGET_TYPE, label: this.intl.t('operator.task-bots.users.label') },
  ];

  get isDisplayingUserGroup() {
    return this.targetUserType === USER_TARGET_TYPE;
  }

  get targetLabel() {
    if (this.isDisplayingUserGroup) {
      return this.intl.t('operator.task-bots.users.label');
    } else {
      return this.intl.t('operator.task-bots.visitors-and-leads');
    }
  }

  get activeContentSuggestionsSetting() {
    if (this.isDisplayingUserGroup) {
      return this.aiAgentSettings.contentSuggestionsEnabledForUsers;
    } else {
      return this.aiAgentSettings.contentSuggestionsEnabledForVisitors;
    }
  }

  @action
  toggleActiveContentSuggestionsSetting() {
    if (this.isDisplayingUserGroup) {
      return (this.aiAgentSettings.contentSuggestionsEnabledForUsers =
        !this.activeContentSuggestionsSetting);
    } else {
      return (this.aiAgentSettings.contentSuggestionsEnabledForVisitors =
        !this.activeContentSuggestionsSetting);
    }
  }

  get activePredictiveAnswersSetting() {
    if (this.isDisplayingUserGroup) {
      return this.aiAgentSettings.predictiveAnswersEnabledForUsers;
    } else {
      return this.aiAgentSettings.predictiveAnswersEnabledForVisitors;
    }
  }

  @action
  toggleActivePredictiveAnswersSetting() {
    if (this.isDisplayingUserGroup) {
      return (this.aiAgentSettings.predictiveAnswersEnabledForUsers =
        !this.activePredictiveAnswersSetting);
    } else {
      return (this.aiAgentSettings.predictiveAnswersEnabledForVisitors =
        !this.activePredictiveAnswersSetting);
    }
  }

  @action
  changeTargetUserType(value) {
    this.targetUserType = value;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `operator_settings_resolution_bot_${value}`,
      context: 'from_panel',
    });
  }

  get aiAgentSettings() {
    return this.args.aiAgentSettings;
  }
}
