/* import __COLOCATED_TEMPLATE__ from './plan-choice-summary.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type StripeMigration from 'embercom/models/billing/stripe-migration';
import {
  PLAN_DATA,
  PRICING_5_X_ADDON_PLAN_IDS,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_EXPERT_ID,
  PRICING_5_X_CORE_PLANS,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  FIN_AI_COPILOT_BASE_ID,
} from 'embercom/lib/billing';
import {
  getCorePlanBreakdown,
  getEstimatedCoreSeatCharge,
  getEstimatedLiteSeatCount,
  helpLinkUrlsForStripeMigrations,
  getPspPlanBreakdown,
  guidanceAndFAQArticlesLink,
  getCopilotUsage,
  getCopilotPrice,
} from 'embercom/helpers/billing/migrations-helper';
import type Price from 'embercom/models/price';
import { action } from '@ember/object';

interface Args {
  migration: StripeMigration;
  pricing5Estimates: {
    essentialEstimate: Price;
    essentialWithPSPEstimate: Price;
    essentialWithCopilotEstimate: Price;
    essentialWithPSPCopilotEstimate: Price;
    advancedEstimate: Price;
    advancedWithPSPEstimate: Price;
    advancedWithCopilotEstimate: Price;
    advancedWithPSPCopilotEstimate: Price;
    expertEstimate: Price;
    expertWithPSPEstimate: Price;
    expertWithCopilotEstimate: Price;
    expertWithPSPCopilotEstimate: Price;
  };
  onEditPlanClick: () => void;
  onPackageConfirmation: any;
}

interface PlanEstimates {
  base: Price;
  withPsp: Price;
  withCopilot: Price;
  withCopilotAndPsp: Price;
}

type PlanID = string;

type PlanEstimatesMap = Record<PlanID, PlanEstimates>;

interface Signature {
  Args: Args;
}
export default class PlanChoiceSummaryComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  translationKey = 'billing.migrations.confirmation.proactive';
  migrationChoiceTranslationKey = 'billing.migrations.migration_overview.migrationChoice';

  get translationsByKeys(): { [index: string]: string } {
    return {
      mainPlan: this['intl'].t(PLAN_DATA[this.corePlanId].nameTranslationKey),
      fullSeat: this['intl'].t(`${this.migrationChoiceTranslationKey}.seats_full`, {
        seatCount: this.estimatedCoreSeatCharge.actualUsage,
      }),
      liteSeat: this['intl'].t(`${this.migrationChoiceTranslationKey}.seats_lite`, {
        seatCount: this.estimatedLiteSeatCount,
      }),
    };
  }

  get helpLinkUrls() {
    return helpLinkUrlsForStripeMigrations;
  }

  get guidanceAndFAQArticlesLink() {
    return guidanceAndFAQArticlesLink(this.appService.app, this.customerService.customer);
  }

  get planIds() {
    return this.args.migration.postMigrationPlanIds;
  }

  get addonIds(): Array<string> {
    return PRICING_5_X_ADDON_PLAN_IDS.filter((val) => this.planIds.includes(Number(val)));
  }

  get corePlanId(): string {
    return PRICING_5_X_CORE_PLANS.find((val) => this.planIds.includes(Number(val)))!;
  }

  get estimatedCoreSeatCharge() {
    return getEstimatedCoreSeatCharge(this.corePlanBreakdown)!;
  }

  get postMigrationPlanEstimate() {
    let selectedPlan = this.planEstimatesMap[this.corePlanId];

    if (
      this.addonIds.includes(PROACTIVE_SUPPORT_PLUS_BASE_ID) &&
      this.addonIds.includes(FIN_AI_COPILOT_BASE_ID)
    ) {
      return selectedPlan.withCopilotAndPsp;
    } else if (this.addonIds.includes(PROACTIVE_SUPPORT_PLUS_BASE_ID)) {
      return selectedPlan.withPsp;
    } else if (this.addonIds.includes(FIN_AI_COPILOT_BASE_ID)) {
      return selectedPlan.withCopilot;
    } else {
      return selectedPlan.base;
    }
  }

  private get planEstimatesMap(): PlanEstimatesMap {
    return {
      [PRICING_5_X_CORE_ESSENTIAL_ID]: {
        base: this.args.pricing5Estimates.essentialEstimate,
        withPsp: this.args.pricing5Estimates.essentialWithPSPEstimate,
        withCopilot: this.args.pricing5Estimates.essentialWithCopilotEstimate,
        withCopilotAndPsp: this.args.pricing5Estimates.essentialWithPSPCopilotEstimate,
      },
      [PRICING_5_X_CORE_ADVANCED_ID]: {
        base: this.args.pricing5Estimates.advancedEstimate,
        withPsp: this.args.pricing5Estimates.advancedWithPSPEstimate,
        withCopilot: this.args.pricing5Estimates.advancedWithCopilotEstimate,
        withCopilotAndPsp: this.args.pricing5Estimates.advancedWithPSPCopilotEstimate,
      },
      [PRICING_5_X_CORE_EXPERT_ID]: {
        base: this.args.pricing5Estimates.expertEstimate,
        withPsp: this.args.pricing5Estimates.expertWithPSPEstimate,
        withCopilot: this.args.pricing5Estimates.expertWithCopilotEstimate,
        withCopilotAndPsp: this.args.pricing5Estimates.expertWithPSPCopilotEstimate,
      },
    };
  }

  get estimatedLiteSeatCount() {
    return getEstimatedLiteSeatCount(
      this.corePlanId,
      this.appService.app.humanAdmins.length,
      this.corePlanBreakdown!,
    );
  }

  get shouldShowAddons() {
    return this.hasIncludedPsp || this.hasCopilotUsage;
  }

  private get hasIncludedPsp() {
    return this.addonIds.includes(PROACTIVE_SUPPORT_PLUS_BASE_ID);
  }

  private get hasCopilotUsage() {
    return getCopilotUsage(this.postMigrationPlanEstimate) > 0;
  }

  get estimatedBasePrice() {
    let estimatedBasePrice = this.estimatedCoreSeatCharge.price;

    if (this.hasIncludedPsp) {
      estimatedBasePrice += this.pspBasePrice;
    }

    return estimatedBasePrice + getCopilotPrice(this.postMigrationPlanEstimate);
  }

  get formattedConfirmationDate() {
    return this.intl.formatDate(this.args.migration.confirmedAt, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }

  private get pspBasePrice() {
    return getPspPlanBreakdown(this.postMigrationPlanEstimate).plan_starting_price;
  }

  private get corePlanBreakdown() {
    return getCorePlanBreakdown(this.postMigrationPlanEstimate.breakdown);
  }

  @action
  onEditPlanClick() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'stripe_migrations',
      place: 'confirmation_page',
      object: 'edit_plan_button',
    });
    this.args.onEditPlanClick();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::PlanChoiceSummary': typeof PlanChoiceSummaryComponent;
  }
}
