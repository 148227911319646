/* import __COLOCATED_TEMPLATE__ from './company-name-change-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import ajax from 'embercom/lib/ajax';
import { action } from '@ember/object';

export default class CompanyNameChangeModal extends Component {
  @service notificationsService;
  @service intl;
  @service appService;

  @action
  async saveCompanyName() {
    try {
      let result = await ajax({
        url: `/ember/customers/${this.args.customer.id}/change_company_details`,
        type: 'POST',
        data: JSON.stringify({
          app_id: this.appService.app.id,
          company_name: this.args.customer.entityName,
        }),
      });
      this._updateCompanyNameAndNotify(result);
    } catch (error) {
      this.notificationsService.notifyError(error.jqXHR.responseJSON[0]);
    }
  }

  _updateCompanyNameAndNotify(result) {
    this.args.onCompanyNameChange(result.entity_name);
    this.notificationsService.notifyConfirmation(
      this.intl.t('billing.billing-settings.company-change-modal.saved'),
    );
    this.args.closeModal();
  }

  @action
  onCancel() {
    this.args.customer.entityName = this.args.companyNameText;
    this.args.closeModal();
  }
}
