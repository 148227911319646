/* import __COLOCATED_TEMPLATE__ from './logical-operator-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Task from 'embercom/models/checklists/task';
import { LogicalOperator } from 'embercom/models/checklists/task';
import type IntlService from 'embercom/services/intl';

interface Args {
  task: Task;
}

export default class LogicalOperatorSwitcher extends Component<Args> {
  @service declare intl: IntlService;

  get logicalDropdownOptions() {
    return [
      {
        text: this.intl.t('outbound.checklists.tasks.and'),
        value: LogicalOperator.AND,
      },
      {
        text: this.intl.t('outbound.checklists.tasks.or'),
        value: LogicalOperator.OR,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Sidebar::TaskSettings::StepCompletion::LogicalOperatorSwitcher': typeof LogicalOperatorSwitcher;
    'checklist/sidebar/task-settings/step-completion/logical-operator-switcher': typeof LogicalOperatorSwitcher;
  }
}
