/* import __COLOCATED_TEMPLATE__ from './cda-onboarding-input-row-component.hbs'; */
/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
export default Component.extend({
  hasError: computed('erroredCdas', 'erroredCdas.length', 'name', function () {
    if (this.erroredCdas.length > 0) {
      return this.erroredCdas.find((cda) => {
        return cda === this.name;
      });
    }
  }),

  tagName: 'tr',

  name: '',
  description: '',
  type: '',
  showPlaceholderText: false,

  namePlaceholder: 'example: plan_type',
  descriptionPlaceholder: 'name of the plan type the user is on',

  typeOptions: [
    { label: 'String', value: 'String' },
    { label: 'Number', value: 'Number' },
    { label: 'Boolean', value: 'Boolean' },
    { label: 'Date', value: 'Date' },
  ],
});
