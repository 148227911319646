/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { FIN_CSAT_COMPATIBLE_CHANNELS } from 'embercom/lib/operator/resolution-bot/constants';
import type IntlService from 'ember-intl/services/intl';
import type Profile from 'embercom/models/ai-agent/profile';
import type { BotMode, InactivityMode } from 'embercom/models/ai-agent/profile';

interface Signature {
  Args: {
    profile: Profile;
  };
}

export default class Editor extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked selectedBotOnlyLocale = this.defaultLocale;
  @tracked showCsatBlockAfterUpdate =
    this.profile.csatBlockUpdateAfter !== undefined && this.profile.csatBlockUpdateAfter !== null;

  supportedAutoCloseModes: { text: string; value: InactivityMode }[];
  inactivityWhenAnswerCurrentSelection: { [key: string]: InactivityMode } = {};

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.supportedAutoCloseModes = [
      { text: this.intl.t('ai-agent.profiles.editor.routed'), value: 'routed' },
      { text: this.intl.t('ai-agent.profiles.editor.closed'), value: 'closed' },
    ];
  }

  get profile() {
    return this.args.profile;
  }

  get defaultLocale() {
    return this.appService.app.locale || 'en';
  }

  get compatibleWithFinCSAT() {
    let targetChannels = this.profile.targetChannels || [];
    if (!targetChannels.length) {
      return true;
    }

    let incompatibleChannels = targetChannels.filter((channel) => {
      return !FIN_CSAT_COMPATIBLE_CHANNELS.includes(channel);
    });

    return !incompatibleChannels.length;
  }

  @action
  updateBotOnlyLocale(locale: string) {
    this.selectedBotOnlyLocale = locale;
  }

  @action
  setBotMode(mode: BotMode) {
    this.profile.botMode = mode;
    if (mode === 'one_time') {
      this.profile.useAiAnswers = false;
      this.profile.preHandoverAnswerEnabled = false;
    } else if (mode === 'bot_only') {
      this.profile.preHandoverAnswerEnabled = false;
    }
    this.profile.maybeResetDefaultInactivityValues();
  }

  @action
  setInactivityModeFor(
    mode: 'userInactivityModeWhenAnswer' | 'userInactivityModeWhenNoAnswer',
    value: InactivityMode,
  ) {
    this.profile[mode] = value;
  }

  @action
  toggleCsatEnabled() {
    this.profile.csatEnabled = !this.profile.csatEnabled;

    this.syncCsatResolutionTypes();
    this.settleCsatBlockAfterUpdate();
  }

  syncCsatResolutionTypes() {
    this.profile.csatEnabledForHardResolution = this.profile.csatEnabled;
    this.profile.csatEnabledForSoftResolution = this.profile.csatEnabled;
  }

  @action
  toggleCsatEnabledForHardResolution() {
    this.profile.csatEnabledForHardResolution = !this.profile.csatEnabledForHardResolution;

    this.syncCsatEnabled();
    this.settleCsatBlockAfterUpdate();
  }

  @action
  toggleCsatEnabledForSoftResolution() {
    this.profile.csatEnabledForSoftResolution = !this.profile.csatEnabledForSoftResolution;

    this.syncCsatEnabled();
    this.settleCsatBlockAfterUpdate();
  }

  syncCsatEnabled() {
    this.profile.csatEnabled = this.profile.isAnyCSATSettingEnabled;
  }

  settleCsatBlockAfterUpdate() {
    if (this.profile.isAnyCSATSettingEnabled) {
      return;
    }

    this.profile.csatBlockUpdateAfter = undefined;
    this.showCsatBlockAfterUpdate = false;
  }

  @action
  toggleRatingChange() {
    this.showCsatBlockAfterUpdate = !this.showCsatBlockAfterUpdate;

    this.profile.csatBlockUpdateAfter = this.showCsatBlockAfterUpdate ? 0 : undefined;
  }

  @action
  setBlockUpdateAfter(e: Event) {
    let value = parseInt((e.target as HTMLInputElement)?.value, 10);
    if (isNaN(value)) {
      value = 0;
    }
    this.profile.csatBlockUpdateAfter = value * 3600;
  }

  @action
  updateInactivityDuration(number: number) {
    this.profile.endUserInactivityTimer = number;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Profiles::Editor': typeof Editor;
    'ai-agent/profiles/editor': typeof Editor;
  }
}
