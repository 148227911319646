/* import __COLOCATED_TEMPLATE__ from './pricing.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Calling from 'embercom/models/settings/calling';
import moment from 'moment-timezone';
import { TRIAL_CREDIT_FULL_AMOUNT } from 'embercom/models/settings/calling';

interface Args {
  product: string;
  settings: Calling;
  startFreeUsageExperience?: () => void;
  hasNonFreePhoneNumber?: boolean;
}

interface Signature {
  Args: Args;
}

export default class Pricing extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: IntlService;

  get pricingBannerText(): string {
    switch (this.args.product) {
      case 'phone':
        return this.intl.t('channels.video-call.settings.phone-pricing-text');
      case 'messenger':
        return this.intl.t('channels.video-call.settings.messenger-pricing-text');
      case 'recording':
        return this.intl.t('channels.video-call.settings.recording-pricing-text');
      default:
        return '';
    }
  }

  get earlyAccessBannerText(): string {
    switch (this.args.product) {
      case 'phone':
        return this.intl.t('channels.video-call.settings.phone-early-access-banner-text');
      case 'messenger':
        return this.intl.t('channels.video-call.settings.messenger-early-access-banner-text');
      case 'recording':
        return this.intl.t('channels.video-call.settings.recording-early-access-banner-text');
      default:
        return '';
    }
  }

  get remainingTrialCredit(): string {
    let balance = Number(this.args.settings.trialCreditBalance).toFixed(2);
    balance = balance.endsWith('.00') ? balance.slice(0, -3) : balance;
    balance = `• $${balance}`;
    return balance;
  }

  get fullDollarAmount(): string {
    return `$${TRIAL_CREDIT_FULL_AMOUNT}`;
  }

  get daysRemaining(): number {
    return Math.ceil(moment(this.args.settings.freeUsageExpiredAt).diff(moment(), 'days', true));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::Pricing': typeof Pricing;
    'calling/settings/pricing': typeof Pricing;
  }
}
