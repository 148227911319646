/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import AttributeComponent from 'embercom/components/attribute-component';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import { ternary } from '@intercom/pulse/lib/computed-properties';

let ProfileAnonymousComponent = AttributeComponent.extend({
  value: ternary('isAnonymous', 'Lead', 'User'),
  isAnonymous: reads('userOrCompany.is_anonymous'),
  filterUrl: computed('userOrCompany', 'attribute', 'value', 'isAnonymous', function () {
    return B64FilterParamEncoder.filterUrl(this.userOrCompany, this.attribute, this.isAnonymous);
  }),
  isNotBlank: true,
});

export default ProfileAnonymousComponent;
