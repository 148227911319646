/* import __COLOCATED_TEMPLATE__ from './if-else-statement-block-editor.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import PredicateGroup from 'predicates/models/predicate-group';
import { copy } from 'ember-copy';
import { isPresent } from '@ember/utils';

export default class IfElseStatementBlockEditor extends Component {
  @service store;
  @tracked composers = [];

  model;

  constructor() {
    super(...arguments);
    if (!this.args.block) {
      this.model = this.createEmptyIfElseStatementFragment(this.store);
      let fallbackBlocks = this.args.generateFallbackBlocks?.();
      if (fallbackBlocks) {
        this.model.fallbackBlocks = fallbackBlocks;
      }
    } else {
      this.model = this.createFragmentFromIfElseStatementBlock(this.store, this.args.block);
    }
  }

  createEmptyIfElseStatementFragment(store) {
    let fragment = createFragmentFromBlock(store, { type: 'ifElseStatement' });
    fragment.predicatesBlocksPairs = [store.createFragment('common/blocks/predicates-blocks-pair')];
    fragment.fallbackBlocks = [{ type: 'paragraph', text: '' }];
    return fragment;
  }

  createFragmentFromIfElseStatementBlock(store, block) {
    let fragment = createFragmentFromBlock(store, block);
    fragment.predicatesBlocksPairs = block.predicatesBlocksPairs.map((pair) =>
      this.createFragmentFromPredicateBlockPair(store, pair),
    );
    return fragment;
  }

  createFragmentFromPredicateBlockPair(store, pair) {
    let fragment = store.createFragment('common/blocks/predicates-blocks-pair', pair);
    fragment.predicateGroup = store.createFragment('predicates/predicate-group', {
      predicates: PredicateGroup.convertRawPredicates(store, pair.predicateGroup.predicates),
    });
    return fragment;
  }

  createFragmentsFromBlocks(store, blocks) {
    return blocks.map((block) => createFragmentFromBlock(store, block));
  }

  @action
  addPredicatesBlocksPair() {
    let newPair = this.store.createFragment('common/blocks/predicates-blocks-pair');
    this.model.predicatesBlocksPairs.pushObject(newPair);
  }

  @action
  composerReady(composer) {
    this.composers = [...this.composers, composer];
  }

  get isUploading() {
    return this.composers.any((composerPublicApi) => composerPublicApi.composer.state.isUploading);
  }

  get isSaveDisabled() {
    if (this.isUploading) {
      return true;
    }

    let pairs = this.model.predicatesBlocksPairs;

    if (pairs.length === 0) {
      return true;
    }

    if (pairs.firstObject.length === 0 || !this.hasContent(pairs.firstObject.blocks)) {
      return true;
    }

    if (this.args.options.requireNonEmptyBlocks) {
      // For some use cases (e.g. email subject line), we don't allow any of the pairs to have empty
      // blocks.

      if (!this.hasContent(this.model.fallbackBlocks)) {
        return true;
      }

      if (pairs.any((pair) => !this.hasContent(pair.blocks))) {
        return true;
      }
    }

    return pairs.any((pair) => pair.predicateGroup.isEmpty || !pair.predicateGroup.isValid);
  }

  hasContent(blocks) {
    if (blocks.length === 0) {
      return false;
    }
    return blocks.any((block) => !block.hasOwnProperty('text') || isPresent(block.text));
  }

  @action
  removePredicatesBlocksPair(index) {
    let newArray = copy(this.model.predicatesBlocksPairs);
    newArray.splice(index, 1);
    this.model.predicatesBlocksPairs = newArray;
  }

  @action
  save() {
    let serializedBlock = this.model.serialize();
    serializedBlock.predicatesBlocksPairs = serializedBlock.predicatesBlocksPairs.map((pair) =>
      pair.serialize(),
    );
    if (!this.hasContent(serializedBlock.fallbackBlocks)) {
      serializedBlock.fallbackBlocks = [];
    }
    this.args.insertIfElseStatement(serializedBlock);
  }
}
