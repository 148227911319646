/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { replyTypeOptions } from 'embercom/models/chats/chat';
import { preferredDevices } from 'embercom/models/data/outbound/constants';
import { schedule } from '@ember/runloop';

export default class ChatEditorComponent extends Component {
  @service appService;
  @service store;
  @service contentEditorService;

  @tracked currentDevice = this._firstPreferredDevice();

  get app() {
    return this.appService.app;
  }

  get composerPreset() {
    if (!this.args.isEditing) {
      return 'readOnlyChat';
    } else if (this.isIos || this.isAndroid) {
      return 'inAppChatMobile';
    }
    return 'inAppChatVideo';
  }

  get isWeb() {
    return this.currentDevice === 'web';
  }

  get isIos() {
    return this.currentDevice === 'ios';
  }

  get isAndroid() {
    return this.currentDevice === 'android';
  }

  get isEmailCollectorReply() {
    return this.args.chat.replyType === replyTypeOptions.emailCollector;
  }

  get objectType() {
    return objectTypes.chat;
  }

  _firstPreferredDevice() {
    let contentPlatform = this.args.chat.preferredDevices.firstObject;
    switch (contentPlatform) {
      case preferredDevices.ios:
        return 'ios';
      case preferredDevices.android:
        return 'android';
      default:
        return 'web';
    }
  }

  @action
  updateCurrentPreviewDevice(device) {
    schedule('afterRender', () => {
      this.currentDevice = device;
    });
  }
}
