/* import __COLOCATED_TEMPLATE__ from './banner.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import Component from '@glimmer/component';
import { PLAN_DATA, PRICING_5_X_CORE_PLANS } from 'embercom/lib/billing';

interface Args {
  customer: any;
  earlyStageGraduation: any;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class BannerComponent extends Component<Signature> {
  get earlyStageGraduation() {
    return this.args.earlyStageGraduation;
  }

  get currentStepYearNumber() {
    return this.earlyStageGraduation.currentStepYearNumber;
  }

  get nextStepYearNumber() {
    return this.earlyStageGraduation.nextStepYearNumber;
  }

  get currentDiscountPercentage() {
    return Math.floor(this.earlyStageGraduation.currentStepPercentageDiscount);
  }

  get nextYearDiscountPercentage() {
    return Math.floor(this.earlyStageGraduation.nextStepPercentageDiscount);
  }

  get graduationPlanName() {
    let corePlan = this.earlyStageGraduation.planIds.filter((id: number) =>
      PRICING_5_X_CORE_PLANS.includes(id.toString()),
    );
    return PLAN_DATA[corePlan].name;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::UpdateEarlyStageSubscription::Banner': typeof BannerComponent;
    'billing/update-early-stage-subscription/banner': typeof BannerComponent;
  }
}
