/* import __COLOCATED_TEMPLATE__ from './shared-elements.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import tinycolor from 'tinycolor2';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  site: HelpCenterSite;
}

interface Signature {
  Args: Args;
  Element: HTMLDivElement;
}

const MAX_BORDER_RADIUS_VALUE = 24;
export default class SharedElements extends Component<Signature> {
  maxBorderRadiusValue = MAX_BORDER_RADIUS_VALUE;
  @tracked borderRadius = this.cardSettings.borderRadius;

  get isReadable(): boolean {
    let actionColor = this.args.site.themeColor;
    let bodyBackgroundColor = this.args.site.customizationOptions.body.backgroundColor;

    if (!bodyBackgroundColor) {
      return true;
    }

    return tinycolor.isReadable(bodyBackgroundColor, actionColor, {
      level: 'AA',
      size: 'small',
    });
  }

  @action
  setBackgroundColor(color: string) {
    this.args.site.customizationOptions.body.backgroundColor = color;
  }

  get cardSettings() {
    return this.args.site.customizationOptions.global?.componentStyle?.card;
  }

  @action
  setBorderRadius() {
    if (this.borderRadius < 0 || this.borderRadius > this.maxBorderRadiusValue) {
      return;
    }

    this.cardSettings.borderRadius = this.borderRadius;
  }

  @action
  onBorderRadiusBlur() {
    if (this.borderRadius > this.maxBorderRadiusValue) {
      this.borderRadius = this.maxBorderRadiusValue;
    } else if (this.borderRadius < 0) {
      this.borderRadius = 0;
    }
    this.setBorderRadius();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Styling::SharedElements': typeof SharedElements;
    'articles/site/settings/styling/shared-elements': typeof SharedElements;
  }
}
