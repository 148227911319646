/* import __COLOCATED_TEMPLATE__ from './uninstall-modal.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { equal, readOnly, and, notEmpty } from '@ember/object/computed';
import { APP_PACKAGE_UNINSTALL_REASONS } from 'embercom/models/developer-hub/app-package/reports/uninstall-feedback';
import { inject as service } from '@ember/service';

export default Component.extend({
  init() {
    this._super(...arguments);
    this.set(
      'reasons',
      Object.entries(APP_PACKAGE_UNINSTALL_REASONS).map(([value, text]) => {
        return {
          text: this.intl.t(`appstore.app-package.uninstall-modal.uninstall-reasons.${text}`),
          value,
        };
      }),
    );
  },
  appName: readOnly('app.name'),
  isUninstalling: false,
  intl: service(),

  reasons: {},

  uninstallConfirmValue: '',
  formattedFeedback: undefined,
  customFeedback: undefined,
  selectedCustomFeedback: equal('formattedFeedback', 'other'),
  enteredUninstall: computed('intl.locale', 'uninstallConfirmValue', function () {
    return (
      this.intl.t(`appstore.app-package.uninstall-modal.uninstall`).toLocaleUpperCase() ===
      this.uninstallConfirmValue
    );
  }),
  enteredFeedback: notEmpty('formattedFeedback'),
  canUninstall: and('enteredUninstall', 'enteredFeedback'),
  isProtectedAppPackage: readOnly('appPackage.isProtectedAppPackage'),

  confirm: action(function () {
    this.set('isUninstalling', true);
    return this.uninstall.perform(this.formattedFeedback, this.customFeedback);
  }),
});
