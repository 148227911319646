/* import __COLOCATED_TEMPLATE__ from './navigation-stack.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';

import { inject as service } from '@ember/service';
import { type ArticleNavigationItem } from 'embercom/services/help-center-navigation-service';
import type HelpCenterNavigationService from 'embercom/services/help-center-navigation-service';
import { type TabId } from 'embercom/services/help-center-setting-navigation-service';

interface Args {
  root: ArticleNavigationItem;
  tabId: TabId;
}

export default class NavigationStack extends Component<Args> {
  @service declare helpCenterNavigationService: HelpCenterNavigationService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.helpCenterNavigationService.initialize(this.args.root, this.args.tabId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::NavigationStack': typeof NavigationStack;
    'articles/site/settings/navigation-stack': typeof NavigationStack;
  }
}
