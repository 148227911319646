/* import __COLOCATED_TEMPLATE__ from './cluster-conversations-line-chart.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import Range from 'embercom/models/reporting/range';
import IntervalFormatter from 'embercom/lib/reporting/flexible/interval-formatter';
import ViewConfig, { GROUPINGS } from 'embercom/lib/reporting/flexible/view-config';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import { COUNT, CONVERSATION_CREATED_AT } from 'embercom/lib/reporting/flexible/constants';
import { units } from 'embercom/lib/reporting/flexible/formatters';
import { first } from 'underscore';
import PALETTE from '@intercom/pulse/lib/palette';

const OFFSET_UNIT = 'ms';
const INTERVAL_ANCHORS = {
  _default: 'day',
  week: 'isoWeek',
  month: 'month',
};

export default class ClusterConversationsLineChart extends Component {
  @service appService;

  get timezone() {
    return this.appService.app.timezone;
  }

  get modifiedFilters() {
    return this.args.filters;
  }

  get range() {
    let durationFromEnd = moment.duration({ days: this.args.interval, milliseconds: -1 });
    let start = moment(this.args.clusterRunUpdatedAt).subtract(durationFromEnd).format();
    let end = moment(this.args.clusterRunUpdatedAt).format();
    return Range.createFromParams(start, end, this.timezone);
  }

  get intervalFormatter() {
    return new IntervalFormatter(this.range.interval);
  }

  get viewConfig() {
    let { offset } = this;
    let viewConfig = new ViewConfig();

    viewConfig.formatUnit = { unit: units.value, displayUnit: 'conversation' };
    viewConfig.aggregations = { defaultOption: COUNT };
    viewConfig.groupings = {
      defaultOption: {
        ...GROUPINGS[CONVERSATION_CREATED_AT],
        dataTransformation: (dataResponses) => {
          // slice the trailing bucket(s) since our end range is rounded to end of day
          // and we therefore expect a bucket beyond our desired range to exist.
          let group = first(dataResponses.groups);
          let endIndex = group.values.findIndex((v) => moment(v).isSameOrAfter(this.range.end));
          group.names = group.names.slice(0, endIndex);
          group.values = group.values.slice(0, endIndex);

          return dataResponses;
        },
        tickPositioner(/* min, max */) {
          return this.tickPositions.map((tickPosition) =>
            moment(tickPosition).add(offset, OFFSET_UNIT).valueOf(),
          );
        },
        tooltipTransformation: (point, series) => {
          let nextPoint = series.points[point.index + 1];
          let endDate = nextPoint ? moment(nextPoint.x) : moment(this.range.end);
          return this.intervalFormatter.datePart(
            point.x,
            series.chart.options.time.timezone,
            endDate.subtract(1, 'd'),
          );
        },
      },
    };
    viewConfig.seriesColors = [PALETTE.blue];

    return viewConfig;
  }

  get dataConfig() {
    return {
      url: '/ember/custom_answers/signal_flexible',
      xAxis: {
        type: 'temporal',
        data: {
          interval: FILTER_PROPERTY_PLACEHOLDER,
          property: CONVERSATION_CREATED_AT,
        },
      },
      series: [
        {
          name: 'comparison_cluster_linechart',
          source: 'answers',
          type: COUNT,
          data: { property: CONVERSATION_CREATED_AT },
          time: {
            property: CONVERSATION_CREATED_AT,
            start: FILTER_PROPERTY_PLACEHOLDER,
            end: FILTER_PROPERTY_PLACEHOLDER,
            ...(this.offset && { offset: this.offset }),
          },
        },
      ],
      filter: {
        type: 'and',
        filters: [
          {
            type: 'category',
            data: {
              property: 'cluster_id',
              values: [this.args.clusterId],
            },
          },
        ],
      },
    };
  }

  get offset() {
    let { intervalFormatter } = this;
    let intervalAnchor = INTERVAL_ANCHORS[intervalFormatter.interval] || INTERVAL_ANCHORS._default;
    let expectedBucketMoment = moment.tz(this.range.start, this.timezone).startOf(intervalAnchor);
    return moment(this.range.start).diff(expectedBucketMoment);
  }
}
