/* import __COLOCATED_TEMPLATE__ from './confirmation.hbs'; */
/* RESPONSIBLE TEAM: team-billing */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type StripeMigration from 'embercom/models/billing/stripe-migration';
import type IntlService from 'embercom/services/intl';

interface Args {
  migration: StripeMigration;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationConfirmationComponent extends Component<Signature> {
  @service declare intl: IntlService;
  get migrationDate() {
    return this.intl.formatDate(this.args.migration.migrationDate, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::Confirmation': typeof BillingMigrationConfirmationComponent;
    'billing/stripe-migration/confirmation': typeof BillingMigrationConfirmationComponent;
  }
}
