/* import __COLOCATED_TEMPLATE__ from './get-support.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import { inject as service } from '@ember/service';
import EmberObject, { action } from '@ember/object';
import { isPresent } from '@ember/utils';

export default class GetSupport extends Component {
  @service intercomEventService;
  @service appService;
  appPackage = this.args.appPackage;

  get app() {
    return this.appService.app;
  }

  get avatars() {
    return this.appPackage.adminAvatars.map((avatar) =>
      EmberObject.create({
        avatarData: {
          showAsActive: false,
          url: avatar,
          displayAsDidUpdate: true,
        },
      }),
    );
  }

  get showGetAppSupport() {
    return this.appPackage.isIntercomApp || isPresent(this.appPackage.supportEmail);
  }

  get showDocLink() {
    return isPresent(this.appPackage.documentationUrl);
  }

  @action
  openSupportLink() {
    // Temporary workaround for the salesforce beta
    if (this.appPackage.implementationIdentifier === 'salesforce-by-intercom') {
      this.appPackage.documentationUrl =
        'https://www.intercom.com/help/en/articles/4497943-install-and-configure-the-salesforce-app';
    }
    safeWindowOpen(this.appPackage.documentationUrl);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `doc_${this.appPackage.isIntercomApp ? 'intercom' : 'partner'}_url_link`,
      app_package_code: this.appPackage.id,
    });
  }
  @action
  openMessenger(event) {
    event.preventDefault();
    showNewMessageInIntercomWidget();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `get_in_touch_with_intercom_link`,
      app_package_code: this.appPackage.id,
    });
  }
  @action
  trackOpenEmailLink() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `get_in_touch_with_partner_link`,
      app_package_code: this.appPackage.id,
    });
  }
}
