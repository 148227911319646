/* import __COLOCATED_TEMPLATE__ from './email-stamp.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    answer: $TSFixMe; // embercom/models/custom-answers/custom-answer
    context: 'list' | 'header';
  };
}

const EmailStamp = templateOnlyComponent<Signature>();
export default EmailStamp;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Answers::EmailStamp': typeof EmailStamp;
  }
}
