/* import __COLOCATED_TEMPLATE__ from './bulk-sync-status.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import {
  MANUAL,
  EVENT_BASED,
  PROCESSING,
  COMPLETED,
  COMPLETED_WITH_ERRORS,
} from 'embercom/lib/app-store/salesforce/settings-constants';

const SECOND_TO_MS_MULTIPLIER = 1000;
const EVENT_BASED_SYNC_ARTICLE_LINK =
  'https://www.intercom.com/help/en/articles/1047665-salesforce-integration-troubleshooting-and-f-a-q#h_ee50436db6';
const MANUAL_BULK_SYNC_ARTICLE_LINK =
  'https://www.intercom.com/help/en/articles/6721636-manually-sync-people-data-in-bulk-using-intercom-s-salesforce-app';

export default class BulkSyncStatus extends Component {
  @tracked openSectionId;
  @service intl;

  get isProcessing() {
    return this.args.status === PROCESSING;
  }

  get statusLabel() {
    if (this.isEventBasedSyncStatus) {
      return this._eventBasedSyncStatusLabel;
    } else {
      return this._manualBulkSyncStatusLabel;
    }
  }

  get bulkType() {
    return this.args.type === MANUAL ? MANUAL : EVENT_BASED;
  }

  get isEventBasedSyncStatus() {
    return this.bulkType === EVENT_BASED;
  }

  get troubleShootingArticleLink() {
    return this.isEventBasedSyncStatus
      ? EVENT_BASED_SYNC_ARTICLE_LINK
      : MANUAL_BULK_SYNC_ARTICLE_LINK;
  }

  get _eventBasedSyncStatusLabel() {
    if (isPresent(this.args.time)) {
      return this._lastSyncedLabel;
    } else {
      return this._notStartedLabel;
    }
  }

  get _manualBulkSyncStatusLabel() {
    if (!isPresent(this.args.status)) {
      return this._notStartedLabel;
    } else if (this.args.status === COMPLETED_WITH_ERRORS || this.args.status === COMPLETED) {
      return this._lastSyncedLabel;
    } else {
      return this._defaultManualBulkSyncLabel;
    }
  }

  get _lastSyncedLabel() {
    return this.intl.t(
      `app.components.appstore.app-package.settings.salesforce-by-intercom.bulk-sync.status.status-label.last-synced-${this.bulkType}.label`,
      {
        timesAgo: this._timesAgo,
      },
    );
  }

  get _notStartedLabel() {
    return this.intl.t(
      `app.components.appstore.app-package.settings.salesforce-by-intercom.bulk-sync.status.status-label.not-started-${this.bulkType}.label`,
    );
  }

  get _defaultManualBulkSyncLabel() {
    return this.intl.t(
      `app.components.appstore.app-package.settings.salesforce-by-intercom.bulk-sync.status.status-label.${this.args.status}.label`,
      {
        percentage: this.args.percentage,
      },
    );
  }

  get _timesAgo() {
    return this.intl.formatRelative(this._normalizedTime, { style: 'narrow' });
  }

  get _normalizedTime() {
    if (this.args.time && Number.isInteger(this.args.time)) {
      return this.args.time * SECOND_TO_MS_MULTIPLIER;
    }

    return this.args.time;
  }

  @action
  onOpenSectionChange(sectionId) {
    this.openSectionId = sectionId;
  }
}
