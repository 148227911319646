/* import __COLOCATED_TEMPLATE__ from './paywalled-list.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class PaywalledList extends Component {
  @service intercomEventService;

  get sortedItems() {
    return this.args.items.sortBy('name');
  }
  get hasMessengerAppInPrefetch() {
    return this.messengerAppInPrefetch && this.messengerAppInPrefetch.length > 0;
  }

  get messengerAppInPrefetch() {
    return this.args.items.filter((item) => item.isPrefetchingConfiguration);
  }
  get paywalledItems() {
    return this.sortedItems.filter((item) => this.isPaywalled(item));
  }
  get nonPaywalledItems() {
    return this.sortedItems.filter((item) => !this.isPaywalled(item));
  }

  isPaywalled(item) {
    return item.paywalledInOperator;
  }

  @action
  logAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'messenger_app_inserter',
    });
  }
}
