/* import __COLOCATED_TEMPLATE__ from './predicates-blocks-pair-editor.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { copy } from 'ember-copy';
import { inject as service } from '@ember/service';

export default class PredicatesBlocksPairEditor extends Component {
  @service store;

  @action
  onChangePredicates(newPredicates) {
    this.args.model.predicateGroup.predicates = copy(newPredicates) || [];
  }
}
