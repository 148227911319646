/* import __COLOCATED_TEMPLATE__ from './feature-list.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/addon/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  allFeatures: string[];
  availableFeaturesForApp: string[];
}

interface Signature {
  Args: Args;
}

interface TableData {
  feature: string;
  feature_description: string;
  currentPlan: boolean;
}

export default class BillingFeaturesFeatureListComponent extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked showFeatureDescription = true;
  @tracked searchTerm = '';
  @tracked initialTableData: Array<TableData> = [];
  @tracked tableData: Array<TableData> = [];

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.createTableData();
  }

  get app() {
    return this.appService.app;
  }

  get customer() {
    return this.customerService.customer;
  }

  createTableData() {
    let data: any = [];
    this.args.allFeatures.forEach((feature) => {
      data.push({
        feature: this.intl.t('pricing-and-packaging.features.'.concat(feature)),
        feature_description: this.intl.t(
          'pricing-and-packaging.features.'.concat(feature).concat('_tooltip'),
        ),
        currentPlan: this.args.availableFeaturesForApp.includes(feature),
      });
    });

    data = data.reject((feature: any) => {
      return !feature.currentPlan;
    });

    data = data.sort((a: any, b: any) =>
      a.feature.localeCompare(b.feature, undefined, { sensitivity: 'base' }),
    );

    this.initialTableData = this.tableData = data;
  }

  @action search(event: InputEvent) {
    let target = event.target as HTMLInputElement;
    let value = String(target.value);

    this.tableData = this.initialTableData.reject((feature: any) => {
      if (value && feature.currentPlan) {
        return !feature.feature.toLowerCase().includes(value.toLowerCase());
      }

      return !feature.currentPlan;
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Features::FeatureList': typeof BillingFeaturesFeatureListComponent;
  }
}
