/* import __COLOCATED_TEMPLATE__ from './payment.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class Payment extends Component {
  @service appService;
  @service customerService;

  get app() {
    return this.appService.app;
  }

  get displayMonthlyBillingDateInfo() {
    let hasAnInvoiceDay = this.customer.subscription?.invoiceDayOfMonth !== undefined;
    let shouldHideInvoiceDay =
      this.app.isSalesforceContracted || this.customerService.isSelfServeAnnualCustomer;

    return hasAnInvoiceDay && !shouldHideInvoiceDay;
  }

  get customer() {
    return this.customerService.customer;
  }

  get collectVatNumber() {
    return this.customer.shippingAddress?.inEu || this.customer.billingAddress?.inEu;
  }
}
