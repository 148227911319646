/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import type Checklist from 'embercom/models/checklists/checklist';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Task from 'embercom/models/checklists/task';
import { ActionType } from 'embercom/models/checklists/task';
import { tracked } from '@glimmer/tracking';
import { isEmpty, isPresent } from '@ember/utils';
import type TaskGroup from 'embercom/models/checklists/task-group';
import { DEFAULT_ESTIMATED_TASK_TIME_SECONDS } from 'embercom/models/data/checklists/constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { type ContentObjectQuery } from 'embercom/services/content-object-service';
import type ContentObjectService from 'embercom/services/content-object-service';
import { taskFor } from 'ember-concurrency-ts';
import { simpleConfettiShoot } from 'embercom/lib/checklists/confetti';
import confetti from 'canvas-confetti';
// @ts-ignore
import { globalRef } from 'ember-ref-bucket';
import type Store from '@ember-data/store';

interface Args {
  checklist: Checklist;
  isEditing: boolean;
  shouldShowValidations: boolean;
}

export enum ChecklistScreen {
  Main = 0,
  Completion = 1,
}

export default class ChecklistEditorComponent extends Component<Args> {
  @service declare store: Store;
  @service declare contentObjectService: ContentObjectService;
  @service declare appService: any;
  @tracked activeTask?: Task;
  @tracked openSectionId?: string;
  @tracked currentScreen: ChecklistScreen = ChecklistScreen.Main;
  @globalRef('completion-screen-confetti-canvas') declare confettiCanvas?: HTMLCanvasElement;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.getTourContentObjects(); // preloads tour info for <ContentEventSelector /> component
    this.getArticleContentObjects();
  }

  private async getTourContentObjects() {
    let query = {
      object_type: objectTypes.tour,
      show_only_live_content: true,
    } as ContentObjectQuery;

    taskFor(this.contentObjectService.objectSearch).perform(query);
  }

  private async getArticleContentObjects() {
    let query = {
      object_type: objectTypes.article,
      show_only_live_content: true,
    } as ContentObjectQuery;

    taskFor(this.contentObjectService.objectSearch).perform(query);
  }

  get app() {
    return this.appService.app;
  }

  get checklist() {
    return this.args.checklist;
  }

  get id() {
    return this.checklist.id;
  }

  get firstTaskGroup() {
    return this.checklist.firstTaskGroup;
  }

  get tasks() {
    return this.firstTaskGroup.nonDeletedTasks;
  }

  get allTasks() {
    return this.checklist.tasks;
  }

  get shouldShowTaskSettings() {
    return isPresent(this.activeTask);
  }

  get shouldShowCompletionScreenSettings() {
    return this.currentScreen === ChecklistScreen.Completion;
  }

  get shouldShowValidations() {
    if (!this.app.showChecklistsInlineValidations) {
      return false;
    }
    return this.args.shouldShowValidations;
  }

  private createTaskGroup(): TaskGroup {
    let newTaskGroup = this.store.createRecord('checklists/taskGroup', {
      order: this.checklist.taskGroups.length,
    });
    this.checklist.taskGroups.pushObject(newTaskGroup);
    return newTaskGroup;
  }

  @action showGeneralSettings() {
    if (this.activeTask) {
      this.activeTask.isActiveTask = false;
    }
    this.activeTask = undefined;
    this.openSectionId = undefined;
    this.currentScreen = ChecklistScreen.Main;
  }

  @action onOpenSectionChange(sectionId: string) {
    if (!sectionId) {
      return;
    }
    this.openSectionId = sectionId;

    let newActiveTask = this.tasks.find((task) => task.accordionSectionId === sectionId);
    if (this.activeTask) {
      this.activeTask.isActiveTask = false;
    }
    this.activeTask = newActiveTask;
    if (this.activeTask) {
      this.activeTask.isActiveTask = true;
    }
  }

  @action addTask() {
    let taskGroup = this.firstTaskGroup ?? this.createTaskGroup();

    let newTask = this.store.createRecord('checklists/task', {
      order: taskGroup.nonDeletedTasks.length,
      actionType: ActionType.ReadOnly,
      estimatedTime: DEFAULT_ESTIMATED_TASK_TIME_SECONDS,
      predicateGroup: { predicates: [] },
      eventPredicateGroup: { predicates: [] },
    });

    taskGroup.tasks.pushObject(newTask);
    if (this.activeTask) {
      this.activeTask.isActiveTask = false;
    }
    newTask.isActiveTask = true;
    this.activeTask = newTask;
    this.openSectionId = newTask.accordionSectionId;

    this.scrollToBottom();
  }

  private scrollToBottom() {
    setTimeout(() => {
      let taskListDiv = document.getElementById('task-list-scroll-div');
      if (taskListDiv) {
        taskListDiv.scrollTop = taskListDiv.scrollHeight;
      }
    }, 0);
  }

  @action deleteTask(taskToDelete: Task) {
    if (this.firstTaskGroup) {
      let deletingActiveTask = this.activeTask === taskToDelete;

      taskToDelete.deleteRecord();
      this.firstTaskGroup.tasks
        .filter((task: Task) => task.order > taskToDelete.order)
        .forEach((task: Task) => (task.order -= 1));

      if (deletingActiveTask) {
        this.showGeneralSettings();
      }
    }
  }

  @action onHeaderClick() {
    this.showGeneralSettings();
    this.openSectionId = undefined;
  }

  @action onTaskHeaderConstructor() {
    this.maintainActiveTaskAfterAutosave();
  }

  @action showMainScreen() {
    this.currentScreen = ChecklistScreen.Main;
  }

  @action showCompletionScreen() {
    this.currentScreen = ChecklistScreen.Completion;
    if (this.checklist.showConfetti) {
      setTimeout(() => {
        this.fireConfettiAnimation();
      }, 500);
    }
  }

  private fireConfettiAnimation() {
    if (!this.confettiCanvas) {
      return;
    }
    let confettiObj = confetti.create(this.confettiCanvas, {
      resize: true,
    });
    simpleConfettiShoot(confettiObj);
  }

  private maintainActiveTaskAfterAutosave() {
    if (!this.openSectionId) {
      return;
    }

    let duplicateTaskOrders = this.getDuplicateTaskOrders();
    if (isEmpty(duplicateTaskOrders)) {
      return;
    }

    let oldActiveTask = this.allTasks.find((task) => {
      return duplicateTaskOrders.includes(task.order) && task.isActiveTask;
    });

    if (!oldActiveTask) {
      return;
    }

    let newActiveTask = this.allTasks.find(
      (task) => task.id && task.order === oldActiveTask!.order,
    );

    if (!newActiveTask) {
      return;
    }

    if (newActiveTask === this.activeTask) {
      return;
    }
    newActiveTask.isActiveTask = true;
    this.activeTask = newActiveTask;
    this.openSectionId = newActiveTask.accordionSectionId;
  }

  private getDuplicateTaskOrders() {
    // Counting task occurences in tasks list using a map {order => count}
    // In the usual scenario each order will appear once
    // Eg. {0 => 1, 1 => 1, 2 => 1}
    //
    // However, new steps will occur twice after an autosave
    // Eg. Adding a new step, and after autosaving will be
    // {0 => 1, 1 => 1, 2 => 1, 3 => 2}
    //
    // Why?
    // Ans: We don't delete the old task records until after the new tasks comopnents constructors fire

    let ordersCount: Map<number, number> = new Map();
    this.allTasks.forEach((task: Task) => {
      let count = ordersCount.get(task.order);
      ordersCount.set(task.order, count ? count + 1 : 1);
    });

    let nonUniqueOrders: number[] = [];
    ordersCount.forEach((value, key) => {
      if (value > 1) {
        nonUniqueOrders.push(key);
      }
    });
    return nonUniqueOrders;
  }

  @action reorderTasks(tasks: Task[]) {
    for (let i = 0; i < tasks.length; i++) {
      tasks[i].order = i;
    }
    this.firstTaskGroup.set('tasks', tasks);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Editor': typeof ChecklistEditorComponent;
    'checklist/editor': typeof ChecklistEditorComponent;
  }
}
