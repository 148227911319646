/* import __COLOCATED_TEMPLATE__ from './day-hour-minute-input.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { action } from '@ember/object';

export default class CommonDayHourMinuteInputComponent extends Component {
  durations = undefined;

  constructor() {
    super(...arguments);
    let milliseconds = this.args.duration;
    let duration = moment.duration(milliseconds);
    this.durations = {
      days: Math.floor(duration.asDays()),
      hours: Math.floor(duration.asHours()) % 24,
      minutes: Math.floor(duration.asMinutes()) % 60,
    };
  }

  @action onUpdate() {
    let time =
      moment.duration(Number(this.durations.days), 'days').asMilliseconds() +
      moment.duration(Number(this.durations.hours), 'hours').asMilliseconds() +
      moment.duration(Number(this.durations.minutes), 'minutes').asMilliseconds();
    if (this.args.onUpdate) {
      this.args.onUpdate(time);
    }
  }
}
