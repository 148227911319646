/* import __COLOCATED_TEMPLATE__ from './selector-item-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class SelectorItemComponent extends Component {
  @action
  clickHandler() {
    this.args.itemAction?.(this.args.admin);
  }
}
