/* import __COLOCATED_TEMPLATE__ from './invite-teammates.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

export default class InviteTeammates extends Component {
  @service appService;
  @service appstoreService;
  @service intercomEventService;
  @service store;
  @service notificationsService;

  @tracked selectedValue;
  @tracked selectedAdminIds = [];
  @tracked isSaving = false;
  @tracked isLoaded = false;

  get appPackage() {
    return this.args.appPackage;
  }

  get allAdminsExceptLoggedIn() {
    return this.appService.app.humanAdmins.filter(
      (admin) => admin.id !== this.appService.app.currentAdmin.id,
    );
  }

  get selectedAdmins() {
    return this.allAdminsExceptLoggedIn.filter((admin) => this.selectedAdminIds.includes(admin.id));
  }

  get notSelectedAdmins() {
    return this.allAdminsExceptLoggedIn.filter(
      (admin) => !this.selectedAdminIds.includes(admin.id),
    );
  }

  get availableAdmins() {
    return this.notSelectedAdmins.map((admin) => {
      return {
        text: admin.name,
        value: admin.id,
        model: admin,
        component: 'appstore/app-package/onboarding/invite-teammates-item',
      };
    });
  }

  trackAnalyticsEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      action: `invite_form_${action}`,
      place: 'app_package_onboarding_tab',
      object: this.appPackage,
    });
  }

  @action addAdmin(id) {
    this.selectedAdminIds = this.selectedAdminIds.concat(id);
    this.selectedValue = undefined;
    this.trackAnalyticsEvent('add_admin');
  }

  @action removeAdmin(id) {
    this.selectedAdminIds = this.selectedAdminIds.filter((selectedId) => selectedId !== id);
    this.trackAnalyticsEvent('remove_admin');
  }

  @action send() {
    let { appPackage, selectedAdminIds: adminIds } = this;
    let invitation = this.store.createRecord('appstore/app-package/invitation', {
      adminIds,
      appPackage,
    });
    this.sendInvitation.perform(invitation);
    this.trackAnalyticsEvent('send');
  }

  @task
  *sendInvitation(invitation) {
    this.isSaving = true;
    try {
      yield invitation.save();
      this.selectedAdminIds = [];
      this.notificationsService.notifyConfirmation('Invites have been successfully sent');
    } catch (error) {
      this.notificationsService.notifyError('Something went wrong. Try again later.');
    }
    this.isSaving = false;
  }
}
