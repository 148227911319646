/* import __COLOCATED_TEMPLATE__ from './hide-usage-details-toggle.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    onClick: () => void;
    groupIdentifier: 'sms' | 'whatsapp' | 'email';
  };
}
const HideUsageDetailsToggle = templateOnlyComponent<Signature>();
export default HideUsageDetailsToggle;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::HideUsageDetailsToggle': typeof HideUsageDetailsToggle;
  }
}
