/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import QualificationAttribute from 'embercom/models/qualification/attribute';
import { task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';

export default Component.extend({
  notificationsService: service(),
  store: service(),
  classNames: ['flex-auto'],
  appSettingsUrl: '/ember/messenger_apps/get_a_demo/settings',

  init() {
    this._super(...arguments);
    this.fetchSettings.perform();
    this.registerHooks(this.getProperties('afterUninstall'));
  },

  fetchSettings: task(function* () {
    let settings = yield this.store.queryRecord('appstore/app-package/get-a-demo/app-setting', {});
    this.set('settings', settings);
  }).drop(),

  afterUninstall: task(function* () {
    yield ajax({
      url: this.appSettingsUrl,
      type: 'DELETE',
      data: JSON.stringify({ app_id: this.get('app.id') }),
    });
  }).drop(),

  removeInvalidAttributes() {
    this.set(
      'settings.qualificationAttributes',
      QualificationAttribute.removeInvalidFragments(this.get('settings.qualificationAttributes')),
    );
  },

  updateSettings: task(function* () {
    this.removeInvalidAttributes();
    yield ajax({
      url: this.appSettingsUrl,
      type: 'PUT',
      data: JSON.stringify(this.settings.serialize()),
    })
      .then((response) => {
        this.notificationsService.notifyConfirmation(
          'Your settings have been successfully updated',
        );
      })
      .catch((response) => {
        this.notificationsService.notifyError(response.jqXHR.responseJSON.errors);
      });
  }).drop(),

  save: action(function () {
    this.updateSettings.perform();
  }),
});
