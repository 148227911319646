/* import __COLOCATED_TEMPLATE__ from './block-numbers.hbs'; */
/*
RESPONSIBLE TEAM: team-phone
*/

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import PhoneCountryCodes, {
  type CountryCode,
  countryFromPhoneNumber,
  countryFromIncompleteNumber,
} from 'embercom/lib/phone-codes';
import { type Country } from 'embercom/lib/phone-codes';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { ajaxDelete, get, post } from 'embercom/lib/ajax';
import { taskFor } from 'ember-concurrency-ts';
import phoneCodes from 'embercom/lib/phone-codes';

interface Signature {
  Args: {};
}

interface BlockedNumber {
  id: number;
  number: string;
  country: Country;
}

interface BlockedNumberModel {
  id: number;
  app_id: number;
  phone_number: string;
  admin_id: number;
  created_at: string;
  updated_at: string;
}

export default class BlockNumbers extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;

  @tracked showingModal = false;
  @tracked blockedNumbers: BlockedNumber[] = [];
  @tracked selectedCountry: Country = phoneCodes['US'];
  @tracked phoneNumberInput = '';

  @dropTask
  *fetchBlockedNumbers(): TaskGenerator<BlockedNumberModel[]> {
    return yield get(`/ember/calling_blocked_numbers`, {
      app_id: this.appService.app.id,
    }) as BlockedNumberModel[];
  }

  @action
  async openModal(event?: Event) {
    event?.preventDefault();
    this.showingModal = true;
    this.phoneNumberInput = this.selectedCountry.dial_code;
    await this.loadBlockedNumbers();
  }

  @action closeModal() {
    this.showingModal = false;
    this.phoneNumberInput = '';
    this.blockedNumbers = [];
  }

  @action
  async loadBlockedNumbers(): Promise<void> {
    let numbers: BlockedNumberModel[] = await taskFor(this.fetchBlockedNumbers).perform();
    this.blockedNumbers = numbers.map((number) => this.numberFromModel(number));
    this.sortBlockedNumbers();
  }

  @action
  async blockNumber() {
    if (!this.phoneNumberInput) {
      return;
    }

    try {
      let response = await post('/ember/calling_blocked_numbers', {
        app_id: this.appService.app.id,
        phone_number: this.phoneNumberInput,
      });
      let formattedNumber = response.phone_number;
      let country = countryFromPhoneNumber(formattedNumber);

      let newBlockedNumber: BlockedNumber = {
        id: response.id,
        number: formattedNumber,
        country,
      };

      this.blockedNumbers.push(newBlockedNumber);
      this.sortBlockedNumbers();
      this.phoneNumberInput = this.selectedCountry.dial_code;

      this.notificationsService.notifyConfirmation(
        this.intl.t('calling.settings.block-numbers.block-success'),
      );
    } catch (error) {
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      if (error.jqXHR.responseText?.includes('already blocked')) {
        this.notificationsService.notifyError(
          this.intl.t('calling.settings.block-numbers.number-already-blocked'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('calling.settings.block-numbers.block-error'),
        );
      }
    }
  }

  @action
  async unBlockNumber(blockedNumber: BlockedNumber) {
    try {
      await ajaxDelete(`/ember/calling_blocked_numbers/${blockedNumber.id}`, {
        app_id: this.appService.app.id,
      });
      this.blockedNumbers = this.blockedNumbers?.filter((n) => n.id !== blockedNumber.id);
      this.notificationsService.notifyConfirmation(
        this.intl.t('calling.settings.block-numbers.unblock-success'),
      );
    } catch (_) {
      this.notificationsService.notifyError(
        this.intl.t('calling.settings.block-numbers.unblock-error'),
      );
    }
  }

  @action
  phoneNumberInputChanged(event?: Event) {
    let phoneNumber = (event?.target as HTMLInputElement)?.value;
    this.selectedCountry = countryFromIncompleteNumber(phoneNumber) ?? this.selectedCountry;
  }

  @action
  selectCountry(countryCode: CountryCode) {
    this.selectedCountry = phoneCodes[countryCode];
    this.phoneNumberInput = this.selectedCountry.dial_code;
  }

  numberFromModel(number: BlockedNumberModel): BlockedNumber {
    return {
      id: number.id,
      number: number.phone_number,
      country: countryFromPhoneNumber(number.phone_number),
    };
  }

  get isLoading() {
    return taskFor(this.fetchBlockedNumbers).isRunning;
  }

  get tableIsEmpty() {
    return !this.isLoading && this.blockedNumbers.length === 0;
  }

  get tableSize() {
    return `${this.blockedNumbers.length}`;
  }

  get submitButtonDisabled() {
    return this.phoneNumberInput.length < 5;
  }

  get countries() {
    return Object.entries(PhoneCountryCodes).map(([countryCode, country]) => ({
      text: this.intl.t(country.nameTranslationKey),
      value: countryCode,
      component: 'inbox2/country-code-option',
      componentAttrs: {
        name: this.intl.t(country.nameTranslationKey),
        code: country.dial_code,
        flagUrl: country.asset_url,
      },
    }));
  }

  sortBlockedNumbers() {
    let getCountryFromNumber = (number: BlockedNumber) =>
      this.intl.t(number.country.nameTranslationKey);

    this.blockedNumbers = this.blockedNumbers.sort((a, b) =>
      getCountryFromNumber(a).localeCompare(getCountryFromNumber(b)),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::BlockNumbers': typeof BlockNumbers;
    'calling/settings/block-numbers': typeof BlockNumbers;
  }
}
