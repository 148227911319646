/* import __COLOCATED_TEMPLATE__ from './supporting-document-modal.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { action, notifyPropertyChange } from '@ember/object';
import type RegulatoryAddress from 'embercom/models/settings/calling/regulatory_address';
import { omit } from 'underscore';
import { type SuggestedAddress } from '../phone-number-provisioning/regulatory-address-section';
import { type RegulatoryBundleArgs } from './phone-number-type-section';
import type RouterService from '@ember/routing/router-service';

export interface UploadedSupportingDocument {
  sid: string;
  name: string;
  type: string;
  attributes: any;
}

interface Args {
  bundleSid: string;
  regulatoryBundle: RegulatoryBundleArgs;
  supportingDocumentsRequirement: any;
  supportingDocuments: UploadedSupportingDocument[];
  supportingDocumentTypes: { value: string; text: string }[];
  selectedDocument?: UploadedSupportingDocument;
  readOnly?: boolean;
  fileUploadNeeded: boolean;
  closeModal: () => void;
  onSupportingDocumentCreated: (UploadedSupportingDocument: UploadedSupportingDocument) => void;
}

export default class SupportingDocumentModal extends Component<Args> {
  @service declare appService: any;
  @service notificationsService: any;
  @service intl: any;
  @service declare router: RouterService;

  @tracked selectedDocumentType?: string = this.args.selectedDocument?.type;
  @tracked attributes: any = this.args.selectedDocument?.attributes || {};
  @tracked file?: File;
  @tracked useSelectedDocumentForAddressProof =
    this.args.selectedDocument?.attributes.address_sids?.length;

  @tracked regulatoryAddress?: RegulatoryAddress;
  @tracked regulatoryAddressSid?: string = this.args.selectedDocument?.attributes.address_sids?.[0];
  @tracked regulatoryAddressValidationError?: string;
  @tracked regulatoryAddressSuggestedFields?: SuggestedAddress;

  constructor(owner: any, args: Args) {
    super(owner, args);
    if (this.args.selectedDocument) {
      this.file = new File([], this.args.selectedDocument.name);
    }
  }

  get remainingDocumentTypes() {
    if (this.args.readOnly) {
      return this.args.supportingDocumentTypes;
    }

    return this.args.supportingDocumentTypes.filter(
      (documentType: any) =>
        !this.args.supportingDocuments.any((doc: any) => doc.type === documentType.value),
    );
  }

  get documentTypeToRequirements() {
    let object: any = {};
    this.args.supportingDocumentsRequirement.forEach((document: any) => {
      document.accepted_documents.forEach((acceptedDocument: any) => {
        object[acceptedDocument.type] = object[acceptedDocument.type] || {
          requirements: [],
          detailed_fields: [],
        };
        object[acceptedDocument.type].requirements.addObject(document.requirement_name);
        object[acceptedDocument.type].detailed_fields.addObjects(acceptedDocument.detailed_fields);
      });
    });
    return object;
  }

  identityFieldsForDocumentType(documentType: string) {
    return this.documentTypeToRequirements[documentType].detailed_fields.filter(
      (field: any) => field.machine_name !== 'address_sids',
    );
  }

  get identityFieldsForSelectedDocumentType() {
    if (!this.selectedDocumentType) {
      return [];
    }

    return this.identityFieldsForDocumentType(this.selectedDocumentType);
  }

  doesDocumentTypeHaveAddress(documentType: string) {
    return this.documentTypeToRequirements[documentType].detailed_fields.any(
      (field: any) => field.machine_name === 'address_sids',
    );
  }

  get doesSelectedDocumentTypeHaveAddress() {
    if (!this.selectedDocumentType) {
      return false;
    }

    return this.doesDocumentTypeHaveAddress(this.selectedDocumentType);
  }

  doesDocumentTypeHaveIdentityFields(documentType: string) {
    return this.documentTypeToRequirements[documentType].detailed_fields.any(
      (field: any) => field.machine_name !== 'address_sids',
    );
  }

  get doesSelectedDocumentTypeHaveIdentityFields() {
    if (!this.selectedDocumentType) {
      return false;
    }

    return this.doesDocumentTypeHaveIdentityFields(this.selectedDocumentType);
  }

  @action
  selectDocumentType(type: string) {
    this.selectedDocumentType = type;
    // Lets default to using the address when its an address proof
    this.useSelectedDocumentForAddressProof = this.doesSelectedDocumentTypeHaveAddress;
  }

  @action
  onFileSelect(file: File) {
    this.file = file;
  }

  @action
  onAddressSelected(addressSid: string) {
    this.regulatoryAddressValidationError = undefined;
    this.regulatoryAddressSuggestedFields = undefined;
    this.regulatoryAddressSid = addressSid;
    this.regulatoryAddress = undefined;
  }

  @action
  onAddressCreated(address: RegulatoryAddress) {
    this.regulatoryAddressValidationError = undefined;
    this.regulatoryAddressSuggestedFields = undefined;
    this.regulatoryAddress = address;
    this.regulatoryAddressSid = undefined;
  }

  @action
  onIdentityFieldChange(field: any, value: any) {
    if (field.machine_name) {
      this.attributes[field.machine_name] = value;
    }
    notifyPropertyChange(this, 'attributes');
  }

  get isAddressValid() {
    if (!this.regulatoryAddress && !this.regulatoryAddressSid) {
      return false;
    }

    if (this.regulatoryAddress) {
      if (!this.regulatoryAddress.validations.isValid) {
        return false;
      }
    }

    return true;
  }

  get shouldShowAddressSelectorForFile() {
    return this.doesSelectedDocumentTypeHaveAddress && this.useSelectedDocumentForAddressProof;
  }

  get isFormValid() {
    if (!this.selectedDocumentType) {
      return false;
    }

    if (this.args.fileUploadNeeded) {
      if (!this.file) {
        return false;
      }

      if (this.shouldShowAddressSelectorForFile) {
        if (!this.isAddressValid) {
          return false;
        }
      }
    } else if (!this.isAddressValid) {
      return false;
    }

    if (this.doesSelectedDocumentTypeHaveIdentityFields) {
      return this.identityFieldsForSelectedDocumentType.every(
        (field: any) => this.attributes[field.machine_name],
      );
    }

    return true;
  }

  get isSms() {
    return this.router.currentRouteName.includes('settings.channels.sms.phone-regulatory-bundles');
  }

  @restartableTask
  *createSupportingDocument() {
    if (!this.selectedDocumentType) {
      return;
    }

    let formData = new FormData();
    formData.append('app_id', this.appService.app.id);
    formData.append('bundle_sid', this.args.bundleSid);
    formData.append('type', this.selectedDocumentType);
    formData.append('attributes', JSON.stringify(this.attributes));
    if (this.regulatoryAddressSid) {
      formData.append('address_sid', this.regulatoryAddressSid);
    }
    if (this.file) {
      formData.append('file', this.file);
    }
    if (this.regulatoryAddress) {
      formData.append(
        'regulatory_address',
        JSON.stringify(omit(this.regulatoryAddress.serialize(), 'sid')),
      );
    }

    if (this.isSms) {
      formData.append('for_sms', 'true');
    }

    try {
      let response: { supporting_document_sid: string } = yield ajax({
        type: 'POST',
        url: '/ember/calling_settings/create_supporting_document_for_bundle',
        data: formData,
        processData: false,
        contentType: false,
      });
      if (!response.supporting_document_sid) {
        throw new Error('No supporting document sid returned');
      }

      let attributesWithAddressSid = this.attributes;
      if (this.regulatoryAddressSid) {
        attributesWithAddressSid = {
          ...attributesWithAddressSid,
          address_sids: [this.regulatoryAddressSid],
        };
      }
      this.args.onSupportingDocumentCreated({
        sid: response.supporting_document_sid,
        name:
          this.file?.name || this.intl.t('calling.settings.phone-regulatory-bundle.no-document'),
        type: this.selectedDocumentType,
        attributes: attributesWithAddressSid,
      });
      this.args.closeModal();
    } catch (err) {
      if (err.jqXHR?.status === 422 && err.jqXHR?.responseJSON.address_validation_error) {
        this.regulatoryAddressValidationError = err.jqXHR.responseJSON.address_validation_error;
        this.regulatoryAddressSuggestedFields = err.jqXHR.responseJSON.suggested_address;
      } else {
        this.notificationsService.notifyError(
          this.intl.t(
            'calling.settings.phone-regulatory-bundle.error-uploading-supporting-document',
          ),
        );
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhoneRegulatoryBundle::SupportingDocumentModal': typeof SupportingDocumentModal;
    'calling/settings/phone-regulatory-bundle/supporting-document-modal': typeof SupportingDocumentModal;
  }
}
