/* import __COLOCATED_TEMPLATE__ from './connect-instagram.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import ENV from 'embercom/config/environment';
import { action } from '@ember/object';

export default class ConnectInstagram extends Component {
  @service store;
  @service appService;
  @service notificationsService;
  @service intercomEventService;
  @service fb;
  @service intl;

  @tracked pagesToInstagramAccounts = {};
  @tracked accessToken;
  @tracked selectedPageId;

  constructor() {
    super(...arguments);
    let app = this.appService.app;
    this.initFacebook(this.fb, app);
  }

  get isConnectedToFacebook() {
    return isPresent(this.accessToken);
  }

  get existingInstagramAccounts() {
    return Object.keys(this.pagesToInstagramAccounts).length > 0;
  }

  get connectedIntegrations() {
    return this.store.peekAll('instagram/integration');
  }

  get instagramAccounts() {
    return Object.entries(this.pagesToInstagramAccounts).map(([pageId, instagramAccount]) => {
      let alreadyConnected = this.connectedIntegrations.any(
        (integration) => integration.instagramId === instagramAccount.instagramAccountId,
      );
      return {
        pageId,
        instagramAccount,
        alreadyConnected,
      };
    });
  }

  @task({ drop: true })
  *startInstagramOauth() {
    this.trackEvent('connect_instagram');
    try {
      let response = yield this.fb.login(
        'instagram_basic,instagram_manage_messages,pages_manage_metadata,pages_show_list,pages_read_engagement,business_management',
      );
      yield this.loadInstagramAccounts.perform();
      this.accessToken = response.authResponse?.accessToken;
    } catch (e) {
      this.errorNotification(
        e,
        this.intl.t('appstore.settings.instagram.connect-instagram.notification.auth-error'),
      );
    }
  }

  @task({ drop: true })
  *loadInstagramAccounts() {
    let resp = yield this.fb.api('/me/accounts?fields=connected_instagram_account{username}');
    resp.data.forEach((facebookPage) => {
      if (facebookPage.connected_instagram_account) {
        if (!this.selectedPageId) {
          this.selectedPageId = facebookPage.id;
        }
        this.pagesToInstagramAccounts[facebookPage.id] = {
          instagramAccountId: facebookPage.connected_instagram_account.id,
          username: facebookPage.connected_instagram_account.username,
        };
      }
    });
    return this.pagesToInstagramAccounts;
  }

  @task({ enqueue: true })
  *confirmInstagramAccount(pageId) {
    try {
      let data = {
        app_id: this.appService.app.id,
        access_token: this.accessToken,
        page_id: pageId,
        instagram_id: this.pagesToInstagramAccounts[pageId]?.instagramAccountId,
        instagram_username: this.pagesToInstagramAccounts[pageId]?.username,
      };
      let response = yield post('/ember/instagram/integrations/initialize', data);
      this.notificationsService.notifyConfirmation(
        this.intl.t('appstore.settings.instagram.connect-instagram.notification.connect-success'),
      );
      this.trackEvent('instagram_connected');
      this.store.pushPayload({ 'instagram/integration': response });
    } catch (e) {
      this.errorNotification(
        e,
        this.intl.t('appstore.settings.instagram.connect-instagram.notification.connect-error'),
      );
    }
  }

  @action
  openOauth() {
    this.startInstagramOauth.perform();
  }

  @action
  closeOnboarding() {
    this.accessToken = null;
  }

  @action
  confirmAccount(pageId) {
    this.confirmInstagramAccount.perform(pageId);
  }

  errorNotification(e, defaultMessage) {
    console.error(e);
    this.notificationsService.notifyResponseError(
      e,
      {
        default: defaultMessage,
      },
      {
        responseProvidedErrors: [403, 422],
        duration: 10000,
      },
    );
  }

  trackEvent(object) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      current_tab: 'settings',
      app_package_code: 'instagram-pv4',
    });
  }

  async initFacebook(fb, app) {
    let initSettings = {
      appId: ENV.APP.whatsapp.appId,
      version: app.koala_api_version,
      xfbml: true,
    };
    await fb.FBInit(initSettings);
    window.FB.init(initSettings);
  }
}
