/* import __COLOCATED_TEMPLATE__ from './expanding-one-way-input.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';
import { action } from '@ember/object';

const keyActions = {
  13: 'onenter',
  27: 'onescape',
};

export default class ExpandingOneWayInput extends Component {
  get value() {
    return this.args.value;
  }

  get placeholder() {
    return this.args.placeholder;
  }

  @action
  handleKeyUp(event) {
    let actionName = keyActions[event.keyCode];
    let action = this.args?.[actionName];

    if (action) {
      action();
    }
  }

  resizeInput = modifier((element, params, hash) => {
    let elementType = element.type;
    let valueIsEmpty = element.value === '';
    // temporarily set the element's value to the placeholder value
    // so that the element will be wide enough to display it
    // clear the element's type attribute, so the placeholder can contain
    // a value that's not actually valid
    // (e.g. type='number' placeholder='123...')
    if (valueIsEmpty) {
      element.type = '';
      element.value = element.placeholder || '';
    }

    element.style.width = '0';
    // we need 1 pixel extra 'padding' for the cursor - see issue #35333
    element.style.width = `${element.scrollWidth + 1}px`;

    if (valueIsEmpty) {
      // revert our temporary swap-in of the placeholder
      element.type = elementType;
      element.value = '';
    }
  });
}
