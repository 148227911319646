/* import __COLOCATED_TEMPLATE__ from './line-item-row-description.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
export default Component.extend({
  classNames: ['invoice__line-item-row__description'],
  tagName: 'td',
  appService: service(),
  app: readOnly('appService.app'),

  pricedOnAgents: computed('lineItem', function () {
    return (
      this.get('lineItem.productAndPlanNames').match('Respond') ||
      this.get('lineItem.productAndPlanNames').match('Inbox')
    );
  }),
  pricedOnMaps: computed('lineItem', function () {
    return (
      this.get('lineItem.productAndPlanNames').match('Engage') ||
      this.get('lineItem.productAndPlanNames').match('Messages')
    );
  }),
  educate: computed('lineItem', function () {
    return (
      this.get('lineItem.productAndPlanNames').match('Educate') ||
      this.get('lineItem.productAndPlanNames').match('Articles')
    );
  }),
});
