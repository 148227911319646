/* import __COLOCATED_TEMPLATE__ from './grid-footer.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  intercomEventService: service(),
  actions: {
    trackClick() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'explore_api_link',
      });
    },
  },
});
