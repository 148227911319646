/* import __COLOCATED_TEMPLATE__ from './button-settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Checklist from 'embercom/models/checklists/checklist';
import { CompletionActionTypes } from 'embercom/models/checklists/checklist';

interface Args {
  checklist: Checklist;
}

export default class CompletionScreenButtonSettings extends Component<Args> {
  @service declare intl: IntlService;

  get checklist() {
    return this.args.checklist;
  }

  get actionItems() {
    return [
      {
        text: this.intl.t('outbound.checklists.completion-screen-settings.actions.close-screen'),
        value: CompletionActionTypes.CLOSE_COMPLETION_SCREEN,
        icon: 'close',
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Sidebar::CompletionScreenSettings::ButtonSettings': typeof CompletionScreenButtonSettings;
    'checklist/sidebar/completion-screen-settings/button-settings': typeof CompletionScreenButtonSettings;
  }
}
