/* import __COLOCATED_TEMPLATE__ from './tour-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { isPresent } from '@ember/utils';

export default class TourSelector extends Component {
  @service appService;
  @service store;
  @tracked tourInserterApp = null;

  constructor() {
    super(...arguments);
    this.fetchTourInserterApp.perform();
  }

  get app() {
    return this.appService.app;
  }

  get tourTitle() {
    return this.localizedBannerContent.actionTitle;
  }

  get tourInserterAppExists() {
    return isPresent(this.tourInserterApp);
  }

  @action selectProductTour(tourCardJSON) {
    let tourId = tourCardJSON.canvas.stored_data.tour_id;
    this.args.localizedBannerContent.actionContentId = tourId;
    this.args.localizedBannerContent.actionContentType = objectTypes.tour;

    let tourTitle = tourCardJSON.canvas.content.components[0].text;
    this.args.localizedBannerContent.actionContentTitle = tourTitle;
  }

  @task
  *fetchTourInserterApp() {
    let tourInserterApps = yield this.store.query('messenger-app', {
      app_package_id: 'intercom-tours',
    });
    this.tourInserterApp = tourInserterApps.firstObject;
  }
}
