/* import __COLOCATED_TEMPLATE__ from './api-usage.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import trustedStyle from '@intercom/pulse/lib/trusted-style';
import { INSTALL_STATUS_CTA_TYPES } from 'embercom/components/appstore/app-package/install-message-unhealthy-status';
import { APP_PACKAGE_INSTALL_STATES } from 'embercom/models/appstore/app-package';

const MIN_INTERCOM_API_LIMIT = 0;
const API_LIMIT_WARNING_PERCENTAGE = 80;

export const API_UNHEALTHY_STATE_MESSAGES = {
  intercomLimitWarning:
    'This workspace will soon hit its limit of Salesforce API calls. This will pause your data sync with Salesforce. Increase the limit in the settings below to avoid this.',
  intercomLimitError:
    'This workspace has hit its limit of Salesforce API calls. Your data sync with Salesforce is paused and the integration won’t work as expected. Increase the limit in the settings below to avoid this.',
  organizationLimitError:
    'You’ve used all of your Salesforce API calls. Your data sync is paused and your other Salesforce integrations may be affected.',
};

export default class ApiUsage extends Component {
  @service notificationsService;
  @service intercomEventService;
  @service appService;
  @service store;
  @tracked copiedIntercomApiLimit;
  @tracked apiUsageData = {};

  constructor() {
    super(...arguments);

    this.fetchApiUsageData.perform();
  }

  @task({ restartable: true })
  *fetchApiUsageData() {
    this.apiUsageData = yield this.store.queryRecord(
      'appstore/app-package/salesforce/api-usage-data',
      { section_id: 'api_usage' },
    );
    this.copiedIntercomApiLimit = this.apiUsageData.settings.apiLimit;
  }

  get invalidApiLimitValue() {
    return (
      this.isInvalidInteger(this.copiedIntercomApiLimit) ||
      this.invalidMinValue ||
      this.invalidMaxValue
    );
  }

  get validationMessage() {
    if (this.isInvalidInteger(this.copiedIntercomApiLimit)) {
      return 'The allocated amount is invalid';
    } else if (this.invalidMinValue) {
      return "The allocated amount can't be lower than 0";
    } else if (this.invalidMaxValue) {
      return "The allocated amount can't be greater than the maximum in Salesforce";
    } else {
      return '';
    }
  }

  isInvalidInteger(value) {
    return isEmpty(value) || isNaN(value) || value % 1 !== 0;
  }

  get otherAppsConsumption() {
    let { organizationApiConsumption, intercomApiConsumption } = this.apiUsageData;

    if (organizationApiConsumption) {
      // Given that we currenty a time bucketing strategy for calculating usage of a 24-hour period
      // which may not match Salesforce's rolling window strategy for the 24-hour period
      // there might be a difference in consumption.
      return Math.max(0, organizationApiConsumption - intercomApiConsumption);
    } else {
      return undefined;
    }
  }

  get invalidMinValue() {
    return this.copiedIntercomApiLimit < MIN_INTERCOM_API_LIMIT;
  }

  get invalidMaxValue() {
    return this.copiedIntercomApiLimit > this.apiUsageData.organizationApiLimit;
  }

  get hasApiLimitChanged() {
    return parseInt(this.copiedIntercomApiLimit, 10) !== this.apiUsageData.settings.apiLimit;
  }

  get intercomApiConsumptionBarStyle() {
    return trustedStyle`width: ${this.getConsumptionPercentage(
      this.apiUsageData.intercomApiConsumption,
    )}%;`;
  }

  get otherAppsApiConsumptionBarStyle() {
    return trustedStyle`width: ${this.getConsumptionPercentage(this.otherAppsConsumption)}%;"`;
  }

  get intercomLimitStyle() {
    return trustedStyle`left: calc(${this.getConsumptionPercentage(
      this.copiedIntercomApiLimit,
    )}% - 1px);`;
  }

  get intercomLimitPercentage() {
    return this.getConsumptionPercentage(this.copiedIntercomApiLimit);
  }

  setIntercomLimitLabelStyle(limitLabelElement, [limitPercentage]) {
    let labelWidth = limitLabelElement.offsetWidth;
    let parentWidth = limitLabelElement.parentElement.offsetWidth;
    let calculatedPosition = (limitPercentage * parentWidth) / 100 - labelWidth / 2;
    let withinBoundsPosition = Math.max(0, Math.min(parentWidth - labelWidth, calculatedPosition));

    limitLabelElement.style = trustedStyle`left: ${withinBoundsPosition}px;`;
  }

  getConsumptionPercentage(consumption) {
    return Math.max(0, Math.min(100, (consumption / this.apiUsageData.organizationApiLimit) * 100));
  }

  getApiHealthState() {
    let apiHealthState = {
      installState: APP_PACKAGE_INSTALL_STATES.ok,
      installStateCtaLabel: null,
      installStateCtaType: null,
      installStateCtaUrl: null,
      installStateMessage: null,
    };

    if (this.apiUsageData) {
      let {
        organizationApiConsumption,
        organizationApiLimit,
        intercomApiConsumption,
        settings: { apiLimit },
      } = this.apiUsageData;

      if (organizationApiConsumption >= organizationApiLimit) {
        apiHealthState.installState = APP_PACKAGE_INSTALL_STATES.unhealthy;
        apiHealthState.installStateCtaType = INSTALL_STATUS_CTA_TYPES.urlCta;
        apiHealthState.installStateMessage = API_UNHEALTHY_STATE_MESSAGES.organizationLimitError;
        apiHealthState.installStateCtaUrl =
          'https://help.salesforce.com/articleView?id=000326126&type=1&mode=1';
        apiHealthState.installStateCtaLabel = 'Learn more';
      } else if (intercomApiConsumption >= apiLimit) {
        apiHealthState.installState = APP_PACKAGE_INSTALL_STATES.unhealthy;
        apiHealthState.installStateMessage = API_UNHEALTHY_STATE_MESSAGES.intercomLimitError;
      } else if (intercomApiConsumption >= (API_LIMIT_WARNING_PERCENTAGE / 100) * apiLimit) {
        apiHealthState.installState = APP_PACKAGE_INSTALL_STATES.unhealthy;
        apiHealthState.installStateMessage = API_UNHEALTHY_STATE_MESSAGES.intercomLimitWarning;
      }
    }

    return apiHealthState;
  }

  updateAppPackageInstallState() {
    let apiHealthState = this.getApiHealthState();

    if (
      apiHealthState.installState === APP_PACKAGE_INSTALL_STATES.ok &&
      !this.isOkOrApiUnhealthyState(this.args.initialInstallState)
    ) {
      this.args.appPackage.setProperties(this.args.initialInstallState);
    } else {
      this.args.appPackage.setProperties(apiHealthState);
    }
  }

  // TODO: Fix this once we have extra context added to app package install status
  // https://github.com/intercom/intercom/issues/201017
  isOkOrApiUnhealthyState(state) {
    return (
      state.installState === APP_PACKAGE_INSTALL_STATES.ok ||
      Object.values(API_UNHEALTHY_STATE_MESSAGES).includes(state.installStateMessage)
    );
  }

  @action
  handleKeyPress(event) {
    let keyCode = event.which !== undefined ? event.which : event.keyCode;

    // Allows only numbers 0-9
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault();
    }
  }

  @task({ enqueue: true })
  *saveSettings() {
    try {
      if (!this.invalidApiLimitValue) {
        let previous_api_limit = this.apiUsageData.settings.apiLimit;
        this.apiUsageData.settings.apiLimit = this.copiedIntercomApiLimit;
        yield this.apiUsageData.save();
        this.notificationsService.notifyConfirmation('Your settings were updated.');
        this.updateAppPackageInstallState();
        this.intercomEventService.trackAnalyticsEvent({
          action: 'clicked',
          object: 'save',
          place: 'salesforce_settings',
          section: 'api_usage',
          outcome: 'success',
          previous_api_limit,
          updated_api_limit: this.copiedIntercomApiLimit,
          organization_api_limit: this.apiUsageData.organizationApiLimit,
          other_apps_consumption: this.otherAppsConsumption,
        });
      }
    } catch (e) {
      this.notificationsService.notifyError(
        "Something went wrong and we couldn't update your api limit.",
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'save',
        place: 'salesforce_settings',
        section: 'api_usage',
        outcome: 'failure',
      });
    }
  }
}
