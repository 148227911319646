/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { bind } from '@ember/runloop';
import $ from 'jquery';
import Component from '@ember/component';
import ClipboardJS from 'clipboard';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend({
  notificationsService: service(),
  regionService: service(),
  intercomConfirmService: service(),

  classNames: [
    'flex-none',
    'flex',
    'flex-row',
    'meeting-scheduler__settings-integration-container',
  ],

  clipboard: null,
  isEditingUrl: false,
  showErrorOnUrlInput: false,

  adminUrlPlaceholder: computed('admin.first_name', 'admin.last_name', function () {
    let fname = this.get('admin.first_name');
    let lname = this.get('admin.last_name');
    return `e.g. ${fname}.${lname}`.toLowerCase();
  }),

  isUrlInvalid: computed('settings.teammateCalendarUrl', function () {
    let url = this.get('settings.teammateCalendarUrl');
    return !this.isValidPath(url);
  }),

  didInsertElement() {
    this._super(...arguments);
    this.clipboard = new ClipboardJS('.js__public-booking-url-clipboard-content');
    this.clipboard.on('success', bind(this, this._onClipboardCopySuccess));
    this.clipboard.on('error', bind(this, this._onClipboardCopyError));

    $('.meeting-scheduler__settings-public-url', this.element).click(this.selectPublicUrl);
  },

  willDestroyElement() {
    this._super(...arguments);
    this.clipboard.destroy();
  },

  _onClipboardCopySuccess() {
    this.notificationsService.notifyConfirmation('Copied to clipboard');
  },

  _onClipboardCopyError() {
    this.notificationsService.notifyError('Failed to copy link');
  },

  selectPublicUrl(event) {
    event.target.focus();
    event.target.select();
  },

  isValidPath(url) {
    let pathRegex = /^[\w.-\d]{1,30}$/;
    return pathRegex.test(url);
  },

  sectionCanClose: task(function* () {
    if (this.get('settings.hasDirtyAttributes')) {
      let options = {
        title: 'Unsaved Changes',
        body: 'You have unsaved changes. Do you want to discard them?',
        confirmButtonText: 'Discard',
      };
      return yield this.intercomConfirmService.confirm(options);
    }
    return true;
  }),

  sectionDidClose: action(function () {
    let settings = this.settings;
    if (settings.get('hasDirtyAttributes')) {
      settings.rollbackAttributes();
    }
  }),

  actions: {
    beginEditingUrl() {
      this.set('isEditingUrl', true);
    },

    blurOnEnter(e) {
      if (e.key === 'Enter') {
        e.target.blur();
      }
    },

    endEditingUrl() {
      if (!this.get('settings.hasDirtyAttributes')) {
        this.set('isEditingUrl', false);
      } else {
        let newUrl = this.get('settings.teammateCalendarUrl');
        if (this.isValidPath(newUrl)) {
          this.save();
          this.set('showErrorOnUrlInput', false);
          this.set('isEditingUrl', false);
        } else {
          this.set('showErrorOnUrlInput', true);
          this.notificationsService.notifyError('The url you chose is invalid. Please try again.');
        }
      }
    },
  },
});
