/* import __COLOCATED_TEMPLATE__ from './app-settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed, action } from '@ember/object';
import { readOnly, not, filterBy, notEmpty, match, empty } from '@ember/object/computed';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend({
  notificationsService: service(),
  store: service(),
  appService: service(),
  app: readOnly('appService.app'),
  defaultAssigneeName: 'Everyone',
  validDeflectionLink: match('settings.deflectionLink', /^(\/|https?:\/\/.*\.)/),
  invalidDeflectionCta: empty('settings.deflectionCta'),
  deflectionNotEnabled: not('settings.deflectionEnabled'),
  showErrorOnLink: false,

  init() {
    this._super(...arguments);
    this.fetchMessengerSettings.perform();
    this.fetchProfiles.perform();
    this.set('openSectionId', 'introduce');
  },

  fetchMessengerSettings: task(function* () {
    let messengerSettings = yield this.store.findRecord(
      'messenger-settings/all',
      this.get('app.id'),
    );
    this.set('messengerSettings', messengerSettings);
  }).drop(),

  fetchProfiles: task(function* () {
    let profiles = yield this.store.findAll('profile');
    this.set('profiles', profiles);
  }),

  attributesInUse: computed('settings.qualificationAttributes', function () {
    let attributes = this.get('settings.qualificationAttributes');
    let emailAttribute = attributes.findBy('identifier', 'email');
    if (emailAttribute) {
      emailAttribute.set('mandatoryAttribute', true);
    }
    return attributes;
  }),

  qualificationAttributes: computed(function () {
    return this.store.findAll('people/qualification-attribute');
  }),

  introductionPreviewCanvas: computed(
    'settings.title',
    'settings.description',
    'settings.introButtonText',
    function () {
      let content = {
        components: [
          this.createTextComponent(this.get('settings.title'), 'header'),
          this.createTextComponent(this.get('settings.description')),
          this.createButtonComponent(this.get('settings.introButtonText') || 'Request a demo'),
        ],
      };
      return { content };
    },
  ),

  detailsPreviewCanvas: computed(
    'settings.title',
    'attributesInUse.content.@each.identifier',
    function () {
      let content = {
        components: [
          this.createTextComponent(this.get('settings.title'), 'header'),
          this.createTextComponent('Add your details so we can get in touch.', 'muted'),
        ],
      };
      // Add components for every attribute in use
      let availableAttrs = this.qualificationAttributes;
      this.attributesInUse.forEach((attribute, index) => {
        if (attribute.get('identifier')) {
          content.components.push(
            ...this.generateAttributeComponents(attribute, availableAttrs, index),
          );
          content.components.push(this.createSpacingComponent());
        }
      });
      content.components.push(this.createButtonComponent('Cancel', 'link'));

      return { content };
    },
  ),

  confirmPreviewCanvas: computed(
    'settings.title',
    'settings.deflectionText',
    'settings.deflectionEnabled',
    'settings.deflectionCta',
    function () {
      let content = {
        components: [
          this.createTextComponent(this.get('settings.title'), 'header'),
          this.createTextComponent(this.get('settings.deflectionText')),
        ],
      };

      if (this.get('settings.deflectionEnabled')) {
        let deflectionText = this.get('settings.deflectionCta') || '';
        content['components'].push(this.createButtonComponent(deflectionText));
      }
      return { content };
    },
  ),

  generateAttributeComponents(attribute, availableAttrs, index) {
    let identifier = attribute.get('identifier');
    let qualificationAttr = availableAttrs.mapBy('dataAttribute').findBy('identifier', identifier);

    let label = qualificationAttr.get('human_friendly_name');

    let components = [];
    let listItems;

    switch (qualificationAttr.get('inferredType')) {
      case 'boolean':
        components.push(this.createTextComponent(label));
        components.push(this.createBooleanComponent());
        break;
      case 'options':
        // Add a label before the list
        components.push(this.createTextComponent(label));
        listItems = qualificationAttr.get('options').map((item) => {
          return this.createListItem(item.get('id'), item.get('value'));
        });
        components.push(this.createListComponent(listItems));
        break;
      case 'string':
      case 'integer':
        components.push(this.createInputComponent(label));
        break;
    }
    return components;
  },

  createButtonComponent(label = '', style = 'primary') {
    return {
      type: 'button',
      label,
      style,
      action: { type: 'submit' },
    };
  },

  createInputComponent(label) {
    return {
      label,
      type: 'input',
      action: {
        type: 'submit',
      },
    };
  },

  createTextComponent(text, style = 'paragraph') {
    return {
      type: 'text',
      text,
      style,
    };
  },

  createBooleanComponent() {
    return {
      type: 'list',
      items: [
        {
          type: 'item',
          id: 'true',
          title: 'Yes',
        },
        {
          type: 'item',
          id: 'false',
          title: 'No',
        },
      ],
    };
  },

  createListComponent(items) {
    return {
      type: 'list',
      items,
    };
  },

  createListItem(id, title) {
    return {
      type: 'item',
      id,
      title,
    };
  },

  createSpacingComponent() {
    return {
      type: 'spacer',
      size: 'm',
    };
  },

  validateIntroductionSection() {
    if (!this.get('settings.title')) {
      this.notificationsService.notifyError('The title cannot be blank.');
      return false;
    }
    if (!this.get('settings.description')) {
      this.notificationsService.notifyError('The description cannot be blank.');
      return false;
    }
    return true;
  },

  validateConfirmationSection() {
    if (this.get('settings.deflectionEnabled') && this.invalidDeflectionCta) {
      this.notificationsService.notifyError('The button label cannot be blank.');
      return false;
    }
    if (this.get('settings.deflectionEnabled') && !this.validDeflectionLink) {
      this.notificationsService.notifyError('The button link you have entered is not a valid URL.');
      this.set('showErrorOnLink', true);
      return false;
    }
    this.set('showErrorOnLink', false);
    return true;
  },

  validateSettings() {
    return this.validateIntroductionSection() && this.validateConfirmationSection();
  },

  profilesWithCalendarUrls: filterBy('profiles', 'calendar_url'),
  hasProfilesWithCalendarUrls: notEmpty('profilesWithCalendarUrls'),
  openSectionCanClose() {
    if (this.openSectionId === 'introduce') {
      return this.validateIntroductionSection();
    } else if (this.openSectionId === 'confirm') {
      return this.validateConfirmationSection();
    }
    return true;
  },
  setDeflectionLink: action(function (calendarUrl) {
    this.set('settings.deflectionLink', calendarUrl);
  }),
  onOpenSectionChange: action(function (sectionId) {
    if (this.openSectionCanClose()) {
      this.set('openSectionId', sectionId);
    }
  }),
  updateAssigneeId: action(function (assigneeId) {
    this.set('settings.assigneeId', assigneeId);
  }),
  update: action(function () {
    if (this.validateSettings()) {
      this.save();
    }
  }),
});
