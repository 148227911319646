/* import __COLOCATED_TEMPLATE__ from './for-leads.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type TaskBots from 'embercom/models/operator-settings/task-bots';
import { action } from '@ember/object';

interface Args {
  settings: TaskBots;
  updateDelayEnabled: () => void;
  openSectionId: string | null;
  openSectionChange: (sectionId: string) => void;
  upfrontEmailCollection: any;
}
export default class AutomationBasicsForLeads extends Component<Args> {
  @service declare appService: any;

  get app(): any {
    return this.appService.app;
  }

  get hasBotWorkflows(): boolean {
    return this.app?.canUseFeature('bot_workflows');
  }

  @action
  toggleUpfrontEmailCollection() {
    if (this.args.upfrontEmailCollection.frequency === 'never') {
      this.args.upfrontEmailCollection.frequency = 'always';
      return;
    }

    this.args.upfrontEmailCollection.frequency = 'never';
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Automation::Basics::ForLeads': typeof AutomationBasicsForLeads;
  }
}
