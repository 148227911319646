/* import __COLOCATED_TEMPLATE__ from './answer-language-panel.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { LANGUAGES_RELEASED_TO_EVERYBODY } from 'embercom/lib/resolution-bot-languages';

export default class AnswerLanguagePanel extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get supportedLanguages() {
    return LANGUAGES_RELEASED_TO_EVERYBODY.map((languageItem) => {
      return {
        text: this.intl.t(`app.lib.languages.language.${languageItem.value}`),
        value: languageItem.value,
      };
    });
  }
}
