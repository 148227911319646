/* import __COLOCATED_TEMPLATE__ from './celebration.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Checklist from 'embercom/models/checklists/checklist';
import { simpleConfettiShoot } from 'embercom/lib/checklists/confetti';
import confetti, { type CreateTypes as CanvasConfettiObject } from 'canvas-confetti';
import { tracked } from '@glimmer/tracking';
// @ts-ignore
import { globalRef } from 'ember-ref-bucket';

interface Args {
  checklist: Checklist;
}

export default class CompletionScreenCelebrationSettings extends Component<Args> {
  @service declare appService: any;
  @tracked confettiObject: CanvasConfettiObject | null = null;
  @globalRef('completion-screen-confetti-canvas') declare confettiCanvas?: HTMLCanvasElement;

  get checklist() {
    return this.args.checklist;
  }

  @action toggleShowConfetti() {
    this.checklist.showConfetti = !this.checklist.showConfetti;
    if (this.checklist.showConfetti) {
      this.fireConfettiAnimation();
    } else if (this.confettiObject) {
      this.confettiObject.reset();
    }
  }

  // we'll add in more types of confetti animations
  private fireConfettiAnimation() {
    if (!this.confettiCanvas) {
      return;
    }
    this.confettiObject = confetti.create(this.confettiCanvas, {
      resize: true,
    });
    simpleConfettiShoot(this.confettiObject);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Sidebar::CompletionScreenSettings::Celebration': typeof CompletionScreenCelebrationSettings;
    'checklist/sidebar/completion-screen-settings/celebration': typeof CompletionScreenCelebrationSettings;
  }
}
