/* import __COLOCATED_TEMPLATE__ from './additional-contracted-usage-breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { capitalize } from '@ember/string';
import type IntlService from 'embercom/services/intl';

interface Args {
  testIdentifier: string;
  pricingMetric: PricingMetric;
  additionalUsage: number;
  additionalPrice: number;
  baseUsageInfo: any[];
  additionalUsageInfoText: string;
  formattedAdditionalPrice: string;
}

export default class AdditionalContractedUsageBreakdown extends Component<Args> {
  @service declare intl: IntlService;

  get sections() {
    return [
      {
        label: capitalize(this.args.pricingMetric.pluralize(0)!),
        metrics: [
          ...this.args.baseUsageInfo,
          {
            label: this.args.additionalUsageInfoText,
            value: this.args.formattedAdditionalPrice,
          },
          {
            label: this.intl.t('billing.summary.price-usage-breakdown-card.total-price'),
            value: this.formatPriceFromCents(this.args.additionalPrice),
          },
        ],
      },
    ];
  }

  formatPriceFromCents(priceInCents: number) {
    return this.intl.formatNumber(priceInCents / 100, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::Tooltips::AdditionalContractedUsageBreakdown': typeof AdditionalContractedUsageBreakdown;
  }
}
