/* import __COLOCATED_TEMPLATE__ from './delineating-conversations.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { pluralize } from 'ember-inflector';
import Delays from '../../../../../lib/workflows/helpers/delays';
import { inject as service } from '@ember/service';

export default class DelineatingConversations extends Component {
  @service intl;
  @tracked thresholdValue;
  @tracked thresholdUnit;

  constructor() {
    super(...arguments);
    this._setValueAndUnit();
  }

  get delays() {
    return new Delays();
  }

  get displayThreshold() {
    return pluralize(this.thresholdValue, this.thresholdUnit);
  }

  get currentThreshold() {
    return this.args.settings.delineationThresholdInSeconds;
  }

  get unitOptions() {
    return [
      {
        value: 'minute',
        text: this.intl.t('appstore.settings.whatsapp.delineating-conversations.minutes'),
      },
      {
        value: 'hour',
        text: this.intl.t('appstore.settings.whatsapp.delineating-conversations.hours'),
      },
      {
        value: 'day',
        text: this.intl.t('appstore.settings.whatsapp.delineating-conversations.days'),
      },
    ];
  }

  _setValueAndUnit() {
    this.thresholdValue = this.delays.getDelayValue(this.currentThreshold);
    this.thresholdUnit = this.delays.getDelayUnit(this.currentThreshold);
  }

  @task({ restartable: true })
  *saveDelineationSettings() {
    let thresholdInSeconds = this.delays.getDelayInSeconds(this.thresholdValue, this.thresholdUnit);
    this.args.settings.delineationThresholdInSeconds = thresholdInSeconds;
    yield this.args.settings.save();
  }
}
