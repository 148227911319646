/* import __COLOCATED_TEMPLATE__ from './why-included-popover.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Signature {
  Args: {
    dataTest: string;
    title: string;
    content: string;
  };
}

// const WhyIncludedPopoverComponent = templateOnlyComponent<Signature>();
export default class WhyIncludedPopoverComponent extends Component<Signature> {
  @service declare purchaseAnalyticsService: any;

  @action onShow() {
    this.trackEvent('show');
  }

  @action onHide() {
    this.trackEvent('hide');
  }

  trackEvent(action: string) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      context: 'why_included_popover',
      place: 'update_subscription',
      object: this.args.dataTest,
    });
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::UpdateEarlyStageSubscription::WhyIncludedPopover': typeof WhyIncludedPopoverComponent;
    'billing/update-early-stage-subscription/why-included-popover': typeof WhyIncludedPopoverComponent;
  }
}
