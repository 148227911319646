/* import __COLOCATED_TEMPLATE__ from './segment.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import { later } from '@ember/runloop';
import { computed } from '@ember/object';
import { alias, equal, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import AdminObjectVisibility from 'embercom/components/mixins/admin-object-visibility';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action } from '@ember/object';

export default Component.extend(AdminObjectVisibility, {
  init() {
    this._super(...arguments);
    this.reset();
  },
  tagName: '',
  intercomEventService: service(),
  notificationsService: service(),
  intl: service(),
  appService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  store: service(),
  app: alias('appService.app'),
  admin: alias('appService.app.currentAdmin'),

  reset() {
    this.setProperties({
      newSegmentName: null,
      currentSegment: this.model,
      newSegment: 'true',
      isSaving: false,
    });
  },

  newNameAlreadyExists: computed('newSegmentName', function () {
    let userDefinedSegmentNames = this.get('app.segments')
      .filter(function (segment) {
        return !segment.get('isPredefined');
      })
      .map(function (segment) {
        return segment.get('name');
      });
    return userDefinedSegmentNames.includes(this.newSegmentName);
  }),

  isNew: equal('newSegment', 'true'),
  isExisting: not('isNew'),

  isValid: computed('isNew', 'newSegmentName', function () {
    if (this.isNew) {
      let name = this.newSegmentName;
      let nameHasValue = name !== null && name.length;

      return nameHasValue && !this.newNameAlreadyExists;
    } else {
      return true;
    }
  }),

  isInvalid: not('isValid'),

  errorMessage: computed('intl.locale', 'errors', function () {
    let errorMsg = this.errors[0].detail;
    if (
      errorMsg &&
      errorMsg.includes('There are too many filters or nested segments in this segment')
    ) {
      return this.intl.t('apps.app.modals.segment.segment-modal.complexity-error');
    }
    return this.intl.t('apps.app.modals.segment.segment-modal.unexpected-error');
  }),

  notificationMessage() {
    let name = this.isNew ? this.newSegmentName : this.get('currentSegment.name');
    return this.isNew
      ? this.intl.t('apps.app.modals.segment.segment-modal.created-message', { segmentName: name })
      : this.intl.t('apps.app.modals.segment.segment-modal.updated-message', { segmentName: name });
  },

  trackSegmentCreatedEvent(segment) {
    let eventType = this.is_company ? 'company' : 'user';
    this.intercomEventService.trackEvent(`${eventType}-segment-created`);
    this.intercomEventService.trackAnalyticsEvent({
      action: this.isNew ? 'created' : 'edited',
      object: segment,
    });
  },

  closeModal: action(function () {
    this.modalService.closeModal();
  }),

  actions: {
    submit() {
      let isNew = this.isNew;
      let self = this;
      this.set('isSaving', true);
      self.set('hasErrored', false);

      let segment = this.model;
      if (isNew) {
        let { app_id, is_company, predicates } = segment;
        segment = this.store.createRecord('segment', {
          name: this.newSegmentName,
          app_id,
          is_company,
          predicates,
        });
      }

      segment.save().then(
        function () {
          self.trackSegmentCreatedEvent(segment);
          self.notificationsService.notifyConfirmation(self.notificationMessage());
          if (isNew) {
            self.get('app.segments').toArray().pushObject(segment);
            self.get('options').showSegment(segment);
            self.addSegmentIdsToAdminsVisibleSegmentIds([segment.get('id')]);
          }
          self.reset();
          self.closeModal();
        },
        function (response) {
          let errors = response.jqXHR.responseJSON.errors;
          later(function () {
            self.set('errors', errors);
            self.set('hasErrored', true);
            self.set('isSaving', false);
          }, 1000);
        },
      );
    },
  },
});
