/* import __COLOCATED_TEMPLATE__ from './collections-editor-and-preview.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { PreviewType } from './settings/react-preview';
import { type Tree, type TreeItem } from 'embercom/objects/tree-list';
import {
  type ArticleSummary,
  type Collection,
  type DataObject,
  DataTypes,
} from './collections/tree/types';
import {
  postMessageToHCIframe,
  PreviewMessageType,
} from './settings/helpers/help-center-preview-helper';
import { type UploadHelper } from 'embercom/objects/media/media-helper';
import { tracked } from '@glimmer/tracking';

interface Signature {
  Args: {
    allCollections: Collection[];
    allArticlesSummaries: ArticleSummary[];
    selectedCollection: Collection;
    getContainerRef: () => any;
    uploadHelper: UploadHelper;
    registerTreeAction: $TSFixMe; // Currently a route-action which is why it's typed as any
    refreshData: () => void;
  };
}

type PublishedArticleCountAndAuthors = {
  articleCount: number;
  authors: AuthorSummary[];
};

export type AuthorSummary = {
  id: string;
  name: string;
  avatar_url: string;
  avatar_shape: string;
  first_name: string;
};

export default class CollectionsEditorAndPreview extends Component<Signature> {
  @service appService: any;
  @service helpCenterService: any;

  @tracked previewType: PreviewType = PreviewType.Homepage;

  get app() {
    return this.appService.app;
  }

  get helpCenterSite() {
    return this.helpCenterService.site;
  }

  get selectedLocaleId() {
    return this.helpCenterService.currentlySelectedLocaleId;
  }

  get defaultLocaleId() {
    return this.helpCenterSite.locale;
  }

  @action setPreviewType(type: PreviewType) {
    this.previewType = type;
  }

  @action
  sendCollectionsToPreviewIframe(tree: Tree) {
    if (!this.app.sideBySideCollectionsPreview) {
      return;
    }

    let collectionIdsWithPublishedArticles: Record<string, PublishedArticleCountAndAuthors> = {};
    tree.children.forEach((child: TreeItem) => {
      let result = this.contentHasPublishedChild(child);
      if (result.articleCount > 0) {
        collectionIdsWithPublishedArticles[child.dataObject.content.id] = result;
      }
    });

    // Filter out any tree children that are not a collection or are not published
    let publishedCollections = tree.children.filter((child: TreeItem) => {
      let dataObject = child.dataObject as DataObject;
      return (
        dataObject.type === DataTypes.collection &&
        collectionIdsWithPublishedArticles[dataObject.content.id]
      );
    });

    // Format the collections to match the help center format
    let formattedCollections = publishedCollections.map((child: TreeItem) => {
      let dataObject = child.dataObject as DataObject;
      let collection = dataObject.content as any; // Some weirdness going on with the Collection type vs ArticleGroup type
      return {
        id: collection.id,
        name: collection.defaultLocalizedContent.name,
        iconUrl: collection.iconUrl,
        iconContentType: this.iconContentType(collection),
        articleCount: collectionIdsWithPublishedArticles[collection.id].articleCount,
        authors: collectionIdsWithPublishedArticles[collection.id].authors,
        authorCount: collectionIdsWithPublishedArticles[collection.id].authors.length,
        description: collection.defaultLocalizedContent.description,
        url: '', // TODO: Need to check how to get the url / if we need to get the url
      };
    });

    postMessageToHCIframe({
      type: PreviewMessageType.OverwriteCollections,
      payload: formattedCollections,
    });
  }

  contentHasPublishedChild(item: TreeItem): PublishedArticleCountAndAuthors {
    if (!item.children?.length) {
      return { articleCount: 0, authors: [] };
    }

    let result = item.children.reduce(
      (acc, child) => {
        let cData = child.dataObject;
        let selectedLocalizedArticle =
          cData.content?.locales && cData.content?.locales[this.defaultLocaleId];

        if (selectedLocalizedArticle?.state === 'published') {
          acc.articleCount += 1;
          if (selectedLocalizedArticle.author) {
            acc.authors.push(selectedLocalizedArticle.author);
          }
        }

        let childResult = this.contentHasPublishedChild(child);
        acc.articleCount += childResult.articleCount;
        acc.authors.push(...childResult.authors);

        return acc;
      },
      { articleCount: 0, authors: [] as AuthorSummary[] },
    );
    result.authors = this.deduplicateAuthors(result.authors);

    return result;
  }

  private deduplicateAuthors(authors: any[]): any[] {
    let uniqueAuthors = new Map<string, any>();

    for (let author of authors) {
      uniqueAuthors.set(author.id, author);
    }

    return Array.from(uniqueAuthors.values());
  }

  private iconContentType(collection: $TSFixMe) {
    if (!collection.externalIconUrl) {
      return 'image/svg+xml';
    }

    return collection.externalIconType;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::CollectionsEditorAndPreview': typeof CollectionsEditorAndPreview;
    'articles/site/collections-editor-and-preview': typeof CollectionsEditorAndPreview;
  }
}
