/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class Main extends Component {
  @service store;
  @service appService;

  @tracked openSectionId = 'add-whatsapp';
  @tracked integrations = [];
  @tracked selectedIntegration = {};
  @tracked whatsappSettings = {};

  constructor() {
    super(...arguments);
    this.loadIntegrations.perform();
    this.loadWhatsappSettings.perform();
  }

  get whatsappIntegration() {
    let integrations = this.integrations || [];
    return (
      integrations.find((int) => int.id === this.selectedIntegration?.id) ||
      integrations.firstObject
    );
  }

  get integrationsOrSettingsIsLoading() {
    return this.loadIntegrations.isRunning || this.loadWhatsappSettings.isRunning;
  }

  @task({ drop: true })
  *loadIntegrations() {
    this.integrations = yield this.store.findAll('whatsapp/integration');
    this.selectedIntegration = this.whatsappIntegration;
  }

  @task({ drop: true })
  *loadWhatsappSettings() {
    this.whatsappSettings = yield this.store.findRecord(
      'whatsapp/settings',
      this.appService.app.id,
    );
  }

  get integrationsDropdown() {
    let dropdownItems = this.integrations.map((integration) => {
      return {
        text: integration.displayString,
        value: integration,
        isSelected: this.selectedIntegration?.id === integration.id,
      };
    });

    return [
      {
        hasItemsMarkableAsSelected: true,
        items: dropdownItems,
      },
    ];
  }

  @action
  selectIntegration(integration) {
    this.selectedIntegration = integration;
  }
}
