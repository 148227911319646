/* import __COLOCATED_TEMPLATE__ from './seat-assignment-modal-body.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
// @ts-nocheck

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';
import { compact } from 'underscore';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';

interface Args {
  migrationSeatConfiguration: MigrationSeatConfiguration;
  selectedPlanId: string;
}

interface Signature {
  Args: Args;
}

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

type TableSortState = {
  valuePath: string;
  direction: SortDirection;
};

export default class SeatAssignmentModalBody extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @tracked sortBy: TableSortState = {
    valuePath: '',
    direction: SortDirection.ASC,
    shouldSort: true,
  };
  @tracked previousSortedData: $TSFixMe = undefined;

  get columns() {
    return compact([
      {
        label: this.intl.t('billing.migrations.edit_plan.admin_seat_mapping.body.headers.name'),
        valuePath: 'name',
        width: this.shouldShowCurrentSeatType ? '60%' : '80%',
        isSortable: true,
      },
      {
        valuePath: 'isActive',
        isSortable: false,
        minWidth: '200px',
      },
      this.shouldShowCurrentSeatType && {
        label: this.intl.t(
          'billing.migrations.edit_plan.admin_seat_mapping.body.headers.current_seat_type',
        ),
        valuePath: 'currentSeatType',
        isSortable: false,
        tooltip: this.intl.t(
          'billing.migrations.edit_plan.admin_seat_mapping.body.header_tooltips.current_seat_type',
        ),
      },
      {
        label: this.intl.t(
          'billing.migrations.edit_plan.admin_seat_mapping.body.headers.new_seat_type',
        ),
        valuePath: 'newSeatType',
        isSortable: true,
        tooltip: this.intl.t(
          'billing.migrations.edit_plan.admin_seat_mapping.body.header_tooltips.new_seat_type',
        ),
      },
    ]);
  }

  get shouldShowCurrentSeatType() {
    return (
      this.appService.app.hasValueBasedPricing2 || this.appService.app.canUsePerProductPricingFlow
    );
  }

  get admins() {
    return this.appService.app.humanAdmins;
  }

  @action
  onSortUpdate(sortBy: string) {
    // This action happens when the user explicitly clicks on sorting
    let sort: TableSortState = {
      valuePath: sortBy,
      direction:
        this.sortBy.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
      shouldSort: true,
    };
    this.sortBy = sort;
  }

  get sortedAdminData() {
    let rawData = this.args.migrationSeatConfiguration.adminSeatMappings;
    if (this.sortBy.shouldSort) {
      let key = this.sortBy.valuePath || 'newSeatType';
      let attribute = key === 'newSeatType' ? 'seatTypes' : 'admin.name';
      let sortedData = [];

      sortedData =
        this.sortBy.direction === SortDirection.ASC
          ? rawData.sortBy(attribute, 'admin.name')
          : rawData.sortBy(attribute).reverseObjects();

      // We have to set up the correct states where we determine whether we sort or not, and if not, what data to give back.

      // eslint-disable-next-line ember/no-side-effects
      this.sortBy.shouldSort = false;

      // eslint-disable-next-line ember/no-side-effects
      this.previousSortedData = sortedData;

      return sortedData;
    }
    return this.previousSortedData || rawData;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::SeatAssignmentModalBody': typeof SeatAssignmentModalBody;
  }
}
