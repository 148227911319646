/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import UserAgentDetector from '@intercom/pulse/lib/user-agent-detector';
import { computed } from '@ember/object';
import { and } from '@ember/object/computed';

export default Component.extend({
  tagName: '',

  shouldUseExtraShortDescription: and('hasFixedHeight', 'appPackage.isMessengerApp'),
  shouldUseFade: computed('hasFixedHeight', function () {
    let lineClampIsNotSupported = UserAgentDetector.isIE() || UserAgentDetector.isFirefox();
    return this.hasFixedHeight && lineClampIsNotSupported;
  }),
});
