/* import __COLOCATED_TEMPLATE__ from './vat-details-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class VatDetailsComponent extends Component {
  @service customerService;
  @tracked companyAddressIsOpen = false;
  @tracked shouldFocusOnVatField = false;

  get vatNumber() {
    return this.customerService.customer.vatNumber;
  }
}
