/* import __COLOCATED_TEMPLATE__ from './navigation-stack-sample-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';

import { inject as service } from '@ember/service';
import type HelpCenterNavigationService from 'embercom/services/help-center-navigation-service';

export default class NavigationStackSampleItem extends Component {
  @service declare helpCenterNavigationService: HelpCenterNavigationService;
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::NavigationStackSampleItem': typeof NavigationStackSampleItem;
  }
}
