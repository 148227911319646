/* import __COLOCATED_TEMPLATE__ from './part.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';
import type Part from 'embercom/models/operator/bot-only-message/part';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    part: Part;
    showOperatorBadge: boolean;
    hidePlaceholder: boolean;
    onPartChange: () => void;
  };
}

const BotOnlyMessagePart = templateOnlyComponent<Signature>();
export default BotOnlyMessagePart;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Profiles::BotOnlyMessage::Part': typeof BotOnlyMessagePart;
  }
}
