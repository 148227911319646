/* import __COLOCATED_TEMPLATE__ from './selector.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

const UNASSIGNED_ID = '0';

export default class Selector extends Component {
  @service appService;

  /**
   * Arguments:
   *
   * assignees - list of admins & teams who can be assigned to
   * currentAdmin - the current logged in admin
   * selectedAdminId - the admin ID currently selected
   * selectedTeamId - the team ID currently selected
   *
   * onSelectItem - function called when selection changes, called with {teamId, adminId}
   *
   * readOnly (false) - disables the dropdown
   * clearAdminWhenTeamChanges (false) - whether to unassign admin when setting team
   */

  get admins() {
    return this.args.assignees.filter(
      (assignee) => !assignee.isTeam && !assignee.isUnassignedAdmin,
    );
  }

  get teams() {
    return this.args.assignees.filter((assignee) => assignee.isTeam && !assignee.isUnassignedAdmin);
  }

  get unassignedAdmin() {
    return this.args.assignees.find((assignee) => assignee.isUnassignedAdmin);
  }

  get groupList() {
    let groupList = [
      {
        headingComponent: 'assignee/selector-heading',
        headingComponentAttrs: {
          text: 'Assign to teammate',
        },
        items: this.adminItems,
        alwaysShowHeading: true,
        isFilterable: true,
        isMain: true,
        hasItemsMarkableAsSelected: true,
      },
    ];
    if (this.teams.length > 0) {
      groupList = [
        ...groupList,
        {
          headingComponent: 'assignee/selector-heading',
          headingComponentAttrs: {
            text: 'Assign to team',
            subText: this.teamHeadingSubText,
          },
          items: this.teamItems,
          alwaysShowHeading: true,
          isFilterable: true,
          isMain: true,
          hasItemsMarkableAsSelected: true,
        },
      ];
    }
    return groupList;
  }

  get teamHeadingSubText() {
    return (
      this.args.clearAdminWhenTeamChanges &&
      !!this.selectedAdmin &&
      'This will unassign the teammate'
    );
  }

  get selectedTeam() {
    if (this.args.assigneeOverwrite && this.args.assigneeOverwrite.isTeam) {
      return this.args.assigneeOverwrite;
    } else if (this.args.assigneeOverwrite) {
      // don't set team if macro is overwriting teammate assignee
      return null;
    } else {
      return this.teams.find((team) => team.id === this.args.selectedTeamId);
    }
  }

  get selectedAdmin() {
    if (this.args.assigneeOverwrite && !this.args.assigneeOverwrite.isTeam) {
      return this.args.assigneeOverwrite;
    } else if (this.args.assigneeOverwrite) {
      // don't set teammate if macro is overwriting team assignee
      return null;
    } else {
      return this.admins.find((admin) => admin.id === this.args.selectedAdminId);
    }
  }

  get teamItems() {
    let type = 'team';
    let unassigned = this._createGroupListItem(this.unassignedAdmin, type, 'None');
    let teams = this.teams
      .without(this.selectedTeam)
      .map((team) => this._createGroupListItem(team, type));

    let items = [unassigned];
    if (this.selectedTeam) {
      let selectedTeam = this._createGroupListItem(this.selectedTeam, type);
      items = [...items, selectedTeam];
    }
    return [...items, ...teams];
  }

  get adminItems() {
    let type = 'admin';
    let unassigned = this._createGroupListItem(this.unassignedAdmin, type, 'None');
    let admins = this.admins
      .without(this.selectedAdmin)
      .map((admin) => this._createGroupListItem(admin, type));

    let items = [unassigned];
    if (this.selectedAdmin) {
      let selectedAdmin = this._createGroupListItem(this.selectedAdmin, type);
      items = [...items, selectedAdmin];
    }
    return [...items, ...admins];
  }

  _createGroupListItem(item, type, textOverride) {
    let selectedItemId;
    if (type === 'team') {
      selectedItemId = this.args.selectedTeamId;
    } else if (type === 'admin') {
      selectedItemId = this.args.selectedAdminId;
    }

    let isListItemSelected = item.id === selectedItemId;

    let listItem = {
      text: textOverride || item.display_as_assignee,
      textOverride,
      value: item.id,
      component: 'assignee/selector-item',
      isFilterable: true,
      assignee: item,
      showAwayAndReassigningStatus: true,
      isSelected: isListItemSelected,
    };

    if (!isListItemSelected) {
      listItem.onSelectItem = () => {
        if (type === 'team') {
          this.args.onSelectItem({
            teamId: item.id || UNASSIGNED_ID,
            adminId: this.args.clearAdminWhenTeamChanges
              ? UNASSIGNED_ID
              : this.args.selectedAdminId || UNASSIGNED_ID,
          });
        } else if (type === 'admin') {
          this.args.onSelectItem({
            teamId: this.args.selectedTeamId || UNASSIGNED_ID,
            adminId: item.id || UNASSIGNED_ID,
          });
        }
      };
    }
    return listItem;
  }
}
