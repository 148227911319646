/* import __COLOCATED_TEMPLATE__ from './phone-number-table.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type PhoneNumber from 'embercom/models/calling-phone-number';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type Task } from 'ember-concurrency';
import { PhoneNumberStatus } from 'embercom/models/calling-phone-number';

interface Args {
  phoneNumbers: Array<PhoneNumber>;
  fetchPhoneNumbersTask: Task<Array<PhoneNumber>, any>;
}

interface Signature {
  Args: Args;
}

export default class PhoneNumberTable extends Component<Signature> {
  readonly activeStatus = PhoneNumberStatus.Active;
  readonly portingStatus = PhoneNumberStatus.Porting;
  readonly missingStatus = PhoneNumberStatus.MissingBundle;

  @service declare appService: any;
  @service declare intl: IntlService;

  @tracked showPhoneNumberDetails = false;
  @tracked selectedPhoneNumber: PhoneNumber | null = null;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.args.fetchPhoneNumbersTask.perform();
  }

  @action
  togglePhoneNumberDetails(phoneNumber: PhoneNumber) {
    this.selectedPhoneNumber = phoneNumber;
  }

  @action
  openTicket(phoneNumber: PhoneNumber) {
    window.Intercom('showTicket', phoneNumber.portingTicketId);
  }

  @action
  closeModal() {
    if (this.selectedPhoneNumber) {
      this.selectedPhoneNumber.rollbackAttributes();
      this.selectedPhoneNumber = null;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhoneNumberTable': typeof PhoneNumberTable;
    'calling/settings/phone-number-table': typeof PhoneNumberTable;
  }
}
