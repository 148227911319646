/* import __COLOCATED_TEMPLATE__ from './compact-locale-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface locale {
  localeId: string;
  name: string;
}

interface Args {
  locale: locale;
  defaultLocale: locale;
  deleteLanguage: (locale: any) => void;
}

interface Signature {
  Args: Args;
}

export default class CompactLocaleComponent extends Component<Signature> {
  @service appService: any;

  get app() {
    return this.appService.app;
  }

  get isDefaultLocale() {
    return this.args.locale.localeId === this.args.defaultLocale.localeId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::Language::CompactLocaleComponent': typeof CompactLocaleComponent;
  }
}
