/* import __COLOCATED_TEMPLATE__ from './notification-rule-editor.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { A } from '@ember/array';
import { computed, action } from '@ember/object';
import { readOnly, filterBy, notEmpty, and, map } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  allChannels: readOnly('slackIntegration.channels'),
  allNotificationTypes: readOnly('slackIntegration.notificationTypes'),

  allNonConversationNotificationTypes: filterBy(
    'allNotificationTypes',
    'isNonConversationType',
    true,
  ),

  selectedNotificationTypes: computed(
    'selectedNonConversationNotificationTypes.[]',
    'selectedConversationNotificationTypeId',
    'isConversationNotificationTypeSelected',
    function () {
      let selectedNotificationTypes = A();
      selectedNotificationTypes.pushObjects(this.selectedNonConversationNotificationTypes);
      if (this.isConversationNotificationTypeSelected) {
        selectedNotificationTypes.pushObject(
          this.allNotificationTypes.findBy('id', this.selectedConversationNotificationTypeId),
        );
      }
      return selectedNotificationTypes;
    },
  ),

  assigneeId: computed('assignee', 'isConversationNotificationTypeSelected', function () {
    if (this.isConversationNotificationTypeSelected) {
      return this.get('assignee.id');
    }
    return null;
  }),

  isChannelSelected: notEmpty('channel'),
  hasNotificationTypesSelected: notEmpty('selectedNotificationTypes'),
  canSave: and('isChannelSelected', 'hasNotificationTypesSelected'),
  editorClass: 'modal__body',

  allChannelSelectList: map('allChannels', function (channel) {
    return {
      value: channel.get('id'),
      text: `#${channel.get('name')}`,
      isSelected: channel.get('id') === this.get('channel.id'),
    };
  }),

  init() {
    this._super(...arguments);
    this.setProperties({
      channel: null,
      assignee: null,
      selectedNonConversationNotificationTypes: [],
      isConversationNotificationTypeSelected: false,
      selectedConversationNotificationTypeId: 'conversation.new',
    });
  },

  didReceiveAttrs() {
    this._super(...arguments);
    let notificationRule = this.notificationRule;

    if (notificationRule) {
      let isConversationNotificationTypeSelected = notificationRule.get(
        'hasConversationNotificationType',
      );
      let selectedConversationNotificationTypeId = 'conversation.new';
      if (isConversationNotificationTypeSelected) {
        selectedConversationNotificationTypeId = notificationRule.get(
          'conversationNotificationType.id',
        );
      }

      this.setProperties({
        channel: notificationRule.get('channel'),
        assignee: notificationRule.get('assignee'),
        selectedNonConversationNotificationTypes: notificationRule
          .get('notificationTypes')
          .filterBy('isNonConversationType', true),
        isConversationNotificationTypeSelected,
        selectedConversationNotificationTypeId,
      });
    }
  },

  saveTask: task(function* () {
    yield this.saveRuleTask.perform(
      {
        channel: this.channel,
        assigneeId: this.assigneeId,
        notificationTypes: this.selectedNotificationTypes,
      },
      this.legacySubscription,
    );
  }).drop(),

  selectChannel: action(function (channelId) {
    let channel = this.allChannels.findBy('id', channelId);
    this.set('channel', channel);
  }),
});
