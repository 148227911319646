/* import __COLOCATED_TEMPLATE__ from './total-price-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed, action } from '@ember/object';
import { alias, readOnly, sum, gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import numericFormatter from 'embercom/lib/numeric-formatter';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import Component from '@ember/component';

export default Component.extend({
  tagName: 'tfoot',
  customerService: service(),
  store: service(),

  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  customer: alias('customerService.customer'),
  appService: service(),
  migrationPrices: null,
  app: alias('appService.app'),
  currentPrice: readOnly('customerService.currentPrice'),

  migrationPrice: computed('customerService.activePlans.[]', 'migrationPrices.[]', function () {
    if (!this.migrationPrices) {
      return null;
    }

    let activePlanIds = this.get('customerService.activePlanIds');
    return this.migrationPrices.find((price) => price.hasSamePlans(activePlanIds));
  }),

  preDiscountTotalAmount: ternaryToProperty(
    'shouldShowLegacyDiscount',
    'formattedSharedBaseTotal',
    'currentPrice.preDiscountAmountToTwoDecimals',
  ),

  cardError: null,
  totalAmount: alias('customerService.totalAmount'),

  //start of hacks for shared base display
  displayProductTotals: computed('customerService.products.@each.active', function () {
    if (!this.customerService.prices) {
      return '';
    }
    return this.get('customerService.products')
      .filterBy('active')
      .map((product) => {
        let planId = product.get('activePlan.idAsNumber');
        if (!planId) {
          planId = this.get('product.plans.firstObject.idAsNumber');
        }
        return this.get('customerService.prices')
          .find((price) => price.hasSamePlans([planId]))
          .get('preDiscountAmount');
      });
  }),

  displayProductTotal: sum('displayProductTotals'),

  formattedSharedBaseTotal: computed('displayProductTotal', function () {
    return `$${numericFormatter(this.displayProductTotal / 100, 2, true)}`;
  }),

  legacyDiscountTotal: computed(
    'displayProductTotal',
    'currentPrice.preDiscountAmount',
    function () {
      return (this.displayProductTotal - this.get('currentPrice.preDiscountAmount')) / 100;
    },
  ),

  legacyDiscountTotalFormatted: computed('legacyDiscountTotal', function () {
    return `$${numericFormatter(this.legacyDiscountTotal, 2, true)}`;
  }),

  shouldShowLegacyDiscount: computed('customerService.currentPrice', function () {
    if (
      this.get('customerService.currentPrice.breakdown') &&
      !this.get('customer.currentlyOnEarlyStage')
    ) {
      return (
        this.get('customerService.currentPrice.breakdown').any((breakdown) => {
          return this.isABaseCharge(breakdown.name);
        }) && this.legacyDiscountTotal > 0
      );
    }
    return false;
  }),

  isABaseCharge(name) {
    return name?.includes('base') || name?.includes('Base');
  },

  //end of hacks for shared base display

  couponPercentOffTextFormatted: computed('currentPrice.couponPercentOff', function () {
    return `${this.get('currentPrice.couponPercentOff')}% off`;
  }),

  couponFixedAmountOffTextFormated: computed('currentPrice.couponDiscountAmount', function () {
    return `$${numericFormatter(this.get('currentPrice.couponFixedAmountOff') / 100, 2, true)} off`;
  }),

  totalAmountAfterMigration: alias('migrationPrice.amountToTwoDecimals'),

  couponDiscountAmountAfterMigrationFormatted: alias(
    'migrationPrice.couponDiscountAmountFormatted',
  ),

  hasCoupons: gt('coupons.length', 0),

  coupons: computed('customer.couponCode', function () {
    let coupons = [];
    if (!this.currentPrice) {
      return coupons;
    }

    if (this.shouldShowLegacyDiscount) {
      coupons.addObject(this.buildSharedBaseLegacyCoupon());
    }

    if (this.customer.couponCode) {
      coupons.addObject(this.buildNormalCoupon());
    }

    return coupons;
  }),

  makeFirstLetterLowerCase: (sentence) => sentence[0].toLowerCase() + sentence.slice(1),

  buildNormalCoupon() {
    let coupon = {
      discountNameFormatted: this.currentPrice.hasPercentOffCoupon
        ? this.couponPercentOffTextFormatted
        : this.couponFixedAmountOffTextFormated,
      discountAmountFormatted: this.get('customerService.couponDiscountAmountFormatted'),
      expiresAtFormatted: this.makeFirstLetterLowerCase(this.customer.formattedCouponExpiresAt),
      canUpdate: true,
    };
    return coupon;
  },

  buildSharedBaseLegacyCoupon() {
    let coupon = {
      discountNameFormatted: 'Bundle',
      discountAmountFormatted: this.legacyDiscountTotalFormatted,
    };

    return coupon;
  },

  updateCoupon: action(function () {
    this.modalService.openModal('billing/coupon-modal', this.customer, { isChange: true });
  }),
});
