/* import __COLOCATED_TEMPLATE__ from './prosemirror-composer.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { type Paragraph } from '@intercom/interblocks.ts';

interface Args {
  link: {
    url: string;
    label: string;
    validations: {
      isValid: boolean;
      message?: string;
    };
    set(key: string, value: any): void;
  };
  attributes: any[];
  screen: any;
  placeholder?: string;
  isValid?: boolean;
  onChange(): void;
}

class ComposerConfig extends BaseConfig {
  placeholder = '';
  autoFocus = false;
  singleBlockMode = true;
  textDirection = 'ltr' as const;
  isPlaintext = true;
  allowedBlocks = ['paragraph'];
  allowedInline = [];
  allowTextAlignment = false;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  attributes: any;
  templating: { picker: string; resolver: AttributeInfoResolver };

  constructor(resolver: any, placeholder: string) {
    super();

    this.placeholder = placeholder;

    this.templating = {
      picker: 'common/attribute-picker',
      resolver,
    };
  }
}

export default class ProsemirrorComposer extends Component<Args> {
  @service declare appService: any;
  @service declare store: any;
  @service declare intl: IntlService;
  blocksDoc: BlocksDocument;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.blocksDoc = new BlocksDocument([{ type: 'paragraph', text: args.link.url }]);
  }

  get app() {
    return this.appService.app;
  }

  get attributeResolver() {
    return new AttributeInfoResolver({
      attributes: this.args.attributes,
      includeAppAttributes: false,
    });
  }

  get composerConfig() {
    return new ComposerConfig(this.attributeResolver, this.args.placeholder || '');
  }

  @action updateBlocks(blocksDoc: BlocksDocument) {
    let { text } = blocksDoc.blocks.at(0) as Paragraph;
    this.args.link.set('url', text);
    this.args.onChange?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Appstore::AppPackage::Settings::QuickLinks::ProsemirrorComposer': typeof ProsemirrorComposer;
    'appstore/app-package/settings/quick-links/prosemirror-composer': typeof ProsemirrorComposer;
  }
}
