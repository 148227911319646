/* import __COLOCATED_TEMPLATE__ from './article-bulk-actions.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ajax from 'embercom/lib/ajax';
import { groupBy } from 'underscore';

export default class ArticleBulkActions extends Component {
  @service intl;
  @service permissionsService;
  @service notificationsService;
  @service appService;
  @service store;
  @service intercomEventService;

  @tracked currentBulkAction = '';
  @tracked showGenericModal = false;
  @tracked showMoveCollectionModal = false;
  @tracked showUpdateAuthorModal = false;

  @tracked selectedCollection = '';
  @tracked isLoading = false;
  @tracked selectedAuthorForBulkUpdate = this.app.currentAdmin;

  ACTIONS = {
    UPDATE_AUTHOR: 'update_author',
  };

  app_id = this.appService.app.id;

  get app() {
    return this.appService.app;
  }

  get actionIsMoveCollection() {
    return this.currentBulkAction === 'move_collection';
  }

  get actionIsUpdateAuthor() {
    return this.currentBulkAction === this.ACTIONS.UPDATE_AUTHOR;
  }

  get targetCollection() {
    let collection = this.args.collectionsList.find(
      (collection) => collection.value === this.selectedCollection,
    );
    return collection ? collection.text : '';
  }

  get collectionsByHelpCenter() {
    return groupBy(this.args.collectionsList, 'helpCenterName');
  }

  get actionAppliesForAllLocales() {
    let selectedLocaleCount = this.selectedLanguageCount;
    let availableLocaleCount = this.args.availableLanguages.length;
    return selectedLocaleCount === 0 || selectedLocaleCount === availableLocaleCount;
  }

  get localesToEditString() {
    return this.args.availableLanguages
      .filter((language) => this.args.selectedLanguages.includes(language.value))
      .map((language) => language.text)
      .join(', ');
  }

  get articleTitleMapping() {
    let mapping = {};
    this.args.articles.forEach((article) => {
      let titleToDisplay = article.titleForDisplay;
      mapping[`${article.id}`] =
        titleToDisplay !== '' ? titleToDisplay : this.intl.t('articles.list.untitled_article');
    });
    return mapping;
  }

  get completedAction() {
    let actionMapping = {
      publish: 'published_article',
      unpublish: 'unpublished_article',
      delete: 'deleted_article',
      move_collection: 'moved_collection_article',
      update_author: 'updated_author_article',
    };
    return actionMapping[this.currentBulkAction];
  }

  get bulkActionGroupList() {
    return [
      {
        items: [
          {
            text: this.intl.t('articles.list.move_article_collection'),
            icon: 'collection',
            onSelectItem: () => this.toggleModal('move_collection'),
            isDisabled: this.args.collectionsList.length === 0,
          },
          {
            text: this.intl.t('articles.list.update_author'),
            icon: 'person-square-fill',
            onSelectItem: () => this.toggleModal(this.ACTIONS.UPDATE_AUTHOR),
          },
          {
            text: this.intl.t('articles.list.delete_article'),
            icon: 'trash',
            isDestructive: true,
            onSelectItem: () => this.toggleModal('delete'),
          },
        ],
      },
    ];
  }

  get publishDisabled() {
    return this.args.articles
      .filter((content) => this.args.selectedContent.includes(content.id))
      .every((content) => content.canBePublished === false);
  }
  get unpublishDisabled() {
    return this.args.articles
      .filter((content) => this.args.selectedContent.includes(content.id))
      .every((content) => content.canBeUnpublished === false);
  }

  get genericModalHeader() {
    let key = '';
    switch (this.currentBulkAction) {
      case 'publish':
        key = this.isSelectingMultipleLanguage
          ? 'articles.list.bulk_actions.publish.multi_language_title'
          : 'articles.list.bulk_actions.publish.single_language_title';
        break;
      case 'unpublish':
        key = this.isSelectingMultipleLanguage
          ? 'articles.list.bulk_actions.unpublish.multi_language_title'
          : 'articles.list.bulk_actions.unpublish.single_language_title';
        break;
      case 'delete':
        key = this.isSelectingMultipleLanguage
          ? 'articles.list.bulk_actions.delete.multi_language_title'
          : 'articles.list.bulk_actions.delete.single_language_title';
        break;
      case this.ACTIONS.UPDATE_AUTHOR:
        key = this.isSelectingMultipleLanguage
          ? 'articles.list.bulk_actions.update_author.multi_language_title'
          : 'articles.list.bulk_actions.update_author.single_language_title';
        break;
      case 'move_collection':
        key = 'articles.list.bulk_actions.move_collection.title';
        break;
    }

    return this.intl.t(key, {
      articleCount: this.selectedContentCount,
    });
  }

  get genericModalPrompt() {
    let key = '';
    switch (this.currentBulkAction) {
      case 'publish':
        key = this.isSelectingMultipleLanguage
          ? 'articles.list.bulk_actions.publish.multi_language_prompt'
          : 'articles.list.bulk_actions.publish.single_language_prompt';
        break;
      case 'unpublish':
        key = this.isSelectingMultipleLanguage
          ? 'articles.list.bulk_actions.unpublish.multi_language_prompt'
          : 'articles.list.bulk_actions.unpublish.single_language_prompt';
        break;
      case 'delete':
        key = this.isSelectingMultipleLanguage
          ? 'articles.list.bulk_actions.delete.multi_language_prompt'
          : 'articles.list.bulk_actions.delete.single_language_prompt';
        break;
      case this.ACTIONS.UPDATE_AUTHOR:
        key = this.isSelectingMultipleLanguage
          ? 'articles.list.bulk_actions.update_author.multi_language_prompt'
          : 'articles.list.bulk_actions.update_author.single_language_prompt';
        break;
      case 'move_collection':
        key = 'articles.list.bulk_actions.move_collection.prompt';
        break;
    }

    return this.intl.t(key, {
      articleCount: this.selectedContentCount,
      ...(this.selectedLanguageCount > 1 && { languages: this.localesToEditString }),
      ...(this.currentBulkAction === 'move_collection' && {
        collectionName: this.targetCollection,
      }),
    });
  }

  get selectedLanguageCount() {
    return this.args.selectedLanguages?.length || 0;
  }

  get isSelectingMultipleLanguage() {
    return this.selectedLanguageCount > 1;
  }

  get selectedContentCount() {
    return this.args.selectedContent?.length || 0;
  }

  get currentBulkActionConfirmation() {
    switch (this.currentBulkAction) {
      case 'publish':
        return this.intl.t('articles.list.bulk_actions.publish.confirmation');
      case 'unpublish':
        return this.intl.t('articles.list.bulk_actions.unpublish.confirmation');
      case 'delete':
        return this.intl.t('articles.list.bulk_actions.delete.confirmation');
      case this.ACTIONS.UPDATE_AUTHOR:
        return this.intl.t('articles.list.bulk_actions.update_author.confirmation');
      case 'move_collection':
        return this.intl.t('articles.list.bulk_actions.move_collection.confirmation');
    }
  }

  @action
  onSelectAuthor(selectedAuthorForBulkUpdate) {
    this.selectedAuthorForBulkUpdate = selectedAuthorForBulkUpdate;
  }

  @action
  async toggleModal(actionType) {
    await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
    this.currentBulkAction = actionType;
    if (this.actionIsMoveCollection && !this.showGenericModal) {
      this.showMoveCollectionModal = true;
      return;
    } else if (this.actionIsUpdateAuthor && !this.showGenericModal) {
      this.showUpdateAuthorModal = true;
      return;
    }
    this.showGenericModal = !this.showGenericModal;
  }

  @action
  transitionToGenericModal() {
    this.showMoveCollectionModal = false;
    this.showUpdateAuthorModal = false;
    this.showGenericModal = true;
  }

  @action
  async bulkAction() {
    this.isLoading = true;
    let selectedLocales =
      this.args.selectedLanguages === undefined || this.args.selectedLanguages.length === 0
        ? this.args.availableLanguages.map((locales) => locales.value)
        : this.args.selectedLanguages;
    let articleTitleMapping = this.articleTitleMapping;
    this.intercomEventService.trackAnalyticsEvent({
      action: `${this.currentBulkAction}`,
      object: 'article_bulk_actions',
      selected_article_count: `${this.selectedContentCount}`,
    });
    let params;
    if (this.actionIsMoveCollection) {
      params = {
        collection_id: this.selectedCollection,
      };
    } else if (this.actionIsUpdateAuthor) {
      params = {
        locales: selectedLocales,
        author_id: this.selectedAuthorForBulkUpdate.id,
      };
    } else {
      params = {
        locales: selectedLocales,
        for_all_locales: this.actionAppliesForAllLocales,
      };
    }
    try {
      let response = await ajax({
        url: `/ember/articles/bulk/`,
        type: 'POST',
        data: JSON.stringify({
          action: this.currentBulkAction,
          article_ids: this.args.selectedContent,
          app_id: this.app_id,
          params,
        }),
      });
      if (this.currentBulkAction === 'delete') {
        await this.args.selectedContent.forEach((article, index) => {
          let recordToDelete = this.store.peekRecord('article-multilingual', article);
          this.store.unloadRecord(recordToDelete);
        });
      } else {
        let successfullyUpdatedArticles = response.articles.filter(
          (updatedArticle) => updatedArticle.status === 'success',
        );
        this.store.pushPayload('article-multilingual', successfullyUpdatedArticles);
      }
      let failedArticleIds = response.articles
        .filter((article) => article.status === 'failure')
        .map((article) => article.article_id);
      let successfulArticleIds = response.articles
        .filter((article) => article.status === 'success')
        .map((article) => article.article_id);
      await this.args.updateFilters({});
      await this.notificationsService.notifyConfirmation(
        this.intl.t('articles.list.bulk_actions.success_action_banner', {
          action: this.intl.t(`articles.list.bulk_actions.${this.completedAction}`),
          articleCount: successfulArticleIds.length,
        }),
      );
      if (failedArticleIds.length > 0) {
        let firstFailedArticleName = articleTitleMapping[failedArticleIds[0]];
        this.notificationsService.notifyError(
          this.intl.t('articles.list.bulk_actions.failure_action_banner', {
            firstArticleName: firstFailedArticleName,
            articleCount: failedArticleIds.length,
            additional: failedArticleIds.length - 1,
          }),
        );
      }
    } catch (err) {
      this.notificationsService.notifyError(`Error processing ${this.currentBulkAction},`);
    } finally {
      this.isLoading = false;
      this.selectedCollection = '';
      this.args.resetBulkSelect();
      this.toggleModal(action);
      this.selectedAuthorForBulkUpdate = this.app.currentAdmin;
    }
  }
}
