/* import __COLOCATED_TEMPLATE__ from './checklist-settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { senderTypes } from 'embercom/models/data/outbound/constants';
import type Checklist from 'embercom/models/checklists/checklist';
import { DeliveryOption } from 'embercom/models/checklists/checklist';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { type Block } from 'embercom/models/common/blocks/block';
import type Store from '@ember-data/store';

const DAY_IN_SECONDS = 86400;
export const dayToSeconds = (days: number) => days * DAY_IN_SECONDS;

const DEFAULT_REMINDER_SPACING_PERIOD = dayToSeconds(1);
const DEFAULT_MAX_REMINDER_COUNT = 5;
const DEFAULT_STOP_REMINDER_PERIOD = dayToSeconds(14);

class ComposerConfig extends BaseConfig {
  autoFocus = false;
  textDirection: NotificationDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  singleBlockMode = true;
  allowedInline = [];
  allowTextAlignment = false;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  attributes: any;
  templating: { picker: string; resolver: AttributeInfoResolver };

  constructor(app: any, placeholder: string) {
    super();

    this.attributes = app.allowedAttributes;
    this.placeholder = placeholder;

    this.templating = {
      picker: 'common/attribute-picker',
      resolver: new AttributeInfoResolver({
        attributes: this.attributes,
        includeAppAttributes: false,
      }),
    };
  }
}

interface Args {
  checklist: Checklist;
}

const UNASSIGNED_ID = 0;
const placeholder = 'Please enter your checklist name';
export default class ChecklistSettings extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @tracked selectedSenderType = this.checklist.senderType;
  @tracked blocksDoc: BlocksDocument;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.blocksDoc = new BlocksDocument(this.checklist.titleBlocks.toArray());
  }

  senderTypeOptions = [
    { text: "Don't show sender", value: senderTypes.noSender },
    { text: 'Show sender', value: senderTypes.admin },
  ];

  defaultReminderSpacingOptions = [...Array(7)].map((_value, index) => ({
    text: index + 1,
    value: dayToSeconds(index + 1),
  }));

  maxReminderCountOptions = [...Array(5)].map((_value, index) => ({
    text: index + 1,
    value: index + 1,
  }));

  defaultStopReminderPeriodOptions = [
    { text: '7', value: dayToSeconds(7) },
    { text: '14', value: dayToSeconds(14) },
    { text: '21', value: dayToSeconds(21) },
    { text: '28', value: dayToSeconds(28) },
  ];

  deliveryOptions = {
    badge: DeliveryOption.BADGE,
    snippet: DeliveryOption.SNIPPET,
    full: DeliveryOption.FULL,
  };

  get app() {
    return this.appService.app;
  }

  get reminderSpacingOptions() {
    let qaOptions = [
      { text: '10 seconds', value: 10 },
      { text: '30 seconds', value: 30 },
      { text: '60 seconds', value: 60 },
    ];

    return this.app.checklistRemindersQA
      ? [...this.defaultReminderSpacingOptions, ...qaOptions]
      : this.defaultReminderSpacingOptions;
  }

  get stopReminderPeriodOptions() {
    let qaOptions = [
      { text: '10 seconds', value: 10 },
      { text: '30 seconds', value: 30 },
      { text: '60 seconds', value: 60 },
    ];
    return this.app.checklistRemindersQA
      ? [...this.defaultStopReminderPeriodOptions, ...qaOptions]
      : this.defaultStopReminderPeriodOptions;
  }

  get composerConfig() {
    return new ComposerConfig(this.app, placeholder);
  }

  get checklist() {
    return this.args.checklist;
  }

  get senderTypes() {
    return senderTypes;
  }

  get hasRemindersEnabled() {
    return (
      this.checklist.maxReminderCount > -1 ||
      this.checklist.reminderSpacingPeriod > -1 ||
      this.checklist.stopReminderAfterPeriod > -1
    );
  }

  @action toggleReminders() {
    if (this.hasRemindersEnabled) {
      this.checklist.maxReminderCount = -1;
      this.checklist.reminderSpacingPeriod = -1;
      this.checklist.stopReminderAfterPeriod = -1;
    } else {
      this.checklist.reminderSpacingPeriod = DEFAULT_REMINDER_SPACING_PERIOD;
      this.checklist.maxReminderCount = DEFAULT_MAX_REMINDER_COUNT;
      this.checklist.stopReminderAfterPeriod = DEFAULT_STOP_REMINDER_PERIOD;
    }
  }

  @action setReminderSpacingPeriod(period: number) {
    this.checklist.reminderSpacingPeriod = period;
  }

  @action setMaxReminderCount(count: number) {
    this.checklist.maxReminderCount = count;
  }

  @action setStopReminderPeriod(period: number) {
    this.checklist.stopReminderAfterPeriod = period;
  }

  @action setSelectedSenderOption(senderType: number) {
    this.selectedSenderType = senderType;
    if (senderType === senderTypes.noSender) {
      this.checklist.senderId = UNASSIGNED_ID;
      this.checklist.senderType = this.senderTypes.noSender;
    } else {
      this.checklist.senderId = this.app.currentAdmin.id;
      this.checklist.senderType = senderType;
    }
  }

  @action updateBlocks(blocksDoc: BlocksDocument) {
    let blockFragments: Array<Block> = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.checklist.set('titleBlocks', blockFragments);
    this.checklist.notifyPropertyChange('titleBlocks');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Sidebar::ChecklistSettings': typeof ChecklistSettings;
    'checklist/sidebar/checklist-settings': typeof ChecklistSettings;
  }
}
