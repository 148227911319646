/* import __COLOCATED_TEMPLATE__ from './calendar-date-picker-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { schedule } from '@ember/runloop';
import $ from 'jquery';
import { A } from '@ember/array';
import { computed, observer } from '@ember/object';
import { on } from '@ember/object/evented';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';
import { subtractFrom, ternary } from '@intercom/pulse/lib/computed-properties';
import ScrollManager from 'embercom/components/mixins/scroll-manager';
import CloseOnDocumentClickManager from 'embercom/components/mixins/close-on-document-click-manager';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';

// Calendar date picker component:
// Arguments:
//  day: selected day (defaults to current date if null)
//  month: selected month (defaults to current month if null)
//  year: selected year (defaults to current year if null)
//  disabled: whether the picker should be disabled (defaults to false)
//  scrollParent: the scroll parent that we bind to to manage relative scrolling.
//  disablePast: whether selecting dates in the past should be forbidden (defaults to false)
//  disableFuture: whether selecting dates in the future should be forbidden (defaults to false)
export default Component.extend(ScrollManager, CloseOnDocumentClickManager, {
  intl: service(),
  classNames: ['flex', 'items-center'],
  classNameBindings: ['isInvisibleSelect:f__invisible-select', 'isOpen:o__opened'],
  attributeBindings: ['tabindex'],
  tabindex: 0,
  MAX_WEEKS_IN_MONTH: 5,
  DAYS_IN_WEEK: 7,
  selectClass: ternary('isInvisibleSelect', 'f__invisible-select__box', 'f__custom-select__box'),
  caretClass: ternary('isInvisibleSelect', 'f__invisible-select__caret', 'f__custom-select__caret'),
  inputSelector: '.f__custom-select__box',
  isFirstMonth: equal('month', 1),
  momentMonthIndex: subtractFrom('month', 1),
  disablePast: false,
  disableFuture: false,
  disableDatesBefore: null,
  disabled: false,

  setup: on('didInsertElement', function () {
    this._setMomentJSLocale();

    let currentDate = moment();
    if (!this.day) {
      this.set('day', currentDate.date());
    }
    if (!this.month) {
      this.set('month', currentDate.month() + 1);
    }
    if (!this.year) {
      this.set('year', currentDate.year());
    }
    this.setSelectedDate();
  }),
  didInsertElement() {
    this._super(...arguments);
    this.bindToScrollParent(this._calculateCalendarPositioning);
  },
  willDestroyElement() {
    this.unbindFromScrollParent();
    this._super(...arguments);
  },

  setSelectedDate() {
    let date = new Date(this.year, this.momentMonthIndex, this.day);
    this.set(
      'selectedDate',
      this.intl.formatDate(date, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }),
    );
  },

  moment: computed('month', 'year', function () {
    return moment({
      year: this.year,
      month: this.momentMonthIndex,
    });
  }),

  monthAndYearLabel: computed('moment', function () {
    return this.moment.format(dateAndTimeFormats.monthAndYear);
  }),

  numberOfDaysInMonth: computed('month', 'year', function () {
    return new Date(this.year, this.month, 0).getDate();
  }),

  numberOfDaysInPreviousMonth: computed('month', 'year', function () {
    let month = this.isFirstMonth ? 12 : this.month - 1;
    let year = this.isFirstMonth ? this.year - 1 : this.year;
    return new Date(year, month, 0).getDate();
  }),

  firstDayOfMonth: computed('moment', function () {
    return this.moment.date(1).days();
  }),

  calendarData: computed('moment', 'day', function () {
    let weeks = A();
    let day = 1;
    for (let week = 0; week <= this.MAX_WEEKS_IN_MONTH; week++) {
      let days = [];
      for (let dayOfWeek = 0; dayOfWeek < this.DAYS_IN_WEEK; dayOfWeek++) {
        if (this._isDayBeforeFirstDayOfTheMonth(week, dayOfWeek)) {
          days.pushObject(this._getPreviousMonthDay(dayOfWeek));
        } else if (day > this.numberOfDaysInMonth) {
          // Don't start a new week for the next month
          if (dayOfWeek === 0) {
            break;
          }
          days.pushObject(this._getNextMonthDay(day));
          day++;
        } else {
          days.pushObject(this._getCurrentMonthDay(day));
          day++;
        }
      }
      if (days.length) {
        weeks.pushObject(days);
      }
    }
    return weeks;
  }),

  _isDayBeforeFirstDayOfTheMonth(week, day) {
    return week === 0 && day < this.firstDayOfMonth;
  },

  _getPreviousMonthDay(dayOfWeek) {
    let previousMonthDate = this.numberOfDaysInPreviousMonth - this.firstDayOfMonth + dayOfWeek + 1;
    return {
      date: previousMonthDate,
      isToday: this._isToday(previousMonthDate, this.momentMonthIndex - 1),
      isSelected: this._isSelected(previousMonthDate, this.momentMonthIndex - 1),
      isDisabled: this._isDisabled(previousMonthDate, this.momentMonthIndex - 1),
      isPreviousWeek: true,
    };
  },

  _getCurrentMonthDay(day) {
    return {
      date: day,
      isToday: this._isToday(day, this.momentMonthIndex),
      isSelected: this._isSelected(day, this.momentMonthIndex),
      isDisabled: this._isDisabled(day, this.momentMonthIndex),
    };
  },

  _getNextMonthDay(day) {
    let nextMonthDate = day - this.numberOfDaysInMonth;
    return {
      date: nextMonthDate,
      isToday: this._isToday(nextMonthDate, this.momentMonthIndex + 1),
      isSelected: this._isSelected(nextMonthDate, this.momentMonthIndex + 1),
      isDisabled: this._isDisabled(nextMonthDate, this.momentMonthIndex + 1),
      isNextWeek: true,
    };
  },

  _isSelected(day, month) {
    return moment({
      day,
      month,
      year: this.year,
    }).isSame(this.selectedDate, 'days');
  },

  _isToday(day, month) {
    return moment({
      day,
      month,
      year: this.year,
    }).isSame(moment(), 'days');
  },

  _isDisabled(day, month) {
    let date = moment({ day, month, year: this.year });
    let inPast = date.isBefore(moment(), 'days');
    let inFuture = date.isAfter(moment(), 'days');
    let beforeMaxPastDate =
      this.disableDatesBefore !== null && date.isBefore(this.disableDatesBefore, 'days');

    return (this.disablePast && inPast) || (this.disableFuture && inFuture) || beforeMaxPastDate;
  },

  _setMomentJSLocale() {
    let momentJsLocale = moment.locale();
    let primaryLocale = this.intl.primaryLocale;

    if (momentJsLocale === primaryLocale) {
      return;
    }

    moment.locale(primaryLocale);
  },

  _calculateCalendarPositioning: observer({
    dependentKeys: ['isOpen', 'month'],

    fn() {
      if (this.isOpen && !this.isDestroying) {
        schedule('afterRender', this, function () {
          if (this.isDestroying) {
            return true;
          }
          let $input = $(this.inputSelector, this.element);
          if ($input.length === 0) {
            $input = $(this.element);
          }
          let $calendar = $('.overlay', this.element);
          let inputBoundingRect = $input[0].getBoundingClientRect();

          $calendar.css({ paddingBottom: 0 }); // Remove padding before calculating height.
          let top = this._calculateTopPosition($calendar, inputBoundingRect);
          $calendar.css({
            position: 'fixed',
            top: `${top}px`,
            left: `${inputBoundingRect.left}px`,
            width: '225px',
            paddingBottom: 0,
          });
        });
      }
    },

    sync: true,
  }),

  _calculateTopPosition($calendar, inputBoundingRect) {
    let MAX_HEIGHT = 260; // This is the height of a six-week calendar
    let shouldOpenBelow = inputBoundingRect.bottom + MAX_HEIGHT < window.innerHeight;
    return shouldOpenBelow
      ? inputBoundingRect.bottom
      : inputBoundingRect.top - $calendar.outerHeight();
  },

  actions: {
    openCalendar() {
      this.toggleProperty('isOpen');
    },
    previousMonth() {
      if (this.isFirstMonth) {
        this.setProperties({
          month: 12,
          year: this.year - 1,
        });
      } else {
        this.decrementProperty('month');
      }
    },
    nextMonth() {
      if (this.month === 12) {
        this.setProperties({
          month: 1,
          year: this.year + 1,
        });
      } else {
        this.incrementProperty('month');
      }
    },
    selectDate(day) {
      this.set('day', day);
      this.setSelectedDate();
      this.set('isOpen', false);

      let onUpdateAction = this.onUpdate;
      if (onUpdateAction) {
        onUpdateAction(this.getProperties('day', 'month', 'year'));
      }
    },
  },
});
