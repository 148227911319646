/* import __COLOCATED_TEMPLATE__ from './feedback-loop-banner.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface Args {
  earlyStageGraduation: any;
}

export default class FeedbackLoopBanner extends Component<Args> {
  @service declare purchaseAnalyticsService: any;
  @service declare intl: IntlService;

  get programName() {
    return this.args.earlyStageGraduation.earlyStageStep?.stepConfig?.couponPercentOff === 100
      ? this.intl.t(
          'billing.details.early_stage_feedback_loop_banner.program_name.startups_program',
        )
      : this.intl.t('billing.details.early_stage_feedback_loop_banner.program_name.early_stage');
  }

  @action
  trackClickEvent() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'early_stage_feedback_loop_banner',
      place: 'billing_summary',
      object: 'chat_with_us',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::EarlyStage::FeedbackLoopBanner': typeof FeedbackLoopBanner;
    'billing/summary/early-stage/feedback-loop-banner': typeof FeedbackLoopBanner;
  }
}
