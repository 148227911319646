/* import __COLOCATED_TEMPLATE__ from './mappings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { filterBy, readOnly, union } from '@ember/object/computed';

const INTEGRATION_CODE_MAPPING = {
  'salesforce-by-intercom': 'Salesforce',
  hubspot: 'HubSpot',
};

const UTM_ATTRIBUTE_IDENTIFIERS = [
  'referrer',
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
];

const GEO_IP_ATTRIBUTE_IDENTIFIERS = ['geoip_data.country_code', 'geoip_data.continent_code'];

const CREATE_ONLY_ATTRIBUTE_IDENTIFIERS = UTM_ATTRIBUTE_IDENTIFIERS.concat(
  GEO_IP_ATTRIBUTE_IDENTIFIERS,
);

const COMPANY_NAME_IDENTIFIER = 'company.name';

export default Component.extend({
  tagName: '',
  attributeService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  attributes: readOnly('attributeService.attributes'),

  personQualificationAttributes: filterBy(
    'app.qualification_attributes',
    'isCompany',
    false,
  ).readOnly(),

  companyQualificationAttributes: filterBy(
    'app.qualification_attributes',
    'isCompany',
    true,
  ).readOnly(),

  companyNameAttribute: filterBy(
    'app.qualification_attributes',
    'identifier',
    COMPANY_NAME_IDENTIFIER,
  ).readOnly(),

  qualificationAttributes: union(
    'companyNameAttribute',
    'personQualificationAttributes',
  ).readOnly(),

  integrationCode: readOnly('mappings.firstObject.integrationCode'),

  crmAttributeLabel: computed('integrationCode', function () {
    return INTEGRATION_CODE_MAPPING[this.integrationCode];
  }),

  utmAttributes: computed(
    'attributes.[]',
    'mappings.[]',
    'mappings.@each.{destinationAttributePath,sourceAttributePath,direction}',
    function () {
      return this.attributes
        .filter((attribute) => UTM_ATTRIBUTE_IDENTIFIERS.includes(attribute.identifier))
        .map((attribute) => {
          return this.extractMappingDataFromAttribute(attribute);
        });
    },
  ),

  geoIpAttributes: computed(
    'attributes.[]',
    'mappings.[]',
    'mappings.@each.{destinationAttributePath,sourceAttributePath,direction}',
    function () {
      return this.attributes
        .filter((attribute) => GEO_IP_ATTRIBUTE_IDENTIFIERS.includes(attribute.identifier))
        .map((attribute) => {
          return this.extractMappingDataFromAttribute(attribute);
        });
    },
  ),

  companyAttributes: computed(
    'companyQualificationAttributes',
    'mappings.[]',
    'mappings.@each.{destinationAttributePath,sourceAttributePath,direction}',
    function () {
      return this.companyQualificationAttributes
        .reject((attribute) => attribute.identifier === COMPANY_NAME_IDENTIFIER)
        .map((attribute) => {
          return this.extractMappingDataFromAttribute(attribute.dataAttribute);
        });
    },
  ),

  mappingData: computed(
    'salesforceMappingData.[]',
    'crmMappingData.[]',
    'integrationCode',
    function () {
      switch (this.integrationCode) {
        case 'salesforce-by-intercom':
          return this.salesforceMappingData;
        default:
          return this.crmMappingData;
      }
    },
  ),

  destinationObjectType: computed('integrationCode', function () {
    switch (this.integrationCode) {
      case 'salesforce-by-intercom':
        return 'Lead';
      case 'hubspot':
        return 'contact';
      case 'marketo_enrichment':
        return 'lead';
      default:
        return '';
    }
  }),

  salesforceMappingData: union(
    'crmMappingData',
    'geoIpAttributes',
    'companyAttributes',
    'utmAttributes',
  ).readOnly(),

  crmMappingData: computed(
    'qualificationAttributes',
    'attributeOptionsForType.[]',
    'mappings.[]',
    'mappings.@each.{destinationAttributePath,sourceAttributePath,direction}',
    function () {
      return this.qualificationAttributes.map((qualificationAttribute) => {
        return this.extractMappingDataFromAttribute(qualificationAttribute.dataAttribute);
      });
    },
  ),

  extractMappingDataFromAttribute(attribute) {
    let sourceAttributePath = attribute.identifier;
    let existingMapping = this.findExistingMappingBySourcePath(sourceAttributePath);
    return {
      name: attribute.name,
      type: attribute.type,
      identifier: sourceAttributePath,
      options: this.getSelectorOptionsForType(attribute.type),
      selectedValue: this.getSelectedValue(existingMapping),
      editable: this.isMappingEditable(existingMapping),
      createOnly: this.isCreateOnlyAttribute(attribute.identifier),
    };
  },

  isCreateOnlyAttribute(identifier) {
    return CREATE_ONLY_ATTRIBUTE_IDENTIFIERS.includes(identifier);
  },

  getSelectedValue(mapping) {
    return (mapping && mapping.destinationAttributePath) || undefined;
  },

  isMappingEditable(mapping) {
    return !mapping || (mapping && mapping.editable);
  },

  getSelectorOptionsForType(type) {
    let typedAttributes = this.attributeOptionsForType.get(type);
    return typedAttributes.map((attribute) => {
      return {
        text: attribute.label,
        value: attribute.path,
        isDisabled: !!this.mappings.findBy('destinationAttributePath', attribute.path),
      };
    });
  },

  findExistingMappingBySourcePath(sourceAttributePath) {
    return this.mappings.findBy('sourceAttributePath', sourceAttributePath);
  },

  createOrUpdateMapping: action(function (rowData, selectedValuePath) {
    let existingMapping = this.findExistingMappingBySourcePath(rowData.identifier);
    if (existingMapping) {
      existingMapping.set('destinationAttributePath', selectedValuePath);
    } else {
      this.mappings.createFragment({
        appIdCode: this.app.id,
        destinationAttributePath: selectedValuePath,
        editable: true,
        integrationCode: this.integrationCode,
        sourceAttributePath: rowData.identifier,
        sourceObjectType: 'contact',
        destinationObjectType: this.destinationObjectType,
        sourceType: rowData.type,
      });
    }
    this.updateSettings.perform();
  }),

  removeMapping: action(function (sourceAttributePath) {
    let removedMapping = this.findExistingMappingBySourcePath(sourceAttributePath);
    this.mappings.removeFragment(removedMapping);
    this.updateSettings.perform();
  }),
});
