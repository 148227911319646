/* import __COLOCATED_TEMPLATE__ from './subheader.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import type Subheader from 'embercom/models/customization-options/global-options/subheader';
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  form: any;
  subheader: Subheader;
}

const SubheaderComponent = templateOnlyComponent<Args>();
export default SubheaderComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Styling::Header::Subheader': typeof SubheaderComponent;
  }
}
