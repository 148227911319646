/* import __COLOCATED_TEMPLATE__ from './people-reached-table.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface PeopleReachedReportExport {
  [key: string]: {
    hasFile: boolean;
    usagePeriod: string;
    date: string;
  };
}
interface Args {
  peopleReachedReports: PeopleReachedReportExport[];
}

export default class PeopleReachedTable extends Component<Args> {
  @service intercomEventService: any;

  get hasAnyPeopleReachedReports(): boolean {
    return Object.values(this.args.peopleReachedReports).some(({ hasFile }) => hasFile);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Invoices::Reporting::PeopleReachedTable': typeof PeopleReachedTable;
    'billing/invoices/reporting/people-reached-table': typeof PeopleReachedTable;
  }
}
