/* import __COLOCATED_TEMPLATE__ from './usage-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Charge from 'embercom/models/billing/price/charge';
import type Contract from 'embercom/models/billing/contract';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { type TooltipRow } from 'embercom/components/billing/usage/tooltips/tooltip-item';
import { PLAN_DATA } from 'embercom/lib/billing';

interface Args {
  testIdentifier: string;
  charge: Charge;
  contract?: Contract;
  pricingMetric: PricingMetric;
  includedUsage: number;
  planId?: string;
}

export default class UsageDetails extends Component<Args> {
  @service declare intl: IntlService;
  @service declare customerService: any;

  get tooltipRows(): TooltipRow[] {
    return [...this.multiWorkspaceDetails, ...this.yourUsageDetails, ...this.includedUsageDetails];
  }

  get formattedActualUsage() {
    let { contract } = this.args;

    if (!this.args.charge.isValidUsageMetric) {
      return;
    } else if (contract && !contract?.isSecondarySubscription) {
      return this.actualUsageTextForIncludedUsage;
    } else if (contract?.isSecondarySubscription) {
      return this.intl.formatNumber(this.getUsageForWorkspace());
    }

    return this.actualUsageTextForIncludedUsage;
  }

  get actualUsageTextForIncludedUsage() {
    let { pricingMetric, includedUsage } = this.args;

    if (!this.args.charge.actualUsage) {
      return this.intl.formatNumber(0);
    }

    if (includedUsage === 0) {
      return this.intl.formatNumber(this.args.charge.actualUsage);
    } else if (pricingMetric.isSeatMetric) {
      return this.formatActualOfIncludedUsage(this.args.charge.actualUsage, includedUsage);
    } else {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.actual-of-included-usage-percent',
        {
          percentUsed: this.intl.formatNumber(this.args.charge.actualUsage / includedUsage, {
            style: 'percent',
            maximumFractionDigits: 0,
          }),
          actualUsage: this.intl.formatNumber(this.args.charge.actualUsage),
          includedUsage: this.intl.formatNumber(includedUsage),
        },
      );
    }
  }

  private formatActualOfIncludedUsage(actualUsage: number, includedUsage: number): string {
    return this.intl.t('billing.summary.price-usage-breakdown-card.actual-of-included-usage', {
      actualUsage: this.intl.formatNumber(actualUsage),
      includedUsage: this.intl.formatNumber(includedUsage),
    });
  }

  get displayInYourSubscriptionRow() {
    return this.args.charge.isAnnualOrSalesforceContracted && this.args.includedUsage > 0;
  }

  get displayMultiWorkspaceRow() {
    let { contract } = this.args;
    return (
      contract && (contract.numberOfSecondarySubscriptions > 0 || contract.isSecondarySubscription)
    );
  }

  get displayIncludedUsageDetails(): boolean {
    // Messages sent - contracted - have usage limits in contract
    // Messages sent - non-contracted - they don't, so we show the PSP line
    if (
      this.args.pricingMetric.metric === Metric.messages_sent &&
      this.args.contract?.contractUsageLimits[this.args.pricingMetric.metric] === 0
    ) {
      return true;
    }

    return this.args.charge.baseUsage > 0;
  }

  get multiWorkspaceDetails() {
    return this.displayMultiWorkspaceRow
      ? [
          {
            label: this.intl.t(
              'billing.summary.price-usage-breakdown-card.overage-tooltip.your-usage-for-this-workspace',
            ),
            muted: true,
            noPadding: true,
          },
          { label: this.formattedActualUsageForCurrentWorkspace },
          {
            label: this.intl.t(
              'billing.summary.price-usage-breakdown-card.overage-tooltip.combined-usage',
            ),
            muted: true,
            noPadding: true,
          },
          { label: this.formattedCombinedUsage },
        ]
      : [];
  }

  get yourUsageDetails() {
    return !this.displayMultiWorkspaceRow
      ? [
          {
            label: this.intl.t(
              'billing.summary.price-usage-breakdown-card.overage-tooltip.your-usage',
            ),
            muted: true,
            noPadding: true,
          },
          { label: `${this.formattedActualUsage}` },
        ]
      : [];
  }

  get includedUsageDetails() {
    if (this.displayInYourSubscriptionRow) {
      return [];
    } //Don't show included usage line if we are showing subscription details

    if (!this.displayIncludedUsageDetails || this.args.planId === undefined) {
      return [];
    }

    return [
      {
        label: this.intl.t(
          'billing.summary.price-usage-breakdown-card.overage-tooltip.included-usage',
          { productName: PLAN_DATA[this.args.planId].name },
        ),
        muted: true,
        noPadding: true,
      },
      {
        label: this.intl.formatNumber(this.args.charge.baseUsage),
      },
    ];
  }

  private getUsage(workspace: any): number {
    return workspace.usage && workspace.usage[this.args.charge.pricing_metric] !== undefined
      ? workspace.usage[this.args.charge.pricing_metric]
      : 0;
  }

  private getUsageForWorkspace(): number {
    let { contract } = this.args;

    let workspace = contract?.perWorkspaceUsage.find(
      (workspace) => workspace.app_id === this.customerService.app.id,
    );

    return workspace ? this.getUsage(workspace) : 0;
  }

  get formattedActualUsageForCurrentWorkspace() {
    let { contract } = this.args;

    if (!this.args.charge.isValidUsageMetric || !contract) {
      return;
    }

    return this.intl.formatNumber(this.getUsageForWorkspace());
  }

  get formattedCombinedUsage() {
    let { contract } = this.args;

    if (!this.args.charge.isValidUsageMetric || !contract) {
      return;
    }

    let combinedUsage = contract.perWorkspaceUsage.reduce(
      (sum, workspace) => sum + this.getUsage(workspace),
      0,
    );

    if (this.args.includedUsage === 0) {
      return this.intl.formatNumber(combinedUsage);
    }

    return this.formatActualOfIncludedUsage(combinedUsage, this.args.includedUsage);
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::Tooltips::UsageDetails': typeof UsageDetails;
  }
}
