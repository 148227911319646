/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import * as IntercomEmbed from '@intercom/intercom-embed';
import AppColor from 'embercom/lib/color';

export default class CarouselEmbeddedStyles extends Component {
  get commonEmbeddedStyleSheetBody() {
    if (IntercomEmbed && IntercomEmbed.getStylesheet) {
      return IntercomEmbed.getStylesheet()
        .replace(/-intercom-app-color/g, this.args.appColor)
        .replace(/#intercom_composer/g, '.intercom_composer');
    } else {
      return '';
    }
  }

  get applicationColors() {
    return new AppColor().generate_message_box_colors(this.args.appColor);
  }
}
