/* import __COLOCATED_TEMPLATE__ from './help-card.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default Component.extend({
  router: service(),
  purchaseAnalyticsService: service(),

  click() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'cancellation_flow',
      place: 'get_help_offer',
      object: `${this.offer.name}_button`,
      section: 'billing_cancel',
    });
    if (this.offer.name === 'chat_with_us') {
      this.openInAppMessenger();
    } else {
      safeWindowOpen(this.offer.link, '_blank');
    }
  },
});
