/* import __COLOCATED_TEMPLATE__ from './cleanup-before-import-modal.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    closeModal: () => void;
    showBackButton?: boolean;
    onNavigationBack?: () => void;
    removeRegularSync: () => void;
    processing?: boolean;
  };
}

const CleanupBeforeImportModal = templateOnlyComponent<Signature>();
export default CleanupBeforeImportModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Shared::CleanupBeforeImportModal': typeof CleanupBeforeImportModal;
  }
}
