/* import __COLOCATED_TEMPLATE__ from './android-push-component.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AndroidPushComponent extends Component {
  @service appService;
  @tracked sdkApp = this.args.sdkApp || {};
  @tracked pushCredentials = this.sdkApp.pushCredentials || [];
}
