/* import __COLOCATED_TEMPLATE__ from './collection-card-new.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type OutboundContent from 'embercom/models/customization-options/outbound-content';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { MediaHelper, type UploadHelper } from 'embercom/objects/media/media-helper';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import {
  CollectionCardImageType,
  DEFAULT_GLOBAL_OPTIONS,
} from 'embercom/models/customization-options/collection-card-options';

interface Args {
  site: HelpCenterSite;
  uploadHelper: UploadHelper;
}

export default class CollectionCardNew extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked selectedCollectionId: number | null = this.allCollectionsOption.value;

  @tracked collectionCardOptions = this.args.site.customizationOptions.collectionCard;
  @tracked globalOptions = this.collectionCardOptions.global;

  get selectedBackgroundOption(): CollectionCardImageType {
    return this.collectionCardOptions.global.imageType;
  }

  constructor(owner: any, args: Args) {
    super(owner, args);

    if (this.globalOptions.backgroundColor) {
      this.globalOptions.selectColor(this.globalOptions.backgroundColor);
    }

    this.snapshotCardOptions();
  }

  get homeCollections() {
    return this.args.site.visibleCollections;
  }

  get cardOptionsSnapshots() {
    return this.args.site.customizationOptions.cardOptionsSnapshots;
  }

  get homePageCollections() {
    let selectCollections = this.homeCollections.map((collection) => {
      return {
        text: collection.name,
        value: parseInt(collection.id, 10),
      };
    });
    return [this.allCollectionsOption, ...selectCollections];
  }

  get allCollectionsOption() {
    return {
      text: this.intl.t('articles.settings.redesign.content.collection-card.all-collections'),
      value: null,
    };
  }

  get isAllCollectionsSelected(): boolean {
    return this.selectedCollectionId === this.allCollectionsOption.value;
  }

  get activeCustomizationOptions(): OutboundContent {
    if (this.isAllCollectionsSelected || this.selectedCollectionId === null) {
      return this.globalOptions;
    }

    let collection = this.collectionCardOptions.getOrCreateCollection(this.selectedCollectionId);

    return collection.outboundContent;
  }

  get activeMediaHelper() {
    let collectionCardMediaHelpers = this.args.uploadHelper.getMediaHelper('collection_card');
    let collectionSiteSettingsName = this.isAllCollectionsSelected
      ? 'collectionCardGlobal'
      : `collectionCard-${this.selectedCollectionId}`;
    let collectionCardType = this.selectedBackgroundOption.toString();

    let mediaHelper = collectionCardMediaHelpers.find((mediaHelper: MediaHelper) => {
      return (
        mediaHelper.siteSettingsName === collectionSiteSettingsName &&
        mediaHelper.collectionCardType === collectionCardType
      );
    });

    if (!mediaHelper) {
      mediaHelper = MediaHelper.create({
        type: 'collection_card',
        siteSettingsName: collectionSiteSettingsName,
        collectionCardType,
      });

      this.args.uploadHelper.getMediaHelper('collection_card').pushObject(mediaHelper);
    }

    return mediaHelper;
  }

  get isIconBackgroundsEnabled() {
    return this.globalOptions.isBackgroundsEnabled;
  }

  snapshotCardOptions(): void {
    let snapshot = {
      selectedCollectionId: this.selectedCollectionId,
      options: this.collectionCardOptions.toJSON(),
    };
    this.cardOptionsSnapshots[this.selectedBackgroundOption] = snapshot;
  }

  restoreCardOptions(key: CollectionCardImageType): void {
    let snapshot = this.cardOptionsSnapshots[key];

    let useDefaultBackroundColor = key === CollectionCardImageType.Icons;
    this.collectionCardOptions.initFromSnapshot(snapshot?.options, useDefaultBackroundColor);
    this.selectedCollectionId = snapshot?.selectedCollectionId ?? null;
    this.collectionCardOptions.deleteMissingCollectionFromSnapshot(snapshot?.options);
  }

  @action
  removeBackgroundImageUrl(): void {
    this.activeCustomizationOptions.backgroundImageUrl = null;
  }

  @action
  toggleIconBackgrounds(): void {
    if (this.globalOptions.isBackgroundsEnabled) {
      this.snapshotCardOptions();
      this.removeAllBackgrounds();
    } else {
      this.restoreCardOptions(CollectionCardImageType.Icons);
    }
  }

  @action
  onSelectCollection(collectionId: string | null): void {
    if (collectionId === this.allCollectionsOption.value) {
      this.selectedCollectionId = this.allCollectionsOption.value;
      return;
    }

    this.selectedCollectionId = parseInt(collectionId, 10);

    this.collectionCardOptions.getOrCreateCollection(this.selectedCollectionId);
  }

  @action
  setFontColor(color: string): void {
    this.activeCustomizationOptions.fontColor = color;

    if (this.isAllCollectionsSelected) {
      this.collectionCardOptions.collections.forEach((collection) => {
        collection.outboundContent.fontColor = color;
      });
    }
  }

  @action
  handleIconBackgroundUpdate(option: OutboundContent): void {
    if (!this.isAllCollectionsSelected) {
      this.activeCustomizationOptions.backgroundColor =
        option.backgroundColor ?? DEFAULT_GLOBAL_OPTIONS.backgroundColor;
      return;
    }

    // Remove all collection upload helpers
    let collectionCardMediaHelpers = this.args.uploadHelper.getMediaHelper('collection_card');
    collectionCardMediaHelpers.forEach((mediaHelper: MediaHelper) => {
      if (
        mediaHelper.siteSettingsName !== 'collectionCardGlobal' &&
        mediaHelper.collectionCardType === CollectionCardImageType.Icons
      ) {
        collectionCardMediaHelpers.removeObject(mediaHelper);
      }
    });

    let { backgroundColor, backgroundImageUrl, backgroundGradient } = option;
    let collectionCards = this.collectionCardOptions.collections;
    collectionCards.forEach((collection) => {
      collection.outboundContent.backgroundImageUrl = backgroundImageUrl;
      collection.outboundContent.duplicateGradient(backgroundGradient);
      collection.outboundContent.backgroundColor = backgroundColor;
    });

    // After removing the image, we want to keep the icon's background option enabled
    // by setting its background color back to the default color
    if (!option.isBackgroundsEnabled) {
      option.backgroundColor = DEFAULT_GLOBAL_OPTIONS.backgroundColor;
    }
  }

  @action
  handleBackgroundImageChange() {
    this.activeCustomizationOptions.removeColor();
    this.activeCustomizationOptions.removeGradient();
    this.activeCustomizationOptions.backgroundImageUrl = this.activeMediaHelper.url || null;

    if (this.isAllCollectionsSelected) {
      // Remove all collection upload helpers
      let collectionCardMediaHelpers = this.args.uploadHelper.getMediaHelper('collection_card');
      collectionCardMediaHelpers.forEach((mediaHelper: MediaHelper) => {
        if (
          mediaHelper.siteSettingsName !== 'collectionCardGlobal' &&
          mediaHelper.collectionCardType === CollectionCardImageType.Images
        ) {
          collectionCardMediaHelpers.removeObject(mediaHelper);
        }
      });

      let { backgroundColor, backgroundImageUrl, backgroundGradient } =
        this.activeCustomizationOptions;
      let collectionCards = this.collectionCardOptions.collections;
      collectionCards.forEach((collection) => {
        collection.outboundContent.backgroundImageUrl = backgroundImageUrl;
        collection.outboundContent.duplicateGradient(backgroundGradient);
        collection.outboundContent.backgroundColor = backgroundColor;
      });
    }
  }

  removeAllBackgrounds() {
    this.collectionCardOptions.collections.forEach((collection) => {
      collection.outboundContent.clearBackground();
    });
    this.globalOptions.clearBackground();
  }

  @action
  handleBackgroundOptionChange(option: 'icons' | 'images' | 'none'): void {
    this.snapshotCardOptions();
    this.removeAllBackgrounds();

    // None option means that no icon/image is shown on the collection card.
    // This is represented by empty background customization options and showIcons
    // set to false on the global options.
    if (option === 'none') {
      this.globalOptions.showIcons = false;
      this.globalOptions.imageType = CollectionCardImageType.None;
    }
    // Icons option means that the collection icon is shown on the collection card.
    // The icon may or may not have a background set and so, showIcons set to true
    // on the global options distinguishes this option from the others.
    // e.g. backgroundImageUrl alone is not enough to distinguish between the images
    // and icons options.
    else if (option === 'icons') {
      this.restoreCardOptions(CollectionCardImageType.Icons);
      this.globalOptions.showIcons = true;
      this.globalOptions.imageType = CollectionCardImageType.Icons;
    }
    // Images option means that the collection card background image is shown on the
    // collection card. To distinguish this option from the icons option with a background
    // image, showIcons is set to false on the global options.
    else if (option === 'images') {
      this.restoreCardOptions(CollectionCardImageType.Images);
      this.globalOptions.showIcons = false;
      this.globalOptions.imageType = CollectionCardImageType.Images;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Styling::CollectionCardNew': typeof CollectionCardNew;
  }
}
