/* import __COLOCATED_TEMPLATE__ from './initializer.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly, notEmpty } from '@ember/object/computed';
import { action } from '@ember/object';
import Component from '@ember/component';
export default Component.extend({
  requiredParams: readOnly('messengerApp.params'),
  hasRequiredParams: notEmpty('requiredParams'),
  hasConfigureUrl: notEmpty('messengerApp.configure_url'),
  preventBackToAppsClick: false,
  headerTooltipText: '',

  init() {
    this.set('paramValues', {});
    this._super(...arguments);
  },

  initializeAction: action(function () {
    this.initialize.perform(this.paramValues);
  }),

  onConfigResults: action(function (paramValues) {
    this.initialize.perform(paramValues);
  }),
});
