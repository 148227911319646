/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  store: service(),
  intercomConfirmService: service(),
  redirectService: service(),
  intl: service(),
  init() {
    this._super(...arguments);
    this.setupTwitterData.perform();
    this.registerHooks({ afterUninstall: this.cleanupPostUninstall });
  },
  setupTwitterData: task(function* () {
    let twitterIntegration = yield this.store.queryRecord('twitter-integration', {});
    this.setProperties({
      twitterIntegration,
    });
  }).drop(),
  cleanupPostUninstall: task(function* () {
    try {
      yield this.twitterIntegration.destroyRecord();
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('appstore.settings.twitter.main.notification.uninstall-error'),
      );
    }
  }).drop(),
  authenticateTwitter() {
    return ajax({
      url: '/ember/twitter_integrations/authenticate',
      type: 'GET',
      data: {
        app_id: this.get('app.id'),
        is_app_store: true,
      },
    });
  },
  addAccountTask: task(function* () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'added',
      object: 'twitter-account',
    });

    try {
      let authenticateResponse = yield this.authenticateTwitter();
      let redirectUrl = yield this.generateRedirectUrl.perform(
        authenticateResponse.redirect_url,
        false,
      );
      this.redirectService.redirect(redirectUrl);
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
  }).drop(),
  removeAccountTask: task(function* (account) {
    let options = {
      title: this.intl.t('appstore.settings.twitter.main.credentials-delete.modal-title'),
      body: this.intl.t('appstore.settings.twitter.main.credentials-delete.modal-confirmation', {
        name: account.get('name'),
      }),
      confirmButtonText: this.intl.t(
        'appstore.settings.twitter.main.credentials-delete.modal-button',
      ),
      primaryButtonType: 'primary-destructive',
    };

    let confirmed = yield this.intercomConfirmService.confirm(options);
    if (confirmed) {
      try {
        yield account.destroyRecord();
        this.notificationsService.notifyConfirmation(
          this.intl.t('appstore.settings.twitter.main.notification.remove-account-success'),
        );
        this.intercomEventService.trackAnalyticsEvent({
          action: 'removed',
          object: account,
        });
      } catch (e) {
        this.notificationsService.notifyError(
          this.intl.t('appstore.settings.twitter.main.remove-account-error', {
            name: account.get('name'),
          }),
        );
      }
    }
  }).drop(),
});
