/* import __COLOCATED_TEMPLATE__ from './tickets-portal-link.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import type TicketsPortalLink from 'embercom/models/customization-options/layout-options/blocks/tickets-portal-link';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  site: HelpCenterSite;
  homepageBlock: TicketsPortalLink;
}

export default class TicketsPortalLinkComponent extends Component<Args> {
  get enabled() {
    return this.args.homepageBlock.enabled;
  }

  set enabled(enabled: boolean) {
    this.args.homepageBlock.enabled = enabled;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Content::Layout::TicketsPortalLink': typeof TicketsPortalLinkComponent;
    'articles/site/settings/content/layout/tickets-portal-link': typeof TicketsPortalLinkComponent;
  }
}
