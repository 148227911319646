/* import __COLOCATED_TEMPLATE__ from './reporting.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class Reporting extends Component {
  @service notificationsService;
  @service appService;
  @service store;

  @tracked reportingData = {};
  customNotificationKey = 'salesforce-settings-notification';

  constructor() {
    super(...arguments);

    this.fetchReportingData.perform();
  }

  @task({ restartable: true })
  *fetchReportingData() {
    this.reportingData = yield this.store.queryRecord(
      'appstore/app-package/salesforce/reporting-data',
      { section_id: 'reporting' },
    );
  }
  get currencyOpportunityField() {
    return this.reportingData.opportunityFields.findBy(
      'name',
      this.reportingData.settings.opportunityValueField,
    );
  }

  get currencyOpportunityFields() {
    return this.reportingData.opportunityFields.filter((item) => item.metadata.type === 'currency');
  }

  get opportunityValueItems() {
    return this.currencyOpportunityFields.map((item) => {
      return {
        text: item.metadata.label,
        value: item.name,
      };
    });
  }

  @task({ enqueue: true })
  *saveSettings(successMessage) {
    try {
      this.notificationsService.notifyLoading('Saving...', -1, this.customNotificationKey);
      yield this.reportingData.save();
      this.notificationsService.removeNotification(this.customNotificationKey);
      this.notificationsService.notifyConfirmation(successMessage);
    } catch (e) {
      this.reportingData.rollbackAttributes();
      this.notificationsService.removeNotification(this.customNotificationKey);
      this.notificationsService.notifyError(
        "Something went wrong and we couldn't update your Salesforce settings",
      );
    }
  }

  @action
  saveValueField(value) {
    this.reportingData.settings.opportunityValueField = value;
    return this.saveSettings.perform(
      `Successfully set "${this.currencyOpportunityField.metadata.label}" as the opportunity value.`,
    );
  }

  @action
  toggleOpportunitySync() {
    this.reportingData.settings.opportunitySync = !this.reportingData.settings.opportunitySync;
    let status = this.reportingData.settings.opportunitySync ? 'enabled' : 'disabled';
    return this.saveSettings.perform(`Successfully ${status} opportunity enrichment.`);
  }
}
