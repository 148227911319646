/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class Editor extends Component {
  @service intl;

  constructor(...args) {
    super(...args);
    this.args.profile.botOnlyMessageLocalizations.forEach((messages) => {
      if (messages.parts.length === 0) {
        messages.parts.createFragment();
      }
    });
  }

  get localeDropdownItems() {
    return this.args.profile.botOnlyMessageLocalizations.map((locale) => ({
      value: locale.locale,
      text:
        this.args.defaultLocale === locale.locale
          ? this.intl.t('ai-agent.profiles.bot-only.default-locale', {
              name: locale.name,
            })
          : locale.name,
    }));
  }

  get selectedBotMessageLocalization() {
    return this.args.profile.botOnlyMessageLocalizations.find(
      (msg) => msg.locale === this.args.selectedLocale,
    );
  }

  @action
  removePart(part) {
    this.selectedBotMessageLocalization.parts.removeObject(part);
    if (this.selectedBotMessageLocalization.parts.length === 0) {
      this.addPart();
    }
  }

  @action
  addPart() {
    this.selectedBotMessageLocalization.parts.createFragment();
  }
}
