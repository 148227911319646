/* import __COLOCATED_TEMPLATE__ from './collection-and-help-center-summary.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { type Collection } from '../site/collections/tree/types';
import { inject as service } from '@ember/service';

interface Args {
  article: any;
  showEditSettings: () => null;
}

export default class CollectionAndHelpCenterSummary extends Component<Args> {
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  get article() {
    return this.args.article;
  }

  get collections() {
    return this.article.inCollections;
  }

  get inNoHelpCenters() {
    return this.helpCenterIds.length === 0;
  }

  get helpCenterIds() {
    return [...new Set(this.collections.map((collection: Collection) => collection.helpCenterId))];
  }

  get appHasMultiHelpCenterFeature() {
    return this.app.canUseFeature('multi_help_center');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Shared::CollectionAndHelpCenterSummary': typeof CollectionAndHelpCenterSummary;
    'articles/shared/collection-and-help-center-summary': typeof CollectionAndHelpCenterSummary;
  }
}
