/* import __COLOCATED_TEMPLATE__ from './customize-reply-buttons.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { QUICK_REPLY_ID } from 'embercom/lib/operator/resolution-bot/constants';

export default class CustomizeReplyButtons extends Component {
  @service intercomEventService;
  @service('ai-agent-quick-replies-service') quickRepliesService;

  get customizableQuickReplies() {
    return [
      this._quickReplyButton(QUICK_REPLY_ID.ALL_DONE, 'allDoneLabel'),
      this._quickReplyButton(QUICK_REPLY_ID.WAIT_FOR_THE_TEAM, 'waitForTheTeamLabel'),
    ];
  }

  @action
  setQuickReplyLabel(labelProp, labelValue) {
    this.args.quickReplies[labelProp] = labelValue;
  }

  @action
  trackAiAgentQuickReplySettings() {
    let quickReplyChoices = this.args.quickReplies.getProperties(
      'allDoneLabel',
      'waitForTheTeamLabel',
    );

    this.intercomEventService.trackAnalyticsEvent({
      action: 'updated',
      section: 'operator',
      place: 'operator_settings',
      object: 'ai_agent_quick_reply_settings',
      ...quickReplyChoices,
    });
  }

  _quickReplyButton(buttonId, labelProp) {
    let { locale } = this.args.previewConfiguration;

    return {
      propName: labelProp,
      selectedItem: this.args.quickReplies[labelProp],
      items: this.quickRepliesService.getSelectItems(buttonId, locale),
      description: this.quickRepliesService.getById(buttonId).description,
      tooltipText: this.quickRepliesService.getById(buttonId).tooltipText,
    };
  }
}
