/* import __COLOCATED_TEMPLATE__ from './company-address-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class CompanyAddressComponent extends Component {
  @service customerService;
  @tracked companyAddressIsOpen = false;

  get customer() {
    return this.customerService.customer;
  }
}
