/* import __COLOCATED_TEMPLATE__ from './company-table-row-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class CompanyTableRow extends Component {
  @tracked isHovered = false;

  get shouldHaveVisibleCheckbox() {
    return this.isHovered || this.args.hasSelectedUsers;
  }
}
