/* import __COLOCATED_TEMPLATE__ from './content-state.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import { inject as service } from '@ember/service';

export default Component.extend(EventTracking, {
  helpCenterService: service(),

  onSelectItem: action(function (value) {
    this.content.set('state', value);
    this.trackEducateArticleEvent(this.article, {
      place: 'article',
      action: 'selected',
      context: 'state',
      value,
      content_locale: this.content.locale,
      is_default_locale: this.content.locale === this.helpCenterService.site.locale,
    });
  }),
});
