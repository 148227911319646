/* import __COLOCATED_TEMPLATE__ from './section.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface Args {
  id: string;
  accordionAPI: any;
  isDisabled: boolean;
  groupId: string;
  handleToggleAll?: boolean;
  onSectionReady?: (sectionId: string) => void;
  Panel: any;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: any;
}

export default class MultisectionAccordionSection extends Component<Signature> {
  @service panelActions: any;

  @tracked isHovered = false;
  @tracked animate = false;
  localId = this.args.id;
  globalId = `${this.args.groupId}-${this.args.id}`;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.setup();
  }

  @action
  setup() {
    this.args.onSectionReady?.(this.globalId);
    this.animate = true;
  }

  @action
  toggle(event: KeyboardEvent) {
    if (!this.args.isDisabled) {
      if (this.args.handleToggleAll && event.altKey) {
        return this.args.accordionAPI.toggleAll(this.globalId);
      }

      this.args.accordionAPI.toggleSection(this.globalId);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::MultisectionAccordion::Section': typeof MultisectionAccordionSection;
    'common/multisection-accordion/section': typeof MultisectionAccordionSection;
  }
}
