/* import __COLOCATED_TEMPLATE__ from './review-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { ANSWER_BOT_ESSENTIAL_ID } from 'embercom/lib/billing';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ReviewWrapper extends Component {
  @service intercomEventService;
  @service notificationsService;
  @service appService;

  answerBotPlanId = ANSWER_BOT_ESSENTIAL_ID;

  @tracked showTestModal = false;

  get app() {
    return this.appService.app;
  }

  @action
  setLiveAndSave() {
    let app = this.appService.app;
    if (!app.hasAnswerBot && app.answerBotIsNotActive) {
      return;
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'set_live',
      object: this.args.answer,
      context: 'review_step',
    });
    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved',
      object: this.args.answer,
      context: 'review_step',
    });

    if (this.args.answer.titleIsComplete) {
      this.args.answer.set('status', 'live');
      this.args.tasks.save.perform();
    } else {
      this.notificationsService.notifyError('Please enter a title');
    }
  }

  @action
  openTestAnswerModal() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'rb_preview_answer_modal',
      context: 'review_step',
    });
    this.args.openTestAnswerModal();
  }

  @action
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  @action
  saveAndClose() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved_and_closed',
      object: this.args.answer,
      context: 'review_step',
    });

    this.args.tasks.save.perform();
  }
}
