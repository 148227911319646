/* import __COLOCATED_TEMPLATE__ from './add-questions.hbs'; */
/* RESPONSIBLE TEAM: team-ml */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface AddQuestionsArgs {
  isOpen: boolean;
  onClose: () => void;
  onSave: (questions: string[]) => void;
  questionQuota: number;
}

export default class AddQuestions extends Component<AddQuestionsArgs> {
  @service declare intl: IntlService;

  @tracked questions: { text: string }[] = [{ text: '' }];

  @action
  addQuestion() {
    this.questions = [...this.questions, { text: '' }];
  }

  @action
  removeQuestion(index: number) {
    this.questions = this.questions.filter((_, i) => i !== index);
  }

  @action
  save() {
    this.args.onSave(this.questions.map((q) => q.text));
    this.onClose();
  }

  @action
  onClose() {
    this.questions = [{ text: '' }];
    this.args.onClose();
  }

  @action
  handleNewLine(index: number, event: KeyboardEvent) {
    event.preventDefault();
    this.questions = [
      ...this.questions.slice(0, index + 1),
      { text: '' },
      ...this.questions.slice(index + 1),
    ];
  }

  @action
  handlePaste(index: number, event: ClipboardEvent) {
    event.preventDefault();

    let pasteData = event.clipboardData?.getData('text');

    if (pasteData) {
      let newQuestions = pasteData
        .split(/\n/)
        .map((text) => text.trim())
        .filter((text) => text);

      if (newQuestions.length > 0) {
        this.questions = [
          ...this.questions.slice(0, index),
          ...newQuestions.map((text) => ({ text })),
          ...this.questions.slice(index + 1),
        ].slice(0, this.args.questionQuota);
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::AddQuestions': typeof AddQuestions;
  }
}
