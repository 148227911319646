/* import __COLOCATED_TEMPLATE__ from './phone-regulatory-bundles.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import Component from '@glimmer/component';
import { type RegulatoryBundleArgs } from './phone-regulatory-bundle/phone-number-type-section';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

export interface Args {
  regulatoryBundles: RegulatoryBundleArgs[];
  confirmationBannerText?: string;
}

export default class PhoneRegulatoryBundles extends Component<Args> {
  @service intl: any;
  @service appService: $TSFixMe;
  @service declare router: RouterService;

  get tableHeader() {
    return [
      {
        label: this.intl.t('calling.settings.phone-regulatory-bundle.friendly-name'),
        valuePath: 'friendlyName',
      },
      {
        label: this.intl.t('calling.settings.phone-regulatory-bundle.country'),
        valuePath: 'countryCode',
      },
      {
        label: this.intl.t('calling.settings.phone-regulatory-bundle.type'),
        valuePath: 'phoneNumberType',
      },
      {
        label: this.intl.t('calling.settings.phone-regulatory-bundle.status'),
        valuePath: 'status',
        width: '40px',
      },
      {
        label: '',
        valuePath: 'actions',
        width: '10px',
      },
    ];
  }

  get canUseNewIaSettings() {
    return this.appService.app.canUseFeature('team-activation-channels-new-ia-settings-hub');
  }

  get isSms() {
    return this.router.currentRouteName.includes('settings.channels.sms.phone-regulatory-bundles');
  }

  get createNewBundle() {
    if (this.isSms) {
      return 'apps.app.settings.channels.sms.phone-regulatory-bundles.new';
    }
    return this.canUseNewIaSettings
      ? 'apps.app.settings.channels.phone.phone-regulatory-bundles.new'
      : 'apps.app.settings.calling.phone-regulatory-bundles.new';
  }

  get showBundle() {
    if (this.isSms) {
      return 'apps.app.settings.channels.sms.phone-regulatory-bundles.show';
    }
    return this.canUseNewIaSettings
      ? 'apps.app.settings.channels.phone.phone-regulatory-bundles.show'
      : 'apps.app.settings.calling.phone-regulatory-bundles.show';
  }

  get editBundle() {
    if (this.isSms) {
      return 'apps.app.settings.channels.sms.phone-regulatory-bundles.edit';
    }
    return this.canUseNewIaSettings
      ? 'apps.app.settings.channels.phone.phone-regulatory-bundles.edit'
      : 'apps.app.settings.calling.phone-regulatory-bundles.edit';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhoneRegulatoryBundles': typeof PhoneRegulatoryBundles;
    'calling/settings/phone-regulatory-bundles': typeof PhoneRegulatoryBundles;
  }
}
