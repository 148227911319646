/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

export default class Main extends Component {
  @service notificationsService;
  @service intercomEventService;

  @task *toggleAutomaticContactCreation() {
    this.args.settings.toggleProperty('contactAutoCreationEnabled');
    try {
      yield this.updateSettings.perform();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'toggled',
        object: 'automatic-contact-creation',
        place: 'hubspot-settings ',
        current_state: this.args.settings.contactAutoCreationEnabled,
      });
    } catch (e) {
      this.args.settings.rollbackAttributes();
      this.notificationsService.notifyError(
        "Something went wrong and we couldn't update your HubSpot settings",
      );
    }
  }

  @task *toggleLeadAttributionEnabled() {
    this.args.settings.toggleProperty('leadAttributionEnabled');
    try {
      yield this.updateSettings.perform();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'toggled',
        object: 'lead-attribution',
        place: 'hubspot-settings ',
        current_state: this.args.settings.leadAttributionEnabled,
      });
    } catch (e) {
      this.args.settings.rollbackAttributes();
      this.notificationsService.notifyError(
        "Something went wrong and we couldn't update your HubSpot settings",
      );
    }
  }

  @task *updateSettings() {
    yield this.args.update.perform(this.args.settings);
  }
}
