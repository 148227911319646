/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  earlyStageGraduation: any;
  customer: any;
  app: any;
  redirectToInvoice?: any;
  esChoiceConfirmation?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

const Summary = templateOnlyComponent<Signature>();
export default Summary;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::EarlyStage::Summary': typeof Summary;
    'billing/summary/early-stage/summary': typeof Summary;
  }
}
