/* import __COLOCATED_TEMPLATE__ from './attribute-last-conversation-rating-given-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import AttributeComponent from 'embercom/components/attribute-component';
import { selectFromObject } from '@intercom/pulse/lib/computed-properties';

let ProfileLastConversationRatingGivenComponent = AttributeComponent.extend({
  parsedValue: selectFromObject('parsedValues', 'value'),
  parsedValues: {
    5: 'Amazing',
    4: 'Great',
    3: 'Ok',
    2: 'Bad',
    1: 'Terrible',
  },
});

export default ProfileLastConversationRatingGivenComponent;
