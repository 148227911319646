/* import __COLOCATED_TEMPLATE__ from './modify.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { inject as service } from '@ember/service';
import type HelpCenterRedirect from 'embercom/models/help-center-redirect';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import type MutableArray from '@ember/array/mutable';
import { A } from '@ember/array';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type ArticleMultilingual from 'embercom/models/article-multilingual';
import { EntityType } from 'embercom/models/data/entity-types';
import type ArticleGroup from 'embercom/models/articles/article-group';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import { get } from 'embercom/lib/ajax';

type Args = {
  site: HelpCenterSite;
  isOpen: boolean;
  onClose: () => void;
  redirect: HelpCenterRedirect | null;
  updateRedirect: TaskGenerator<void>;
};

export default class Modify extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare helpCenterService: any;
  @service declare appService: any;

  @tracked availableArticles: MutableArray<ArticleMultilingual>;
  @tracked availableCollections: MutableArray<ArticleGroup>;
  @tracked selectedArticle: ArticleMultilingual | null = null;
  @tracked articleLoading = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.availableArticles = A();
    this.availableCollections = A();

    taskFor(this.loadAvailableArticles).perform();
    taskFor(this.loadAvailableCollections).perform();
  }

  @task
  *loadAvailableArticles(): TaskGenerator<void> {
    let response = yield get('/ember/help_center_redirects/articles_for_help_center', {
      help_center_id: this.args.site.id,
      app_id: this.appService.app.id,
    });

    this.availableArticles = response.articles;
  }

  @task
  *loadAvailableCollections(): TaskGenerator<void> {
    let response = yield this.store.findAll('articles/article-group');

    this.availableCollections.pushObjects(
      response.toArray().filter((collection: any) => collection.helpCenterId === this.args.site.id),
    );
  }

  @action async onSelectArticle(articleId: number) {
    if (!this.redirect) {
      return;
    }
    this.articleLoading = true;
    this.selectedArticle = await this.store.findRecord('article-multilingual', articleId);
    this.articleLoading = false;
    this.redirect.locale = undefined;
    this.redirect.articleId = articleId;
  }

  @action onSelectCollection(collectionId: number) {
    if (!this.redirect) {
      return;
    }
    this.redirect.locale = undefined;
    this.redirect.collectionId = collectionId;
  }

  @action onSelectLocale(locale: string) {
    if (!this.redirect) {
      return;
    }

    this.redirect.locale = locale;
  }

  @action changeToArticleType() {
    if (!this.isArticleRedirect && this.redirect) {
      this.redirect.collectionId = undefined;
    }
  }

  @action changeToCollectionType() {
    if (!this.isCollectionRedirect && this.redirect) {
      this.redirect.articleId = undefined;
      this.selectedArticle = null;
    }
  }

  get isCreating() {
    return this.redirect?.isNew || false;
  }

  get articleOptions() {
    return this.availableArticles.map((article) => {
      return {
        text:
          // @ts-ignore
          article.title ||
          this.intl.t('articles.settings.redesign.general.domain.redirects.modify.drawer.untitled'),
        value: article.id.toString(),
      };
    });
  }

  get collectionOptions() {
    return this.availableCollections.map((collection) => ({
      text:
        collection.name ||
        // @ts-ignore
        collection.missingNamePlaceholder,
      value: collection.id,
    }));
  }

  get selectedCollection() {
    // @ts-ignore
    return this.availableCollections.findBy('id', this.selectedEntityId);
  }

  get selectedEntityId() {
    if (this.isArticleRedirect) {
      return this.redirect?.articleId?.toString();
    } else {
      return this.redirect?.collectionId?.toString();
    }
  }

  get showLocalePicker() {
    return this.redirect?.collectionId || this.selectedArticle || this.articleLoading;
  }

  get localeOptions() {
    let availableLocales = this.helpCenterService.allUniqueLocalesOrdered;

    let getLocaleState = (localeId: string) => {
      if (this.isArticleRedirect) {
        // @ts-ignore
        return this.selectedArticle?.contentByLocale(localeId)?.state || 'missing';
      }

      let collectionHasLocale =
        // @ts-ignore
        !!this.selectedCollection?.localizedContentByLocaleId(localeId)?.name;

      return collectionHasLocale ? 'published' : 'draft';
    };

    let allOptions = availableLocales.map((locale: any) => ({
      locale_name: locale.name,
      locale_id: `(${locale.localeId.toUpperCase()})`,
      value: locale.localeId,
      state: getLocaleState(locale.localeId),
      component: 'articles/show/article-locale-option',
    }));

    if (this.isArticleRedirect) {
      return allOptions.filter((option: any) => option.state !== 'missing');
    }

    return allOptions;
  }

  get shouldDisableSaving() {
    return !this.redirect?.isRedirectValid;
  }

  get validationTooltip() {
    return this.redirect?.validations.error?.message;
  }

  get redirect() {
    return this.args.redirect;
  }

  get isArticleRedirect() {
    return this.redirect?.entityType === EntityType.ArticleContent;
  }

  get isCollectionRedirect() {
    return this.redirect?.entityType === EntityType.ArticleCollection;
  }

  get articleRedirectType() {
    return EntityType.ArticleContent;
  }

  get collectionRedirectType() {
    return EntityType.ArticleCollection;
  }

  get helpCenterSiteUrlHint() {
    let siteUrl = this.helpCenterService?.site?.url;
    return `${this.intl.t(
      'articles.settings.redesign.general.domain.redirects.modify.drawer.hint-prefix',
    )} ${siteUrl}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    '::Articles::Site::Settings::General::Redirects::Modify': typeof Modify;
  }
}
