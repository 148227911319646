/* import __COLOCATED_TEMPLATE__ from './coupon-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import numericFormatter from 'embercom/lib/numeric-formatter';

export default Component.extend({
  purchaseAnalyticsService: service(),
  coupon: null,
  couponCode: null,
  isEnteringCoupon: false,
  couponErrors: null,
  totalAmount: null,
  onUpdateTotal: null,
  store: service(),
  tagName: '',

  init() {
    this._super();
    if (this.couponCode) {
      this.set('isEnteringCoupon', true);
      this.enterAndSubmitCoupon();
    }
  },

  submitButtonDisabled: computed('couponCode', 'validateCouponTask', function () {
    return !this.couponCode || this.get('validateCouponTask.isRunning');
  }),

  totalAmountPostCoupon: computed('totalAmount', 'coupon', function () {
    if (!this.coupon) {
      return this.totalAmount;
    }

    let totalAmountInCents = this.totalAmount;
    let couponDiscountInCents = this.couponDiscountInCents;
    if (this.get('coupon.amountOffInCents')) {
      return Math.max(totalAmountInCents - couponDiscountInCents, 0);
    }

    if (this.get('coupon.percentOff')) {
      return totalAmountInCents - couponDiscountInCents;
    }
    return this.totalAmount;
  }),

  couponDiscountInCents: computed('totalAmount', 'coupon', function () {
    if (this.get('coupon.amountOffInCents')) {
      return this.get('coupon.amountOffInCents');
    } else if (this.get('coupon.percentOff')) {
      return this.totalAmount * (this.get('coupon.percentOff') / 100.0);
    }
    return 0;
  }),

  couponDiscount: computed('coupon', function () {
    if (!this.coupon) {
      return 0;
    }

    if (this.get('coupon.amountOffInCents')) {
      let couponAmount = this.get('coupon.amountOffInCents') / 100.0;
      let formattedCouponAmount = numericFormatter(couponAmount, 2, true);
      return `$${formattedCouponAmount}`;
    }

    if (this.get('coupon.percentOff')) {
      return `${this.get('coupon.percentOff')}%`;
    }
  }),

  validateCouponTask: task(function* () {
    yield this.store.findRecord('coupon', this.couponCode).then(
      (coupon) => {
        this.set('coupon', coupon);
        this.set('isEnteringCoupon', false);
        this.set('couponErrors', null);
        this.onUpdateTotal(this.couponCode, this.totalAmountPostCoupon, this.couponDiscountInCents);
      },
      (err) => {
        this.set('couponErrors', err.jqXHR.responseJSON.errors.coupon);
      },
    );
  }).restartable(),

  enterAndSubmitCoupon: action(function () {
    this.purchaseAnalyticsService.trackEvent({
      action: 'entered',
      object: 'enter_coupon',
      context: this.context,
      place: this.place,
    });
    if (this.couponErrors || !this.couponCode) {
      return;
    }
    this.validateCouponTask.perform();
  }),

  applyAndSubmitCoupon: action(function () {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'apply_coupon',
      context: this.context,
      place: this.place,
      mobile: this.get('media.isMobile'),
    });
    if (this.couponErrors || !this.couponCode) {
      return;
    }
    this.validateCouponTask.perform();
  }),

  couponEdited: action(function () {
    // submit couponCode
    // it is wrong
    // display error
    // start typing again
    // remove error and allow apply
    if (this.couponErrors) {
      this.set('couponErrors', null);
    }
  }),

  actions: {
    beginEnteringCoupon() {
      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked',
        object: 'enter_coupon',
        context: this.context,
        place: this.place,
      });
      this.set('isEnteringCoupon', true);
    },
  },
});
