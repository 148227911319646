/* import __COLOCATED_TEMPLATE__ from './search-for-question.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-jquery */
import EmberObject, { get } from '@ember/object';
import Component from '@glimmer/component';
import { timeout, all } from 'ember-concurrency';
import { post } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { NEW_ANSWER_ANIMATION_LENGTH } from 'embercom/transition-maps/answers-transitions';
import { isBlank } from '@ember/utils';
import { debounce } from '@ember/runloop';
import { dropTask, restartableTask } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { ref } from 'ember-ref-bucket';

const EXAMPLES_TO_DISPLAY_COUNT = 3;
const MINUMUM_SEARCH_TERM_LENGTH = 3;

export default class SearchForQuestion extends Component {
  @service intercomEventService;
  @service notificationsService;
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  @service modalService;
  @service appService;

  @tracked searchTerm = '';
  @tracked hasSearched = false;

  @ref('inputElement') inputElement;

  get showSuggestionClusters() {
    let isMidSearch = this.hasSearched || this.searchTask.isRunning;
    return this.args.shouldShowSuggestionClusters && !isMidSearch;
  }

  constructor() {
    super(...arguments);

    if (this.args.suggestionId) {
      this.searchTerm = this.args.answer.exampleQuestions.firstObject?.text;
    }

    if (this.args.prepopulatedSearchTerm) {
      this.searchTerm = this.args.prepopulatedSearchTerm;
    }

    if (!isBlank(this.searchTerm)) {
      this.loadAndDisplaySuggestions.perform();
    }
  }

  runSearchTask(searchTerm) {
    this.searchTask.perform(searchTerm);
  }

  @dropTask
  *searchTask() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'searched',
      object: 'questions',
      keywords: this.searchTerm,
      models: [this.args.answer],
    });

    let currentExampleCount = this.args.questionTrainingState.currentlyPresentedExamples.length;
    this.args.questionTrainingState.newSearchPerformed();

    yield this.loadAndDisplaySuggestions.perform(currentExampleCount);
  }

  @restartableTask
  *fetchSuggestions() {
    try {
      let params = this._buildFetchParameters();
      let response = yield post('/ember/custom_answers/question_suggestions', params);

      return response.results.map((suggestion) =>
        EmberObject.create({
          identifier: suggestion.id,
          text: suggestion.text,
          source: suggestion.source,
          textWithHighlights: suggestion.highlighted_text,
        }),
      );
    } catch (e) {
      this.notificationsService.notifyError(
        "Something went wrong and we couldn't get a new example question.",
      );
    }
  }

  @restartableTask
  *loadAndDisplaySuggestions(exampleCount = EXAMPLES_TO_DISPLAY_COUNT) {
    if (
      this.args.questionTrainingState.currentlyPresentedExamples.length >= EXAMPLES_TO_DISPLAY_COUNT
    ) {
      return;
    }

    let blankResults = new Array(exampleCount).fill(undefined).map(() =>
      EmberObject.create({
        loading: true,
      }),
    );

    this.args.questionTrainingState.set('currentlyPresentedExamples', blankResults);

    let fetchAndTimeoutResults = yield all([
      this.fetchSuggestions.perform(),
      timeout(NEW_ANSWER_ANIMATION_LENGTH + 200),
    ]);

    let loadedExamples = fetchAndTimeoutResults[0];
    this.refreshCurrentlyPresentedExamples(loadedExamples);
  }

  refreshCurrentlyPresentedExamples(loadedExamples) {
    // Whenever an item is accepted or removed we remove it from the list immediately, and insert a
    // new blank item with loading: true. This is what triggers the main list update animation.
    // This is done in the accept and reject actions.
    // Once the new search has run we find any loading items and update their content in place,
    // including setting loading: false. This triggers the secondary animation inside the item
    // which changes the spinner to the text.

    if (!Array.isArray(loadedExamples)) {
      return;
    }

    let currentlyPresentedExamples = this.args.questionTrainingState.currentlyPresentedExamples;

    let slotsToAdd = EXAMPLES_TO_DISPLAY_COUNT - currentlyPresentedExamples.length;

    let resultsToPopulate = currentlyPresentedExamples.concat(
      new Array(slotsToAdd).fill(undefined).map(() =>
        EmberObject.create({
          loading: true,
        }),
      ),
    );

    let slotsToFill = resultsToPopulate.filter((slotToFill) => slotToFill.loading);

    slotsToFill.forEach((slotToFill, i) => {
      let example = loadedExamples[i];

      if (example) {
        slotToFill.setProperties(example);
        slotToFill.set('loading', false);
      }
    });

    let newPresentedExamples = resultsToPopulate.filter((x) => !x.loading);

    this.args.questionTrainingState.setCurrentlyPresentedExamples(newPresentedExamples);
    this.hasSearched = true;

    if (this.args.onNewSearchResults) {
      this.args.onNewSearchResults(newPresentedExamples);
    }
  }

  _buildFetchParameters() {
    return {
      app_id: this.appService.app.id,
      query: this.searchTerm,
      recently_selected_examples: this._serializeExamples(
        this.args.questionTrainingState.addedExamplesFromCurrentSearch,
      ),
      recently_rejected_examples: this._serializeExamples(
        this.args.questionTrainingState.recentlyRejectedExamples,
      ),
      all_selected_examples: this._serializeExamples(this.args.answer.exampleQuestions),
      rejected_examples: this._serializeExamples(
        this.args.questionTrainingState.removedAndRejectedExamples,
      ),
      currently_presented_examples: this._serializeExamples(
        this.args.questionTrainingState.currentlyPresentedExamples.filter(
          (example) => !example.loading,
        ),
      ),
      required_keywords: this.args.answer.controlCriteria.serialize(),
      language_code: this.args.answer.languageCode,
    };
  }

  _serializeExamples(examples) {
    return examples.map((example) => {
      return {
        id: get(example, 'identifier'),
        text: get(example, 'text'),
      };
    });
  }

  @action
  search(event) {
    let length = event.target.value;
    if (length < MINUMUM_SEARCH_TERM_LENGTH) {
      return;
    }

    debounce(this, this.runSearchTask, this.searchTerm, 500);
  }

  @action
  clearSearch() {
    this.searchTerm = '';
    this.inputElement.focus();
  }

  @action
  acceptSuggestedQuestion(searchResult) {
    if (searchResult.isSelecting || searchResult.loading) {
      return;
    }
    searchResult.set('isSelecting', true);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'added',
      object: 'question',
      models: [this.args.answer],
      search_source: searchResult.source,
      question_id: searchResult.identifier,
      question_text: searchResult.text,
    });

    this.args.onSuggestedQuestionAccepted(searchResult, this.searchTerm);
    this.onSuggestedQuestionAcceptedOrRejected.perform(searchResult);
  }

  @action
  rejectSuggestedQuestion(searchResult) {
    if (searchResult.isSelecting || searchResult.loading) {
      return;
    }
    searchResult.set('isSelecting', true);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'rejected',
      object: 'question',
      models: [this.args.answer],
      search_source: searchResult.source,
      question_id: searchResult.identifier,
      question_text: searchResult.text,
    });

    this.args.onSuggestedQuestionRejected(searchResult);
    this.onSuggestedQuestionAcceptedOrRejected.perform(searchResult);
  }

  @action
  applySuggestedCluster(cluster) {
    if (this.args.onSuggestedClusterSelected) {
      this.args.onSuggestedClusterSelected(cluster);
    }
    this.searchTerm = cluster.archetype.text;
    this.loadAndDisplaySuggestions.perform();
  }

  @restartableTask
  *onSuggestedQuestionAcceptedOrRejected(searchResult) {
    let examplesToKeep = this.args.questionTrainingState.currentlyPresentedExamples.filter(
      (example) => example !== searchResult,
    );

    let newPresentedExamples = examplesToKeep.concat(EmberObject.create({ loading: true }));

    this.args.questionTrainingState.set('currentlyPresentedExamples', newPresentedExamples);

    let fetchAndTimeoutResults = yield all([
      this.fetchSuggestions.perform(),
      timeout(NEW_ANSWER_ANIMATION_LENGTH + 200),
    ]);

    this.refreshCurrentlyPresentedExamples(fetchAndTimeoutResults[0]);
  }
}
