/* import __COLOCATED_TEMPLATE__ from './sub-migration-choice-addons.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { PLAN_DATA, PROACTIVE_SUPPORT_PLUS_BASE_ID } from 'embercom/lib/billing';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import {
  getCopilotBreakdown,
  getCopilotUsage,
  getHasPSPUsage,
  getPspPlanBreakdown,
  helpLinkUrlsForStripeMigrations,
} from 'embercom/helpers/billing/migrations-helper';
import type StripeMigration from 'embercom/models/billing/stripe-migration';
import type Price from 'embercom/models/price';
import { isPresent } from '@ember/utils';

interface Args {
  translationKey: string;
  migration: StripeMigration;
  postMigrationPlanEstimate: Price;
  shouldHideTooltip?: true;
  shouldHideCopilotTeammateCount?: true;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationChoiceAddons extends Component<Signature> {
  @service declare intl: IntlService;

  get pspTranslation() {
    return this.intl.t(PLAN_DATA[PROACTIVE_SUPPORT_PLUS_BASE_ID].nameTranslationKey);
  }

  get pspHelpLinkUrl() {
    return helpLinkUrlsForStripeMigrations['psp'];
  }

  get pspBasePrice() {
    return getPspPlanBreakdown(this.args.postMigrationPlanEstimate).plan_starting_price;
  }

  get hasIncludedPsp() {
    return isPresent(getPspPlanBreakdown(this.args.postMigrationPlanEstimate));
  }

  get hasPSPUsage() {
    return getHasPSPUsage(this.args.postMigrationPlanEstimate);
  }

  get hasCopilotUsage() {
    return this.copilotUsage > 0;
  }

  get copilotUsage() {
    return getCopilotUsage(this.args.postMigrationPlanEstimate);
  }

  get copilotPrice() {
    return getCopilotBreakdown(this.args.postMigrationPlanEstimate).amount;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationSubcontent::SubMigrationChoiceAddons': typeof BillingMigrationChoiceAddons;
  }
}
