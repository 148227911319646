/* import __COLOCATED_TEMPLATE__ from './frame.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import $ from 'jquery';
import Component from '@ember/component';
import { debounceTask } from 'ember-lifeline';
import EmberObject, { computed } from '@ember/object';
import { equal, readOnly, empty, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import DidChangeAttrs from 'ember-did-change-attrs';
import { task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import { Promise as EmberPromise } from 'rsvp';
import ENV from 'embercom/config/environment';
import safeWindowOpen from '../../../lib/safe-window-open';

const DEBOUNCE_DURATION = 1000;
const FADE_DURATION = ENV.environment !== 'test' ? 250 : 0;

const MAX_PARTICIPANT_EAGER_INITIALIZE = 5;

const CANVAS_STATE_INITIALIZING = 'initializing';
const CANVAS_STATE_LOADING = 'loading';
const CANVAS_STATE_SUCCESSFUL = 'successful';
const CANVAS_STATE_ERROR = 'error';

const CanvasState = EmberObject.extend({
  state: CANVAS_STATE_INITIALIZING,
  canvas: null,

  isInitializing: equal('state', CANVAS_STATE_INITIALIZING),
  isLoading: equal('state', CANVAS_STATE_LOADING),
  isError: equal('state', CANVAS_STATE_ERROR),
});

export default Component.extend(DidChangeAttrs, {
  store: service(),
  appService: service(),
  intercomEventService: service(),
  app: readOnly('appService.app'),
  messengerAppInsertionService: service(),

  canvasesByUser: null,
  supportUrl: null,

  currentCanvasState: computed('user.id', 'canvasesByUser', function () {
    return this.canvasesByUser[this.user.id];
  }),

  currentCanvasStateEmpty: empty('currentCanvasState'),
  showLoadingIndicator: or('currentCanvasState.isInitializing', 'currentCanvasStateEmpty'),

  init() {
    this._super(...arguments);
    this.set('canvasesByUser', {});

    let participants = this.participantsToEagerLoad();
    this.markParticipantsAsInitializing(participants);
    this.initializeAppForParticipants(participants);
  },

  willDestroy() {
    this._super(...arguments);
    this.initializeApp.cancelAll();
  },

  didChangeAttrsConfig: {
    attrs: ['conversation', 'user'],
  },

  didChangeAttrs(changes) {
    this._super(...arguments);

    if (changes.conversation) {
      this.handleConversationChanged();
    }

    if (changes.user) {
      this.handleUserChanged();
    }
  },

  participantsToEagerLoad() {
    let participantsExcludingCurrentUser = this.conversation.participants.filter(
      (p) => p.id !== this.conversation.main_participant.id,
    );
    return [
      this.conversation.main_participant,
      ...participantsExcludingCurrentUser.slice(0, MAX_PARTICIPANT_EAGER_INITIALIZE - 1),
    ];
  },

  markParticipantsAsInitializing(participants) {
    participants.forEach((user) => {
      this.createInitialCanvasStateForUser(user);
    });
  },

  initializeAppForParticipants(participants) {
    participants.forEach((user) => {
      this.initializeApp.perform(user);
    });
  },

  handleConversationChanged() {
    this.initializeApp.cancelAll();
    this.clearAllCanvases();

    let participants = this.participantsToEagerLoad();
    this.markParticipantsAsInitializing(participants);
    debounceTask(this, 'initializeAppForParticipants', participants, DEBOUNCE_DURATION);
  },

  handleUserChanged() {
    if (!this.hasCanvasForUser(this.user)) {
      this.initializeApp.perform(this.user);
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    this.clearAllCanvases();
  },

  clearAllCanvases() {
    Object.keys(this.canvasesByUser).forEach((userId) => this.clearUserCanvas(userId));
    this.set('canvasesByUser', {});
  },

  clearUserCanvas(userId) {
    let canvasState = this.canvasesByUser[userId];
    if (canvasState && canvasState.canvas) {
      this.canvasesByUser[userId].set('canvas', null);
    }
  },

  initializeMessengerApp(cardCreationOptions) {
    let promise = new EmberPromise((resolve, reject) => {
      this.messengerAppInsertionService.on(
        'updateInboxAppCanvas',
        this,
        '_resolveMessengerAppInitialization',
      );
      this.messengerAppInsertionService.initializeMessengerApp(
        this.inboxAppId,
        cardCreationOptions,
        resolve,
        reject,
      );
    });

    let timeout = new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error('Inserting messenger app timed out')), ENV.APP._5000MS);
    });

    return EmberPromise.race([promise, timeout]).finally(() => {
      this.messengerAppInsertionService.off(
        'updateInboxAppCanvas',
        this,
        '_resolveMessengerAppInitialization',
      );
    });
  },

  _resolveMessengerAppInitialization(inboxAppId, error, resolve, reject) {
    if (this.inboxAppId === inboxAppId) {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    }
  },

  setSupportUrl(err) {
    if (err && err.jqXHR && err.jqXHR.responseJSON) {
      this.set('supportUrl', err.jqXHR.responseJSON.support_url);
    }
  },

  initializeApp: task(function* (user) {
    try {
      let response = yield ajax({
        url: '/ember/inbox_apps/initialize',
        type: 'POST',
        data: JSON.stringify({ ...this.commonRequestParams(), user_id: user.id }),
      });

      yield this.fadeAndSwapCanvasForUser(response, user);
      this.analyticsEventForFrame('initialized');
    } catch (err) {
      this.setSupportUrl(err);
      this.analyticsEventForFrame('failed');
      this.setCanvasErrorStateForUser(user);
    }
  })
    .enqueue()
    .maxConcurrency(3),

  submitApp: task(function* (actionComponentId, canvasInputValues) {
    try {
      let user = this.user;

      this.updateCurrentCanvasLoadingStateForUser(user);

      let response = yield ajax({
        url: '/ember/inbox_apps/submit',
        type: 'POST',
        data: JSON.stringify({
          ...this.commonRequestParams(),
          component_id: actionComponentId,
          input_values: canvasInputValues,
          current_canvas: this.currentCanvasState.canvas,
          user_id: this.user.id,
        }),
      });

      if (response.card_creation_options) {
        yield this.initializeMessengerApp(response.card_creation_options);
        if (response.canvas) {
          yield this.fadeAndSwapCanvasForUser(response, user);
        } else {
          this.updateCurrentCanvasSuccessfulStateForUser(user);
        }
      } else if (response.canvas) {
        yield this.fadeAndSwapCanvasForUser(response, user);
      }
      this.analyticsEventForFrame('submit');
    } catch (err) {
      this.setSupportUrl(err);
      this.analyticsEventForFrame('failed');
      this.setCanvasErrorStateForUser(this.user);
    }
  }),

  urlAction: task(function* (url) {
    yield this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'inbox_app',
      place: 'inbox',
      inbox_app_id: this.inboxAppId,
      url,
    });
    safeWindowOpen(url);
  }),

  commonRequestParams() {
    return {
      conversation_id: this.conversation.id,
      inbox_app_id: this.inboxAppId,
      admin_id: this.currentAdminId || this.app.currentAdmin.id,
      app_id: this.appId || this.app.id,
    };
  },

  async fadeAndSwapCanvasForUser(response, user) {
    // When initializing an app in a group conversation we will be calling this method for every participant in
    // the conversation. We only want to animate when swapping in the canvas for the currently visible user.
    let shouldAnimate = this.user.id === user.id;

    if (shouldAnimate) {
      await $(this.element).animate({ opacity: 0 }, FADE_DURATION).promise();
    }

    this.parseCanvasResponseForUser(response, user);

    if (shouldAnimate) {
      $(this.element).animate({ opacity: 1 }, FADE_DURATION);
    }
  },

  parseCanvasResponseForUser(response, user) {
    if (!('canvas' in response)) {
      throw Error('No canvas or results provided');
    }

    this.setCanvasForUser(user, response.canvas);
  },

  hasCanvasForUser(user) {
    return this.canvasesByUser[user.id] !== undefined;
  },

  setCanvasStateForUser(user, canvasState) {
    this.set('canvasesByUser', { ...this.canvasesByUser, ...{ [user.id]: canvasState } });
  },

  createInitialCanvasStateForUser(user) {
    let canvasState = CanvasState.create({ state: CANVAS_STATE_INITIALIZING });
    this.setCanvasStateForUser(user, canvasState);
  },

  updateCurrentCanvasLoadingStateForUser(user) {
    let canvasState = CanvasState.create({
      state: CANVAS_STATE_LOADING,
      canvas: this.canvasesByUser[user.id].canvas,
    });
    this.setCanvasStateForUser(user, canvasState);
  },

  updateCurrentCanvasSuccessfulStateForUser(user) {
    let canvasCopy = this.canvasesByUser[user.id].canvas;
    this.clearUserCanvas(user.id);

    let canvasState = CanvasState.create({
      state: CANVAS_STATE_SUCCESSFUL,
      canvas: canvasCopy,
    });
    this.setCanvasStateForUser(user, canvasState);
  },

  setCanvasErrorStateForUser(user) {
    let canvasState = CanvasState.create({ state: CANVAS_STATE_ERROR });
    this.setCanvasStateForUser(user, canvasState);
  },

  setCanvasForUser(user, canvas) {
    this.clearUserCanvas(user.id);

    let canvasState = CanvasState.create({ state: CANVAS_STATE_SUCCESSFUL, canvas });
    this.setCanvasStateForUser(user, canvasState);
  },

  analyticsEventForFrame(action, state) {
    let userState;
    if (this.currentCanvasState) {
      userState = this.currentCanvasState.state;
      if (userState === 'initializing') {
        userState = 'loading';
      }
    }
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'inbox_app',
      place: 'inbox',
      inbox_app_id: this.inboxAppId,
      current_state: userState,
      participants: this.conversation.participants.map((p) => p.toJSON({ includeId: true })),
      participants_count: this.conversation.participants.length,
    });
  },
});
