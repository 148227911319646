/* import __COLOCATED_TEMPLATE__ from './billing-comparison-estimated-plan-card.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Price from 'embercom/models/price';
import {
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_EXPERT_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
} from 'embercom/lib/billing';
import type IntlService from 'ember-intl/services/intl';
import {
  getCopilotUsage,
  getHasPSPUsage,
  getPspPlanBreakdown,
} from 'embercom/helpers/billing/migrations-helper';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';
import type StripeMigration from 'embercom/models/billing/stripe-migration';

interface Args {
  migration: StripeMigration;
  planId:
    | typeof PRICING_5_X_CORE_ESSENTIAL_ID
    | typeof PRICING_5_X_CORE_ADVANCED_ID
    | typeof PRICING_5_X_CORE_EXPERT_ID;
  pricing5Estimates: {
    essentialEstimate: Price;
    essentialWithPSPEstimate: Price;
    essentialWithCopilotEstimate: Price;
    essentialWithPSPCopilotEstimate: Price;
    advancedEstimate: Price;
    advancedWithPSPEstimate: Price;
    advancedWithCopilotEstimate: Price;
    advancedWithPSPCopilotEstimate: Price;
    expertEstimate: Price;
    expertWithPSPEstimate: Price;
    expertWithCopilotEstimate: Price;
    expertWithPSPCopilotEstimate: Price;
  };
  howWeAssignSeatsLink?: string;
  howWeAssignSeatsContent?: string;
  isRecommended: boolean;
  isConfirmedByCustomer: boolean;
  migrationSeatConfiguration?: MigrationSeatConfiguration;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationBillingComparisonEstimatedPlanCardComponent extends Component<Signature> {
  @service appService: any;
  @service intl!: IntlService;

  get currentPrice() {
    if (this.hasCopilotUsage && this.shouldShowPSPAddon) {
      return this.priceCombinations!.estimatedPriceWithPSPAndCopilot;
    } else if (this.shouldShowPSPAddon) {
      return this.priceCombinations!.estimatedPriceWithPsp;
    } else if (this.hasCopilotUsage) {
      return this.priceCombinations!.estimatedPriceWithCopilot;
    } else {
      return this.priceCombinations!.estimatedPriceWithoutAddons;
    }
  }

  get totalPrice() {
    return this.currentPrice.amount / 100;
  }

  get pspBasePrice() {
    return this.pspPlanBreakdown.plan_starting_price / 100;
  }

  get hasCopilotUsage() {
    return getCopilotUsage(this.priceCombinations!.estimatedPriceWithCopilot) > 0;
  }

  private get shouldShowPSPAddon() {
    return (
      this.hasPSPUsage ||
      this.args.migration.postMigrationPlanIds.includes(Number(PROACTIVE_SUPPORT_PLUS_BASE_ID))
    );
  }

  get hasPSPUsage() {
    return getHasPSPUsage(this.priceCombinations!.estimatedPriceWithPsp);
  }

  private get pspPlanBreakdown() {
    return getPspPlanBreakdown(this.priceCombinations!.estimatedPriceWithPsp);
  }

  private get priceCombinations() {
    switch (this.args.planId) {
      case PRICING_5_X_CORE_ESSENTIAL_ID:
        return {
          estimatedPriceWithoutAddons: this.args.pricing5Estimates.essentialEstimate,
          estimatedPriceWithPsp: this.args.pricing5Estimates.essentialWithPSPEstimate,
          estimatedPriceWithCopilot: this.args.pricing5Estimates.essentialWithCopilotEstimate,
          estimatedPriceWithPSPAndCopilot:
            this.args.pricing5Estimates.essentialWithPSPCopilotEstimate,
        };
      case PRICING_5_X_CORE_ADVANCED_ID:
        return {
          estimatedPriceWithoutAddons: this.args.pricing5Estimates.advancedEstimate,
          estimatedPriceWithPsp: this.args.pricing5Estimates.advancedWithPSPEstimate,
          estimatedPriceWithCopilot: this.args.pricing5Estimates.advancedWithCopilotEstimate,
          estimatedPriceWithPSPAndCopilot:
            this.args.pricing5Estimates.advancedWithPSPCopilotEstimate,
        };
      case PRICING_5_X_CORE_EXPERT_ID:
        return {
          estimatedPriceWithoutAddons: this.args.pricing5Estimates.expertEstimate,
          estimatedPriceWithPsp: this.args.pricing5Estimates.expertWithPSPEstimate,
          estimatedPriceWithCopilot: this.args.pricing5Estimates.expertWithCopilotEstimate,
          estimatedPriceWithPSPAndCopilot: this.args.pricing5Estimates.expertWithPSPCopilotEstimate,
        };
      default:
        return;
    }
  }

  get heading() {
    switch (this.args.planId) {
      case PRICING_5_X_CORE_ESSENTIAL_ID:
        return this.intl.t('billing.migrations.billing_comparison.essential_plan_card_title');
      case PRICING_5_X_CORE_ADVANCED_ID:
        return this.intl.t('billing.migrations.billing_comparison.advanced_plan_card_title');
      case PRICING_5_X_CORE_EXPERT_ID:
        return this.intl.t('billing.migrations.billing_comparison.expert_plan_card_title');
      default:
        return;
    }
  }

  get body() {
    switch (this.args.planId) {
      case PRICING_5_X_CORE_ESSENTIAL_ID:
        return this.intl.t('billing.migrations.billing_comparison.essential_plan_card_body');
      case PRICING_5_X_CORE_ADVANCED_ID:
        return this.intl.t('billing.migrations.billing_comparison.advanced_plan_card_body');
      case PRICING_5_X_CORE_EXPERT_ID:
        return this.intl.t('billing.migrations.billing_comparison.expert_plan_card_body');
      default:
        return;
    }
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::BillingComparisonEstimatedPlanCard': typeof BillingMigrationBillingComparisonEstimatedPlanCardComponent;
  }
}
