/* import __COLOCATED_TEMPLATE__ from './content-summary.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';

export default Component.extend({
  appService: service(),
  app: reads('appService.app'),
  selectedLocalizedContent: computed('localeId', 'article.articleContents.[]', function () {
    if (!this.localeId) {
      return this.article.defaultLocalizedContent;
    }
    return this.article.contentByLocale(this.localeId);
  }),
  showReviewStamp: computed(
    'selectedLocalizedContent',
    'article.needsReview',
    'localeId',
    function () {
      if (this.localeId) {
        return this.selectedLocalizedContent?.needsReview;
      } else {
        return this.article.needsReview;
      }
    },
  ),
});
