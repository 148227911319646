/* import __COLOCATED_TEMPLATE__ from './connect-whatsapp.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import ENV from 'embercom/config/environment';
import whatsappEmbeddedSignup from 'embercom/lib/channels/whatsapp/helpers';

export default class ConnectWhatsapp extends Component {
  @service store;
  @service appService;
  @service notificationsService;
  @service intercomEventService;
  @service fb;
  @service intl;
  @tracked showMultiplePhoneNumbers = false;
  @tracked accountPhoneNumbers;
  @tracked selectedNumber;
  @tracked showMigrationFlowInfoSelected = false;
  @tracked showMigrationFlow = false;
  @tracked migrationFlowWhatsappBusinessAccountId;
  @tracked showForm = true;

  constructor() {
    super(...arguments);
    let app = this.appService.app;
    this.initFacebook(this.fb, app);
  }

  get isTaskRunning() {
    return (
      this.setupWhatsapp.isRunning ||
      this.fetchAccountPhoneNumbers.isRunning ||
      this.submitNumber.isRunning
    );
  }

  @task({ drop: true })
  *setupWhatsapp(accessToken) {
    let data = {
      app_id: this.appService.app.id,
      access_token: accessToken,
    };
    let response = yield post('/ember/whatsapp/integrations/initialize', data);
    if ('multiple_phone_numbers' in response) {
      return false;
    }

    this.store.pushPayload({ 'whatsapp/integration': response });
    return true;
  }

  @task({ drop: true })
  *startEmbeddedSignup() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'get_started',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });
    try {
      let response = yield whatsappEmbeddedSignup(this.appService.app.id, this.fb);
      let success = yield this.setupWhatsapp.perform(response.authResponse.accessToken);
      if (success) {
        this.successNotification();
        this.closeConnectWhatsappFlow();
      } else {
        this.accountPhoneNumbers = yield this.fetchAccountPhoneNumbers.perform({
          accessToken: response.authResponse.accessToken,
          accountsOnly: false,
        });
        this.selectedNumber = '0,0';
        this.showMultiplePhoneNumbers = true;
      }
    } catch (e) {
      this.errorNotification(e);
      this.closeConnectWhatsappFlow();
    } finally {
      this.setGetStartedCDA.perform();
    }
  }

  @task({ drop: true })
  *startMigratingFlowSignup() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'get_started_migration_flow',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });
    try {
      let response = yield whatsappEmbeddedSignup(this.appService.app.id, this.fb);
      let waba_ids = yield this.fetchAccountPhoneNumbers.perform({
        accessToken: response.authResponse.accessToken,
        accountsOnly: true,
      });
      // we only will have 1 whatsapp account shared with us or have error thrown in backend
      this.migrationFlowWhatsappBusinessAccountId = waba_ids[0];
      this.showMigrationFlow = true;
    } catch (e) {
      this.migrationFlowWhatsappBusinessAccountId = '';
      this.showMigrationFlow = true;
    } finally {
      this.setGetStartedCDA.perform();
    }
  }

  @task({ drop: true })
  *fetchAccountPhoneNumbers({ accessToken, accountsOnly }) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'fetch_phone_numbers',
      object: 'get_started',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });
    let data = {
      app_id: this.appService.app.id,
      access_token: accessToken,
      accounts_only: accountsOnly,
    };
    let response = yield post('/ember/whatsapp/integrations/get_shared_whatsapp_accounts', data);
    return response;
  }

  @task({ drop: true })
  *submitNumber() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'submit_phone_number',
      object: 'get_started',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });
    let [accountIndex, numberIndex] = this.selectedNumber
      .split(',')
      .map((index) => parseInt(index, 10));

    let accountId = this.accountPhoneNumbers[accountIndex].id;
    let phoneNumber =
      this.accountPhoneNumbers[accountIndex].phone_numbers[numberIndex].display_phone_number;

    let data = {
      app_id: this.appService.app.id,
      whatsapp_account_id: accountId,
      phone_number: phoneNumber,
    };
    try {
      this.showMultiplePhoneNumbers = false;
      this.selectedNumber = '0,0';
      let response = yield post('/ember/whatsapp/integrations/submit_number', data);
      this.store.pushPayload({ 'whatsapp/integration': response });
      this.successNotification();
    } catch (e) {
      this.errorNotification(e);
    } finally {
      this.closeConnectWhatsappFlow();
    }
  }

  @task({ drop: true })
  *setGetStartedCDA() {
    let data = { app_id: this.appService.app.id };
    return yield post('/ember/whatsapp/integrations/set_get_started_cda', data);
  }

  @action
  usePhoneNumber() {
    this.submitNumber.perform();
  }

  @action
  openEmbeddedSignup() {
    this.startEmbeddedSignup.perform();
  }

  @action
  openMigratingFlowSignup() {
    this.startMigratingFlowSignup.perform();
  }

  @action
  closeMigratingFlowSignup() {
    this.showMigrationFlow = false;
    this.closeConnectWhatsappFlow();
  }

  closeConnectWhatsappFlow() {
    this.args.onExit();
  }

  successNotification() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('appstore.settings.whatsapp.connect-whatsapp.notification.success'),
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'completed',
      object: 'get_started',
      current_tab: 'settings',
      app_package_code: 'whatsapp-fqam',
    });
  }

  errorNotification(e) {
    console.error(e);
    this.notificationsService.notifyResponseError(
      e,
      {
        default: this.intl.t('appstore.settings.whatsapp.connect-whatsapp.notification.error'),
      },
      {
        responseProvidedErrors: [403, 422, 500],
        duration: 10000,
      },
    );
  }

  async initFacebook(fb, app) {
    let initSettings = {
      appId: ENV.APP.whatsapp.appId,
      version: app.koala_api_version,
      xfbml: true,
    };
    await fb.FBInit(initSettings);
    window.FB.init(initSettings);
  }
}
