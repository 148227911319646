/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  status?: string;
}

export default class Status extends Component<Args> {
  @service appService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  get tooltipText() {
    switch (this.args.status) {
      case 'missing-article':
        return this.intl.t('articles.list.content_status.badge.missing_article_tooltip');
      case 'draft':
        return this.intl.t('articles.list.content_status.badge.draft_tooltip');
      default:
        return 'articles.list.content_status.badge.missing_label';
    }
  }

  get isTooltipDisabled() {
    return this.args.status !== 'missing-article' && this.args.status !== 'draft';
  }

  get stateType() {
    return this.args.status || 'missing';
  }

  get stateCssClass() {
    return `o__${this.stateType.replace('_', '-')}`;
  }

  get stateTranslationKey() {
    switch (this.stateType) {
      case 'missing-article':
        return `articles.list.content_status.badge.missing_article`;
      case 'missing-title':
        return `articles.list.content_status.badge.missing_title`;
      case 'published':
        return `articles.list.content_status.badge.published_label`;
      case 'draft':
        return `articles.list.content_status.badge.draft_label`;
      case 'missing':
      default:
        return `articles.list.content_status.badge.missing_label`;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Collections::Tree::Cell::Status': typeof Status;
    'articles/site/collections/tree/cell/status': typeof Status;
  }
}
