/* import __COLOCATED_TEMPLATE__ from './install-button.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed, action } from '@ember/object';
import Component from '@ember/component';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';
import { not } from '@ember/object/computed';
import { readOnly } from '@ember/object/computed';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  adminNotVerified: not('app.currentAdmin.email_verified'),
  adminNotPermitted: not('adminPermitted'),
  mailToAdmin: null,
  showModal: false,
  intl: service(),
  installButtonText: ternary(
    'appPackage.isLegacyVersionInstalled',
    'appstore.app-package.install.upgrade-now',
    'appstore.app-package.install.install-now',
  ),
  customFeatureText: computed('intl.locale', 'appPackage.paywallFlag', function () {
    return this.get('appPackage.paywallFlag') === 'whatsapp_integration'
      ? this.intl.t('appstore.app-package.install.get-whatsapp')
      : null;
  }),

  requestInstallDropdownGroupList: computed('app.adminsWhoCanInstallApps', function () {
    return [
      {
        isFilterable: true,
        items: this.get('app.adminsWhoCanInstallApps').map((admin) => ({
          text: admin.get('name'),
          value: admin,
          component: 'common/avatar-and-name-dropdown-item',
          componentAttrs: {
            model: admin,
            modelDisplayName: admin.get('name'),
            avatarSize: 'xs',
          },
        })),
      },
    ];
  }),

  requestInstallTask: task(function* () {
    try {
      yield ajax({
        url: '/ember/appstore/app_packages/request_install',
        type: 'POST',
        dataType: 'text',
        data: JSON.stringify({
          admin_id: this.get('mailToAdmin.id'),
          app_package_code: this.get('appPackage.id'),
          app_id: this.get('app.id'),
        }),
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('appstore.app-package.install.request-sent'),
      );
      this.set('showModal', false);
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('appstore.app-package.install.request-failed'),
      );
    }
  }).drop(),

  trackAndOpenModal: action(function () {
    this.set('showModal', true);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened_request_install_modal',
      object: this.appPackage,
    });
  }),

  setMailToAdmin: action(function (admin) {
    this.set('mailToAdmin', admin);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'mail_to_admin_selected',
      object: this.appPackage,
    });
  }),

  requestAppInstall: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'requested_install',
      object: this.appPackage,
    });
    this.requestInstallTask.perform();
  }),

  actions: {
    trackLearnMoreClick() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked_learn_more',
        object: this.appPackage,
      });
    },
  },
});
