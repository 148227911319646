/* import __COLOCATED_TEMPLATE__ from './timeline.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import moment from 'moment-timezone';
import type StripeMigration from 'embercom/models/billing/stripe-migration';

interface Args {
  migration: StripeMigration;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationTimelineComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;

  translationKey = 'billing.migrations.migration_overview.timeline';

  get timelineContent() {
    return [
      {
        testKey: 'explore',
        date: this.intl.t(`${this.translationKey}.timeline_date`, {
          date: this.timelineDates.explore,
        }),
        title: this.intl.t(`${this.translationKey}.explore_title`),
        content: [
          this.intl.t(`${this.translationKey}.explore_list_1`),
          this.intl.t(`${this.translationKey}.explore_list_2`, {
            migrationDate: this.timelineDates.migration,
          }),
        ],
      },
      {
        testKey: 'lockIn',
        date: this.intl.t(`${this.translationKey}.timeline_date`, {
          date: this.timelineDates.lockIn,
        }),
        title: this.intl.t(`${this.translationKey}.lock_in_title`),
        content: [
          this.intl.t(`${this.translationKey}.lock_in_list_1`),
          this.intl.t(`${this.translationKey}.lock_in_list_2`),
        ],
      },
      {
        testKey: 'migration',
        date: this.intl.t(`${this.translationKey}.timeline_date`, {
          date: this.timelineDates.migration,
        }),
        title: this.intl.t(`${this.translationKey}.migration_title`),
        content: [
          this.intl.t(`${this.translationKey}.migration_list_1`),
          this.intl.t(`${this.translationKey}.migration_list_2`),
        ],
      },
      {
        testKey: 'enjoy',
        date: this.intl.t(`${this.translationKey}.timeline_date`, {
          date: this.timelineDates.enjoy,
        }),
        title: this.intl.t(`${this.translationKey}.enjoy_title`),
        content: [
          this.intl.t(`${this.translationKey}.enjoy_list_1`),
          this.intl.t(`${this.translationKey}.enjoy_list_2`),
          this.intl.t(`${this.translationKey}.enjoy_list_3`),
        ],
      },
      {
        testKey: 'resume',
        date: this.intl.t(`${this.translationKey}.timeline_date`, {
          date: this.timelineDates.resume,
        }),
        title: this.intl.t(`${this.translationKey}.resume_title`),
        content: [this.intl.t(`${this.translationKey}.resume_list`)],
      },
    ];
  }

  generateEndOfNextCycle(nextInvoiceDate: moment.Moment) {
    let monthAfterNextInvoiceDate = nextInvoiceDate.clone().add(1, 'month');
    let lastDayOfCycleEndMonth = monthAfterNextInvoiceDate.endOf('month').date();
    let invoiceDayOfMonth = this.customerService.customer?.subscription?.invoiceDayOfMonth;

    let nextInvoiceDayOfMonth =
      invoiceDayOfMonth > lastDayOfCycleEndMonth ? lastDayOfCycleEndMonth : invoiceDayOfMonth;

    let effectiveEndOfNextCycleDate = monthAfterNextInvoiceDate.set('date', nextInvoiceDayOfMonth);

    return effectiveEndOfNextCycleDate;
  }

  get timelineDates() {
    let currentInvoiceDate = moment.tz(
      this.customerService.customer?.currentBillingCycleStartDate,
      'UTC',
    );
    let migrationDate = moment(this.args.migration.migrationDate);

    return {
      explore: this.formatMomentDate(currentInvoiceDate),
      lockIn: this.formatMomentDate(migrationDate.clone().subtract(1, 'day')),
      migration: this.formatMomentDate(migrationDate),
      enjoy: this.formatMomentDate(migrationDate.clone().add(1, 'day')),
      resume: this.formatMomentDate(this.generateEndOfNextCycle(migrationDate)),
    };
  }

  formatMomentDate(momentDate: moment.Moment) {
    return this.intl.formatDate(momentDate, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationSubcontent::Timeline': typeof BillingMigrationTimelineComponent;
  }
}
