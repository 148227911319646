/* import __COLOCATED_TEMPLATE__ from './review-features-popover.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Signature {
  Args: {
    title: string;
    content: string;
    trackEventName?: string;
    showOn: ('click' | 'mouseenter')[];
    hideOn: ('click' | 'clickout' | 'mouseleave')[];
  };
  Blocks: {
    default: [];
  };
}

export default class ReviewFeaturesPopoverComponent extends Component<Signature> {
  @service declare purchaseAnalyticsService: any;
  @action onShow() {
    this.trackEvent('show');
  }

  @action onHide() {
    this.trackEvent('hide');
  }

  trackEvent(action: string) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      context: 'review_features_popover',
      place: 'update_subscription',
      object: this.args.trackEventName,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::UpdateEarlyStageSubscription::ReviewFeaturesPopover': typeof ReviewFeaturesPopoverComponent;
    'billing/update-early-stage-subscription/review-features-popover': typeof ReviewFeaturesPopoverComponent;
  }
}
