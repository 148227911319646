/* import __COLOCATED_TEMPLATE__ from './question-box.hbs'; */
/* RESPONSIBLE TEAM: team-ml */

import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import type { PlaygroundTestQuestion } from 'embercom/lib/fin-playground';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface QuestionBoxArgs {
  question: PlaygroundTestQuestion;
  isRunning: boolean;
  isSelected: boolean;
  onSelect: (questionText: string) => void;
  onRemove: (question_id?: number) => void;
}

type QuestionBoxSignature = {
  Args: QuestionBoxArgs;
  Element: HTMLLIElement;
};

export default class AiAgentPlaygroundQuestionBox extends Component<QuestionBoxSignature> {
  @service declare intl: IntlService;

  @tracked isHovered = false;

  get status() {
    let question = this.args.question;

    if (question.status === 'completed' && question.isNotDirectAnswer) {
      return 'failed' as const;
    }

    return question.status ?? ('pending' as const);
  }

  get icon() {
    return {
      running: 'sync' as const,
      completed: 'check' as const,
      failed: 'close' as const,
      pending: 'tooltip' as const,
    }[this.status];
  }

  get questionOptionsList() {
    return [
      {
        text: this.intl.t('ai-agent.playground.delete-question'),
        icon: 'trash',
        onSelectItem: this.deleteQuestion,
        isDisabled: false,
      },
    ];
  }

  @action
  deleteQuestion() {
    this.args.onRemove(this.args.question.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::QuestionBox': typeof AiAgentPlaygroundQuestionBox;
  }
}
