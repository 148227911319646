/* import __COLOCATED_TEMPLATE__ from './selector-item.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';

export default class Selector extends Component {
  attributeBindings = ['assigneeDisplayName:data-assignee'];

  get assigneeDisplayName() {
    let textOverride = this.args.item.textOverride;
    let assignee = this.args.item.assignee;
    return textOverride || assignee.display_as_assignee;
  }
}
