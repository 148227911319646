/* import __COLOCATED_TEMPLATE__ from './billing-comparison.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import {
  PRICING_5_X_CORE_EXPERT_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
} from 'embercom/lib/billing';
import type StripeMigration from 'embercom/models/billing/stripe-migration';
import type Price from 'embercom/models/price';
import { inject as service } from '@ember/service';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';

interface Args {
  migration: StripeMigration;
  currentPrices: Price[];
  pricing5Estimates: {
    essentialEstimate: Price;
    essentialWithPSPEstimate: Price;
    essentialWithCopilotEstimate: Price;
    essentialWithPSPCopilotEstimate: Price;
    advancedEstimate: Price;
    advancedWithPSPEstimate: Price;
    advancedWithCopilotEstimate: Price;
    advancedWithPSPCopilotEstimate: Price;
    expertEstimate: Price;
    expertWithPSPEstimate: Price;
    expertWithCopilotEstimate: Price;
    expertWithPSPCopilotEstimate: Price;
  };
  howWeAssignSeatsLink?: string;
  howWeAssignSeatsContent?: string;
  migrationSeatConfiguration?: MigrationSeatConfiguration;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationBillingComparisonComponent extends Component<Signature> {
  @service declare appService: any;

  get pricing5EssentialPlanId(): typeof PRICING_5_X_CORE_ESSENTIAL_ID {
    return PRICING_5_X_CORE_ESSENTIAL_ID;
  }

  get pricing5AdvancedPlanId(): typeof PRICING_5_X_CORE_ADVANCED_ID {
    return PRICING_5_X_CORE_ADVANCED_ID;
  }

  get pricing5ExpertPlanId(): typeof PRICING_5_X_CORE_EXPERT_ID {
    return PRICING_5_X_CORE_EXPERT_ID;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::BillingComparison': typeof BillingMigrationBillingComparisonComponent;
  }
}
