/* import __COLOCATED_TEMPLATE__ from './remove-product-link.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    isAddon: boolean;
    openModal: () => void;
  };
}

const RemoveProductLink = templateOnlyComponent<Signature>();
export default RemoveProductLink;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::RemoveProductLink': typeof RemoveProductLink;
  }
}
