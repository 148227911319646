/* import __COLOCATED_TEMPLATE__ from './psp-why-it-is-included.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {}

interface Signature {
  Args: Args;
}

const BillingMigrationTooltipsPspWhyItIsIncluded = templateOnlyComponent<Signature>();
export default BillingMigrationTooltipsPspWhyItIsIncluded;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::Tooltips::PspWhyItIsIncluded': typeof BillingMigrationTooltipsPspWhyItIsIncluded;
  }
}
