/* import __COLOCATED_TEMPLATE__ from './help-center-option.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    item: {
      text: string;
      value: string;
      isDisabled?: boolean;
      websiteTurnedOn: boolean;
    };
  };
}

const HelpCenterOption = templateOnlyComponent<Signature>();
export default HelpCenterOption;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Editor::SettingsSideDrawer::HelpCenterOption': typeof HelpCenterOption;
    'articles/editor/settings-side-drawer/help-center-option': typeof HelpCenterOption;
  }
}
