/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import type HelpCenterNavigationService from 'embercom/services/help-center-navigation-service';
import { inject as service } from '@ember/service';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  site: HelpCenterSite;
  app: any;
  uploadHelper: any;
}

export default class Footer extends Component<Args> {
  @service declare helpCenterNavigationService: HelpCenterNavigationService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Content::Footer': typeof Footer;
    'articles/site/settings/content/footer': typeof Footer;
  }
}
