/* import __COLOCATED_TEMPLATE__ from './screen-controls.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type Checklist from 'embercom/models/checklists/checklist';
import type Task from 'embercom/models/checklists/task';
import { validateCompletionScreen } from 'embercom/validations/typescript/checklists/checklist';
import { taskValidationErrors } from 'embercom/validations/typescript/checklists/task';
import { ChecklistScreen } from '../editor';

interface Args {
  currentScreen: ChecklistScreen;
  checklist: Checklist;
  isEditing: boolean;
  shouldShowValidations: boolean;
  showMainScreen: () => void;
  showCompletionScreen: () => void;
}

export default class ScreenControlsComponent extends Component<Args> {
  get checklist() {
    return this.args.checklist;
  }

  get mainScreenSelected() {
    return this.args.currentScreen === ChecklistScreen.Main;
  }

  get completionScreenSelected() {
    return this.args.currentScreen === ChecklistScreen.Completion;
  }

  get showChecklistTabError() {
    if (!this.args.shouldShowValidations) {
      return false;
    }

    let taskErrors = this.checklist.tasks.flatMap((task: Task) => taskValidationErrors(task));
    return !this.checklist.hasTitle || this.checklist.tasks.length === 0 || taskErrors.length > 0;
  }

  get showCelebrationTabError() {
    if (!this.args.shouldShowValidations) {
      return false;
    }

    let completionErrors = validateCompletionScreen(this.checklist);
    return completionErrors.length > 0;
  }

  @action onCompletionScreenDelete() {
    this.args.checklist.resetCompletionScreen();
    this.args.checklist.showCompletionScreen = false;
    this.args.showMainScreen();
  }

  @action onCompletionScreenAdd() {
    this.args.checklist.initCompletionScreen();
    this.args.checklist.showCompletionScreen = true;
    this.args.showCompletionScreen();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Editor::ScreenControls': typeof ScreenControlsComponent;
    'checklist/editor/screen-controls': typeof ScreenControlsComponent;
  }
}
