/* import __COLOCATED_TEMPLATE__ from './change-plan-instructions-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import moment from 'moment-timezone';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';

export default class ChangePlanInstructionsComponent extends Component {
  @service purchaseAnalyticsService;
  @service customerService;
  @service appService;

  get billingDateOfMonth() {
    return moment
      .localeData()
      .ordinal(this.customerService.customer.subscription.invoiceDayOfMonth);
  }

  get customer() {
    return this.customerService.customer;
  }

  get subscriptionStartsOn() {
    let termStartDate = this.customer?.subscription?.termStartDate;
    if (!termStartDate) {
      return null;
    }
    return moment(termStartDate).format(dateAndTimeFormats.date);
  }

  get billingPeriodDurationInMonths() {
    return this.customer?.subscription?.billingPeriodDurationInMonths;
  }

  get app() {
    return this.appService.app;
  }

  get isFrozenForNonPayment() {
    return this.app.isFrozenForNonPayment;
  }

  @action openInAppMessenger(textForMessenger) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'talk_to_sales',
      place: 'billing_summary',
      context: 'banner',
    });
    showNewMessageInIntercomWidget(textForMessenger);
  }
}
