/* import __COLOCATED_TEMPLATE__ from './attribute-component.hbs'; */
/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { on } from '@ember/object/evented';
import { isBlank } from '@ember/utils';
import { not, reads } from '@ember/object/computed';
import { computed } from '@ember/object';
import Component from '@ember/component';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import rawValueToFilterValue from 'embercom/lib/users/user-company-list/raw-value-to-filter-value';

export default Component.extend({
  tagName: 'span',
  extraClass: '',
  filterValue: computed('attribute.identifier', 'value', function () {
    return rawValueToFilterValue(this.get('attribute.identifier'), this.value);
  }),
  filterUrl: computed('userOrCompany', 'attribute', 'value', function () {
    return B64FilterParamEncoder.filterUrl(this.userOrCompany, this.attribute, this.filterValue);
  }),
  tooltipText: reads('value'),
  value: computed(
    'userOrCompany',
    'isNotBlank',
    'userOrCompany.email',
    'userOrCompany.display_as',
    'attribute.identifier',
    'userOrCompany._lastEdit',
    function () {
      if (this.isNotBlank) {
        return this.userOrCompany.getValue(this.get('attribute.identifier'));
      }
      return 'Unknown';
    },
  ),
  identifierWithoutPrefix: computed('attribute.identifier', function () {
    let identifier = this.get('attribute.identifier');
    if (this.get('userOrCompany.isCompany')) {
      identifier = identifier.replace(/^company\./, '');
    }
    return identifier;
  }),
  isBlank: not('isNotBlank'),
  classNameBindings: ['isBlank:text-gray'],
  isNotBlank: computed(
    'userOrCompany',
    'attribute.identifier',
    'userOrCompany.email',
    'userOrCompany.display_as',
    'userOrCompany._lastEdit',
    function () {
      let userOrCompany = this.userOrCompany;
      if (this.attribute && userOrCompany) {
        return !isBlank(userOrCompany.getValue(this.get('attribute.identifier')));
      }
      return false;
    },
  ),
  setValueTooltipText: on('init', function () {
    this.set('valueTooltipText', this.tooltipText);
  }),
  fixClasses: on('init', function () {
    this.classNames = this.classNames.slice();
    this.classNames.push(this.extraClass);
  }),
});
