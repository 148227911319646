/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';

export default class ThumbnailControls extends Component {
  get currentScreenIndex() {
    if (this.args.currentScreen) {
      return this.args.currentScreen.order;
    }
  }
}
