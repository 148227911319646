/* import __COLOCATED_TEMPLATE__ from './body-button-action.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { or } from '@ember/object/computed';
import { action } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import defaultTo from '@intercom/pulse/lib/default-to';

export default Component.extend({
  notificationsService: service(),
  beforeSave: defaultTo(() => {}),
  afterSave: defaultTo(() => {}),
  classNames: ['flex-none'],
  classNameBindings: ['noMargin::mt-5'],
  attributeBindings: ['data-test-accordion-body-button'],
  'data-test-accordion-body-button': true,

  save: task(function* () {
    try {
      yield this.beforeSave();
      let data = yield this.model.save();
      let message = this.successNotification || `Your ${this.context} have been updated`;
      this.notificationsService.notifyConfirmation(message);
      this.afterSave(data);
    } catch (error) {
      if (error) {
        let message =
          this.errorNotification || `Your ${this.context} have not been updated. Please try later`;
        this.notificationsService.notifyResponseError(error, {
          default: message,
        });
      }
    }
  }).drop(),

  isCancelling: false,
  showSaveCancelButtons: or('allowSaving', 'isCancelling'),

  close() {
    let body = this.body;
    if (body) {
      body.accordionAPI.closeSection();
    }
  },

  closeSection: action(function () {
    this.close();
  }),

  actions: {
    discard() {
      try {
        this.set('isCancelling', true);
        this.model.refresh();
      } catch (e) {
        this.model.rollbackAttributes();
      } finally {
        this.set('isCancelling', false);
        this.close();
      }
    },
  },
});
