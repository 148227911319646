/* import __COLOCATED_TEMPLATE__ from './app-configurator.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { schedule } from '@ember/runloop';
import { action, computed } from '@ember/object';
import { and, not } from '@ember/object/computed';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { post } from 'embercom/lib/ajax';
import $ from 'jquery';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { underscore } from '@ember/string';

export default Component.extend({
  intercomEventService: service(),
  notificationsService: service(),
  classNames: ['app-framework__messenger-apps__inserter__app-configurator'],
  selectedApp: null,
  initializeType: null,
  contextualData: null,
  closePopover: null,
  store: service(),
  router: service(),
  appService: service(),
  appstoreService: service(),
  attributeBindings: ['data-test-app-inserter-popover'],
  'data-test-app-inserter-popover': true,
  initializationUrl: computed('initializeType', function () {
    return `/ember/messenger_apps/initialize_${this.initializeType}`;
  }),

  shouldShowInitializerComponent: and(
    'selectedApp.hasConfiguration',
    'isNotPrefetchingConfigurationOptions',
  ),

  isNotPrefetchingConfigurationOptions: not('prefetchCanvasOrCardCreationOptions.isRunning'),
  showAppStoreModal: false,
  selectedAppPackage: null,
  shouldInitializeCanvas: true,
  preventBackToAppsClick: false,

  didInsertElement() {
    this._super(...arguments);
    this._resetTemporaryMessengerAppProperties();
    this._initializeAppIfNoConfiguration();
  },

  _resetTemporaryMessengerAppProperties() {
    if (!this.get('messengerApps.length')) {
      return;
    }
    this.messengerApps.setEach('isPrefetchingConfiguration', undefined);
    this.messengerApps.setEach('prefetchedConfigurationOptions', undefined);
  },

  _initializeAppIfNoConfiguration() {
    let selectedApp = this.selectedApp;
    if (selectedApp && !selectedApp.get('hasConfiguration')) {
      selectedApp.set('isInitializing', true);
      this.initialize.perform();
    }
  },

  initialize: task(function* (canvas_creation_options) {
    canvas_creation_options = canvas_creation_options || {};
    let messenger_app_id = this.get('selectedApp.id');
    let context = this.contextualData || {};
    let card_creation_params = {
      messenger_app_id,
      canvas_creation_options,
    };

    try {
      let actionData;
      if (this.shouldInitializeCanvas) {
        let params = {
          app_id: this.get('appService.app.id'),
          card_creation_params,
          context,
        };
        actionData = yield post(this.initializationUrl, params);
        actionData.messenger_app_id = messenger_app_id;
      } else {
        actionData = card_creation_params.canvas_creation_options;
      }
      this.insert(actionData, card_creation_params);
      let appName = this.get('selectedApp.app_package_id');
      let analyticsEvent = {
        action: 'initialized',
        object: 'messenger_app_card',
        messenger_app_id,
        app_name: appName,
        conversation_id: context.conversation_id,
        message_id: context.message_id,
        message_variation_id: context.message_variation_id,
        messenger_card_id: actionData?.id,
      };
      if (context.object_type) {
        analyticsEvent['object_type'] = objectNames[context.object_type];
        analyticsEvent[`${underscore(objectNames[context.object_type])}_id`] = context.object_id;
      }
      this.intercomEventService.trackAnalyticsEvent(analyticsEvent);
    } catch (error) {
      this._resetTemporaryMessengerAppProperties();
      this.set('selectedApp.isInitializing', false);
      console.error(error);
      this.notificationsService.notifyResponseError(error, {
        default: 'Something went wrong while trying to set up that card, please try again',
        403: 'Something went wrong. This app is no longer available',
      });
    }
    if (!this.isPinnedAppInserter) {
      this.set('selectedApp', null);
    }
  }),

  insert(actionData, cardCreationParams) {
    this.insertAction(actionData, this.selectedApp, cardCreationParams);
    this.set('selectedApp.isInitializing', false);
    this.set('selectedApp.isPrefetchingConfiguration', false);
    let closePopover = this.closePopover;
    if (closePopover !== null) {
      closePopover();
    }
  },

  addAnalyticsEventForSelectedMessengerApp({ selectedApp, isInstalled }) {
    let context = this.contextualData || {};
    let analyticsEvent = {
      action: 'clicked',
      object: 'messenger_app',
      messenger_app_id: selectedApp.id,
      conversation_id: context.conversation_id,
      message_id: context.message_id,
      message_variation_id: context.message_variation_id,
      is_installed: isInstalled,
      is_notifiable: selectedApp.notifiable,
    };
    if (context.object_type) {
      analyticsEvent['object_type'] = objectNames[context.object_type];
      analyticsEvent[`${underscore(objectNames[context.object_type])}_id`] = context.object_id;
    }
    this.intercomEventService.trackAnalyticsEvent(analyticsEvent);
  },

  prefetchCanvasOrCardCreationOptions: task(function* () {
    try {
      let data = {
        admin_id: this.get('appService.app.currentAdmin.id'),
        app_id: this.get('appService.app.id'),
        messenger_app_id: this.get('selectedApp.id'),
        context: this.contextualData,
        current_canvas: this.canvas && this.canvas.serialize(),
      };
      this.selectedApp.set('isPrefetchingConfiguration', true);
      let prefetchedConfigurationOptions = yield post('/ember/messenger_apps/configure_card', data);
      if (prefetchedConfigurationOptions.results) {
        yield this.initialize.perform(prefetchedConfigurationOptions.results);
      } else {
        this.selectedApp.set('isPrefetchingConfiguration', false);
        this.set('selectedApp.prefetchedConfigurationOptions', prefetchedConfigurationOptions);
      }
    } catch (error) {
      this._resetTemporaryMessengerAppProperties();
    }
  }).drop(),

  fetchAppPackageAndDisplayModal: task(function* (appPackageId) {
    let selectedAppPackage = yield this.store.findRecord('appstore/app-package', appPackageId);
    this.setProperties({
      selectedAppPackage,
      showAppStoreModal: true,
    });
  }).drop(),

  resetInstallRedirectQueryParams() {
    if (this.appstoreService.hasAppPackageCode()) {
      this.router.transitionTo({
        queryParams: { app_package_code: null, install_success: null, error_message: null },
      });
    }
  },

  resetAppPackageProperties() {
    this.setProperties({
      selectedAppPackage: null,
      showAppStoreModal: false,
    });
  },

  _scrollMessengerAppIntoView(appPackageCode) {
    if (this.isDestroying) {
      return;
    }
    let $messengerAppElement = $(`[data-app-package-id="${appPackageCode}"]`);
    if (!$messengerAppElement.visible()) {
      $('.js__popover__header').animate({ scrollTop: 0 }, 0);
    }
  },

  selectMessengerApp: action(function (messengerApp) {
    this.addAnalyticsEventForSelectedMessengerApp({
      selectedApp: messengerApp,
      isInstalled: true,
    });
    this.set('selectedApp', messengerApp);

    // When the app has no configuration (static or live) we render the initialize card immediately.
    // When the app has a configuration URL, we prefetch the configuration options.
    // otherwise, the messenger-framework/initializer component will be shown and will take care
    // of frame initialization
    if (!messengerApp.get('hasConfiguration')) {
      messengerApp.set('isInitializing', true);
      this.initialize.perform();
    } else if (this.get('selectedApp.configure_url')) {
      this.prefetchCanvasOrCardCreationOptions.perform();
    }
  }),

  selectUninstalledMessengerApp: action(function (messengerApp) {
    this.addAnalyticsEventForSelectedMessengerApp({
      selectedApp: messengerApp,
      isInstalled: false,
    });
    this.fetchAppPackageAndDisplayModal.perform(messengerApp.get('app_package_id'));
  }),

  closeAppStoreModal: action(function () {
    let selectedAppPackageId = this.get('selectedAppPackage.id');
    this.resetInstallRedirectQueryParams();
    this.resetAppPackageProperties();
    schedule('afterRender', this, this._scrollMessengerAppIntoView, selectedAppPackageId);
  }),

  actions: {
    returnToMessengerAppList() {
      let context = this.contextualData || {};
      let analyticsEvent = {
        action: 'clicked',
        object: 'messenger_app_inserter_back_to_app_list',
        messenger_app_id: this.get('selectedApp.id'),
        conversation_id: context.conversation_id,
        message_id: context.message_id,
        message_variation_id: context.message_variation_id,
      };
      if (context.object_type) {
        analyticsEvent['object_type'] = objectNames[context.object_type];
        analyticsEvent[`${underscore(objectNames[context.object_type])}_id`] = context.object_id;
      }
      this.intercomEventService.trackAnalyticsEvent(analyticsEvent);
      this.set('selectedApp', null);
    },

    closePopoverModal() {
      let closePopover = this.closePopover;
      if (closePopover !== null) {
        closePopover();
      }
    },
  },
});
