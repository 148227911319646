/* import __COLOCATED_TEMPLATE__ from './composer.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import intercomMessengerColors from '@intercom/intercom-messenger-colors';
import PALETTE from '@intercom/pulse/lib/palette';

const MINIMAL_CONTRAST_RATIO = 1.6;
const LIGHT_TEXT_COLOR = PALETTE.white;
const DARK_TEXT_COLOR = PALETTE.black;
const UNASSIGNED_SENDER = -1;

// Equivalent of 0.32 alpha value, see https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
const DROP_SHADOW_TRANSPARENCY_IN_HEX = '52';

export default class Composer extends Component {
  @service appService;
  @service store;
  @tracked messengerSettings = [];

  constructor() {
    super(...arguments);
    this.fetchMessengerSettings.perform();
  }

  get app() {
    return this.appService.app;
  }

  get bannerColor() {
    return this.args.banner.color;
  }

  get bannerTextColor() {
    let messengerColors = intercomMessengerColors({
      primaryColor: this.bannerColor,
    });
    return messengerColors.primary_on_white_contrast > MINIMAL_CONTRAST_RATIO
      ? LIGHT_TEXT_COLOR
      : DARK_TEXT_COLOR;
  }

  get bannerDropShadowColor() {
    return `${this.bannerColor}${DROP_SHADOW_TRANSPARENCY_IN_HEX}`;
  }

  get bannerStyle() {
    return this.args.banner.bannerStyleAsString;
  }

  get bannerPosition() {
    return this.args.banner.bannerPositionAsString;
  }

  get localizedBannerContent() {
    return this.args.localizedBannerContent;
  }

  get shouldShowAvatar() {
    if (this.localizedBannerContent.fromId === UNASSIGNED_SENDER) {
      return false;
    }
    return true;
  }

  get showButton() {
    return this.args.banner.isOpenAURLAction;
  }

  get shouldShowDismissButton() {
    return this.args.banner.showDismissButton;
  }

  @task({ drop: true })
  *fetchMessengerSettings() {
    let messengerSettings = yield this.store.findRecord('messenger-settings/all', this.app.id);
    this.messengerSettings = messengerSettings;
  }
}
