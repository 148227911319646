/* import __COLOCATED_TEMPLATE__ from './tooltip-item.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    tooltipRows: TooltipRow[];
  };
}

export type TooltipRow = {
  label: string;
  value?: string;
  muted?: boolean;
  noPadding?: boolean;
};

const TooltipItem = templateOnlyComponent<Signature>();
export default TooltipItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::Tooltips::TooltipItem': typeof TooltipItem;
  }
}
