/* import __COLOCATED_TEMPLATE__ from './external-ai-settings.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { postRequest } from 'embercom/lib/inbox/requests';
import type IntlService from 'ember-intl/services/intl';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export const TERMS_AND_CONDITIONS_URL = 'https://www.intercom.com/terms-of-service';
export default class AiAutomationExternalAiSettings extends Component {
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;

  get isExternalAiEnabled() {
    return this.appService.app.hasConsentedToExternalAi;
  }

  @action
  async toggleExternalAi() {
    let endpoint = this.isExternalAiEnabled
      ? `/ember/external_ai_consent/opt_out?app_id=${this.appService.app.id}`
      : `/ember/external_ai_consent/opt_in?app_id=${this.appService.app.id}`;

    let originalValue = this.isExternalAiEnabled;

    try {
      this.appService.app.set('hasConsentedToExternalAi', !originalValue);
      await postRequest(endpoint);
    } catch (error) {
      this.appService.app.set('hasConsentedToExternalAi', originalValue);

      if (error.response?.status === 404) {
        let json = await error.response.json();

        this.notificationsService.notifyError(
          this.intl.t(
            'new-settings.ai-automation.external-ai.failed-to-toggle-external-ai-consent-with-reason',
            { reason: json.errors },
          ),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t(
            'new-settings.ai-automation.external-ai.failed-to-toggle-external-ai-consent',
          ),
        );
      }

      throw error;
    }
  }

  @action
  async openExternalTermsAndConditions() {
    safeWindowOpen(TERMS_AND_CONDITIONS_URL, '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAutomation::ExternalAiSettings': typeof AiAutomationExternalAiSettings;
    'ai-automation/external-ai-settings': typeof AiAutomationExternalAiSettings;
  }
}
