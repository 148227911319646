/* import __COLOCATED_TEMPLATE__ from './bulk-action-select-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-side-effects */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import Component from '@ember/component';
import { computed } from '@ember/object';
import pluralise from 'embercom/lib/inflector';

export default Component.extend({
  intercomEventService: service(),
  appService: service(),
  intl: service(),
  usersOrCompanies: computed('isCompany', function () {
    if (this.isCompany) {
      return 'companies';
    }
    return pluralise(this.personTypeName);
  }),

  bulkExportTooltipText: computed('bulkActionAudienceLabel', function () {
    return `Export ${this.bulkActionAudienceLabel}`;
  }),
  bulkArchiveTooltipText: computed('bulkActionAudienceLabel', function () {
    return `Archive ${this.bulkActionAudienceLabel}`;
  }),
  bulkOptOutTooltipText: computed('intl.locale', 'bulkActionAudienceLabel', function () {
    return this.intl.t('components.modal.unsubscribe-from-subscription-type.tooltip-text', {
      audience: this.bulkActionAudienceLabel,
    });
  }),
  bulkOptInTooltipText: computed('intl.locale', 'bulkActionAudienceLabel', function () {
    return this.intl.t('components.modal.opt-in-to-subscription-type.tooltip-text', {
      audience: this.bulkActionAudienceLabel,
    });
  }),
  archiveComponent: ternary(
    'isCompany',
    'modal/company-deletion/bulk-archive-companies',
    'modal/user-deletion/bulk-archive-users',
  ),

  bulkActions: computed('intl.locale', function () {
    let items = [
      {
        text: this.intl.t(
          `components.modal.opt-in-to-subscription-type.export.${this.usersOrCompanies}`,
        ),
        icon: 'filled-cloud-download',
        value: 'export',
        onSelectItem: () => this.send('export'),
        tooltipText: this.bulkExportTooltipText,
      },
      {
        text: this.intl.t(
          `components.modal.opt-in-to-subscription-type.archive.${this.usersOrCompanies}`,
        ),
        icon: 'delete',
        value: 'archive',
        onSelectItem: () => this.send('delete'),
        tooltipText: this.bulkArchiveTooltipText,
        isDestructive: true,
      },
    ];

    if (!this.isCompany) {
      items.push({
        text: this.intl.t(
          `components.modal.opt-in-to-subscription-type.unsubscribe.${this.usersOrCompanies}`,
        ),
        icon: 'unsubscribed',
        value: 'unsubscribe-from-subscription-type',
        onSelectItem: () => this.send('unsubscribeFromSubscriptionType'),
        tooltipText: this.bulkOptOutTooltipText,
      });
      items.push({
        text: this.intl.t(
          `components.modal.opt-in-to-subscription-type.subscribe.${this.usersOrCompanies}`,
        ),
        icon: 'check',
        value: 'opt-in-to-subscription-type',
        onSelectItem: () => this.send('subscribeToSubscriptionType'),
        tooltipText: this.bulkOptInTooltipText,
      });
    }

    return [{ items }];
  }),

  actions: {
    export() {
      this.checkPermissionsForExportAndOpenAppropriateModal();
    },
    unsubscribeFromSubscriptionType() {
      this.set('modalComponent', 'modal/unsubscribe-from-subscription-type');
      this.set('showModal', true);
    },
    subscribeToSubscriptionType() {
      this.set('modalComponent', 'modal/subscribe-to-subscription-type');
      this.set('showModal', true);
    },
    showCreateUserModal() {
      this.showCreateUserModal();
    },
    startImport() {
      this.import();
    },
    delete() {
      this._trackAnalytics('archive-clicked');
      this.set('modalComponent', this.archiveComponent);
      this.set('showModal', true);
    },
  },

  _trackAnalytics(_action) {
    this.intercomEventService.trackAnalyticsEvent({
      action: _action,
      object: this.usersOrCompanies,
      place: 'platform',
    });
  },
});
