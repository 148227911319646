/* import __COLOCATED_TEMPLATE__ from './article-multilingual-editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { on } from '@ember/object/evented';
import { isBlank } from '@ember/utils';
import { not, or, readOnly, gt } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import ErrorHandling from 'embercom/components/mixins/articles/error-handling';
import { MAX_SUMMARY_SIZE, MAX_TITLE_SIZE } from 'embercom/models/article';
import {
  equalToProperty,
  findByProperty,
  ternaryToProperty,
  isAny,
} from '@intercom/pulse/lib/computed-properties';
import { getRouterService } from 'embercom/lib/container-lookup';
import { task } from 'ember-concurrency';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import ArticlesComposerConfig from 'embercom/lib/articles/composer-config';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';

export default Component.extend(ErrorHandling, EventTracking, {
  intercomEventService: service(),
  intl: service(),
  appService: service(),
  app: readOnly('appService.app'),
  notificationsService: service(),
  clipboardService: service(),
  store: service(),
  helpCenterService: service(),
  site: readOnly('helpCenterService.site'),
  currentlySelectedLocaleId: readOnly('helpCenterService.currentlySelectedLocaleId'),
  currentlySelectedLocale: readOnly('helpCenterService.currentlySelectedLocale'),
  showSideDrawer: false,
  clipboard: null,

  home: computed(function () {
    return this.store.peekRecord('articles/article-group', 'home');
  }),

  activeContent: computed('article.articleContents.[]', 'currentlySelectedLocaleId', function () {
    return this.article.contentByLocale(this.currentlySelectedLocaleId);
  }),

  activeContentBlocks: computed('activeContent', function () {
    if (this.activeContent) {
      return this.activeContent.jsonBlocksForEditing || this.activeContent.jsonBlocks;
    }
    return [];
  }),

  isMultilingual: gt('site.selectedLocales.length', 1),
  isNotMultilingual: not('isMultilingual'),
  shouldShowErrorBanner: null,
  remainingMissingContentErrorCount: null,
  originalCollection: null,
  collectionHasNotChanged: equalToProperty('originalCollection.id', 'article.folder.id'),
  collectionHasChanged: not('collectionHasNotChanged'),

  hasDirtyArticleContents: isAny('article.articleContents', 'hasDirtyAttributes', true),

  dirty: or(
    'article.hasDirtyAttributes',
    'hasDirtyArticleContents',
    'collectionHasChanged',
    'article.ruleset.hasDirtyAttributes',
  ),

  notDirty: not('dirty'),
  maxSummarySize: MAX_SUMMARY_SIZE,

  audienceTargetingItems: computed(
    'intl.locale',
    'site.segmentedContentTurnedOn',
    'app.canUseArticleAudienceTargeting',
    function () {
      return [
        {
          items: [
            {
              text: this.intl.t('articles.editor.visible-to-anyone'),
              value: 'everyone',
            },
            {
              text: this.intl.t('articles.editor.visible-to-restricted-audience'),
              value: 'users_only',
              component: 'articles/editor/target-audience-paywalled-select-item',
              isDisabled:
                !this.app.canUseArticleAudienceTargeting || !this.site.segmentedContentTurnedOn,
            },
          ],
        },
      ];
    },
  ),

  defaultTargetAudience: 'everyone',

  articleVisibility: ternaryToProperty(
    'site.segmentedContentTurnedOn',
    'article.targetUserType',
    'defaultTargetAudience',
  ),

  classNames: ['flex-auto', 'overflow-auto', 'articles__editor__multilingual-container'],

  setup: on('didInsertElement', function () {
    this.get('article.folder').then((folder) => {
      this.set('originalCollection', folder);
    });

    let showErrorBanner = this.get('article.hasMissingContent');

    this.set('shouldShowErrorBanner', showErrorBanner);
    this.set(
      'remainingMissingContentErrorCount',
      this.get('article.missingContentErrorTotalCount'),
    );
  }),

  activeContentBlocksDocument: computed('activeContentBlocks', function () {
    return new BlocksDocument(this.activeContentBlocks);
  }),

  prosemirrorComposerConfig: computed('activeContent', function () {
    let eventService = this.intercomEventService;
    let analytics = {
      trackAnalyticsEvent: (eventName, attrs) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'article',
          place: 'composer',
          ...attrs,
        });
      },
    };

    let anchorLinkOnClick = (heading) => {
      this.copyHeadingLinkToClipboard(heading);
    };

    return new ArticlesComposerConfig(
      this.app,
      this.activeContent.textDirection,
      analytics,
      anchorLinkOnClick,
    );
  }),

  _buildArticleChangesForTracking() {
    let changes = {};

    if (this.article.changedAttributes().targetUserType) {
      changes.targetUserType = true;
    }

    if (this.collectionHasChanged) {
      changes.collection = true;
    }

    return changes;
  },

  _buildContentsChangesForTracking() {
    let contentsChanges = this.article.articleContents
      .filter((content) => content.hasDirtyAttributes)
      .map((content) => {
        let changes = { content };
        if (content.changedAttributes().state) {
          changes.state = content.changedAttributes().state[1];
        }
        return changes;
      });
    return contentsChanges;
  },

  _triggerSaveTrackingEvents(articleChanges = {}, contentsChanges = []) {
    let metadata = {
      place: 'article',
      action: 'saved',
      saved_as_draft: this.get('article.isDraft'),
    };

    if (articleChanges.targetUserType) {
      metadata.target_user_type = this.get('article.targetUserType');
    }

    if (articleChanges.collection) {
      let action = this.get('article.folder.isHome') ? 'removed' : 'add';
      metadata.action = action;
      if (action === 'removed') {
        metadata.from_collection_id = this.get('originalCollection.id');
        metadata.collection_id = this.get('originalCollection.id');
      } else {
        metadata.collection_id = this.get('article.folder.id');
      }
    }

    this.trackEducateArticleEvent(this.article, metadata);
    contentsChanges.map((contentChange) => {
      this.trackEducateArticleContentEvent(this.article, contentChange.content, {
        state: contentChange.state,
      });
    });
  },

  _getNotificationMessage() {
    // Due to the fact that in multilingual universe a user can edit multiple articles with multiple statuses
    // we will not support draft specific messages for multilingual.
    let isDraft = this.isNotMultilingual && this.get('activeContent.isDraft');
    let inHome = this.get('article.folder.isHome');
    let leavingHomeDirectory = !inHome && this.get('originalCollection.isHome');
    let movingCollections = this.collectionHasChanged;

    let message = this.intl.t('articles.editor.article-saved-successfully');

    if (this.isNotMultilingual) {
      message = isDraft
        ? this.intl.t('articles.editor.article-saved-as-draft')
        : this.intl.t('articles.editor.article-ready-to-add-to-collection');
    }

    if (!inHome) {
      message = isDraft
        ? this.intl.t('articles.editor.updated-draft-in-helpcenter-visible-to-team')
        : this.intl.t('articles.editor.updated-draft-in-helpcenter');
    }
    if (movingCollections) {
      if (inHome) {
        message = isDraft
          ? this.intl.t('articles.editor.draft-removed-from-helpcenter')
          : this.intl.t('articles.editor.article-removed-from-helpcenter');
      } else {
        message = isDraft
          ? this.intl.t('articles.editor.moved-draft-to-different-location')
          : this.intl.t('articles.editor.moved-article-to-different-location');
      }
      if (leavingHomeDirectory) {
        message = isDraft
          ? this.intl.t('articles.editor.draft-saved-visible-to-team')
          : this.intl.t('articles.editor.article-saved');
      }
    }
    return message;
  },

  _showSaveNotifications() {
    let message = this._getNotificationMessage();
    this.notificationsService.notifyConfirmation(message);
  },

  articleHasContentInSelectedLocale: findByProperty(
    'article.articleContents.content',
    'locale',
    'currentlySelectedLocaleId',
  ),

  _trackCopyLink(heading, success) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'anchor_link_button',
      action: 'clicked',
      locale: this.currentlySelectedLocaleId,
      anchor_link_type: heading.type,
      success,
    });
  },

  copyHeadingLinkToClipboard(heading) {
    let url = `${this.activeContent.publicUrl}#${heading.idAttribute}`;
    let onSuccess = () => {
      this._trackCopyLink(heading, true);
      let previewText = htmlToTextContent(heading.text);
      previewText = previewText.length > 45 ? `${previewText.substring(0, 45)}…` : previewText;
      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.editor.copied-anchor-link-to-place', { preview_text: previewText }),
      );
      this.clipboard?.destroy();
    };
    let onError = () => {
      this._trackCopyLink(heading, false);
      this.notificationsService.notifyWarning('Failed to copy link');
      this.clipboard?.destroy();
    };
    this.clipboard = this.clipboardService.createClipboard(
      '.js__prosemirror-composer-copy-clipboard-element',
      url,
      onSuccess,
      onError,
    );
  },

  saveAndClose: task(function* () {
    let article = this.article;

    // TODO - should we select the language with long title? what if there's more than one?
    if (
      article.articleContents.any(
        (content) => content.title && content.title.length > MAX_TITLE_SIZE,
      )
    ) {
      this.notificationsService.notifyError(
        this.intl.t('articles.editor.article-title-length-too-long', {
          max_title_size: MAX_TITLE_SIZE,
        }),
      );
      return;
    }

    // TODO - should we select the language with long summary? what if there's more than one?
    if (
      article.articleContents.any(
        (content) => content.summary && content.summary.length > MAX_SUMMARY_SIZE,
      )
    ) {
      this.notificationsService.notifyError(
        this.intl.t('articles.editor.article-title-length-too-long', {
          max_title_size: MAX_TITLE_SIZE,
        }),
      );
      return;
    }

    // TODO - should we have a translated version of "Untitled article"?
    article.articleContents.forEach((content) => {
      if (isBlank(content.title)) {
        content.set('title', this.intl.t('articles.editor.untitled-article'));
      }
    });

    let articleChanges = this._buildArticleChangesForTracking();
    let contentsChanges = this._buildContentsChangesForTracking();

    try {
      yield article.save();
      if (article.ruleset) {
        article.ruleset.rollbackAttributes();
      }

      // @hourliert, this is needed due to ember not being able to resolve conflicts when saving new embedded model records
      // see https://discuss.emberjs.com/t/proposal-fix-saving-new-embedded-records-creates-duplicates/3677
      article.articleContents.forEach((a) => {
        a.rollbackAttributes();
      });
    } catch (response) {
      this.handleServerError(response, this.intl.t('articles.editor.problem-saving-article'));
      return;
    }
    if (this.collectionHasChanged && article.get('folder.id') !== 'home') {
      article.incrementProperty('collection.count');
    }

    this._triggerSaveTrackingEvents(articleChanges, contentsChanges);
    this._showSaveNotifications();
    getRouterService(this).transitionTo('apps.app.articles.articles.article.show');
  }).drop(),

  executeSaveAndClose: action(function () {
    this.saveAndClose.perform().catch((error) => {
      if (error && error.message !== 'TaskCancelation') {
        this.handleServerError(error, this.intl.t('articles.editor.problem-saving-article'));
      }
    });
  }),

  cancelEdit: action(function () {
    window.history.back();
  }),

  prosemirrorComposerChanged: action(function (newBlocksDocument) {
    this.set('activeContent.jsonBlocks', newBlocksDocument.blocks);
    this.set('activeContent.jsonBlocksForEditing', newBlocksDocument.blocks);
  }),

  prosemirrorComposerReady: action(function (composer) {
    this.set('prosemirrorComposer', composer);
  }),

  setArticleGroup: action(function (groupId) {
    let folder = this.store.peekRecord('articles/article-group', groupId);
    this.set('article.folder', folder);
    this.trackEducateArticleEvent(this.article, {
      place: 'article',
      action: 'selected',
      context: 'collection',
      value: groupId,
    });
  }),

  addCurrentlySelectedLocale: action(function () {
    this.article.createContentIfNone(this.currentlySelectedLocaleId);
  }),

  afterLocaleUpdate: action(function () {
    if (!this.articleHasContentInSelectedLocale) {
      this.article.createContentIfNone(this.currentlySelectedLocaleId);
    }
  }),

  actions: {
    setTargetUserType(value) {
      this.set('article.targetUserType', value);
      this.trackEducateArticleEvent(this.article, {
        place: 'article',
        action: 'selected',
        context: 'target_user_type',
        value,
      });
    },
  },
});
