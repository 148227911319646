/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  classNames: ['flex-none', 'flex', 'flex-row'],
  didReceiveAttrs() {
    this._super(...arguments);
    let currentRouteName = this.currentRouteName;
    if (!currentRouteName.match(/\.loading$/)) {
      this.set('internalCurrentRouteName', currentRouteName);
    }
  },
});
