/* import __COLOCATED_TEMPLATE__ from './collection-list.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { CollectionListTemplate } from 'embercom/models/customization-options/layout-options/blocks/collection-list';
import type CollectionList from 'embercom/models/customization-options/layout-options/blocks/collection-list';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  site: HelpCenterSite;
  homepageBlock: CollectionList;
}

export default class CollectionListComponent extends Component<Args> {
  get columns() {
    return this.args.homepageBlock.columns || 1;
  }

  set columns(value: number) {
    this.args.homepageBlock.setColumns(value);

    // TODO: Remove this when migrated all settings into customization options
    this.args.site.homeCollectionCols = value;

    if (value === 1) {
      this.template = CollectionListTemplate.Classic;
    }
  }

  get template() {
    return this.args.homepageBlock.template;
  }

  set template(value: CollectionListTemplate) {
    this.args.homepageBlock.template = value;
  }

  get isSingleColumnSelected() {
    return this.columns === 1;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::NewStyling::Layout::CollectionList': typeof CollectionListComponent;
    'articles/site/settings/new-styling/layout/collection-list': typeof CollectionListComponent;
  }
}
