/* import __COLOCATED_TEMPLATE__ from './current-price-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Breakdown from 'embercom/models/billing/price/breakdown';
import type Plan from 'embercom/models/plan';

interface Args {
  breakdowns: Array<Breakdown>;
}

interface Signature {
  Args: Args;
}

export default class BillingMigrationCurrentPriceDetailsComponent extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare intl: IntlService;

  get corePlanBreakdowns() {
    return this.args.breakdowns.filter(
      (breakdown) => !this.isAddonBreakdown(breakdown) && !this.isDiscountBreakdown(breakdown),
    );
  }

  get addonBreakdowns() {
    return this.args.breakdowns.filter((breakdown) => this.isAddonBreakdown(breakdown));
  }

  get discountBreakdowns() {
    return this.args.breakdowns.filter((breakdown) => this.isDiscountBreakdown(breakdown));
  }

  get additionalTeammatesToShow(): number {
    if (this.appService.app.requiresInboxSeatAccess) {
      return this.appService.app.adminsWithoutInboxAccess.length;
    }
    if (this.customerService.customer.subscription.isOnTprPricingModel) {
      return this.appService.app.humanAdmins.length;
    }

    return 0;
  }

  get sharedBasePriceBreakdown() {
    return this.args.breakdowns.find((breakdown) => breakdown.isSharedBaseBreakdown);
  }

  private isDiscountBreakdown(breakdown: Breakdown) {
    return breakdown.isSharedBaseBreakdown || breakdown.isDiscount;
  }

  private isAddonBreakdown(breakdown: Breakdown) {
    let plan = this.customerService.plans.find(
      (plan: Plan) => plan.idAsNumber === breakdown.plan_id,
    );

    if (plan === undefined) {
      return false;
    }

    return !!plan.product.addon;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::CurrentPriceDetails': typeof BillingMigrationCurrentPriceDetailsComponent;
  }
}
