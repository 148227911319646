/* import __COLOCATED_TEMPLATE__ from './tabs-component.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class TabsComponent extends Component {
  @service router;
  @service intl;

  get activeView() {
    return this.args.displaying || 'article';
  }

  @action
  changeView(displaying) {
    this.args.changeView(displaying);
  }

  get tabComponentConfigs() {
    return [
      {
        label: this.intl.t('articles.show.tabs.tabs-components.article'),
        view: 'article',
        component: 'articles/show/tabs/overview-tab',
      },
      {
        label: this.intl.t('articles.show.tabs.tabs-components.views'),
        view: 'views',
        component: 'articles/show/tabs/stat-tab',
        statistic: {
          value: this.args.stats?.viewCount,
        },
      },
      {
        label: this.intl.t('articles.show.tabs.tabs-components.conversations'),
        view: 'newchat',
        component: 'articles/show/tabs/stat-tab',
        statistic: {
          value: this.args.stats?.replyCount,
        },
      },
      {
        label: this.intl.t('articles.show.tabs.tabs-components.reacted'),
        view: 'reacted',
        component: 'articles/show/tabs/reactions-tab',
        statistic: {
          subaggregations: [
            { value: '\uD83D\uDE03', count: this.args.stats?.reaction0Percentage },
            { value: '\uD83D\uDE10', count: this.args.stats?.reaction1Percentage },
            { value: '\uD83D\uDE1E', count: this.args.stats?.reaction2Percentage },
          ],
        },
      },
    ];
  }
}
