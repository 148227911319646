/* import __COLOCATED_TEMPLATE__ from './input-with-pills.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';

const BACKSPACE = 8;

export default Component.extend({
  pills: null,

  onFocus: undefined,
  onBlur: undefined,
  onKeyDown: undefined,
  onKeyUp: undefined,

  keyDown(e) {
    let cursorPosition = e.target.selectionStart;
    let lastPill = this.pills.lastObject;

    if (e.which === BACKSPACE) {
      if (cursorPosition === 0 && this.pills.length > 0) {
        if (lastPill.selected) {
          let index = this.pills.length - 1;
          this.removePill(lastPill, index);
        }
        this._markLastPillForDeletionIfAvailable();
      }
    } else if (lastPill) {
      lastPill.set('selected', false);
    }
    if (this.onKeyDown) {
      this.onKeyDown();
    }
  },

  keyUp(e) {
    if (this.onKeyUp) {
      this.onKeyUp(e);
    }
  },

  mouseDown(e) {
    let inContainer = this.element.contains(e.target);
    this.set('activeClickInContainer', inContainer);
  },

  mouseUp() {
    if (this.activeClickInContainer) {
      this.element.querySelector('input').focus();
    }
    this.set('activeClickInContainer', undefined);
  },

  focusOut() {
    if (!this.activeClickInContainer && this.onBlur) {
      this.onBlur();
    }
  },

  focusIn() {
    if (this.onFocus) {
      this.onFocus();
    }
  },

  _markLastPillForDeletionIfAvailable() {
    let lastPill = this.pills.lastObject;
    if (lastPill) {
      lastPill.set('selected', true);
    }
  },
});
