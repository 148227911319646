/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-side-effects */
import { and } from '@ember/object/computed';
import { computed } from '@ember/object';
import AttributeComponent from 'embercom/components/attribute-component';

let ProfileStringComponent = AttributeComponent.extend({
  isMultipleUrl: computed('userOrCompany', 'attribute', 'value', function () {
    let urls = this.commaSeparatedTokens;
    if (urls.length > 1) {
      return urls.every(function (element) {
        return element.match(/^https?:\/\//);
      });
    }
    return false;
  }),
  linkToEmailDomain: and('isEmailAddress', 'isNotEmailDomain'),
  isEmailAddress: computed('userOrCompany', 'attribute', 'value', function () {
    let regex = /^([a-z0-9._%+-]+)@([a-z0-9.-]+\.[a-z]{2,4})$/;
    let matches = regex.exec(this.value.toString().toLowerCase());
    if (matches && matches.length === 3) {
      this.set('emailUsername', matches[1]);
      this.set('emailDomain', matches[2]);
      return true;
    }
    return false;
  }),
  isNotEmailDomain: computed('emailDomain', function () {
    let emailDomain = this.emailDomain;
    return !['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'].includes(emailDomain);
  }),
  commaSeparatedTokens: computed('userOrCompany', 'attribute', 'value', function () {
    let tokens = this.value.toString().split(',');
    return tokens.map(function (token) {
      return String(token).trim();
    });
  }),
  filterUrl: computed('userOrCompany', 'attribute', 'value', function () {
    if (this.value && this.value.toString().match(/^https?:\/\//)) {
      return this.value;
    }
    return this._super();
  }),
});

export default ProfileStringComponent;
