/* import __COLOCATED_TEMPLATE__ from './tree-list.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */

import Component from '@glimmer/component';
import move from 'ember-animated/motions/move';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import { type Tree, type TreeParent } from 'embercom/objects/tree-list';
import { type TransitionContext } from 'ember-animated';

import ENV from 'embercom/config/environment';

interface Args {
  parent: TreeParent;
  tree: Tree;
  root?: boolean;
}

export default class TreeList extends Component<Args> {
  // eslint-disable-next-line require-yield
  *listTransition({ insertedSprites, keptSprites, removedSprites }: TransitionContext) {
    if (ENV.environment === 'test') {
      return;
    }

    keptSprites.forEach((sprite) => move(sprite));
    insertedSprites.forEach((sprite) => fadeIn(sprite));
    removedSprites.forEach((sprite) => fadeOut(sprite));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Common::TreeList': typeof TreeList;
    'common/tree-list': typeof TreeList;
  }
}
