/* import __COLOCATED_TEMPLATE__ from './company-address-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { post } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { addressValidation } from 'embercom/lib/address-validation';
import AddressParams from 'embercom/lib/billing/address-params';
import { isArray } from '@ember/array';
import { action } from '@ember/object';

export default class CompanyAdressModal extends Component {
  @service appService;
  @service customerService;
  @service notificationsService;
  @service store;
  @service intl;
  @tracked address = new AddressParams();
  @tracked showAllErrors = false;
  @tracked isVatInvalid = false;

  constructor() {
    super(...arguments);
    this.address.countryCode = this.customerService.customer.billingAddress?.country || 'US';

    if (this.customerService.customer.shippingAddress) {
      this.address.countryCode = this.customerService.customer.shippingAddress.country;
      this.address.stateCode = this.customerService.customer.shippingAddress.state;
      this.address.postcode = this.customerService.customer.shippingAddress.postcode;
      this.address.streetAddress = this.customerService.customer.shippingAddress.streetAddress;
      this.address.city = this.customerService.customer.shippingAddress.city;
    }
    this.address.vatNumber = this.customerService.customer.vatNumber;
  }

  get primaryButtonLabel() {
    return this.save.isRunning
      ? 'billing.address-form.address-modal.updating'
      : 'billing.address-form.address-modal.update-address';
  }

  get secondaryButtonLabel() {
    return this.intl.t('billing.address-form.address-modal.cancel');
  }

  @action
  onVatChange() {
    this.isVatInvalid = false;
  }

  @task
  *save() {
    if (!addressValidation(this.address)) {
      this.showAllErrors = true;
      return;
    }

    try {
      let response = yield post(
        `/ember/customers/${this.customerService.customer.id}/update_shipping_address`,
        {
          app_id: this.appService.app.id,
          street_address: this.address.streetAddress,
          city: this.address.city,
          postcode: this.address.postcode,
          country: this.address.countryCode,
          state_code: this.address.stateCode,
          vat_number: this.address.vatNumber,
        },
      );
      this.store.pushPayload('billing/customer', {
        'billing/customer': response,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('billing.address-form.address-modal.successful-update'),
      );
      this.args.closeModal();
    } catch (error) {
      let errorMessage = this.intl.t('billing.address-form.address-modal.update-error');
      if (isArray(error.jqXHR.responseJSON)) {
        errorMessage = error.jqXHR.responseJSON[0];
      }
      this.notificationsService.notifyError(errorMessage);

      // Checking for hardcoded VAT error message from the BillingService here - if it happens, we highlight the VAT field.
      if (errorMessage === 'Invalid VAT number') {
        this.isVatInvalid = true;
      }
    }
  }
}
