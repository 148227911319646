/* import __COLOCATED_TEMPLATE__ from './avatar-upload-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { cancel } from '@ember/runloop';
import { action } from '@ember/object';
import $ from 'jquery';
import { not, reads, none, gt, and } from '@ember/object/computed';
import Component from '@ember/component';
import ImageUploadComponent from 'embercom/components/mixins/image-upload-component';
import { inject as service } from '@ember/service';

export default Component.extend(ImageUploadComponent, {
  intercomEventService: service(),
  notificationsService: service(),
  saving: false,
  maxFileSize: '15MB',
  isNotSaving: not('saving'),
  avatarUrl: reads('admin.avatarUrl'),
  hasNoAvatar: none('admin.avatar'),
  fileIsLargerThan15MB: gt('file.size', 15000000),
  fileIsTooLarge: and('fileIsLargerThan15MB', 'hasFile'),

  resetFileIfInvalid() {
    if (this.fileIsInvalid) {
      this.notificationsService.notifyError(this.message);
      cancel(this.invalidFileResetTask);
      if (!(this.isDestroying || this.isDestroyed)) {
        this.setProperties({
          file: undefined,
          uploadDidFail: false,
          hasFile: false,
        });
      }
    }
  },

  removeFile: action(function () {
    this.set('file', null);
    this.set('hasFile', false);
  }),

  saveAdminAvatar: action(function () {
    this.set('saving', true);
    let admin = this.admin;
    let file = this.file;

    admin.createAvatar(file).then(
      () => {
        this.setProperties({
          hasSaved: true,
          hasFile: false,
        });
        this.notificationsService.notifyConfirmation('Your photo has been updated!');
        this.intercomEventService.trackAnalyticsEvent({
          action: 'completed',
          object: 'photo-upload',
        });
      },
      () => {
        this.set('hasErrored', true);
      },
    );
  }),

  actions: {
    showFilePicker() {
      $('input:file', this.element).click();
    },

    removeAdminAvatar() {
      this.admin.destroyAvatar();
    },
  },
});
