/* import __COLOCATED_TEMPLATE__ from './plan-dropdown-option-item.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { PLAN_DATA } from 'embercom/lib/billing';

interface Args {
  item: {
    value: string;
    text: string;
    label: string;
  };
}

export default class PlanDropdownOptionItem extends Component<{ Args: Args }> {
  get pricePerSeat() {
    return PLAN_DATA[this.args.item.value].pricePerSeat;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::PlanDropdownOptionItem': typeof PlanDropdownOptionItem;
  }
}
