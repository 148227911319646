/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class Main extends Component {
  @service store;
  @service appService;
  @service intercomEventService;

  @tracked integrations = [];
  @tracked instagramSettings;
  @tracked showModal = false;
  @tracked removingIntegration;

  constructor() {
    super(...arguments);
    this.loadIntegrations.perform();
    this.loadInstagramSettings.perform();
  }

  get app() {
    return this.appService.app;
  }

  @task({ drop: true })
  *loadIntegrations() {
    this.integrations = yield this.store.findAll('instagram/integration');
  }

  @task({ drop: true })
  *loadInstagramSettings() {
    this.instagramSettings = yield this.store.findRecord('instagram/settings', this.app.id);
  }

  @task({ drop: true })
  *removeAccount() {
    yield this.removingIntegration.destroyRecord();
    this.removingIntegration = null;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'deleted',
      object: 'instagram_integration',
      current_tab: 'settings',
      app_package_code: 'instagram-pv4',
    });
  }

  @action
  showRemoveModal(instagramIntegration) {
    this.removingIntegration = instagramIntegration;
    this.showModal = true;
  }

  @action
  confirmRemoveAccount() {
    this.removeAccount.perform();
    this.showModal = false;
  }
}
