/* import __COLOCATED_TEMPLATE__ from './auto-resolve-event-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Task from 'embercom/models/checklists/task';
import { EventType } from 'embercom/models/checklists/task';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';

interface Args {
  task: Task;
}

const userEventsToFilterOut = [
  'user_event_summaries.view-article',
  'article_viewed',
  'user_event_summaries.viewed article',
  'user_event_summaries.viewed-article',
  'user_event_summaries.reacted with 😃 to article',
  'user_event_summaries.reacted with 😐 to article',
  'user_event_summaries.reacted with 😞 to article',
];

export default class AutoResolveEventSelector extends Component<Args> {
  @service declare appService: any;
  @service attributeService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  get task() {
    return this.args.task;
  }

  get activeEvents() {
    return this.attributeService.attributes.filter((attr: any) => {
      if (!attr.isUserEvent) {
        return false;
      }

      // Filter out attributes in the list, except if they are already set on the model.
      if (userEventsToFilterOut.includes(attr.identifier)) {
        let predicateAlreadySetOnModel = this.task.eventId === attr.event_id;
        return predicateAlreadySetOnModel;
      }
      return true;
    });
  }

  get activeEventsNames() {
    return this.activeEvents.map((event: any) => {
      return {
        text: event.human_friendly_name,
        value: event,
        icon: 'trigger',
      };
    });
  }

  get baseEvent() {
    if (!this.task.eventId) {
      return null;
    }

    return this.attributeService.attributes.find(
      (attr: any) => attr.event_id === this.task.eventId,
    );
  }

  get eventName() {
    return this.baseEvent.humanFriendlyName;
  }

  get attributeSelectItems() {
    return [
      {
        heading: this.intl.t('outbound.checklists.task-settings.triggered-events'),
        items: this.activeEventsNames,
      },
    ];
  }

  @action onSelectItem(event: any) {
    this.task.eventType = EventType.UserEvent;
    this.task.eventId = event.event_id;
  }

  @action deleteAutoResolveEvent() {
    if (this.baseEvent) {
      this.task.eventPredicateGroup.predicates = [];
      this.task.eventType = null;
      this.task.eventId = null;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Sidebar::AutoResolveEventSelector': typeof AutoResolveEventSelector;
    'checklist/sidebar/auto-resolve-event-selector': typeof AutoResolveEventSelector;
  }
}
