/* import __COLOCATED_TEMPLATE__ from './coupon-modal.hbs'; */
/* RESPONSIBLE TEAM: team-billing */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { readOnly } from '@ember/object/computed';
import { action } from '@ember/object';

export default Component.extend({
  tagName: '',
  notificationsService: service(),
  customerService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  store: service(),
  appService: service(),
  app: readOnly('appService.app'),

  init() {
    this._super(...arguments);
    this.setProperties({
      isSaving: false,
      errors: [],
      code: '',
    });
  },

  loadCustomer: action(function (payload) {
    return this.store.pushPayload('billing/customer', {
      'billing/customer': payload,
    });
  }),

  closeModal: action(function () {
    this.modalService.closeModal();
  }),

  actions: {
    redeem() {
      let self = this;
      this.set('isSaving', true);
      return ajax({
        url: `/ember/customers/${self.get('model.id')}/redeem_coupon`,
        type: 'POST',
        data: JSON.stringify({
          code: self.get('code'),
          app_id: self.get('app.id'),
        }),
      }).then(
        function (customer) {
          this.notificationsService.notifyConfirmation(
            `You’ve redeemed the ‘${self.get('code')}’ coupon`,
          );
          this.customerService.loadData({ fetchPrices: true, source: 'coupon-modal' });
          this.loadCustomer(customer);
          this.closeModal();
        }.bind(this),
        function (response) {
          this.set('errors', response.jqXHR.responseJSON);
          this.set('isSaving', false);
        }.bind(this),
      );
    },
  },
});
