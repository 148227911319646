/* import __COLOCATED_TEMPLATE__ from './address-form.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import States from 'embercom/lib/states';
import Countries from 'embercom/lib/countries';
import { countrySelect } from 'embercom/helpers/country-select-helper';
import AddressSettings from 'embercom/lib/address-settings';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class AddressForm extends Component {
  @tracked states;
  @tracked addressConfiguration;
  @tracked showVatNumber;
  @service intl;

  constructor() {
    super(...arguments);

    this.addressConfiguration =
      AddressSettings[this.args.address.countryCode] || AddressSettings.default;

    if (this.addressConfiguration.showState) {
      this.states = States[this.args.address.countryCode];
    }

    this.showVatNumber = this.shouldCollectVatNumber(this.args.address.countryCode);
  }

  get countries() {
    return countrySelect(Countries.allCountries);
  }

  get isCountryCodeInvalid() {
    return isEmpty(this.args.address.countryCode);
  }

  get isStreetAddressInvalid() {
    return isEmpty(this.args.address.streetAddress);
  }

  get isCityInvalid() {
    return isEmpty(this.args.address.city);
  }

  get isStateCodeInvalid() {
    return isEmpty(this.args.address.stateCode);
  }

  get isZipcodeInvalid() {
    let postcode = this.args.address.postcode;
    return isEmpty(postcode) || !this.validPostcodeFormat();
  }

  get zipcodeErrorMessage() {
    let postcode = this.args.address.postcode;
    let localizedPostCodeLabel = this.intl.t(this.addressConfiguration.postCodeLabelTranslationKey);
    if (isEmpty(postcode)) {
      return this.intl.t('billing.address-form.post-code-blank', {
        postCodeLabel: localizedPostCodeLabel,
      });
    } else if (!this.validPostcodeFormat()) {
      return this.intl.t('billing.address-form.post-code-invalid', {
        postCodeLabel: localizedPostCodeLabel,
      });
    }
    return '';
  }

  validPostcodeFormat() {
    if (!this.addressConfiguration.postCodeRegex) {
      return true;
    }

    return this.addressConfiguration.postCodeRegex.exec(this.args.address.postcode);
  }

  @action
  onCountrySelected(code) {
    this.addressConfiguration = AddressSettings[code] || AddressSettings.default;

    if (this.addressConfiguration.showState) {
      this.states = States[code];
    }
    this.args.address.stateCode = undefined;

    this.showVatNumber = this.shouldCollectVatNumber(code);
    if (!this.showVatNumber) {
      this.args.address.vatNumber = undefined;
    }

    this.args.address.countryCode = code;
  }

  @action
  onStateCodeSelected(code) {
    this.args.address.stateCode = code;
  }

  @action
  emitVatChange() {
    this.args.onVatChange();
  }

  shouldCollectVatNumber(code) {
    if (!this.args.collectVatNumber || !code) {
      return false;
    }

    let country = Countries.allCountries.find((country) => country.code === code);
    return country.inEu;
  }
}
