/* import __COLOCATED_TEMPLATE__ from './input-with-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import { calculateMaxHeight } from '@intercom/pulse/lib/dropdown-utils';
import trustedStyle from '@intercom/pulse/lib/trusted-style';
import { isPresent } from '@ember/utils';

export default class InputWithDropdown extends Component {
  @tracked searchText;
  @tracked inputHasFocus = false;
  @tracked openerWidth;
  @tracked maxOverlayHeight;
  @tracked hoveredItemIndex = 0;
  @tracked mouseFocused = false;
  @tracked selectedItems = this.args.options.filter((o) => o.isSelected);
  isFirstLoad = true;
  keyDownHandlers = {
    Escape: this.onEscape.bind(this),
    ArrowDown: this.onArrowDown.bind(this),
    ArrowUp: this.onArrowUp.bind(this),
    Enter: this.onEnter.bind(this),
  };

  get nonSelectedItems() {
    let selectedValues = this.selectedItems.map((s) => s.value);
    return this.args.options.filter((t) => !selectedValues.includes(t.value));
  }

  get displayedItems() {
    if (this.searchText) {
      return this.nonSelectedItems.filter((t) =>
        t.text.toLowerCase().includes(this.searchText.toLowerCase()),
      );
    } else {
      return this.nonSelectedItems;
    }
  }

  get popoverStyle() {
    return trustedStyle`width: ${this.openerWidth}px; max-height: ${this.maxOverlayHeight}px; overflow: scroll;`;
  }

  get noItemMessage() {
    return isPresent(this.args.noItemPlaceholder)
      ? this.args.noItemPlaceholder
      : 'No matching items';
  }

  @action
  removePill(selectedOption) {
    if (selectedOption.readonly) {
      return;
    }
    this.selectedItems.removeObject(selectedOption);
    if (this.args.onChange) {
      this.args.onChange(this.selectedItems.map((s) => s.value));
    }
  }

  @action
  onFocus(popover) {
    this.inputHasFocus = true;
    if (!this.isFirstLoad) {
      popover.show();
    }
    this.isFirstLoad = false;
  }

  @action
  offFocus(popover) {
    this.inputHasFocus = false;
    this.hoveredItemIndex = 0;
    popover.hide();
  }

  @action
  itemMouseFocused(index) {
    this.mouseFocused = true;
    this.hoveredItemIndex = index;
  }

  onEscape(event, popover) {
    popover.hide();
    event.stopPropagation();
    this.hoveredItemIndex = 0;
  }

  onArrowUp(event, popover) {
    this.mouseFocused = false;
    this.hoveredItemIndex = Math.max(this.hoveredItemIndex - 1, 0);
    this.scrollToItem(-this.itemHeight);
  }

  onArrowDown(event, popover) {
    if (!popover.isVisible) {
      popover.show();
    } else {
      this.mouseFocused = false;
      this.hoveredItemIndex = Math.min(this.hoveredItemIndex + 1, this.displayedItems.length - 1);
      this.scrollToItem(this.itemHeight);
    }
  }

  get itemHeight() {
    let item = document.querySelector('.dropdown__list-item');
    return item.offsetHeight;
  }

  scrollToItem(offset) {
    let container = document.querySelector('.dropdown__list');
    container.scrollTop += offset;
  }

  onEnter(event, popover) {
    this.onSelectItem(this.displayedItems[this.hoveredItemIndex]);
  }

  @action
  onInputKeyDown(popover) {
    let key = event.key;
    if (this.keyDownHandlers[key]) {
      this.keyDownHandlers[key](event, popover);
      return;
    }
    if (popover.isVisible) {
      this.hoveredItemIndex = 0;
    } else {
      popover.show();
    }
  }

  @action
  measureOpener(data) {
    if (!this.isDestroying) {
      this.openerWidth = data.state.elements.reference.offsetWidth;
    }
  }
  @action
  calculateMaxHeight(popperModifierData) {
    if (!this.isDestroying) {
      this.maxOverlayHeight = calculateMaxHeight(popperModifierData);
    }
  }
  @action
  onSelectItem(item) {
    this.selectedItems.pushObject(item);
    if (this.args.onChange) {
      this.args.onChange(this.selectedItems.map((s) => s.value));
    }
    this.searchText = '';
    next(() => {
      let container = document.querySelector('.input-with-dropdown');
      container.scrollTo({ top: container.scrollHeight });
      document.querySelector('.js__input-with-pills__input').focus();
    });
  }
}
