/* import __COLOCATED_TEMPLATE__ from './migration-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import type StripeMigration from 'embercom/models/billing/stripe-migration';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Price from 'embercom/models/price';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import moment from 'moment-timezone';
import {
  formattedMigrationDate,
  howWeAssignSeatsLink,
} from 'embercom/helpers/billing/migrations-helper';
import type MigrationSeatConfiguration from 'embercom/models/billing/migration-seat-configuration';
import {
  FIN_AI_COPILOT_BASE_ID,
  PRICING_5_X_ADDON_PLAN_IDS,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_EXPERT_ID,
  PRICING_5_X_CORE_PLANS,
} from 'embercom/lib/billing';
import {
  PRICING_5_X_FULL_SEAT_TYPE as CORE,
  COPILOT_SEAT_TYPE as COPILOT,
} from 'embercom/lib/settings/seats/constants';
import type RouterService from '@ember/routing/router-service';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type Transition from '@ember/routing/transition';
import { type CopilotPlanToPriceMap, type CopilotSeatInfo } from './types';
import type Breakdown from 'embercom/models/billing/price/breakdown';
import type Charge from 'embercom/models/billing/price/charge';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { isPresent } from '@ember/utils';

interface Args {
  migration: StripeMigration;
  currentPrices: Price[];
  pricing5Estimates: {
    essentialEstimate: Price;
    essentialWithPSPEstimate: Price;
    essentialWithCopilotEstimate: Price;
    essentialWithPSPCopilotEstimate: Price;
    advancedEstimate: Price;
    advancedWithPSPEstimate: Price;
    advancedWithCopilotEstimate: Price;
    advancedWithPSPCopilotEstimate: Price;
    expertEstimate: Price;
    expertWithPSPEstimate: Price;
    expertWithCopilotEstimate: Price;
    expertWithPSPCopilotEstimate: Price;
  };
  pricing5EstimatesForEditPackage: {
    essentialEstimate: Price;
    essentialWithPSPEstimate: Price;
    essentialWithCopilotEstimate: Price;
    essentialWithPSPCopilotEstimate: Price;
    advancedEstimate: Price;
    advancedWithPSPEstimate: Price;
    advancedWithCopilotEstimate: Price;
    advancedWithPSPCopilotEstimate: Price;
    expertEstimate: Price;
    expertWithPSPEstimate: Price;
    expertWithCopilotEstimate: Price;
    expertWithPSPCopilotEstimate: Price;
  };
  availableEssentialFeatures: string[];
  availableAdvancedFeatures: string[];
  availableExpertFeatures: string[];
  availablePSPFeatures: string[];
  allFeatures: string[];
  availableFeaturesForApp: string[];
  showEditPlanTab: boolean | undefined;
  onProactiveMigrationSave: () => void;
  newSettings?: boolean;
  selectedTab?: string;
  onTabSelectionChange?: (selectedTab: string) => void;
  migrationSeatConfiguration: MigrationSeatConfiguration;
  onMigrationSeatConfigurationSave: (seatCounts: {
    core_seat_count: number;
    copilot_seat_count: number;
  }) => Promise<void>;
  onPackageConfirmation: () => Promise<void>;
}

interface Signature {
  Args: Args;
}

const EDIT_PACKAGE_TAB = 'moveToNewPricing';
const MIGRATION_OVERVIEW_TAB = 'migrationOverview';

export default class BillingMigrationMigrationDetailsComponent extends Component<Signature> {
  @service declare appService: any;
  @service declare customerService: any;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare router: RouterService;
  @service declare intercomConfirmService: IntercomConfirmService;

  @tracked addonPlanIds: string[] = [];
  @tracked hasUnsavedChanges = false;
  @tracked planSelection = '';
  @tracked oldSettingsSelectedTab = this.initialSelectedTab();
  @tracked hasAppliedSeatChanges = false;

  initialPlanSelection = '';
  initialAddonPlanIds: string[] = [];
  initialAdminSeatMapping: { [key: string]: string[] } = {};

  SEATS_ASSIGNMENT_TOOLTIP = {
    pppWithSeats:
      'billing.migrations.migration_overview.migrationChoice.seats_assignment_tooltip_ppp_seats',
    other:
      'billing.migrations.migration_overview.migrationChoice.seats_assignment_tooltip_other_seats',
  };

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.planSelection =
      this.getDefaultCorePlanSelection(this.args.migration.postMigrationPlanIds) || '';
    this.addonPlanIds = this.getAddonPlanIds(this.args.migration.postMigrationPlanIds) || [];
    this.initialPlanSelection = this.planSelection;
    this.initialAddonPlanIds = this.addonPlanIds;
    this.setInitialAdminSeatMapping();

    this.router.on('routeWillChange', this.warnIfUnsavedChangesCallback);
    this.router.on('routeWillChange', this.warnIfUnsavedChangesOnTabChangeCallback);
  }

  get selectedTab() {
    if (this.args.newSettings) {
      return this.args.selectedTab;
    }
    return this.oldSettingsSelectedTab;
  }

  initialSelectedTab() {
    if (this.args.showEditPlanTab || !this.args.migration.isProactive) {
      return EDIT_PACKAGE_TAB;
    }
    return MIGRATION_OVERVIEW_TAB;
  }

  get shouldShowImprovedFlow() {
    return this.appService.app.canSeePricingMigrationImprovedFlow;
  }

  get editPlanLabel() {
    return this.args.migration.isProactive
      ? this.intl.t('billing.migrations.migration_edit_plan')
      : this.intl.t('billing.migrations.move_to_new_pricing');
  }

  get nextInvoiceDate() {
    return this.intl.formatDate(moment(this.customerService.customer?.currentBillingCycleEndDate), {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  get formattedMigrationDate() {
    return formattedMigrationDate(this.args.migration.migrationDate, this.intl);
  }

  get shouldShowHelpTab() {
    return this.args.migration.isProactive;
  }

  @action
  talkToSales() {
    showNewMessageInIntercomWidget();
  }

  get howWeAssignSeatsLink() {
    return howWeAssignSeatsLink(this.appService.app, this.customerService.customer);
  }

  get seatAssignmentTooltipContent() {
    if (this.appService.app.canUsePerProductPricingFlow) {
      return this.SEATS_ASSIGNMENT_TOOLTIP.pppWithSeats;
    }
    return this.SEATS_ASSIGNMENT_TOOLTIP.other;
  }

  @action onTabSelectionChange(selectedValue: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'stripe_migrations',
      place: selectedValue,
      object: 'tab_changes',
    });

    document
      .querySelector('.migration-details-container')
      ?.scrollTo({ top: 0, behavior: 'smooth' });

    if (this.args.newSettings) {
      this.args.onTabSelectionChange!(selectedValue);
    } else {
      this.oldSettingsSelectedTab = selectedValue;
    }
  }

  willDestroy(): void {
    super.willDestroy();

    this.router.off('routeWillChange', this.warnIfUnsavedChangesCallback);
  }

  getDefaultCorePlanSelection(planIds: number[]) {
    let corePlan = planIds.find((planId) => PRICING_5_X_CORE_PLANS.includes(String(planId)));

    return corePlan ? String(corePlan) : '';
  }

  get editPlanCopilotSeatsByPlanId() {
    return {
      [PRICING_5_X_CORE_ESSENTIAL_ID]: this.getCopilotSeatInfo(
        this.args.pricing5EstimatesForEditPackage.essentialWithCopilotEstimate,
      ),
      [PRICING_5_X_CORE_ADVANCED_ID]: this.getCopilotSeatInfo(
        this.args.pricing5EstimatesForEditPackage.advancedWithCopilotEstimate,
      ),
      [PRICING_5_X_CORE_EXPERT_ID]: this.getCopilotSeatInfo(
        this.args.pricing5EstimatesForEditPackage.expertWithCopilotEstimate,
      ),
    };
  }

  getAddonPlanIds(planIds: number[]) {
    let addonPlanIds = planIds
      .filter((planId) => PRICING_5_X_ADDON_PLAN_IDS.includes(String(planId)))
      .map((planId) => String(planId));

    // If there is copilot usage, add it here too (currently customers can't choose/remove it yet)
    let selectedCorePlan = this.getDefaultCorePlanSelection(planIds);
    if (selectedCorePlan) {
      let copilotSeatCount = this.getCopilotSeatInfo(
        this.copilotPlanToPriceMapping[selectedCorePlan],
      ).seatCount;

      if (copilotSeatCount > 0 && !addonPlanIds.includes(FIN_AI_COPILOT_BASE_ID)) {
        addonPlanIds.push(FIN_AI_COPILOT_BASE_ID);
      }
    }

    return addonPlanIds;
  }

  get copilotPlanToPriceMapping(): CopilotPlanToPriceMap {
    return {
      [PRICING_5_X_CORE_ESSENTIAL_ID]: this.args.pricing5Estimates.essentialWithCopilotEstimate,
      [PRICING_5_X_CORE_ADVANCED_ID]: this.args.pricing5Estimates.advancedWithCopilotEstimate,
      [PRICING_5_X_CORE_EXPERT_ID]: this.args.pricing5Estimates.expertWithCopilotEstimate,
    };
  }

  get copilotSeatPrice() {
    return this.getCopilotSeatInfo(this.copilotPlanToPriceMapping[this.planSelection]).pricePerSeat;
  }

  private getCopilotSeatInfo(price: Price): CopilotSeatInfo {
    let charge = price.breakdown
      .find((bd: Breakdown) => bd.plan_id!.toString() === FIN_AI_COPILOT_BASE_ID)
      .charges.find((charge: Charge) => charge.pricing_metric === Metric.copilot_seat_count);

    return { seatCount: charge.actual_usage, pricePerSeat: charge.per_unit_price / 100 };
  }

  setInitialAdminSeatMapping() {
    if (isPresent(this.args.migrationSeatConfiguration)) {
      this.initialAdminSeatMapping = this.args.migrationSeatConfiguration.adminSeatMappings.reduce(
        (acc: { [key: string]: string[] }, mapping) => {
          acc[mapping.adminId] = mapping.seatTypes;
          return acc;
        },
        {},
      );
    }
  }

  @action
  private async warnIfUnsavedChangesCallback(transition: Transition) {
    if (transition.from?.name !== transition.to.name) {
      if (
        this.router.currentRouteName !== transition.to.name &&
        this.hasUnsavedChanges &&
        this.selectedTab !== MIGRATION_OVERVIEW_TAB
      ) {
        transition.abort();
        if (await this.showUnsavedChangesConfirmation()) {
          this.rollbackChanges();
          transition.retry();
        }
      }
    }
  }

  @action
  private async warnIfUnsavedChangesOnTabChangeCallback(transition: Transition) {
    let newTab = this.selectedTab;
    if (transition.from?.name === transition.to.name) {
      if (this.hasUnsavedChanges && newTab !== EDIT_PACKAGE_TAB) {
        this.args.onTabSelectionChange!(EDIT_PACKAGE_TAB);
        if (await this.showUnsavedChangesConfirmation()) {
          this.rollbackChanges();
          this.args.onTabSelectionChange!(newTab || EDIT_PACKAGE_TAB);
        }
      }
    }
  }

  private async showUnsavedChangesConfirmation(): Promise<boolean> {
    return this.intercomConfirmService.confirm({
      title: this.intl.t('billing.migrations.unsaved_changes_modal.title'),
      body: this.intl.t('billing.migrations.unsaved_changes_modal.description'),
      confirmButtonText: this.intl.t('billing.migrations.unsaved_changes_modal.continue'),
      cancelButtonText: this.intl.t('billing.migrations.unsaved_changes_modal.cancel'),
      primaryButtonType: 'primary-destructive',
    });
  }

  @action
  rollbackChanges() {
    this.planSelection = this.initialPlanSelection;
    this.addonPlanIds = this.initialAddonPlanIds;
    if (isPresent(this.args.migrationSeatConfiguration)) {
      this.args.migrationSeatConfiguration.adminSeatMappings.forEach((mapping) => {
        mapping.seatTypes = this.initialAdminSeatMapping[mapping.adminId] || [];
      });
    }

    this.setHasUnsavedChanges(false);
    this.onMigrationSeatConfigurationApplyChanges({
      core_seat_count: Number(this.initialMappingSeatCount(CORE)),
      copilot_seat_count: Number(this.initialMappingSeatCount(COPILOT)),
    });
  }

  private initialMappingSeatCount(seatType: string): number {
    return Object.values(this.initialAdminSeatMapping).reduce(
      (count, seatTypes) => count + (seatTypes.includes(seatType) ? 1 : 0),
      0,
    );
  }

  @action
  setHasUnsavedChanges(hasUnsavedChanges: boolean) {
    this.hasUnsavedChanges = hasUnsavedChanges;
  }

  @action
  async onMigrationSeatConfigurationApplyChanges(seatCounts: {
    core_seat_count: number;
    copilot_seat_count: number;
  }) {
    this.hasAppliedSeatChanges = true;
    await this.args.onMigrationSeatConfigurationSave(seatCounts);
  }

  @action
  setPlanSelection(planId: string) {
    this.planSelection = planId;
    this.setHasUnsavedChanges(true);
  }

  @action
  setAddonPlanIds(addonPlanIds: string[]) {
    this.addonPlanIds = addonPlanIds;
    this.setHasUnsavedChanges(true);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::MigrationDetails': typeof BillingMigrationMigrationDetailsComponent;
  }
}
