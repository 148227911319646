/* import __COLOCATED_TEMPLATE__ from './capability-grid.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',
  appService: service(),
  sidebarLabel: 'Add to conversation details',
  conversationsLabel: 'Send in conversations',
  everywhereLabel: 'Use in conversations or details',

  filterValue: computed('inboxCapability', function () {
    let inboxCapability = this.inboxCapability;
    if (inboxCapability === 'sidebar') {
      return this.sidebarLabel;
    }
    if (inboxCapability === 'conversations') {
      return this.conversationsLabel;
    }
    return this.everywhereLabel;
  }),

  init() {
    this._super(...arguments);

    this.setProperties({
      buttonParams: { subtle: true },
      filterGroupList: [
        {
          items: [
            {
              inboxCapability: null,
              label: this.everywhereLabel,
              analyticObject: 'inbox_capabilities_filter_all',
              component: 'appstore/listing/capability-filter-item',
            },
            {
              inboxCapability: 'sidebar',
              label: this.sidebarLabel,
              analyticObject: 'inbox_capabilities_filter_sidebar',
              component: 'appstore/listing/capability-filter-item',
            },
            {
              inboxCapability: 'conversations',
              label: this.conversationsLabel,
              analyticObject: 'inbox_capabilities_filter_conversations',
              component: 'appstore/listing/capability-filter-item',
            },
          ],
        },
      ],
    });
  },

  _inboxAppPackages: computed('inboxCapability', 'appPackages.[]', function () {
    let appPackages = this.appPackages;

    switch (this.inboxCapability) {
      case 'conversations':
        return appPackages.filter((appPackage) =>
          appPackage.get('capabilities.works_with_inbox_conversations'),
        );
      case 'sidebar':
        return appPackages.filter((appPackage) =>
          appPackage.get('capabilities.works_with_inbox_sidebar'),
        );
      default:
        return appPackages;
    }
  }),

  appPackageGroup: computed('appPackages.[]', 'capability', '_inboxAppPackages', function () {
    let { name } = this.capability;
    let appPackages = name === 'Help Desk' ? this._inboxAppPackages : this.appPackages;
    return { name, appPackages };
  }),

  onInboxCapabilityChange: action(function (item) {
    this.set('inboxCapability', item.inboxCapability);
  }),
});
