/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { alias, not, equal } from '@ember/object/computed';
import Component from '@ember/component';

export default Component.extend({
  pageId: alias('settings.statuspagePageId'),
  isNotValidPageId: not('isValidPageId'),
  isValidPageId: equal('pageId.length', 12),
});
