/* import __COLOCATED_TEMPLATE__ from './program-overview-card.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import templateOnlyComponent from '@ember/component/template-only';
import { type ProgramStep } from 'embercom/components/billing/summary/early-stage/program-overview-hero';

interface Signature {
  Args: {
    progressValue: number;
    dotLeftPosition: string;
    earlyStageProgramSteps: (ProgramStep | null)[];
    shouldShowDot: boolean;
  };
}

const ProgramOverviewCard = templateOnlyComponent<Signature>();
export default ProgramOverviewCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::EarlyStage::ProgramOverviewCard': typeof ProgramOverviewCard;
    'billing/summary/early-stage/program-overview-card': typeof ProgramOverviewCard;
  }
}
