/* import __COLOCATED_TEMPLATE__ from './invoice-history-component.hbs'; */
/* RESPONSIBLE TEAM: team-billing */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import moment from 'moment-timezone';

export default class InvoiceHistoryComponent extends Component {
  @service appService;

  @tracked showPaymentModal = null;
  @tracked activeInvoice = null;
  @tracked isPaymentProhibited = true;
  @tracked paymentPayload = null;

  @tracked showFailedPaymentPopup = true;

  get failedPaymentForInvoice() {
    let { filteredInvoices, unpaidInvoice } = this.args;

    return filteredInvoices.any((invoice) => invoice.number === unpaidInvoice);
  }

  get unpaidInvoice() {
    let { filteredInvoices, unpaidInvoice } = this.args;

    return filteredInvoices.filter((invoice) => invoice.number === unpaidInvoice)?.firstObject;
  }

  get nextAttempt() {
    let { nextAttempt } = this.args;

    return moment(nextAttempt, 'YYYY-MM-DD').format('MMMM Do, YYYY');
  }

  get finalAttempt() {
    let { finalAttempt } = this.args;

    return finalAttempt === 'true';
  }

  get canPayManually() {
    return this.showPaymentModal;
  }

  get app() {
    return this.appService.app;
  }

  @action
  setPaymentPayload(paymentPayload) {
    this.paymentPayload = paymentPayload;
  }
}
