/* import __COLOCATED_TEMPLATE__ from './content-block.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  site: HelpCenterSite;
}

export default class ContentBlock extends Component<Args> {
  @action
  initializeLocalisedContent(localeId: string) {
    this.args.site.initializeLocalisedContent(localeId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::NewStyling::Layout::ContentBlock': typeof ContentBlock;
  }
}
