/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { replyTypeOptions } from 'embercom/models/chats/chat';
import { sentAsOptions } from 'embercom/models/data/outbound/constants';
import { action } from '@ember/object';
import { preferredDevices } from 'embercom/models/data/outbound/constants';

export default class Sidebar extends Component {
  replyTypeOptions = replyTypeOptions;
  sentAsOptions = sentAsOptions;

  get showPushMessageToggle() {
    return (
      this.args.chat.preferredDevices.includes(preferredDevices.ios) ||
      this.args.chat.preferredDevices.includes(preferredDevices.android)
    );
  }

  @action
  updateReplyType(replyOption) {
    this.args.chat.replyType = replyOption;

    let followUpActions = this.args.chat.localizedChatContents.firstObject.followUpActions;
    followUpActions.answerBotEnabled = false;
    followUpActions.followUpReply = null;
    followUpActions.leadQualificationEnabled = false;
  }

  @action
  changePushNotificationOption() {
    this.args.chat.sendPushNotification = !this.args.chat.sendPushNotification;
  }
}
