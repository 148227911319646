/* import __COLOCATED_TEMPLATE__ from './go-to-help-center.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly, reads } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),

  url: computed('site.url', 'site.locale', function () {
    return `${this.get('site.url')}/${this.get('site.locale')}`;
  }),
  isVisible: reads('site.originalWebsiteTurnedOn'),
});
