/* import __COLOCATED_TEMPLATE__ from './view-all-pricing-tiers.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Charge from 'embercom/models/billing/price/charge';
import type IntlService from 'ember-intl/addon/services/intl';
interface Args {
  charge: Charge;
}

export default class ViewAllPricingTiers extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;
  get charge() {
    return this.args.charge;
  }

  get blockSize() {
    return this.charge.block_size;
  }

  get tiers() {
    return this.charge.tiers;
  }

  get maxTier() {
    return this.charge.tiers.lastObject;
  }

  get secondLastTier() {
    return this.charge.tiers[this.charge.tiers.length - 2];
  }

  get perUnitMetricName() {
    let translatedString = `billing.summary.card-elements.view-all-pricing-tiers.per-unit-metric-names.${this.charge.pricing_metric}`;

    return this.intl.t(translatedString, {
      default: 'billing.summary.card-elements.view-all-pricing-tiers.per-unit-metric-names.default',
      count: this.intl.formatNumber(this.blockSize),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Summary::CardElements::ViewAllPricingTiers': typeof ViewAllPricingTiers;
  }
}
