/* import __COLOCATED_TEMPLATE__ from './custom-usage-highcharts-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Highcharts from 'highcharts';
import { initializeHighcharts } from 'embercom/components/reporting/flexible/highcharts';
import { type Metric } from 'embercom/models/data/pricing/metric-types';

initializeHighcharts();

interface Args {
  content: $TSFixMe;
  chartOptions: $TSFixMe;
  metric: Metric;
  target: $TSFixMe;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class CustomUsageHighchartsComponent extends Component<Signature> {
  @tracked declare chart: Highcharts.Chart;
  @tracked declare attributes: $TSFixMe;

  @action
  draw(element: Highcharts.Options) {
    let series =
      this.args.content && this.args.content.length
        ? this.args.content
        : [{ id: 'noData', data: 0, color: '#aaa', showInLegend: false }];
    this.chart = Highcharts.chart(element, { ...this.args.chartOptions, series });
  }

  @action
  update(element: Highcharts.Options) {
    this.draw(element);
    // TODO: Right now we are re-creating Highcharts on every render.
    // A more performant way would be to create a Highcharts instance once
    // and then update its series and config if needs be on this update function.
    // This requires a certain type of refactoring and knowledge that we will employ
    // later once we have more examples.
  }

  @action
  hideActiveTooltips() {
    let tooltip = this.chart.tooltip;

    if (tooltip) {
      tooltip.hide(0);
    }
  }

  willDestroy() {
    this.chart.destroy();
    super.willDestroy();
  }

  get getChartAttributes() {
    return this.attributes;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::CustomUsageHighchartsComponent': typeof CustomUsageHighchartsComponent;
    'billing/usage/custom-usage-highcharts-component': typeof CustomUsageHighchartsComponent;
  }
}
