/* import __COLOCATED_TEMPLATE__ from './conversation-list.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';

export interface Args {
  companyId: string;
  type: ListType;
  showStatusFilter: boolean;
}

type ListType = 'conversation' | 'ticket';
type Status = 'opened' | 'snoozed' | 'all';

type LoadConversationsResponse = {
  totalCount: number;
  conversations: any;
  nextPage: string | number | null;
};

type LoadConversationsParams = {
  app_id: string;
  company_id: string;
  sort_order: 'desc' | 'asc';
  status: Status;
  page_from?: string | number;
  type: ListType;
};

export default class ConversationList extends Component<Args> {
  @service declare appService: any;
  @service intl!: IntlService;

  @tracked totalCount = 0;
  @tracked status: Status = 'all';
  @tracked conversations: Array<any> = [];
  @tracked nextPage: string | number | undefined;

  conversationLoader;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.conversationLoader = owner.factoryFor('customClass:inbox/conversationLoader').create();
    taskFor(this.loadConversationsTask).perform();
  }

  get noResultsText() {
    if (this.args.type === 'ticket') {
      return this.intl.t('apps.app.companies.tickets.no-tickets');
    } else if (this.status === 'opened') {
      return this.intl.t('apps.app.companies.conversations.no-open-conversations');
    } else if (this.status === 'snoozed') {
      return this.intl.t('apps.app.companies.conversations.no-snoozed-conversations');
    } else {
      return this.intl.t('apps.app.companies.conversations.no-conversations');
    }
  }

  get statusDropdownTitle() {
    if (this.status === 'opened') {
      return this.intl.t('apps.app.companies.conversations.open');
    } else if (this.status === 'snoozed') {
      return this.intl.t('apps.app.companies.conversations.snoozed');
    } else {
      return this.intl.t('apps.app.companies.conversations.all');
    }
  }

  @task({ enqueue: true })
  *loadConversationsTask() {
    if (!this.nextPage && this.conversations.length > 0) {
      return;
    }

    let params: LoadConversationsParams = {
      app_id: this.appService.app.id,
      company_id: this.args.companyId,
      sort_order: 'desc',
      status: this.status,
      type: this.args.type,
    };

    if (this.nextPage) {
      params.page_from = this.nextPage;
    }

    let result = (yield this.conversationLoader.loadConversations(
      params,
    )) as LoadConversationsResponse;
    this.totalCount = result.totalCount;
    this.conversations.pushObjects(result.conversations);
    this.nextPage = result.nextPage || undefined;
  }

  @action
  changeStatus(status: Status) {
    taskFor(this.loadConversationsTask).cancelAll();
    this.status = status;
    this.conversations = [];
    this.totalCount = 0;
    this.nextPage = '';
    taskFor(this.loadConversationsTask).perform();
  }

  @action
  loadNextPage() {
    if (this.conversations.length < this.totalCount) {
      taskFor(this.loadConversationsTask).perform();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Companies::Profile::Conversations::ConversationList': typeof ConversationList;
    'companies/profile/conversations/conversation-list': typeof ConversationList;
  }
}
