/* import __COLOCATED_TEMPLATE__ from './end-user-section.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import Component from '@glimmer/component';
import { restartableTask } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';

// Twilio does not give better structured data on how to tell if a field is a dropdown
// We are using this string to hackishly find the options for the dropdown
// eslint-disable-next-line @intercom/intercom/no-bare-strings
const TWILIO_REGULATION_DROPDOWN_FIELD_DESCRIPTION = 'Choose any one of the following values:';

export interface EndUserSectionArgs {
  bundleSid: string;
  endUserRequirement: any;
  endUser: any;
  readOnly?: boolean;
  onEndUserCreated: (sid: string) => void;
  onEndUserCreationFailed: () => void;
}

export default class EndUserSection extends Component<EndUserSectionArgs> {
  @service declare appService: any;
  @service notificationsService: any;
  @service intl: any;
  @service declare router: RouterService;

  get isSms() {
    return this.router.currentRouteName.includes('settings.channels.sms.phone-regulatory-bundles');
  }

  @restartableTask
  *nextSection() {
    try {
      let extraFields: any = {};
      if (this.hasBusinessIdentity) {
        extraFields['business_identity'] = 'DIRECT_CUSTOMER';
      }
      if (this.hasIsSubassigned) {
        extraFields['is_subassigned'] = 'NO';
      }
      let result: { end_user_sid: string } = yield post(
        '/ember/calling_settings/create_regulatory_end_user_for_bundle',
        {
          app_id: this.appService.app.id,
          bundle_sid: this.args.bundleSid,
          end_user: { ...this.args.endUser, ...extraFields },
          for_sms: this.isSms,
        },
      );
      if (!result.end_user_sid) {
        throw new Error('End user creation failed');
      }

      this.args.onEndUserCreated(result.end_user_sid);
    } catch (e) {
      this.args.onEndUserCreationFailed();
      this.notificationsService.notifyError(
        this.intl.t('calling.settings.phone-regulatory-bundle.end-user-section-error'),
      );
    }
  }

  get hasBusinessIdentity() {
    return this.args.endUserRequirement.detailed_fields.some(
      (field: any) => field.machine_name === 'business_identity',
    );
  }

  get hasIsSubassigned() {
    return this.args.endUserRequirement.detailed_fields.some(
      (field: any) => field.machine_name === 'is_subassigned',
    );
  }

  get detailedFields() {
    return this.args.endUserRequirement.detailed_fields
      .filter(
        (field: any) =>
          field.machine_name !== 'business_identity' && field.machine_name !== 'is_subassigned',
      )
      .map((field: any) => {
        if (this.isDropdownField(field)) {
          return {
            ...field,
            options: this.dropdownFieldOptions(field),
          };
        }

        return field;
      });
  }

  isDropdownField(field: any) {
    return field.description?.startsWith(TWILIO_REGULATION_DROPDOWN_FIELD_DESCRIPTION);
  }

  @action
  dropdownFieldOptions(field: any) {
    let options = this.findSubstringInBrackets(field.description);
    if (!options) {
      return [];
    }

    return options
      .split(',')
      .map((option: string) => ({ text: option.trim(), value: option.trim() }));
  }

  findSubstringInBrackets(str: string) {
    let match = str.match(/\[(.*?)\]/);
    return match ? match[1] : null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhoneRegulatoryBundle::EndUserSection': typeof EndUserSection;
    'calling/settings/phone-regulatory-bundle/end-user-section': typeof EndUserSection;
  }
}
