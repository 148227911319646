/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Component.extend({
  notificationsService: service(),
  redirectService: service(),
  appService: service(),
  regionService: service(),
  appId: readOnly('appService.app.id'),
  adminId: readOnly('appService.app.currentAdmin.id'),

  baseUrl: readOnly('regionService.messengerAppsBaseURL'),
  zoomAuthRedirectUrl: computed('adminId', 'appId', 'baseUrl', function () {
    return `${this.baseUrl}/webinar/zoom_oauth/auth?app_id_code=${this.appId}&admin_id=${this.adminId}`;
  }),
  connectToZoom: task(function* () {
    try {
      let redirectUrl = yield this.generateRedirectUrl.perform(this.zoomAuthRedirectUrl, false);
      this.redirectService.redirect(redirectUrl);
    } catch (e) {
      this.notificationsService.notifyError('Failed to connect to Zoom');
    }
  }).drop(),
  actions: {
    revokeAccess() {
      this.set('settings.hasIntegratedProvider', false);
      this.update.perform(this.settings);
    },
  },
});
