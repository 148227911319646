/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { type UploadHelper } from 'embercom/objects/media/media-helper';
import { tracked } from '@glimmer/tracking';
import EmberObject, { action } from '@ember/object';
import type Admin from 'embercom/models/admin';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type ArticleSyncSetting from 'embercom/models/articles/article-sync-setting';
import { dropTask } from 'ember-concurrency-decorators';
import { PreviewType } from './react-preview';
import { inject as service } from '@ember/service';
import {
  type SectionId,
  type TabId,
} from 'embercom/services/help-center-setting-navigation-service';
import type HelpCenterSettingNavigationService from 'embercom/services/help-center-setting-navigation-service';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import { postMessageToHCIframe, PreviewMessageType } from './helpers/help-center-preview-helper';

export class HelpCenterSettingNavigationHelper extends EmberObject {
  navigationService: HelpCenterSettingNavigationService | undefined;

  get currentTabId() {
    return this.navigationService?.currentTabId ?? '';
  }

  get currentSectionId() {
    return this.navigationService?.currentSectionId ?? '';
  }

  navigate(sectionId: SectionId, tabId?: TabId) {
    this.navigationService?.navigate(sectionId, tabId);
  }

  updateContainer(container: HTMLElement) {
    this.navigationService?.updateContainer(container);
  }

  updateLastScrollPosition(scrollPosition: number) {
    this.navigationService?.updateLastScrollPosition(scrollPosition);
  }

  navigateTab(tabId: TabId) {
    this.navigationService?.navigateTab(tabId);
  }
}

export class TlsSelectionHelper extends EmberObject {
  @tracked currentSelection = 0;
  initialSelection = 0;

  initialize(newSelection: number): void {
    this.initialSelection = newSelection;
    this.currentSelection = newSelection;
  }

  reset(): void {
    this.currentSelection = this.initialSelection;
  }

  get hasUnsavedChanges(): boolean {
    return this.currentSelection !== this.initialSelection;
  }
}

interface Args {
  app: any;
  admin: Admin;
  site: HelpCenterSite;
  customDomain: any;
  isManagedCustomDomainEnabled: boolean;
  cleanCustomDomainAndSave?: Function;
  createCustomDomain?: Function;
  saveSite?: Function;
  isSyncing?: boolean;
  zendeskSyncSetting?: ArticleSyncSetting;
  configureZendeskSync?: Function;
  triggerManualSync?: Function;
  pauseRegularSync?: Function;
  removeRegularSync?: Function;
  uploadHelper: UploadHelper;
  sectionId?: string;
  refreshIsRunning?: boolean;
  refreshDomain?: Function;
  saveIsRunning?: boolean;
  newSettings?: boolean;
  tlsSelectionHelper?: TlsSelectionHelper;
}

export default class Main extends Component<Args> {
  @service intercomEventService: any;
  @service declare appService: any;
  @service declare helpCenterSettingNavigationService: HelpCenterSettingNavigationService;
  @service declare guideLibraryService: GuideLibraryService;

  tlsSelectionHelper: TlsSelectionHelper =
    this.args.tlsSelectionHelper || TlsSelectionHelper.create();
  navigationHelper: HelpCenterSettingNavigationHelper;
  @tracked previewType: PreviewType = PreviewType.Homepage;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.tlsSelectionHelper.initialize(this.determineSelection);
    this.navigationHelper = HelpCenterSettingNavigationHelper.create({
      navigationService: this.helpCenterSettingNavigationService,
    });

    if (this.args.sectionId) {
      let sectionId = this.args.sectionId as SectionId;
      this.navigationHelper.navigate(sectionId);
    }
  }

  @dropTask
  *doSave() {
    if (
      !this.args.cleanCustomDomainAndSave ||
      !this.args.createCustomDomain ||
      !this.args.saveSite
    ) {
      return;
    }
    if (this.shouldUpdateCustomDomain) {
      if (this.tlsSelectionHelper.currentSelection > 0) {
        yield this.args.cleanCustomDomainAndSave();
      } else {
        yield this.args.createCustomDomain();
      }
    } else {
      this.args.saveSite();
    }
    this.tlsSelectionHelper.initialize(this.tlsSelectionHelper.currentSelection);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'set_live',
      place: 'app.article.site.settings',
      context: 'articles.settings.save_and_set_live_button',
      section: 'header.main',
    });
    let stepIdentifier = 'guide_library_foundational_steps_publish_help_center';
    yield this.guideLibraryService.markStepCompleted(stepIdentifier);
  }

  get determineSelection(): number {
    if (this.args.customDomain) {
      return 0;
    } else if (this.args.site.customDomainUsesSsl) {
      return 2;
    } else {
      return 1;
    }
  }

  get shouldUpdateCustomDomain() {
    let protocolChange = this.tlsSelectionHelper.hasUnsavedChanges;
    let customDomainChange = !!this.args.site.changedAttributes()['customDomain'];
    return protocolChange || customDomainChange;
  }

  get paywallFeatureKey() {
    if (this.args.site.isDefault) {
      return 'help_center';
    } else if (this.args.app.showMultiHelpCenterPaywalling) {
      return 'multi_help_center';
    }

    return '';
  }

  @action
  setPreviewType(previewType: PreviewType) {
    if (previewType === undefined) {
      return;
    }

    // Don't change preview page when editing footer
    if (previewType === PreviewType.Footer) {
      this.scrollIframe('footer');
    } else {
      this.scrollIframe('header');
      this.previewType = previewType;
    }
  }

  private scrollIframe(location: 'header' | 'footer') {
    postMessageToHCIframe({
      type: PreviewMessageType.ScrollTo,
      location,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::Main': typeof Main;
    'articles/site/settings/main': typeof Main;
  }
}
