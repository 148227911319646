/* import __COLOCATED_TEMPLATE__ from './graduation-picker-sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import {
  CORE_SALES_LED_PLAN_IDS,
  CORE_SELF_SERVE_PLAN_IDS,
  CORE_STARTER_BASE_ID,
  VBP_SALES_LED_ADD_ONS,
  VBP_SELF_SERVE_ADD_ONS,
  PRICING_5_X_ADDON_PLAN_IDS,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  FIN_AI_COPILOT_BASE_ID,
  PRICING_5_X_PLAN_IDS,
} from 'embercom/lib/billing';

export default class GraduationPickerSidebar extends Component {
  @service notificationsService;
  @service appService;
  @service customerService;
  @service intl;

  get url() {
    let url = `${window.location.protocol}//${window.location.host}/a/apps/${this.appService.app.id}/billing/summary?graduation`;
    return url;
  }

  get earlyStageGraduation() {
    return this.args.earlyStageGraduation;
  }

  get earlyStageDescription() {
    return this.intl.t(
      `billing.summary.graduation-picker-sidebar.early-stage-description-y-${this.nextStepYear}`,
    );
  }

  get nextStepYear() {
    return this.earlyStageGraduation.nextStepYearNumber;
  }

  get planPriceForRemovedPlan() {
    if (this.args.graduatingPlan?.idAsNumber) {
      return this.customerService.getPriceFromPlanIds(
        [this.args.graduatingPlan.idAsNumber],
        this.earlyStageGraduation?.priceSetIdentifier,
      );
    }
  }

  get planPriceForSelectedPlan() {
    if (this.args.selectedPlan?.idAsNumber) {
      return this.customerService.getPriceFromPlanIds(
        [this.args.selectedPlan.idAsNumber],
        this.earlyStageGraduation?.graduationPricingModel,
      );
    }
  }

  get priceForSelectedPlan() {
    if (this.args.selectedPlan?.id === FIN_AI_COPILOT_BASE_ID) {
      return this.planPriceForSelectedPlan?.formattedPerUnitPrice;
    }
    return this.planPriceForSelectedPlan?.formattedStartingPrice;
  }

  get isEarlyStage() {
    return !!this.args.selectedPlan?.isEarlyStage;
  }

  get isSalesPlan() {
    return isEmpty(this.args.selectedPlan)
      ? false
      : [...CORE_SALES_LED_PLAN_IDS, ...VBP_SALES_LED_ADD_ONS].includes(this.args.selectedPlan.id);
  }

  get isDowngradeToSelfServe() {
    return (
      (CORE_SALES_LED_PLAN_IDS.includes(this.args.recommendedPlan?.id) ||
        this.args.graduatingPlan?.id > this.args.selectedPlan.id ||
        this.args.recommendedPlan?.id > this.args.selectedPlan.id) &&
      CORE_SELF_SERVE_PLAN_IDS.includes(this.args.selectedPlan.id)
    );
  }

  get isCoreSelfServe() {
    return isEmpty(this.args.selectedPlan)
      ? false
      : [
          ...CORE_SELF_SERVE_PLAN_IDS,
          CORE_STARTER_BASE_ID,
          PRICING_5_X_CORE_ESSENTIAL_ID,
          PRICING_5_X_CORE_ADVANCED_ID,
        ].includes(this.args.selectedPlan.id);
  }

  get isSelfServeAddon() {
    return [...PRICING_5_X_ADDON_PLAN_IDS, ...VBP_SELF_SERVE_ADD_ONS].includes(
      this.args.selectedPlan?.id,
    );
  }

  get isValidEmptySelection() {
    return this.args.allowEmptySelection && !this.args.selectedPlan;
  }

  get pricingPageUrl() {
    if (this.isCoreSelfServe && PRICING_5_X_PLAN_IDS.includes(this.args.selectedPlan.id)) {
      return 'https://www.intercom.com/pricing';
    }
    return this.appService.app.pricingPageUrl;
  }
}
