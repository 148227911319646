/* import __COLOCATED_TEMPLATE__ from './left-side-nav.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import Article from 'embercom/models/article';
import { ARTICLES_SURVEY_ID } from 'embercom/lib/ai-assist';
import { isBlank } from '@ember/utils';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type HelpCenterSite from 'embercom/models/help-center-site';

const HELP_CENTER_SETTINGS_URL = '/articles/site/settings/';
const HELP_CENTER_COLLECTIONS_URL = '/articles/site/collections/site-collection/';
const URLS_TO_OPEN_SECTION_ON = [HELP_CENTER_SETTINGS_URL, HELP_CENTER_COLLECTIONS_URL];

interface Args {
  toggleSearch: () => void;
  showSearch: () => void;
  transitionToSettingsPage: () => void;
}

export default class LeftSideNav extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare helpCenterService: any;
  @service declare intercomEventService: any;

  @tracked showSavedViews = true;
  @tracked showDeleteConfirmationModal = false;
  @tracked helpCenterToDelete = null;
  @tracked shouldShowSavedViews = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    // show expanded saved views panel by default if we have views
    this.shouldShowSavedViews = this.hasSavedViews;

    // Automatically open the settings section if we only have a single
    // help center site
    if (this.hasSingleSite) {
      this.site.set('isHelpCenterSectionOpen', true);
    }

    Article.updateArticleCountsForApp(this.app);
    this._initialiseSettingsSubmenus();
  }

  get hasSingleSite() {
    return this.sites?.length === 1 && !!this.site;
  }

  get isOnArticlesRoute() {
    let routeRegex = /^apps\.app\.articles\.articles/;
    return routeRegex.test(this.router.currentRouteName);
  }

  get isOnSiteRoute() {
    let routeRegex = /^apps\.app\.articles\.site/;
    return routeRegex.test(this.router.currentRouteName);
  }

  get isOnAboutRoute() {
    let routeRegex = /^apps\.app\.articles\.about/;
    return routeRegex.test(this.router.currentRouteName);
  }

  get app() {
    return this.appService.app;
  }

  get site() {
    return this.helpCenterService.site;
  }

  get sites(): HelpCenterSite[] {
    return this.helpCenterService.sites;
  }

  get hasLiveSites() {
    return this.helpCenterService.allLiveSites.length > 0;
  }

  get helpCenterOn() {
    return !this.site.turnedOff;
  }

  get hasSavedViews() {
    return (this.store.peekAll('content-service/saved-view').length as number) > 0;
  }

  get canSeeMultipleHelpCenterSurvey() {
    return this.sites?.length > 1;
  }

  get idFromURL() {
    let pathname = this.router.currentURL.split('?')[0];

    let onCorrectUrl = URLS_TO_OPEN_SECTION_ON.some((url) => pathname.includes(url));
    if (!onCorrectUrl) {
      return null;
    }

    let id = pathname.split('/').lastObject;
    if (isNaN(Number(id)) || isBlank(id)) {
      return null;
    }
    return id;
  }

  get getPublishedQueryParams() {
    let request = { conditions: { status: 'published' } };
    return JSON.stringify(request);
  }

  get getDraftQueryParams() {
    let request = { conditions: { status: 'draft' } };
    return JSON.stringify(request);
  }

  _initialiseSettingsSubmenus() {
    let helpCenterId = this.idFromURL;
    if (!helpCenterId) {
      return;
    }
    this.sites.forEach((site: any) => {
      site.isHelpCenterSectionOpen = site.id === helpCenterId;
    });
  }

  @action
  toggleShowSavedViews() {
    this.shouldShowSavedViews = this.hasSavedViews && !this.shouldShowSavedViews;
  }

  @action
  trackEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'start_guide_clicked',
      context: 'from_inbox_submenu',
      place: 'start_guide',
    });
  }

  @action
  triggerAiAssistFeedbackSurvey() {
    window.Intercom('startSurvey', ARTICLES_SURVEY_ID);
  }

  @action
  updateShowSavedViews() {
    this.showSavedViews = !this.showSavedViews;
  }

  @action
  deleteHelpCenter(helpCenter: any) {
    this.showDeleteConfirmationModal = true;
    this.helpCenterToDelete = helpCenter;
  }

  @action
  toggleHelpCenterSection(helpCenter: any) {
    helpCenter.isHelpCenterSectionOpen = !helpCenter.isHelpCenterSectionOpen;
  }

  @action
  async confirmDeleteHelpCenter() {
    if (!this.helpCenterToDelete) {
      return;
    }
    await this.helpCenterService.deleteHelpCenter.perform(this.helpCenterToDelete);
    this.helpCenterToDelete = null;
    this.showDeleteConfirmationModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Shared::LeftSideNav': typeof LeftSideNav;
    'articles/shared/left-side-nav': typeof LeftSideNav;
  }
}
