/* import __COLOCATED_TEMPLATE__ from './messenger-transition.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class MessengerTransition extends Component {
  @tracked selectedIntegration = {};

  constructor() {
    super(...arguments);
    this.selectedIntegration =
      this.whatsappIntegrations?.find(
        (integration) => integration.id === this.args.settings?.messengerIntegrationId,
      ) || this.whatsappIntegrations?.firstObject;
  }

  get whatsappIntegrations() {
    return this.args.whatsappIntegrations;
  }

  get integrationsDropdown() {
    let dropdownItems = this.whatsappIntegrations.map((integration) => {
      return {
        text: integration.displayString,
        value: integration,
        isSelected: this.selectedIntegration?.id === integration.id,
      };
    });

    return [
      {
        hasItemsMarkableAsSelected: true,
        items: dropdownItems,
      },
    ];
  }

  @action
  selectIntegration(integration) {
    this.selectedIntegration = integration;
    this.changeMessengerIntegration.perform();
  }

  @task({ restartable: true })
  *toggleMessengerIntegration() {
    this.args.settings.toggleProperty('messengerIntegration');
    if (this.messengerIntegrationOn) {
      this.args.settings.set('messengerIntegrationId', this.selectedIntegration.id);
    } else {
      this.args.settings.set('messengerIntegrationId', null);
    }
    yield this.args.settings.save();
  }

  @task({ restartable: true })
  *changeMessengerIntegration() {
    if (this.messengerIntegrationOn) {
      this.args.settings.set('messengerIntegrationId', this.selectedIntegration.id);
    }
    yield this.args.settings.save();
  }

  get messengerIntegrationOn() {
    return this.args.settings.messengerIntegration;
  }
}
