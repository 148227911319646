/* import __COLOCATED_TEMPLATE__ from './notification-rule-list-item.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { isEmpty } from '@ember/utils';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import moment from 'moment-timezone';
import { ternary } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  tagName: 'tr',
  owner: readOnly('notificationRule.owner'),
  channel: readOnly('notificationRule.channel'),
  notificationTypes: readOnly('notificationRule.notificationTypes'),

  attributeBindings: ['data-test-notification-rule'],
  'data-test-notification-rule': true,

  tableCellClass: ternary('inAppStore', 'tbl__cell whitespace-normal', 'integrations__tbl__cell'),
  formattedCreatedAt: computed('notificationRule.createdAt', function () {
    let createdAt = this.get('notificationRule.createdAt');
    if (isEmpty(createdAt)) {
      return null;
    }
    if (moment().diff(createdAt) <= 0) {
      return 'a few seconds ago';
    }
    return moment(createdAt).fromNow();
  }),
});
