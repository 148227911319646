/* import __COLOCATED_TEMPLATE__ from './link-qr-section.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default class extends Component {
  @service intercomEventService;

  @action
  sendAnalyticsEvent(whatsappIntegration) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'copied_phone_number',
      object: whatsappIntegration,
    });
  }

  @action
  openWhatsappManager(whatsappIntegration) {
    let url = `https://business.facebook.com/wa/manage/phone-numbers/?waba_id=${whatsappIntegration?.whatsappAccountId}&business_id=${whatsappIntegration?.businessId}`;
    safeWindowOpen(url, '_blank');
  }
}
