/* import __COLOCATED_TEMPLATE__ from './trial-seat-selector.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type QuoteService from 'embercom/services/quote-service';
import type Plan from 'embercom/models/plan';
import moment from 'moment-timezone';

interface Args {
  header: string;
  loading: boolean;
  plan: Plan;
  numberTriallingSeats: string;
  priceTranslationString: string;
  moveTrialingCopilotSeats: () => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class TrialSeatSelector extends Component<Signature> {
  @service declare intl: any;
  @service declare quoteService: QuoteService;

  get planDaysRemainingOnTrial() {
    if (!this.args.plan.activeTrial) {
      return 0;
    }
    let trialEnd = moment(this.args.plan.activeTrialEndedAt);
    return trialEnd.diff(moment(), 'days');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::AnnualManageSubscription::TrialSeatSelector': typeof TrialSeatSelector;
  }
}
