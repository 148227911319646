/* import __COLOCATED_TEMPLATE__ from './pricing5-early-stage-banner-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import {
  AppPricing5PriceModels,
  PRICING_5_0_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_0_EARLY_STAGE_PRICING_MODEL,
  PRICING_5_1_COPILOT_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_1_COPILOT_EARLY_STAGE_PRICING_MODEL,
  PRICING_5_1_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_1_EARLY_STAGE_PRICING_MODEL,
} from 'embercom/lib/billing';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';

export default class Pricing5EarlyStageBannerComponent extends Component {
  @service purchaseAnalyticsService;
  @service customerService;
  @service appService;

  get app() {
    return this.appService.app;
  }

  get earlyStageStep() {
    return this.customerService.earlyStageGraduation.earlyStageStep;
  }

  get graduationInProgress() {
    return this.customerService.earlyStageGraduation.inProgress;
  }

  get pricingModelIdentifier() {
    return this.customerService.currentPricingModelIdentifier;
  }

  get isOnPricing51EarlyStage() {
    return [
      PRICING_5_1_EARLY_STAGE_PRICING_MODEL,
      PRICING_5_1_EARLY_STAGE_FREE_PRICING_MODEL,
      PRICING_5_1_COPILOT_EARLY_STAGE_PRICING_MODEL,
      PRICING_5_1_COPILOT_EARLY_STAGE_FREE_PRICING_MODEL,
    ].includes(this.pricingModelIdentifier);
  }

  get seatCount() {
    return this.isOnPricing51EarlyStage ? 6 : 5;
  }

  get messageSentCount() {
    return this.isOnPricing51EarlyStage ? 500 : 3000;
  }

  get pricingDetailsUrl() {
    switch (this.pricingModelIdentifier) {
      case PRICING_5_0_EARLY_STAGE_FREE_PRICING_MODEL:
        return 'https://www.intercom.com/help/en/articles/8806647-startup-partner-program';
      case PRICING_5_0_EARLY_STAGE_PRICING_MODEL:
        return 'https://www.intercom.com/help/en/articles/8711333-early-stage-program';
      case PRICING_5_1_EARLY_STAGE_FREE_PRICING_MODEL:
        return 'https://www.intercom.com/help/en/articles/9105521-startup-partner-program';
      case PRICING_5_1_EARLY_STAGE_PRICING_MODEL:
        return 'https://www.intercom.com/help/en/articles/9096410-early-stage-program';
      case PRICING_5_1_COPILOT_EARLY_STAGE_PRICING_MODEL:
        return 'https://www.intercom.com/help/en/articles/9361928-early-stage-program-from-june-27-2024';
      case PRICING_5_1_COPILOT_EARLY_STAGE_FREE_PRICING_MODEL:
        return 'https://www.intercom.com/help/en/articles/9361933-startup-partner-program-from-june-27-2024';
      default:
        return '';
    }
  }

  get endDate() {
    return moment(this.earlyStageStep.endsAt).format(dateAndTimeFormats.date);
  }

  get proactiveSupportPlusHCLink() {
    if (this.app.pricing5PricingModel === AppPricing5PriceModels.PRICING_5_1) {
      return 'https://www.intercom.com/help/en/articles/9061648-proactive-support-plus';
    } else {
      return 'https://www.intercom.com/help/en/articles/8205747-proactive-support-plus';
    }
  }
}
