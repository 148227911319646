/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ScreenEditor extends Component {
  @service intercomEventService;
  @service contentEditorService;

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  get onLastScreen() {
    return (
      this.args.screen.order ===
      this.args.screen.get('localizedCarouselContent.carouselScreens.length') - 1
    );
  }

  get onFirstScreen() {
    return this.args.screen.order === 0;
  }

  @action onBack() {
    this.args.onBack();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'mobile_carousel_full_preview_nav_clicked',
      object: `carousel_editor_screen_btn`,
      owner: 'team-messenger',
      place: 'carousel_editor_screen',
      metadata: { ruleset_id: this.ruleset.id },
    });
  }
}
