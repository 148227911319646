/* import __COLOCATED_TEMPLATE__ from './price-breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { capitalize } from '@ember/string';
import type IntlService from 'embercom/services/intl';
import type Contract from 'embercom/models/billing/contract';
import type Charge from 'embercom/models/billing/price/charge';
import type Store from '@ember-data/store';
import { Metric } from 'embercom/models/data/pricing/metric-types';

interface Args {
  testIdentifier: string;
  pricingMetric: PricingMetric;
  charges: Charge[];
  contract?: Contract;
  planId?: string;
}

export default class PriceBreakdown extends Component<Args> {
  @service declare intl: IntlService;
  @service declare customerService: any;
  @service declare store: Store;

  get charge() {
    return this.args.charges[0];
  }

  get includedUsage() {
    let { pricingMetric } = this.args;

    if (pricingMetric.metric === Metric.messages_sent) {
      return this.charge.baseUsage + this.charge.additionalUsagePurchased;
    }
    return this.charge.includedUsage;
  }

  get displayInYourSubscriptionRow() {
    return this.charge.isAnnualOrSalesforceContracted && this.includedUsage > 0;
  }

  private getUsage(workspace: any): number {
    return workspace.usage && workspace.usage[this.charge.pricing_metric] !== undefined
      ? workspace.usage[this.charge.pricing_metric]
      : 0;
  }

  private getUsageForWorkspace(): number {
    let { contract } = this.args;

    let workspace = contract?.perWorkspaceUsage.find(
      (workspace) => workspace.app_id === this.customerService.app.id,
    );

    return workspace ? this.getUsage(workspace) : 0;
  }

  get isUsageLessThanIncludedUsage() {
    let { contract } = this.args;

    if (contract?.isSecondarySubscription) {
      return this.getUsageForWorkspace() <= this.includedUsage;
    }

    return this.charge.actualUsage <= this.includedUsage;
  }

  get overageBreakdownTitle() {
    if (this.customerService.onPricing5_X) {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.pricing-5-overage-metric',
        { metric: capitalize(this.charge.pricingMetricModel.pricing5UsageIncludedText(2)!) },
      );
    } else {
      return this.intl.t(
        'billing.summary.price-usage-breakdown-card.overage-tooltip.overage-metric',
        { metric: capitalize(this.charge.pricingMetricModel.pluralize(2)!) },
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::Tooltips::PriceBreakdown': typeof PriceBreakdown;
  }
}
