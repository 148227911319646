/* import __COLOCATED_TEMPLATE__ from './task-settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Checklist from 'embercom/models/checklists/checklist';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import type Task from 'embercom/models/checklists/task';
import type Store from '@ember-data/store';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import type IntlService from 'embercom/services/intl';

interface Args {
  checklist: Checklist;
  activeTask: Task;
  shouldShowValidations: boolean;
  showGeneralSettings: () => void;
}

export default class TaskSettings extends Component<Args> {
  @service declare appService: any;
  @service declare attributeService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @tracked actionUrlBlocksDoc: BlocksDocument;

  @tracked showAutoComplete: boolean;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.actionUrlBlocksDoc = new BlocksDocument(this.renderableActionUrl);
    this.showAutoComplete = this.task.canBeAutoResolved;
    if (!this.task.canBeManuallyResolved) {
      this.showAutoComplete = true; // if manual resolve is off then we should show auto complete rules (even if no rules selected)
    }
  }

  get app() {
    return this.appService.app;
  }

  get checklist() {
    return this.args.checklist;
  }

  get task() {
    return this.args.activeTask;
  }

  get renderableActionUrl() {
    return [
      {
        type: 'paragraph',
        text: this.task.actionUrl ? this.escapedHtmlText(this.task.actionUrl) : '',
      },
    ];
  }

  private escapedHtmlText(text: string) {
    let pre = document.createElement('pre');
    let textNode = document.createTextNode(text);
    pre.appendChild(textNode);
    return pre.innerHTML;
  }

  get baseEvent() {
    if (!this.task.eventId) {
      return null;
    }

    return this.attributeService.attributes.find(
      (attr: any) => attr.event_id === this.task.eventId,
    );
  }

  get showAutoResolveRulesValidation() {
    return this.args.shouldShowValidations && !this.task.canBeResolved;
  }

  @action toggleAllowAutoCompletion() {
    if (!this.showAutoComplete) {
      this.showAutoComplete = true;
      return;
    }
    this.task.eventPredicateGroup.predicates = [];
    this.task.eventType = null;
    this.task.eventId = null;
    this.task.predicateGroup.predicates = [];
    this.showAutoComplete = false;
  }

  @action onTaskChange() {
    this.actionUrlBlocksDoc = new BlocksDocument(this.renderableActionUrl);
    this.showAutoComplete = this.task.canBeAutoResolved;
    if (!this.task.canBeManuallyResolved) {
      this.showAutoComplete = true; // if manual resolve is off then we should show auto complete rules (even if no rules selected)
    }
  }

  @action updateActionUrl(blocksDoc: BlocksDocument) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    let blockText = blockFragments.firstObject.text;
    let textContent = htmlToTextContent(blockText);

    this.task.actionUrl = textContent.trim();
    this.actionUrlBlocksDoc = blocksDoc;
  }

  @action toggleManualResolve() {
    this.task.canBeManuallyResolved = !this.task.canBeManuallyResolved;
  }

  @action filterTimeInput(event: KeyboardEvent) {
    let target = event.target as HTMLInputElement;
    let length = target.value.length;

    // Don't allow 0 as first character
    if (event.key === '0' && length === 0) {
      event.preventDefault();
      return;
    }

    if (event.key < '0' || event.key > '9') {
      event.preventDefault();
      return;
    }

    // Set a max length of 3 for the input itself
    if (length >= 3) {
      event.preventDefault();
      return;
    }
  }

  @action onEstimatedTimeChange(event: any) {
    let newTime = Number(event.target.value);

    // newTime is only ever 0 when input is cleared and user blurs the field
    // In this case I just set it to 1 minute (the default minimum)
    if (newTime === 0) {
      newTime = 1;
    }
    this.task.estimatedTime = newTime * 60;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checklist::Sidebar::TaskSettings': typeof TaskSettings;
    'checklist/sidebar/task-settings': typeof TaskSettings;
  }
}
