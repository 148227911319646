/* import __COLOCATED_TEMPLATE__ from './predicates-editor.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class PredicatesEditor extends Component {
  @service appService;
  @service attributeService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get attributeGroupList() {
    let groups = [];

    groups.push({
      heading: this.intl.t('composer.if-else-statement-block-editor.predicates-editor.person-data'),
      attributes: this.attributeService.filterableUserAttributesForMessaging,
    });

    if (this.app.companiesActive) {
      groups.push({
        heading: this.intl.t(
          'composer.if-else-statement-block-editor.predicates-editor.company-data',
        ),
        attributes: this.attributeService.filterableCompanyAttributesForMessaging,
      });
    }

    return groups;
  }
}
