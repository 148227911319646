/* import __COLOCATED_TEMPLATE__ from './price-breakdown-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Breakdown from 'embercom/models/billing/price/breakdown';
import type Plan from 'embercom/models/plan';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import {
  INBOX_PRO_ID,
  INBOX_ESSENTIAL_ID,
  MESSAGES_PRO_ID,
  MESSAGES_ESSENTIAL_ID,
} from 'embercom/lib/billing';
import { isPresent } from '@ember/utils';

interface Args {
  breakdown: Breakdown;
  sharedBasePriceBreakdown: Breakdown | undefined;
}

interface Signature {
  Args: Args;
}

const PRICING_METRICS_TO_SHOW_USAGE = [
  Metric.inbox_seats,
  Metric.latest_daily_admin_count,
  Metric.thirty_day_messaged_contacts,
  Metric.resolutions,
];

const SHARED_BASE_CORE_PLANS = [
  INBOX_PRO_ID,
  INBOX_ESSENTIAL_ID,
  MESSAGES_PRO_ID,
  MESSAGES_ESSENTIAL_ID,
];

export default class BillingMigrationPriceBreakdownDetailsComponent extends Component<Signature> {
  @service declare customerService: $TSFixMe;
  @service declare intl: IntlService;

  translationKey = 'billing.migrations.current_price_details.breakdown.';

  get usagesToShow() {
    return this.chargesToShow.map((charge) => {
      let currentUsage = Math.max(charge.actualUsage, charge.minimumUsage);

      return {
        pricingMetricName: charge.pricing_metric,
        description: `${this.intl.formatNumber(currentUsage)} ${charge.pricingMetricModel.pluralize()}`,
      };
    });
  }

  private get chargesToShow() {
    return this.args.breakdown.charges.filter((charge) => {
      if (PRICING_METRICS_TO_SHOW_USAGE.includes(charge.pricing_metric)) {
        return Math.max(charge.actualUsage, charge.minimumUsage) > 0;
      }

      return false;
    });
  }

  get breakdownTotal() {
    if (this.args.breakdown.isDiscount) {
      return this.args.breakdown.amount / 100;
    } else if (this.args.breakdown.isSharedBaseBreakdown) {
      return (this.args.breakdown.pre_discount_price! / 100) * -1;
    } else if (
      this.hasSharedBasePrice &&
      this.planId &&
      SHARED_BASE_CORE_PLANS.includes(this.planId.toString())
    ) {
      return (
        (this.args.breakdown.pre_discount_price! +
          (this.args.sharedBasePriceBreakdown?.pre_discount_price || 0)) /
        100
      );
    }

    return this.args.breakdown.pre_discount_price! / 100;
  }

  private get hasSharedBasePrice() {
    return isPresent(this.args.sharedBasePriceBreakdown);
  }

  get shouldShowSharedBaseBundleDiscount() {
    let activeSharedBaseCorePlans = this.customerService.activePlans.filter((plan: Plan) =>
      SHARED_BASE_CORE_PLANS.includes(plan.id),
    );

    return activeSharedBaseCorePlans.length > 1;
  }

  get label() {
    if (this.args.breakdown.isDiscount || this.args.breakdown.isSharedBaseBreakdown) {
      return;
    }

    return this.args.breakdown.nameWithProduct;
  }

  private get planId() {
    return this.args.breakdown.plan_id;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::PriceBreakdownDetails': typeof BillingMigrationPriceBreakdownDetailsComponent;
  }
}
