/* import __COLOCATED_TEMPLATE__ from './main-content.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { findByProperty, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import Component from '@ember/component';
import { readOnly, not, bool, filterBy, union } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend({
  appService: service(),
  store: service(),
  notificationsService: service(),
  app: readOnly('appService.app'),
  currentAdmin: readOnly('app.currentAdmin'),
  adminNotVerified: not('currentAdmin.email_verified'),
  emailVerificationSent: bool('currentAdmin.current_email_pending_verification'),
  disableVerificationButton: not('currentAdmin.canResendVerificationEmail'),
  appPackages: readOnly('model.appPackages'),
  defaultCategories: readOnly('model.defaultCategories'),
  featuredCategories: readOnly('model.featuredCategories'),
  collections: readOnly('model.collections'),

  featuredCategoriesAndApps: null,
  publicCategories: filterBy('defaultCategories', 'publiclyVisible', true),
  visiblefeaturedCategories: filterBy('featuredCategories', 'publiclyVisible', true),
  visibleCollections: filterBy('collections', 'publiclyVisible', true),
  allCategories: union('defaultCategories', 'featuredCategories', 'collections'),
  selectedCategory: findByProperty('allCategories', 'slug', 'category'),
  installedAppPackages: computed('appPackages.@each.isInstalled', 'installed', function () {
    return this.appPackages.filter((app) => app.get('isInstalled'));
  }),
  apps: ternaryToProperty('installed', 'installedAppPackages', 'appPackages'),
  gridTitle: computed(
    'search',
    'selectedCategory.name',
    'installed',
    'showCapability',
    'selectedCapability.name',
    function () {
      if (this.search) {
        return 'Search results';
      } else if (this.get('selectedCategory.name')) {
        return this.get('selectedCategory.name');
      } else if (this.get('selectedCapability.name')) {
        return `Works with ${this.get('selectedCapability.name')}`;
      } else if (this.installed) {
        return 'Your installed apps';
      } else {
        return 'All apps';
      }
    },
  ),

  sendVerificationEmail: task(function* () {
    if (this.disableVerificationButton) {
      return;
    }
    try {
      yield this.currentAdmin.sendValidationEmailWithRetry();
      this.notificationsService.notifyConfirmation(
        'Verification email sent. Please check your email.',
      );
    } catch (e) {
      this.notificationsService.notifyError(
        'Something went wrong sending your verfication email. Please try again.',
      );
    }
  }),
});
