/* import __COLOCATED_TEMPLATE__ from './edit-plan-modal.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { CORE_SEAT_METRIC, INCLUDED_TIERS_FROM_PRICING_ENDPOINT } from 'embercom/lib/billing';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

export default class EditPlanModal extends Component {
  @service appService;
  @service customerService;
  @service permissionsService;
  @service purchaseAnalyticsService;
  @tracked activePlan = null;
  @tracked selectedPlan = null;

  constructor() {
    super(...arguments);
    this.selectedPlan = this.args.selectedPlan;
    this.currentPlan = this.selectedProduct.previousActivePlan;
    this.activePlan = this.setActivePlan();
    this.loadPrices.perform();
  }

  @task({ restartable: true })
  *loadPrices() {
    let params = this.allRequiredPriceCombinations();
    yield this.customerService.bulkLoadPrices(params);
  }

  allRequiredPriceCombinations() {
    let activePlanIds = this.customerService.activePlanIds;
    let targetProduct = this.selectedProduct;
    let plansForTargetProduct = targetProduct.planIds;

    if (activePlanIds.length === 0) {
      return [];
    }

    let activePlansWithoutTargetProduct = this.removePlanIdsFromActivePlans(
      plansForTargetProduct,
      activePlanIds,
    );

    return plansForTargetProduct.map((planId) => {
      let planIds = [planId].concat(activePlansWithoutTargetProduct);
      return {
        planIds,
        includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
        includePlanCombinationValidation: true,
        source: 'edit-plan-modal-component',
      };
    });
  }

  removePlanIdsFromActivePlans(planIds, activePlanIds) {
    return activePlanIds.filter((planId) => {
      return !planIds.includes(planId);
    });
  }

  setActivePlan() {
    let higherPlanId = this.selectedProduct.higherPlanId;
    this.customerService.updateData({ planIds: [higherPlanId] });
    return this.selectedProduct.plans.find((plan) => plan.id === higherPlanId);
  }

  getActivePlan() {
    return this.selectedProduct.plans.find((plan) => plan.active);
  }

  get selectedProduct() {
    return this.customerService.products.find((product) =>
      product.plans.map((plan) => plan.id).includes(this.selectedPlan.id),
    );
  }

  get activePlanTeammateLimit() {
    return this.activePlan.get('pricingStrategies').findBy('pricingMetric', CORE_SEAT_METRIC)
      ?.maximum;
  }

  get teammateLimitReached() {
    return this.appService.app.humanAdmins.length > this.activePlanTeammateLimit;
  }

  get planPrice() {
    return this.customerService.getPriceFromPlanIds([this.activePlan.idAsNumber]);
  }

  get hasBillingPermission() {
    return this.permissionsService.currentAdminCan('can_access_billing_settings');
  }

  get isValidPlanCombination() {
    let price = this.customerService.getPriceFromPlanIds([this.activePlan.idAsNumber]);
    return price?.plan_combination_valid;
  }

  @action updatePlan(plan) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'plan_card',
      place: 'edit_plan_modal',
      planId: plan.id,
    });

    if (plan.id !== this.currentPlan?.id) {
      this.customerService.updateData({ planIds: [plan.id] });
      this.activePlan = this.getActivePlan();
    }
  }
}
