/* import __COLOCATED_TEMPLATE__ from './attachment-list.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import type { Block, AttachmentList } from '@intercom/interblocks.ts';

interface Args {
  blocks: Array<Block>;
}

interface Signature {
  Args: Args;
}

export default class ComposerAttachmentList extends Component<Signature> {
  get attachments() {
    let attachmentList = this.args.blocks.findBy('type', 'attachmentList') as
      | AttachmentList
      | undefined;
    return attachmentList?.attachments;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Composer::AttachmentList': typeof ComposerAttachmentList;
  }
}
