/* import __COLOCATED_TEMPLATE__ from './for-users.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type TaskBots from 'embercom/models/operator-settings/task-bots';

interface Args {
  settings: TaskBots;
  updateDelayEnabled: () => void;
  openSectionId: string | null;
  openSectionChange: (sectionId: string) => void;
}
export default class AutomationBasicsForUsers extends Component<Args> {
  @service declare appService: any;

  get app(): any {
    return this.appService.app;
  }

  get hasBotWorkflows(): boolean {
    return this.app?.canUseFeature('bot_workflows');
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Automation::Basics::ForUsers': typeof AutomationBasicsForUsers;
  }
}
