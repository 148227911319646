/* import __COLOCATED_TEMPLATE__ from './screen-stats.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { extractUnversionedId } from 'embercom/helpers/content-versioning';

export default class ScreenStats extends Component {
  @service contentEditorService;

  getUnversionedScreenId(screenId) {
    if (this.contentEditorService.selectedVersion) {
      return extractUnversionedId(screenId);
    }
    return screenId;
  }

  get stats() {
    let id = this.getUnversionedScreenId(this.args.screenId);
    return this.args.stats[id];
  }
}
