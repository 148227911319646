/* import __COLOCATED_TEMPLATE__ from './resume-pending-cancellation-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action } from '@ember/object';
import { readOnly } from '@ember/object/computed';

export default Component.extend({
  tagName: '',
  intercomEventService: service(),
  notificationsService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  store: service(),

  init() {
    this._super(...arguments);
    this.setProperties({
      isResuming: false,
      errors: [],
    });
  },

  closeModal: action(function () {
    this.modalService.closeModal();
  }),

  loadCustomer(payload) {
    return this.store.pushPayload('billing/customer', {
      'billing/customer': payload,
    });
  },

  resume: action(function () {
    let self = this;
    this.set('isResuming', true);
    return ajax({
      url: `/ember/customers/${self.get('model.id')}/resume_pending_cancellation`,
      type: 'POST',
      data: JSON.stringify({
        code: self.get('code'),
        app_id: self.get('app.id'),
      }),
    }).then(
      function (customer) {
        this.loadCustomer(customer);
        this.notificationsService.notifyConfirmation('Your subscription has been resumed');
        this.closeModal();
        this.intercomEventService.trackEvent('resumed-pending-subscription-cancellation');
      }.bind(this),
      function (response) {
        this.set('errors', response.jqXHR.responseJSON);
        this.set('isResuming', false);
      }.bind(this),
    );
  }),
});
