/* import __COLOCATED_TEMPLATE__ from './delete.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { equal, readOnly } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { ternary } from '@intercom/pulse/lib/computed-properties';
// eslint-disable-next-line @intercom/intercom/no-legacy-modal
import BulkActionModal from 'embercom/controllers/mixins/users-and-companies/bulk-action-modal';
import ajax from 'embercom/lib/ajax';
import { default as inflector, default as pluralise } from 'embercom/lib/inflector';
import numberFormatter from 'embercom/lib/number-formatter';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';

export default Component.extend(BulkActionModal, {
  tagName: '',
  pubsub: service(),
  notificationsService: service(),
  router: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  appService: service(),
  app: readOnly('appService.app'),

  init() {
    this._super(...arguments);
    this.reset();
  },

  reset() {
    this.setProperties({
      isDeleting: false,
      hasErrored: false,
    });
  },
  modalTarget: 'company',
  modalTargetPlural: 'companies',
  modalTargetPluralForLink: computed('modalTarget', 'modalTargetPlural', function () {
    if (this.modalTarget === 'person') {
      return 'users';
    }
    if (this.modalTarget === 'user') {
      return 'users';
    }
    return this.modalTargetPlural;
  }),
  modalAction: 'delete',
  singleUserSelected: equal('model.selectedIDs.length', 1),
  notificationMessage() {
    let targetCount = this.get('model.hasCount')
      ? this.get('model.count')
      : 'An unknown number of ';
    let notice = `It might take a minute or two for them to disappear from your ${pluralise(
      this.modalTarget,
    )} list.`;
    if (this.get('model.hasCount')) {
      return `You've deleted ${targetCount} ${this.modalTargetPlural}. ${notice}`;
    } else {
      return `${targetCount} ${this.modalTargetPlural} were deleted. ${notice}`;
    }
  },
  deletionNumberFormatter: computed('model.count', function () {
    return numberFormatter(this.get('model.count'));
  }),
  deletingTargetTextWithRemoves: computed('model.count', function () {
    return inflector(
      `a ${this.modalTarget} removes`,
      this.get('model.count'),
      `${this.modalTargetPlural} removes`,
    );
  }),
  doBulkDelete: true,
  endpointForDeletion: ternary('doBulkDelete', 'bulk_delete', 'delete'),
  submitUrl: computed('modalTargetPluralForLink', 'endpointForDeletion', function () {
    return `/ember/${this.modalTargetPluralForLink}/${this.endpointForDeletion}.json`;
  }),
  deleteParameters: computed('app', 'model', function () {
    return JSON.stringify($.extend(this.model.toApi(), { app_id: this.get('app.id') }));
  }),

  closeModal: action(function () {
    this.modalService.closeModal();
  }),
  actions: {
    submit() {
      this.set('isDeleting', true);
      this.set('hasErrored', false);
      let self = this;

      ajax({
        url: this.submitUrl,
        type: 'POST',
        data: this.deleteParameters,
      }).then(
        function () {
          self.notificationsService.notifyConfirmation(self.notificationMessage());
          self.send('decrementTargetCount');
          self.get('pubsub').publish('reloadUserOrCompanyList');
          self.reset();
          self.closeModal();
          self.send('deleteComplete');
        },
        function (jqXHR) {
          self.processBulkActionFailure(jqXHR, 'isDeleting');
        },
      );
    },
    deleteComplete() {
      this.router.replaceWith('apps.app.companies.segments.segment', 'all');
    },
    decrementTargetCount() {
      let countAttributeLabel = `app.${this.modalTarget}_count`;
      let newTotal = this.get(countAttributeLabel) - this.get('model.count');
      this.set(countAttributeLabel, newTotal);
    },
  },
});
