/* import __COLOCATED_TEMPLATE__ from './side-drawer-opener.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  hideIntercomWidgetWhenLoaded,
  showIntercomWidget,
} from 'embercom/lib/intercom-widget-helper';

export default class SideDrawerOpener extends Component {
  @tracked sideDrawerIsVisible = false;

  @action showSideDrawer() {
    hideIntercomWidgetWhenLoaded();
    this.sideDrawerIsVisible = true;
  }

  @action hideSideDrawer() {
    this.sideDrawerIsVisible = false;
    showIntercomWidget();
  }

  get conversationIds() {
    return this.args.answer.exampleQuestionSourceConversationIds.slice(0, 20);
  }
}
