/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import { empty } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import SlackIntegration from 'embercom/models/slack-legacy-integration';

export default Component.extend({
  tagName: '',

  store: service(),
  notificationsService: service(),
  redirectService: service(),
  intercomConfirmService: service(),
  intercomEventService: service(),

  slackIntegration: null,

  showConnectModal: false,
  noTopicsSelected: empty('slackIntegration.selectedTopics'),

  fetchSlackIntegration: task(function* () {
    try {
      let slackIntegration = yield this.store.queryRecord('slack-legacy-integration', {
        retrieve_subscriptions: false,
      });
      this.set('slackIntegration', slackIntegration);
    } catch (e) {
      this.notificationsService.notifyError(
        'Connection error: Please refresh the page and try again.',
      );
    }
  }).drop(),

  connect: task(function* () {
    try {
      this.intercomEventService.trackEvent('integrations_hub_metric', {
        action: 'completed',
        object: 'slack-integration-step2',
        owner: 'dx',
        place: 'integrations-hub',
        integration_id: 'slack',
      });
      let topicIds = this.slackIntegration.selectedTopics.map((topic) => topic.id);
      let oauthRedirectSubpath = 'appstore';

      let redirectUrl = yield SlackIntegration.getRedirectUrl(
        this.app.id,
        topicIds,
        oauthRedirectSubpath,
      );

      if (redirectUrl) {
        this.redirectService.redirect(redirectUrl.redirect_url);
      }
    } catch (e) {
      this.notificationsService.notifyError('An error occurred while adding Slack integration');
    }
  }).drop(),

  deleteSubscription: task(function* (subscription) {
    let options = {
      title: 'Delete subscription',
      body: 'Are you sure you want to delete this subscription?',
      confirmButtonText: 'Delete',
      primaryButtonType: 'primary-destructive',
    };
    let confirmed = yield this.intercomConfirmService.confirm(options);
    if (confirmed) {
      this.intercomEventService.trackEvent('integrations_hub_metric', {
        action: 'removed',
        object: 'slack-webhook',
        owner: 'dx',
        place: 'integrations-hub-slack',
        integration_id: 'slack',
      });

      yield subscription.destroyRecord();
    }
  }).drop(),

  closeModal: action(function () {
    this.slackIntegration.topics.forEach((t) => t.set('selected', false));
    this.set('showConnectModal', false);
  }),
});
