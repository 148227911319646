/* import __COLOCATED_TEMPLATE__ from './answer-editor.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import StandardAudienceEditorConfiguration from 'embercom/objects/audience-editor/standard-audience-editor-configuration';
import CustomAnswerEditorConfig from 'embercom/objects/visual-builder/configuration/editor/custom-answer';
import type SuggestedAnswerCluster from 'embercom/models/custom-answers/suggested-answer-cluster';
import { type AllChannels } from 'embercom/lib/operator/custom-bots/constants';
import {
  ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL,
  ALL_CHANNELS_EXCEPT_PHONE_CALL,
} from 'embercom/lib/operator/custom-bots/constants';

const EDITOR_SECTION_TO_ANALYTICS_CONTEXT = {
  'example-questions': 'question',
  'response-editor': 'answer',
  'answer-review': 'review_step',
  audience: 'audience',
};

type EditorSection = keyof typeof EDITOR_SECTION_TO_ANALYTICS_CONTEXT;

interface Signature {
  Args: {
    answer: $TSFixMe; // embercom/models/custom-answers/custom-answer
    suggestionId: number;
    initiallyOpenSectionName: EditorSection;
    suggestedAnswerClusters: (typeof SuggestedAnswerCluster)[];
    clusterId: string;
    isLoadingSuggestedAnswerClusters: boolean;
    expandSelectedExamples: boolean;
    prepopulatedSearchTerm: string;
    showTestModal: boolean;
    toggleTestAnswerModal: () => void;
    onSuggestionUsed: () => void;
    onLanguageUpdated: () => void;
    save: () => void;
  };
}

export default class AnswerEditor extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;

  @tracked audienceTargetingOpened = false;
  @tracked reviewOpened = false;
  @tracked suggestionId = this.args.suggestionId;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
  }

  get app() {
    return this.appService.app;
  }

  get audienceIsReviewed() {
    let audienceIsValid = this.args.answer.ruleset.validations.isTruelyValid;
    if (this.args.answer.hasBeenUpdated) {
      return audienceIsValid;
    }
    return audienceIsValid && this.audienceTargetingOpened;
  }

  get audienceEditorConfiguration() {
    return new StandardAudienceEditorConfiguration({
      showClientPredicates: false,
      allowedUserRoles: ['user', 'lead', 'visitor'],
      useRolePredicateGroup: true,
    });
  }

  get initiallyOpenSectionName() {
    if (!this.args.answer.exampleQuestionsIsComplete) {
      return 'language';
    }

    return 'example-questions';
  }

  get targetingEmail() {
    return this.args.answer.targetChannels.includes('email');
  }

  get editorConfiguration() {
    return new CustomAnswerEditorConfig({ targetChannels: this.args.answer.targetChannels });
  }

  @action
  sectionCanClose(sectionName: EditorSection) {
    if (sectionName === 'response-editor') {
      return !this.args.answer.paths.isAny('isUploading');
    }
    return true;
  }

  @action
  sectionDidOpen(sectionName: EditorSection) {
    let analyticsContext = EDITOR_SECTION_TO_ANALYTICS_CONTEXT[sectionName];
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'answer_editor_section',
      context: analyticsContext,
    });

    if (sectionName === 'audience') {
      this.audienceTargetingOpened = true;
    } else if (sectionName === 'answer-review') {
      this.reviewOpened = true;
    }
  }

  @action
  sectionDidClose(sectionName: EditorSection) {
    if (sectionName === 'example-questions') {
      this.args.onSuggestionUsed && this.args.onSuggestionUsed();
    }
  }

  @action
  togglePredictiveAnswerToggle() {
    this.args.answer.disabledInPredictiveContext = !this.args.answer.disabledInPredictiveContext;
  }

  @action
  toggleEmailTargeting() {
    let emailIndex = this.args.answer.targetChannels.indexOf('email');
    // we need to replace the entire target channels array in order to trigger dependent key updates
    let updatedTargetChannels: AllChannels[];
    if (emailIndex === -1) {
      updatedTargetChannels = [...ALL_CHANNELS_EXCEPT_PHONE_CALL];
    } else {
      updatedTargetChannels = [...ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL];
    }
    this.args.answer.set('targetChannels', updatedTargetChannels);
    this.args.answer.visualBuilderObject.editorConfig.targetChannels = updatedTargetChannels;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Answers::Editor::AnswerEditor': typeof AnswerEditor;
    'answers/editor/answer-editor': typeof AnswerEditor;
  }
}
