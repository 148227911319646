/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import type IntlService from 'embercom/services/intl';

export function formatPrice(price: number, intl: IntlService) {
  return intl.formatNumber(price, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  });
}

export function formatPriceFromCents(priceInCents: number, intl: IntlService) {
  return intl.formatNumber(priceInCents / 100, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  });
}
